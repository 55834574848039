<template>
   <div id="jsonModel" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-lg"> 
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ jsonmodel.title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                   <div class="api-details-pnl">
                       <p>
                           API End Point : <span style="font-weight:bold">{{ jsonmodel.url }}</span>
                       </p>
                       <div style="margin-top:20px;">
                           <ul class="nav nav-tabs" id="myTab123" role="tablist">
                                <li class="nav-item" role="presentation" style="width:50%;">
                                    <a class="nav-link active" id="request-tab" data-toggle="tab" href="#request" role="tab" aria-controls="home" aria-selected="true">Request</a>
                                </li>
                                <li class="nav-item" role="presentation" style="width:50%;">
                                    <a class="nav-link" id="response-tab" data-toggle="tab" href="#response" role="tab" aria-controls="profile" aria-selected="false">Response</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent123">
                                <div class="tab-pane fade show active" id="request" role="tabpanel" aria-labelledby="request-tab">
                                   <div class="json-view-panel">
                                       <vue-json-pretty :path="'res'" :data="jsonmodel.api_req"> </vue-json-pretty>
                                   </div>
                                </div>
                                <div class="tab-pane fade" id="response" role="tabpanel" aria-labelledby="response-tab">
                                    <div class="json-view-panel">
                                        <vue-json-pretty :path="'res'" :data="jsonmodel.api_res"> </vue-json-pretty>
                                    </div>
                                </div>
                            </div>
                       </div>
                   </div>
                </div>
                </div>
            </div>
        </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  name: "JsonModel",
  components: {
    VueJsonPretty
  },
  computed : {
     jsonmodel : function(){
         return this.$store.getters["common/getJsonModel"];
     }  
  }
};
</script>