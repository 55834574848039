<template>
<div class="flight-search-contents">
    <div class="search-form flights">
        <form v-on:submit.prevent="actionSearch" novalidate>
            <div class="row d-flex justify-content-between align-items-center" style="margin: 0">
                <div class="form-row">
                    
                    <div class="d-flex form-lft justify-content-start align-items-center search-form-details" v-for="index in flight.cursector" :key="index">
                        <div class="form-group">
                            <label for="Departure city">Departure City</label>
                            <input type="text" v-model="flight.form[index - 1].origin_airport_code" class="form-control" placeholder="From" />
                            <small v-if="
                                $v.flight.form['$each'][index - 1] != undefined &&
                                !$v.flight.form['$each'][index - 1].origin_airport_code
                                .isValid &&
                                $v.flight.form['$each'][index - 1].origin_airport_code
                                .$error
                                " class="text-danger">Select Origin Airport
                            </small>
                        </div>
                        <div class="form-group">
                            <label for="Arrival city">Arrival City</label>
                            <input type="text" v-model="flight.form[index - 1].destination_airport_code" class="form-control" placeholder="To" />
                            <small v-if="
                                $v.flight.form['$each'][index - 1] != undefined &&
                                !$v.flight.form['$each'][index - 1].destination_airport_code
                                .isValid &&
                                $v.flight.form['$each'][index - 1].destination_airport_code
                                .$error
                                " class="text-danger">Select Destination Airport
                            </small>
                        </div>
                        <div class="form-group date_wrap ">
                            <label for="Arrival Date">Arrival Date</label>
                            <flat-pickr class="form-control" :config="configStartDate" v-model="flight.form[index - 1].arrival_date" @on-change="onStartChange(flight.form[index - 1].arrival_date)" placeholder="Arrival"></flat-pickr>
                            <small v-if="
                                $v.flight.form['$each'][index - 1] != undefined &&
                                !$v.flight.form['$each'][index - 1].arrival_date.isValid &&
                                $v.flight.form['$each'][index - 1].arrival_date.$error
                                " class="text-danger">Select Arrival Date
                            </small>
                        </div>
                        <div class="form-group time-label">
                            <label for="From Time">FromTime</label>
                            <select id="No of stops" class="form-control" v-model="flight.form[index - 1].FromTime">
                                <!--  <option v-for="(i,idx) in max.connection " :key="idx">{{ i }}</option>-->
                                <option value="" disabled selected hidden>Select Option</option>
                                <option value=" ">clear</option>
                                <option selected="selected" value="00">12am</option>
                                <option value="01">1am</option>
                                <option value="02">2am</option>
                                <option value="03">3am</option>
                                <option value="04">4am</option>
                                <option value="05">5am</option>
                                <option value="06">6am</option>
                                <option value="07">7am</option>
                                <option value="08">8am</option>
                                <option value="09">9am</option>
                                <option value="10">10am</option>
                                <option value="11">11am</option>
                                <option value="12">12pm</option>
                                <option value="13">1pm</option>
                                <option value="14">2pm</option>
                                <option value="15">3pm</option>
                                <option value="16">4pm</option>
                                <option value="17">5pm</option>
                                <option value="18">6pm</option>
                                <option value="19">7pm</option>
                                <option value="20">8pm</option>
                                <option value="21">9pm</option>
                                <option value="22">10pm</option>
                                <option value="23">11pm</option>
                              <!--  <option value="24">12pm</option> -->
                            </select>
                        </div>

                        <div class="form-group time-label">
                            <label for="To Time">ToTime</label>
                            <select id="No of stops" class="form-control" v-model="flight.form[index - 1].TOTime">
                                <!--  <option v-for="(i,idx) in max.connection " :key="idx">{{ i }}</option>-->
                                <option value="" disabled selected hidden>Select Option</option>
                                <option value=" ">clear</option>
                                <option selected="selected" value="00">12am</option>
                                <option value="01">1am</option>
                                <option value="02">2am</option>
                                <option value="03">3am</option>
                                <option value="04">4am</option>
                                <option value="05">5am</option>
                                <option value="06">6am</option>
                                <option value="07">7am</option>
                                <option value="08">8am</option>
                                <option value="09">9am</option>
                                <option value="10">10am</option>
                                <option value="11">11am</option>
                                <option value="12">12pm</option>
                                <option value="13">1pm</option>
                                <option value="14">2pm</option>
                                <option value="15">3pm</option>
                                <option value="16">4pm</option>
                                <option value="17">5pm</option>
                                <option value="18">6pm</option>
                                <option value="19">7pm</option>
                                <option value="20">8pm</option>
                                <option value="21">9pm</option>
                                <option value="22">10pm</option>
                                <option value="23">11pm</option>
                               <!-- <option value="24">12pm</option>-->
                            </select>
                        </div>
                   
                        <div class="form-group add-days-check">
                            <div>
                                <label for="plusoneday" class="flex">plus Oneday</label>
                            </div>
                            <input type="checkbox" style="width: 20px; height: 20px;" id="PlusonedayOPtion" name="PlusonedayOPtion" v-model="flight.form[index - 1].plus_oneday_option" />
                        </div>

                        <div class="form-group date_wrap" v-if="flight.trip == 'oneway' || flight.trip == 'return'">
                             <label for="return">ReturnDate</label>
                            <flat-pickr class="form-control" :config="configEndDate" v-model="return_date" placeholder="(Return)"></flat-pickr>
                        </div>
                       <fieldset aria-describedby="" class="form-group addAnother-flight" id="__BVID__129">
                            <div tabindex="-1" role="group" class="bv-no-focus-ring" v-if="flight.cursector < flight.masector">
                                <a class="font-weight-bold btn addReturnBtn" @click="addflight">
                                    <span class="add_another_flight_btn">+ Add Flight</span>
                                </a>
                            </div>
                        </fieldset>
                        <br />
                       <fieldset aria-describedby="" class="form-group remove-btn" id="__BVID__129">
                            <div tabindex="-1" role="group" class="bv-no-focus-ring" v-if="flight.trip == 'multi' && index > 1">
                                <a class="font-weight-bold btn addReturnBtn d-flex" @click="remove(index)">
                                    <span class="add_another_flight_btn mr-2">Remove</span>
                                    <span class="remove-circle text-center">x</span>
                                </a>
                            </div>
                        </fieldset>

                    </div>
                   <!-- <fieldset aria-describedby="" class="form-group addAnother-flight" id="__BVID__129">
                            <div tabindex="-1" role="group" class="bv-no-focus-ring" v-if="flight.cursector < flight.masector">
                                <a class="font-weight-bold btn addReturnBtn" @click="addflight">
                                    <span class="add_another_flight_btn">+ Add Flight</span>
                                </a>
                            </div>
                        </fieldset>
                        <br />-->
                </div>
                
                <div class="form-row">
                    <div class="d-flex form-lft justify-content-start align-items-center search-form-adult-detls my-2">
                        <div class="form-group connection-lbl">
                            <label for="Max NO of connection">Max No of connection</label>
                            <select id="No of stops" class="form-control" v-model="flight.no_of_stops" placeholder="SelectOption">
                                <option value="" disabled selected hidden>Select Option</option>
                                <option value="">clear</option>
                                <option selected="selected" value="0">Direct Only</option>
                                <option value="1">One or Less</option>
                                <option value="2">TWO or Less</option>
                                <option value="3">Three or Less</option>
                            </select>
                        </div>
                        <div class="cabintype">
                            <label for="Maximum">CabinType</label>
                            <b-form-group class="mr-2 p-0" label>
                                <multiselect :multiple="true" open-direction='bottom' :show-labels="false" :close-on-select="false" :options="cabinOption" v-model="flight.cabin_type" placeholder="SelectOption" @input="cabinDisable"></multiselect>
                            </b-form-group>
                        </div>
                        <div class="form-group connectn-tme">
                            <label for="Maximum">Layover Duration</label>
                            <select id="Maximum connection " class="form-control" v-model="flight.MaxmiumTime" placeholder="select Option">
                                <option value="" disabled selected hidden>Select Option</option>
                                <option value=" ">clear</option>
                                <option selected="selected" value="01">1h</option>
                                <option value="02">2h</option>
                                <option value="03">3h</option>
                                <option value="04">4h</option>
                                <option value="05">5h</option>
                                <option value="06">6h</option>
                                <option value="07">7h</option>
                                <option value="08">8h</option>
                                <option value="09">9h</option>
                                <option value="10">10h</option>
                                <option value="11">11h</option>
                                <option value="12">12h</option>
                                <option value="13">13h</option>
                                <option value="14">14h</option>
                                <option value="15">15h</option>
                                <option value="16">16h</option>
                                <option value="17">17h</option>
                                <option value="18">18h</option>
                                <option value="19">19h</option>
                                <option value="20">20h</option>
                                <option value="21">21h</option>
                                <option value="22">22h</option>
                                <option value="23">23h</option>
                                <option value="24">24h</option>
                            </select>
                        </div>
                        <div class="form-group traveler-detls">
                            <label for="Adults">Adults</label>
                            <select id="No of stops" class="form-control" v-model="flight.passengerAdult">
                                <option value="" disabled selected hidden>Select Option</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                            </select>
                        </div>
                        <div class="form-group traveler-detls">
                            <label for="Adults">Childs</label>
                            <select id="No of stops" class="form-control" v-model="flight.passengerChild">
                                <option value="" disabled selected hidden>Select Option</option>
                                <option selected="selected" value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                            </select>
                        </div>
                        <div class="form-group traveler-detls">
                            <label for="Infant">Infant</label>
                            <select id="No of stops" class="form-control" v-model="flight.passengerInfant">
                                <option value="" disabled selected hidden>Select Option</option>
                                <option selected="selected" value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                            </select>
                        </div>
                    </div>
                 </div>
                
                <div class="form-row">
                    <div class="d-flex form-lft justify-content-start align-items-center search-form-chkbox my-2">
                        <div class="form-group">
                            <input type="checkbox" style="width: 20px; height: 20px; margin-right: 8px; margin-bottom: 0;" id="NeedBestItinenery" name="NeedBestItinenery" v-model="flight.need_best_itenary" />
                            <label for="NeedBestItinenery">Best Itinenery </label>
                        </div>
                        <div class="form-group" v-if="return_date!=''" >
                            <input  type="checkbox" style="width: 20px; height: 20px; margin-right: 8px; margin-bottom: 0;" id="DoubleOneWay" name="DoubleOneWay" v-model="flight.double_one_way"  />
                            <label  for="vehicle2">Double OneWay</label>
                        </div>
                        <div class="form-group">
                            <input type="checkbox" style="width: 20px; height: 20px; margin-right: 8px; margin-bottom: 0;" id="Avoid us flag" name="Avoid us flag" v-model="flight.avoid_us_flag" />
                            <label for="Avoid us flag">Avoid US</label>
                        </div>
                        <div class="form-group">
                            <input type="checkbox" style="width: 20px; height: 20px; margin-right: 8px; margin-bottom: 0;" id="Include Luggage" name="Include Luggage" v-model="flight.include_luggage" />
                            <label for="Include Luggage">Include Luggage</label>
                        </div>
                        <div class="form-group">
                            <input type="checkbox" style="width: 20px; height: 20px; margin-right: 8px; margin-bottom: 0;" id="Directflight" name="Directflight" v-model="flight.direct_flight" />
                            <label for="Directflight">Direct Flight</label>
                        </div>
                        <div class="form-group">
                            <input type="checkbox" style="width: 20px; height: 20px; margin-right: 8px; margin-bottom: 0;" id="NearbyAirport" name="NearbyAirport" v-model="flight.near_by_airport" />
                            <label for="NearbyAirport">Nearby Airport</label>
                        </div>
                        <div class="form-group">
                            <input type="checkbox" style="width: 20px; height: 20px; margin-right: 8px; margin-bottom: 0;" id="IncludeRail" name="IncludeRail" />
                            <label for="IncludeRail">Include Rail</label>
                        </div>
                        <div class="form-group">
                            <input type="checkbox" style="width: 20px; height: 20px; margin-right: 8px; margin-bottom: 0;" id="Refundable" name="Refundable" v-model="flight.refundable" />
                            <label for="Refundable">Refundable Fare Only</label>
                        </div>
                        <div class="form-group mr-2 p-0">
                            <label for="Refundable">No of Result</label>
                            <input type="text" v-model="flight.no_of_result" class="form-control" placeholder="Enter Number" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <b-form-group class="searchSubmit">
                    <button type="submit" class="btn font-weight-bold text-white font-size-20 btn-primary">Search</button>
                </b-form-group>
            </div>
        </form>
    </div>
</div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import {
    airshop_request
} from "@/utils/request-mockup";
import {
    required,
    minLength,
    maxLength,
    email,
    alpha,
    numeric,
    alphaNum,
    helpers,
    regex,
} from "vuelidate/lib/validators";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import {
    BootstrapVue
} from "bootstrap-vue";
Vue.use(BootstrapVue);
//import airline from "~public/mock/airline.json";
export default {
    name: "FlightSearchForm",
    components: {
        flatPickr,
        Multiselect
    },
    data() {
        return {
            return_date: "",
            flight: {
                trip: "oneway",
                cursector: 1,
                masector: 4,
                cabin_type: ["ECONOMY"],
                additional_cabin_type: "",
                no_of_stops: "",
                no_of_result:"300",
                need_best_itenary: false,
                double_one_way: false,
                avoid_us_flag: false,
                include_luggage: false,
                direct_flight: false,
                near_by_airport: false,
                refundable: false,
                passengerAdult:"1",
                passengerChild:"0",
                passengerInfant:"0",
                MaxmiumTime: " ",
                form: [{
                        origin_airport_code: "MAA",
                        destination_airport_code: "DXB",
                        arrival_date: "2021-10-25",
                        FromTime:" ",
                        TOTime:" ",
                        plus_oneday_option: false,
                    },
                    {
                        origin_airport_code: "",
                        destination_airport_code: "",
                        arrival_date:"",
                        FromTime:" ",
                        TOTime:" ",
                        plus_oneday_option: false,
                    },
                ],
            },
            dateConfig: {
                wrap: true,
            },
            configStartDate: {
                minDate: new Date().getFullYear() +
                    "-" +
                    (new Date().getMonth() + 4) +
                    "-" +
                    (new Date().getDate() - (new Date().getDate() - 1)),
            },
            configEndDate: {
                minDate: new Date().getFullYear() +
                    "-" +
                    (new Date().getMonth() + 5) +
                    "-" +
                    (new Date().getDate() - (new Date().getDate() - 1)),
            },
            max: {
                connection: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24"
                ],
            },

            cabinOption: [
                "ALL",
                "ECONOMY",
                "PREMECONOMY",
                "BUSINESS",
                "FIRST CLASS",

            ]

        };
    },
    validations: {
        flight: {
            form: {
                $each: {
                    origin_airport_code: {
                        required,
                    },
                    destination_airport_code: {
                        required,
                    },
                    arrival_date: {
                        required,
                    },
                },
            },
        },
    },
    methods: {
        isFormValid() {
            //console.log("hello")
            this.$v.$touch();
            if (!this.isValid) {
                return true;
            }
            return false;
        },
        addflight() {
            this.flight.form.push({
                origin_airport_code: "",
                destination_airport_code: "",
                arrival_date:"",
                FromTime:" ",
                TOTime:" ",
                plus_oneday_option: false,
            });
            this.flight.form[this.flight.cursector].origin_airport_code = JSON.parse(
                JSON.stringify(
                    this.flight.form[this.flight.cursector - 1].destination_airport_code
                )
            );
            this.flight.trip = "multi";
            this.return_date="";
            //this.flight.form[this.flight.cursector].arrival_date=this.flight.form[this.flight.cursector-1].arrival_date
            this.flight.cursector = this.flight.cursector + 1;
            console.log(this.flight.cursector);
        },
        remove(index) {
            if (this.flight.cursector == 1) {
                return "";
            }
            this.flight.cursector = this.flight.cursector - 1;
            if (this.flight.cursector == 0) {
                this.flight.trip = "oneway";
            }
            if (this.flight.cursector == 1) {
                this.flight.trip = "return";
            }
            if (this.flight.cursector >= 2) {
                this.$delete(this.flight.form, index);
            }
        },
        onStartChange(selectedDates) {
            this.$set(this.configEndDate, "minDate", selectedDates);
        },
        cabinDisable() {
            var isAllSelected = this.flight.cabin_type.filter(obj => obj == 'ALL');

            if (isAllSelected.length > 0) {
                this.flight.cabin_type = "ALL";
            }
        },
       

        actionSearch: function () {
            // console.log("actionSearch");
            let ndc_url = localStorage.getItem("ndc_api_url") ?
                localStorage.getItem("ndc_api_url") :
                "";
            let ndc_token = localStorage.getItem("ndc_auth_token") ?
                localStorage.getItem("ndc_auth_token") :
                "";
            if (!ndc_url || !ndc_token) {
                this.$toast.open({
                    message: "Please configure credentials settings",
                    type: "error",
                    duration: 3000,
                    dismissible: true,
                    position: "top",
                });
                return false;
            }
            if (this.isFormValid()) {

                var temp = [];
                //this.$store.dispatch("flights/setFlightListVisible", true)
                for (var k = 0; k < this.flight.cursector; k++) {
                    let inputparam_multi = {
                        orgin_aircode: this.flight.form[k].origin_airport_code,
                        dest_aircode: this.flight.form[k].destination_airport_code,
                        arrival_date: this.flight.form[k].arrival_date,
                        fromTime: this.flight.form[k].FromTime,
                        toTime: this.flight.form[k].TOTime,
                        plus_oneday_option: this.flight.form[k].plus_oneday_option,
                        return_date: this.return_date,
                        trip_type: this.flight.trip,
                        //cabin_type: this.flight.cabin_type,
                        no_of_stops: this.flight.no_of_stops,
                        need_best_itenary: this.flight.need_best_itenary,
                        double_one_way: this.flight.double_one_way,
                        avoid_us_flag: this.flight.avoid_us_flag,
                        include_luggage: this.flight.include_luggage,
                        direct_flight: this.flight.direct_flight,
                        near_by_airport: this.flight.near_by_airport,
                        refundable: this.flight.refundable,
                        cabin_type: this.flight.cabin_type,
                        additional_cabin_type: this.flight.additional_cabin_type,
                        MaxmiumTime: this.flight.MaxmiumTime,
                        passengerAdult:this.flight.passengerAdult,
                        passengerChild:this.flight.passengerChild,
                        passengerInfant:this.flight.passengerInfant,
                        no_of_result:this.flight.no_of_result
                        
                    };
                    temp.push(inputparam_multi);
                }
                var inputparam = temp;
                this.$store
                    .dispatch("flights/flightSearchForm", inputparam)
                    .then((error) => {
                        if (error) {
                            let msg = error.Error["Value"];
                            this.$toast.open({
                                message: msg,
                                type: "error",
                                duration: 3000,
                                dismissible: true,
                                position: "top",
                            });
                        }
                    });

                //this.$store.dispatch("common/setAppLoaderstatus",true);
            }
        },
    },
};
</script>
<style>

</style>