<template>
    <div class="flight-search-list-pnl"> 
        <div class="search-result-header d-flex w-100 justify-content-between align-items-center">
            <h3 style="font-size:18px;">Best result</h3>
            <span class="badge badge-info" style="font-size:14px;cursor: pointer;" @click="viewjson">View JSON</span>
        </div>
        
        <div class="product-list-container">
            <div v-for="(item,index) in flightRes" :key="index" class="product-details">
                <div v-for=" (fItem, fIndex) in item.Flights" :key="fIndex">
                    <FlightList :flightdetails="fItem" :showSelBtn="true" :ResponseId="item.shoppingResponseId" :offerid="item.OfferID" :fare="item.TotalPrice.BookingCurrencyPrice" :fIndex="fIndex"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FlightList from "./flight-list.vue";
import $ from 'jquery';

export default {
  name: "FlightResult",
  components: {
    FlightList
  },
  data(){
      return {  
          airshop_request : {},
          airshop_response : {}
      }
  },
  computed : {
      flightRes : function(){
          return this.$store.getters["flights/getDealOffer"];
      }
  },
  methods: {
    viewjson: function() {
        let dataobj = this.$store.getters["flights/getJsonViewser"];
        let jsonmodel = {
            title : "Air Shopping",
            //url : "https://newapi.tltid.com/AirShopping",
             url:this.api_url+"/"+"AirShopping",
            api_req : dataobj.airshop_request,
            api_res : dataobj.airshop_response
        };
        this.$store.dispatch("common/setJsonModel", jsonmodel).then(() => {
            $('#jsonModel').modal('toggle');
        });
    },
    randomNUmber : function(){
        return Math.random().toString(36).substring(2);
    }
  },
  created(){
      let ndc_url = (localStorage.getItem('ndc_api_url')) ? (localStorage.getItem('ndc_api_url')) : "";
      this.api_url = ndc_url;
    },
};
</script>