<template>
<div>
    <div class="row" v-if="Object.keys(flightdetails).length > 0">
        <div class="col-1">
            <div class="d-flex justify-content-center">
                <!--<img :src="'../../assets/images/airline/'+dspAirline.MarketingCarrier.AirlineID+'.png'" style="width:50px;height:50px;" alt=""/>-->
                <img :src="'img/airline/'+dspAirline.MarketingCarrier.AirlineID+'.png'" style="width:50px;height:50px;" alt="" />
            </div>
        </div>
        <div class="col">
            <div class="d-flex justify-content-center align-items-center">
                <div>
                    <p class="mb-0" style="font-weight:bold;">{{formatDate(firstSegment.Departure.Date + 'T' + firstSegment.Departure.Time,'HH:mm')}} - {{formatDate(lastSegment.Arrival.Date + 'T' + lastSegment.Arrival.Time,'HH:mm')}}</p>
                    <p style="font-size:12px;">{{ dspAirline.MarketingCarrier.Name }}</p>
                </div>
            </div>
        </div>
        <div class="col-2">
            <div class="d-flex justify-content-center align-items-center">
                <div>
                    <p class="mb-0" style="font-weight: 400;">{{journey.Time.replace(/H/g, 'h').replace(/M/g, 'min(s)')}}</p>
                    <p style="font-size:12px;font-weight: 400;">{{firstSegment.Departure.AirportCode}}-{{lastSegment.Arrival.AirportCode}}</p>
                </div>
            </div>
        </div>
        <div class="col-2">
            <div class="d-flex justify-content-center align-items-center">
                <div>
                    <p class="mb-0" style="font-weight: 400;" v-if="journey.Stops == 0">Non Stop</p>
                    <p class="mb-0" style="font-weight: 400;" v-else>{{journey.Stops}} Stop</p>
                </div>
            </div>
        </div>
        <div class="col-2" v-if="fIndex == 0">
            <div class="d-flex justify-content-center align-items-center">
                <div class="align-items-center">
                    <p class="mb-0" style="font-weight: 400;">C$ {{ disPrice }}</p>
                </div>
            </div>
        </div>
        <div class="col-2" v-else></div>
        <div class="col-2" v-if="showSelBtn && fIndex == 0">
            <div class="d-flex justify-content-center align-items-center">
                <div class="align-items-center">
                    <a class="badge badge-primary" style="font-size:18px;padding:8px;cursor: pointer;" @click="selectFlights(ResponseId,offerid)">Select Flight</a>
                </div>
            </div>
        </div>
        <div class="col-2" v-else></div>
    </div><br>
</div>
</template>

<script>
import moment from "moment";

export default {
    name: "FlightResult",
    props: ["flightdetails", "showSelBtn", "fare", "fIndex","ResponseId","offerid"],
    watch: {
        $flightdetails: {
            handler() {
                this.renderFlight();
            },
            deep: true,
            immediate: true,
        }
    },
    data() {
        return {
            firstSegment: [],
            lastSegment: [],
            dspAirline: [],
            journey: [],
            disPrice: ""
        }
    },
    methods: {
        formatDate(givenDate, givenFormat) {
            return moment(givenDate).format("hh:mm a");
        },
        renderFlight() {

            /* let {
                    Flights = [],
                    TotalPrice
            } = this.flightdetails; */

            let flight = this.flightdetails;
            let segmentCount = flight.Segments.length;
            let firstSegment = flight.Segments[0];
            let lastSegment = flight.Segments[segmentCount - 1];
            let dspAirline = {
                ...firstSegment
            };

            this.dspAirline = dspAirline;
            this.firstSegment = firstSegment;
            this.lastSegment = lastSegment;
            this.journey = flight.Journey;
            this.disPrice = this.fare;

        },
        selectFlights: function (shoppingResponseId, offerId) {
            let dataobj = {
                "shoppingResponseId": shoppingResponseId,
                "offerId": offerId
            }
            this.$store.commit("flights/SetDealSelected", dataobj);
            //console.log("seleted flights");
            this.$store.dispatch('flights/setFlightResultVisible', false);
        }
    }
};
</script>
