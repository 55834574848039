<template>
  <div class="main_container">
     <Header/>
     <div class="wrapper py-4">
         <div class="container">
             <slot />
         </div>  
     </div>  
     <Footer/>
  </div>  
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: 'DefaultLayout',
  components: {
    Header,
    Footer
  },
  data() {
    return {
    };
  },
};
</script>
