<template>
<div class="booking_list_container">
    <div class="tbl_booking_list">
    <table class="table">
    <thead>
        <tr>
        <th scope="col">PNR</th>
        <th scope="col">Booking Req ID</th>
        <th scope="col">Booking Date</th>
        <th scope="col">Travel Date</th>
        <th scope="col">Status</th>
        <th cope="col" style="text-align:center">Action</th>
        </tr>
    </thead>
    <tbody> 
        <tr  v-for="(item,index) in bookingres" :key="index">
            <td>{{ item.pnr }}</td>
            <td>{{ item.bookingid }}</td>
            <td>{{ item.booking_date }}</td>
            <td>{{ item.traveldate }}</td>
            <td >{{ item.status }}</td>
            <td style="text-align:center">
                <button class="btn btn-primary btn-sm" style="margin-right:10px;" @click="viewbooking(item)">View</button>
                <button class="btn btn-primary btn-sm" style="margin-right:10px;display:none;" @click="cancelbooking(item)">Cancel</button>
                <button class="btn btn-primary btn-sm" style="margin-right:10px;" @click="createticket(item)">Create Ticket</button>
            </td>
        </tr>  
        <tr v-if="bookingres.length == 0">
            <td colspan="6" style="text-align:center">
                <span>No Record Found!</span>
            </td>
        </tr>
    </tbody>
    </table>
    </div>

    <div id="bookingviewModal" class="modal fade" tabindex="-1">
        <div class="modal-dialog  modal-lg"> 
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Booking Detail View</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="view-booking-pnl">
                   <p style="text-align:center;"><span class="badge badge-info" style="font-size: 18px; cursor: pointer; margin-right: 10px;" @click="viewbookingviewjson">View JSON</span></p>
                </div>
            </div>
         </div>
        </div>
    </div>

    <div id="ticketModal" class="modal fade" tabindex="-1">
        <div class="modal-dialog  modal-lg"> 
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Create Ticket</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="view-booking-pnl">
                   <p style="text-align:center;"><span class="badge badge-info" style="font-size: 18px; cursor: pointer; margin-right: 10px;" @click="createticketviewjson">View JSON</span></p>
                </div>
            </div>
         </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from "jquery";
export default {
  name: "BookingList",
  data(){
     return {
         api_url : "",
     } 
  },
  computed: {
    bookingres: function() {
      return this.$store.getters["bookings/getBookingList"];
    }
  },
  methods: {
    viewbooking: function(item) {
      this.$store.dispatch("bookings/retreiveBooking", item).then(res => {
        console.log(res);  
        $("#bookingviewModal").modal("toggle");
      });
    },
    cancelbooking: function(item) {
      
    },
    createticket: function(item) {
      this.$store.dispatch("bookings/createTicket", item).then(res => {
        $("#ticketModal").modal("toggle");
      });
    },
    viewbookingviewjson: function() {
      let dataobj = this.$store.getters["bookings/getJsonViewser"];
      console.log(dataobj.vieworder_response);
      let jsonmodel = {
        title: "Order Retreive",
        //url: "https://newapi.tltid.com/AirOrderRetreive",
        url:this.api_url+"/"+"AirOrderRetreive",
        api_req: dataobj.vieworder_request,
        api_res: dataobj.vieworder_response
      };
      this.$store.dispatch("common/setJsonModel", jsonmodel).then(() => {
        $("#jsonModel").modal("toggle");
      });
    },
    createticketviewjson: function() {
      let dataobj = this.$store.getters["bookings/getJsonViewser"];
      console.log(dataobj.vieworder_response);
      let jsonmodel = {
        title: "Create Ticket",
        //url: "https://newapi.tltid.com/AirDocIssue",
        url:this.api_url+"/"+"AirDocIssue",
        api_req: dataobj.createticket_request,
        api_res: dataobj.createticket_response
      };
      this.$store.dispatch("common/setJsonModel", jsonmodel).then(() => {
        $("#jsonModel").modal("toggle");
      });
    }
  },
  created(){
      let ndc_url = (localStorage.getItem('ndc_api_url')) ? (localStorage.getItem('ndc_api_url')) : "";
      this.api_url = ndc_url;
     
  },
};
</script>
