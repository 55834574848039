<template>
    <header>
        <nav class="navbar navbar-expand-lg p-0 header_navbar flex-wrap">
            <div class="container">
                <div class="d-flex w-100 justify-content-between align-items-center">
                    <a class="navbar-brand" href="/">
                                    <img
                                        src="../assets/images/logo.svg"
                                        alt=""
                                        />
                    </a>
                    <a class="credentials_panel">
                        <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" style="font-size:12px;" type="button" id="credentials_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            CREDENTIALS
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="credentials_dropdown" style="width: 420px;" @click="credentials_pnl_click">
                            <form v-on:submit.prevent="actionSubmit" novalidate>
                             <div class="container" style="display:block;">
                                 <div class="row">
                                    <div class="col-4">API End Point</div>
                                    <div class="col">
                                        <input type="text" class="form-control" v-model="api_url" :class="{ 'is-invalid': $v.api_url.$error }">
                                    </div>  
                                 </div>
                                 <div class="row">
                                    <div class="col-4">Auth Token</div>
                                    <div class="col">
                                        <input type="text" class="form-control" v-model="auth_token" :class="{ 'is-invalid': $v.auth_token.$error }">
                                    </div>  
                                 </div>
                                 <div class="row">
                                     <div class="col d-flex justify-content-center align-items-center">
                                         <button type="submit" class="btn btn-primary">Save</button>
                                     </div>
                                 </div>    
                             </div>
                             </form>
                        </div>
                        </div>
                    </a>    
                </div>
            </div>
        </nav>    
    </header>    
</template>

<script>
import {
  required
} from "vuelidate/lib/validators";
export default {
  name: "Header",
  data(){
     return {
         api_url : "",
         auth_token : ""
     } 
  },
  validations: {
    api_url: {
      required
    },
    auth_token : {
      required
    }
  },
  methods: {
      credentials_pnl_click : function(evt){
          evt.stopPropagation();
          return false;
      },
      actionSubmit : function(){
         this.$v.$touch();
         if (!this.$v.$invalid) {
           localStorage.setItem('ndc_api_url', this.api_url);
           localStorage.setItem('ndc_auth_token', this.auth_token);
           window.location.reload();
         }
      }
  },
  created(){
      let ndc_url = (localStorage.getItem('ndc_api_url')) ? (localStorage.getItem('ndc_api_url')) : "";
      let ndc_token = (localStorage.getItem('ndc_auth_token')) ? (localStorage.getItem('ndc_auth_token')) : "";
      this.api_url = ndc_url;
      this.auth_token = ndc_token;
  },
};
</script>

