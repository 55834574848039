export const airports = [{"airport_iata_code":"AUH", "airport_name":"Abu Dhabi International Airport", "city_name":"Abu Dhabi"},
 {"airport_iata_code":"AZI", "airport_name":"Bateen Airport", "city_name":""},
 {"airport_iata_code":"AAN", "airport_name":"Al Ain International Airport", "city_name":"Al Ain"},
 {"airport_iata_code":"DHF", "airport_name":"Al Dhafra Air Base", "city_name":""},
 {"airport_iata_code":"DXB", "airport_name":"Dubai International Airport", "city_name":"Dubai"},
 {"airport_iata_code":"NHD", "airport_name":"Al Minhad Air Base", "city_name":"Dubai"},
 {"airport_iata_code":"DWC", "airport_name":"Al Maktoum International Airport", "city_name":"Jebel Ali"},
 {"airport_iata_code":"FJR", "airport_name":"Fujairah International Airport", "city_name":""},
 {"airport_iata_code":"RKT", "airport_name":"Ras Al Khaimah International Airport", "city_name":"Ras Al Khaimah"},
 {"airport_iata_code":"SHJ", "airport_name":"Sharjah International Airport", "city_name":"Sharjah"},
 {"airport_iata_code":"OAA", "airport_name":"Shank Air Base", "city_name":""},
 {"airport_iata_code":"BIN", "airport_name":"Bamiyan Airport", "city_name":"Bamiyan"},
 {"airport_iata_code":"BST", "airport_name":"Bost Airport", "city_name":"Bost"},
 {"airport_iata_code":"CCN", "airport_name":"Chakcharan Airport", "city_name":"Chakcharan"},
 {"airport_iata_code":"DAZ", "airport_name":"Darwaz Airport", "city_name":"Darwaz"},
 {"airport_iata_code":"FAH", "airport_name":"Farah Airport", "city_name":"Farah"},
 {"airport_iata_code":"FBD", "airport_name":"Faizabad Airport", "city_name":"Faizabad"},
 {"airport_iata_code":"KWH", "airport_name":"Khwahan Airport", "city_name":"Khwahan"},
 {"airport_iata_code":"HEA", "airport_name":"Herat Airport", "city_name":""},
 {"airport_iata_code":"OAI", "airport_name":"Bagram Air Base", "city_name":"Bagram"},
 {"airport_iata_code":"JAA", "airport_name":"Jalalabad Airport", "city_name":""},
 {"airport_iata_code":"KBL", "airport_name":"Kabul International Airport", "city_name":"Kabul"},
 {"airport_iata_code":"KDH", "airport_name":"Kandahar Airport", "city_name":""},
 {"airport_iata_code":"KHT", "airport_name":"Khost Airport", "city_name":"Khost"},
 {"airport_iata_code":"MMZ", "airport_name":"Maimana Airport", "city_name":""},
 {"airport_iata_code":"MZR", "airport_name":"Mazar I Sharif Airport", "city_name":""},
 {"airport_iata_code":"LQN", "airport_name":"Qala-I-Naw Airport", "city_name":"Qala-I-Naw"},
 {"airport_iata_code":"OAS", "airport_name":"Sharana Airstrip", "city_name":"Sharana"},
 {"airport_iata_code":"OAH", "airport_name":"Shindand Airport", "city_name":""},
 {"airport_iata_code":"SGA", "airport_name":"Sheghnan Airport", "city_name":"Sheghnan"},
 {"airport_iata_code":"TII", "airport_name":"Tarin Kowt Airport", "city_name":"Tarin Kowt"},
 {"airport_iata_code":"UND", "airport_name":"Konduz Airport", "city_name":""},
 {"airport_iata_code":"OAZ", "airport_name":"Camp Bastion Airport", "city_name":""},
 {"airport_iata_code":"ZAJ", "airport_name":"Zaranj Airport", "city_name":"Zaranj"},
 {"airport_iata_code":"OLR", "airport_name":"Salerno Landing Zone Airport", "city_name":""},
 {"airport_iata_code":"DWR", "airport_name":"Dywer Airbase", "city_name":"Camp Dwyer"},
 {"airport_iata_code":"ANU", "airport_name":"V.C. Bird International Airport", "city_name":"St. George"},
 {"airport_iata_code":"BBQ", "airport_name":"Codrington Airport", "city_name":"Codrington"},
 {"airport_iata_code":"AXA", "airport_name":"Wallblake Airport", "city_name":"The Valley"},
 {"airport_iata_code":"TIA", "airport_name":"Tirana International Airport Mother Teresa", "city_name":"Tirana"},
 {"airport_iata_code":"LWN", "airport_name":"Gyumri Shirak Airport", "city_name":"Gyumri"},
 {"airport_iata_code":"EVN", "airport_name":"Zvartnots International Airport", "city_name":"Yerevan"},
 {"airport_iata_code":"ANL", "airport_name":"Andulo Airport", "city_name":"Andulo"},
 {"airport_iata_code":"CNZ", "airport_name":"Cangamba Airport", "city_name":"Cangamba"},
 {"airport_iata_code":"DRC", "airport_name":"Dirico Airport", "city_name":"Dirico"},
 {"airport_iata_code":"GGC", "airport_name":"Lumbala Airport", "city_name":"Lumbala"},
 {"airport_iata_code":"JMB", "airport_name":"Jamba Airport", "city_name":"Jamba"},
 {"airport_iata_code":"KNP", "airport_name":"Capanda Airport", "city_name":"Capanda"},
 {"airport_iata_code":"NDF", "airport_name":"Ndalatandos Airport", "city_name":"Ndalatandos"},
 {"airport_iata_code":"AZZ", "airport_name":"Ambriz Airport", "city_name":"Ambriz"},
 {"airport_iata_code":"SSY", "airport_name":"Mbanza Congo Airport", "city_name":"Mbanza Congo"},
 {"airport_iata_code":"BUG", "airport_name":"Benguela Airport", "city_name":"Benguela"},
 {"airport_iata_code":"CAB", "airport_name":"Cabinda Airport", "city_name":"Cabinda"},
 {"airport_iata_code":"CFF", "airport_name":"Cafunfo Airport", "city_name":"Cafunfo"},
 {"airport_iata_code":"PGI", "airport_name":"Chitato Airport", "city_name":"Chitato"},
 {"airport_iata_code":"CBT", "airport_name":"Catumbela Airport", "city_name":"Catumbela"},
 {"airport_iata_code":"CTI", "airport_name":"Cuito Cuanavale Airport", "city_name":"Cuito Cuanavale"},
 {"airport_iata_code":"CXM", "airport_name":"Camaxilo Airport", "city_name":"Camaxilo"},
 {"airport_iata_code":"CAV", "airport_name":"Cazombo Airport", "city_name":"Cazombo"},
 {"airport_iata_code":"DUE", "airport_name":"Dundo Airport", "city_name":"Chitato"},
 {"airport_iata_code":"VPE", "airport_name":"Ngjiva Pereira Airport", "city_name":"Ngiva"},
 {"airport_iata_code":"NOV", "airport_name":"Nova Lisboa Airport", "city_name":"Huambo"},
 {"airport_iata_code":"SVP", "airport_name":"Kuito Airport", "city_name":"Kuito"},
 {"airport_iata_code":"LAD", "airport_name":"Quatro De Fevereiro Airport", "city_name":"Luanda"},
 {"airport_iata_code":"MEG", "airport_name":"Malanje Airport", "city_name":"Malanje"},
 {"airport_iata_code":"SPP", "airport_name":"Menongue Airport", "city_name":"Menongue"},
 {"airport_iata_code":"MSZ", "airport_name":"Namibe Airport", "city_name":"Namibe"},
 {"airport_iata_code":"GXG", "airport_name":"Negage Airport", "city_name":"Negage"},
 {"airport_iata_code":"PBN", "airport_name":"Porto Amboim Airport", "city_name":"Port Amboim"},
 {"airport_iata_code":"VHC", "airport_name":"Saurimo Airport", "city_name":"Saurimo"},
 {"airport_iata_code":"SZA", "airport_name":"Soyo Airport", "city_name":"Soyo"},
 {"airport_iata_code":"NDD", "airport_name":"Sumbe Airport", "city_name":"Sumbe"},
 {"airport_iata_code":"UAL", "airport_name":"Luau Airport", "city_name":"Luau"},
 {"airport_iata_code":"SDD", "airport_name":"Lubango Airport", "city_name":"Lubango"},
 {"airport_iata_code":"LUO", "airport_name":"Luena Airport", "city_name":"Luena"},
 {"airport_iata_code":"UGO", "airport_name":"Uige Airport", "city_name":"Uige"},
 {"airport_iata_code":"CEO", "airport_name":"Waco Kungo Airport", "city_name":"Waco Kungo"},
 {"airport_iata_code":"XGN", "airport_name":"Xangongo Airport", "city_name":"Xangongo"},
 {"airport_iata_code":"ARZ", "airport_name":"N'zeto Airport", "city_name":"N'zeto"},
 {"airport_iata_code":"TNM", "airport_name":"Teniente Rodolfo Marsh Martin Base", "city_name":"Isla Rey Jorge"},
 {"airport_iata_code":"MJR", "airport_name":"Miramar Airport", "city_name":"Miramar"},
 {"airport_iata_code":"CCT", "airport_name":"Colonia Catriel Airport", "city_name":"Colonia Catriel"},
 {"airport_iata_code":"COC", "airport_name":"Comodoro Pierrestegui Airport", "city_name":"Concordia"},
 {"airport_iata_code":"GHU", "airport_name":"Gualeguaychu Airport", "city_name":"Gualeguaychu"},
 {"airport_iata_code":"JNI", "airport_name":"Junin Airport", "city_name":"Junin"},
 {"airport_iata_code":"PRA", "airport_name":"General Urquiza Airport", "city_name":"Parana"},
 {"airport_iata_code":"ROS", "airport_name":"Islas Malvinas Airport", "city_name":"Rosario"},
 {"airport_iata_code":"SFN", "airport_name":"Sauce Viejo Airport", "city_name":"Santa Fe"},
 {"airport_iata_code":"AEP", "airport_name":"Jorge Newbery Airpark", "city_name":"Buenos Aires"},
 {"airport_iata_code":"LCM", "airport_name":"La Cumbre Airport", "city_name":"La Cumbre"},
 {"airport_iata_code":"COR", "airport_name":"Ingeniero Ambrosio Taravella Airport", "city_name":"Cordoba"},
 {"airport_iata_code":"FDO", "airport_name":"San Fernando Airport", "city_name":"San Fernando"},
 {"airport_iata_code":"LPG", "airport_name":"La Plata Airport", "city_name":"La Plata"},
 {"airport_iata_code":"EPA", "airport_name":"El Palomar Airport", "city_name":"El Palomar"},
 {"airport_iata_code":"EZE", "airport_name":"Ministro Pistarini International Airport", "city_name":"Ezeiza"},
 {"airport_iata_code":"HOS", "airport_name":"Chos Malal Airport", "city_name":"Chos Malal"},
 {"airport_iata_code":"CVH", "airport_name":"Caviahue Airport", "city_name":"Lafontaine"},
 {"airport_iata_code":"GNR", "airport_name":"Dr. Arturo H. Illia Airport", "city_name":"General Roca"},
 {"airport_iata_code":"RDS", "airport_name":"Rincon De Los Sauces Airport", "city_name":"Rincon de los Sauces"},
 {"airport_iata_code":"APZ", "airport_name":"Zapala Airport", "city_name":"Zapala"},
 {"airport_iata_code":"MDZ", "airport_name":"El Plumerillo Airport", "city_name":"Mendoza"},
 {"airport_iata_code":"LGS", "airport_name":"Comodoro D.R. Salom\u00f3n Airport", "city_name":"Malargue"},
 {"airport_iata_code":"AFA", "airport_name":"Suboficial Ay Santiago Germano Airport", "city_name":"San Rafael"},
 {"airport_iata_code":"CTC", "airport_name":"Catamarca Airport", "city_name":"Catamarca"},
 {"airport_iata_code":"SDE", "airport_name":"Vicecomodoro Angel D. La Paz Aragon\u00e9s Airport", "city_name":"Santiago del Estero"},
 {"airport_iata_code":"RHD", "airport_name":"Las Termas Airport", "city_name":"Rio Hondo"},
 {"airport_iata_code":"IRJ", "airport_name":"Capitan V A Almonacid Airport", "city_name":"La Rioja"},
 {"airport_iata_code":"TUC", "airport_name":"Teniente Benjamin Matienzo Airport", "city_name":"San Miguel de Tucuman"},
 {"airport_iata_code":"UAQ", "airport_name":"Domingo Faustino Sarmiento Airport", "city_name":"San Juan"},
 {"airport_iata_code":"CRR", "airport_name":"Ceres Airport", "city_name":"Ceres"},
 {"airport_iata_code":"RCU", "airport_name":"Area De Material Airport", "city_name":"Rio Cuarto"},
 {"airport_iata_code":"VDR", "airport_name":"Villa Dolores Airport", "city_name":"Villa Dolores"},
 {"airport_iata_code":"RLO", "airport_name":"Valle Del Conlara International Airport", "city_name":"Merlo"},
 {"airport_iata_code":"LUQ", "airport_name":"Brigadier Mayor D Cesar Raul Ojeda Airport", "city_name":"San Luis"},
 {"airport_iata_code":"CNQ", "airport_name":"Corrientes Airport", "city_name":"Corrientes"},
 {"airport_iata_code":"RES", "airport_name":"Resistencia International Airport", "city_name":"Resistencia"},
 {"airport_iata_code":"FMA", "airport_name":"Formosa Airport", "city_name":"Formosa"},
 {"airport_iata_code":"IGR", "airport_name":"Cataratas Del Iguaz\u00fa International Airport", "city_name":"Puerto Iguazu"},
 {"airport_iata_code":"AOL", "airport_name":"Paso De Los Libres Airport", "city_name":"Paso de los Libres"},
 {"airport_iata_code":"MCS", "airport_name":"Monte Caseros Airport", "city_name":"Monte Caseros"},
 {"airport_iata_code":"PSS", "airport_name":"Libertador Gral D Jose De San Martin Airport", "city_name":"Posadas"},
 {"airport_iata_code":"SLA", "airport_name":"Martin Miguel De Guemes International Airport", "city_name":"Salta"},
 {"airport_iata_code":"JUJ", "airport_name":"Gobernador Horacio Guzman International Airport", "city_name":"San Salvador de Jujuy"},
 {"airport_iata_code":"ORA", "airport_name":"Or\u00e1n Airport", "city_name":"Or\u00e1n"},
 {"airport_iata_code":"TTG", "airport_name":"General Enrique Mosconi Airport", "city_name":"Tartagal"},
 {"airport_iata_code":"CLX", "airport_name":"Clorinda Airport", "city_name":"Clorinda"},
 {"airport_iata_code":"ELO", "airport_name":"El Dorado Airport", "city_name":"El Dorado"},
 {"airport_iata_code":"OYA", "airport_name":"Goya Airport", "city_name":"Goya"},
 {"airport_iata_code":"LLS", "airport_name":"Alferez Armando Rodriguez Airport", "city_name":"Las Lomitas"},
 {"airport_iata_code":"MDX", "airport_name":"Mercedes Airport", "city_name":"Mercedes"},
 {"airport_iata_code":"RCQ", "airport_name":"Reconquista Airport", "city_name":"Reconquista"},
 {"airport_iata_code":"UZU", "airport_name":"Curuzu Cuatia Airport", "city_name":"Curuzu Cuatia"},
 {"airport_iata_code":"EHL", "airport_name":"El Bolson Airport", "city_name":"El Bolson"},
 {"airport_iata_code":"CRD", "airport_name":"General E. Mosconi Airport", "city_name":"Comodoro Rivadavia"},
 {"airport_iata_code":"EMX", "airport_name":"El Maiten Airport", "city_name":"El Maiten"},
 {"airport_iata_code":"EQS", "airport_name":"Brigadier Antonio Parodi Airport", "city_name":"Esquel"},
 {"airport_iata_code":"LHS", "airport_name":"Las Heras Airport", "city_name":"Las Heras"},
 {"airport_iata_code":"IGB", "airport_name":"Cabo F.A.A. H. R. Bord\u00f3n Airport", "city_name":"Ingeniero Jacobacci"},
 {"airport_iata_code":"OES", "airport_name":"Antoine De St Exupery Airport", "city_name":"San Antonio Oeste"},
 {"airport_iata_code":"MQD", "airport_name":"Maquinchao Airport", "city_name":"Maquinchao"},
 {"airport_iata_code":"ARR", "airport_name":"D. Casimiro Szlapelis Airport", "city_name":"Alto Rio Senguerr"},
 {"airport_iata_code":"SGV", "airport_name":"Sierra Grande Airport", "city_name":"Sierra Grande"},
 {"airport_iata_code":"REL", "airport_name":"Almirante Marco Andres Zar Airport", "city_name":"Rawson"},
 {"airport_iata_code":"VDM", "airport_name":"Gobernador Castello Airport", "city_name":"Viedma / Carmen de Patagones"},
 {"airport_iata_code":"PMY", "airport_name":"El Tehuelche Airport", "city_name":"Puerto Madryn"},
 {"airport_iata_code":"ING", "airport_name":"Lago Argentino Airport", "city_name":"El Calafate"},
 {"airport_iata_code":"FTE", "airport_name":"El Calafate Airport", "city_name":"El Calafate"},
 {"airport_iata_code":"PUD", "airport_name":"Puerto Deseado Airport", "city_name":"Puerto Deseado"},
 {"airport_iata_code":"RGA", "airport_name":"Hermes Quijada International Airport", "city_name":"Rio Grande"},
 {"airport_iata_code":"RGL", "airport_name":"Piloto Civil N. Fern\u00e1ndez Airport", "city_name":"Rio Gallegos"},
 {"airport_iata_code":"USH", "airport_name":"Malvinas Argentinas Airport", "city_name":"Ushuahia"},
 {"airport_iata_code":"ULA", "airport_name":"Capitan D Daniel Vazquez Airport", "city_name":"San Julian"},
 {"airport_iata_code":"ROY", "airport_name":"Rio Mayo Airport", "city_name":"Rio Mayo"},
 {"airport_iata_code":"PMQ", "airport_name":"Perito Moreno Airport", "city_name":"Perito Moreno"},
 {"airport_iata_code":"GGS", "airport_name":"Gobernador Gregores Airport", "city_name":"Gobernador Gregores"},
 {"airport_iata_code":"JSM", "airport_name":"Jose De San Martin Airport", "city_name":"Chubut"},
 {"airport_iata_code":"RYO", "airport_name":"28 De Noviembre Airport", "city_name":"El Turbio"},
 {"airport_iata_code":"RZA", "airport_name":"Santa Cruz Airport", "city_name":"Santa Cruz"},
 {"airport_iata_code":"BHI", "airport_name":"Comandante Espora Airport", "city_name":"Bahia Blanca"},
 {"airport_iata_code":"OVR", "airport_name":"Olavarria Airport", "city_name":"Olavarria"},
 {"airport_iata_code":"GPO", "airport_name":"General Pico Airport", "city_name":"General Pico"},
 {"airport_iata_code":"OYO", "airport_name":"Tres Arroyos Airport", "city_name":"Tres Arroyos"},
 {"airport_iata_code":"SST", "airport_name":"Santa Teresita Airport", "city_name":"Santa Teresita"},
 {"airport_iata_code":"MDQ", "airport_name":"\u00c1stor Piazzola International Airport", "city_name":"Mar del Plata"},
 {"airport_iata_code":"NQN", "airport_name":"Presidente Peron Airport", "city_name":"Neuquen"},
 {"airport_iata_code":"NEC", "airport_name":"Necochea Airport", "city_name":"Necochea"},
 {"airport_iata_code":"PEH", "airport_name":"Comodoro Pedro Zanni Airport", "city_name":"Pehuaj\u00f3"},
 {"airport_iata_code":"RSA", "airport_name":"Santa Rosa Airport", "city_name":"Santa Rosa"},
 {"airport_iata_code":"BRC", "airport_name":"San Carlos De Bariloche Airport", "city_name":"San Carlos de Bariloche"},
 {"airport_iata_code":"TDL", "airport_name":"H\u00e9roes De Malvinas Airport", "city_name":"Tandil"},
 {"airport_iata_code":"VLG", "airport_name":"Villa Gesell Airport", "city_name":"Villa Gesell"},
 {"airport_iata_code":"CUT", "airport_name":"Cutral-Co Airport", "city_name":"Cutral-Co"},
 {"airport_iata_code":"CPC", "airport_name":"Aviador C. Campos Airport", "city_name":"Chapelco/San Martin de los And"},
 {"airport_iata_code":"LCP", "airport_name":"Loncopue Airport", "city_name":"Loncopue"},
 {"airport_iata_code":"QRF", "airport_name":"Bragado Airport", "city_name":"Bragado"},
 {"airport_iata_code":"CSZ", "airport_name":"Brigadier Hector Ruiz Airport", "city_name":"Coronel Suarez"},
 {"airport_iata_code":"CVI", "airport_name":"Caleta Olivia Airport", "city_name":"Caleta Olivia"},
 {"airport_iata_code":"CNT", "airport_name":"Charata Airport", "city_name":"Charata"},
 {"airport_iata_code":"VGS", "airport_name":"General Villegas Airport", "city_name":"General Villegas"},
 {"airport_iata_code":"LMD", "airport_name":"Los Menucos Airport", "city_name":"Los Menucos"},
 {"airport_iata_code":"VCF", "airport_name":"Valcheta Airport", "city_name":"Valcheta"},
 {"airport_iata_code":"VME", "airport_name":"Villa Mercedes Airport", "city_name":"Villa Mercedes"},
 {"airport_iata_code":"NCJ", "airport_name":"Sunchales Aeroclub Airport", "city_name":"Sunchales"},
 {"airport_iata_code":"CPG", "airport_name":"Carmen De Patagones Airport", "city_name":"Carmen de Patagones"},
 {"airport_iata_code":"PRQ", "airport_name":"Termal Airport", "city_name":"Presidencia Roque S\u00e1enz Pe\u00f1a"},
 {"airport_iata_code":"TAV", "airport_name":"Tau Airport", "city_name":"Tau Village"},
 {"airport_iata_code":"OFU", "airport_name":"Ofu Village Airport", "city_name":"Ofu Village"},
 {"airport_iata_code":"FTI", "airport_name":"Fitiuta Airport", "city_name":"Fitiuta Village"},
 {"airport_iata_code":"PPG", "airport_name":"Pago Pago International Airport", "city_name":"Pago Pago"},
 {"airport_iata_code":"HOH", "airport_name":"Hohenems-Dornbirn Airport", "city_name":"Hohenems / Dornbirn"},
 {"airport_iata_code":"GRZ", "airport_name":"Graz Airport", "city_name":"Graz"},
 {"airport_iata_code":"INN", "airport_name":"Innsbruck Airport", "city_name":"Innsbruck"},
 {"airport_iata_code":"KLU", "airport_name":"Klagenfurt Airport", "city_name":"Klagenfurt am W\u00f6rthersee"},
 {"airport_iata_code":"LNZ", "airport_name":"Linz Airport", "city_name":"Linz"},
 {"airport_iata_code":"SZG", "airport_name":"Salzburg Airport", "city_name":"Salzburg"},
 {"airport_iata_code":"VIE", "airport_name":"Vienna International Airport", "city_name":"Vienna"},
 {"airport_iata_code":"KFE", "airport_name":"Fortescue - Dave Forrest Aerodrome", "city_name":"Cloudbreak Village"},
 {"airport_iata_code":"BCZ", "airport_name":"Milyakburra Airport", "city_name":"Bickerton Island"},
 {"airport_iata_code":"AGW", "airport_name":"Agnew Airport", "city_name":"Agnew"},
 {"airport_iata_code":"AYD", "airport_name":"Alroy Downs Airport", "city_name":"Alroy Downs"},
 {"airport_iata_code":"BCK", "airport_name":"Bolwarra Airport", "city_name":"Bolwarra"},
 {"airport_iata_code":"BFC", "airport_name":"Bloomfield Airport", "city_name":"Bloomfield"},
 {"airport_iata_code":"BVW", "airport_name":"Batavia Downs Airport", "city_name":"Batavia Downs"},
 {"airport_iata_code":"BYX", "airport_name":"Baniyala Airport", "city_name":"Baniyala"},
 {"airport_iata_code":"COB", "airport_name":"Coolibah Airport", "city_name":"Coolibah"},
 {"airport_iata_code":"CQP", "airport_name":"Cape Flattery Airport", "city_name":"Cape Flattery"},
 {"airport_iata_code":"CRJ", "airport_name":"Coorabie Airport", "city_name":"Coorabie"},
 {"airport_iata_code":"CRY", "airport_name":"Carlton Hill Airport", "city_name":"Carlton Hill"},
 {"airport_iata_code":"CSD", "airport_name":"Cresswell Downs Airport", "city_name":"Cresswell Downs"},
 {"airport_iata_code":"CTR", "airport_name":"Cattle Creek Airport", "city_name":"Cattle Creek"},
 {"airport_iata_code":"DYM", "airport_name":"Diamantina Lakes Airport", "city_name":"Diamantina Lakes"},
 {"airport_iata_code":"EDD", "airport_name":"Erldunda Airport", "city_name":"Erldunda"},
 {"airport_iata_code":"EKD", "airport_name":"Elkedra Airport", "city_name":"Elkedra"},
 {"airport_iata_code":"HAT", "airport_name":"Heathlands Airport", "city_name":"Heathlands"},
 {"airport_iata_code":"HIG", "airport_name":"Highbury Airport", "city_name":"Highbury"},
 {"airport_iata_code":"HLV", "airport_name":"Helenvale Airport", "city_name":"Helenvale"},
 {"airport_iata_code":"KBD", "airport_name":"Kimberley Downs Airport", "city_name":"Kimberley Downs"},
 {"airport_iata_code":"KGR", "airport_name":"Kulgera Airport", "city_name":"Kulgera"},
 {"airport_iata_code":"KOH", "airport_name":"Koolatah Airport", "city_name":"Koolatah"},
 {"airport_iata_code":"KYF", "airport_name":"Yeelirrie Airport", "city_name":"Yeelirrie"},
 {"airport_iata_code":"LIB", "airport_name":"Limbunya Airport", "city_name":"Limbunya"},
 {"airport_iata_code":"MNW", "airport_name":"Macdonald Downs Airport", "city_name":"Macdonald Downs"},
 {"airport_iata_code":"MUP", "airport_name":"Mulga Park Airport", "city_name":"Mulga Park"},
 {"airport_iata_code":"MWY", "airport_name":"Miranda Downs Airport", "city_name":"Miranda Downs"},
 {"airport_iata_code":"MYO", "airport_name":"Camballin Airport", "city_name":"Myroodah"},
 {"airport_iata_code":"NKB", "airport_name":"Noonkanbah Airport", "city_name":"Noonkanbah"},
 {"airport_iata_code":"OKB", "airport_name":"Orchid Beach Airport", "city_name":"Orchid Beach"},
 {"airport_iata_code":"PEP", "airport_name":"Peppimenarti Airport", "city_name":"Peppimenarti"},
 {"airport_iata_code":"RDA", "airport_name":"Rockhampton Downs Airport", "city_name":"Rockhampton Downs"},
 {"airport_iata_code":"SSK", "airport_name":"Sturt Creek Airport", "city_name":"Sturt Creek"},
 {"airport_iata_code":"SWB", "airport_name":"Shaw River Airport", "city_name":"Shaw River"},
 {"airport_iata_code":"TPR", "airport_name":"Tom Price Airport", "city_name":"Tom Price"},
 {"airport_iata_code":"TWP", "airport_name":"Torwood Airport", "city_name":"Torwood"},
 {"airport_iata_code":"WRW", "airport_name":"Warrawagine Airport", "city_name":"Warrawagine"},
 {"airport_iata_code":"WSY", "airport_name":"Whitsunday Airstrip", "city_name":"Airlie Beach"},
 {"airport_iata_code":"ZVG", "airport_name":"Springvale Airport", "city_name":"Springvale"},
 {"airport_iata_code":"BHT", "airport_name":"Brighton Downs Airport", "city_name":""},
 {"airport_iata_code":"CBC", "airport_name":"Cherrabun Airport", "city_name":""},
 {"airport_iata_code":"ALH", "airport_name":"Albany Airport", "city_name":"Albany"},
 {"airport_iata_code":"ABG", "airport_name":"Abingdon Downs Airport", "city_name":""},
 {"airport_iata_code":"AWN", "airport_name":"Alton Downs Airport", "city_name":""},
 {"airport_iata_code":"AUD", "airport_name":"Augustus Downs Airport", "city_name":""},
 {"airport_iata_code":"MRP", "airport_name":"Marla Airport", "city_name":""},
 {"airport_iata_code":"AXL", "airport_name":"Alexandria Homestead Airport", "city_name":""},
 {"airport_iata_code":"AXC", "airport_name":"Aramac Airport", "city_name":""},
 {"airport_iata_code":"ADO", "airport_name":"Andamooka Airport", "city_name":""},
 {"airport_iata_code":"AMX", "airport_name":"Ammaroo Airport", "city_name":""},
 {"airport_iata_code":"AMT", "airport_name":"Amata Airport", "city_name":""},
 {"airport_iata_code":"AYL", "airport_name":"Anthony Lagoon Airport", "city_name":""},
 {"airport_iata_code":"ABH", "airport_name":"Alpha Airport", "city_name":""},
 {"airport_iata_code":"ARY", "airport_name":"Ararat Airport", "city_name":""},
 {"airport_iata_code":"GYL", "airport_name":"Argyle Airport", "city_name":""},
 {"airport_iata_code":"ARM", "airport_name":"Armidale Airport", "city_name":"Armidale"},
 {"airport_iata_code":"AAB", "airport_name":"Arrabury Airport", "city_name":""},
 {"airport_iata_code":"AUU", "airport_name":"Aurukun Airport", "city_name":""},
 {"airport_iata_code":"AWP", "airport_name":"Austral Downs Airport", "city_name":""},
 {"airport_iata_code":"AVG", "airport_name":"Auvergne Airport", "city_name":""},
 {"airport_iata_code":"AYQ", "airport_name":"Ayers Rock Connellan Airport", "city_name":"Ayers Rock"},
 {"airport_iata_code":"AYR", "airport_name":"Ayr Airport", "city_name":""},
 {"airport_iata_code":"ACF", "airport_name":"Brisbane Archerfield Airport", "city_name":"Brisbane"},
 {"airport_iata_code":"ABM", "airport_name":"Bamaga Injinoo Airport", "city_name":""},
 {"airport_iata_code":"BCI", "airport_name":"Barcaldine Airport", "city_name":"Barcaldine"},
 {"airport_iata_code":"ASP", "airport_name":"Alice Springs Airport", "city_name":"Alice Springs"},
 {"airport_iata_code":"BDD", "airport_name":"Badu Island Airport", "city_name":""},
 {"airport_iata_code":"BKP", "airport_name":"Barkly Downs Airport", "city_name":""},
 {"airport_iata_code":"BBE", "airport_name":"Big Bell Airport", "city_name":"Big Bell"},
 {"airport_iata_code":"BNE", "airport_name":"Brisbane International Airport", "city_name":"Brisbane"},
 {"airport_iata_code":"OOL", "airport_name":"Gold Coast Airport", "city_name":"Gold Coast"},
 {"airport_iata_code":"BKQ", "airport_name":"Blackall Airport", "city_name":"Blackall"},
 {"airport_iata_code":"CNS", "airport_name":"Cairns International Airport", "city_name":"Cairns"},
 {"airport_iata_code":"CTL", "airport_name":"Charleville Airport", "city_name":"Charleville"},
 {"airport_iata_code":"BDW", "airport_name":"Bedford Downs Airport", "city_name":""},
 {"airport_iata_code":"BXG", "airport_name":"Bendigo Airport", "city_name":""},
 {"airport_iata_code":"BVI", "airport_name":"Birdsville Airport", "city_name":""},
 {"airport_iata_code":"BTX", "airport_name":"Betoota Airport", "city_name":""},
 {"airport_iata_code":"BQW", "airport_name":"Balgo Hill Airport", "city_name":""},
 {"airport_iata_code":"BHQ", "airport_name":"Broken Hill Airport", "city_name":"Broken Hill"},
 {"airport_iata_code":"HTI", "airport_name":"Hamilton Island Airport", "city_name":"Hamilton Island"},
 {"airport_iata_code":"BEU", "airport_name":"Bedourie Airport", "city_name":""},
 {"airport_iata_code":"BIW", "airport_name":"Billiluna Airport", "city_name":""},
 {"airport_iata_code":"BZP", "airport_name":"Bizant Airport", "city_name":"Lakefield National Park"},
 {"airport_iata_code":"BRK", "airport_name":"Bourke Airport", "city_name":""},
 {"airport_iata_code":"BUC", "airport_name":"Burketown Airport", "city_name":""},
 {"airport_iata_code":"BLN", "airport_name":"Benalla Airport", "city_name":""},
 {"airport_iata_code":"LCN", "airport_name":"Balcanoona Airport", "city_name":""},
 {"airport_iata_code":"BLS", "airport_name":"Bollon Airport", "city_name":""},
 {"airport_iata_code":"BQB", "airport_name":"Busselton Regional Airport", "city_name":""},
 {"airport_iata_code":"ISA", "airport_name":"Mount Isa Airport", "city_name":"Mount Isa"},
 {"airport_iata_code":"MCY", "airport_name":"Sunshine Coast Airport", "city_name":"Maroochydore"},
 {"airport_iata_code":"MKY", "airport_name":"Mackay Airport", "city_name":"Mackay"},
 {"airport_iata_code":"BNK", "airport_name":"Ballina Byron Gateway Airport", "city_name":"Ballina"},
 {"airport_iata_code":"BSJ", "airport_name":"Bairnsdale Airport", "city_name":""},
 {"airport_iata_code":"GIC", "airport_name":"Boigu Airport", "city_name":""},
 {"airport_iata_code":"OKY", "airport_name":"Oakey Airport", "city_name":""},
 {"airport_iata_code":"BQL", "airport_name":"Boulia Airport", "city_name":""},
 {"airport_iata_code":"BMP", "airport_name":"Brampton Island Airport", "city_name":""},
 {"airport_iata_code":"PPP", "airport_name":"Proserpine Whitsunday Coast Airport", "city_name":"Proserpine"},
 {"airport_iata_code":"ROK", "airport_name":"Rockhampton Airport", "city_name":"Rockhampton"},
 {"airport_iata_code":"BOX", "airport_name":"Borroloola Airport", "city_name":""},
 {"airport_iata_code":"BME", "airport_name":"Broome International Airport", "city_name":"Broome"},
 {"airport_iata_code":"BZD", "airport_name":"Balranald Airport", "city_name":""},
 {"airport_iata_code":"BTD", "airport_name":"Brunette Downs Airport", "city_name":""},
 {"airport_iata_code":"BWQ", "airport_name":"Brewarrina Airport", "city_name":""},
 {"airport_iata_code":"BHS", "airport_name":"Bathurst Airport", "city_name":"Bathurst"},
 {"airport_iata_code":"BRT", "airport_name":"Bathurst Island Airport", "city_name":""},
 {"airport_iata_code":"TSV", "airport_name":"Townsville Airport", "city_name":"Townsville"},
 {"airport_iata_code":"BLT", "airport_name":"Blackwater Airport", "city_name":""},
 {"airport_iata_code":"BDB", "airport_name":"Bundaberg Airport", "city_name":"Bundaberg"},
 {"airport_iata_code":"BUY", "airport_name":"Bunbury Airport", "city_name":""},
 {"airport_iata_code":"BIP", "airport_name":"Bulimba Airport", "city_name":""},
 {"airport_iata_code":"ZBO", "airport_name":"Bowen Airport", "city_name":""},
 {"airport_iata_code":"WEI", "airport_name":"Weipa Airport", "city_name":"Weipa"},
 {"airport_iata_code":"BWB", "airport_name":"Barrow Island Airport", "city_name":""},
 {"airport_iata_code":"BVZ", "airport_name":"Beverley Springs Airport", "city_name":""},
 {"airport_iata_code":"CGV", "airport_name":"Caiguna Airport", "city_name":""},
 {"airport_iata_code":"CLH", "airport_name":"Coolah Airport", "city_name":""},
 {"airport_iata_code":"CVQ", "airport_name":"Carnarvon Airport", "city_name":""},
 {"airport_iata_code":"CSI", "airport_name":"Casino Airport", "city_name":""},
 {"airport_iata_code":"CAZ", "airport_name":"Cobar Airport", "city_name":""},
 {"airport_iata_code":"COJ", "airport_name":"Coonabarabran Airport", "city_name":""},
 {"airport_iata_code":"CBY", "airport_name":"Canobie Airport", "city_name":"Canobie"},
 {"airport_iata_code":"CBI", "airport_name":"Cape Barren Island Airport", "city_name":""},
 {"airport_iata_code":"CPD", "airport_name":"Coober Pedy Airport", "city_name":""},
 {"airport_iata_code":"CRB", "airport_name":"Collarenebri Airport", "city_name":""},
 {"airport_iata_code":"CCL", "airport_name":"Chinchilla Airport", "city_name":""},
 {"airport_iata_code":"CNC", "airport_name":"Coconut Island Airport", "city_name":""},
 {"airport_iata_code":"CNJ", "airport_name":"Cloncurry Airport", "city_name":"Cloncurry"},
 {"airport_iata_code":"CBX", "airport_name":"Condobolin Airport", "city_name":""},
 {"airport_iata_code":"CUD", "airport_name":"Caloundra Airport", "city_name":""},
 {"airport_iata_code":"CED", "airport_name":"Ceduna Airport", "city_name":""},
 {"airport_iata_code":"CVC", "airport_name":"Cleve Airport", "city_name":""},
 {"airport_iata_code":"CFI", "airport_name":"Camfield Airport", "city_name":""},
 {"airport_iata_code":"LLG", "airport_name":"Chillagoe Airport", "city_name":""},
 {"airport_iata_code":"CXT", "airport_name":"Charters Towers Airport", "city_name":""},
 {"airport_iata_code":"DCN", "airport_name":"RAAF Base Curtin", "city_name":""},
 {"airport_iata_code":"CKI", "airport_name":"Croker Island Airport", "city_name":""},
 {"airport_iata_code":"CTN", "airport_name":"Cooktown Airport", "city_name":""},
 {"airport_iata_code":"CMQ", "airport_name":"Clermont Airport", "city_name":""},
 {"airport_iata_code":"CMA", "airport_name":"Cunnamulla Airport", "city_name":""},
 {"airport_iata_code":"CML", "airport_name":"Camooweal Airport", "city_name":""},
 {"airport_iata_code":"NIF", "airport_name":"Camp Nifty Airport", "city_name":""},
 {"airport_iata_code":"CES", "airport_name":"Cessnock Airport", "city_name":""},
 {"airport_iata_code":"CNB", "airport_name":"Coonamble Airport", "city_name":""},
 {"airport_iata_code":"ODL", "airport_name":"Cordillo Downs Airport", "city_name":"Cordillo Downs"},
 {"airport_iata_code":"CUQ", "airport_name":"Coen Airport", "city_name":""},
 {"airport_iata_code":"OOM", "airport_name":"Cooma Snowy Mountains Airport", "city_name":"Cooma"},
 {"airport_iata_code":"CDA", "airport_name":"Cooinda Airport", "city_name":""},
 {"airport_iata_code":"CWW", "airport_name":"Corowa Airport", "city_name":""},
 {"airport_iata_code":"CYG", "airport_name":"Corryong Airport", "city_name":""},
 {"airport_iata_code":"CDQ", "airport_name":"Croydon Airport", "city_name":""},
 {"airport_iata_code":"KCE", "airport_name":"Collinsville Airport", "city_name":""},
 {"airport_iata_code":"CMD", "airport_name":"Cootamundra Airport", "city_name":""},
 {"airport_iata_code":"CUG", "airport_name":"Cudal Airport", "city_name":""},
 {"airport_iata_code":"CUY", "airport_name":"Cue Airport", "city_name":""},
 {"airport_iata_code":"CWR", "airport_name":"Cowarie Airport", "city_name":""},
 {"airport_iata_code":"CCW", "airport_name":"Cowell Airport", "city_name":""},
 {"airport_iata_code":"CWT", "airport_name":"Cowra Airport", "city_name":""},
 {"airport_iata_code":"DJR", "airport_name":"Dajarra Airport", "city_name":""},
 {"airport_iata_code":"DBY", "airport_name":"Dalby Airport", "city_name":""},
 {"airport_iata_code":"DRN", "airport_name":"Dirranbandi Airport", "city_name":""},
 {"airport_iata_code":"DNB", "airport_name":"Dunbar Airport", "city_name":""},
 {"airport_iata_code":"DRB", "airport_name":"Derby Airport", "city_name":""},
 {"airport_iata_code":"DFP", "airport_name":"Drumduff Airport", "city_name":"Drumduff"},
 {"airport_iata_code":"DGD", "airport_name":"Dalgaranga Gold Mine Airport", "city_name":""},
 {"airport_iata_code":"DXD", "airport_name":"Dixie Airport", "city_name":"New Dixie"},
 {"airport_iata_code":"DLK", "airport_name":"Dulkaninna Airport", "city_name":"Dulkaninna"},
 {"airport_iata_code":"DDN", "airport_name":"Delta Downs Airport", "city_name":""},
 {"airport_iata_code":"DLV", "airport_name":"Delissaville Airport", "city_name":""},
 {"airport_iata_code":"DYW", "airport_name":"Daly Waters Airport", "city_name":"Daly Waters"},
 {"airport_iata_code":"DMD", "airport_name":"Doomadgee Airport", "city_name":""},
 {"airport_iata_code":"DVR", "airport_name":"Daly River Airport", "city_name":""},
 {"airport_iata_code":"NLF", "airport_name":"Darnley Island Airport", "city_name":"Darnley Island"},
 {"airport_iata_code":"DRD", "airport_name":"Dorunda Airport", "city_name":""},
 {"airport_iata_code":"DVP", "airport_name":"Davenport Downs Airport", "city_name":""},
 {"airport_iata_code":"DPO", "airport_name":"Devonport Airport", "city_name":"Devonport"},
 {"airport_iata_code":"DOX", "airport_name":"Dongara Airport", "city_name":""},
 {"airport_iata_code":"DRY", "airport_name":"Drysdale River Airport", "city_name":""},
 {"airport_iata_code":"DHD", "airport_name":"Durham Downs Airport", "city_name":""},
 {"airport_iata_code":"DRR", "airport_name":"Durrie Airport", "city_name":""},
 {"airport_iata_code":"DKV", "airport_name":"Docker River Airport", "city_name":""},
 {"airport_iata_code":"DYA", "airport_name":"Dysart Airport", "city_name":""},
 {"airport_iata_code":"ECH", "airport_name":"Echuca Airport", "city_name":""},
 {"airport_iata_code":"EUC", "airport_name":"Eucla Airport", "city_name":""},
 {"airport_iata_code":"ETD", "airport_name":"Etadunna Airport", "city_name":"Etadunna"},
 {"airport_iata_code":"ENB", "airport_name":"Eneabba Airport", "city_name":"Eneabba"},
 {"airport_iata_code":"EIH", "airport_name":"Einasleigh Airport", "city_name":"Einasleigh"},
 {"airport_iata_code":"ELC", "airport_name":"Elcho Island Airport", "city_name":"Elcho Island"},
 {"airport_iata_code":"EMD", "airport_name":"Emerald Airport", "city_name":"Emerald"},
 {"airport_iata_code":"ERB", "airport_name":"Ernabella Airport", "city_name":""},
 {"airport_iata_code":"EPR", "airport_name":"Esperance Airport", "city_name":""},
 {"airport_iata_code":"EVD", "airport_name":"Eva Downs Airport", "city_name":"Eva Downs"},
 {"airport_iata_code":"EVH", "airport_name":"Evans Head Aerodrome", "city_name":""},
 {"airport_iata_code":"EXM", "airport_name":"Exmouth Airport", "city_name":""},
 {"airport_iata_code":"FRB", "airport_name":"Forbes Airport", "city_name":"Forbes,"},
 {"airport_iata_code":"FLY", "airport_name":"Finley Airport", "city_name":""},
 {"airport_iata_code":"FLS", "airport_name":"Flinders Island Airport", "city_name":""},
 {"airport_iata_code":"FVL", "airport_name":"Flora Valley Airport", "city_name":""},
 {"airport_iata_code":"FIK", "airport_name":"Finke Airport", "city_name":"Finke"},
 {"airport_iata_code":"FOS", "airport_name":"Forrest Airport", "city_name":""},
 {"airport_iata_code":"FOT", "airport_name":"Forster (Wallis Is) Airport", "city_name":""},
 {"airport_iata_code":"FIZ", "airport_name":"Fitzroy Crossing Airport", "city_name":""},
 {"airport_iata_code":"GAH", "airport_name":"Gayndah Airport", "city_name":""},
 {"airport_iata_code":"GBL", "airport_name":"South Goulburn Is Airport", "city_name":""},
 {"airport_iata_code":"GUH", "airport_name":"Gunnedah Airport", "city_name":""},
 {"airport_iata_code":"GOO", "airport_name":"Goondiwindi Airport", "city_name":""},
 {"airport_iata_code":"GDD", "airport_name":"Gordon Downs Airport", "city_name":"Gordon Downs"},
 {"airport_iata_code":"GGD", "airport_name":"Gregory Downs Airport", "city_name":""},
 {"airport_iata_code":"GTS", "airport_name":"Granite Downs Airport", "city_name":""},
 {"airport_iata_code":"GET", "airport_name":"Geraldton Airport", "city_name":""},
 {"airport_iata_code":"GFN", "airport_name":"Grafton Airport", "city_name":""},
 {"airport_iata_code":"GBV", "airport_name":"Gibb River Airport", "city_name":""},
 {"airport_iata_code":"GLT", "airport_name":"Gladstone Airport", "city_name":"Gladstone"},
 {"airport_iata_code":"GUL", "airport_name":"Goulburn Airport", "city_name":""},
 {"airport_iata_code":"GLG", "airport_name":"Glengyle Airport", "city_name":""},
 {"airport_iata_code":"GEX", "airport_name":"Geelong Airport", "city_name":""},
 {"airport_iata_code":"GLI", "airport_name":"Glen Innes Airport", "city_name":""},
 {"airport_iata_code":"GLM", "airport_name":"Glenormiston Airport", "city_name":""},
 {"airport_iata_code":"GVP", "airport_name":"Greenvale Airport", "city_name":""},
 {"airport_iata_code":"GPN", "airport_name":"Garden Point Airport", "city_name":""},
 {"airport_iata_code":"GTE", "airport_name":"Groote Eylandt Airport", "city_name":"Groote Eylandt"},
 {"airport_iata_code":"GFF", "airport_name":"Griffith Airport", "city_name":"Griffith"},
 {"airport_iata_code":"GTT", "airport_name":"Georgetown Airport", "city_name":""},
 {"airport_iata_code":"GEE", "airport_name":"Georgetown (Tas) Airport", "city_name":""},
 {"airport_iata_code":"GYP", "airport_name":"Gympie Airport", "city_name":""},
 {"airport_iata_code":"HWK", "airport_name":"Wilpena Pound Airport", "city_name":"Hawker"},
 {"airport_iata_code":"HXX", "airport_name":"Hay Airport", "city_name":""},
 {"airport_iata_code":"HVB", "airport_name":"Hervey Bay Airport", "city_name":"Hervey Bay"},
 {"airport_iata_code":"HUB", "airport_name":"Humbert River Airport", "city_name":""},
 {"airport_iata_code":"HIP", "airport_name":"Headingly Airport", "city_name":""},
 {"airport_iata_code":"HID", "airport_name":"Horn Island Airport", "city_name":"Horn Island"},
 {"airport_iata_code":"HCQ", "airport_name":"Halls Creek Airport", "city_name":""},
 {"airport_iata_code":"HMG", "airport_name":"Hermannsburg Airport", "city_name":""},
 {"airport_iata_code":"HLT", "airport_name":"Hamilton Airport", "city_name":""},
 {"airport_iata_code":"HOK", "airport_name":"Hooker Creek Airport", "city_name":""},
 {"airport_iata_code":"MHU", "airport_name":"Mount Hotham Airport", "city_name":"Mount Hotham"},
 {"airport_iata_code":"HTU", "airport_name":"Hopetoun Airport", "city_name":""},
 {"airport_iata_code":"HSM", "airport_name":"Horsham Airport", "city_name":""},
 {"airport_iata_code":"HGD", "airport_name":"Hughenden Airport", "city_name":""},
 {"airport_iata_code":"IDK", "airport_name":"Indulkana Airport", "city_name":""},
 {"airport_iata_code":"IFL", "airport_name":"Innisfail Airport", "city_name":""},
 {"airport_iata_code":"IFF", "airport_name":"Iffley Airport", "city_name":""},
 {"airport_iata_code":"IGH", "airport_name":"Ingham Airport", "city_name":""},
 {"airport_iata_code":"IKP", "airport_name":"Inkerman Airport", "city_name":""},
 {"airport_iata_code":"INJ", "airport_name":"Injune Airport", "city_name":""},
 {"airport_iata_code":"INM", "airport_name":"Innamincka Airport", "city_name":""},
 {"airport_iata_code":"IVW", "airport_name":"Inverway Airport", "city_name":"Inverway"},
 {"airport_iata_code":"ISI", "airport_name":"Isisford Airport", "city_name":""},
 {"airport_iata_code":"IVR", "airport_name":"Inverell Airport", "city_name":""},
 {"airport_iata_code":"JAB", "airport_name":"Jabiru Airport", "city_name":""},
 {"airport_iata_code":"JUN", "airport_name":"Jundah Airport", "city_name":""},
 {"airport_iata_code":"QJD", "airport_name":"Jindabyne Airport", "city_name":""},
 {"airport_iata_code":"JCK", "airport_name":"Julia Creek Airport", "city_name":""},
 {"airport_iata_code":"JUR", "airport_name":"Jurien Bay Airport", "city_name":""},
 {"airport_iata_code":"UBU", "airport_name":"Kalumburu Airport", "city_name":""},
 {"airport_iata_code":"KAX", "airport_name":"Kalbarri Airport", "city_name":""},
 {"airport_iata_code":"KBY", "airport_name":"Streaky Bay Airport", "city_name":""},
 {"airport_iata_code":"KCS", "airport_name":"Kings Creek Airport", "city_name":""},
 {"airport_iata_code":"KRA", "airport_name":"Kerang Airport", "city_name":""},
 {"airport_iata_code":"KNS", "airport_name":"King Island Airport", "city_name":""},
 {"airport_iata_code":"KBB", "airport_name":"Kirkimbie Station Airport", "city_name":"Kirkimbie"},
 {"airport_iata_code":"KFG", "airport_name":"Kalkgurung Airport", "city_name":""},
 {"airport_iata_code":"KKP", "airport_name":"Koolburra Airport", "city_name":"Koolburra"},
 {"airport_iata_code":"KRB", "airport_name":"Karumba Airport", "city_name":""},
 {"airport_iata_code":"KML", "airport_name":"Kamileroi Airport", "city_name":""},
 {"airport_iata_code":"KPS", "airport_name":"Kempsey Airport", "city_name":""},
 {"airport_iata_code":"KNI", "airport_name":"Katanning Airport", "city_name":""},
 {"airport_iata_code":"KWM", "airport_name":"Kowanyama Airport", "city_name":"Kowanyama"},
 {"airport_iata_code":"KGY", "airport_name":"Kingaroy Airport", "city_name":""},
 {"airport_iata_code":"KGC", "airport_name":"Kingscote Airport", "city_name":""},
 {"airport_iata_code":"KUG", "airport_name":"Kubin Airport", "city_name":""},
 {"airport_iata_code":"LWH", "airport_name":"Lawn Hill Airport", "city_name":""},
 {"airport_iata_code":"LGH", "airport_name":"Leigh Creek Airport", "city_name":""},
 {"airport_iata_code":"LNO", "airport_name":"Leonora Airport", "city_name":"Leonora"},
 {"airport_iata_code":"LEL", "airport_name":"Lake Evella Airport", "city_name":""},
 {"airport_iata_code":"LDH", "airport_name":"Lord Howe Island Airport", "city_name":"Lord Howe Island"},
 {"airport_iata_code":"IRG", "airport_name":"Lockhart River Airport", "city_name":""},
 {"airport_iata_code":"LTP", "airport_name":"Lyndhurst Airport", "city_name":"Lyndhurst"},
 {"airport_iata_code":"LDC", "airport_name":"Lindeman Island Airport", "city_name":"Lindeman Island"},
 {"airport_iata_code":"LSY", "airport_name":"Lismore Airport", "city_name":"Lismore"},
 {"airport_iata_code":"LNH", "airport_name":"Lake Nash Airport", "city_name":"Alpurrurulam"},
 {"airport_iata_code":"LOC", "airport_name":"Lock Airport", "city_name":"Lock"},
 {"airport_iata_code":"LOA", "airport_name":"Lorraine Airport", "city_name":""},
 {"airport_iata_code":"LTV", "airport_name":"Lotus Vale Airport", "city_name":"Lotus Vale"},
 {"airport_iata_code":"LUU", "airport_name":"Laura Airport", "city_name":""},
 {"airport_iata_code":"LHG", "airport_name":"Lightning Ridge Airport", "city_name":""},
 {"airport_iata_code":"LRE", "airport_name":"Longreach Airport", "city_name":"Longreach"},
 {"airport_iata_code":"LUT", "airport_name":"New Laura Airport", "city_name":""},
 {"airport_iata_code":"LER", "airport_name":"Leinster Airport", "city_name":""},
 {"airport_iata_code":"LVO", "airport_name":"Laverton Airport", "city_name":""},
 {"airport_iata_code":"TGN", "airport_name":"Latrobe Valley Airport", "city_name":""},
 {"airport_iata_code":"LZR", "airport_name":"Lizard Island Airport", "city_name":""},
 {"airport_iata_code":"UBB", "airport_name":"Mabuiag Island Airport", "city_name":"Mabuiag Island"},
 {"airport_iata_code":"AVV", "airport_name":"Avalon Airport", "city_name":"Melbourne"},
 {"airport_iata_code":"ABX", "airport_name":"Albury Airport", "city_name":"Albury"},
 {"airport_iata_code":"MRG", "airport_name":"Mareeba Airport", "city_name":""},
 {"airport_iata_code":"MBB", "airport_name":"Marble Bar Airport", "city_name":""},
 {"airport_iata_code":"XMC", "airport_name":"Mallacoota Airport", "city_name":""},
 {"airport_iata_code":"MFP", "airport_name":"Manners Creek Airport", "city_name":""},
 {"airport_iata_code":"MLR", "airport_name":"Millicent Airport", "city_name":""},
 {"airport_iata_code":"DGE", "airport_name":"Mudgee Airport", "city_name":"Mudgee"},
 {"airport_iata_code":"MQA", "airport_name":"Mandora Airport", "city_name":"Mandora"},
 {"airport_iata_code":"MKR", "airport_name":"Meekatharra Airport", "city_name":""},
 {"airport_iata_code":"MEB", "airport_name":"Melbourne Essendon Airport", "city_name":""},
 {"airport_iata_code":"MIM", "airport_name":"Merimbula Airport", "city_name":"Merimbula"},
 {"airport_iata_code":"SXE", "airport_name":"RAAF Base East Sale", "city_name":""},
 {"airport_iata_code":"MGT", "airport_name":"Milingimbi Airport", "city_name":"Milingimbi Island"},
 {"airport_iata_code":"MNG", "airport_name":"Maningrida Airport", "city_name":"Maningrida"},
 {"airport_iata_code":"GSN", "airport_name":"Mount Gunson Airport", "city_name":"Mount Gunson"},
 {"airport_iata_code":"MGV", "airport_name":"Margaret River (Station) Airport", "city_name":""},
 {"airport_iata_code":"MQZ", "airport_name":"Margaret River Airport", "city_name":""},
 {"airport_iata_code":"HBA", "airport_name":"Hobart International Airport", "city_name":"Hobart"},
 {"airport_iata_code":"MHO", "airport_name":"Mount House Airport", "city_name":""},
 {"airport_iata_code":"MCV", "airport_name":"McArthur River Mine Airport", "city_name":"McArthur River Mine"},
 {"airport_iata_code":"MQL", "airport_name":"Mildura Airport", "city_name":"Mildura"},
 {"airport_iata_code":"XML", "airport_name":"Minlaton Airport", "city_name":""},
 {"airport_iata_code":"MIH", "airport_name":"Mitchell Plateau Airport", "city_name":"Mitchell Plateau"},
 {"airport_iata_code":"MTQ", "airport_name":"Mitchell Airport", "city_name":""},
 {"airport_iata_code":"MJP", "airport_name":"Manjimup Airport", "city_name":""},
 {"airport_iata_code":"LST", "airport_name":"Launceston Airport", "city_name":"Launceston"},
 {"airport_iata_code":"MBW", "airport_name":"Melbourne Moorabbin Airport", "city_name":"Melbourne"},
 {"airport_iata_code":"MEL", "airport_name":"Melbourne International Airport", "city_name":"Melbourne"},
 {"airport_iata_code":"MMM", "airport_name":"Middlemount Airport", "city_name":""},
 {"airport_iata_code":"MTL", "airport_name":"Maitland Airport", "city_name":""},
 {"airport_iata_code":"WME", "airport_name":"Mount Keith Airport", "city_name":""},
 {"airport_iata_code":"ONR", "airport_name":"Monkira Airport", "city_name":""},
 {"airport_iata_code":"OXY", "airport_name":"Morney Airport", "city_name":""},
 {"airport_iata_code":"MMG", "airport_name":"Mount Magnet Airport", "city_name":""},
 {"airport_iata_code":"OOR", "airport_name":"Mooraberree Airport", "city_name":""},
 {"airport_iata_code":"MRZ", "airport_name":"Moree Airport", "city_name":"Moree"},
 {"airport_iata_code":"MET", "airport_name":"Moreton Airport", "city_name":"Moreton"},
 {"airport_iata_code":"MIN", "airport_name":"Minnipa Airport", "city_name":""},
 {"airport_iata_code":"MQE", "airport_name":"Marqua Airport", "city_name":"Marqua"},
 {"airport_iata_code":"MOV", "airport_name":"Moranbah Airport", "city_name":"Moranbah"},
 {"airport_iata_code":"RRE", "airport_name":"Marree Airport", "city_name":""},
 {"airport_iata_code":"MWB", "airport_name":"Morawa Airport", "city_name":""},
 {"airport_iata_code":"MYA", "airport_name":"Moruya Airport", "city_name":"Moruya"},
 {"airport_iata_code":"MTD", "airport_name":"Mount Sanford Station Airport", "city_name":""},
 {"airport_iata_code":"UTB", "airport_name":"Muttaburra Airport", "city_name":""},
 {"airport_iata_code":"MGB", "airport_name":"Mount Gambier Airport", "city_name":""},
 {"airport_iata_code":"ONG", "airport_name":"Mornington Island Airport", "city_name":""},
 {"airport_iata_code":"MNQ", "airport_name":"Monto Airport", "city_name":""},
 {"airport_iata_code":"MUQ", "airport_name":"Muccan Station Airport", "city_name":"Muccan Station"},
 {"airport_iata_code":"MNE", "airport_name":"Mungeranie Airport", "city_name":"Mungeranie"},
 {"airport_iata_code":"MYI", "airport_name":"Murray Island Airport", "city_name":"Murray Island"},
 {"airport_iata_code":"MVK", "airport_name":"Mulka Airport", "city_name":"Mulka"},
 {"airport_iata_code":"MXU", "airport_name":"Mullewa Airport", "city_name":""},
 {"airport_iata_code":"MXD", "airport_name":"Marion Downs Airport", "city_name":""},
 {"airport_iata_code":"MBH", "airport_name":"Maryborough Airport", "city_name":""},
 {"airport_iata_code":"RTY", "airport_name":"Merty Merty Airport", "city_name":""},
 {"airport_iata_code":"NMR", "airport_name":"Nappa Merrie Airport", "city_name":""},
 {"airport_iata_code":"NRA", "airport_name":"Narrandera Airport", "city_name":"Narrandera"},
 {"airport_iata_code":"NAA", "airport_name":"Narrabri Airport", "city_name":"Narrabri"},
 {"airport_iata_code":"RPM", "airport_name":"Ngukurr Airport", "city_name":""},
 {"airport_iata_code":"NBH", "airport_name":"Nambucca Heads Airport", "city_name":"Nambucca Heads"},
 {"airport_iata_code":"NLS", "airport_name":"Nicholson Airport", "city_name":""},
 {"airport_iata_code":"NAC", "airport_name":"Naracoorte Airport", "city_name":""},
 {"airport_iata_code":"QRM", "airport_name":"Narromine Airport", "city_name":""},
 {"airport_iata_code":"RVT", "airport_name":"Ravensthorpe Airport", "city_name":""},
 {"airport_iata_code":"NSV", "airport_name":"Noosa Airport", "city_name":""},
 {"airport_iata_code":"NSM", "airport_name":"Norseman Airport", "city_name":""},
 {"airport_iata_code":"NTN", "airport_name":"Normanton Airport", "city_name":""},
 {"airport_iata_code":"NUR", "airport_name":"Nullabor Motel Airport", "city_name":""},
 {"airport_iata_code":"NLL", "airport_name":"Nullagine Airport", "city_name":""},
 {"airport_iata_code":"NUB", "airport_name":"Numbulwar Airport", "city_name":""},
 {"airport_iata_code":"ZNE", "airport_name":"Newman Airport", "city_name":"Newman"},
 {"airport_iata_code":"NYN", "airport_name":"Nyngan Airport", "city_name":""},
 {"airport_iata_code":"OPI", "airport_name":"Oenpelli Airport", "city_name":""},
 {"airport_iata_code":"XCO", "airport_name":"Colac Airport", "city_name":""},
 {"airport_iata_code":"OLP", "airport_name":"Olympic Dam Airport", "city_name":"Olympic Dam"},
 {"airport_iata_code":"ODD", "airport_name":"Oodnadatta Airport", "city_name":""},
 {"airport_iata_code":"MOO", "airport_name":"Moomba Airport", "city_name":""},
 {"airport_iata_code":"RBS", "airport_name":"Orbost Airport", "city_name":""},
 {"airport_iata_code":"OAG", "airport_name":"Orange Airport", "city_name":"Orange"},
 {"airport_iata_code":"ODR", "airport_name":"Ord River Airport", "city_name":"Ord River"},
 {"airport_iata_code":"OSO", "airport_name":"Osborne Mine Airport", "city_name":""},
 {"airport_iata_code":"OYN", "airport_name":"Ouyen Airport", "city_name":""},
 {"airport_iata_code":"ADL", "airport_name":"Adelaide International Airport", "city_name":"Adelaide"},
 {"airport_iata_code":"PUG", "airport_name":"Port Augusta Airport", "city_name":""},
 {"airport_iata_code":"PMK", "airport_name":"Palm Island Airport", "city_name":""},
 {"airport_iata_code":"PBO", "airport_name":"Paraburdoo Airport", "city_name":"Paraburdoo"},
 {"airport_iata_code":"PDE", "airport_name":"Pandie Pandie Airport", "city_name":""},
 {"airport_iata_code":"DRW", "airport_name":"Darwin International Airport", "city_name":"Darwin"},
 {"airport_iata_code":"PRD", "airport_name":"Pardoo Airport", "city_name":"Pardoo"},
 {"airport_iata_code":"BEO", "airport_name":"Aeropelican Airport", "city_name":""},
 {"airport_iata_code":"GOV", "airport_name":"Gove Airport", "city_name":"Nhulunbuy"},
 {"airport_iata_code":"PPI", "airport_name":"Port Pirie Airport", "city_name":""},
 {"airport_iata_code":"JAD", "airport_name":"Perth Jandakot Airport", "city_name":"Perth"},
 {"airport_iata_code":"KTA", "airport_name":"Karratha Airport", "city_name":"Karratha"},
 {"airport_iata_code":"KGI", "airport_name":"Kalgoorlie Boulder Airport", "city_name":"Kalgoorlie"},
 {"airport_iata_code":"PKE", "airport_name":"Parkes Airport", "city_name":"Parkes"},
 {"airport_iata_code":"PKT", "airport_name":"Port Keats Airport", "city_name":""},
 {"airport_iata_code":"KNX", "airport_name":"Kununurra Airport", "city_name":"Kununurra"},
 {"airport_iata_code":"PLO", "airport_name":"Port Lincoln Airport", "city_name":"Port Lincoln"},
 {"airport_iata_code":"LEA", "airport_name":"Learmonth Airport", "city_name":"Exmouth"},
 {"airport_iata_code":"EDR", "airport_name":"Pormpuraaw Airport", "city_name":""},
 {"airport_iata_code":"PQQ", "airport_name":"Port Macquarie Airport", "city_name":"Port Macquarie"},
 {"airport_iata_code":"PTJ", "airport_name":"Portland Airport", "city_name":""},
 {"airport_iata_code":"PHE", "airport_name":"Port Hedland International Airport", "city_name":"Port Hedland"},
 {"airport_iata_code":"PER", "airport_name":"Perth International Airport", "city_name":"Perth"},
 {"airport_iata_code":"PEA", "airport_name":"Penneshaw Airport", "city_name":"Ironstone"},
 {"airport_iata_code":"KTR", "airport_name":"Tindal Airport", "city_name":""},
 {"airport_iata_code":"UMR", "airport_name":"Woomera Airfield", "city_name":"Woomera"},
 {"airport_iata_code":"UIR", "airport_name":"Quirindi Airport", "city_name":""},
 {"airport_iata_code":"ULP", "airport_name":"Quilpie Airport", "city_name":""},
 {"airport_iata_code":"UEE", "airport_name":"Queenstown Airport", "city_name":""},
 {"airport_iata_code":"RMK", "airport_name":"Renmark Airport", "city_name":""},
 {"airport_iata_code":"RCM", "airport_name":"Richmond Airport", "city_name":""},
 {"airport_iata_code":"RAM", "airport_name":"Ramingining Airport", "city_name":""},
 {"airport_iata_code":"ROH", "airport_name":"Robinhood Airport", "city_name":""},
 {"airport_iata_code":"RBU", "airport_name":"Roebourne Airport", "city_name":"Roebourne"},
 {"airport_iata_code":"RBC", "airport_name":"Robinvale Airport", "city_name":""},
 {"airport_iata_code":"RMA", "airport_name":"Roma Airport", "city_name":"Roma"},
 {"airport_iata_code":"RSB", "airport_name":"Roseberth Airport", "city_name":""},
 {"airport_iata_code":"RTS", "airport_name":"Rottnest Island Airport", "city_name":""},
 {"airport_iata_code":"RTP", "airport_name":"Rutland Plains Airport", "city_name":""},
 {"airport_iata_code":"RHL", "airport_name":"Roy Hill Station Airport", "city_name":""},
 {"airport_iata_code":"NDS", "airport_name":"Sandstone Airport", "city_name":"Sandstone"},
 {"airport_iata_code":"BWU", "airport_name":"Sydney Bankstown Airport", "city_name":"Sydney"},
 {"airport_iata_code":"CBR", "airport_name":"Canberra International Airport", "city_name":"Canberra"},
 {"airport_iata_code":"CFS", "airport_name":"Coffs Harbour Airport", "city_name":"Coffs Harbour"},
 {"airport_iata_code":"CDU", "airport_name":"Camden Airport", "city_name":""},
 {"airport_iata_code":"NSO", "airport_name":"Scone Airport", "city_name":""},
 {"airport_iata_code":"SQC", "airport_name":"Southern Cross Airport", "city_name":""},
 {"airport_iata_code":"DBO", "airport_name":"Dubbo City Regional Airport", "city_name":"Dubbo"},
 {"airport_iata_code":"SGO", "airport_name":"St George Airport", "city_name":""},
 {"airport_iata_code":"SIX", "airport_name":"Singleton Airport", "city_name":"Singleton"},
 {"airport_iata_code":"ZGL", "airport_name":"South Galway Airport", "city_name":""},
 {"airport_iata_code":"SGP", "airport_name":"Shay Gap Airport", "city_name":"Shay Gap"},
 {"airport_iata_code":"DNM", "airport_name":"Shark Bay Airport", "city_name":""},
 {"airport_iata_code":"JHQ", "airport_name":"Shute Harbour Airport", "city_name":""},
 {"airport_iata_code":"SHT", "airport_name":"Shepparton Airport", "city_name":""},
 {"airport_iata_code":"SBR", "airport_name":"Saibai Island Airport", "city_name":"Saibai Island"},
 {"airport_iata_code":"SIO", "airport_name":"Smithton Airport", "city_name":""},
 {"airport_iata_code":"SHU", "airport_name":"Smith Point Airport", "city_name":""},
 {"airport_iata_code":"STH", "airport_name":"Strathmore Airport", "city_name":""},
 {"airport_iata_code":"SNB", "airport_name":"Snake Bay Airport", "city_name":""},
 {"airport_iata_code":"NOA", "airport_name":"Nowra Airport", "city_name":""},
 {"airport_iata_code":"SNH", "airport_name":"Stanthorpe Airport", "city_name":""},
 {"airport_iata_code":"SCG", "airport_name":"Spring Creek Airport", "city_name":""},
 {"airport_iata_code":"SHQ", "airport_name":"Southport Airport", "city_name":""},
 {"airport_iata_code":"KSV", "airport_name":"Springvale Airport", "city_name":""},
 {"airport_iata_code":"SRN", "airport_name":"Strahan Airport", "city_name":""},
 {"airport_iata_code":"SYD", "airport_name":"Sydney Kingsford Smith International Airport", "city_name":"Sydney"},
 {"airport_iata_code":"HLS", "airport_name":"St Helens Airport", "city_name":""},
 {"airport_iata_code":"TMW", "airport_name":"Tamworth Airport", "city_name":"Tamworth"},
 {"airport_iata_code":"WGA", "airport_name":"Wagga Wagga City Airport", "city_name":"Wagga Wagga"},
 {"airport_iata_code":"SWH", "airport_name":"Swan Hill Airport", "city_name":""},
 {"airport_iata_code":"SWC", "airport_name":"Stawell Airport", "city_name":""},
 {"airport_iata_code":"XTR", "airport_name":"Tara Airport", "city_name":""},
 {"airport_iata_code":"TBL", "airport_name":"Tableland Homestead Airport", "city_name":""},
 {"airport_iata_code":"XTO", "airport_name":"Taroom Airport", "city_name":""},
 {"airport_iata_code":"TAQ", "airport_name":"Tarcoola Airport", "city_name":"Tarcoola"},
 {"airport_iata_code":"TBK", "airport_name":"Timber Creek Airport", "city_name":""},
 {"airport_iata_code":"TDR", "airport_name":"Theodore Airport", "city_name":""},
 {"airport_iata_code":"TEF", "airport_name":"Telfer Airport", "city_name":""},
 {"airport_iata_code":"TEM", "airport_name":"Temora Airport", "city_name":""},
 {"airport_iata_code":"TAN", "airport_name":"Tangalooma Airport", "city_name":""},
 {"airport_iata_code":"XTG", "airport_name":"Thargomindah Airport", "city_name":""},
 {"airport_iata_code":"TYG", "airport_name":"Thylungra Airport", "city_name":""},
 {"airport_iata_code":"TYB", "airport_name":"Tibooburra Airport", "city_name":""},
 {"airport_iata_code":"TKY", "airport_name":"Turkey Creek Airport", "city_name":"Turkey Creek"},
 {"airport_iata_code":"TUM", "airport_name":"Tumut Airport", "city_name":""},
 {"airport_iata_code":"TYP", "airport_name":"Tobermorey Airport", "city_name":"Tobermorey"},
 {"airport_iata_code":"THG", "airport_name":"Thangool Airport", "city_name":"Biloela"},
 {"airport_iata_code":"TCA", "airport_name":"Tennant Creek Airport", "city_name":"Tennant Creek"},
 {"airport_iata_code":"TCW", "airport_name":"Tocumwal Airport", "city_name":""},
 {"airport_iata_code":"TRO", "airport_name":"Taree Airport", "city_name":"Taree"},
 {"airport_iata_code":"TWB", "airport_name":"Toowoomba Airport", "city_name":""},
 {"airport_iata_code":"UDA", "airport_name":"Undara Airport", "city_name":""},
 {"airport_iata_code":"CZY", "airport_name":"Cluny Airport", "city_name":""},
 {"airport_iata_code":"USL", "airport_name":"Useless Loop Airport", "city_name":""},
 {"airport_iata_code":"VCD", "airport_name":"Victoria River Downs Airport", "city_name":""},
 {"airport_iata_code":"VNR", "airport_name":"Vanrook Station Airport", "city_name":""},
 {"airport_iata_code":"WLA", "airport_name":"Wallal Airport", "city_name":"Wallal"},
 {"airport_iata_code":"WAV", "airport_name":"Wave Hill Airport", "city_name":""},
 {"airport_iata_code":"WMB", "airport_name":"Warrnambool Airport", "city_name":""},
 {"airport_iata_code":"SYU", "airport_name":"Warraber Island Airport", "city_name":"Sue Islet"},
 {"airport_iata_code":"WIO", "airport_name":"Wilcannia Airport", "city_name":""},
 {"airport_iata_code":"WLC", "airport_name":"Walcha Airport", "city_name":""},
 {"airport_iata_code":"WAZ", "airport_name":"Warwick Airport", "city_name":""},
 {"airport_iata_code":"WND", "airport_name":"Windarra Airport", "city_name":""},
 {"airport_iata_code":"WNR", "airport_name":"Windorah Airport", "city_name":""},
 {"airport_iata_code":"WGT", "airport_name":"Wangaratta Airport", "city_name":""},
 {"airport_iata_code":"WYA", "airport_name":"Whyalla Airport", "city_name":"Whyalla"},
 {"airport_iata_code":"WIT", "airport_name":"Wittenoom Airport", "city_name":""},
 {"airport_iata_code":"WKB", "airport_name":"Warracknabeal Airport", "city_name":""},
 {"airport_iata_code":"WGE", "airport_name":"Walgett Airport", "city_name":""},
 {"airport_iata_code":"NTL", "airport_name":"Newcastle Airport", "city_name":"Williamtown"},
 {"airport_iata_code":"WUN", "airport_name":"Wiluna Airport", "city_name":""},
 {"airport_iata_code":"WPK", "airport_name":"Wrotham Park Airport", "city_name":""},
 {"airport_iata_code":"WDI", "airport_name":"Wondai Airport", "city_name":""},
 {"airport_iata_code":"WOL", "airport_name":"Wollongong Airport", "city_name":""},
 {"airport_iata_code":"QRR", "airport_name":"Warren Airport", "city_name":""},
 {"airport_iata_code":"WLO", "airport_name":"Waterloo Airport", "city_name":""},
 {"airport_iata_code":"WIN", "airport_name":"Winton Airport", "city_name":""},
 {"airport_iata_code":"WUD", "airport_name":"Wudinna Airport", "city_name":""},
 {"airport_iata_code":"WEW", "airport_name":"Wee Waa Airport", "city_name":""},
 {"airport_iata_code":"WWI", "airport_name":"Woodie Woodie Airport", "city_name":"Woodie Woodie"},
 {"airport_iata_code":"WYN", "airport_name":"Wyndham Airport", "city_name":""},
 {"airport_iata_code":"BWT", "airport_name":"Wynyard Airport", "city_name":"Burnie"},
 {"airport_iata_code":"YLG", "airport_name":"Yalgoo Airport", "city_name":"Yalgoo"},
 {"airport_iata_code":"OKR", "airport_name":"Yorke Island Airport", "city_name":"Yorke Island"},
 {"airport_iata_code":"XMY", "airport_name":"Yam Island Airport", "city_name":"Yam Island"},
 {"airport_iata_code":"YUE", "airport_name":"Yuendumu Airport", "city_name":""},
 {"airport_iata_code":"NGA", "airport_name":"Young Airport", "city_name":""},
 {"airport_iata_code":"ORR", "airport_name":"Yorketown Airport", "city_name":""},
 {"airport_iata_code":"KYI", "airport_name":"Yalata Mission Airport", "city_name":"Yalata Mission"},
 {"airport_iata_code":"AUA", "airport_name":"Queen Beatrix International Airport", "city_name":"Oranjestad"},
 {"airport_iata_code":"GYD", "airport_name":"Heydar Aliyev International Airport", "city_name":"Baku"},
 {"airport_iata_code":"KVD", "airport_name":"Ganja Airport", "city_name":"Ganja"},
 {"airport_iata_code":"LLK", "airport_name":"Lankaran International Airport", "city_name":"Lankaran"},
 {"airport_iata_code":"NAJ", "airport_name":"Nakhchivan Airport", "city_name":"Nakhchivan"},
 {"airport_iata_code":"GBB", "airport_name":"Gabala International Airport", "city_name":"Gabala"},
 {"airport_iata_code":"ZTU", "airport_name":"Zaqatala International Airport", "city_name":"Zaqatala"},
 {"airport_iata_code":"ZXT", "airport_name":"Zabrat Airport", "city_name":"Baku"},
 {"airport_iata_code":"BNX", "airport_name":"Banja Luka International Airport", "city_name":"Banja Luka"},
 {"airport_iata_code":"OMO", "airport_name":"Mostar International Airport", "city_name":"Mostar"},
 {"airport_iata_code":"SJJ", "airport_name":"Sarajevo International Airport", "city_name":"Sarajevo"},
 {"airport_iata_code":"TZL", "airport_name":"Tuzla International Airport", "city_name":"Tuzla"},
 {"airport_iata_code":"BGI", "airport_name":"Sir Grantley Adams International Airport", "city_name":"Bridgetown"},
 {"airport_iata_code":"BZL", "airport_name":"Barisal Airport", "city_name":"Barisal"},
 {"airport_iata_code":"CXB", "airport_name":"Cox's Bazar Airport", "city_name":"Cox's Bazar"},
 {"airport_iata_code":"CLA", "airport_name":"Comilla Airport", "city_name":"Comilla"},
 {"airport_iata_code":"CGP", "airport_name":"Shah Amanat International Airport", "city_name":"Chittagong"},
 {"airport_iata_code":"IRD", "airport_name":"Ishurdi Airport", "city_name":"Ishurdi"},
 {"airport_iata_code":"JSR", "airport_name":"Jessore Airport", "city_name":"Jashahor"},
 {"airport_iata_code":"LLJ", "airport_name":"Lalmonirhat Airport", "city_name":"Lalmonirhat"},
 {"airport_iata_code":"RJH", "airport_name":"Shah Mokhdum Airport", "city_name":"Rajshahi"},
 {"airport_iata_code":"SPD", "airport_name":"Saidpur Airport", "city_name":"Saidpur"},
 {"airport_iata_code":"TKR", "airport_name":"Thakurgaon Airport", "city_name":"Thakurgaon"},
 {"airport_iata_code":"ZHM", "airport_name":"Shamshernagar Airport", "city_name":"Shamshernagar"},
 {"airport_iata_code":"ZYL", "airport_name":"Osmany International Airport", "city_name":"Sylhet"},
 {"airport_iata_code":"DAC", "airport_name":"Dhaka / Hazrat Shahjalal International Airport", "city_name":"Dhaka"},
 {"airport_iata_code":"QON", "airport_name":"Sterpenich Airport", "city_name":"Arlon"},
 {"airport_iata_code":"ANR", "airport_name":"Antwerp International Airport (Deurne)", "city_name":"Antwerp"},
 {"airport_iata_code":"BRU", "airport_name":"Brussels Airport", "city_name":"Brussels"},
 {"airport_iata_code":"CRL", "airport_name":"Brussels South Charleroi Airport", "city_name":"Brussels"},
 {"airport_iata_code":"KJK", "airport_name":"Wevelgem Airport", "city_name":"Wevelgem"},
 {"airport_iata_code":"LGG", "airport_name":"Li\u00e8ge Airport", "city_name":"Li\u00e8ge"},
 {"airport_iata_code":"QNM", "airport_name":"Suarl\u00e9e Airport", "city_name":"Namur"},
 {"airport_iata_code":"OST", "airport_name":"Ostend-Bruges International Airport", "city_name":"Ostend"},
 {"airport_iata_code":"ZGQ", "airport_name":"Tournai/Maubray Airport", "city_name":"Tournai"},
 {"airport_iata_code":"QHA", "airport_name":"Hasselt Airport", "city_name":"Hasselt"},
 {"airport_iata_code":"OBL", "airport_name":"Oostmalle Air Base", "city_name":"Zoersel"},
 {"airport_iata_code":"XKY", "airport_name":"Kaya Airport", "city_name":"Kaya"},
 {"airport_iata_code":"OUG", "airport_name":"Ouahigouya Airport", "city_name":"Ouahigouya"},
 {"airport_iata_code":"XDJ", "airport_name":"Djibo Airport", "city_name":"Djibo"},
 {"airport_iata_code":"XLU", "airport_name":"Leo Airport", "city_name":"Leo"},
 {"airport_iata_code":"PUP", "airport_name":"Po Airport", "city_name":"Po"},
 {"airport_iata_code":"XBO", "airport_name":"Boulsa Airport", "city_name":"Boulsa"},
 {"airport_iata_code":"XBG", "airport_name":"Bogande Airport", "city_name":"Bogande"},
 {"airport_iata_code":"DIP", "airport_name":"Diapaga Airport", "city_name":"Diapaga"},
 {"airport_iata_code":"DOR", "airport_name":"Dori Airport", "city_name":"Dori"},
 {"airport_iata_code":"FNG", "airport_name":"Fada N'gourma Airport", "city_name":"Fada N'gourma"},
 {"airport_iata_code":"XGG", "airport_name":"Gorom-Gorom Airport", "city_name":"Gorom-Gorom"},
 {"airport_iata_code":"XKA", "airport_name":"Kantchari Airport", "city_name":"Kantchari"},
 {"airport_iata_code":"TMQ", "airport_name":"Tambao Airport", "city_name":"Tambao"},
 {"airport_iata_code":"XPA", "airport_name":"Pama Airport", "city_name":"Pama"},
 {"airport_iata_code":"ARL", "airport_name":"Arly Airport", "city_name":"Arly"},
 {"airport_iata_code":"XSE", "airport_name":"Sebba Airport", "city_name":"Sebba"},
 {"airport_iata_code":"TEG", "airport_name":"Tenkodogo Airport", "city_name":"Tenkodogo"},
 {"airport_iata_code":"XZA", "airport_name":"Zabr\u00e9 Airport", "city_name":"Zabr\u00e9"},
 {"airport_iata_code":"OUA", "airport_name":"Ouagadougou Airport", "city_name":"Ouagadougou"},
 {"airport_iata_code":"BNR", "airport_name":"Banfora Airport", "city_name":"Banfora"},
 {"airport_iata_code":"DGU", "airport_name":"Dedougou Airport", "city_name":"Dedougou"},
 {"airport_iata_code":"XGA", "airport_name":"Gaoua Airport", "city_name":"Gaoua"},
 {"airport_iata_code":"XNU", "airport_name":"Nouna Airport", "city_name":"Nouna"},
 {"airport_iata_code":"BOY", "airport_name":"Bobo Dioulasso Airport", "city_name":"Bobo Dioulasso"},
 {"airport_iata_code":"TUQ", "airport_name":"Tougan Airport", "city_name":"Tougan"},
 {"airport_iata_code":"XDE", "airport_name":"Diebougou Airport", "city_name":"Diebougou"},
 {"airport_iata_code":"XAR", "airport_name":"Aribinda Airport", "city_name":"Aribinda"},
 {"airport_iata_code":"HKV", "airport_name":"Haskovo Airport", "city_name":"Haskovo"},
 {"airport_iata_code":"JAM", "airport_name":"Bezmer Air Base", "city_name":"Yambol"},
 {"airport_iata_code":"BOJ", "airport_name":"Burgas Airport", "city_name":"Burgas"},
 {"airport_iata_code":"GOZ", "airport_name":"Gorna Oryahovitsa Airport", "city_name":"Gorna Oryahovitsa"},
 {"airport_iata_code":"PDV", "airport_name":"Plovdiv International Airport", "city_name":"Plovdiv"},
 {"airport_iata_code":"PVN", "airport_name":"Dolna Mitropoliya Air Base", "city_name":"Dolna Mitropoliya"},
 {"airport_iata_code":"SOF", "airport_name":"Sofia Airport", "city_name":"Sofia"},
 {"airport_iata_code":"SLS", "airport_name":"Silistra Polkovnik Lambrinovo Airfield", "city_name":"Silistra"},
 {"airport_iata_code":"SZR", "airport_name":"Stara Zagora Airport", "city_name":"Stara Zagora"},
 {"airport_iata_code":"VID", "airport_name":"Vidin Smurdan Airfield", "city_name":"Vidin"},
 {"airport_iata_code":"VAR", "airport_name":"Varna Airport", "city_name":"Varna"},
 {"airport_iata_code":"BAH", "airport_name":"Bahrain International Airport", "city_name":"Bahrain"},
 {"airport_iata_code":"BJM", "airport_name":"Bujumbura International Airport", "city_name":"Bujumbura"},
 {"airport_iata_code":"GID", "airport_name":"Gitega Airport", "city_name":"Gitega"},
 {"airport_iata_code":"KRE", "airport_name":"Kirundo Airport", "city_name":"Kirundo"},
 {"airport_iata_code":"COO", "airport_name":"Cadjehoun Airport", "city_name":"Cotonou"},
 {"airport_iata_code":"DJA", "airport_name":"Djougou Airport", "city_name":"Djougou"},
 {"airport_iata_code":"KDC", "airport_name":"Kandi Airport", "city_name":"Kandi"},
 {"airport_iata_code":"NAE", "airport_name":"Natitingou Airport", "city_name":"Natitingou"},
 {"airport_iata_code":"PKO", "airport_name":"Parakou Airport", "city_name":"Parakou"},
 {"airport_iata_code":"SVF", "airport_name":"Sav\u00e9 Airport", "city_name":"Sav\u00e9"},
 {"airport_iata_code":"SBH", "airport_name":"Gustaf III Airport", "city_name":"Gustavia"},
 {"airport_iata_code":"BDA", "airport_name":"L.F. Wade International International Airport", "city_name":"Hamilton"},
 {"airport_iata_code":"BWN", "airport_name":"Brunei International Airport", "city_name":"Bandar Seri Begawan"},
 {"airport_iata_code":"MHW", "airport_name":"Monteagudo Airport", "city_name":"El Ba\u00f1ado"},
 {"airport_iata_code":"APB", "airport_name":"Apolo Airport", "city_name":"Apolo"},
 {"airport_iata_code":"ASC", "airport_name":"Ascenci\u00f3n De Guarayos Airport", "city_name":"Ascensi\u00f3n de Guarayos"},
 {"airport_iata_code":"BJO", "airport_name":"Bermejo Airport", "city_name":"Bermejo"},
 {"airport_iata_code":"CAM", "airport_name":"Camiri Airport", "city_name":"Camiri"},
 {"airport_iata_code":"CBB", "airport_name":"Jorge Wilsterman International Airport", "city_name":"Cochabamba"},
 {"airport_iata_code":"CIJ", "airport_name":"Capit\u00e1n An\u00edbal Arab Airport", "city_name":"Cobija"},
 {"airport_iata_code":"CEP", "airport_name":"Concepci\u00f3n Airport", "city_name":"Concepci\u00f3n"},
 {"airport_iata_code":"SRZ", "airport_name":"El Trompillo Airport", "city_name":"Santa Cruz"},
 {"airport_iata_code":"GYA", "airport_name":"Capit\u00e1n de Av. Emilio Beltr\u00e1n Airport", "city_name":"Guayaramer\u00edn"},
 {"airport_iata_code":"BVK", "airport_name":"Huacaraje Airport", "city_name":"Itenes"},
 {"airport_iata_code":"SJS", "airport_name":"San Jos\u00e9 De Chiquitos Airport", "city_name":"San Jos\u00e9 de Chiquitos"},
 {"airport_iata_code":"SJB", "airport_name":"San Joaqu\u00edn Airport", "city_name":"San Joaqu\u00edn"},
 {"airport_iata_code":"SJV", "airport_name":"San Javier Airport", "city_name":"San Javier"},
 {"airport_iata_code":"LPB", "airport_name":"El Alto International Airport", "city_name":"La Paz / El Alto"},
 {"airport_iata_code":"MGD", "airport_name":"Magdalena Airport", "city_name":"Magdalena"},
 {"airport_iata_code":"ORU", "airport_name":"Juan Mendoza Airport", "city_name":"Oruro"},
 {"airport_iata_code":"POI", "airport_name":"Capitan Nicolas Rojas Airport", "city_name":"Potos\u00ed"},
 {"airport_iata_code":"PUR", "airport_name":"Puerto Rico Airport", "city_name":"Puerto Rico/Manuripi"},
 {"airport_iata_code":"PSZ", "airport_name":"Capit\u00e1n Av. Salvador Ogaya G. airport", "city_name":"Puerto Su\u00e1rez"},
 {"airport_iata_code":"SRD", "airport_name":"San Ram\u00f3n Airport", "city_name":"San Ram\u00f3n / Mamor\u00e9"},
 {"airport_iata_code":"RBO", "airport_name":"Robor\u00e9 Airport", "city_name":"Robor\u00e9"},
 {"airport_iata_code":"RIB", "airport_name":"Capit\u00e1n Av. Selin Zeitun Lopez Airport", "city_name":"Riberalta"},
 {"airport_iata_code":"REY", "airport_name":"Reyes Airport", "city_name":"Reyes"},
 {"airport_iata_code":"SBL", "airport_name":"Santa Ana Del Yacuma Airport", "city_name":"Santa Ana del Yacuma"},
 {"airport_iata_code":"SRJ", "airport_name":"Capit\u00e1n Av. German Quiroga G. Airport", "city_name":"San Borja"},
 {"airport_iata_code":"SNG", "airport_name":"Capit\u00e1n Av. Juan Cochamanidis S. Airport", "city_name":"San Ignacio de Velasco"},
 {"airport_iata_code":"SNM", "airport_name":"San Ignacio de Moxos Airport", "city_name":"San Ignacio de Moxos"},
 {"airport_iata_code":"SRB", "airport_name":"Santa Rosa De Yacuma Airport", "city_name":"Santa Rosa"},
 {"airport_iata_code":"SRE", "airport_name":"Juana Azurduy De Padilla Airport", "city_name":"Sucre"},
 {"airport_iata_code":"MQK", "airport_name":"San Mat\u00edas Airport", "city_name":"San Mat\u00edas"},
 {"airport_iata_code":"TJA", "airport_name":"Capitan Oriel Lea Plaza Airport", "city_name":"Tarija"},
 {"airport_iata_code":"TDD", "airport_name":"Teniente Av. Jorge Henrich Arauz Airport", "city_name":"Trinidad"},
 {"airport_iata_code":"UYU", "airport_name":"Uyuni Airport", "city_name":"Quijarro"},
 {"airport_iata_code":"VAH", "airport_name":"Capit\u00e1n Av. Vidal Villagomez Toledo Airport", "city_name":"Vallegrande"},
 {"airport_iata_code":"VLM", "airport_name":"Teniente Coronel Rafael Pab\u00f3n Airport", "city_name":"Villamontes"},
 {"airport_iata_code":"VVI", "airport_name":"Viru Viru International Airport", "city_name":"Santa Cruz"},
 {"airport_iata_code":"BYC", "airport_name":"Yacuiba Airport", "city_name":"Yacu\u00edba"},
 {"airport_iata_code":"RBQ", "airport_name":"Rurenabaque Airport", "city_name":"Rurenabaque"},
 {"airport_iata_code":"BVL", "airport_name":"Baures Airport", "city_name":"Baures"},
 {"airport_iata_code":"BON", "airport_name":"Flamingo International Airport", "city_name":"Kralendijk"},
 {"airport_iata_code":"EUX", "airport_name":"F. D. Roosevelt Airport", "city_name":"Sint Eustatius"},
 {"airport_iata_code":"SAB", "airport_name":"Juancho E. Yrausquin Airport", "city_name":"Saba"},
 {"airport_iata_code":"OTT", "airport_name":"Andre Maggi Airport", "city_name":"Cotrigua\u00e7u"},
 {"airport_iata_code":"CDJ", "airport_name":"Concei\u00e7\u00e3o do Araguaia Airport", "city_name":"Concei\u00e7\u00e3o Do Araguaia"},
 {"airport_iata_code":"APS", "airport_name":"Base A\u00e9rea Airport", "city_name":"An\u00e1polis"},
 {"airport_iata_code":"AQA", "airport_name":"Araraquara Airport", "city_name":"Araraquara"},
 {"airport_iata_code":"AJU", "airport_name":"Santa Maria Airport", "city_name":"Aracaju"},
 {"airport_iata_code":"AFL", "airport_name":"Alta Floresta Airport", "city_name":"Alta Floresta"},
 {"airport_iata_code":"ARU", "airport_name":"Ara\u00e7atuba Airport", "city_name":"Ara\u00e7atuba"},
 {"airport_iata_code":"AAX", "airport_name":"Arax\u00e1 Airport", "city_name":"Arax\u00e1"},
 {"airport_iata_code":"BEL", "airport_name":"Val de Cans/J\u00falio Cezar Ribeiro International Airport", "city_name":"Bel\u00e9m"},
 {"airport_iata_code":"BGX", "airport_name":"Comandante Gustavo Kraemer Airport", "city_name":"Bag\u00e9"},
 {"airport_iata_code":"PLU", "airport_name":"Pampulha - Carlos Drummond de Andrade Airport", "city_name":"Belo Horizonte"},
 {"airport_iata_code":"BFH", "airport_name":"Bacacheri Airport", "city_name":"Curitiba"},
 {"airport_iata_code":"BJP", "airport_name":"Aeroporto Estadual Arthur Siqueira Airport", "city_name":"Bragan\u00e7a Paulista"},
 {"airport_iata_code":"QAK", "airport_name":"Major Brigadeiro Doorgal Borges Airport", "city_name":"Barbacena"},
 {"airport_iata_code":"BSB", "airport_name":"Presidente Juscelino Kubistschek International Airport", "city_name":"Bras\u00edlia"},
 {"airport_iata_code":"BAT", "airport_name":"Chafei Amsei Airport", "city_name":"Barretos"},
 {"airport_iata_code":"BAU", "airport_name":"Bauru Airport", "city_name":"Bauru"},
 {"airport_iata_code":"BVB", "airport_name":"Atlas Brasil Cantanhede Airport", "city_name":"Boa Vista"},
 {"airport_iata_code":"BPG", "airport_name":"Barra do Gar\u00e7as Airport", "city_name":"Barra Do Gar\u00e7as"},
 {"airport_iata_code":"BZC", "airport_name":"Umberto Modiano Airport", "city_name":"Cabo Frio"},
 {"airport_iata_code":"CAC", "airport_name":"Cascavel Airport", "city_name":"Cascavel"},
 {"airport_iata_code":"CFB", "airport_name":"Cabo Frio Airport", "city_name":"Cabo Frio"},
 {"airport_iata_code":"CNF", "airport_name":"Tancredo Neves International Airport", "city_name":"Belo Horizonte"},
 {"airport_iata_code":"CGR", "airport_name":"Campo Grande Airport", "city_name":"Campo Grande"},
 {"airport_iata_code":"XAP", "airport_name":"Chapec\u00f3 Airport", "city_name":"Chapec\u00f3"},
 {"airport_iata_code":"CLN", "airport_name":"Brig. Lysias Augusto Rodrigues Airport", "city_name":"Carolina"},
 {"airport_iata_code":"CKS", "airport_name":"Caraj\u00e1s Airport", "city_name":"Caraj\u00e1s"},
 {"airport_iata_code":"CCM", "airport_name":"Forquilhinha - Crici\u00fama Airport", "city_name":"Crici\u00fama"},
 {"airport_iata_code":"CLV", "airport_name":"Caldas Novas Airport", "city_name":"Caldas Novas"},
 {"airport_iata_code":"QNS", "airport_name":"Canoas Airport", "city_name":"Porto Alegre"},
 {"airport_iata_code":"CAW", "airport_name":"Bartolomeu Lisandro Airport", "city_name":"Campos Dos Goytacazes"},
 {"airport_iata_code":"CMG", "airport_name":"Corumb\u00e1 International Airport", "city_name":"Corumb\u00e1"},
 {"airport_iata_code":"CWB", "airport_name":"Afonso Pena Airport", "city_name":"Curitiba"},
 {"airport_iata_code":"CRQ", "airport_name":"Caravelas Airport", "city_name":"Caravelas"},
 {"airport_iata_code":"CXJ", "airport_name":"Campo dos Bugres Airport", "city_name":"Caxias Do Sul"},
 {"airport_iata_code":"CGB", "airport_name":"Marechal Rondon Airport", "city_name":"Cuiab\u00e1"},
 {"airport_iata_code":"CZS", "airport_name":"Cruzeiro do Sul Airport", "city_name":"Cruzeiro Do Sul"},
 {"airport_iata_code":"BYO", "airport_name":"Bonito Airport", "city_name":"Bonito"},
 {"airport_iata_code":"PPB", "airport_name":"Presidente Prudente Airport", "city_name":"Presidente Prudente"},
 {"airport_iata_code":"MAO", "airport_name":"Eduardo Gomes International Airport", "city_name":"Manaus"},
 {"airport_iata_code":"JCR", "airport_name":"Jacareacanga Airport", "city_name":"Jacareacanga"},
 {"airport_iata_code":"ESI", "airport_name":"Espinosa Airport", "city_name":"Espinosa"},
 {"airport_iata_code":"IGU", "airport_name":"Cataratas International Airport", "city_name":"Foz Do Igua\u00e7u"},
 {"airport_iata_code":"FLN", "airport_name":"Herc\u00edlio Luz International Airport", "city_name":"Florian\u00f3polis"},
 {"airport_iata_code":"FEN", "airport_name":"Fernando de Noronha Airport", "city_name":"Fernando De Noronha"},
 {"airport_iata_code":"FOR", "airport_name":"Pinto Martins International Airport", "city_name":"Fortaleza"},
 {"airport_iata_code":"GIG", "airport_name":"Gale\u00e3o - Ant\u00f4nio Carlos Jobim International Airport", "city_name":"Rio De Janeiro"},
 {"airport_iata_code":"GJM", "airport_name":"Guajar\u00e1-Mirim Airport", "city_name":"Guajar\u00e1-Mirim"},
 {"airport_iata_code":"GYN", "airport_name":"Santa Genoveva Airport", "city_name":"Goi\u00e2nia"},
 {"airport_iata_code":"GRU", "airport_name":"Guarulhos - Governador Andr\u00e9 Franco Montoro International Airport", "city_name":"S\u00e3o Paulo"},
 {"airport_iata_code":"GPB", "airport_name":"Tancredo Thomas de Faria Airport", "city_name":"Guarapuava"},
 {"airport_iata_code":"GVR", "airport_name":"Governador Valadares Airport", "city_name":"Governador Valadares"},
 {"airport_iata_code":"GUJ", "airport_name":"Guaratinguet\u00e1 Airport", "city_name":"Guaratinguet\u00e1"},
 {"airport_iata_code":"ATM", "airport_name":"Altamira Airport", "city_name":"Altamira"},
 {"airport_iata_code":"ITA", "airport_name":"Itacoatiara Airport", "city_name":"Itacoatiara"},
 {"airport_iata_code":"ITB", "airport_name":"Itaituba Airport", "city_name":"Itaituba"},
 {"airport_iata_code":"IOS", "airport_name":"Bahia - Jorge Amado Airport", "city_name":"Ilh\u00e9us"},
 {"airport_iata_code":"IPN", "airport_name":"Usiminas Airport", "city_name":"Ipatinga"},
 {"airport_iata_code":"IMP", "airport_name":"Prefeito Renato Moreira Airport", "city_name":"Imperatriz"},
 {"airport_iata_code":"JDF", "airport_name":"Francisco de Assis Airport", "city_name":"Juiz De Fora"},
 {"airport_iata_code":"JPA", "airport_name":"Presidente Castro Pinto Airport", "city_name":"Jo\u00e3o Pessoa"},
 {"airport_iata_code":"JDO", "airport_name":"Orlando Bezerra de Menezes Airport", "city_name":"Juazeiro Do Norte"},
 {"airport_iata_code":"JOI", "airport_name":"Lauro Carneiro de Loyola Airport", "city_name":"Joinville"},
 {"airport_iata_code":"CPV", "airport_name":"Presidente Jo\u00e3o Suassuna Airport", "city_name":"Campina Grande"},
 {"airport_iata_code":"VCP", "airport_name":"Viracopos International Airport", "city_name":"Campinas"},
 {"airport_iata_code":"LEC", "airport_name":"Chapada Diamantina Airport", "city_name":"Len\u00e7\u00f3is"},
 {"airport_iata_code":"LAJ", "airport_name":"Lages Airport", "city_name":"Lages"},
 {"airport_iata_code":"LIP", "airport_name":"Lins Airport", "city_name":"Lins"},
 {"airport_iata_code":"LDB", "airport_name":"Governador Jos\u00e9 Richa Airport", "city_name":"Londrina"},
 {"airport_iata_code":"LAZ", "airport_name":"Bom Jesus da Lapa Airport", "city_name":"Bom Jesus Da Lapa"},
 {"airport_iata_code":"MAB", "airport_name":"Marab\u00e1 Airport", "city_name":"Marab\u00e1"},
 {"airport_iata_code":"MQH", "airport_name":"Mina\u00e7u Airport", "city_name":"Mina\u00e7u"},
 {"airport_iata_code":"MEU", "airport_name":"Monte Dourado Airport", "city_name":"Almeirim"},
 {"airport_iata_code":"MEA", "airport_name":"Maca\u00e9 Airport", "city_name":"Maca\u00e9"},
 {"airport_iata_code":"MGF", "airport_name":"Regional de Maring\u00e1 - S\u00edlvio Nane Junior Airport", "city_name":"Maring\u00e1"},
 {"airport_iata_code":"MOC", "airport_name":"M\u00e1rio Ribeiro Airport", "city_name":"Montes Claros"},
 {"airport_iata_code":"MII", "airport_name":"Mar\u00edlia Airport", "city_name":"Mar\u00edlia"},
 {"airport_iata_code":"PLL", "airport_name":"Ponta Pelada Airport", "city_name":"Manaus"},
 {"airport_iata_code":"MCZ", "airport_name":"Zumbi dos Palmares Airport", "city_name":"Macei\u00f3"},
 {"airport_iata_code":"MCP", "airport_name":"Alberto Alcolumbre Airport", "city_name":"Macap\u00e1"},
 {"airport_iata_code":"MVF", "airport_name":"Dix-Sept Rosado Airport", "city_name":"Mossor\u00f3"},
 {"airport_iata_code":"SAO", "airport_name":"Campo de Marte Airport", "city_name":"S\u00e3o Paulo"},
 {"airport_iata_code":"MNX", "airport_name":"Manicor\u00e9 Airport", "city_name":"Manicor\u00e9"},
 {"airport_iata_code":"NVT", "airport_name":"Ministro Victor Konder International Airport", "city_name":"Navegantes"},
 {"airport_iata_code":"GEL", "airport_name":"Santo \u00c2ngelo Airport", "city_name":"Santo \u00c2ngelo"},
 {"airport_iata_code":"NAT", "airport_name":"Augusto Severo Airport", "city_name":"Natal"},
 {"airport_iata_code":"OYK", "airport_name":"Oiapoque Airport", "city_name":"Oiapoque"},
 {"airport_iata_code":"POA", "airport_name":"Salgado Filho Airport", "city_name":"Porto Alegre"},
 {"airport_iata_code":"PHB", "airport_name":"Prefeito Doutor Jo\u00e3o Silva Filho Airport", "city_name":"Parna\u00edba"},
 {"airport_iata_code":"POO", "airport_name":"Po\u00e7os de Caldas Airport", "city_name":"Po\u00e7os De Caldas"},
 {"airport_iata_code":"PFB", "airport_name":"Lauro Kurtz Airport", "city_name":"Passo Fundo"},
 {"airport_iata_code":"PMW", "airport_name":"Brigadeiro Lysias Rodrigues Airport", "city_name":"Palmas"},
 {"airport_iata_code":"PET", "airport_name":"Pelotas Airport", "city_name":"Pelotas"},
 {"airport_iata_code":"PNZ", "airport_name":"Senador Nilo Coelho Airport", "city_name":"Petrolina"},
 {"airport_iata_code":"PNB", "airport_name":"Porto Nacional Airport", "city_name":"Porto Nacional"},
 {"airport_iata_code":"PMG", "airport_name":"Ponta Por\u00e3 Airport", "city_name":"Ponta Por\u00e3"},
 {"airport_iata_code":"BPS", "airport_name":"Porto Seguro Airport", "city_name":"Porto Seguro"},
 {"airport_iata_code":"PVH", "airport_name":"Governador Jorge Teixeira de Oliveira Airport", "city_name":"Porto Velho"},
 {"airport_iata_code":"VDC", "airport_name":"Vit\u00f3ria da Conquista Airport", "city_name":"Vit\u00f3ria Da Conquista"},
 {"airport_iata_code":"RBR", "airport_name":"Pl\u00e1cido de Castro Airport", "city_name":"Rio Branco"},
 {"airport_iata_code":"REC", "airport_name":"Guararapes - Gilberto Freyre International Airport", "city_name":"Recife"},
 {"airport_iata_code":"SDU", "airport_name":"Santos Dumont Airport", "city_name":"Rio De Janeiro"},
 {"airport_iata_code":"RAO", "airport_name":"Leite Lopes Airport", "city_name":"Ribeir\u00e3o Preto"},
 {"airport_iata_code":"BRB", "airport_name":"Barreirinhas Airport", "city_name":""},
 {"airport_iata_code":"SNZ", "airport_name":"Santa Cruz Airport", "city_name":"Rio De Janeiro"},
 {"airport_iata_code":"SJK", "airport_name":"Professor Urbano Ernesto Stumpf Airport", "city_name":"S\u00e3o Jos\u00e9 Dos Campos"},
 {"airport_iata_code":"SLZ", "airport_name":"Marechal Cunha Machado International Airport", "city_name":"S\u00e3o Lu\u00eds"},
 {"airport_iata_code":"RIA", "airport_name":"Santa Maria Airport", "city_name":"Santa Maria"},
 {"airport_iata_code":"STM", "airport_name":"Maestro Wilson Fonseca Airport", "city_name":"Santar\u00e9m"},
 {"airport_iata_code":"CGH", "airport_name":"Congonhas Airport", "city_name":"S\u00e3o Paulo"},
 {"airport_iata_code":"SJP", "airport_name":"S\u00e3o Jos\u00e9 do Rio Preto Airport", "city_name":"S\u00e3o Jos\u00e9 Do Rio Preto"},
 {"airport_iata_code":"SSZ", "airport_name":"Base A\u00e9rea de Santos Airport", "city_name":"Guaruj\u00e1"},
 {"airport_iata_code":"SSA", "airport_name":"Deputado Luiz Eduardo Magalh\u00e3es International Airport", "city_name":"Salvador"},
 {"airport_iata_code":"QHP", "airport_name":"Base de Avia\u00e7\u00e3o de Taubat\u00e9 Airport", "city_name":"Taubat\u00e9"},
 {"airport_iata_code":"TMT", "airport_name":"Trombetas Airport", "city_name":"Oriximin\u00e1"},
 {"airport_iata_code":"UNA", "airport_name":"Hotel Transam\u00e9rica Airport", "city_name":"Una"},
 {"airport_iata_code":"TOW", "airport_name":"Toledo Airport", "city_name":"Toledo"},
 {"airport_iata_code":"THE", "airport_name":"Senador Petr\u00f4nio Portela Airport", "city_name":"Teresina"},
 {"airport_iata_code":"TFF", "airport_name":"Tef\u00e9 Airport", "city_name":"Tef\u00e9"},
 {"airport_iata_code":"TRQ", "airport_name":"Tarauac\u00e1 Airport", "city_name":"Tarauac\u00e1"},
 {"airport_iata_code":"TEC", "airport_name":"Tel\u00eamaco Borba Airport", "city_name":"Tel\u00eamaco Borba"},
 {"airport_iata_code":"TBT", "airport_name":"Tabatinga Airport", "city_name":"Tabatinga"},
 {"airport_iata_code":"TUR", "airport_name":"Tucuru\u00ed Airport", "city_name":"Tucuru\u00ed"},
 {"airport_iata_code":"SJL", "airport_name":"S\u00e3o Gabriel da Cachoeira Airport", "city_name":"S\u00e3o Gabriel Da Cachoeira"},
 {"airport_iata_code":"PAV", "airport_name":"Paulo Afonso Airport", "city_name":"Paulo Afonso"},
 {"airport_iata_code":"URG", "airport_name":"Rubem Berta Airport", "city_name":"Uruguaiana"},
 {"airport_iata_code":"UDI", "airport_name":"Ten. Cel. Aviador C\u00e9sar Bombonato Airport", "city_name":"Uberl\u00e2ndia"},
 {"airport_iata_code":"UBA", "airport_name":"M\u00e1rio de Almeida Franco Airport", "city_name":"Uberaba"},
 {"airport_iata_code":"VAG", "airport_name":"Major Brigadeiro Trompowsky Airport", "city_name":"Varginha"},
 {"airport_iata_code":"BVH", "airport_name":"Vilhena Airport", "city_name":"Vilhena"},
 {"airport_iata_code":"VIX", "airport_name":"Eurico de Aguiar Salles Airport", "city_name":"Vit\u00f3ria"},
 {"airport_iata_code":"QPS", "airport_name":"Campo Fontenelle Airport", "city_name":"Pirassununga"},
 {"airport_iata_code":"CPQ", "airport_name":"Amarais Airport", "city_name":"Campinas"},
 {"airport_iata_code":"QCJ", "airport_name":"Botucatu Airport", "city_name":"Botucatu"},
 {"airport_iata_code":"OLC", "airport_name":"Senadora Eunice Micheles Airport", "city_name":"S\u00e3o Paulo De Oliven\u00e7a"},
 {"airport_iata_code":"SOD", "airport_name":"Sorocaba Airport", "city_name":"Sorocaba"},
 {"airport_iata_code":"QDC", "airport_name":"Dracena Airport", "city_name":"Dracena"},
 {"airport_iata_code":"JLS", "airport_name":"Jales Airport", "city_name":"Jales"},
 {"airport_iata_code":"QOA", "airport_name":"Mococa Airport", "city_name":"Mococa"},
 {"airport_iata_code":"QGC", "airport_name":"Len\u00e7\u00f3is Paulista Airport", "city_name":"Len\u00e7\u00f3is Paulista"},
 {"airport_iata_code":"QNV", "airport_name":"Aeroclube Airport", "city_name":"Nova Igua\u00e7u"},
 {"airport_iata_code":"OUS", "airport_name":"Ourinhos Airport", "city_name":"Ourinhos"},
 {"airport_iata_code":"QHB", "airport_name":"Piracicaba Airport", "city_name":"Piracicaba"},
 {"airport_iata_code":"QIQ", "airport_name":"Rio Claro Airport", "city_name":"Rio Claro"},
 {"airport_iata_code":"QVP", "airport_name":"Avar\u00e9-Arandu Airport", "city_name":"Avar\u00e9"},
 {"airport_iata_code":"QRZ", "airport_name":"Resende Airport", "city_name":"Resende"},
 {"airport_iata_code":"QSC", "airport_name":"S\u00e3o Carlos Airport", "city_name":"S\u00e3o Carlos"},
 {"airport_iata_code":"UBT", "airport_name":"Ubatuba Airport", "city_name":"Ubatuba"},
 {"airport_iata_code":"QGS", "airport_name":"Alagoinhas Airport", "city_name":"Alagoinhas"},
 {"airport_iata_code":"VOT", "airport_name":"Votuporanga Airport", "city_name":"Votuporanga"},
 {"airport_iata_code":"QGB", "airport_name":"Limeira Airport", "city_name":"Limeira"},
 {"airport_iata_code":"IZA", "airport_name":"Zona da Mata Regional Airport", "city_name":"Juiz De Fora"},
 {"airport_iata_code":"LVR", "airport_name":"Municipal Bom Futuro Airport", "city_name":"Lucas do Rio Verde"},
 {"airport_iata_code":"FRC", "airport_name":"Franca Airport", "city_name":"Franca"},
 {"airport_iata_code":"CFO", "airport_name":"Confresa Airport", "city_name":"Confresa"},
 {"airport_iata_code":"RIG", "airport_name":"Rio Grande Airport", "city_name":"Rio Grande"},
 {"airport_iata_code":"JTC", "airport_name":"Bauru - Arealva Airport", "city_name":"Bauru"},
 {"airport_iata_code":"ARS", "airport_name":"Usina Santa Cruz Airport", "city_name":"Santa Cruz Cabr\u00e1lia"},
 {"airport_iata_code":"APY", "airport_name":"Alto Parna\u00edba Airport", "city_name":"Alto Parna\u00edba"},
 {"airport_iata_code":"APQ", "airport_name":"Arapiraca Airport", "city_name":"Arapiraca"},
 {"airport_iata_code":"AMJ", "airport_name":"Cirilo Queir\u00f3z Airport", "city_name":"Almenara"},
 {"airport_iata_code":"AIF", "airport_name":"Marcelo Pires Halzhausen Airport", "city_name":"Assis"},
 {"airport_iata_code":"BDC", "airport_name":"Barra do Corda Airport", "city_name":"Barra Do Corda"},
 {"airport_iata_code":"BVM", "airport_name":"Belmonte Airport", "city_name":"Belmonte"},
 {"airport_iata_code":"BRA", "airport_name":"Barreiras Airport", "city_name":"Barreiras"},
 {"airport_iata_code":"BSS", "airport_name":"Balsas Airport", "city_name":"Balsas"},
 {"airport_iata_code":"BMS", "airport_name":"S\u00f3crates Mariani Bittencourt Airport", "city_name":"Brumado"},
 {"airport_iata_code":"BQQ", "airport_name":"Barra Airport", "city_name":"Barra"},
 {"airport_iata_code":"CTP", "airport_name":"Carutapera Airport", "city_name":"Carutapera"},
 {"airport_iata_code":"CPU", "airport_name":"Cururupu Airport", "city_name":"Cururupu"},
 {"airport_iata_code":"QCH", "airport_name":"Colatina Airport", "city_name":"Colatina"},
 {"airport_iata_code":"RDC", "airport_name":"Reden\u00e7\u00e3o Airport", "city_name":"Reden\u00e7\u00e3o"},
 {"airport_iata_code":"LEP", "airport_name":"Leopoldina Airport", "city_name":"Leopoldina"},
 {"airport_iata_code":"DTI", "airport_name":"Diamantina Airport", "city_name":"Diamantina"},
 {"airport_iata_code":"DIQ", "airport_name":"Divin\u00f3polis Airport", "city_name":"Divin\u00f3polis"},
 {"airport_iata_code":"CNV", "airport_name":"Canavieiras Airport", "city_name":"Canavieiras"},
 {"airport_iata_code":"SXX", "airport_name":"S\u00e3o F\u00e9lix do Xingu Airport", "city_name":"S\u00e3o F\u00e9lix Do Xingu"},
 {"airport_iata_code":"GUZ", "airport_name":"Guarapari Airport", "city_name":"Guarapari"},
 {"airport_iata_code":"GDP", "airport_name":"Guadalupe Airport", "city_name":"Guadalupe"},
 {"airport_iata_code":"GNM", "airport_name":"Guanambi Airport", "city_name":"Guanambi"},
 {"airport_iata_code":"GMS", "airport_name":"Fazenda Canad\u00e1 Airport", "city_name":"Uberl\u00e2ndia"},
 {"airport_iata_code":"QGP", "airport_name":"Garanhuns Airport", "city_name":"Garanhuns"},
 {"airport_iata_code":"IRE", "airport_name":"Irec\u00ea Airport", "city_name":"Irec\u00ea"},
 {"airport_iata_code":"QIG", "airport_name":"Iguatu Airport", "city_name":"Iguatu"},
 {"airport_iata_code":"QIT", "airport_name":"Itapetinga Airport", "city_name":"Itapetinga"},
 {"airport_iata_code":"IPU", "airport_name":"Ipia\u00fa Airport", "city_name":"Ipia\u00fa"},
 {"airport_iata_code":"JCM", "airport_name":"Jacobina Airport", "city_name":"Jacobina"},
 {"airport_iata_code":"FEC", "airport_name":"Jo\u00e3o Durval Carneiro Airport", "city_name":"Feira De Santana"},
 {"airport_iata_code":"JEQ", "airport_name":"Jequi\u00e9 Airport", "city_name":"Jequi\u00e9"},
 {"airport_iata_code":"JNA", "airport_name":"Janu\u00e1ria Airport", "city_name":"Janu\u00e1ria"},
 {"airport_iata_code":"JDR", "airport_name":"Prefeito Oct\u00e1vio de Almeida Neves Airport", "city_name":"S\u00e3o Jo\u00e3o Del Rei"},
 {"airport_iata_code":"CMP", "airport_name":"Santana do Araguaia Airport", "city_name":"Santana Do Araguaia"},
 {"airport_iata_code":"QDF", "airport_name":"Conselheiro Lafaiete Airport", "city_name":"Conselheiro Lafaiete"},
 {"airport_iata_code":"CDI", "airport_name":"Cachoeiro do Itapemirim Airport", "city_name":"Cachoeiro Do Itapemirim"},
 {"airport_iata_code":"QCP", "airport_name":"Currais Novos Airport", "city_name":"Currais Novos"},
 {"airport_iata_code":"LVB", "airport_name":"Livramento do Brumado Airport", "city_name":"Livramento Do Brumado"},
 {"airport_iata_code":"SSO", "airport_name":"S\u00e3o Louren\u00e7o Airport", "city_name":"S\u00e3o Louren\u00e7o"},
 {"airport_iata_code":"MTE", "airport_name":"Monte Alegre Airport", "city_name":"Monte Alegre"},
 {"airport_iata_code":"MVS", "airport_name":"Mucuri Airport", "city_name":"Mucuri"},
 {"airport_iata_code":"SBJ", "airport_name":"S\u00e3o Mateus Airport", "city_name":"S\u00e3o Mateus"},
 {"airport_iata_code":"PTQ", "airport_name":"Porto de Moz Airport", "city_name":"Porto De Moz"},
 {"airport_iata_code":"NNU", "airport_name":"Nanuque Airport", "city_name":"Nanuque"},
 {"airport_iata_code":"QBX", "airport_name":"Sobral Airport", "city_name":"Sobral"},
 {"airport_iata_code":"PSW", "airport_name":"Municipal Jos\u00e9 Figueiredo Airport", "city_name":"Passos"},
 {"airport_iata_code":"ORX", "airport_name":"Oriximin\u00e1 Airport", "city_name":"Oriximin\u00e1"},
 {"airport_iata_code":"PCS", "airport_name":"Picos Airport", "city_name":"Picos"},
 {"airport_iata_code":"POJ", "airport_name":"Patos de Minas Airport", "city_name":"Patos De Minas"},
 {"airport_iata_code":"PIV", "airport_name":"Pirapora Airport", "city_name":"Pirapora"},
 {"airport_iata_code":"FLB", "airport_name":"Cangapara Airport", "city_name":"Floriano"},
 {"airport_iata_code":"PDF", "airport_name":"Prado Airport", "city_name":"Prado"},
 {"airport_iata_code":"CAU", "airport_name":"Caruaru Airport", "city_name":"Caruaru"},
 {"airport_iata_code":"SFK", "airport_name":"Soure Airport", "city_name":"Soure"},
 {"airport_iata_code":"OBI", "airport_name":"\u00d3bidos Airport", "city_name":"\u00d3bidos"},
 {"airport_iata_code":"TFL", "airport_name":"Juscelino Kubitscheck Airport", "city_name":"Te\u00f3filo Otoni"},
 {"airport_iata_code":"VAL", "airport_name":"Valen\u00e7a Airport", "city_name":"Valen\u00e7a"},
 {"airport_iata_code":"QID", "airport_name":"M\u00e9lio Viana Airport", "city_name":"Tr\u00eas Cora\u00e7\u00f5es"},
 {"airport_iata_code":"BVS", "airport_name":"Breves Airport", "city_name":"Breves"},
 {"airport_iata_code":"CMC", "airport_name":"Camocim Airport", "city_name":"Camocim"},
 {"airport_iata_code":"QXC", "airport_name":"Fazenda S\u00e3o Braz Airport", "city_name":"Barra De Santo Antonio"},
 {"airport_iata_code":"PHI", "airport_name":"Pinheiro Airport", "city_name":"Pinheiro"},
 {"airport_iata_code":"ITI", "airport_name":"Agropecu\u00e1ria Castanhais Airport", "city_name":"Cumaru Do Norte"},
 {"airport_iata_code":"PPY", "airport_name":"Pouso Alegre Airport", "city_name":"Pouso Alegre"},
 {"airport_iata_code":"APU", "airport_name":"Apucarana Airport", "city_name":"Apucarana"},
 {"airport_iata_code":"BGV", "airport_name":"Aeroclube de Bento Gon\u00e7alves Airport", "city_name":"Bento Gon\u00e7alves"},
 {"airport_iata_code":"BNU", "airport_name":"Blumenau Airport", "city_name":"Blumenau"},
 {"airport_iata_code":"CCI", "airport_name":"Conc\u00f3rdia Airport", "city_name":"Conc\u00f3rdia"},
 {"airport_iata_code":"QCN", "airport_name":"Canela Airport", "city_name":"Canela"},
 {"airport_iata_code":"CKO", "airport_name":"Corn\u00e9lio Proc\u00f3pio Airport", "city_name":"Corn\u00e9lio Proc\u00f3pio"},
 {"airport_iata_code":"DOU", "airport_name":"Dourados Airport", "city_name":"Dourados"},
 {"airport_iata_code":"ERM", "airport_name":"Erechim Airport", "city_name":"Erechim"},
 {"airport_iata_code":"FBE", "airport_name":"Francisco Beltr\u00e3o Airport", "city_name":"Francisco Beltr\u00e3o"},
 {"airport_iata_code":"QGA", "airport_name":"Gua\u00edra Airport", "city_name":"Gua\u00edra"},
 {"airport_iata_code":"IJU", "airport_name":"Iju\u00ed Airport", "city_name":"Iju\u00ed"},
 {"airport_iata_code":"ITQ", "airport_name":"Itaqui Airport", "city_name":"Itaqui"},
 {"airport_iata_code":"JCB", "airport_name":"Santa Terezinha Airport", "city_name":"Joa\u00e7aba"},
 {"airport_iata_code":"CBW", "airport_name":"Campo Mour\u00e3o Airport", "city_name":"Campo Mour\u00e3o"},
 {"airport_iata_code":"QDB", "airport_name":"Cachoeira do Sul Airport", "city_name":"Cachoeira Do Sul"},
 {"airport_iata_code":"QCR", "airport_name":"Curitibanos Airport", "city_name":"Curitibanos"},
 {"airport_iata_code":"ALQ", "airport_name":"Alegrete Novo Airport", "city_name":"Alegrete"},
 {"airport_iata_code":"QMF", "airport_name":"Mafra Airport", "city_name":"Mafra"},
 {"airport_iata_code":"QGF", "airport_name":"Montenegro Airport", "city_name":"Montenegro"},
 {"airport_iata_code":"QHV", "airport_name":"Novo Hamburgo Airport", "city_name":"Novo Hamburgo"},
 {"airport_iata_code":"SQX", "airport_name":"S\u00e3o Miguel do Oeste Airport", "city_name":"S\u00e3o Miguel Do Oeste"},
 {"airport_iata_code":"APX", "airport_name":"Arapongas Airport", "city_name":"Arapongas"},
 {"airport_iata_code":"PTO", "airport_name":"Pato Branco Airport", "city_name":"Pato Branco"},
 {"airport_iata_code":"PNG", "airport_name":"Paranagu\u00e1 Airport", "city_name":"Paranagu\u00e1"},
 {"airport_iata_code":"PVI", "airport_name":"Paranava\u00ed Airport", "city_name":"Paranava\u00ed"},
 {"airport_iata_code":"PBB", "airport_name":"Parana\u00edba Airport", "city_name":"Parana\u00edba"},
 {"airport_iata_code":"QAC", "airport_name":"Castro Airport", "city_name":"Castro"},
 {"airport_iata_code":"SQY", "airport_name":"S\u00e3o Louren\u00e7o do Sul Airport", "city_name":"S\u00e3o Louren\u00e7o Do Sul"},
 {"airport_iata_code":"QOJ", "airport_name":"S\u00e3o Borja Airport", "city_name":"S\u00e3o Borja"},
 {"airport_iata_code":"CSU", "airport_name":"Santa Cruz do Sul Airport", "city_name":"Santa Cruz Do Sul"},
 {"airport_iata_code":"UMU", "airport_name":"Umuarama Airport", "city_name":"Umuarama"},
 {"airport_iata_code":"QVB", "airport_name":"Uni\u00e3o da Vit\u00f3ria Airport", "city_name":"Uni\u00e3o Da Vit\u00f3ria"},
 {"airport_iata_code":"VIA", "airport_name":"Videira Airport", "city_name":"Videira"},
 {"airport_iata_code":"CTQ", "airport_name":"Santa Vit\u00f3ria do Palmar Airport", "city_name":"Santa Vit\u00f3ria Do Palmar"},
 {"airport_iata_code":"AXE", "airport_name":"Xanxer\u00ea Airport", "city_name":"Xanxer\u00ea"},
 {"airport_iata_code":"AAG", "airport_name":"Arapoti Airport", "city_name":"Arapoti"},
 {"airport_iata_code":"SRA", "airport_name":"Santa Rosa Airport", "city_name":"Santa Rosa"},
 {"airport_iata_code":"PGZ", "airport_name":"Ponta Grossa Airport", "city_name":"Ponta Grossa"},
 {"airport_iata_code":"BAZ", "airport_name":"Barcelos Airport", "city_name":"Barcelos"},
 {"airport_iata_code":"RBB", "airport_name":"Borba Airport", "city_name":"Borba"},
 {"airport_iata_code":"CAF", "airport_name":"Carauari Airport", "city_name":"Carauari"},
 {"airport_iata_code":"CQS", "airport_name":"Costa Marques Airport", "city_name":"Costa Marques"},
 {"airport_iata_code":"DMT", "airport_name":"Diamantino Airport", "city_name":"Diamantino"},
 {"airport_iata_code":"DNO", "airport_name":"Dian\u00f3polis Airport", "city_name":"Dian\u00f3polis"},
 {"airport_iata_code":"ERN", "airport_name":"Eirunep\u00e9 Airport", "city_name":"Eirunep\u00e9"},
 {"airport_iata_code":"CQA", "airport_name":"Canarana Airport", "city_name":"Canarana"},
 {"airport_iata_code":"FEJ", "airport_name":"Feij\u00f3 Airport", "city_name":"Feij\u00f3"},
 {"airport_iata_code":"SXO", "airport_name":"S\u00e3o F\u00e9lix do Araguaia Airport", "city_name":"S\u00e3o F\u00e9lix Do Araguaia"},
 {"airport_iata_code":"GRP", "airport_name":"Gurupi Airport", "city_name":"Gurupi"},
 {"airport_iata_code":"AUX", "airport_name":"Aragua\u00edna Airport", "city_name":"Aragua\u00edna"},
 {"airport_iata_code":"IPG", "airport_name":"Ipiranga Airport", "city_name":"Santo Ant\u00f4nio Do I\u00e7\u00e1"},
 {"airport_iata_code":"IDO", "airport_name":"Santa Izabel do Morro Airport", "city_name":"Cristal\u00e2ndia"},
 {"airport_iata_code":"JPR", "airport_name":"Ji-Paran\u00e1 Airport", "city_name":"Ji-Paran\u00e1"},
 {"airport_iata_code":"JIA", "airport_name":"Ju\u00edna Airport", "city_name":"Ju\u00edna"},
 {"airport_iata_code":"JRN", "airport_name":"Juruena Airport", "city_name":"Juruena"},
 {"airport_iata_code":"CCX", "airport_name":"C\u00e1ceres Airport", "city_name":"C\u00e1ceres"},
 {"airport_iata_code":"CIZ", "airport_name":"Coari Airport", "city_name":"Coari"},
 {"airport_iata_code":"TLZ", "airport_name":"Catal\u00e3o Airport", "city_name":"Catal\u00e3o"},
 {"airport_iata_code":"LBR", "airport_name":"L\u00e1brea Airport", "city_name":"L\u00e1brea"},
 {"airport_iata_code":"RVD", "airport_name":"General Leite de Castro Airport", "city_name":"Rio Verde"},
 {"airport_iata_code":"MBZ", "airport_name":"Mau\u00e9s Airport", "city_name":"Mau\u00e9s"},
 {"airport_iata_code":"NVP", "airport_name":"Novo Aripuan\u00e3 Airport", "city_name":"Novo Aripuan\u00e3"},
 {"airport_iata_code":"AQM", "airport_name":"Nova Vida Airport", "city_name":"Ariquemes"},
 {"airport_iata_code":"BCR", "airport_name":"Novo Campo Airport", "city_name":"Boca Do Acre"},
 {"airport_iata_code":"NQL", "airport_name":"Niquel\u00e2ndia Airport", "city_name":"Niquel\u00e2ndia"},
 {"airport_iata_code":"FBA", "airport_name":"Fonte Boa Airport", "city_name":"Fonte Boa"},
 {"airport_iata_code":"PIN", "airport_name":"Parintins Airport", "city_name":"Parintins"},
 {"airport_iata_code":"PBQ", "airport_name":"Pimenta Bueno Airport", "city_name":"Pimenta Bueno"},
 {"airport_iata_code":"AAI", "airport_name":"Arraias Airport", "city_name":"Arraias"},
 {"airport_iata_code":"ROO", "airport_name":"Rondon\u00f3polis Airport", "city_name":"Rondon\u00f3polis"},
 {"airport_iata_code":"AIR", "airport_name":"Aripuan\u00e3 Airport", "city_name":"Aripuan\u00e3"},
 {"airport_iata_code":"OPS", "airport_name":"Presidente Jo\u00e3o Batista Figueiredo Airport", "city_name":"Sinop"},
 {"airport_iata_code":"STZ", "airport_name":"Santa Terezinha Airport", "city_name":"Santa Terezinha"},
 {"airport_iata_code":"IRZ", "airport_name":"Tapuruquara Airport", "city_name":"Santa Isabel Do Rio Negro"},
 {"airport_iata_code":"TGQ", "airport_name":"Tangar\u00e1 da Serra Airport", "city_name":"Tangar\u00e1 Da Serra"},
 {"airport_iata_code":"AZL", "airport_name":"Fazenda Tucunar\u00e9 Airport", "city_name":"Sapezal"},
 {"airport_iata_code":"QHN", "airport_name":"Taguatinga Airport", "city_name":"Taguatinga"},
 {"airport_iata_code":"SQM", "airport_name":"S\u00e3o Miguel do Araguaia Airport", "city_name":"S\u00e3o Miguel Do Araguaia"},
 {"airport_iata_code":"VLP", "airport_name":"Vila Rica Airport", "city_name":"Vila Rica"},
 {"airport_iata_code":"MBK", "airport_name":"Regional Orlando Villas Boas Airport", "city_name":"Matup\u00e1"},
 {"airport_iata_code":"NOK", "airport_name":"Xavantina Airport", "city_name":"Nova Xavantina"},
 {"airport_iata_code":"MAY", "airport_name":"Clarence A. Bain Airport", "city_name":"Mangrove Cay"},
 {"airport_iata_code":"ASD", "airport_name":"Andros Town Airport", "city_name":"Andros Town"},
 {"airport_iata_code":"COX", "airport_name":"Congo Town Airport", "city_name":"Andros"},
 {"airport_iata_code":"MHH", "airport_name":"Marsh Harbour International Airport", "city_name":"Marsh Harbour"},
 {"airport_iata_code":"SAQ", "airport_name":"San Andros Airport", "city_name":"Andros Island"},
 {"airport_iata_code":"AXP", "airport_name":"Spring Point Airport", "city_name":"Spring Point"},
 {"airport_iata_code":"TCB", "airport_name":"Treasure Cay Airport", "city_name":"Treasure Cay"},
 {"airport_iata_code":"WKR", "airport_name":"Abaco I Walker C Airport", "city_name":"Walker's Cay"},
 {"airport_iata_code":"CCZ", "airport_name":"Chub Cay Airport", "city_name":""},
 {"airport_iata_code":"GHC", "airport_name":"Great Harbour Cay Airport", "city_name":""},
 {"airport_iata_code":"BIM", "airport_name":"South Bimini Airport", "city_name":"South Bimini"},
 {"airport_iata_code":"ATC", "airport_name":"Arthur's Town Airport", "city_name":"Arthur's Town"},
 {"airport_iata_code":"CAT", "airport_name":"New Bight Airport", "city_name":"Cat Island"},
 {"airport_iata_code":"CXY", "airport_name":"Cat Cay Airport", "city_name":"Cat Cay"},
 {"airport_iata_code":"CRI", "airport_name":"Colonel Hill Airport", "city_name":"Colonel Hill"},
 {"airport_iata_code":"PWN", "airport_name":"Pitts Town Airport", "city_name":"Pitts Town"},
 {"airport_iata_code":"GGT", "airport_name":"Exuma International Airport", "city_name":"George Town"},
 {"airport_iata_code":"ELH", "airport_name":"North Eleuthera Airport", "city_name":"North Eleuthera"},
 {"airport_iata_code":"GHB", "airport_name":"Governor's Harbour Airport", "city_name":"Governor's Harbour"},
 {"airport_iata_code":"NMC", "airport_name":"Normans Cay Airport", "city_name":""},
 {"airport_iata_code":"RSD", "airport_name":"Rock Sound Airport", "city_name":"Rock Sound"},
 {"airport_iata_code":"TYM", "airport_name":"Staniel Cay Airport", "city_name":""},
 {"airport_iata_code":"FPO", "airport_name":"Grand Bahama International Airport", "city_name":"Freeport"},
 {"airport_iata_code":"WTD", "airport_name":"West End Airport", "city_name":"West End"},
 {"airport_iata_code":"IGA", "airport_name":"Inagua Airport", "city_name":"Matthew Town"},
 {"airport_iata_code":"LGI", "airport_name":"Deadman's Cay Airport", "city_name":"Deadman's Cay"},
 {"airport_iata_code":"SML", "airport_name":"Stella Maris Airport", "city_name":"Stella Maris"},
 {"airport_iata_code":"MYG", "airport_name":"Mayaguana Airport", "city_name":"Mayaguana"},
 {"airport_iata_code":"NAS", "airport_name":"Lynden Pindling International Airport", "city_name":"Nassau"},
 {"airport_iata_code":"PID", "airport_name":"Nassau Paradise Island Airport", "city_name":"Nassau"},
 {"airport_iata_code":"DCT", "airport_name":"Duncan Town Airport", "city_name":""},
 {"airport_iata_code":"RCY", "airport_name":"Rum Cay Airport", "city_name":""},
 {"airport_iata_code":"ZSA", "airport_name":"San Salvador Airport", "city_name":"San Salvador"},
 {"airport_iata_code":"PBH", "airport_name":"Paro Airport", "city_name":"Paro"},
 {"airport_iata_code":"HUK", "airport_name":"Hukuntsi Airport", "city_name":"Hukuntsi"},
 {"airport_iata_code":"FRW", "airport_name":"Francistown Airport", "city_name":"Francistown"},
 {"airport_iata_code":"GNZ", "airport_name":"Ghanzi Airport", "city_name":"Ghanzi"},
 {"airport_iata_code":"JWA", "airport_name":"Jwaneng Airport", "city_name":""},
 {"airport_iata_code":"BBK", "airport_name":"Kasane Airport", "city_name":"Kasane"},
 {"airport_iata_code":"KHW", "airport_name":"Khwai River Lodge Airport", "city_name":"Khwai River Lodge"},
 {"airport_iata_code":"MUB", "airport_name":"Maun Airport", "city_name":"Maun"},
 {"airport_iata_code":"ORP", "airport_name":"Orapa Airport", "city_name":""},
 {"airport_iata_code":"QPH", "airport_name":"Palapye Airport", "city_name":"Palapye"},
 {"airport_iata_code":"GBE", "airport_name":"Sir Seretse Khama International Airport", "city_name":"Gaborone"},
 {"airport_iata_code":"SXN", "airport_name":"Sua Pan Airport", "city_name":""},
 {"airport_iata_code":"PKW", "airport_name":"Selebi Phikwe Airport", "city_name":""},
 {"airport_iata_code":"SWX", "airport_name":"Shakawe Airport", "city_name":"Shakawe"},
 {"airport_iata_code":"TLD", "airport_name":"Limpopo Valley Airport", "city_name":"Tuli Lodge"},
 {"airport_iata_code":"TBY", "airport_name":"Tshabong Airport", "city_name":"Tshabong"},
 {"airport_iata_code":"BQT", "airport_name":"Brest Airport", "city_name":"Brest"},
 {"airport_iata_code":"GME", "airport_name":"Gomel Airport", "city_name":"Gomel"},
 {"airport_iata_code":"VTB", "airport_name":"Vitebsk East Airport", "city_name":"Vitebsk"},
 {"airport_iata_code":"GNA", "airport_name":"Hrodna Airport", "city_name":"Hrodna"},
 {"airport_iata_code":"MHP", "airport_name":"Minsk 1 Airport", "city_name":"Minsk"},
 {"airport_iata_code":"MSQ", "airport_name":"Minsk International Airport", "city_name":"Minsk"},
 {"airport_iata_code":"MVQ", "airport_name":"Mogilev Airport", "city_name":"Mogilev"},
 {"airport_iata_code":"BZE", "airport_name":"Philip S. W. Goldson International Airport", "city_name":"Belize City"},
 {"airport_iata_code":"BCV", "airport_name":"Belmopan Airport", "city_name":"Belmopan"},
 {"airport_iata_code":"BGK", "airport_name":"Big Creek Airport", "city_name":"Big Creek"},
 {"airport_iata_code":"CUK", "airport_name":"Caye Caulker Airport", "city_name":"Caye Caulker"},
 {"airport_iata_code":"CYC", "airport_name":"Caye Chapel Airport", "city_name":"Caye Chapel"},
 {"airport_iata_code":"CZH", "airport_name":"Corozal Municipal Airport", "city_name":"Corozal"},
 {"airport_iata_code":"DGA", "airport_name":"Dangriga Airport", "city_name":"Dangriga"},
 {"airport_iata_code":"INB", "airport_name":"Independence Airport", "city_name":"Independence"},
 {"airport_iata_code":"MDB", "airport_name":"Melinda Airport", "city_name":"Melinda"},
 {"airport_iata_code":"ORZ", "airport_name":"Orange Walk Airport", "city_name":"Orange Walk"},
 {"airport_iata_code":"PLJ", "airport_name":"Placencia Airport", "city_name":"Placencia"},
 {"airport_iata_code":"PND", "airport_name":"Punta Gorda Airport", "city_name":"Punta Gorda"},
 {"airport_iata_code":"SJX", "airport_name":"Sartaneja Airport", "city_name":"Sartaneja"},
 {"airport_iata_code":"SPR", "airport_name":"San Pedro Airport", "city_name":"San Pedro"},
 {"airport_iata_code":"SQS", "airport_name":"Matthew Spain Airport", "city_name":"San Ignacio"},
 {"airport_iata_code":"STU", "airport_name":"Santa Cruz Airport", "city_name":"Santa Cruz"},
 {"airport_iata_code":"SVK", "airport_name":"Silver Creek Airport", "city_name":"Silver Creek"},
 {"airport_iata_code":"TZA", "airport_name":"Belize City Municipal Airport", "city_name":"Belize City"},
 {"airport_iata_code":"YMV", "airport_name":"Mary River Aerodrome", "city_name":""},
 {"airport_iata_code":"YZZ", "airport_name":"Trail Airport", "city_name":"Trail"},
 {"airport_iata_code":"YAA", "airport_name":"Anahim Lake Airport", "city_name":"Anahim Lake"},
 {"airport_iata_code":"DGF", "airport_name":"Douglas Lake Airport", "city_name":"Douglas Lake"},
 {"airport_iata_code":"JHL", "airport_name":"Fort MacKay/Albian Aerodrome", "city_name":"Albian Village"},
 {"airport_iata_code":"DUQ", "airport_name":"Duncan Airport", "city_name":"Duncan"},
 {"airport_iata_code":"YWM", "airport_name":"Williams Harbour Airport", "city_name":"Williams Harbour"},
 {"airport_iata_code":"YFX", "airport_name":"St. Lewis (Fox Harbour) Airport", "city_name":"St. Lewis"},
 {"airport_iata_code":"YHA", "airport_name":"Port Hope Simpson Airport", "city_name":"Port Hope Simpson"},
 {"airport_iata_code":"YRG", "airport_name":"Rigolet Airport", "city_name":"Rigolet"},
 {"airport_iata_code":"DVK", "airport_name":"Diavik Airport", "city_name":"Diavik"},
 {"airport_iata_code":"YCK", "airport_name":"Colville Lake Airport", "city_name":"Colville Lake"},
 {"airport_iata_code":"ZFW", "airport_name":"Fairview Airport", "city_name":"Fairview"},
 {"airport_iata_code":"YLE", "airport_name":"What\u00ec Airport", "city_name":"What\u00ec"},
 {"airport_iata_code":"NML", "airport_name":"Fort McMurray / Mildred Lake Airport", "city_name":"Fort McMurray"},
 {"airport_iata_code":"ZSP", "airport_name":"St. Paul Airport", "city_name":"St. Paul"},
 {"airport_iata_code":"DAS", "airport_name":"Great Bear Lake Airport", "city_name":"Great Bear Lake"},
 {"airport_iata_code":"YFI", "airport_name":"Fort Mackay / Firebag", "city_name":"Suncor Energy Site"},
 {"airport_iata_code":"SUR", "airport_name":"Summer Beaver Airport", "city_name":"Summer Beaver"},
 {"airport_iata_code":"YAX", "airport_name":"Wapekeka Airport", "city_name":"Angling Lake"},
 {"airport_iata_code":"WNN", "airport_name":"Wunnumin Lake Airport", "city_name":"Wunnumin Lake"},
 {"airport_iata_code":"YNO", "airport_name":"North Spirit Lake Airport", "city_name":"North Spirit Lake"},
 {"airport_iata_code":"XBE", "airport_name":"Bearskin Lake Airport", "city_name":"Bearskin Lake"},
 {"airport_iata_code":"KIF", "airport_name":"Kingfisher Lake Airport", "city_name":"Kingfisher Lake"},
 {"airport_iata_code":"YOG", "airport_name":"Ogoki Post Airport", "city_name":"Ogoki Post"},
 {"airport_iata_code":"YEB", "airport_name":"Bar River Airport", "city_name":"Bar River"},
 {"airport_iata_code":"YHP", "airport_name":"Poplar Hill Airport", "city_name":"Poplar Hill"},
 {"airport_iata_code":"YNX", "airport_name":"Snap Lake Airport", "city_name":"Snap Lake"},
 {"airport_iata_code":"YKU", "airport_name":"Chisasibi Airport", "city_name":"Chisasibi"},
 {"airport_iata_code":"ZTB", "airport_name":"T\u00eate-\u00e0-la-Baleine Airport", "city_name":"T\u00eate-\u00e0-la-Baleine"},
 {"airport_iata_code":"ZKG", "airport_name":"Kegaska Airport", "city_name":"Kegaska"},
 {"airport_iata_code":"YAU", "airport_name":"Donaldson Airport", "city_name":"Kattiniq"},
 {"airport_iata_code":"ZLT", "airport_name":"La Tabati\u00e8re Airport", "city_name":"La Tabati\u00e8re"},
 {"airport_iata_code":"YAC", "airport_name":"Cat Lake Airport", "city_name":"Cat Lake"},
 {"airport_iata_code":"YAR", "airport_name":"La Grande-3 Airport", "city_name":"La Grande-3"},
 {"airport_iata_code":"YAG", "airport_name":"Fort Frances Municipal Airport", "city_name":"Fort Frances"},
 {"airport_iata_code":"YAH", "airport_name":"La Grande-4 Airport", "city_name":"La Grande-4"},
 {"airport_iata_code":"YAL", "airport_name":"Alert Bay Airport", "city_name":"Alert Bay"},
 {"airport_iata_code":"YAM", "airport_name":"Sault Ste Marie Airport", "city_name":"Sault Ste Marie"},
 {"airport_iata_code":"XKS", "airport_name":"Kasabonika Airport", "city_name":"Kasabonika"},
 {"airport_iata_code":"YKG", "airport_name":"Kangirsuk Airport", "city_name":"Kangirsuk"},
 {"airport_iata_code":"YAT", "airport_name":"Attawapiskat Airport", "city_name":"Attawapiskat"},
 {"airport_iata_code":"YAY", "airport_name":"St. Anthony Airport", "city_name":"St. Anthony"},
 {"airport_iata_code":"YAZ", "airport_name":"Tofino / Long Beach Airport", "city_name":"Tofino"},
 {"airport_iata_code":"YBA", "airport_name":"Banff Airport", "city_name":"Banff"},
 {"airport_iata_code":"YBB", "airport_name":"Kugaaruk Airport", "city_name":"Kugaaruk"},
 {"airport_iata_code":"YBC", "airport_name":"Baie Comeau Airport", "city_name":"Baie-Comeau"},
 {"airport_iata_code":"QBC", "airport_name":"Bella Coola Airport", "city_name":"Bella Coola"},
 {"airport_iata_code":"YBE", "airport_name":"Uranium City Airport", "city_name":"Uranium City"},
 {"airport_iata_code":"YBY", "airport_name":"Bonnyville Airport", "city_name":"Bonnyville"},
 {"airport_iata_code":"YBG", "airport_name":"CFB Bagotville", "city_name":"Bagotville"},
 {"airport_iata_code":"YBK", "airport_name":"Baker Lake Airport", "city_name":"Baker Lake"},
 {"airport_iata_code":"YBL", "airport_name":"Campbell River Airport", "city_name":"Campbell River"},
 {"airport_iata_code":"XTL", "airport_name":"Tadoule Lake Airport", "city_name":"Tadoule Lake"},
 {"airport_iata_code":"YBR", "airport_name":"Brandon Municipal Airport", "city_name":"Brandon"},
 {"airport_iata_code":"YBT", "airport_name":"Brochet Airport", "city_name":"Brochet"},
 {"airport_iata_code":"YBV", "airport_name":"Berens River Airport", "city_name":"Berens River"},
 {"airport_iata_code":"YBX", "airport_name":"Lourdes de Blanc Sablon Airport", "city_name":"Lourdes-De-Blanc-Sablon"},
 {"airport_iata_code":"YRF", "airport_name":"Cartwright Airport", "city_name":"Cartwright"},
 {"airport_iata_code":"YCB", "airport_name":"Cambridge Bay Airport", "city_name":"Cambridge Bay"},
 {"airport_iata_code":"YCC", "airport_name":"Cornwall Regional Airport", "city_name":"Cornwall"},
 {"airport_iata_code":"YCD", "airport_name":"Nanaimo Airport", "city_name":"Nanaimo"},
 {"airport_iata_code":"YCE", "airport_name":"James T. Field Memorial Aerodrome", "city_name":"Centralia"},
 {"airport_iata_code":"YCG", "airport_name":"Castlegar/West Kootenay Regional Airport", "city_name":"Castlegar"},
 {"airport_iata_code":"YCH", "airport_name":"Miramichi Airport", "city_name":"Miramichi"},
 {"airport_iata_code":"XCM", "airport_name":"Chatham Kent Airport", "city_name":"Chatham-Kent"},
 {"airport_iata_code":"YCL", "airport_name":"Charlo Airport", "city_name":"Charlo"},
 {"airport_iata_code":"YCN", "airport_name":"Cochrane Airport", "city_name":"Cochrane"},
 {"airport_iata_code":"YCO", "airport_name":"Kugluktuk Airport", "city_name":"Kugluktuk"},
 {"airport_iata_code":"YCQ", "airport_name":"Chetwynd Airport", "city_name":"Chetwynd"},
 {"airport_iata_code":"YCR", "airport_name":"Cross Lake (Charlie Sinclair Memorial) Airport", "city_name":"Cross Lake"},
 {"airport_iata_code":"YCS", "airport_name":"Chesterfield Inlet Airport", "city_name":"Chesterfield Inlet"},
 {"airport_iata_code":"YCT", "airport_name":"Coronation Airport", "city_name":"Coronation"},
 {"airport_iata_code":"YCW", "airport_name":"Chilliwack Airport", "city_name":"Chilliwack"},
 {"airport_iata_code":"YCY", "airport_name":"Clyde River Airport", "city_name":"Clyde River"},
 {"airport_iata_code":"YCZ", "airport_name":"Fairmont Hot Springs Airport", "city_name":"Fairmont Hot Springs"},
 {"airport_iata_code":"YDA", "airport_name":"Dawson City Airport", "city_name":"Dawson City"},
 {"airport_iata_code":"YDB", "airport_name":"Burwash Airport", "city_name":"Burwash"},
 {"airport_iata_code":"YDF", "airport_name":"Deer Lake Airport", "city_name":"Deer Lake"},
 {"airport_iata_code":"YDL", "airport_name":"Dease Lake Airport", "city_name":"Dease Lake"},
 {"airport_iata_code":"XRR", "airport_name":"Ross River Airport", "city_name":"Ross River"},
 {"airport_iata_code":"YDN", "airport_name":"Dauphin Barker Airport", "city_name":"Dauphin"},
 {"airport_iata_code":"YDO", "airport_name":"Dolbeau St Felicien Airport", "city_name":"Dolbeau-St-F\u00e9licien"},
 {"airport_iata_code":"YDP", "airport_name":"Nain Airport", "city_name":"Nain"},
 {"airport_iata_code":"YDQ", "airport_name":"Dawson Creek Airport", "city_name":"Dawson Creek"},
 {"airport_iata_code":"YEG", "airport_name":"Edmonton International Airport", "city_name":"Edmonton"},
 {"airport_iata_code":"YEK", "airport_name":"Arviat Airport", "city_name":"Arviat"},
 {"airport_iata_code":"YEL", "airport_name":"Elliot Lake Municipal Airport", "city_name":"Elliot Lake"},
 {"airport_iata_code":"YEM", "airport_name":"Manitoulin East Municipal Airport", "city_name":"Manitowaning"},
 {"airport_iata_code":"YEN", "airport_name":"Estevan Airport", "city_name":"Estevan"},
 {"airport_iata_code":"YER", "airport_name":"Fort Severn Airport", "city_name":"Fort Severn"},
 {"airport_iata_code":"YET", "airport_name":"Edson Airport", "city_name":"Edson"},
 {"airport_iata_code":"YEU", "airport_name":"Eureka Airport", "city_name":"Eureka"},
 {"airport_iata_code":"YEV", "airport_name":"Inuvik Mike Zubko Airport", "city_name":"Inuvik"},
 {"airport_iata_code":"YEY", "airport_name":"Amos Magny Airport", "city_name":"Amos"},
 {"airport_iata_code":"YFA", "airport_name":"Fort Albany Airport", "city_name":"Fort Albany"},
 {"airport_iata_code":"YFB", "airport_name":"Iqaluit Airport", "city_name":"Iqaluit"},
 {"airport_iata_code":"YFC", "airport_name":"Fredericton Airport", "city_name":"Fredericton"},
 {"airport_iata_code":"YFE", "airport_name":"Forestville Airport", "city_name":"Forestville"},
 {"airport_iata_code":"YFH", "airport_name":"Fort Hope Airport", "city_name":"Fort Hope"},
 {"airport_iata_code":"YTM", "airport_name":"La Macaza / Mont-Tremblant International Inc Airport", "city_name":"Rivi\u00e8re Rouge"},
 {"airport_iata_code":"YFO", "airport_name":"Flin Flon Airport", "city_name":"Flin Flon"},
 {"airport_iata_code":"YFR", "airport_name":"Fort Resolution Airport", "city_name":"Fort Resolution"},
 {"airport_iata_code":"YFS", "airport_name":"Fort Simpson Airport", "city_name":"Fort Simpson"},
 {"airport_iata_code":"YMN", "airport_name":"Makkovik Airport", "city_name":"Makkovik"},
 {"airport_iata_code":"YGB", "airport_name":"Texada Gillies Bay Airport", "city_name":"Texada"},
 {"airport_iata_code":"YGH", "airport_name":"Fort Good Hope Airport", "city_name":"Fort Good Hope"},
 {"airport_iata_code":"YGK", "airport_name":"Kingston Norman Rogers Airport", "city_name":"Kingston"},
 {"airport_iata_code":"YGL", "airport_name":"La Grande Rivi\u00e8re Airport", "city_name":"La Grande Rivi\u00e8re"},
 {"airport_iata_code":"YGM", "airport_name":"Gimli Industrial Park Airport", "city_name":"Gimli"},
 {"airport_iata_code":"YGO", "airport_name":"Gods Lake Narrows Airport", "city_name":"Gods Lake Narrows"},
 {"airport_iata_code":"YGP", "airport_name":"Gasp\u00e9 (Michel-Pouliot) Airport", "city_name":"Gasp\u00e9"},
 {"airport_iata_code":"YGQ", "airport_name":"Geraldton Greenstone Regional Airport", "city_name":"Geraldton"},
 {"airport_iata_code":"YGR", "airport_name":"\u00celes-de-la-Madeleine Airport", "city_name":"\u00celes-de-la-Madeleine"},
 {"airport_iata_code":"YGT", "airport_name":"Igloolik Airport", "city_name":"Igloolik"},
 {"airport_iata_code":"YGV", "airport_name":"Havre St Pierre Airport", "city_name":"Havre St-Pierre"},
 {"airport_iata_code":"YGW", "airport_name":"Kuujjuarapik Airport", "city_name":"Kuujjuarapik"},
 {"airport_iata_code":"YGX", "airport_name":"Gillam Airport", "city_name":"Gillam"},
 {"airport_iata_code":"YGZ", "airport_name":"Grise Fiord Airport", "city_name":"Grise Fiord"},
 {"airport_iata_code":"YQC", "airport_name":"Quaqtaq Airport", "city_name":"Quaqtaq"},
 {"airport_iata_code":"YHB", "airport_name":"Hudson Bay Airport", "city_name":"Hudson Bay"},
 {"airport_iata_code":"YHD", "airport_name":"Dryden Regional Airport", "city_name":"Dryden"},
 {"airport_iata_code":"YHE", "airport_name":"Hope Airport", "city_name":"Hope"},
 {"airport_iata_code":"YHF", "airport_name":"Hearst Ren\u00e9 Fontaine Municipal Airport", "city_name":"Hearst"},
 {"airport_iata_code":"YNS", "airport_name":"Nemiscau Airport", "city_name":"Nemiscau"},
 {"airport_iata_code":"YHI", "airport_name":"Ulukhaktok Holman Airport", "city_name":"Ulukhaktok"},
 {"airport_iata_code":"YHK", "airport_name":"Gjoa Haven Airport", "city_name":"Gjoa Haven"},
 {"airport_iata_code":"YHM", "airport_name":"John C. Munro Hamilton International Airport", "city_name":"Hamilton"},
 {"airport_iata_code":"YHN", "airport_name":"Hornepayne Municipal Airport", "city_name":"Hornepayne"},
 {"airport_iata_code":"YHO", "airport_name":"Hopedale Airport", "city_name":"Hopedale"},
 {"airport_iata_code":"YHR", "airport_name":"Chevery Airport", "city_name":"Chevery"},
 {"airport_iata_code":"YHT", "airport_name":"Haines Junction Airport", "city_name":"Haines Junction"},
 {"airport_iata_code":"YHU", "airport_name":"Montr\u00e9al / Saint-Hubert Airport", "city_name":"Montr\u00e9al"},
 {"airport_iata_code":"YHY", "airport_name":"Hay River / Merlyn Carter Airport", "city_name":"Hay River"},
 {"airport_iata_code":"YHZ", "airport_name":"Halifax / Stanfield International Airport", "city_name":"Halifax"},
 {"airport_iata_code":"YIB", "airport_name":"Atikokan Municipal Airport", "city_name":"Atikokan"},
 {"airport_iata_code":"YDG", "airport_name":"Digby / Annapolis Regional Airport", "city_name":"Digby"},
 {"airport_iata_code":"YIF", "airport_name":"St Augustin Airport", "city_name":"St-Augustin"},
 {"airport_iata_code":"YIK", "airport_name":"Ivujivik Airport", "city_name":"Ivujivik"},
 {"airport_iata_code":"YIO", "airport_name":"Pond Inlet Airport", "city_name":"Pond Inlet"},
 {"airport_iata_code":"YIV", "airport_name":"Island Lake Airport", "city_name":"Island Lake"},
 {"airport_iata_code":"YJF", "airport_name":"Fort Liard Airport", "city_name":"Fort Liard"},
 {"airport_iata_code":"YJN", "airport_name":"St Jean Airport", "city_name":"St Jean"},
 {"airport_iata_code":"ZEL", "airport_name":"Denny Island Airport", "city_name":"Bella Bella"},
 {"airport_iata_code":"YJT", "airport_name":"Stephenville Airport", "city_name":"Stephenville"},
 {"airport_iata_code":"YKA", "airport_name":"Kamloops Airport", "city_name":"Kamloops"},
 {"airport_iata_code":"YKC", "airport_name":"Collins Bay Airport", "city_name":"Collins Bay"},
 {"airport_iata_code":"LAK", "airport_name":"Aklavik Airport", "city_name":"Aklavik"},
 {"airport_iata_code":"YKF", "airport_name":"Waterloo Airport", "city_name":"Kitchener"},
 {"airport_iata_code":"YWB", "airport_name":"Kangiqsujuaq (Wakeham Bay) Airport", "city_name":"Kangiqsujuaq"},
 {"airport_iata_code":"YKJ", "airport_name":"Key Lake Airport", "city_name":"Key Lake"},
 {"airport_iata_code":"YKL", "airport_name":"Schefferville Airport", "city_name":"Schefferville"},
 {"airport_iata_code":"AKV", "airport_name":"Akulivik Airport", "city_name":"Akulivik"},
 {"airport_iata_code":"YKQ", "airport_name":"Waskaganish Airport", "city_name":"Waskaganish"},
 {"airport_iata_code":"YKX", "airport_name":"Kirkland Lake Airport", "city_name":"Kirkland Lake"},
 {"airport_iata_code":"YKY", "airport_name":"Kindersley Airport", "city_name":"Kindersley"},
 {"airport_iata_code":"YKZ", "airport_name":"Buttonville Municipal Airport", "city_name":"Toronto"},
 {"airport_iata_code":"YPJ", "airport_name":"Aupaluk Airport", "city_name":"Aupaluk"},
 {"airport_iata_code":"YLB", "airport_name":"Lac La Biche Airport", "city_name":"Lac La Biche"},
 {"airport_iata_code":"YLC", "airport_name":"Kimmirut Airport", "city_name":"Kimmirut"},
 {"airport_iata_code":"YLD", "airport_name":"Chapleau Airport", "city_name":"Chapleau"},
 {"airport_iata_code":"YLH", "airport_name":"Lansdowne House Airport", "city_name":"Lansdowne House"},
 {"airport_iata_code":"YLJ", "airport_name":"Meadow Lake Airport", "city_name":"Meadow Lake"},
 {"airport_iata_code":"YSG", "airport_name":"Lutselk'e Airport", "city_name":"Lutselk'e"},
 {"airport_iata_code":"YLL", "airport_name":"Lloydminster Airport", "city_name":"Lloydminster"},
 {"airport_iata_code":"YLQ", "airport_name":"La Tuque Airport", "city_name":"La Tuque"},
 {"airport_iata_code":"YLR", "airport_name":"Leaf Rapids Airport", "city_name":"Leaf Rapids"},
 {"airport_iata_code":"YLK", "airport_name":"Barrie-Orillia (Lake Simcoe Regional Airport)", "city_name":"Barrie-Orillia"},
 {"airport_iata_code":"YLT", "airport_name":"Alert Airport", "city_name":"Alert"},
 {"airport_iata_code":"XGR", "airport_name":"Kangiqsualujjuaq (Georges River) Airport", "city_name":"Kangiqsualujjuaq"},
 {"airport_iata_code":"YLW", "airport_name":"Kelowna International Airport", "city_name":"Kelowna"},
 {"airport_iata_code":"YMA", "airport_name":"Mayo Airport", "city_name":"Mayo"},
 {"airport_iata_code":"YME", "airport_name":"Matane Airport", "city_name":"Matane"},
 {"airport_iata_code":"YMG", "airport_name":"Manitouwadge Airport", "city_name":"Manitouwadge"},
 {"airport_iata_code":"YMH", "airport_name":"Mary's Harbour Airport", "city_name":"Mary's Harbour"},
 {"airport_iata_code":"YMJ", "airport_name":"Moose Jaw Air Vice Marshal C. M. McEwen Airport", "city_name":"Moose Jaw"},
 {"airport_iata_code":"YML", "airport_name":"Charlevoix Airport", "city_name":"Charlevoix"},
 {"airport_iata_code":"YMM", "airport_name":"Fort McMurray Airport", "city_name":"Fort McMurray"},
 {"airport_iata_code":"YMO", "airport_name":"Moosonee Airport", "city_name":"Moosonee"},
 {"airport_iata_code":"YMT", "airport_name":"Chapais Airport", "city_name":"Chibougamau"},
 {"airport_iata_code":"YUD", "airport_name":"Umiujaq Airport", "city_name":"Umiujaq"},
 {"airport_iata_code":"YMW", "airport_name":"Maniwaki Airport", "city_name":"Maniwaki"},
 {"airport_iata_code":"YMX", "airport_name":"Montreal International (Mirabel) Airport", "city_name":"Montr\u00e9al"},
 {"airport_iata_code":"YNA", "airport_name":"Natashquan Airport", "city_name":"Natashquan"},
 {"airport_iata_code":"YNC", "airport_name":"Wemindji Airport", "city_name":"Wemindji"},
 {"airport_iata_code":"YND", "airport_name":"Ottawa / Gatineau Airport", "city_name":"Gatineau"},
 {"airport_iata_code":"YNE", "airport_name":"Norway House Airport", "city_name":"Norway House"},
 {"airport_iata_code":"YNH", "airport_name":"Hudsons Hope Airport", "city_name":"Hudson's Hope"},
 {"airport_iata_code":"YLY", "airport_name":"Langley Airport", "city_name":"Langley"},
 {"airport_iata_code":"YNL", "airport_name":"Points North Landing Airport", "city_name":"Points North Landing"},
 {"airport_iata_code":"YNM", "airport_name":"Matagami Airport", "city_name":"Matagami"},
 {"airport_iata_code":"HZP", "airport_name":"Fort Mackay / Horizon Airport", "city_name":"Fort Mackay"},
 {"airport_iata_code":"YOA", "airport_name":"Ekati Airport", "city_name":"Ekati"},
 {"airport_iata_code":"YOC", "airport_name":"Old Crow Airport", "city_name":"Old Crow"},
 {"airport_iata_code":"YOD", "airport_name":"CFB Cold Lake", "city_name":"Cold Lake"},
 {"airport_iata_code":"YOH", "airport_name":"Oxford House Airport", "city_name":"Oxford House"},
 {"airport_iata_code":"YOJ", "airport_name":"High Level Airport", "city_name":"High Level"},
 {"airport_iata_code":"YOO", "airport_name":"Oshawa Airport", "city_name":"Oshawa"},
 {"airport_iata_code":"YOP", "airport_name":"Rainbow Lake Airport", "city_name":"Rainbow Lake"},
 {"airport_iata_code":"YOS", "airport_name":"Owen Sound / Billy Bishop Regional Airport", "city_name":"Owen Sound"},
 {"airport_iata_code":"YOW", "airport_name":"Ottawa Macdonald-Cartier International Airport", "city_name":"Ottawa"},
 {"airport_iata_code":"YPA", "airport_name":"Prince Albert Glass Field", "city_name":"Prince Albert"},
 {"airport_iata_code":"YPC", "airport_name":"Paulatuk (Nora Aliqatchialuk Ruben) Airport", "city_name":"Paulatuk"},
 {"airport_iata_code":"YPS", "airport_name":"Port Hawkesbury Airport", "city_name":"Port Hawkesbury"},
 {"airport_iata_code":"YPE", "airport_name":"Peace River Airport", "city_name":"Peace River"},
 {"airport_iata_code":"YPG", "airport_name":"Southport Airport", "city_name":"Portage"},
 {"airport_iata_code":"YPH", "airport_name":"Inukjuak Airport", "city_name":"Inukjuak"},
 {"airport_iata_code":"YPL", "airport_name":"Pickle Lake Airport", "city_name":"Pickle Lake"},
 {"airport_iata_code":"YPM", "airport_name":"Pikangikum Airport", "city_name":"Pikangikum"},
 {"airport_iata_code":"YPN", "airport_name":"Port Menier Airport", "city_name":"Port-Menier"},
 {"airport_iata_code":"YPO", "airport_name":"Peawanuck Airport", "city_name":"Peawanuck"},
 {"airport_iata_code":"YPQ", "airport_name":"Peterborough Airport", "city_name":"Peterborough"},
 {"airport_iata_code":"YPR", "airport_name":"Prince Rupert Airport", "city_name":"Prince Rupert"},
 {"airport_iata_code":"YPW", "airport_name":"Powell River Airport", "city_name":"Powell River"},
 {"airport_iata_code":"YPX", "airport_name":"Puvirnituq Airport", "city_name":"Puvirnituq"},
 {"airport_iata_code":"YPY", "airport_name":"Fort Chipewyan Airport", "city_name":"Fort Chipewyan"},
 {"airport_iata_code":"YPZ", "airport_name":"Burns Lake Airport", "city_name":"Burns Lake"},
 {"airport_iata_code":"YQA", "airport_name":"Muskoka Airport", "city_name":"Muskoka"},
 {"airport_iata_code":"YQB", "airport_name":"Quebec Jean Lesage International Airport", "city_name":"Quebec"},
 {"airport_iata_code":"YQD", "airport_name":"The Pas Airport", "city_name":"The Pas"},
 {"airport_iata_code":"YQF", "airport_name":"Red Deer Regional Airport", "city_name":"Red Deer"},
 {"airport_iata_code":"YQG", "airport_name":"Windsor Airport", "city_name":"Windsor"},
 {"airport_iata_code":"YQH", "airport_name":"Watson Lake Airport", "city_name":"Watson Lake"},
 {"airport_iata_code":"YQI", "airport_name":"Yarmouth Airport", "city_name":"Yarmouth"},
 {"airport_iata_code":"YQK", "airport_name":"Kenora Airport", "city_name":"Kenora"},
 {"airport_iata_code":"YQL", "airport_name":"Lethbridge County Airport", "city_name":"Lethbridge"},
 {"airport_iata_code":"YQM", "airport_name":"Greater Moncton International Airport", "city_name":"Moncton"},
 {"airport_iata_code":"YQN", "airport_name":"Nakina Airport", "city_name":"Nakina"},
 {"airport_iata_code":"YQQ", "airport_name":"Comox Airport", "city_name":"Comox"},
 {"airport_iata_code":"YQR", "airport_name":"Regina International Airport", "city_name":"Regina"},
 {"airport_iata_code":"YQS", "airport_name":"St Thomas Municipal Airport", "city_name":"St Thomas"},
 {"airport_iata_code":"YQT", "airport_name":"Thunder Bay Airport", "city_name":"Thunder Bay"},
 {"airport_iata_code":"YQU", "airport_name":"Grande Prairie Airport", "city_name":"Grande Prairie"},
 {"airport_iata_code":"YQV", "airport_name":"Yorkton Municipal Airport", "city_name":"Yorkton"},
 {"airport_iata_code":"YQW", "airport_name":"North Battleford Airport", "city_name":"North Battleford"},
 {"airport_iata_code":"YQX", "airport_name":"Gander International Airport", "city_name":"Gander"},
 {"airport_iata_code":"YQY", "airport_name":"Sydney / J.A. Douglas McCurdy Airport", "city_name":"Sydney"},
 {"airport_iata_code":"YQZ", "airport_name":"Quesnel Airport", "city_name":"Quesnel"},
 {"airport_iata_code":"YRA", "airport_name":"Rae Lakes Airport", "city_name":"Gam\u00e8t\u00ec"},
 {"airport_iata_code":"YRB", "airport_name":"Resolute Bay Airport", "city_name":"Resolute Bay"},
 {"airport_iata_code":"YRI", "airport_name":"Rivi\u00e8re-du-Loup Airport", "city_name":"Rivi\u00e8re-du-Loup"},
 {"airport_iata_code":"YRJ", "airport_name":"Roberval Airport", "city_name":"Roberval"},
 {"airport_iata_code":"YRL", "airport_name":"Red Lake Airport", "city_name":"Red Lake"},
 {"airport_iata_code":"YRM", "airport_name":"Rocky Mountain House Airport", "city_name":"Rocky Mountain House"},
 {"airport_iata_code":"YRO", "airport_name":"Ottawa / Rockcliffe Airport", "city_name":"Ottawa"},
 {"airport_iata_code":"YRQ", "airport_name":"Trois-Rivi\u00e8res Airport", "city_name":"Trois-Rivi\u00e8res"},
 {"airport_iata_code":"YRS", "airport_name":"Red Sucker Lake Airport", "city_name":"Red Sucker Lake"},
 {"airport_iata_code":"YRT", "airport_name":"Rankin Inlet Airport", "city_name":"Rankin Inlet"},
 {"airport_iata_code":"YRV", "airport_name":"Revelstoke Airport", "city_name":"Revelstoke"},
 {"airport_iata_code":"YSB", "airport_name":"Sudbury Airport", "city_name":"Sudbury"},
 {"airport_iata_code":"YSC", "airport_name":"Sherbrooke Airport", "city_name":"Sherbrooke"},
 {"airport_iata_code":"YSE", "airport_name":"Squamish Airport", "city_name":"Squamish"},
 {"airport_iata_code":"YSF", "airport_name":"Stony Rapids Airport", "city_name":"Stony Rapids"},
 {"airport_iata_code":"YSH", "airport_name":"Smiths Falls-Montague (Russ Beach) Airport", "city_name":"Smiths Falls"},
 {"airport_iata_code":"YSJ", "airport_name":"Saint John Airport", "city_name":"Saint John"},
 {"airport_iata_code":"YSK", "airport_name":"Sanikiluaq Airport", "city_name":"Sanikiluaq"},
 {"airport_iata_code":"YSL", "airport_name":"St Leonard Airport", "city_name":"St Leonard"},
 {"airport_iata_code":"YSM", "airport_name":"Fort Smith Airport", "city_name":"Fort Smith"},
 {"airport_iata_code":"YCM", "airport_name":"Niagara District Airport", "city_name":"St Catharines"},
 {"airport_iata_code":"YSP", "airport_name":"Marathon Airport", "city_name":"Marathon"},
 {"airport_iata_code":"YST", "airport_name":"St. Theresa Point Airport", "city_name":"St. Theresa Point"},
 {"airport_iata_code":"YSU", "airport_name":"Summerside Airport", "city_name":"Summerside"},
 {"airport_iata_code":"YSY", "airport_name":"Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport", "city_name":"Sachs Harbour"},
 {"airport_iata_code":"YTA", "airport_name":"Pembroke Airport", "city_name":"Pembroke"},
 {"airport_iata_code":"YTE", "airport_name":"Cape Dorset Airport", "city_name":"Cape Dorset"},
 {"airport_iata_code":"YTF", "airport_name":"Alma Airport", "city_name":"Alma"},
 {"airport_iata_code":"YTH", "airport_name":"Thompson Airport", "city_name":"Thompson"},
 {"airport_iata_code":"YTL", "airport_name":"Big Trout Lake Airport", "city_name":"Big Trout Lake"},
 {"airport_iata_code":"YTQ", "airport_name":"Tasiujaq Airport", "city_name":"Tasiujaq"},
 {"airport_iata_code":"YTR", "airport_name":"CFB Trenton", "city_name":"Trenton"},
 {"airport_iata_code":"YTS", "airport_name":"Timmins/Victor M. Power", "city_name":"Timmins"},
 {"airport_iata_code":"YTZ", "airport_name":"Billy Bishop Toronto City Centre Airport", "city_name":"Toronto"},
 {"airport_iata_code":"YUB", "airport_name":"Tuktoyaktuk Airport", "city_name":"Tuktoyaktuk"},
 {"airport_iata_code":"YUL", "airport_name":"Montreal / Pierre Elliott Trudeau International Airport", "city_name":"Montr\u00e9al"},
 {"airport_iata_code":"YUT", "airport_name":"Repulse Bay Airport", "city_name":"Repulse Bay"},
 {"airport_iata_code":"YUX", "airport_name":"Hall Beach Airport", "city_name":"Hall Beach"},
 {"airport_iata_code":"YUY", "airport_name":"Rouyn Noranda Airport", "city_name":"Rouyn-Noranda"},
 {"airport_iata_code":"YVB", "airport_name":"Bonaventure Airport", "city_name":"Bonaventure"},
 {"airport_iata_code":"YVC", "airport_name":"La Ronge Airport", "city_name":"La Ronge"},
 {"airport_iata_code":"YVG", "airport_name":"Vermilion Airport", "city_name":"Vermilion"},
 {"airport_iata_code":"YVE", "airport_name":"Vernon Airport", "city_name":"Vernon"},
 {"airport_iata_code":"YVM", "airport_name":"Qikiqtarjuaq Airport", "city_name":"Qikiqtarjuaq"},
 {"airport_iata_code":"YVO", "airport_name":"Val-d'Or Airport", "city_name":"Val-d'Or"},
 {"airport_iata_code":"YVP", "airport_name":"Kuujjuaq Airport", "city_name":"Kuujjuaq"},
 {"airport_iata_code":"YVQ", "airport_name":"Norman Wells Airport", "city_name":"Norman Wells"},
 {"airport_iata_code":"YVR", "airport_name":"Vancouver International Airport", "city_name":"Vancouver"},
 {"airport_iata_code":"YVT", "airport_name":"Buffalo Narrows Airport", "city_name":"Buffalo Narrows"},
 {"airport_iata_code":"YVV", "airport_name":"Wiarton Airport", "city_name":"Wiarton"},
 {"airport_iata_code":"YVZ", "airport_name":"Deer Lake Airport", "city_name":"Deer Lake"},
 {"airport_iata_code":"YWA", "airport_name":"Petawawa Airport", "city_name":"Petawawa"},
 {"airport_iata_code":"YWG", "airport_name":"Winnipeg / James Armstrong Richardson International Airport", "city_name":"Winnipeg"},
 {"airport_iata_code":"YWJ", "airport_name":"D\u00e9line Airport", "city_name":"D\u00e9line"},
 {"airport_iata_code":"YWK", "airport_name":"Wabush Airport", "city_name":"Wabush"},
 {"airport_iata_code":"YWL", "airport_name":"Williams Lake Airport", "city_name":"Williams Lake"},
 {"airport_iata_code":"YWP", "airport_name":"Webequie Airport", "city_name":"Webequie"},
 {"airport_iata_code":"YWY", "airport_name":"Wrigley Airport", "city_name":"Wrigley"},
 {"airport_iata_code":"YXC", "airport_name":"Cranbrook Airport", "city_name":"Cranbrook"},
 {"airport_iata_code":"YXD", "airport_name":"Edmonton City Centre (Blatchford Field) Airport", "city_name":"Edmonton"},
 {"airport_iata_code":"YXE", "airport_name":"Saskatoon John G. Diefenbaker International Airport", "city_name":"Saskatoon"},
 {"airport_iata_code":"YXH", "airport_name":"Medicine Hat Airport", "city_name":"Medicine Hat"},
 {"airport_iata_code":"YXJ", "airport_name":"Fort St John Airport", "city_name":"Fort St.John"},
 {"airport_iata_code":"YXK", "airport_name":"Rimouski Airport", "city_name":"Rimouski"},
 {"airport_iata_code":"YXL", "airport_name":"Sioux Lookout Airport", "city_name":"Sioux Lookout"},
 {"airport_iata_code":"YXN", "airport_name":"Whale Cove Airport", "city_name":"Whale Cove"},
 {"airport_iata_code":"YXP", "airport_name":"Pangnirtung Airport", "city_name":"Pangnirtung"},
 {"airport_iata_code":"YXQ", "airport_name":"Beaver Creek Airport", "city_name":"Beaver Creek"},
 {"airport_iata_code":"YXR", "airport_name":"Earlton (Timiskaming Regional) Airport", "city_name":"Earlton"},
 {"airport_iata_code":"YXS", "airport_name":"Prince George Airport", "city_name":"Prince George"},
 {"airport_iata_code":"YXT", "airport_name":"Terrace Airport", "city_name":"Terrace"},
 {"airport_iata_code":"YXU", "airport_name":"London Airport", "city_name":"London"},
 {"airport_iata_code":"YXX", "airport_name":"Abbotsford Airport", "city_name":"Abbotsford"},
 {"airport_iata_code":"YXY", "airport_name":"Whitehorse / Erik Nielsen International Airport", "city_name":"Whitehorse"},
 {"airport_iata_code":"YXZ", "airport_name":"Wawa Airport", "city_name":"Wawa"},
 {"airport_iata_code":"YYB", "airport_name":"North Bay Airport", "city_name":"North Bay"},
 {"airport_iata_code":"YYC", "airport_name":"Calgary International Airport", "city_name":"Calgary"},
 {"airport_iata_code":"YYD", "airport_name":"Smithers Airport", "city_name":"Smithers"},
 {"airport_iata_code":"YYE", "airport_name":"Fort Nelson Airport", "city_name":"Fort Nelson"},
 {"airport_iata_code":"YYF", "airport_name":"Penticton Airport", "city_name":"Penticton"},
 {"airport_iata_code":"YYG", "airport_name":"Charlottetown Airport", "city_name":"Charlottetown"},
 {"airport_iata_code":"YYH", "airport_name":"Taloyoak Airport", "city_name":"Taloyoak"},
 {"airport_iata_code":"YYJ", "airport_name":"Victoria International Airport", "city_name":"Victoria"},
 {"airport_iata_code":"YYL", "airport_name":"Lynn Lake Airport", "city_name":"Lynn Lake"},
 {"airport_iata_code":"YYM", "airport_name":"Cowley Airport", "city_name":"Cowley"},
 {"airport_iata_code":"YYN", "airport_name":"Swift Current Airport", "city_name":"Swift Current"},
 {"airport_iata_code":"YYQ", "airport_name":"Churchill Airport", "city_name":"Churchill"},
 {"airport_iata_code":"YYR", "airport_name":"Goose Bay Airport", "city_name":"Goose Bay"},
 {"airport_iata_code":"YYT", "airport_name":"St. John's International Airport", "city_name":"St. John's"},
 {"airport_iata_code":"YYU", "airport_name":"Kapuskasing Airport", "city_name":"Kapuskasing"},
 {"airport_iata_code":"YYW", "airport_name":"Armstrong Airport", "city_name":"Armstrong"},
 {"airport_iata_code":"YYY", "airport_name":"Mont Joli Airport", "city_name":"Mont-Joli"},
 {"airport_iata_code":"YYZ", "airport_name":"Lester B. Pearson International Airport", "city_name":"Toronto"},
 {"airport_iata_code":"YZD", "airport_name":"Downsview Airport", "city_name":"Toronto"},
 {"airport_iata_code":"YZE", "airport_name":"Gore Bay Manitoulin Airport", "city_name":"Gore Bay"},
 {"airport_iata_code":"YZF", "airport_name":"Yellowknife Airport", "city_name":"Yellowknife"},
 {"airport_iata_code":"YZG", "airport_name":"Salluit Airport", "city_name":"Salluit"},
 {"airport_iata_code":"YZH", "airport_name":"Slave Lake Airport", "city_name":"Slave Lake"},
 {"airport_iata_code":"YZP", "airport_name":"Sandspit Airport", "city_name":"Sandspit"},
 {"airport_iata_code":"YZR", "airport_name":"Chris Hadfield Airport", "city_name":"Sarnia"},
 {"airport_iata_code":"YZS", "airport_name":"Coral Harbour Airport", "city_name":"Coral Harbour"},
 {"airport_iata_code":"YZT", "airport_name":"Port Hardy Airport", "city_name":"Port Hardy"},
 {"airport_iata_code":"YZU", "airport_name":"Whitecourt Airport", "city_name":"Whitecourt"},
 {"airport_iata_code":"YZV", "airport_name":"Sept-\u00celes Airport", "city_name":"Sept-\u00celes"},
 {"airport_iata_code":"YZW", "airport_name":"Teslin Airport", "city_name":"Teslin"},
 {"airport_iata_code":"YZX", "airport_name":"CFB Greenwood", "city_name":"Greenwood"},
 {"airport_iata_code":"ZAC", "airport_name":"York Landing Airport", "city_name":"York Landing"},
 {"airport_iata_code":"YSN", "airport_name":"Salmon Arm Airport", "city_name":"Salmon Arm"},
 {"airport_iata_code":"YDT", "airport_name":"Boundary Bay Airport", "city_name":"Vancouver"},
 {"airport_iata_code":"ILF", "airport_name":"Ilford Airport", "city_name":"Ilford"},
 {"airport_iata_code":"ZBF", "airport_name":"Bathurst Airport", "city_name":"Bathurst"},
 {"airport_iata_code":"ZBM", "airport_name":"Bromont (Roland Desourdy) Airport", "city_name":"Bromont"},
 {"airport_iata_code":"ZEE", "airport_name":"Kelsey Airport", "city_name":"Kelsey"},
 {"airport_iata_code":"ZEM", "airport_name":"Eastmain River Airport", "city_name":"Eastmain River"},
 {"airport_iata_code":"ZFA", "airport_name":"Faro Airport", "city_name":"Faro"},
 {"airport_iata_code":"ZFD", "airport_name":"Fond-Du-Lac Airport", "city_name":"Fond-Du-Lac"},
 {"airport_iata_code":"XPK", "airport_name":"Pukatawagan Airport", "city_name":"Pukatawagan"},
 {"airport_iata_code":"ZFM", "airport_name":"Fort Mcpherson Airport", "city_name":"Fort Mcpherson"},
 {"airport_iata_code":"ZFN", "airport_name":"Tulita Airport", "city_name":"Tulita"},
 {"airport_iata_code":"ZGF", "airport_name":"Grand Forks Airport", "city_name":"Grand Forks"},
 {"airport_iata_code":"ZGI", "airport_name":"Gods River Airport", "city_name":"Gods River"},
 {"airport_iata_code":"ZGR", "airport_name":"Little Grand Rapids Airport", "city_name":"Little Grand Rapids"},
 {"airport_iata_code":"ZHP", "airport_name":"High Prairie Airport", "city_name":"High Prairie"},
 {"airport_iata_code":"ZJG", "airport_name":"Jenpeg Airport", "city_name":"Jenpeg"},
 {"airport_iata_code":"ZJN", "airport_name":"Swan River Airport", "city_name":"Swan River"},
 {"airport_iata_code":"ZKE", "airport_name":"Kashechewan Airport", "city_name":"Kashechewan"},
 {"airport_iata_code":"YTD", "airport_name":"Thicket Portage Airport", "city_name":"Thicket Portage"},
 {"airport_iata_code":"MSA", "airport_name":"Muskrat Dam Airport", "city_name":"Muskrat Dam"},
 {"airport_iata_code":"ZMH", "airport_name":"South Cariboo Region / 108 Mile Airport", "city_name":"108 Mile"},
 {"airport_iata_code":"PIW", "airport_name":"Pikwitonei Airport", "city_name":"Pikwitonei"},
 {"airport_iata_code":"ZMT", "airport_name":"Masset Airport", "city_name":"Masset"},
 {"airport_iata_code":"XPP", "airport_name":"Poplar River Airport", "city_name":"Poplar River"},
 {"airport_iata_code":"ZPB", "airport_name":"Sachigo Lake Airport", "city_name":"Sachigo Lake"},
 {"airport_iata_code":"WPC", "airport_name":"Pincher Creek Airport", "city_name":"Pincher Creek"},
 {"airport_iata_code":"ZPO", "airport_name":"Pinehouse Lake Airport", "city_name":"Pinehouse Lake"},
 {"airport_iata_code":"ZRJ", "airport_name":"Round Lake (Weagamow Lake) Airport", "city_name":"Round Lake"},
 {"airport_iata_code":"ZSJ", "airport_name":"Sandy Lake Airport", "city_name":"Sandy Lake"},
 {"airport_iata_code":"XSI", "airport_name":"South Indian Lake Airport", "city_name":"South Indian Lake"},
 {"airport_iata_code":"ZST", "airport_name":"Stewart Airport", "city_name":"Stewart"},
 {"airport_iata_code":"YDV", "airport_name":"Bloodvein River Airport", "city_name":"Bloodvein River"},
 {"airport_iata_code":"ZTM", "airport_name":"Shamattawa Airport", "city_name":"Shamattawa"},
 {"airport_iata_code":"ZUC", "airport_name":"Ignace Municipal Airport", "city_name":"Ignace"},
 {"airport_iata_code":"ZUM", "airport_name":"Churchill Falls Airport", "city_name":"Churchill Falls"},
 {"airport_iata_code":"XLB", "airport_name":"Lac Brochet Airport", "city_name":"Lac Brochet"},
 {"airport_iata_code":"ZWL", "airport_name":"Wollaston Lake Airport", "city_name":"Wollaston Lake"},
 {"airport_iata_code":"YLP", "airport_name":"Mingan Airport", "city_name":"Mingan"},
 {"airport_iata_code":"YOI", "airport_name":"Opinaca Aerodrome", "city_name":""},
 {"airport_iata_code":"CCK", "airport_name":"Cocos (Keeling) Islands Airport", "city_name":"Cocos (Keeling) Islands"},
 {"airport_iata_code":"FIH", "airport_name":"Ndjili International Airport", "city_name":"Kinshasa"},
 {"airport_iata_code":"NLO", "airport_name":"Ndolo Airport", "city_name":""},
 {"airport_iata_code":"MNB", "airport_name":"Muanda Airport", "city_name":""},
 {"airport_iata_code":"BOA", "airport_name":"Boma Airport", "city_name":"Boma"},
 {"airport_iata_code":"LZI", "airport_name":"Luozi Airport", "city_name":"Luozi"},
 {"airport_iata_code":"MAT", "airport_name":"Tshimpi Airport", "city_name":"Matadi"},
 {"airport_iata_code":"NKL", "airport_name":"Nkolo Fuma Airport", "city_name":"Nkolo Fuma"},
 {"airport_iata_code":"INO", "airport_name":"Inongo Airport", "city_name":"Inongo"},
 {"airport_iata_code":"NIO", "airport_name":"Nioki Airport", "city_name":"Nioki"},
 {"airport_iata_code":"FDU", "airport_name":"Bandundu Airport", "city_name":""},
 {"airport_iata_code":"KRZ", "airport_name":"Basango Mboliasa Airport", "city_name":"Kiri"},
 {"airport_iata_code":"KKW", "airport_name":"Kikwit Airport", "city_name":""},
 {"airport_iata_code":"IDF", "airport_name":"Idiofa Airport", "city_name":"Idiofa"},
 {"airport_iata_code":"LUS", "airport_name":"Lusanga Airport", "city_name":"Lusanga"},
 {"airport_iata_code":"MSM", "airport_name":"Masi Manimba Airport", "city_name":"Masi Manimba"},
 {"airport_iata_code":"MDK", "airport_name":"Mbandaka Airport", "city_name":"Mbandaka"},
 {"airport_iata_code":"BSU", "airport_name":"Basankusu Airport", "city_name":"Basankusu"},
 {"airport_iata_code":"LIE", "airport_name":"Libenge Airport", "city_name":"Libenge"},
 {"airport_iata_code":"BDT", "airport_name":"Gbadolite Airport", "city_name":""},
 {"airport_iata_code":"GMA", "airport_name":"Gemena Airport", "city_name":"Gemena"},
 {"airport_iata_code":"KLI", "airport_name":"Kotakoli Airport", "city_name":""},
 {"airport_iata_code":"BMB", "airport_name":"Bumbar Airport", "city_name":"Bumbar"},
 {"airport_iata_code":"LIQ", "airport_name":"Lisala Airport", "city_name":""},
 {"airport_iata_code":"BNB", "airport_name":"Boende Airport", "city_name":"Boende"},
 {"airport_iata_code":"IKL", "airport_name":"Ikela Airport", "city_name":"Ikela"},
 {"airport_iata_code":"FKI", "airport_name":"Bangoka International Airport", "city_name":"Kisangani"},
 {"airport_iata_code":"YAN", "airport_name":"Yangambi Airport", "city_name":"Yangambi"},
 {"airport_iata_code":"IRP", "airport_name":"Matari Airport", "city_name":""},
 {"airport_iata_code":"BUX", "airport_name":"Bunia Airport", "city_name":""},
 {"airport_iata_code":"BZU", "airport_name":"Buta Zega Airport", "city_name":""},
 {"airport_iata_code":"BKY", "airport_name":"Bukavu Kavumu Airport", "city_name":""},
 {"airport_iata_code":"RUE", "airport_name":"Rughenda Airfield", "city_name":"Butembo"},
 {"airport_iata_code":"GOM", "airport_name":"Goma International Airport", "city_name":"Goma"},
 {"airport_iata_code":"BNC", "airport_name":"Beni Airport", "city_name":"Beni"},
 {"airport_iata_code":"KND", "airport_name":"Kindu Airport", "city_name":"Kindu"},
 {"airport_iata_code":"KLY", "airport_name":"Kinkungwa Airport", "city_name":"Kalima"},
 {"airport_iata_code":"PUN", "airport_name":"Punia Airport", "city_name":"Punia"},
 {"airport_iata_code":"FBM", "airport_name":"Lubumbashi International Airport", "city_name":"Lubumbashi"},
 {"airport_iata_code":"PWO", "airport_name":"Pweto Airport", "city_name":"Pweto"},
 {"airport_iata_code":"KEC", "airport_name":"Kasenga Airport", "city_name":"Kasenga"},
 {"airport_iata_code":"KWZ", "airport_name":"Kolwezi Airport", "city_name":""},
 {"airport_iata_code":"MNO", "airport_name":"Manono Airport", "city_name":"Manono"},
 {"airport_iata_code":"BDV", "airport_name":"Moba Airport", "city_name":"Moba"},
 {"airport_iata_code":"FMI", "airport_name":"Kalemie Airport", "city_name":""},
 {"airport_iata_code":"KBO", "airport_name":"Kabalo Airport", "city_name":"Kabalo"},
 {"airport_iata_code":"KOO", "airport_name":"Kongolo Airport", "city_name":"Kongolo"},
 {"airport_iata_code":"KMN", "airport_name":"Kamina Base Airport", "city_name":""},
 {"airport_iata_code":"KAP", "airport_name":"Kapanga Airport", "city_name":"Kapanga"},
 {"airport_iata_code":"KNM", "airport_name":"Kaniama Airport", "city_name":"Kaniama"},
 {"airport_iata_code":"KGA", "airport_name":"Kananga Airport", "city_name":"Kananga"},
 {"airport_iata_code":"LZA", "airport_name":"Luiza Airport", "city_name":"Luiza"},
 {"airport_iata_code":"TSH", "airport_name":"Tshikapa Airport", "city_name":"Tshikapa"},
 {"airport_iata_code":"LJA", "airport_name":"Lodja Airport", "city_name":"Lodja"},
 {"airport_iata_code":"LBO", "airport_name":"Lusambo Airport", "city_name":"Lusambo"},
 {"airport_iata_code":"MEW", "airport_name":"Mweka Airport", "city_name":"Mweka"},
 {"airport_iata_code":"BAN", "airport_name":"Basongo Airport", "city_name":"Basongo"},
 {"airport_iata_code":"PFR", "airport_name":"Ilebo Airport", "city_name":"Ilebo"},
 {"airport_iata_code":"MJM", "airport_name":"Mbuji Mayi Airport", "city_name":"Mbuji Mayi"},
 {"airport_iata_code":"GDJ", "airport_name":"Gandajika Airport", "city_name":"Gandajika"},
 {"airport_iata_code":"KBN", "airport_name":"Tunta Airport", "city_name":"Kabinda"},
 {"airport_iata_code":"CRF", "airport_name":"Carnot Airport", "city_name":"Carnot"},
 {"airport_iata_code":"BGF", "airport_name":"Bangui M'Poko International Airport", "city_name":"Bangui"},
 {"airport_iata_code":"BGU", "airport_name":"Bangassou Airport", "city_name":"Bangassou"},
 {"airport_iata_code":"IRO", "airport_name":"Birao Airport", "city_name":"Birao"},
 {"airport_iata_code":"BEM", "airport_name":"Bossemb\u00e9l\u00e9 Airport", "city_name":"Bossemb\u00e9l\u00e9"},
 {"airport_iata_code":"BBY", "airport_name":"Bambari Airport", "city_name":"Bambari"},
 {"airport_iata_code":"NDL", "airport_name":"N'D\u00e9l\u00e9 Airport", "city_name":"N'D\u00e9l\u00e9"},
 {"airport_iata_code":"BOP", "airport_name":"Bouar Airport", "city_name":"Bouar"},
 {"airport_iata_code":"BIV", "airport_name":"Bria Airport", "city_name":"Bria"},
 {"airport_iata_code":"BSN", "airport_name":"Bossangoa Airport", "city_name":"Bossangoa"},
 {"airport_iata_code":"BBT", "airport_name":"Berb\u00e9rati Airport", "city_name":"Berb\u00e9rati"},
 {"airport_iata_code":"ODA", "airport_name":"Ouadda Airport", "city_name":"Ouadda"},
 {"airport_iata_code":"AIG", "airport_name":"Yalinga Airport", "city_name":"Yalinga"},
 {"airport_iata_code":"IMO", "airport_name":"Zemio Airport", "city_name":"Zemio"},
 {"airport_iata_code":"MKI", "airport_name":"M'Boki Airport", "city_name":"Mboki"},
 {"airport_iata_code":"BTG", "airport_name":"Batangafo Airport", "city_name":"Batangafo"},
 {"airport_iata_code":"GDI", "airport_name":"Gordil Airport", "city_name":"Melle"},
 {"airport_iata_code":"BMF", "airport_name":"Bakouma Airport", "city_name":"Bakouma"},
 {"airport_iata_code":"ODJ", "airport_name":"Ouanda Djall\u00e9 Airport", "city_name":"Ouanda Djall\u00e9"},
 {"airport_iata_code":"RFA", "airport_name":"Rafa\u00ef Airport", "city_name":"Rafa\u00ef"},
 {"airport_iata_code":"BCF", "airport_name":"Bouca Airport", "city_name":"Bouca"},
 {"airport_iata_code":"BOZ", "airport_name":"Bozoum Airport", "city_name":"Bozoum"},
 {"airport_iata_code":"OKG", "airport_name":"Okoyo Airport", "city_name":"Okoyo"},
 {"airport_iata_code":"BZV", "airport_name":"Maya-Maya Airport", "city_name":"Brazzaville"},
 {"airport_iata_code":"DJM", "airport_name":"Djambala Airport", "city_name":"Djambala"},
 {"airport_iata_code":"KNJ", "airport_name":"Kindamba Airport", "city_name":"Kindamba"},
 {"airport_iata_code":"LCO", "airport_name":"Lague Airport", "city_name":"Lague"},
 {"airport_iata_code":"MUY", "airport_name":"Mouyondzi Airport", "city_name":"Mouyondzi"},
 {"airport_iata_code":"SIB", "airport_name":"Sibiti Airport", "city_name":"Sibiti"},
 {"airport_iata_code":"NKY", "airport_name":"Yokangassi Airport", "city_name":"Nkayi"},
 {"airport_iata_code":"ANJ", "airport_name":"Zanaga Airport", "city_name":"Zanaga"},
 {"airport_iata_code":"MSX", "airport_name":"Mossendjo Airport", "city_name":"Mossendjo"},
 {"airport_iata_code":"BOE", "airport_name":"Boundji Airport", "city_name":"Boundji"},
 {"airport_iata_code":"EWO", "airport_name":"Ewo Airport", "city_name":"Ewo"},
 {"airport_iata_code":"GMM", "airport_name":"Gamboma Airport", "city_name":"Gamboma"},
 {"airport_iata_code":"ION", "airport_name":"Impfondo Airport", "city_name":"Impfondo"},
 {"airport_iata_code":"KEE", "airport_name":"Kelle Airport", "city_name":"Kelle"},
 {"airport_iata_code":"MKJ", "airport_name":"Makoua Airport", "city_name":"Makoua"},
 {"airport_iata_code":"FTX", "airport_name":"Owando Airport", "city_name":"Owando"},
 {"airport_iata_code":"SOE", "airport_name":"Souanke Airport", "city_name":"Souanke"},
 {"airport_iata_code":"BTB", "airport_name":"Betou Airport", "city_name":"Betou"},
 {"airport_iata_code":"OUE", "airport_name":"Ouesso Airport", "city_name":""},
 {"airport_iata_code":"KMK", "airport_name":"Makabana Airport", "city_name":"Makabana"},
 {"airport_iata_code":"DIS", "airport_name":"Ngot Nzoungou Airport", "city_name":"Dolisie"},
 {"airport_iata_code":"PNR", "airport_name":"Pointe Noire Airport", "city_name":"Pointe Noire"},
 {"airport_iata_code":"GVA", "airport_name":"Geneva Cointrin International Airport", "city_name":"Geneva"},
 {"airport_iata_code":"QLS", "airport_name":"Lausanne-la Blecherette Airport", "city_name":"Lausanne"},
 {"airport_iata_code":"QNC", "airport_name":"Neuchatel Airport", "city_name":""},
 {"airport_iata_code":"SIR", "airport_name":"Sion Airport", "city_name":"Sion"},
 {"airport_iata_code":"ZIN", "airport_name":"Interlaken Air Base", "city_name":""},
 {"airport_iata_code":"LUG", "airport_name":"Lugano Airport", "city_name":"Lugano"},
 {"airport_iata_code":"BRN", "airport_name":"Bern Belp Airport", "city_name":"Bern"},
 {"airport_iata_code":"BXO", "airport_name":"Buochs Airport", "city_name":"Buochs"},
 {"airport_iata_code":"ZHI", "airport_name":"Grenchen Airport", "city_name":""},
 {"airport_iata_code":"ZRH", "airport_name":"Z\u00fcrich Airport", "city_name":"Zurich"},
 {"airport_iata_code":"ZJI", "airport_name":"Locarno Airport", "city_name":"Locarno"},
 {"airport_iata_code":"ACH", "airport_name":"St Gallen Altenrhein Airport", "city_name":"Altenrhein"},
 {"airport_iata_code":"SMV", "airport_name":"Samedan Airport", "city_name":""},
 {"airport_iata_code":"ABJ", "airport_name":"Port Bouet Airport", "city_name":"Abidjan"},
 {"airport_iata_code":"OGO", "airport_name":"Abengourou Airport", "city_name":"Abengourou"},
 {"airport_iata_code":"BXI", "airport_name":"Boundiali Airport", "city_name":"Boundiali"},
 {"airport_iata_code":"BYK", "airport_name":"Bouak\u00e9 Airport", "city_name":""},
 {"airport_iata_code":"BQO", "airport_name":"Bouna Airport", "city_name":"Bouna"},
 {"airport_iata_code":"BDK", "airport_name":"Soko Airport", "city_name":"Bondoukou"},
 {"airport_iata_code":"DIM", "airport_name":"Dimbokro Airport", "city_name":"Dimbokro"},
 {"airport_iata_code":"DJO", "airport_name":"Daloa Airport", "city_name":""},
 {"airport_iata_code":"FEK", "airport_name":"Ferkessedougou Airport", "city_name":"Ferkessedougou"},
 {"airport_iata_code":"GGN", "airport_name":"Gagnoa Airport", "city_name":"Gagnoa"},
 {"airport_iata_code":"GGO", "airport_name":"Guiglo Airport", "city_name":"Guiglo"},
 {"airport_iata_code":"BBV", "airport_name":"Nero-Mer Airport", "city_name":"Grand-B\u00e9r\u00e9by"},
 {"airport_iata_code":"HGO", "airport_name":"Korhogo Airport", "city_name":""},
 {"airport_iata_code":"MJC", "airport_name":"Man Airport", "city_name":""},
 {"airport_iata_code":"KEO", "airport_name":"Odienne Airport", "city_name":"Odienne"},
 {"airport_iata_code":"OFI", "airport_name":"Ouango Fitini Airport", "city_name":"Ouango Fitini"},
 {"airport_iata_code":"SEO", "airport_name":"Seguela Airport", "city_name":"Seguela"},
 {"airport_iata_code":"SPY", "airport_name":"San Pedro Airport", "city_name":""},
 {"airport_iata_code":"ZSS", "airport_name":"Sassandra Airport", "city_name":"Sassandra"},
 {"airport_iata_code":"TXU", "airport_name":"Tabou Airport", "city_name":"Tabou"},
 {"airport_iata_code":"ASK", "airport_name":"Yamoussoukro Airport", "city_name":"Yamoussoukro"},
 {"airport_iata_code":"AIT", "airport_name":"Aitutaki Airport", "city_name":"Aitutaki"},
 {"airport_iata_code":"AIU", "airport_name":"Enua Airport", "city_name":"Atiu Island"},
 {"airport_iata_code":"MGS", "airport_name":"Mangaia Island Airport", "city_name":"Mangaia Island"},
 {"airport_iata_code":"MHX", "airport_name":"Manihiki Island Airport", "city_name":"Manihiki Island"},
 {"airport_iata_code":"MUK", "airport_name":"Mauke Airport", "city_name":"Mauke Island"},
 {"airport_iata_code":"MOI", "airport_name":"Mitiaro Island Airport", "city_name":"Mitiaro Island"},
 {"airport_iata_code":"PYE", "airport_name":"Tongareva Airport", "city_name":"Penrhyn Island"},
 {"airport_iata_code":"RAR", "airport_name":"Rarotonga International Airport", "city_name":"Avarua"},
 {"airport_iata_code":"ZUD", "airport_name":"Pupelde Airport", "city_name":"Ancud"},
 {"airport_iata_code":"LOB", "airport_name":"San Rafael Airport", "city_name":"Los Andes"},
 {"airport_iata_code":"WAP", "airport_name":"Alto Palena Airport", "city_name":"Alto Palena"},
 {"airport_iata_code":"ARI", "airport_name":"Chacalluta Airport", "city_name":"Arica"},
 {"airport_iata_code":"WPA", "airport_name":"Cabo 1\u00b0 Juan Rom\u00e1n Airport", "city_name":"Puerto Aysen"},
 {"airport_iata_code":"BBA", "airport_name":"Balmaceda Airport", "city_name":"Balmaceda"},
 {"airport_iata_code":"TOQ", "airport_name":"Barriles Airport", "city_name":"Tocopilla"},
 {"airport_iata_code":"CCH", "airport_name":"Chile Chico Airport", "city_name":"Chile Chico"},
 {"airport_iata_code":"CJC", "airport_name":"El Loa Airport", "city_name":"Calama"},
 {"airport_iata_code":"YAI", "airport_name":"Gral. Bernardo O\u00b4Higgins Airport", "city_name":"Chillan"},
 {"airport_iata_code":"PUQ", "airport_name":"Pdte. carlos Iba\u00f1ez del Campo Airport", "city_name":"Punta Arenas"},
 {"airport_iata_code":"COW", "airport_name":"Tambillos Airport", "city_name":"Coquimbo"},
 {"airport_iata_code":"GXQ", "airport_name":"Teniente Vidal Airport", "city_name":"Coyhaique"},
 {"airport_iata_code":"IQQ", "airport_name":"Diego Aracena Airport", "city_name":"Iquique"},
 {"airport_iata_code":"SCL", "airport_name":"Comodoro Arturo Merino Ben\u00edtez International Airport", "city_name":"Santiago"},
 {"airport_iata_code":"ESR", "airport_name":"Ricardo Garc\u00eda Posada Airport", "city_name":"El Salvador"},
 {"airport_iata_code":"FRT", "airport_name":"El Avellano Airport", "city_name":"Frutillar"},
 {"airport_iata_code":"ANF", "airport_name":"Cerro Moreno Airport", "city_name":"Antofagasta"},
 {"airport_iata_code":"WPR", "airport_name":"Capitan Fuentes Martinez Airport Airport", "city_name":"Porvenir"},
 {"airport_iata_code":"FFU", "airport_name":"Futaleuf\u00fa Airport", "city_name":"Futaleufu"},
 {"airport_iata_code":"LSQ", "airport_name":"Mar\u00eda Dolores Airport", "city_name":"Los Angeles"},
 {"airport_iata_code":"WPU", "airport_name":"Guardiamarina Za\u00f1artu Airport", "city_name":"Puerto Williams"},
 {"airport_iata_code":"CPO", "airport_name":"Chamonate Airport", "city_name":"Copiapo"},
 {"airport_iata_code":"LGR", "airport_name":"Cochrane Airport", "city_name":"Cochrane"},
 {"airport_iata_code":"CCP", "airport_name":"Carriel Sur Airport", "city_name":"Concepcion"},
 {"airport_iata_code":"IPC", "airport_name":"Mataveri Airport", "city_name":"Isla De Pascua"},
 {"airport_iata_code":"ZOS", "airport_name":"Ca\u00f1al Bajo Carlos - Hott Siebert Airport", "city_name":"Osorno"},
 {"airport_iata_code":"VLR", "airport_name":"Vallenar Airport", "city_name":"Vallenar"},
 {"airport_iata_code":"ZLR", "airport_name":"Municipal de Linares Airport", "city_name":"Linares"},
 {"airport_iata_code":"PNT", "airport_name":"Tte. Julio Gallardo Airport", "city_name":"Puerto Natales"},
 {"airport_iata_code":"OVL", "airport_name":"El Tuqui Airport", "city_name":"Ovalle"},
 {"airport_iata_code":"ZPC", "airport_name":"Puc\u00f3n Airport", "city_name":"Pucon"},
 {"airport_iata_code":"PUX", "airport_name":"El Mirador Airport", "city_name":"Puerto Varas"},
 {"airport_iata_code":"CNR", "airport_name":"Cha\u00f1aral Airport", "city_name":"Cha\u00f1aral"},
 {"airport_iata_code":"VAP", "airport_name":"Rodelillo Airport", "city_name":"Vi\u00f1a Del Mar"},
 {"airport_iata_code":"QRC", "airport_name":"De La Independencia Airport", "city_name":"Rancagua"},
 {"airport_iata_code":"SMB", "airport_name":"Franco Bianco Airport", "city_name":"Cerro Sombrero"},
 {"airport_iata_code":"LSC", "airport_name":"La Florida Airport", "city_name":"La Serena-Coquimbo"},
 {"airport_iata_code":"SSD", "airport_name":"V\u00edctor Laf\u00f3n Airport", "city_name":"San Felipe"},
 {"airport_iata_code":"WCA", "airport_name":"Gamboa Airport", "city_name":"Castro"},
 {"airport_iata_code":"ZCO", "airport_name":"Maquehue Airport", "city_name":"Temuco"},
 {"airport_iata_code":"PMC", "airport_name":"El Tepual Airport", "city_name":"Puerto Montt"},
 {"airport_iata_code":"TLX", "airport_name":"Panguilemo Airport", "city_name":"Talca"},
 {"airport_iata_code":"WCH", "airport_name":"Chait\u00e9n Airport", "city_name":"Chaiten"},
 {"airport_iata_code":"ZIC", "airport_name":"Victoria Airport", "city_name":"Victoria"},
 {"airport_iata_code":"TTC", "airport_name":"Las Breas Airport", "city_name":"Taltal"},
 {"airport_iata_code":"ZAL", "airport_name":"Pichoy Airport", "city_name":"Valdivia"},
 {"airport_iata_code":"KNA", "airport_name":"Vi\u00f1a del mar Airport", "city_name":"Vi\u00f1a Del Mar"},
 {"airport_iata_code":"NKS", "airport_name":"Nkongsamba Airport", "city_name":"Nkongsamba"},
 {"airport_iata_code":"KBI", "airport_name":"Kribi Airport", "city_name":"Kribi"},
 {"airport_iata_code":"TKC", "airport_name":"Tiko Airport", "city_name":"Tiko"},
 {"airport_iata_code":"DLA", "airport_name":"Douala International Airport", "city_name":"Douala"},
 {"airport_iata_code":"MMF", "airport_name":"Mamfe Airport", "city_name":"Mamfe"},
 {"airport_iata_code":"BLC", "airport_name":"Bali Airport", "city_name":"Bali"},
 {"airport_iata_code":"KLE", "airport_name":"Ka\u00e9l\u00e9 Airport", "city_name":"Ka\u00e9l\u00e9"},
 {"airport_iata_code":"OUR", "airport_name":"Batouri Airport", "city_name":"Batouri"},
 {"airport_iata_code":"GXX", "airport_name":"Yagoua Airport", "city_name":"Yagoua"},
 {"airport_iata_code":"MVR", "airport_name":"Salak Airport", "city_name":"Maroua"},
 {"airport_iata_code":"FOM", "airport_name":"Foumban Nkounja Airport", "city_name":"Foumban"},
 {"airport_iata_code":"NGE", "airport_name":"N'Gaound\u00e9r\u00e9 Airport", "city_name":"N'Gaound\u00e9r\u00e9"},
 {"airport_iata_code":"BTA", "airport_name":"Bertoua Airport", "city_name":"Bertoua"},
 {"airport_iata_code":"GOU", "airport_name":"Garoua International Airport", "city_name":"Garoua"},
 {"airport_iata_code":"DSC", "airport_name":"Dschang Airport", "city_name":"Dschang"},
 {"airport_iata_code":"BFX", "airport_name":"Bafoussam Airport", "city_name":"Bafoussam"},
 {"airport_iata_code":"BPC", "airport_name":"Bamenda Airport", "city_name":"Bamenda"},
 {"airport_iata_code":"EBW", "airport_name":"Ebolowa Airport", "city_name":"Ebolowa"},
 {"airport_iata_code":"YAO", "airport_name":"Yaound\u00e9 Airport", "city_name":"Yaound\u00e9"},
 {"airport_iata_code":"NSI", "airport_name":"Yaound\u00e9 Nsimalen International Airport", "city_name":"Yaound\u00e9"},
 {"airport_iata_code":"TVS", "airport_name":"Tangshan Sann\u00fche Airport", "city_name":"Tangshan"},
 {"airport_iata_code":"BSD", "airport_name":"Baoshan Yunduan Airport", "city_name":""},
 {"airport_iata_code":"DZU", "airport_name":"Dazu Air Base", "city_name":"Dazu"},
 {"airport_iata_code":"LNJ", "airport_name":"Lintsang Airfield", "city_name":"Lincang"},
 {"airport_iata_code":"PZI", "airport_name":"Bao'anying Airport", "city_name":"Panzhihua"},
 {"airport_iata_code":"FUO", "airport_name":"Foshan Shadi Airport", "city_name":"Foshan"},
 {"airport_iata_code":"HUZ", "airport_name":"Huizhou Airport", "city_name":"Huizhou"},
 {"airport_iata_code":"HSC", "airport_name":"Shaoguan Guitou Airport", "city_name":"Shaoguan"},
 {"airport_iata_code":"JGS", "airport_name":"Jinggangshan Airport", "city_name":"Ji'an"},
 {"airport_iata_code":"AEB", "airport_name":"Baise Youjiang Airport", "city_name":"Baise"},
 {"airport_iata_code":"DOY", "airport_name":"Dongying Shengli Airport", "city_name":"Dongying"},
 {"airport_iata_code":"XEN", "airport_name":"Xingcheng Air Base", "city_name":""},
 {"airport_iata_code":"AAT", "airport_name":"Altay Air Base", "city_name":"Altay"},
 {"airport_iata_code":"THQ", "airport_name":"Tianshui Maijishan Airport", "city_name":"Tianshui"},
 {"airport_iata_code":"YZY", "airport_name":"Zhangye Southeast Air Base", "city_name":""},
 {"airport_iata_code":"DDG", "airport_name":"Dandong Airport", "city_name":"Dandong"},
 {"airport_iata_code":"NTG", "airport_name":"Nantong Airport", "city_name":"Nantong"},
 {"airport_iata_code":"DQA", "airport_name":"Saertu Airport", "city_name":"Daqing Shi"},
 {"airport_iata_code":"HIA", "airport_name":"Lianshui Airport", "city_name":"Huai'an"},
 {"airport_iata_code":"JGD", "airport_name":"Jiagedaqi Airport", "city_name":"Jiagedaqi"},
 {"airport_iata_code":"JIC", "airport_name":"Jinchuan Airport", "city_name":"Jinchang"},
 {"airport_iata_code":"JIQ", "airport_name":"Qianjiang Wulingshan Airport", "city_name":"Qianjiang"},
 {"airport_iata_code":"JUH", "airport_name":"Jiuhuashan Airport", "city_name":"Chizhou"},
 {"airport_iata_code":"LAC", "airport_name":"Swallow Reef Airstrip", "city_name":"Spratley Islands"},
 {"airport_iata_code":"PEK", "airport_name":"Beijing Capital International Airport", "city_name":"Beijing"},
 {"airport_iata_code":"CIF", "airport_name":"Chifeng Airport", "city_name":"Chifeng"},
 {"airport_iata_code":"CIH", "airport_name":"Changzhi Airport", "city_name":"Changzhi"},
 {"airport_iata_code":"DSN", "airport_name":"Ordos Ejin Horo Airport", "city_name":"Ordos"},
 {"airport_iata_code":"DAT", "airport_name":"Datong Airport", "city_name":"Datong"},
 {"airport_iata_code":"ERL", "airport_name":"Erenhot Saiwusu International Airport", "city_name":"Erenhot,"},
 {"airport_iata_code":"HDG", "airport_name":"Handan Airport", "city_name":"Handan"},
 {"airport_iata_code":"HET", "airport_name":"Baita International Airport", "city_name":"Hohhot"},
 {"airport_iata_code":"HLD", "airport_name":"Dongshan Airport", "city_name":"Hailar"},
 {"airport_iata_code":"NAY", "airport_name":"Beijing Nanyuan Airport", "city_name":"Beijing"},
 {"airport_iata_code":"BAV", "airport_name":"Baotou Airport", "city_name":"Baotou"},
 {"airport_iata_code":"SHP", "airport_name":"Shanhaiguan Airport", "city_name":"Qinhuangdao"},
 {"airport_iata_code":"SJW", "airport_name":"Shijiazhuang Daguocun International Airport", "city_name":"Shijiazhuang"},
 {"airport_iata_code":"TSN", "airport_name":"Tianjin Binhai International Airport", "city_name":"Tianjin"},
 {"airport_iata_code":"TGO", "airport_name":"Tongliao Airport", "city_name":"Tongliao"},
 {"airport_iata_code":"WUA", "airport_name":"Wuhai Airport", "city_name":"Wuhai"},
 {"airport_iata_code":"HLH", "airport_name":"Ulanhot Airport", "city_name":"Ulanhot"},
 {"airport_iata_code":"XIL", "airport_name":"Xilinhot Airport", "city_name":"Xilinhot"},
 {"airport_iata_code":"XNT", "airport_name":"Xingtai Dalian Airport", "city_name":"Xingtai"},
 {"airport_iata_code":"YCU", "airport_name":"Yuncheng Guangong Airport", "city_name":"Yuncheng"},
 {"airport_iata_code":"TYN", "airport_name":"Taiyuan Wusu Airport", "city_name":"Taiyuan"},
 {"airport_iata_code":"BHY", "airport_name":"Beihai Airport", "city_name":"Beihai"},
 {"airport_iata_code":"CGD", "airport_name":"Changde Airport", "city_name":"Changde"},
 {"airport_iata_code":"HJJ", "airport_name":"Zhijiang Airport", "city_name":"Huaihua"},
 {"airport_iata_code":"DYG", "airport_name":"Dayong Airport", "city_name":"Dayong"},
 {"airport_iata_code":"CAN", "airport_name":"Guangzhou Baiyun International Airport", "city_name":"Guangzhou"},
 {"airport_iata_code":"CSX", "airport_name":"Changsha Huanghua International Airport", "city_name":"Changsha"},
 {"airport_iata_code":"HNY", "airport_name":"Hengyang Airport", "city_name":"Hengyang"},
 {"airport_iata_code":"KWL", "airport_name":"Guilin Liangjiang International Airport", "city_name":"Guilin City"},
 {"airport_iata_code":"LLF", "airport_name":"Lingling Airport", "city_name":"Yongzhou"},
 {"airport_iata_code":"MXZ", "airport_name":"Meixian Airport", "city_name":"Meixian"},
 {"airport_iata_code":"NNG", "airport_name":"Nanning Wuxu Airport", "city_name":"Nanning"},
 {"airport_iata_code":"SWA", "airport_name":"Shantou Waisha Airport", "city_name":"Shantou"},
 {"airport_iata_code":"ZUH", "airport_name":"Zhuhai Airport", "city_name":"Zhuhai"},
 {"airport_iata_code":"SZX", "airport_name":"Shenzhen Bao'an International Airport", "city_name":"Shenzhen"},
 {"airport_iata_code":"WUZ", "airport_name":"Changzhoudao Airport", "city_name":"Wuzhou"},
 {"airport_iata_code":"XIN", "airport_name":"Xingning Airport", "city_name":"Xingning"},
 {"airport_iata_code":"LZH", "airport_name":"Bailian Airport", "city_name":"Liuzhou"},
 {"airport_iata_code":"ZHA", "airport_name":"Zhanjiang Airport", "city_name":"Zhanjiang"},
 {"airport_iata_code":"AYN", "airport_name":"Anyang Airport", "city_name":"Anyang"},
 {"airport_iata_code":"CGO", "airport_name":"Xinzheng Airport", "city_name":"Zhengzhou"},
 {"airport_iata_code":"ENH", "airport_name":"Enshi Airport", "city_name":"Enshi"},
 {"airport_iata_code":"LHK", "airport_name":"Guangzhou MR Air Base", "city_name":"Guanghua"},
 {"airport_iata_code":"WUH", "airport_name":"Wuhan Tianhe International Airport", "city_name":"Wuhan"},
 {"airport_iata_code":"LYA", "airport_name":"Luoyang Airport", "city_name":"Luoyang"},
 {"airport_iata_code":"NNY", "airport_name":"Nanyang Airport", "city_name":"Nanyang"},
 {"airport_iata_code":"SHS", "airport_name":"Shashi Airport", "city_name":"Shashi"},
 {"airport_iata_code":"XFN", "airport_name":"Xiangfan Airport", "city_name":"Xiangfan"},
 {"airport_iata_code":"YIH", "airport_name":"Yichang Airport", "city_name":"Yichang"},
 {"airport_iata_code":"HAK", "airport_name":"Haikou Meilan International Airport", "city_name":"Haikou"},
 {"airport_iata_code":"SYX", "airport_name":"Sanya Phoenix International Airport", "city_name":"Sanya"},
 {"airport_iata_code":"AKA", "airport_name":"Ankang Airport", "city_name":"Ankang"},
 {"airport_iata_code":"DNH", "airport_name":"Dunhuang Airport", "city_name":"Dunhuang"},
 {"airport_iata_code":"GOQ", "airport_name":"Golmud Airport", "city_name":"Golmud"},
 {"airport_iata_code":"GYU", "airport_name":"Guyuan Liupanshan Airport", "city_name":"Guyuan"},
 {"airport_iata_code":"HZG", "airport_name":"Hanzhong Airport", "city_name":"Hanzhong"},
 {"airport_iata_code":"INC", "airport_name":"Yinchuan Airport", "city_name":"Yinchuan"},
 {"airport_iata_code":"JNG", "airport_name":"Jining Qufu Airport", "city_name":"Jining"},
 {"airport_iata_code":"JGN", "airport_name":"Jiayuguan Airport", "city_name":"Jiayuguan"},
 {"airport_iata_code":"LHW", "airport_name":"Lanzhou Zhongchuan Airport", "city_name":"Lanzhou"},
 {"airport_iata_code":"IQN", "airport_name":"Qingyang Airport", "city_name":"Qingyang"},
 {"airport_iata_code":"SIA", "airport_name":"Xiguan Airport", "city_name":"Xi'an"},
 {"airport_iata_code":"XNN", "airport_name":"Xining Caojiabu Airport", "city_name":"Xining"},
 {"airport_iata_code":"XIY", "airport_name":"Xi'an Xianyang International Airport", "city_name":"Xianyang"},
 {"airport_iata_code":"ENY", "airport_name":"Yan'an Airport", "city_name":"Yan'an"},
 {"airport_iata_code":"UYN", "airport_name":"Yulin Airport", "city_name":"Yulin"},
 {"airport_iata_code":"ZHY", "airport_name":"Zhongwei Shapotou Airport", "city_name":"Zhongwei"},
 {"airport_iata_code":"DLU", "airport_name":"Dali Airport", "city_name":"Xiaguan"},
 {"airport_iata_code":"DIG", "airport_name":"Diqing Airport", "city_name":"Shangri-La"},
 {"airport_iata_code":"JHG", "airport_name":"Xishuangbanna Gasa Airport", "city_name":"Jinghong"},
 {"airport_iata_code":"LJG", "airport_name":"Lijiang Airport", "city_name":"Lijiang"},
 {"airport_iata_code":"LUM", "airport_name":"Mangshi Airport", "city_name":"Luxi"},
 {"airport_iata_code":"KMG", "airport_name":"Kunming Wujiaba International Airport", "city_name":"Kunming"},
 {"airport_iata_code":"SYM", "airport_name":"Simao Airport", "city_name":"Simao"},
 {"airport_iata_code":"ZAT", "airport_name":"Zhaotong Airport", "city_name":"Zhaotong"},
 {"airport_iata_code":"XMN", "airport_name":"Xiamen Gaoqi International Airport", "city_name":"Xiamen"},
 {"airport_iata_code":"AQG", "airport_name":"Anqing Airport", "city_name":"Anqing"},
 {"airport_iata_code":"BFU", "airport_name":"Bengbu Airport", "city_name":"Bengbu"},
 {"airport_iata_code":"CZX", "airport_name":"Changzhou Airport", "city_name":"Changzhou"},
 {"airport_iata_code":"KHN", "airport_name":"Nanchang Changbei International Airport", "city_name":"Nanchang"},
 {"airport_iata_code":"FUG", "airport_name":"Fuyang Xiguan Airport", "city_name":"Fuyang"},
 {"airport_iata_code":"FOC", "airport_name":"Fuzhou Changle International Airport", "city_name":"Fuzhou"},
 {"airport_iata_code":"KOW", "airport_name":"Ganzhou Airport", "city_name":"Ganzhou"},
 {"airport_iata_code":"HGH", "airport_name":"Hangzhou Xiaoshan International Airport", "city_name":"Hangzhou"},
 {"airport_iata_code":"JDZ", "airport_name":"Jingdezhen Airport", "city_name":"Jingdezhen"},
 {"airport_iata_code":"JIU", "airport_name":"Jiujiang Lushan Airport", "city_name":"Jiujiang"},
 {"airport_iata_code":"TNA", "airport_name":"Yaoqiang Airport", "city_name":"Jinan"},
 {"airport_iata_code":"JUZ", "airport_name":"Quzhou Airport", "city_name":"Quzhou"},
 {"airport_iata_code":"LCX", "airport_name":"Longyan Guanzhishan Airport", "city_name":"Longyan"},
 {"airport_iata_code":"LYG", "airport_name":"Lianyungang Airport", "city_name":"Lianyungang"},
 {"airport_iata_code":"HYN", "airport_name":"Huangyan Luqiao Airport", "city_name":"Huangyan"},
 {"airport_iata_code":"LYI", "airport_name":"Shubuling Airport", "city_name":"Linyi"},
 {"airport_iata_code":"NGB", "airport_name":"Ningbo Lishe International Airport", "city_name":"Ningbo"},
 {"airport_iata_code":"NKG", "airport_name":"Nanjing Lukou Airport", "city_name":"Nanjing"},
 {"airport_iata_code":"HFE", "airport_name":"Hefei Luogang International Airport", "city_name":"Hefei"},
 {"airport_iata_code":"PVG", "airport_name":"Shanghai Pudong International Airport", "city_name":"Shanghai"},
 {"airport_iata_code":"TAO", "airport_name":"Liuting Airport", "city_name":"Qingdao"},
 {"airport_iata_code":"JJN", "airport_name":"Quanzhou Airport", "city_name":"Quanzhou"},
 {"airport_iata_code":"RUG", "airport_name":"Rugao Air Base", "city_name":"Rugao"},
 {"airport_iata_code":"SHA", "airport_name":"Shanghai Hongqiao International Airport", "city_name":"Shanghai"},
 {"airport_iata_code":"SZV", "airport_name":"Guangfu Airport", "city_name":"Suzhou"},
 {"airport_iata_code":"TXN", "airport_name":"Tunxi International Airport", "city_name":"Huangshan"},
 {"airport_iata_code":"WEF", "airport_name":"Weifang Airport", "city_name":"Weifang"},
 {"airport_iata_code":"WEH", "airport_name":"Weihai Airport", "city_name":"Weihai"},
 {"airport_iata_code":"WHU", "airport_name":"Wuhu Air Base", "city_name":"Wuhu"},
 {"airport_iata_code":"WUX", "airport_name":"Sunan Shuofang International Airport", "city_name":"Wuxi"},
 {"airport_iata_code":"WUS", "airport_name":"Nanping Wuyishan Airport", "city_name":"Wuyishan"},
 {"airport_iata_code":"WNZ", "airport_name":"Wenzhou Yongqiang Airport", "city_name":"Wenzhou"},
 {"airport_iata_code":"XUZ", "airport_name":"Xuzhou Guanyin Airport", "city_name":"Xuzhou"},
 {"airport_iata_code":"YNZ", "airport_name":"Yancheng Airport", "city_name":"Yancheng"},
 {"airport_iata_code":"YNT", "airport_name":"Yantai Laishan Airport", "city_name":"Yantai"},
 {"airport_iata_code":"YIW", "airport_name":"Yiwu Airport", "city_name":"Yiwu"},
 {"airport_iata_code":"HSN", "airport_name":"Zhoushan Airport", "city_name":"Zhoushan"},
 {"airport_iata_code":"NGQ", "airport_name":"Ngari Gunsa Airport", "city_name":"Shiquanhe"},
 {"airport_iata_code":"AVA", "airport_name":"Anshun Huangguoshu Airport", "city_name":"Anshun"},
 {"airport_iata_code":"BPX", "airport_name":"Qamdo Bangda Airport", "city_name":"Bangda"},
 {"airport_iata_code":"CKG", "airport_name":"Chongqing Jiangbei International Airport", "city_name":"Chongqing"},
 {"airport_iata_code":"DAX", "airport_name":"Dachuan Airport", "city_name":"Dazhou"},
 {"airport_iata_code":"GHN", "airport_name":"Guanghan Airport", "city_name":"Civil Aviation Flight Universi"},
 {"airport_iata_code":"GYS", "airport_name":"Guangyuan Airport", "city_name":"Guangyuan"},
 {"airport_iata_code":"KWE", "airport_name":"Longdongbao Airport", "city_name":"Guiyang"},
 {"airport_iata_code":"JZH", "airport_name":"Jiuzhai Huanglong Airport", "city_name":"Jiuzhaigou"},
 {"airport_iata_code":"LIA", "airport_name":"Liangping Airport", "city_name":"Liangping"},
 {"airport_iata_code":"LXA", "airport_name":"Lhasa Gonggar Airport", "city_name":"Lhasa"},
 {"airport_iata_code":"LZO", "airport_name":"Luzhou Airport", "city_name":"Luzhou"},
 {"airport_iata_code":"MIG", "airport_name":"Mianyang Airport", "city_name":"Mianyang"},
 {"airport_iata_code":"NAO", "airport_name":"Nanchong Airport", "city_name":"Nanchong"},
 {"airport_iata_code":"HZH", "airport_name":"Liping Airport", "city_name":"Liping"},
 {"airport_iata_code":"LZY", "airport_name":"Nyingchi Airport", "city_name":"Nyingchi"},
 {"airport_iata_code":"TCZ", "airport_name":"Tengchong Tuofeng Airport", "city_name":"Tengchong"},
 {"airport_iata_code":"TEN", "airport_name":"Tongren Fenghuang Airport", "city_name":""},
 {"airport_iata_code":"CTU", "airport_name":"Chengdu Shuangliu International Airport", "city_name":"Chengdu"},
 {"airport_iata_code":"WXN", "airport_name":"Wanxian Airport", "city_name":"Wanxian"},
 {"airport_iata_code":"XIC", "airport_name":"Xichang Qingshan Airport", "city_name":"Xichang"},
 {"airport_iata_code":"YBP", "airport_name":"Yibin Caiba Airport", "city_name":"Yibin"},
 {"airport_iata_code":"ACX", "airport_name":"Xingyi Airport", "city_name":"Xingyi"},
 {"airport_iata_code":"ZYI", "airport_name":"Zunyi Xinzhou Airport", "city_name":"Zunyi"},
 {"airport_iata_code":"AKU", "airport_name":"Aksu Airport", "city_name":"Aksu"},
 {"airport_iata_code":"BPL", "airport_name":"Alashankou Bole (Bortala) airport", "city_name":"Bole"},
 {"airport_iata_code":"IQM", "airport_name":"Qiemo Airport", "city_name":"Qiemo"},
 {"airport_iata_code":"HMI", "airport_name":"Hami Airport", "city_name":"Hami"},
 {"airport_iata_code":"KCA", "airport_name":"Kuqa Airport", "city_name":"Kuqa"},
 {"airport_iata_code":"KRL", "airport_name":"Korla Airport", "city_name":"Korla"},
 {"airport_iata_code":"KRY", "airport_name":"Karamay Airport", "city_name":"Karamay"},
 {"airport_iata_code":"KHG", "airport_name":"Kashgar Airport", "city_name":"Kashgar"},
 {"airport_iata_code":"SXJ", "airport_name":"Shanshan Airport", "city_name":"Shanshan"},
 {"airport_iata_code":"TCG", "airport_name":"Tacheng Airport", "city_name":"Tacheng"},
 {"airport_iata_code":"HTN", "airport_name":"Hotan Airport", "city_name":"Hotan"},
 {"airport_iata_code":"URC", "airport_name":"\u00dcr\u00fcmqi Diwopu International Airport", "city_name":"\u00dcr\u00fcmqi"},
 {"airport_iata_code":"YIN", "airport_name":"Yining Airport", "city_name":"Yining"},
 {"airport_iata_code":"AOG", "airport_name":"Anshan Air Base", "city_name":"Anshan"},
 {"airport_iata_code":"CGQ", "airport_name":"Longjia Airport", "city_name":"Changchun"},
 {"airport_iata_code":"CNI", "airport_name":"Changhai Airport", "city_name":"Changhai"},
 {"airport_iata_code":"CHG", "airport_name":"Chaoyang Airport", "city_name":"Chaoyang"},
 {"airport_iata_code":"HRB", "airport_name":"Taiping Airport", "city_name":"Harbin"},
 {"airport_iata_code":"HEK", "airport_name":"Heihe Airport", "city_name":"Heihe"},
 {"airport_iata_code":"JIL", "airport_name":"Jilin Airport", "city_name":"Jilin"},
 {"airport_iata_code":"JMU", "airport_name":"Jiamusi Airport", "city_name":"Jiamusi"},
 {"airport_iata_code":"JNZ", "airport_name":"Jinzhou Airport", "city_name":"Jinzhou"},
 {"airport_iata_code":"LDS", "airport_name":"Lindu Airport", "city_name":"Yichun"},
 {"airport_iata_code":"YUS", "airport_name":"Yushu Batang Airport", "city_name":"Yushu"},
 {"airport_iata_code":"MDG", "airport_name":"Mudanjiang Hailang International Airport", "city_name":"Mudanjiang"},
 {"airport_iata_code":"OHE", "airport_name":"Gu-Lian Airport", "city_name":"Mohe"},
 {"airport_iata_code":"NDG", "airport_name":"Qiqihar Sanjiazi Airport", "city_name":"Qiqihar"},
 {"airport_iata_code":"DLC", "airport_name":"Zhoushuizi Airport", "city_name":"Dalian"},
 {"airport_iata_code":"TNH", "airport_name":"Tonghua Sanyuanpu Airport", "city_name":"Tonghua"},
 {"airport_iata_code":"SHE", "airport_name":"Taoxian Airport", "city_name":"Shenyang"},
 {"airport_iata_code":"YNJ", "airport_name":"Yanji Chaoyangchuan Airport", "city_name":"Yanji"},
 {"airport_iata_code":"NBS", "airport_name":"Changbaishan Airport", "city_name":"Baishan"},
 {"airport_iata_code":"ACM", "airport_name":"Arica Airport", "city_name":"Arica"},
 {"airport_iata_code":"ECO", "airport_name":"El Encanto Airport", "city_name":"El Encanto"},
 {"airport_iata_code":"ARO", "airport_name":"Arboletes Airport", "city_name":"Arboletes"},
 {"airport_iata_code":"AYI", "airport_name":"Yari Airport", "city_name":"Yari"},
 {"airport_iata_code":"ACL", "airport_name":"Aguaclara Airport", "city_name":"Aguaclara"},
 {"airport_iata_code":"TAU", "airport_name":"Tauramena Airport", "city_name":"Tauramena"},
 {"airport_iata_code":"AYC", "airport_name":"Ayacucho Airport", "city_name":"Ayacucho"},
 {"airport_iata_code":"DZI", "airport_name":"Codazzi Airport", "city_name":"Hacienda Borrero"},
 {"airport_iata_code":"BHF", "airport_name":"Bahia Cupica Airport", "city_name":"Bah\u00eda Cupica"},
 {"airport_iata_code":"AYA", "airport_name":"Ayapel Airport", "city_name":"Ayapel"},
 {"airport_iata_code":"NBB", "airport_name":"Barranco Minas Airport", "city_name":"Barranco Minas"},
 {"airport_iata_code":"BAC", "airport_name":"Barranca De Upia Airport", "city_name":"Barranca De Upia"},
 {"airport_iata_code":"ECR", "airport_name":"El Charco Airport", "city_name":"El Charco"},
 {"airport_iata_code":"AZT", "airport_name":"Zapatoca Airport", "city_name":"Zapatoca"},
 {"airport_iata_code":"SQB", "airport_name":"Santa Ana Airport", "city_name":"Piedras"},
 {"airport_iata_code":"ARF", "airport_name":"Acaricuara Airport", "city_name":"Acaricuara"},
 {"airport_iata_code":"ACR", "airport_name":"Araracuara Airport", "city_name":"Araracuara"},
 {"airport_iata_code":"ACD", "airport_name":"Alcides Fern\u00e1ndez Airport", "city_name":"Acand\u00ed"},
 {"airport_iata_code":"AFI", "airport_name":"Amalfi Airport", "city_name":"Amalfi"},
 {"airport_iata_code":"ADN", "airport_name":"Andes Airport", "city_name":"Andes"},
 {"airport_iata_code":"API", "airport_name":"Gomez Nino Apiay Air Base", "city_name":"Apiay"},
 {"airport_iata_code":"AXM", "airport_name":"El Eden Airport", "city_name":"Armenia"},
 {"airport_iata_code":"PUU", "airport_name":"Tres De Mayo Airport", "city_name":"Puerto As\u00eds"},
 {"airport_iata_code":"ELB", "airport_name":"Las Flores Airport", "city_name":"El Banco"},
 {"airport_iata_code":"BGA", "airport_name":"Palonegro Airport", "city_name":"Bucaramanga"},
 {"airport_iata_code":"BOG", "airport_name":"El Dorado International Airport", "city_name":"Bogota"},
 {"airport_iata_code":"BAQ", "airport_name":"Ernesto Cortissoz International Airport", "city_name":"Barranquilla"},
 {"airport_iata_code":"BSC", "airport_name":"Jos\u00e9 Celestino Mutis Airport", "city_name":"Bah\u00eda Solano"},
 {"airport_iata_code":"BUN", "airport_name":"Gerardo Tobar L\u00f3pez Airport", "city_name":"Buenaventura"},
 {"airport_iata_code":"CPB", "airport_name":"Capurgan\u00e1 Airport", "city_name":"Capurgan\u00e1"},
 {"airport_iata_code":"CUC", "airport_name":"Camilo Daza International Airport", "city_name":"C\u00facuta"},
 {"airport_iata_code":"COG", "airport_name":"Mandinga Airport", "city_name":"Condoto"},
 {"airport_iata_code":"CTG", "airport_name":"Rafael Nu\u00f1ez International Airport", "city_name":"Cartagena"},
 {"airport_iata_code":"CCO", "airport_name":"Carimagua Airport", "city_name":"Puerto L\u00f3pez"},
 {"airport_iata_code":"CLO", "airport_name":"Alfonso Bonilla Aragon International Airport", "city_name":"Cali"},
 {"airport_iata_code":"CIM", "airport_name":"Cimitarra Airport", "city_name":"Cimitarra"},
 {"airport_iata_code":"RAV", "airport_name":"Cravo Norte Airport", "city_name":"Cravo Norte"},
 {"airport_iata_code":"TCO", "airport_name":"La Florida Airport", "city_name":"Tumaco"},
 {"airport_iata_code":"CUO", "airport_name":"Carur\u00fa Airport", "city_name":"Carur\u00fa"},
 {"airport_iata_code":"CAQ", "airport_name":"Juan H White Airport", "city_name":"Caucasia"},
 {"airport_iata_code":"CVE", "airport_name":"Cove\u00f1as Airport", "city_name":"Cove\u00f1as"},
 {"airport_iata_code":"CZU", "airport_name":"Las Brujas Airport", "city_name":"Corozal"},
 {"airport_iata_code":"EBG", "airport_name":"El Bagre Airport", "city_name":"El Bagre"},
 {"airport_iata_code":"EJA", "airport_name":"Yarigu\u00edes Airport", "city_name":"Barrancabermeja"},
 {"airport_iata_code":"FLA", "airport_name":"Gustavo Artunduaga Paredes Airport", "city_name":"Florencia"},
 {"airport_iata_code":"FDA", "airport_name":"Fundaci\u00f3n Airport", "city_name":"Fundaci\u00f3n"},
 {"airport_iata_code":"GIR", "airport_name":"Santiago Vila Airport", "city_name":"Girardot"},
 {"airport_iata_code":"CRC", "airport_name":"Santa Ana Airport", "city_name":"Cartago"},
 {"airport_iata_code":"GPI", "airport_name":"Juan Casiano Airport", "city_name":"Guapi"},
 {"airport_iata_code":"GLJ", "airport_name":"Garz\u00f3n La Jagua Airport", "city_name":"Garz\u00f3n"},
 {"airport_iata_code":"CPL", "airport_name":"Chaparral Airport", "city_name":"Chaparral"},
 {"airport_iata_code":"HTZ", "airport_name":"Hato Corozal Airport", "city_name":"Hato Corozal"},
 {"airport_iata_code":"IBE", "airport_name":"Perales Airport", "city_name":"Ibagu\u00e9"},
 {"airport_iata_code":"IGO", "airport_name":"Chigorod\u00f3 Airport", "city_name":"Chigorod\u00f3"},
 {"airport_iata_code":"MMP", "airport_name":"Cicuco Airport", "city_name":"Lim\u00f3n"},
 {"airport_iata_code":"IPI", "airport_name":"San Luis Airport", "city_name":"Ipiales"},
 {"airport_iata_code":"LQM", "airport_name":"Caucaya Airport", "city_name":"Puerto Legu\u00edzamo"},
 {"airport_iata_code":"LPD", "airport_name":"La Pedrera Airport", "city_name":"La Pedrera"},
 {"airport_iata_code":"LET", "airport_name":"Alfredo V\u00e1squez Cobo International Airport", "city_name":"Leticia"},
 {"airport_iata_code":"EOH", "airport_name":"Enrique Olaya Herrera Airport", "city_name":"Medell\u00edn"},
 {"airport_iata_code":"MFS", "airport_name":"Miraflores Airport", "city_name":"Miraflores"},
 {"airport_iata_code":"MGN", "airport_name":"Baracoa Airport", "city_name":"Magangu\u00e9"},
 {"airport_iata_code":"MCJ", "airport_name":"Maicao Airport", "city_name":"Maicao"},
 {"airport_iata_code":"MTB", "airport_name":"Montelibano Airport", "city_name":"Montel\u00edbano"},
 {"airport_iata_code":"MTR", "airport_name":"Los Garzones Airport", "city_name":"Monter\u00eda"},
 {"airport_iata_code":"MVP", "airport_name":"Fabio Alberto Leon Bentley Airport", "city_name":"Mit\u00fa"},
 {"airport_iata_code":"MZL", "airport_name":"La Nubia Airport", "city_name":"Manizales"},
 {"airport_iata_code":"NCI", "airport_name":"Necocli Airport", "city_name":"Necocli"},
 {"airport_iata_code":"NQU", "airport_name":"Reyes Murillo Airport", "city_name":"Nuqu\u00ed"},
 {"airport_iata_code":"NVA", "airport_name":"Benito Salas Airport", "city_name":"Neiva"},
 {"airport_iata_code":"OCV", "airport_name":"Aguas Claras Airport", "city_name":"Oca\u00f1a"},
 {"airport_iata_code":"ORC", "airport_name":"Orocue Airport", "city_name":"Orocue"},
 {"airport_iata_code":"PCR", "airport_name":"German Olano Airport", "city_name":"Puerto Carre\u00f1o"},
 {"airport_iata_code":"PDA", "airport_name":"Obando Airport", "city_name":"Puerto In\u00edrida"},
 {"airport_iata_code":"PEI", "airport_name":"Mateca\u00f1a International Airport", "city_name":"Pereira"},
 {"airport_iata_code":"PTX", "airport_name":"Pitalito Airport", "city_name":"Pitalito"},
 {"airport_iata_code":"PLT", "airport_name":"Plato Airport", "city_name":"Plato"},
 {"airport_iata_code":"NAR", "airport_name":"Puerto Nare Airport", "city_name":"Armenia"},
 {"airport_iata_code":"PPN", "airport_name":"Guillermo Le\u00f3n Valencia Airport", "city_name":"Popay\u00e1n"},
 {"airport_iata_code":"PQE", "airport_name":"German Olano Air Base", "city_name":"La Dorada"},
 {"airport_iata_code":"PBE", "airport_name":"Puerto Berrio Airport", "city_name":"Puerto Berrio"},
 {"airport_iata_code":"PSO", "airport_name":"Antonio Narino Airport", "city_name":"Pasto"},
 {"airport_iata_code":"PVA", "airport_name":"El Embrujo Airport", "city_name":"Providencia"},
 {"airport_iata_code":"PZA", "airport_name":"Paz De Ariporo Airport", "city_name":"Paz De Ariporo"},
 {"airport_iata_code":"MQU", "airport_name":"Jose Celestino Mutis Airport", "city_name":"Mariquita"},
 {"airport_iata_code":"MDE", "airport_name":"Jose Maria C\u00f3rdova International Airport", "city_name":"Rionegro"},
 {"airport_iata_code":"RCH", "airport_name":"Almirante Padilla Airport", "city_name":"Riohacha"},
 {"airport_iata_code":"SJE", "airport_name":"Jorge E. Gonzalez Torres Airport", "city_name":"San Jos\u00e9 Del Guaviare"},
 {"airport_iata_code":"SMR", "airport_name":"Sim\u00f3n Bol\u00edvar International Airport", "city_name":"Santa Marta"},
 {"airport_iata_code":"SOX", "airport_name":"Alberto Lleras Camargo Airport", "city_name":"Sogamoso"},
 {"airport_iata_code":"ADZ", "airport_name":"Gustavo Rojas Pinilla International Airport", "city_name":"San Andr\u00e9s"},
 {"airport_iata_code":"SVI", "airport_name":"Eduardo Falla Solano Airport", "city_name":"San Vicente Del Cagu\u00e1n"},
 {"airport_iata_code":"TIB", "airport_name":"Tib\u00fa Airport", "city_name":"Tib\u00fa"},
 {"airport_iata_code":"TDA", "airport_name":"Trinidad Airport", "city_name":"Trinidad"},
 {"airport_iata_code":"TLU", "airport_name":"Tol\u00fa Airport", "city_name":"Tol\u00fa"},
 {"airport_iata_code":"TME", "airport_name":"Gustavo Vargas Airport", "city_name":"Tame"},
 {"airport_iata_code":"TQS", "airport_name":"Tres Esquinas Air Base", "city_name":"Tres Esquinas"},
 {"airport_iata_code":"TRB", "airport_name":"Gonzalo Mej\u00eda Airport", "city_name":"Turbo"},
 {"airport_iata_code":"AUC", "airport_name":"Santiago Perez Airport", "city_name":"Arauca"},
 {"airport_iata_code":"UIB", "airport_name":"El Cara\u00f1o Airport", "city_name":"Quibd\u00f3"},
 {"airport_iata_code":"ULQ", "airport_name":"Farfan Airport", "city_name":"Tulu\u00e1"},
 {"airport_iata_code":"URR", "airport_name":"Urrao Airport", "city_name":"Urrao"},
 {"airport_iata_code":"VUP", "airport_name":"Alfonso L\u00f3pez Pumarejo Airport", "city_name":"Valledupar"},
 {"airport_iata_code":"VVC", "airport_name":"Vanguardia Airport", "city_name":"Villavicencio"},
 {"airport_iata_code":"AYG", "airport_name":"Yaguara Airport", "city_name":"San Vicente Del Cagu\u00e1n"},
 {"airport_iata_code":"EYP", "airport_name":"El Yopal Airport", "city_name":"El Yopal"},
 {"airport_iata_code":"LMC", "airport_name":"La Macarena Airport", "city_name":"La Macarena"},
 {"airport_iata_code":"APO", "airport_name":"Apartad\u00f3 Airport", "city_name":"Apartad\u00f3"},
 {"airport_iata_code":"ARQ", "airport_name":"El Troncal Airport", "city_name":"Arauquita"},
 {"airport_iata_code":"LCR", "airport_name":"La Chorrera Airport", "city_name":"La Chorrera"},
 {"airport_iata_code":"SNT", "airport_name":"Las Cruces Airport", "city_name":"Sabana De Torres"},
 {"airport_iata_code":"TCD", "airport_name":"Tarapac\u00e1 Airport", "city_name":"Tarapac\u00e1"},
 {"airport_iata_code":"FON", "airport_name":"Arenal Airport", "city_name":"La Fortuna/San Carlos"},
 {"airport_iata_code":"TTQ", "airport_name":"Aerotortuguero Airport", "city_name":"Roxana"},
 {"airport_iata_code":"BAI", "airport_name":"Buenos Aires Airport", "city_name":"Punta Arenas"},
 {"airport_iata_code":"BCL", "airport_name":"Barra del Colorado Airport", "city_name":"Pococi"},
 {"airport_iata_code":"CSC", "airport_name":"Codela Airport", "city_name":"Guapiles"},
 {"airport_iata_code":"OTR", "airport_name":"Coto 47 Airport", "city_name":"Corredores"},
 {"airport_iata_code":"RIK", "airport_name":"Carrillo Airport", "city_name":"Nicoya"},
 {"airport_iata_code":"DRK", "airport_name":"Drake Bay Airport", "city_name":"Puntarenas"},
 {"airport_iata_code":"FMG", "airport_name":"Flamingo Airport", "city_name":"Brasilito"},
 {"airport_iata_code":"GLF", "airport_name":"Golfito Airport", "city_name":"Golfito"},
 {"airport_iata_code":"GPL", "airport_name":"Guapiles Airport", "city_name":"Pococi"},
 {"airport_iata_code":"PBP", "airport_name":"Islita Airport", "city_name":"Nandayure"},
 {"airport_iata_code":"LIR", "airport_name":"Daniel Oduber Quiros International Airport", "city_name":"Liberia"},
 {"airport_iata_code":"LSL", "airport_name":"Los Chiles Airport", "city_name":"Los Chiles"},
 {"airport_iata_code":"LIO", "airport_name":"Limon International Airport", "city_name":"Puerto Limon"},
 {"airport_iata_code":"NCT", "airport_name":"Guanacaste Airport", "city_name":"Nicoya/Guanacate"},
 {"airport_iata_code":"NOB", "airport_name":"Nosara Airport", "city_name":"Nicoya"},
 {"airport_iata_code":"SJO", "airport_name":"Juan Santamaria International Airport", "city_name":"San Jose"},
 {"airport_iata_code":"PJM", "airport_name":"Puerto Jimenez Airport", "city_name":"Puerto Jimenez"},
 {"airport_iata_code":"PMZ", "airport_name":"Palmar Sur Airport", "city_name":"Palmar Sur"},
 {"airport_iata_code":"SYQ", "airport_name":"Tobias Bolanos International Airport", "city_name":"San Jose"},
 {"airport_iata_code":"XQP", "airport_name":"Quepos Managua Airport", "city_name":"Quepos"},
 {"airport_iata_code":"RFR", "airport_name":"Rio Frio / Progreso Airport", "city_name":"Rio Frio / Progreso"},
 {"airport_iata_code":"PLD", "airport_name":"Playa Samara Airport", "city_name":"Playa Samara"},
 {"airport_iata_code":"TOO", "airport_name":"San Vito De Java Airport", "city_name":"Coto Brus"},
 {"airport_iata_code":"TNO", "airport_name":"Tamarindo De Santa Cruz Airport", "city_name":"Santa Cruz"},
 {"airport_iata_code":"TMU", "airport_name":"Tambor Airport", "city_name":"Nicoya"},
 {"airport_iata_code":"UPL", "airport_name":"Upala Airport", "city_name":"Upala"},
 {"airport_iata_code":"BCA", "airport_name":"Gustavo Rizo Airport", "city_name":"Baracoa"},
 {"airport_iata_code":"BWW", "airport_name":"Las Brujas Airport", "city_name":"Cayo Santa Maria"},
 {"airport_iata_code":"BYM", "airport_name":"Carlos Manuel de Cespedes Airport", "city_name":"Bayamo"},
 {"airport_iata_code":"AVI", "airport_name":"Maximo Gomez Airport", "city_name":"Ciego de Avila"},
 {"airport_iata_code":"CCC", "airport_name":"Jardines Del Rey Airport", "city_name":"Cayo Coco"},
 {"airport_iata_code":"CFG", "airport_name":"Jaime Gonzalez Airport", "city_name":"Cienfuegos"},
 {"airport_iata_code":"CYO", "airport_name":"Vilo Acuna International Airport", "city_name":"Cayo Largo del Sur"},
 {"airport_iata_code":"CMW", "airport_name":"Ignacio Agramonte International Airport", "city_name":"Camaguey"},
 {"airport_iata_code":"QCO", "airport_name":"Col\u00f3n Airport", "city_name":"Col\u00f3n"},
 {"airport_iata_code":"SCU", "airport_name":"Antonio Maceo International Airport", "city_name":"Santiago"},
 {"airport_iata_code":"NBW", "airport_name":"Leeward Point Field", "city_name":"Guantanamo Bay Naval Station"},
 {"airport_iata_code":"GAO", "airport_name":"Mariana Grajales Airport", "city_name":"Guant\u00e1namo"},
 {"airport_iata_code":"HAV", "airport_name":"Jos\u00e9 Mart\u00ed International Airport", "city_name":"Havana"},
 {"airport_iata_code":"HOG", "airport_name":"Frank Pais International Airport", "city_name":"Holguin"},
 {"airport_iata_code":"VRO", "airport_name":"Kawama Airport", "city_name":"Matanzas"},
 {"airport_iata_code":"LCL", "airport_name":"La Coloma Airport", "city_name":"Pinar del Rio"},
 {"airport_iata_code":"UMA", "airport_name":"Punta de Maisi Airport", "city_name":"Maisi"},
 {"airport_iata_code":"MJG", "airport_name":"Mayajigua Airport", "city_name":"Mayajigua"},
 {"airport_iata_code":"MOA", "airport_name":"Orestes Acosta Airport", "city_name":"Moa"},
 {"airport_iata_code":"MZO", "airport_name":"Sierra Maestra Airport", "city_name":"Manzanillo"},
 {"airport_iata_code":"QSN", "airport_name":"San Nicolas De Bari Airport", "city_name":"San Nicol\u00e1s"},
 {"airport_iata_code":"ICR", "airport_name":"Nicaro Airport", "city_name":"Nicaro"},
 {"airport_iata_code":"GER", "airport_name":"Rafael Cabrera Airport", "city_name":"Nueva Gerona"},
 {"airport_iata_code":"UPB", "airport_name":"Playa Baracoa Airport", "city_name":"Havana"},
 {"airport_iata_code":"QPD", "airport_name":"Pinar Del Rio Airport", "city_name":"Pinar del Rio"},
 {"airport_iata_code":"SNU", "airport_name":"Abel Santamaria Airport", "city_name":"Santa Clara"},
 {"airport_iata_code":"SNJ", "airport_name":"San Julian Air Base", "city_name":"Pinar Del Rio"},
 {"airport_iata_code":"SZJ", "airport_name":"Siguanea Airport", "city_name":"Isla de la Juventud"},
 {"airport_iata_code":"USS", "airport_name":"Sancti Spiritus Airport", "city_name":"Sancti Spiritus"},
 {"airport_iata_code":"TND", "airport_name":"Alberto Delgado Airport", "city_name":"Trinidad"},
 {"airport_iata_code":"VRA", "airport_name":"Juan Gualberto Gomez International Airport", "city_name":"Varadero"},
 {"airport_iata_code":"VTU", "airport_name":"Hermanos Ameijeiras Airport", "city_name":"Las Tunas"},
 {"airport_iata_code":"PST", "airport_name":"Preston Airport", "city_name":"Preston"},
 {"airport_iata_code":"BVR", "airport_name":"Esperadinha Airport", "city_name":"Brava Island"},
 {"airport_iata_code":"SID", "airport_name":"Am\u00edlcar Cabral International Airport", "city_name":"Espargos"},
 {"airport_iata_code":"NTO", "airport_name":"Agostinho Neto Airport", "city_name":"Ponta do Sol"},
 {"airport_iata_code":"BVC", "airport_name":"Rabil Airport", "city_name":"Rabil"},
 {"airport_iata_code":"MMO", "airport_name":"Maio Airport", "city_name":"Vila do Maio"},
 {"airport_iata_code":"MTI", "airport_name":"Mosteiros Airport", "city_name":"Vila do Mosteiros"},
 {"airport_iata_code":"RAI", "airport_name":"Praia International Airport", "city_name":"Praia"},
 {"airport_iata_code":"SFL", "airport_name":"S\u00e3o Filipe Airport", "city_name":"S\u00e3o Filipe"},
 {"airport_iata_code":"SNE", "airport_name":"Pregui\u00e7a Airport", "city_name":"Pregui\u00e7a"},
 {"airport_iata_code":"VXE", "airport_name":"S\u00e3o Pedro Airport", "city_name":"S\u00e3o Pedro"},
 {"airport_iata_code":"CUR", "airport_name":"Hato International Airport", "city_name":"Willemstad"},
 {"airport_iata_code":"XCH", "airport_name":"Christmas Island Airport", "city_name":"Christmas Island"},
 {"airport_iata_code":"ECN", "airport_name":"Ercan International Airport", "city_name":"Nicosia"},
 {"airport_iata_code":"LCA", "airport_name":"Larnaca International Airport", "city_name":"Larnarca"},
 {"airport_iata_code":"PFO", "airport_name":"Paphos International Airport", "city_name":"Paphos"},
 {"airport_iata_code":"AKT", "airport_name":"RAF Akrotiri", "city_name":""},
 {"airport_iata_code":"GEC", "airport_name":"Ge\u00e7itkale Air Base", "city_name":""},
 {"airport_iata_code":"GTW", "airport_name":"Hole\u0161ov Airport", "city_name":"Hole\u0161ov"},
 {"airport_iata_code":"UHE", "airport_name":"Kunovice Airport", "city_name":"Uhersk\u00e9 Hradi\u0161t?"},
 {"airport_iata_code":"KLV", "airport_name":"Karlovy Vary International Airport", "city_name":"Karlovy Vary"},
 {"airport_iata_code":"MKA", "airport_name":"Mari\u00e1nsk\u00e9 L\u00e1zn? Airport", "city_name":"Mari\u00e1nsk\u00e9 L\u00e1zn?"},
 {"airport_iata_code":"OSR", "airport_name":"Ostrava Leos Jan\u00e1?ek Airport", "city_name":"Ostrava"},
 {"airport_iata_code":"PED", "airport_name":"Pardubice Airport", "city_name":"Pardubice"},
 {"airport_iata_code":"PRV", "airport_name":"P?erov Air Base", "city_name":"P?erov"},
 {"airport_iata_code":"PRG", "airport_name":"Ruzyn? International Airport", "city_name":"Prague"},
 {"airport_iata_code":"BRQ", "airport_name":"Brno-Tu?any Airport", "city_name":"Brno"},
 {"airport_iata_code":"ZBE", "airport_name":"Zabreh Ostrava Airport", "city_name":"Zabreh"},
 {"airport_iata_code":"AOC", "airport_name":"Altenburg-Nobitz Airport", "city_name":"Altenburg"},
 {"airport_iata_code":"HDF", "airport_name":"Heringsdorf Airport", "city_name":"Heringsdorf"},
 {"airport_iata_code":"REB", "airport_name":"Rechlin-L\u00e4rz Airport", "city_name":"L\u00e4rz"},
 {"airport_iata_code":"CSO", "airport_name":"Cochstedt Airport", "city_name":"Magdeburg"},
 {"airport_iata_code":"BBH", "airport_name":"Barth Airport", "city_name":""},
 {"airport_iata_code":"ZMG", "airport_name":"Magdeburg Airport", "city_name":"Magdeburg"},
 {"airport_iata_code":"CBU", "airport_name":"Cottbus-Drewitz Airport", "city_name":"Cottbus"},
 {"airport_iata_code":"GTI", "airport_name":"R\u00fcgen Airport", "city_name":"R\u00fcgen"},
 {"airport_iata_code":"PEF", "airport_name":"Peenem\u00fcnde Airport", "city_name":"Peenem\u00fcnde"},
 {"airport_iata_code":"SXF", "airport_name":"Berlin-Sch\u00f6nefeld International Airport", "city_name":"Berlin"},
 {"airport_iata_code":"DRS", "airport_name":"Dresden Airport", "city_name":"Dresden"},
 {"airport_iata_code":"ERF", "airport_name":"Erfurt Airport", "city_name":"Erfurt"},
 {"airport_iata_code":"FRA", "airport_name":"Frankfurt am Main International Airport", "city_name":"Frankfurt-am-Main"},
 {"airport_iata_code":"FMO", "airport_name":"M\u00fcnster Osnabr\u00fcck Airport", "city_name":"M\u00fcnster"},
 {"airport_iata_code":"HAM", "airport_name":"Hamburg Airport", "city_name":"Hamburg"},
 {"airport_iata_code":"CGN", "airport_name":"Cologne Bonn Airport", "city_name":"Cologne"},
 {"airport_iata_code":"DUS", "airport_name":"D\u00fcsseldorf International Airport", "city_name":"D\u00fcsseldorf"},
 {"airport_iata_code":"MUC", "airport_name":"Munich International Airport", "city_name":"Munich"},
 {"airport_iata_code":"NUE", "airport_name":"Nuremberg Airport", "city_name":"Nuremberg"},
 {"airport_iata_code":"LEJ", "airport_name":"Leipzig Halle Airport", "city_name":"Leipzig"},
 {"airport_iata_code":"SCN", "airport_name":"Saarbr\u00fccken Airport", "city_name":"Saarbr\u00fccken"},
 {"airport_iata_code":"STR", "airport_name":"Stuttgart Airport", "city_name":"Stuttgart"},
 {"airport_iata_code":"TXL", "airport_name":"Berlin-Tegel International Airport", "city_name":"Berlin"},
 {"airport_iata_code":"HAJ", "airport_name":"Hannover Airport", "city_name":"Hannover"},
 {"airport_iata_code":"BRE", "airport_name":"Bremen Airport", "city_name":"Bremen"},
 {"airport_iata_code":"QEF", "airport_name":"Frankfurt-Egelsbach Airport", "city_name":"Egelsbach"},
 {"airport_iata_code":"HHN", "airport_name":"Frankfurt-Hahn Airport", "city_name":"Hahn"},
 {"airport_iata_code":"MHG", "airport_name":"Mannheim-City Airport", "city_name":"Mannheim"},
 {"airport_iata_code":"QMZ", "airport_name":"Mainz-Finthen Airport", "city_name":"Mainz"},
 {"airport_iata_code":"EIB", "airport_name":"Eisenach-Kindel Airport", "city_name":"Eisenach"},
 {"airport_iata_code":"SGE", "airport_name":"Siegerland Airport", "city_name":""},
 {"airport_iata_code":"XFW", "airport_name":"Hamburg-Finkenwerder Airport", "city_name":"Hamburg"},
 {"airport_iata_code":"KEL", "airport_name":"Kiel-Holtenau Airport", "city_name":"Kiel"},
 {"airport_iata_code":"LBC", "airport_name":"L\u00fcbeck Blankensee Airport", "city_name":"L\u00fcbeck"},
 {"airport_iata_code":"EUM", "airport_name":"Neum\u00fcnster Airport", "city_name":"Neum\u00fcnster"},
 {"airport_iata_code":"FMM", "airport_name":"Memmingen Allgau Airport", "city_name":"Memmingen"},
 {"airport_iata_code":"AAH", "airport_name":"Aachen-Merzbr\u00fcck Airport", "city_name":"Aachen"},
 {"airport_iata_code":"BNJ", "airport_name":"Bonn-Hangelar Airport", "city_name":"Bonn"},
 {"airport_iata_code":"ESS", "airport_name":"Essen Mulheim Airport", "city_name":""},
 {"airport_iata_code":"BFE", "airport_name":"Bielefeld Airport", "city_name":"Bielefeld"},
 {"airport_iata_code":"ZOJ", "airport_name":"Marl-Loem\u00fchle Airport", "city_name":"Marl"},
 {"airport_iata_code":"MGL", "airport_name":"M\u00f6nchengladbach Airport", "city_name":"M\u00f6nchengladbach"},
 {"airport_iata_code":"PAD", "airport_name":"Paderborn Lippstadt Airport", "city_name":"Paderborn"},
 {"airport_iata_code":"NRN", "airport_name":"Niederrhein Airport", "city_name":"Weeze"},
 {"airport_iata_code":"DTM", "airport_name":"Dortmund Airport", "city_name":"Dortmund"},
 {"airport_iata_code":"AGB", "airport_name":"Augsburg Airport", "city_name":"Augsburg"},
 {"airport_iata_code":"OBF", "airport_name":"Oberpfaffenhofen Airport", "city_name":""},
 {"airport_iata_code":"RBM", "airport_name":"Straubing Airport", "city_name":"Straubing"},
 {"airport_iata_code":"FDH", "airport_name":"Friedrichshafen Airport", "city_name":"Friedrichshafen"},
 {"airport_iata_code":"FRF", "airport_name":"Oschersleben Airport", "city_name":"Oschersleben"},
 {"airport_iata_code":"SZW", "airport_name":"Schwerin Parchim Airport", "city_name":""},
 {"airport_iata_code":"BYU", "airport_name":"Bayreuth Airport", "city_name":"Bayreuth"},
 {"airport_iata_code":"QOB", "airport_name":"Ansbach-Petersdorf Airport", "city_name":"Ansbach"},
 {"airport_iata_code":"HOQ", "airport_name":"Hof-Plauen Airport", "city_name":"Hof"},
 {"airport_iata_code":"BBJ", "airport_name":"Bitburg Airport", "city_name":"Bitburg"},
 {"airport_iata_code":"ZQW", "airport_name":"Zweibr\u00fccken Airport", "city_name":"Zweibr\u00fccken"},
 {"airport_iata_code":"FKB", "airport_name":"Karlsruhe Baden-Baden Airport", "city_name":"Baden-Baden"},
 {"airport_iata_code":"ZQL", "airport_name":"Donaueschingen-Villingen Airport", "city_name":"Donaueschingen"},
 {"airport_iata_code":"LHA", "airport_name":"Lahr Airport", "city_name":""},
 {"airport_iata_code":"BWE", "airport_name":"Braunschweig Wolfsburg Airport", "city_name":""},
 {"airport_iata_code":"KSF", "airport_name":"Kassel-Calden Airport", "city_name":"Kassel"},
 {"airport_iata_code":"BRV", "airport_name":"Bremerhaven Airport", "city_name":"Bremerhaven"},
 {"airport_iata_code":"XLW", "airport_name":"Lemwerder Airport", "city_name":"Lemwerder"},
 {"airport_iata_code":"EME", "airport_name":"Emden Airport", "city_name":"Emden"},
 {"airport_iata_code":"AGE", "airport_name":"Wangerooge Airport", "city_name":"Wangerooge"},
 {"airport_iata_code":"WVN", "airport_name":"Wilhelmshaven-Mariensiel Airport", "city_name":"Wilhelmshaven"},
 {"airport_iata_code":"JUI", "airport_name":"Juist Airport", "city_name":"Juist"},
 {"airport_iata_code":"LGO", "airport_name":"Langeoog Airport", "city_name":"Langeoog"},
 {"airport_iata_code":"ZOW", "airport_name":"Nordhorn-Lingen Airport", "city_name":"Klausheide"},
 {"airport_iata_code":"BMK", "airport_name":"Borkum Airport", "city_name":"Borkum"},
 {"airport_iata_code":"NOD", "airport_name":"Norden-Norddeich Airport", "city_name":"Norddeich"},
 {"airport_iata_code":"VAC", "airport_name":"Varrelbusch Airport", "city_name":"Cloppenburg"},
 {"airport_iata_code":"NRD", "airport_name":"Norderney Airport", "city_name":"Norderney"},
 {"airport_iata_code":"BMR", "airport_name":"Baltrum Airport", "city_name":"Baltrum"},
 {"airport_iata_code":"HEI", "airport_name":"Heide-B\u00fcsum Airport", "city_name":"B\u00fcsum"},
 {"airport_iata_code":"FLF", "airport_name":"Flensburg-Sch\u00e4ferhaus Airport", "city_name":"Flensburg"},
 {"airport_iata_code":"HGL", "airport_name":"Helgoland-D\u00fcne Airport", "city_name":"Helgoland"},
 {"airport_iata_code":"QHU", "airport_name":"Husum-Schwesing Airport", "city_name":"Husum"},
 {"airport_iata_code":"PSH", "airport_name":"St. Peter-Ording Airport", "city_name":"Sankt Peter-Ording"},
 {"airport_iata_code":"GWT", "airport_name":"Westerland Sylt Airport", "city_name":"Westerland"},
 {"airport_iata_code":"OHR", "airport_name":"Wyk auf F\u00f6hr Airport", "city_name":"Wyk auf F\u00f6hr"},
 {"airport_iata_code":"SPM", "airport_name":"Spangdahlem Air Base", "city_name":"Trier"},
 {"airport_iata_code":"RMS", "airport_name":"Ramstein Air Base", "city_name":"Ramstein"},
 {"airport_iata_code":"ZCD", "airport_name":"Bamberg-Breitenau Airport", "city_name":"Bamberg"},
 {"airport_iata_code":"ZCN", "airport_name":"Celle Airport", "city_name":""},
 {"airport_iata_code":"ZPQ", "airport_name":"Rheine Bentlage Airport", "city_name":""},
 {"airport_iata_code":"FRZ", "airport_name":"Fritzlar Airport", "city_name":"Fritzlar"},
 {"airport_iata_code":"ZNF", "airport_name":"Hanau Army Air Field", "city_name":""},
 {"airport_iata_code":"NDZ", "airport_name":"Nordholz Airport", "city_name":""},
 {"airport_iata_code":"GKE", "airport_name":"Geilenkirchen Airport", "city_name":""},
 {"airport_iata_code":"RLG", "airport_name":"Rostock-Laage Airport", "city_name":"Rostock"},
 {"airport_iata_code":"QOE", "airport_name":"Norvenich Airport", "city_name":""},
 {"airport_iata_code":"WBG", "airport_name":"Schleswig Airport", "city_name":""},
 {"airport_iata_code":"FNB", "airport_name":"Neubrandenburg Airport", "city_name":"Neubrandenburg"},
 {"airport_iata_code":"WIE", "airport_name":"Wiesbaden Army Airfield", "city_name":"Wiesbaden"},
 {"airport_iata_code":"FEL", "airport_name":"F\u00fcrstenfeldbruck Airport", "city_name":"F\u00fcrstenfeldbruck"},
 {"airport_iata_code":"IGS", "airport_name":"Ingolstadt Manching Airport", "city_name":"Manching"},
 {"airport_iata_code":"GUT", "airport_name":"G\u00fctersloh Airport", "city_name":"G\u00fctersloh"},
 {"airport_iata_code":"BGN", "airport_name":"Brugge Air Base", "city_name":""},
 {"airport_iata_code":"JIB", "airport_name":"Djibouti-Ambouli Airport", "city_name":"Djibouti City"},
 {"airport_iata_code":"AII", "airport_name":"Ali-Sabieh Airport", "city_name":"Ali-Sabieh"},
 {"airport_iata_code":"MHI", "airport_name":"Moucha Airport", "city_name":"Moucha Island"},
 {"airport_iata_code":"OBC", "airport_name":"Obock Airport", "city_name":"Obock"},
 {"airport_iata_code":"TDJ", "airport_name":"Tadjoura Airport", "city_name":"Tadjoura"},
 {"airport_iata_code":"AAR", "airport_name":"Aarhus Airport", "city_name":"Aarhus"},
 {"airport_iata_code":"BLL", "airport_name":"Billund Airport", "city_name":"Billund"},
 {"airport_iata_code":"CPH", "airport_name":"Copenhagen Kastrup Airport", "city_name":"Copenhagen"},
 {"airport_iata_code":"EBJ", "airport_name":"Esbjerg Airport", "city_name":"Esbjerg"},
 {"airport_iata_code":"KRP", "airport_name":"Karup Airport", "city_name":"Karup"},
 {"airport_iata_code":"BYR", "airport_name":"L\u00e6s\u00f8 Airport", "city_name":"L\u00e6s\u00f8"},
 {"airport_iata_code":"MRW", "airport_name":"Lolland Falster Maribo Airport", "city_name":"Lolland Falster / Maribo"},
 {"airport_iata_code":"ODE", "airport_name":"Odense Airport", "city_name":"Odense"},
 {"airport_iata_code":"RKE", "airport_name":"Copenhagen Roskilde Airport", "city_name":"Copenhagen"},
 {"airport_iata_code":"RNN", "airport_name":"Bornholm Airport", "city_name":"R\u00f8nne"},
 {"airport_iata_code":"SGD", "airport_name":"S\u00f8nderborg Airport", "city_name":"S\u00f8nderborg"},
 {"airport_iata_code":"CNL", "airport_name":"Sindal Airport", "city_name":"Sindal"},
 {"airport_iata_code":"SKS", "airport_name":"Vojens Skrydstrup Airport", "city_name":"Vojens"},
 {"airport_iata_code":"SQW", "airport_name":"Skive Airport", "city_name":"Skive"},
 {"airport_iata_code":"TED", "airport_name":"Thisted Airport", "city_name":"Thisted"},
 {"airport_iata_code":"STA", "airport_name":"Stauning Airport", "city_name":"Skjern / Ringk\u00f8bing"},
 {"airport_iata_code":"AAL", "airport_name":"Aalborg Airport", "city_name":"Aalborg"},
 {"airport_iata_code":"DCF", "airport_name":"Canefield Airport", "city_name":"Canefield"},
 {"airport_iata_code":"DOM", "airport_name":"Melville Hall Airport", "city_name":"Marigot"},
 {"airport_iata_code":"BRX", "airport_name":"Maria Montez International Airport", "city_name":"Barahona"},
 {"airport_iata_code":"CBJ", "airport_name":"Cabo Rojo Airport", "city_name":"Cabo Rojo"},
 {"airport_iata_code":"AZS", "airport_name":"Saman\u00e1 El Catey International Airport", "city_name":"Samana"},
 {"airport_iata_code":"COZ", "airport_name":"Constanza Dom Re Airport", "city_name":"Costanza"},
 {"airport_iata_code":"JBQ", "airport_name":"La Isabela International Airport", "city_name":"La Isabela"},
 {"airport_iata_code":"LRM", "airport_name":"Casa De Campo International Airport", "city_name":"La Romana"},
 {"airport_iata_code":"PUJ", "airport_name":"Punta Cana International Airport", "city_name":"Punta Cana"},
 {"airport_iata_code":"EPS", "airport_name":"Samana El Portillo Airport", "city_name":"Samana"},
 {"airport_iata_code":"POP", "airport_name":"Gregorio Luperon International Airport", "city_name":"Puerto Plata"},
 {"airport_iata_code":"SNX", "airport_name":"Sabana de Mar Airport", "city_name":"Sabana de Mar"},
 {"airport_iata_code":"SDQ", "airport_name":"Las Am\u00e9ricas International Airport", "city_name":"Santo Domingo"},
 {"airport_iata_code":"STI", "airport_name":"Cibao International Airport", "city_name":"Santiago"},
 {"airport_iata_code":"QLD", "airport_name":"Blida Airport", "city_name":""},
 {"airport_iata_code":"BUJ", "airport_name":"Bou Saada Airport", "city_name":""},
 {"airport_iata_code":"BJA", "airport_name":"Soummam Airport", "city_name":"B\u00e9ja\u00efa"},
 {"airport_iata_code":"ALG", "airport_name":"Houari Boumediene Airport", "city_name":"Algiers"},
 {"airport_iata_code":"DJG", "airport_name":"Djanet Inedbirene Airport", "city_name":"Djanet"},
 {"airport_iata_code":"QFD", "airport_name":"Boufarik Airport", "city_name":""},
 {"airport_iata_code":"VVZ", "airport_name":"Illizi Takhamalt Airport", "city_name":"Illizi"},
 {"airport_iata_code":"QSF", "airport_name":"Ain Arnat Airport", "city_name":"S\u00e9tif"},
 {"airport_iata_code":"TMR", "airport_name":"Aguenar \u2013 Hadj Bey Akhamok Airport", "city_name":"Tamanrasset"},
 {"airport_iata_code":"GJL", "airport_name":"Jijel Ferhat Abbas Airport", "city_name":"Jijel"},
 {"airport_iata_code":"MZW", "airport_name":"Mecheria Airport", "city_name":"Mecheria"},
 {"airport_iata_code":"QZN", "airport_name":"Relizane Airport", "city_name":""},
 {"airport_iata_code":"AAE", "airport_name":"Annaba Airport", "city_name":"Annabah"},
 {"airport_iata_code":"CZL", "airport_name":"Mohamed Boudiaf International Airport", "city_name":"Constantine"},
 {"airport_iata_code":"TEE", "airport_name":"Cheikh Larbi T\u00e9bessi Airport", "city_name":"T\u00e9bessi"},
 {"airport_iata_code":"BLJ", "airport_name":"Batna Airport", "city_name":"Batna"},
 {"airport_iata_code":"HRM", "airport_name":"Hassi R'Mel Airport", "city_name":""},
 {"airport_iata_code":"QDJ", "airport_name":"Tsletsi Airport", "city_name":""},
 {"airport_iata_code":"TID", "airport_name":"Bou Chekif Airport", "city_name":"Tiaret"},
 {"airport_iata_code":"TIN", "airport_name":"Tindouf Airport", "city_name":"Tindouf"},
 {"airport_iata_code":"QAS", "airport_name":"Ech Cheliff Airport", "city_name":""},
 {"airport_iata_code":"TAF", "airport_name":"Tafaraoui Airport", "city_name":""},
 {"airport_iata_code":"TLM", "airport_name":"Zenata \u2013 Messali El Hadj Airport", "city_name":"Tlemcen"},
 {"airport_iata_code":"ORN", "airport_name":"Es Senia Airport", "city_name":"Oran"},
 {"airport_iata_code":"CBH", "airport_name":"B\u00e9char Boudghene Ben Ali Lotfi Airport", "city_name":"B\u00e9char"},
 {"airport_iata_code":"BFW", "airport_name":"Sidi Bel Abbes Airport", "city_name":""},
 {"airport_iata_code":"MUW", "airport_name":"Ghriss Airport", "city_name":""},
 {"airport_iata_code":"EBH", "airport_name":"El Bayadh Airport", "city_name":"El Bayadh"},
 {"airport_iata_code":"INF", "airport_name":"In Guezzam Airport", "city_name":"In Guezzam"},
 {"airport_iata_code":"BMW", "airport_name":"Bordj Badji Mokhtar Airport", "city_name":"Bordj Badji Mokhtar"},
 {"airport_iata_code":"AZR", "airport_name":"Touat Cheikh Sidi Mohamed Belkebir Airport", "city_name":""},
 {"airport_iata_code":"BSK", "airport_name":"Biskra Airport", "city_name":"Biskra"},
 {"airport_iata_code":"ELG", "airport_name":"El Golea Airport", "city_name":""},
 {"airport_iata_code":"GHA", "airport_name":"Noum\u00e9rat - Moufdi Zakaria Airport", "city_name":"Gharda\u00efa"},
 {"airport_iata_code":"HME", "airport_name":"Oued Irara Airport", "city_name":"Hassi Messaoud"},
 {"airport_iata_code":"INZ", "airport_name":"In Salah Airport", "city_name":"In Salah"},
 {"airport_iata_code":"TGR", "airport_name":"Touggourt Sidi Madhi Airport", "city_name":"Touggourt"},
 {"airport_iata_code":"LOO", "airport_name":"Laghouat Airport", "city_name":"Laghouat"},
 {"airport_iata_code":"ELU", "airport_name":"Guemar Airport", "city_name":"Guemar"},
 {"airport_iata_code":"TMX", "airport_name":"Timimoun Airport", "city_name":"Timimoun"},
 {"airport_iata_code":"OGX", "airport_name":"Ain el Beida Airport", "city_name":"Ouargla"},
 {"airport_iata_code":"IAM", "airport_name":"In Am\u00e9nas Airport", "city_name":"Am\u00e9nas"},
 {"airport_iata_code":"MZD", "airport_name":"M\u00e9ndez Airport", "city_name":"Santiago de M\u00e9ndez"},
 {"airport_iata_code":"ATF", "airport_name":"Chacho\u00e1n Airport", "city_name":"Ambato"},
 {"airport_iata_code":"OCC", "airport_name":"Francisco De Orellana Airport", "city_name":"Coca"},
 {"airport_iata_code":"CUE", "airport_name":"Mariscal Lamar Airport", "city_name":"Cuenca"},
 {"airport_iata_code":"GPS", "airport_name":"Seymour Airport", "city_name":"Baltra"},
 {"airport_iata_code":"GYE", "airport_name":"Simon Bolivar International Airport", "city_name":"Guayaquil"},
 {"airport_iata_code":"IBB", "airport_name":"General Villamil Airport", "city_name":"Isabela"},
 {"airport_iata_code":"JIP", "airport_name":"Jipijapa Airport", "city_name":"Jipijapa"},
 {"airport_iata_code":"LTX", "airport_name":"Cotopaxi International Airport", "city_name":"Latacunga"},
 {"airport_iata_code":"MRR", "airport_name":"Jose Maria Velasco Ibarra Airport", "city_name":"Macar\u00e1"},
 {"airport_iata_code":"XMS", "airport_name":"Coronel E Carvajal Airport", "city_name":"Macas"},
 {"airport_iata_code":"MCH", "airport_name":"General Manuel Serrano Airport", "city_name":"Machala"},
 {"airport_iata_code":"MEC", "airport_name":"Eloy Alfaro International Airport", "city_name":"Manta"},
 {"airport_iata_code":"LGQ", "airport_name":"Nueva Loja Airport", "city_name":"Lago Agrio"},
 {"airport_iata_code":"PYO", "airport_name":"Putumayo Airport", "city_name":"Puerto Putumayo"},
 {"airport_iata_code":"PVO", "airport_name":"Reales Tamarindos Airport", "city_name":"Portoviejo"},
 {"airport_iata_code":"UIO", "airport_name":"Nuevo Aeropuerto Internacional Mariscal Sucre", "city_name":"Quito"},
 {"airport_iata_code":"ETR", "airport_name":"Coronel Artilleria Victor Larrea Airport", "city_name":"Santa Rosa"},
 {"airport_iata_code":"SNC", "airport_name":"General Ulpiano Paez Airport", "city_name":"Salinas"},
 {"airport_iata_code":"SUQ", "airport_name":"Sucua Airport", "city_name":"Sucua"},
 {"airport_iata_code":"PTZ", "airport_name":"Rio Amazonas Airport", "city_name":"Shell Mera"},
 {"airport_iata_code":"SCY", "airport_name":"San Crist\u00f3bal Airport", "city_name":"San Crist\u00f3bal"},
 {"airport_iata_code":"BHA", "airport_name":"Los Perales Airport", "city_name":"Bah\u00eda de Caraquez"},
 {"airport_iata_code":"TSC", "airport_name":"Taisha Airport", "city_name":"Taisha"},
 {"airport_iata_code":"TPN", "airport_name":"Tiputini Airport", "city_name":"Tiputini"},
 {"airport_iata_code":"LOH", "airport_name":"Camilo Ponce Enriquez Airport", "city_name":"La Toma (Catamayo)"},
 {"airport_iata_code":"ESM", "airport_name":"General Rivadeneira Airport", "city_name":"Tachina"},
 {"airport_iata_code":"TPC", "airport_name":"Tarapoa Airport", "city_name":"Tarapoa"},
 {"airport_iata_code":"TUA", "airport_name":"Teniente Coronel Luis a Mantilla Airport", "city_name":"Tulc\u00e1n"},
 {"airport_iata_code":"KDL", "airport_name":"K\u00e4rdla Airport", "city_name":"K\u00e4rdla"},
 {"airport_iata_code":"URE", "airport_name":"Kuressaare Airport", "city_name":"Kuressaare"},
 {"airport_iata_code":"EPU", "airport_name":"P\u00e4rnu Airport", "city_name":"P\u00e4rnu"},
 {"airport_iata_code":"TLL", "airport_name":"Tallinn Airport", "city_name":"Tallinn"},
 {"airport_iata_code":"TAY", "airport_name":"Tartu Airport", "city_name":"Tartu"},
 {"airport_iata_code":"AUE", "airport_name":"Abu Rudeis Airport", "city_name":"Abu Rudeis"},
 {"airport_iata_code":"SEW", "airport_name":"Siwa Oasis North Airport", "city_name":"Siwa"},
 {"airport_iata_code":"DBB", "airport_name":"El Alamein International Airport", "city_name":"El Alamein"},
 {"airport_iata_code":"AAC", "airport_name":"El Arish International Airport", "city_name":"El Arish"},
 {"airport_iata_code":"ATZ", "airport_name":"Assiut International Airport", "city_name":"Assiut"},
 {"airport_iata_code":"ALY", "airport_name":"El Nouzha Airport", "city_name":"Alexandria"},
 {"airport_iata_code":"HBE", "airport_name":"Borg El Arab International Airport", "city_name":"Alexandria"},
 {"airport_iata_code":"ABS", "airport_name":"Abu Simbel Airport", "city_name":"Abu Simbel"},
 {"airport_iata_code":"CAI", "airport_name":"Cairo International Airport", "city_name":"Cairo"},
 {"airport_iata_code":"CWE", "airport_name":"Cairo West Airport", "city_name":"El Cairo"},
 {"airport_iata_code":"DAK", "airport_name":"Dakhla Airport", "city_name":""},
 {"airport_iata_code":"HRG", "airport_name":"Hurghada International Airport", "city_name":"Hurghada"},
 {"airport_iata_code":"EGH", "airport_name":"El Gora Airport", "city_name":""},
 {"airport_iata_code":"UVL", "airport_name":"El Kharga Airport", "city_name":""},
 {"airport_iata_code":"LXR", "airport_name":"Luxor International Airport", "city_name":"Luxor"},
 {"airport_iata_code":"RMF", "airport_name":"Marsa Alam International Airport", "city_name":"Marsa Alam"},
 {"airport_iata_code":"HMB", "airport_name":"Sohag International Airport", "city_name":"Sohag"},
 {"airport_iata_code":"MUH", "airport_name":"Mersa Matruh Airport", "city_name":"Mersa Matruh"},
 {"airport_iata_code":"GSQ", "airport_name":"Shark El Oweinat International Airport", "city_name":""},
 {"airport_iata_code":"PSD", "airport_name":"Port Said Airport", "city_name":"Port Said"},
 {"airport_iata_code":"SKV", "airport_name":"St Catherine International Airport", "city_name":""},
 {"airport_iata_code":"SSH", "airport_name":"Sharm El Sheikh International Airport", "city_name":"Sharm el-Sheikh"},
 {"airport_iata_code":"ASW", "airport_name":"Aswan International Airport", "city_name":"Aswan"},
 {"airport_iata_code":"TCP", "airport_name":"Taba International Airport", "city_name":"Taba"},
 {"airport_iata_code":"ELT", "airport_name":"El Tor Airport", "city_name":""},
 {"airport_iata_code":"SMW", "airport_name":"Smara Airport", "city_name":"Smara"},
 {"airport_iata_code":"VIL", "airport_name":"Dakhla Airport", "city_name":"Dakhla"},
 {"airport_iata_code":"EUN", "airport_name":"Hassan I Airport", "city_name":"El Aai\u00fan"},
 {"airport_iata_code":"ASM", "airport_name":"Asmara International Airport", "city_name":"Asmara"},
 {"airport_iata_code":"MSW", "airport_name":"Massawa International Airport", "city_name":"Massawa"},
 {"airport_iata_code":"ASA", "airport_name":"Assab International Airport", "city_name":"Asab"},
 {"airport_iata_code":"TES", "airport_name":"Tessenei Airport", "city_name":"Tessenei"},
 {"airport_iata_code":"ABC", "airport_name":"Albacete-Los Llanos Airport", "city_name":"Albacete"},
 {"airport_iata_code":"ALC", "airport_name":"Alicante International Airport", "city_name":"Alicante"},
 {"airport_iata_code":"LEI", "airport_name":"Almer\u00eda International Airport", "city_name":"Almer\u00eda"},
 {"airport_iata_code":"OVD", "airport_name":"Asturias Airport", "city_name":"Ran\u00f3n"},
 {"airport_iata_code":"ODB", "airport_name":"C\u00f3rdoba Airport", "city_name":"C\u00f3rdoba"},
 {"airport_iata_code":"BIO", "airport_name":"Bilbao Airport", "city_name":"Bilbao"},
 {"airport_iata_code":"RGS", "airport_name":"Burgos Airport", "city_name":"Burgos"},
 {"airport_iata_code":"BCN", "airport_name":"Barcelona International Airport", "city_name":"Barcelona"},
 {"airport_iata_code":"BJZ", "airport_name":"Badajoz Airport", "city_name":"Badajoz"},
 {"airport_iata_code":"CDT", "airport_name":"Castellon De La Plana Airport", "city_name":"Castell\u00f3n de La Plana"},
 {"airport_iata_code":"LCG", "airport_name":"A Coru\u00f1a Airport", "city_name":"Culleredo"},
 {"airport_iata_code":"ECV", "airport_name":"Cuatro Vientos Airport", "city_name":"Madrid"},
 {"airport_iata_code":"ILD", "airport_name":"Lleida-Alguaire Airport", "city_name":"Lleida"},
 {"airport_iata_code":"GRO", "airport_name":"Girona Airport", "city_name":"Girona"},
 {"airport_iata_code":"GRX", "airport_name":"Federico Garcia Lorca Airport", "city_name":"Granada"},
 {"airport_iata_code":"IBZ", "airport_name":"Ibiza Airport", "city_name":"Ibiza"},
 {"airport_iata_code":"XRY", "airport_name":"Jerez Airport", "city_name":"Jerez de la Forntera"},
 {"airport_iata_code":"MJV", "airport_name":"San Javier Airport", "city_name":"San Javier"},
 {"airport_iata_code":"QSA", "airport_name":"Sabadell Airport", "city_name":"Sabadell"},
 {"airport_iata_code":"LEN", "airport_name":"Leon Airport", "city_name":"Le\u00f3n"},
 {"airport_iata_code":"RJL", "airport_name":"Logro\u00f1o-Agoncillo Airport", "city_name":"Logro\u00f1o"},
 {"airport_iata_code":"MAD", "airport_name":"Madrid Barajas International Airport", "city_name":"Madrid"},
 {"airport_iata_code":"HEV", "airport_name":"Maf\u00e9 - Gibrale\u00f3n Airport", "city_name":"Gibrale\u00f3n"},
 {"airport_iata_code":"AGP", "airport_name":"M\u00e1laga Airport", "city_name":"M\u00e1laga"},
 {"airport_iata_code":"MAH", "airport_name":"Menorca Airport", "city_name":"Menorca Island"},
 {"airport_iata_code":"OZP", "airport_name":"Moron Air Base", "city_name":"Mor\u00f3n"},
 {"airport_iata_code":"PMI", "airport_name":"Palma De Mallorca Airport", "city_name":"Palma De Mallorca"},
 {"airport_iata_code":"PNA", "airport_name":"Pamplona Airport", "city_name":"Pamplona"},
 {"airport_iata_code":"REU", "airport_name":"Reus Air Base", "city_name":"Reus"},
 {"airport_iata_code":"ROZ", "airport_name":"Rota Naval Station Airport", "city_name":"Rota"},
 {"airport_iata_code":"SLM", "airport_name":"Salamanca Airport", "city_name":"Salamanca"},
 {"airport_iata_code":"EAS", "airport_name":"San Sebastian Airport", "city_name":"Hondarribia"},
 {"airport_iata_code":"SCQ", "airport_name":"Santiago de Compostela Airport", "city_name":"Santiago de Compostela"},
 {"airport_iata_code":"LEU", "airport_name":"Aer\u00f2drom dels Pirineus-Alt Urgell Airport", "city_name":"Montferrer / Castellb\u00f2"},
 {"airport_iata_code":"TOJ", "airport_name":"Torrej\u00f3n Airport", "city_name":"Madrid"},
 {"airport_iata_code":"VLC", "airport_name":"Valencia Airport", "city_name":"Valencia"},
 {"airport_iata_code":"VLL", "airport_name":"Valladolid Airport", "city_name":"Valladolid"},
 {"airport_iata_code":"VIT", "airport_name":"Vitoria/Foronda Airport", "city_name":"Alava"},
 {"airport_iata_code":"VGO", "airport_name":"Vigo Airport", "city_name":"Vigo"},
 {"airport_iata_code":"SDR", "airport_name":"Santander Airport", "city_name":"Santander"},
 {"airport_iata_code":"ZAZ", "airport_name":"Zaragoza Air Base", "city_name":"Zaragoza"},
 {"airport_iata_code":"SVQ", "airport_name":"Sevilla Airport", "city_name":"Sevilla"},
 {"airport_iata_code":"RMU", "airport_name":"Corvera/Murcia International Airport", "city_name":"Corvera"},
 {"airport_iata_code":"FUE", "airport_name":"Fuerteventura Airport", "city_name":"Fuerteventura Island"},
 {"airport_iata_code":"GMZ", "airport_name":"La Gomera Airport", "city_name":"Alajero, La Gomera Island"},
 {"airport_iata_code":"VDE", "airport_name":"Hierro Airport", "city_name":"El Hierro Island"},
 {"airport_iata_code":"SPC", "airport_name":"La Palma Airport", "city_name":"Sta Cruz de la Palma, La Palma"},
 {"airport_iata_code":"LPA", "airport_name":"Gran Canaria Airport", "city_name":"Gran Canaria Island"},
 {"airport_iata_code":"ACE", "airport_name":"Lanzarote Airport", "city_name":"Lanzarote Island"},
 {"airport_iata_code":"TFS", "airport_name":"Tenerife South Airport", "city_name":"Tenerife Island"},
 {"airport_iata_code":"TFN", "airport_name":"Tenerife Norte Airport", "city_name":"Tenerife Island"},
 {"airport_iata_code":"MLN", "airport_name":"Melilla Airport", "city_name":"Melilla Island"},
 {"airport_iata_code":"GLC", "airport_name":"Geladi Airport", "city_name":"Geladi"},
 {"airport_iata_code":"SHC", "airport_name":"Shire Inda Selassie Airport", "city_name":"Shire Indasilase"},
 {"airport_iata_code":"ADD", "airport_name":"Bole International Airport", "city_name":"Addis Ababa"},
 {"airport_iata_code":"AMH", "airport_name":"Arba Minch Airport", "city_name":""},
 {"airport_iata_code":"AXU", "airport_name":"Axum Airport", "city_name":""},
 {"airport_iata_code":"BCO", "airport_name":"Baco Airport", "city_name":"Baco"},
 {"airport_iata_code":"BJR", "airport_name":"Bahir Dar Airport", "city_name":"Bahir Dar"},
 {"airport_iata_code":"BEI", "airport_name":"Beica Airport", "city_name":"Beica"},
 {"airport_iata_code":"DSE", "airport_name":"Combolcha Airport", "city_name":"Dessie"},
 {"airport_iata_code":"DEM", "airport_name":"Dembidollo Airport", "city_name":"Dembidollo"},
 {"airport_iata_code":"DBM", "airport_name":"Debra Marcos Airport", "city_name":"Debra Marcos"},
 {"airport_iata_code":"DIR", "airport_name":"Aba Tenna Dejazmach Yilma International Airport", "city_name":"Dire Dawa"},
 {"airport_iata_code":"DBT", "airport_name":"Debre Tabor Airport", "city_name":"Debre Tabor"},
 {"airport_iata_code":"FNH", "airport_name":"Fincha Airport", "city_name":"Fincha"},
 {"airport_iata_code":"GOB", "airport_name":"Robe Airport", "city_name":"Goba"},
 {"airport_iata_code":"GNN", "airport_name":"Ghinnir Airport", "city_name":"Ghinnir"},
 {"airport_iata_code":"GMB", "airport_name":"Gambella Airport", "city_name":"Gambela"},
 {"airport_iata_code":"GDQ", "airport_name":"Gonder Airport", "city_name":"Gondar"},
 {"airport_iata_code":"GDE", "airport_name":"Gode Airport", "city_name":"Gode"},
 {"airport_iata_code":"GOR", "airport_name":"Gore Airport", "city_name":"Gore"},
 {"airport_iata_code":"QHR", "airport_name":"Harar Meda Airport", "city_name":"Debre Zeyit"},
 {"airport_iata_code":"HUE", "airport_name":"Humera Airport", "city_name":"Humera"},
 {"airport_iata_code":"JIM", "airport_name":"Jimma Airport", "city_name":"Jimma"},
 {"airport_iata_code":"ABK", "airport_name":"Kabri Dehar Airport", "city_name":"Kabri Dehar"},
 {"airport_iata_code":"LFO", "airport_name":"Kelafo East Airport", "city_name":"Kelafo"},
 {"airport_iata_code":"AWA", "airport_name":"Awassa Airport", "city_name":"Awassa"},
 {"airport_iata_code":"LLI", "airport_name":"Lalibella Airport", "city_name":"Lalibela"},
 {"airport_iata_code":"MQX", "airport_name":"Mekele Airport", "city_name":""},
 {"airport_iata_code":"NDM", "airport_name":"Mendi Airport", "city_name":"Mendi"},
 {"airport_iata_code":"MTF", "airport_name":"Mizan Teferi Airport", "city_name":"Mizan Teferi"},
 {"airport_iata_code":"NEJ", "airport_name":"Nejjo Airport", "city_name":"Nejjo"},
 {"airport_iata_code":"NEK", "airport_name":"Nekemte Airport", "city_name":"Nekemte"},
 {"airport_iata_code":"SXU", "airport_name":"Soddu Airport", "city_name":"Soddu"},
 {"airport_iata_code":"ASO", "airport_name":"Asosa Airport", "city_name":"Asosa"},
 {"airport_iata_code":"TIE", "airport_name":"Tippi Airport", "city_name":"Tippi"},
 {"airport_iata_code":"WAC", "airport_name":"Waca Airport", "city_name":"Waca"},
 {"airport_iata_code":"HIL", "airport_name":"Shilavo Airport", "city_name":"Shilavo"},
 {"airport_iata_code":"ENF", "airport_name":"Enontekio Airport", "city_name":"Enontekio"},
 {"airport_iata_code":"QVE", "airport_name":"Forssa Airport", "city_name":"Forssa"},
 {"airport_iata_code":"KEV", "airport_name":"Halli Airport", "city_name":"Halli / Kuorevesi"},
 {"airport_iata_code":"HEM", "airport_name":"Helsinki Malmi Airport", "city_name":"Helsinki"},
 {"airport_iata_code":"HEL", "airport_name":"Helsinki Vantaa Airport", "city_name":"Helsinki"},
 {"airport_iata_code":"HYV", "airport_name":"Hyvinkaa Airport", "city_name":""},
 {"airport_iata_code":"KTQ", "airport_name":"Kitee Airport", "city_name":""},
 {"airport_iata_code":"IVL", "airport_name":"Ivalo Airport", "city_name":"Ivalo"},
 {"airport_iata_code":"JOE", "airport_name":"Joensuu Airport", "city_name":"Joensuu / Liperi"},
 {"airport_iata_code":"JYV", "airport_name":"Jyvaskyla Airport", "city_name":"Jyv\u00e4skyl\u00e4n Maalaiskunta"},
 {"airport_iata_code":"KAU", "airport_name":"Kauhava Airport", "city_name":"Kauhava"},
 {"airport_iata_code":"KEM", "airport_name":"Kemi-Tornio Airport", "city_name":"Kemi / Tornio"},
 {"airport_iata_code":"KAJ", "airport_name":"Kajaani Airport", "city_name":"Kajaani"},
 {"airport_iata_code":"KHJ", "airport_name":"Kauhajoki Airport", "city_name":""},
 {"airport_iata_code":"KOK", "airport_name":"Kruunupyy Airport", "city_name":"Kokkola / Kruunupyy"},
 {"airport_iata_code":"KAO", "airport_name":"Kuusamo Airport", "city_name":"Kuusamo"},
 {"airport_iata_code":"KTT", "airport_name":"Kittila Airport", "city_name":"Kittila"},
 {"airport_iata_code":"KUO", "airport_name":"Kuopio Airport", "city_name":"Kuopio / Siilinj\u00e4rvi"},
 {"airport_iata_code":"QLF", "airport_name":"Lahti Vesivehmaa Airport", "city_name":""},
 {"airport_iata_code":"LPP", "airport_name":"Lappeenranta Airport", "city_name":"Lappeenranta"},
 {"airport_iata_code":"MHQ", "airport_name":"Mariehamn Airport", "city_name":""},
 {"airport_iata_code":"MIK", "airport_name":"Mikkeli Airport", "city_name":"Mikkeli"},
 {"airport_iata_code":"OUL", "airport_name":"Oulu Airport", "city_name":"Oulu / Oulunsalo"},
 {"airport_iata_code":"POR", "airport_name":"Pori Airport", "city_name":"Pori"},
 {"airport_iata_code":"RVN", "airport_name":"Rovaniemi Airport", "city_name":"Rovaniemi"},
 {"airport_iata_code":"SVL", "airport_name":"Savonlinna Airport", "city_name":"Savonlinna"},
 {"airport_iata_code":"SJY", "airport_name":"Sein\u00e4joki Airport", "city_name":"Sein\u00e4joki / Ilmajoki"},
 {"airport_iata_code":"SOT", "airport_name":"Sodankyla Airport", "city_name":"Sodankyla"},
 {"airport_iata_code":"TMP", "airport_name":"Tampere-Pirkkala Airport", "city_name":"Tampere / Pirkkala"},
 {"airport_iata_code":"TKU", "airport_name":"Turku Airport", "city_name":"Turku"},
 {"airport_iata_code":"UTI", "airport_name":"Utti Air Base", "city_name":"Utti / Valkeala"},
 {"airport_iata_code":"VAA", "airport_name":"Vaasa Airport", "city_name":"Vaasa"},
 {"airport_iata_code":"VRK", "airport_name":"Varkaus Airport", "city_name":"Varkaus / Joroinen"},
 {"airport_iata_code":"YLI", "airport_name":"Ylivieska Airport", "city_name":""},
 {"airport_iata_code":"ICI", "airport_name":"Cicia Airport", "city_name":"Cicia"},
 {"airport_iata_code":"BFJ", "airport_name":"Ba Airport", "city_name":""},
 {"airport_iata_code":"NAN", "airport_name":"Nadi International Airport", "city_name":"Nadi"},
 {"airport_iata_code":"PTF", "airport_name":"Malolo Lailai Island Airport", "city_name":"Malolo Lailai Island"},
 {"airport_iata_code":"KDV", "airport_name":"Vunisea Airport", "city_name":"Vunisea"},
 {"airport_iata_code":"MNF", "airport_name":"Mana Island Airport", "city_name":"Mana Island"},
 {"airport_iata_code":"MFJ", "airport_name":"Moala Airport", "city_name":"Moala"},
 {"airport_iata_code":"SUV", "airport_name":"Nausori International Airport", "city_name":"Nausori"},
 {"airport_iata_code":"LEV", "airport_name":"Levuka Airfield", "city_name":"Bureta"},
 {"airport_iata_code":"NGI", "airport_name":"Ngau Airport", "city_name":"Ngau"},
 {"airport_iata_code":"LUC", "airport_name":"Laucala Island Airport", "city_name":"Laucala Island"},
 {"airport_iata_code":"LKB", "airport_name":"Lakeba Island Airport", "city_name":"Lakeba Island"},
 {"airport_iata_code":"LBS", "airport_name":"Labasa Airport", "city_name":""},
 {"airport_iata_code":"TVU", "airport_name":"Matei Airport", "city_name":"Matei"},
 {"airport_iata_code":"KXF", "airport_name":"Koro Island Airport", "city_name":"Koro Island"},
 {"airport_iata_code":"RTA", "airport_name":"Rotuma Airport", "city_name":"Rotuma"},
 {"airport_iata_code":"SVU", "airport_name":"Savusavu Airport", "city_name":"Savusavu"},
 {"airport_iata_code":"VAU", "airport_name":"Vatukoula Airport", "city_name":"Vatukoula"},
 {"airport_iata_code":"KAY", "airport_name":"Wakaya Island Airport", "city_name":"Wakaya Island"},
 {"airport_iata_code":"ONU", "airport_name":"Ono-I-Lau Airport", "city_name":"Ono-I-Lau"},
 {"airport_iata_code":"YAS", "airport_name":"Yasawa Island Airport", "city_name":"Yasawa Island"},
 {"airport_iata_code":"VBV", "airport_name":"Vanua Balavu Airport", "city_name":"Vanua Balavu"},
 {"airport_iata_code":"VTF", "airport_name":"Vatulele Airport", "city_name":"Vatulele"},
 {"airport_iata_code":"PSY", "airport_name":"Stanley Airport", "city_name":"Stanley"},
 {"airport_iata_code":"MPN", "airport_name":"Mount Pleasant Airport", "city_name":"Mount Pleasant"},
 {"airport_iata_code":"ULI", "airport_name":"Falalop Island Airport", "city_name":"Falalop Island"},
 {"airport_iata_code":"TKK", "airport_name":"Chuuk International Airport", "city_name":"Weno Island"},
 {"airport_iata_code":"PNI", "airport_name":"Pohnpei International Airport", "city_name":"Pohnpei Island"},
 {"airport_iata_code":"KSA", "airport_name":"Kosrae International Airport", "city_name":"Okat"},
 {"airport_iata_code":"YAP", "airport_name":"Yap International Airport", "city_name":"Yap Island"},
 {"airport_iata_code":"FAE", "airport_name":"Vagar Airport", "city_name":"Vagar"},
 {"airport_iata_code":"DPE", "airport_name":"St Aubin Airport", "city_name":"Dieppe"},
 {"airport_iata_code":"CQF", "airport_name":"Calais-Dunkerque Airport", "city_name":"Calais/Dunkerque"},
 {"airport_iata_code":"XCP", "airport_name":"Compi\u00e8gne Margny Airport", "city_name":"Calais-Dunkerque"},
 {"airport_iata_code":"XLN", "airport_name":"Laon - Chambry Airport", "city_name":"Calais-Dunkerque"},
 {"airport_iata_code":"XSJ", "airport_name":"P\u00e9ronne-Saint-Quentin Airport", "city_name":"P\u00e9ronne/Saint-Quentin"},
 {"airport_iata_code":"XDK", "airport_name":"Dunkerque les Moeres Airport", "city_name":"Calais-Dunkerque"},
 {"airport_iata_code":"BYF", "airport_name":"Albert-Bray Airport", "city_name":"Albert/Bray"},
 {"airport_iata_code":"LTQ", "airport_name":"Le Touquet-C\u00f4te d'Opale Airport", "city_name":"Le Touquet-Paris-Plage"},
 {"airport_iata_code":"XVS", "airport_name":"Valenciennes-Denain Airport", "city_name":"Valenciennes/Denain"},
 {"airport_iata_code":"QAM", "airport_name":"Amiens-Glisy Airport", "city_name":"Amiens/Glisy"},
 {"airport_iata_code":"AGF", "airport_name":"Agen-La Garenne Airport", "city_name":"Agen/La Garenne"},
 {"airport_iata_code":"BOD", "airport_name":"Bordeaux-M\u00e9rignac (BA 106) Airport", "city_name":"Bordeaux/M\u00e9rignac"},
 {"airport_iata_code":"EGC", "airport_name":"Bergerac-Roumani\u00e8re Airport", "city_name":"Bergerac/Roumani\u00e8re"},
 {"airport_iata_code":"CNG", "airport_name":"Cognac-Ch\u00e2teaubernard (BA 709) Air Base", "city_name":"Cognac/Ch\u00e2teaubernard"},
 {"airport_iata_code":"LRH", "airport_name":"La Rochelle-\u00cele de R\u00e9 Airport", "city_name":"La Rochelle/\u00cele de R\u00e9"},
 {"airport_iata_code":"PIS", "airport_name":"Poitiers-Biard Airport", "city_name":"Poitiers/Biard"},
 {"airport_iata_code":"MCU", "airport_name":"Montlu\u00e7on-Gu\u00e9ret Airport", "city_name":"Montlu\u00e7on/Gu\u00e9ret"},
 {"airport_iata_code":"LIG", "airport_name":"Limoges Airport", "city_name":"Limoges/Bellegarde"},
 {"airport_iata_code":"XMJ", "airport_name":"Mont-de-Marsan (BA 118) Air Base", "city_name":"Mont-de-Marsan"},
 {"airport_iata_code":"NIT", "airport_name":"Niort-Souch\u00e9 Airport", "city_name":"Niort/Souch\u00e9"},
 {"airport_iata_code":"TLS", "airport_name":"Toulouse-Blagnac Airport", "city_name":"Toulouse/Blagnac"},
 {"airport_iata_code":"PUF", "airport_name":"Pau Pyr\u00e9n\u00e9es Airport", "city_name":"Pau/Pyr\u00e9n\u00e9es (Uzein)"},
 {"airport_iata_code":"LDE", "airport_name":"Tarbes-Lourdes-Pyr\u00e9n\u00e9es Airport", "city_name":"Tarbes/Lourdes/Pyr\u00e9n\u00e9es"},
 {"airport_iata_code":"ANG", "airport_name":"Angoul\u00eame-Brie-Champniers Airport", "city_name":"Angoul\u00eame/Brie/Champniers"},
 {"airport_iata_code":"BVE", "airport_name":"Brive-La Roche Airport", "city_name":"Brive-la-Gaillarde"},
 {"airport_iata_code":"PGX", "airport_name":"P\u00e9rigueux-Bassillac Airport", "city_name":"P\u00e9rigueux/Bassillac"},
 {"airport_iata_code":"XDA", "airport_name":"Dax Seyresse Airport", "city_name":"Perigueux"},
 {"airport_iata_code":"BIQ", "airport_name":"Biarritz-Anglet-Bayonne Airport", "city_name":"Biarritz/Anglet/Bayonne"},
 {"airport_iata_code":"XCX", "airport_name":"Ch\u00e2tellerault Airport", "city_name":"Biarritz"},
 {"airport_iata_code":"ZAO", "airport_name":"Cahors-Lalbenque Airport", "city_name":"Cahors/Lalbenque"},
 {"airport_iata_code":"XGT", "airport_name":"Gu\u00e9ret St Laurent Airport", "city_name":"Cahors"},
 {"airport_iata_code":"XAC", "airport_name":"Arcachon-La Teste-de-Buch Airport", "city_name":"Arcachon/La Teste-de-Buch"},
 {"airport_iata_code":"LBI", "airport_name":"Albi-Le S\u00e9questre Airport", "city_name":"Albi/Le S\u00e9questre"},
 {"airport_iata_code":"DCM", "airport_name":"Castres-Mazamet Airport", "city_name":"Castres/Mazamet"},
 {"airport_iata_code":"RDZ", "airport_name":"Rodez-Marcillac Airport", "city_name":"Rodez/Marcillac"},
 {"airport_iata_code":"RYN", "airport_name":"Royan-M\u00e9dis Airport", "city_name":"Royan/M\u00e9dis"},
 {"airport_iata_code":"XMW", "airport_name":"Montauban Airport", "city_name":"Montauban"},
 {"airport_iata_code":"XLR", "airport_name":"Libourne-Artigues-de-Lussac Airport", "city_name":"Libourne/Artigues-de-Lussac"},
 {"airport_iata_code":"RCO", "airport_name":"Rochefort-Saint-Agnant (BA 721) Airport", "city_name":"Rochefort/Saint-Agnant"},
 {"airport_iata_code":"XSL", "airport_name":"Sarlat Domme Airport", "city_name":"Rochefort"},
 {"airport_iata_code":"XTB", "airport_name":"Tarbes Laloub\u00e8re Airport", "city_name":"Rochefort"},
 {"airport_iata_code":"IDY", "airport_name":"\u00cele d'Yeu Airport", "city_name":"\u00cele d'Yeu"},
 {"airport_iata_code":"CMR", "airport_name":"Colmar-Houssen Airport", "city_name":"Colmar/Houssen"},
 {"airport_iata_code":"XBV", "airport_name":"Beaune-Challanges Airport", "city_name":"Beaune/Challanges"},
 {"airport_iata_code":"DLE", "airport_name":"Dole-Tavaux Airport", "city_name":"Dole/Tavaux"},
 {"airport_iata_code":"XVN", "airport_name":"Verdun-Le Rozelier Airport", "city_name":"Verdun/Le Rozelier"},
 {"airport_iata_code":"MVV", "airport_name":"Meg\u00e8ve Airport", "city_name":"Verdun"},
 {"airport_iata_code":"OBS", "airport_name":"Aubenas-Ard\u00e8che M\u00e9ridional Airport", "city_name":"Aubenas/Ard\u00e8che M\u00e9ridional"},
 {"airport_iata_code":"LPY", "airport_name":"Le Puy-Loudes Airport", "city_name":"Le Puy/Loudes"},
 {"airport_iata_code":"AHZ", "airport_name":"L'alpe D'huez Airport", "city_name":"Bourg"},
 {"airport_iata_code":"ETZ", "airport_name":"Metz-Nancy-Lorraine Airport", "city_name":"Metz / Nancy"},
 {"airport_iata_code":"ANE", "airport_name":"Angers-Loire Airport", "city_name":"Angers/Marc\u00e9"},
 {"airport_iata_code":"XAV", "airport_name":"Albertville Airport", "city_name":"Angers"},
 {"airport_iata_code":"BIA", "airport_name":"Bastia-Poretta Airport", "city_name":"Bastia/Poretta"},
 {"airport_iata_code":"CLY", "airport_name":"Calvi-Sainte-Catherine Airport", "city_name":"Calvi/Sainte-Catherine"},
 {"airport_iata_code":"FSC", "airport_name":"Figari Sud-Corse Airport", "city_name":"Figari Sud-Corse"},
 {"airport_iata_code":"AJA", "airport_name":"Ajaccio-Napol\u00e9on Bonaparte Airport", "city_name":"Ajaccio/Napol\u00e9on Bonaparte"},
 {"airport_iata_code":"PRP", "airport_name":"Propriano Airport", "city_name":"Propriano"},
 {"airport_iata_code":"SOZ", "airport_name":"Solenzara (BA 126) Air Base", "city_name":"Solenzara"},
 {"airport_iata_code":"MFX", "airport_name":"M\u00e9ribel Airport", "city_name":"Ajaccio"},
 {"airport_iata_code":"AUF", "airport_name":"Auxerre-Branches Airport", "city_name":"Auxerre/Branches"},
 {"airport_iata_code":"CMF", "airport_name":"Chamb\u00e9ry-Savoie Airport", "city_name":"Chamb\u00e9ry/Aix-les-Bains"},
 {"airport_iata_code":"CFE", "airport_name":"Clermont-Ferrand Auvergne Airport", "city_name":"Clermont-Ferrand/Auvergne"},
 {"airport_iata_code":"BOU", "airport_name":"Bourges Airport", "city_name":"Bourges"},
 {"airport_iata_code":"QNJ", "airport_name":"Annemasse Airport", "city_name":"Annemasse"},
 {"airport_iata_code":"CVF", "airport_name":"Courchevel Airport", "city_name":"Courcheval"},
 {"airport_iata_code":"LYS", "airport_name":"Lyon Saint-Exup\u00e9ry Airport", "city_name":"Lyon"},
 {"airport_iata_code":"QNX", "airport_name":"M\u00e2con-Charnay Airport", "city_name":"M\u00e2con/Charnay"},
 {"airport_iata_code":"SYT", "airport_name":"Saint-Yan Airport", "city_name":"Saint-Yan"},
 {"airport_iata_code":"RNE", "airport_name":"Roanne-Renaison Airport", "city_name":"Roanne/Renaison"},
 {"airport_iata_code":"NCY", "airport_name":"Annecy-Haute-Savoie-Mont Blanc Airport", "city_name":"Annecy/Meythet"},
 {"airport_iata_code":"XMK", "airport_name":"Mont\u00e9limar - Anc\u00f4ne Airport", "city_name":"Annecy"},
 {"airport_iata_code":"GNB", "airport_name":"Grenoble-Is\u00e8re Airport", "city_name":"Grenoble/Saint-Geoirs"},
 {"airport_iata_code":"VAF", "airport_name":"Valence-Chabeuil Airport", "city_name":"Valence/Chabeuil"},
 {"airport_iata_code":"VHY", "airport_name":"Vichy-Charmeil Airport", "city_name":"Vichy/Charmeil"},
 {"airport_iata_code":"AUR", "airport_name":"Aurillac Airport", "city_name":"Aurillac"},
 {"airport_iata_code":"CHR", "airport_name":"Ch\u00e2teauroux-D\u00e9ols \"Marcel Dassault\" Airport", "city_name":"Ch\u00e2teauroux/D\u00e9ols"},
 {"airport_iata_code":"LYN", "airport_name":"Lyon-Bron Airport", "city_name":"Lyon/Bron"},
 {"airport_iata_code":"CEQ", "airport_name":"Cannes-Mandelieu Airport", "city_name":"Cannes/Mandelieu"},
 {"airport_iata_code":"EBU", "airport_name":"Saint-\u00c9tienne-Bouth\u00e9on Airport", "city_name":"Saint-\u00c9tienne/Bouth\u00e9on"},
 {"airport_iata_code":"QIE", "airport_name":"Istres Le Tub\u00e9/Istres Air Base (BA 125) Airport", "city_name":"Istres/Le Tub\u00e9"},
 {"airport_iata_code":"CCF", "airport_name":"Carcassonne Airport", "city_name":"Carcassonne/Salvaza"},
 {"airport_iata_code":"MRS", "airport_name":"Marseille Provence Airport", "city_name":"Marseille"},
 {"airport_iata_code":"NCE", "airport_name":"Nice-C\u00f4te d'Azur Airport", "city_name":"Nice"},
 {"airport_iata_code":"XOG", "airport_name":"Orange-Caritat (BA 115) Air Base", "city_name":"Orange/Caritat"},
 {"airport_iata_code":"PGF", "airport_name":"Perpignan-Rivesaltes (Llaban\u00e8re) Airport", "city_name":"Perpignan/Rivesaltes"},
 {"airport_iata_code":"CTT", "airport_name":"Le Castellet Airport", "city_name":"Le Castellet"},
 {"airport_iata_code":"BAE", "airport_name":"Barcelonnette - Saint-Pons Airport", "city_name":"Le Castellet"},
 {"airport_iata_code":"XAS", "airport_name":"Al\u00e8s-Deaux Airport", "city_name":"Al\u00e8s/Deaux"},
 {"airport_iata_code":"MPL", "airport_name":"Montpellier-M\u00e9diterran\u00e9e Airport", "city_name":"Montpellier/M\u00e9diterran\u00e9e"},
 {"airport_iata_code":"BZR", "airport_name":"B\u00e9ziers-Vias Airport", "city_name":"B\u00e9ziers/Vias"},
 {"airport_iata_code":"AVN", "airport_name":"Avignon-Caumont Airport", "city_name":"Avignon/Caumont"},
 {"airport_iata_code":"GAT", "airport_name":"Gap - Tallard Airport", "city_name":"Avignon"},
 {"airport_iata_code":"MEN", "airport_name":"Mende-Brenoux Airport", "city_name":"Mende/Br\u00e9noux"},
 {"airport_iata_code":"SCP", "airport_name":"Mont-Dauphin - St-Cr\u00e9pin Airport", "city_name":"Mende"},
 {"airport_iata_code":"BVA", "airport_name":"Paris Beauvais Till\u00e9 Airport", "city_name":"Beauvais/Till\u00e9"},
 {"airport_iata_code":"XSU", "airport_name":"Saumur-Saint-Florent Airport", "city_name":"Saumur/Saint-Florent"},
 {"airport_iata_code":"EVX", "airport_name":"\u00c9vreux-Fauville (BA 105) Air Base", "city_name":"\u00c9vreux/Fauville"},
 {"airport_iata_code":"XAN", "airport_name":"Alen\u00e7on Valframbert Airport", "city_name":"Evreux"},
 {"airport_iata_code":"LEH", "airport_name":"Le Havre Octeville Airport", "city_name":"Le Havre/Octeville"},
 {"airport_iata_code":"XAB", "airport_name":"Abbeville-Buigny-Saint-Maclou Airport", "city_name":"Abbeville (Buigny/Saint-Maclou"},
 {"airport_iata_code":"ORE", "airport_name":"Orl\u00e9ans-Bricy (BA 123) Air Base", "city_name":"Orl\u00e9ans/Bricy"},
 {"airport_iata_code":"XCR", "airport_name":"Ch\u00e2lons-Vatry Air Base", "city_name":"Ch\u00e2lons/Vatry"},
 {"airport_iata_code":"LSO", "airport_name":"Les Sables-d'Olonne Talmont Airport", "city_name":"Les Sables-d'Olonne"},
 {"airport_iata_code":"URO", "airport_name":"Rouen Airport", "city_name":"Rouen/Vall\u00e9e de Seine"},
 {"airport_iata_code":"XBQ", "airport_name":"Blois-Le Breuil Airport", "city_name":"Blois/Le Breuil"},
 {"airport_iata_code":"QTJ", "airport_name":"Chartres \u2013 M\u00e9tropole Airport", "city_name":"Chartres / Champhol"},
 {"airport_iata_code":"TUF", "airport_name":"Tours-Val-de-Loire Airport", "city_name":"Tours/Val de Loire (Loire Vall"},
 {"airport_iata_code":"CET", "airport_name":"Cholet Le Pontreau Airport", "city_name":"Cholet/Le Pontreau"},
 {"airport_iata_code":"LVA", "airport_name":"Laval-Entrammes Airport", "city_name":"Laval/Entrammes"},
 {"airport_iata_code":"LBG", "airport_name":"Paris-Le Bourget Airport", "city_name":"Paris"},
 {"airport_iata_code":"CSF", "airport_name":"Creil Air Base", "city_name":"Creil"},
 {"airport_iata_code":"CDG", "airport_name":"Charles de Gaulle International Airport", "city_name":"Paris"},
 {"airport_iata_code":"TNF", "airport_name":"Toussus-le-Noble Airport", "city_name":"Toussus-le-Noble"},
 {"airport_iata_code":"ORY", "airport_name":"Paris-Orly Airport", "city_name":"Paris"},
 {"airport_iata_code":"POX", "airport_name":"Pontoise - Cormeilles-en-Vexin Airport", "city_name":"Pontoise"},
 {"airport_iata_code":"VIY", "airport_name":"Villacoublay-V\u00e9lizy (BA 107) Air Base", "city_name":"Villacoublay/V\u00e9lizy"},
 {"airport_iata_code":"QYR", "airport_name":"Troyes-Barberey Airport", "city_name":"Troyes/Barberey"},
 {"airport_iata_code":"NVS", "airport_name":"Nevers-Fourchambault Airport", "city_name":"Nevers/Fourchambault"},
 {"airport_iata_code":"XCB", "airport_name":"Cambrai-\u00c9pinoy (BA 103) Air Base", "city_name":"Cambrai/\u00c9pinoy"},
 {"airport_iata_code":"XME", "airport_name":"Maubeuge-\u00c9lesmes Airport", "city_name":"Maubeuge/\u00c9lesmes"},
 {"airport_iata_code":"LIL", "airport_name":"Lille-Lesquin Airport", "city_name":"Lille/Lesquin"},
 {"airport_iata_code":"HZB", "airport_name":"Merville-Calonne Airport", "city_name":"Merville/Calonne"},
 {"airport_iata_code":"XCZ", "airport_name":"Charleville-M\u00e9zi\u00e8res Airport", "city_name":"Charleville-M\u00e9zi\u00e8res"},
 {"airport_iata_code":"XVO", "airport_name":"Vesoul-Frotey Airport", "city_name":"Vesoul/Frotey"},
 {"airport_iata_code":"BES", "airport_name":"Brest Bretagne Airport", "city_name":"Brest/Guipavas"},
 {"airport_iata_code":"CER", "airport_name":"Cherbourg-Maupertus Airport", "city_name":"Cherbourg/Maupertus"},
 {"airport_iata_code":"DNR", "airport_name":"Dinard-Pleurtuit-Saint-Malo Airport", "city_name":"Dinard/Pleurtuit/Saint-Malo"},
 {"airport_iata_code":"LBY", "airport_name":"La Baule-Escoublac Airport", "city_name":"La Baule-Escoublac"},
 {"airport_iata_code":"GFR", "airport_name":"Granville Airport", "city_name":"Granville"},
 {"airport_iata_code":"DOL", "airport_name":"Deauville-Saint-Gatien Airport", "city_name":"Deauville"},
 {"airport_iata_code":"LRT", "airport_name":"Lorient South Brittany (Bretagne Sud) Airport", "city_name":"Lorient/Lann/Bihou\u00e9"},
 {"airport_iata_code":"EDM", "airport_name":"La Roche-sur-Yon Airport", "city_name":"La Roche-sur-Yon/Les Ajoncs"},
 {"airport_iata_code":"LDV", "airport_name":"Landivisiau Air Base", "city_name":"Landivisiau"},
 {"airport_iata_code":"CFR", "airport_name":"Caen-Carpiquet Airport", "city_name":"Caen/Carpiquet"},
 {"airport_iata_code":"LME", "airport_name":"Le Mans-Arnage Airport", "city_name":"Le Mans/Arnage"},
 {"airport_iata_code":"RNS", "airport_name":"Rennes-Saint-Jacques Airport", "city_name":"Rennes/Saint-Jacques"},
 {"airport_iata_code":"LAI", "airport_name":"Lannion-C\u00f4te de Granit Airport", "city_name":"Lannion"},
 {"airport_iata_code":"UIP", "airport_name":"Quimper-Cornouaille Airport", "city_name":"Quimper/Pluguffan"},
 {"airport_iata_code":"NTE", "airport_name":"Nantes Atlantique Airport", "city_name":"Nantes"},
 {"airport_iata_code":"SBK", "airport_name":"Saint-Brieuc-Armor Airport", "city_name":"Saint-Brieuc/Armor"},
 {"airport_iata_code":"MXN", "airport_name":"Morlaix-Ploujean Airport", "city_name":"Morlaix/Ploujean"},
 {"airport_iata_code":"VNE", "airport_name":"Vannes-Meucon Airport", "city_name":"Vannes/Meucon"},
 {"airport_iata_code":"SNR", "airport_name":"Saint-Nazaire-Montoir Airport", "city_name":"Saint-Nazaire/Montoir"},
 {"airport_iata_code":"QBQ", "airport_name":"Besan\u00e7on Thise Airport", "city_name":"St Nazaire"},
 {"airport_iata_code":"BSL", "airport_name":"EuroAirport Basel-Mulhouse-Freiburg Airport", "city_name":"B\u00e2le/Mulhouse"},
 {"airport_iata_code":"DIJ", "airport_name":"Dijon-Bourgogne Airport", "city_name":"Dijon/Longvic"},
 {"airport_iata_code":"MZM", "airport_name":"Metz-Frescaty (BA 128) Air Base", "city_name":"Metz/Frescaty"},
 {"airport_iata_code":"EPL", "airport_name":"\u00c9pinal-Mirecourt Airport", "city_name":"\u00c9pinal/Mirecourt"},
 {"airport_iata_code":"XMF", "airport_name":"Montb\u00e9liard-Courcelles Airport", "city_name":"Montb\u00e9liard/Courcelles"},
 {"airport_iata_code":"ENC", "airport_name":"Nancy-Essey Airport", "city_name":"Nancy/Essey"},
 {"airport_iata_code":"BOR", "airport_name":"Fontaine Airport", "city_name":"Belfort"},
 {"airport_iata_code":"RHE", "airport_name":"Reims-Champagne (BA 112) Airport", "city_name":"Reims/Champagne"},
 {"airport_iata_code":"SXB", "airport_name":"Strasbourg Airport", "city_name":"Strasbourg"},
 {"airport_iata_code":"VTL", "airport_name":"Vittel Champ De Course Airport", "city_name":"Luxeuil"},
 {"airport_iata_code":"XHE", "airport_name":"Toulon-Hy\u00e8res Airport", "city_name":"Toulon/Hy\u00e8res/Le Palyvestre"},
 {"airport_iata_code":"FNI", "airport_name":"N\u00eemes-Arles-Camargue Airport", "city_name":"N\u00eemes/Garons"},
 {"airport_iata_code":"LTT", "airport_name":"La M\u00f4le Airport", "city_name":"La M\u00f4le"},
 {"airport_iata_code":"AWE", "airport_name":"Alowe Airport", "city_name":"Wonga Wongue Reserve"},
 {"airport_iata_code":"BGB", "airport_name":"Booue Airport", "city_name":"Booue"},
 {"airport_iata_code":"KDN", "airport_name":"Ndende Airport", "city_name":"Ndende"},
 {"airport_iata_code":"FOU", "airport_name":"Fougamou Airport", "city_name":"Fougamou"},
 {"airport_iata_code":"MBC", "airport_name":"M'Bigou Airport", "city_name":"M'Bigou"},
 {"airport_iata_code":"MGX", "airport_name":"Moabi Airport", "city_name":"Moabi"},
 {"airport_iata_code":"KDJ", "airport_name":"Ville Airport", "city_name":"N'Djol\u00e9"},
 {"airport_iata_code":"KOU", "airport_name":"Koulamoutou Airport", "city_name":"Koulamoutou"},
 {"airport_iata_code":"MJL", "airport_name":"Mouilla Ville Airport", "city_name":"Mouila"},
 {"airport_iata_code":"OYE", "airport_name":"Oyem Airport", "city_name":"Oyem"},
 {"airport_iata_code":"OKN", "airport_name":"Okondja Airport", "city_name":"Okondja"},
 {"airport_iata_code":"LBQ", "airport_name":"Lambarene Airport", "city_name":"Lambarene"},
 {"airport_iata_code":"MVX", "airport_name":"Minvoul Airport", "city_name":"Minvoul"},
 {"airport_iata_code":"BMM", "airport_name":"Bitam Airport", "city_name":"Bitam"},
 {"airport_iata_code":"MFF", "airport_name":"Moanda Airport", "city_name":"Moanda"},
 {"airport_iata_code":"MKB", "airport_name":"Mekambo Airport", "city_name":"Mekambo"},
 {"airport_iata_code":"POG", "airport_name":"Port Gentil Airport", "city_name":"Port Gentil"},
 {"airport_iata_code":"OMB", "airport_name":"Omboue Hopital Airport", "city_name":"Omboue"},
 {"airport_iata_code":"MKU", "airport_name":"Makokou Airport", "city_name":"Makokou"},
 {"airport_iata_code":"LBV", "airport_name":"Leon M Ba Airport", "city_name":"Libreville"},
 {"airport_iata_code":"MZC", "airport_name":"Mitzic Airport", "city_name":"Mitzic"},
 {"airport_iata_code":"MVB", "airport_name":"M'Vengue El Hadj Omar Bongo Ondimba International Airport", "city_name":"Franceville"},
 {"airport_iata_code":"LTL", "airport_name":"Lastourville Airport", "city_name":"Lastourville"},
 {"airport_iata_code":"ZKM", "airport_name":"Sette Cama Airport", "city_name":"Sette Cama"},
 {"airport_iata_code":"TCH", "airport_name":"Tchibanga Airport", "city_name":"Tchibanga"},
 {"airport_iata_code":"MYB", "airport_name":"Mayumba Airport", "city_name":"Mayumba"},
 {"airport_iata_code":"AKE", "airport_name":"Akieni Airport", "city_name":"Akieni"},
 {"airport_iata_code":"GAX", "airport_name":"Gamba Airport", "city_name":"Gamba"},
 {"airport_iata_code":"BFS", "airport_name":"Belfast International Airport", "city_name":"Belfast"},
 {"airport_iata_code":"ENK", "airport_name":"St Angelo Airport", "city_name":"Enniskillen"},
 {"airport_iata_code":"BHD", "airport_name":"George Best Belfast City Airport", "city_name":"Belfast"},
 {"airport_iata_code":"LDY", "airport_name":"City of Derry Airport", "city_name":"Derry"},
 {"airport_iata_code":"BHX", "airport_name":"Birmingham International Airport", "city_name":"Birmingham"},
 {"airport_iata_code":"CVT", "airport_name":"Coventry Airport", "city_name":"Coventry"},
 {"airport_iata_code":"GLO", "airport_name":"Gloucestershire Airport", "city_name":"Staverton"},
 {"airport_iata_code":"ORM", "airport_name":"Sywell Aerodrome", "city_name":"Northampton"},
 {"airport_iata_code":"NQT", "airport_name":"Nottingham Airport", "city_name":"Nottingham"},
 {"airport_iata_code":"MAN", "airport_name":"Manchester Airport", "city_name":"Manchester"},
 {"airport_iata_code":"DSA", "airport_name":"Robin Hood Doncaster Sheffield Airport", "city_name":"Doncaster"},
 {"airport_iata_code":"UPV", "airport_name":"Upavon Aerodrome", "city_name":"Upavon"},
 {"airport_iata_code":"LYE", "airport_name":"RAF Lyneham", "city_name":"Lyneham"},
 {"airport_iata_code":"DGX", "airport_name":"MOD St. Athan", "city_name":"St. Athan"},
 {"airport_iata_code":"YEO", "airport_name":"RNAS Yeovilton", "city_name":"Yeovil"},
 {"airport_iata_code":"CAL", "airport_name":"Campbeltown Airport", "city_name":"Campbeltown"},
 {"airport_iata_code":"EOI", "airport_name":"Eday Airport", "city_name":"Eday"},
 {"airport_iata_code":"FIE", "airport_name":"Fair Isle Airport", "city_name":"Fair Isle"},
 {"airport_iata_code":"WHS", "airport_name":"Whalsay Airport", "city_name":"Whalsay"},
 {"airport_iata_code":"COL", "airport_name":"Coll Airport", "city_name":"Coll Island"},
 {"airport_iata_code":"NRL", "airport_name":"North Ronaldsay Airport", "city_name":"North Ronaldsay"},
 {"airport_iata_code":"OBN", "airport_name":"Oban Airport", "city_name":"North Connel"},
 {"airport_iata_code":"PPW", "airport_name":"Papa Westray Airport", "city_name":"Papa Westray"},
 {"airport_iata_code":"SOY", "airport_name":"Stronsay Airport", "city_name":"Stronsay"},
 {"airport_iata_code":"NDY", "airport_name":"Sanday Airport", "city_name":"Sanday"},
 {"airport_iata_code":"LWK", "airport_name":"Lerwick / Tingwall Airport", "city_name":"Lerwick"},
 {"airport_iata_code":"WRY", "airport_name":"Westray Airport", "city_name":"Westray"},
 {"airport_iata_code":"CSA", "airport_name":"Colonsay Airstrip", "city_name":"Colonsay"},
 {"airport_iata_code":"HAW", "airport_name":"Haverfordwest Airport", "city_name":"Haverfordwest"},
 {"airport_iata_code":"CWL", "airport_name":"Cardiff International Airport", "city_name":"Cardiff"},
 {"airport_iata_code":"SWS", "airport_name":"Swansea Airport", "city_name":"Swansea"},
 {"airport_iata_code":"BRS", "airport_name":"Bristol International Airport", "city_name":"Bristol"},
 {"airport_iata_code":"LPL", "airport_name":"Liverpool John Lennon Airport", "city_name":"Liverpool"},
 {"airport_iata_code":"LTN", "airport_name":"London Luton Airport", "city_name":"London"},
 {"airport_iata_code":"LEQ", "airport_name":"Land's End Airport", "city_name":"Land's End"},
 {"airport_iata_code":"PLH", "airport_name":"Plymouth City Airport", "city_name":"Plymouth"},
 {"airport_iata_code":"ISC", "airport_name":"St. Mary's Airport", "city_name":"St. Mary's"},
 {"airport_iata_code":"BOH", "airport_name":"Bournemouth Airport", "city_name":"Bournemouth"},
 {"airport_iata_code":"SOU", "airport_name":"Southampton Airport", "city_name":"Southampton"},
 {"airport_iata_code":"BBP", "airport_name":"Bembridge Airport", "city_name":"Bembridge"},
 {"airport_iata_code":"QLA", "airport_name":"Lasham Airport", "city_name":"Lasham"},
 {"airport_iata_code":"NQY", "airport_name":"Newquay Cornwall Airport", "city_name":"Newquay"},
 {"airport_iata_code":"QUG", "airport_name":"Chichester/Goodwood Airport", "city_name":"Chichester"},
 {"airport_iata_code":"ESH", "airport_name":"Shoreham Airport", "city_name":"Brighton"},
 {"airport_iata_code":"BQH", "airport_name":"London Biggin Hill Airport", "city_name":"London"},
 {"airport_iata_code":"LGW", "airport_name":"London Gatwick Airport", "city_name":"London"},
 {"airport_iata_code":"KRH", "airport_name":"Redhill Aerodrome", "city_name":"Redhill"},
 {"airport_iata_code":"LCY", "airport_name":"London City Airport", "city_name":"London"},
 {"airport_iata_code":"FAB", "airport_name":"Farnborough Airport", "city_name":"Farnborough"},
 {"airport_iata_code":"BBS", "airport_name":"Blackbushe Airport", "city_name":"Yateley"},
 {"airport_iata_code":"LHR", "airport_name":"London Heathrow Airport", "city_name":"London"},
 {"airport_iata_code":"SEN", "airport_name":"Southend Airport", "city_name":"Southend"},
 {"airport_iata_code":"LYX", "airport_name":"Lydd Airport", "city_name":"Lydd, Ashford"},
 {"airport_iata_code":"MSE", "airport_name":"Kent International Airport", "city_name":"Manston"},
 {"airport_iata_code":"CAX", "airport_name":"Carlisle Airport", "city_name":"Carlisle"},
 {"airport_iata_code":"BLK", "airport_name":"Blackpool International Airport", "city_name":"Blackpool"},
 {"airport_iata_code":"HUY", "airport_name":"Humberside Airport", "city_name":"Grimsby"},
 {"airport_iata_code":"BWF", "airport_name":"Barrow Walney Island Airport", "city_name":"Barrow-in-Furness"},
 {"airport_iata_code":"LBA", "airport_name":"Leeds Bradford Airport", "city_name":"Leeds"},
 {"airport_iata_code":"CEG", "airport_name":"Hawarden Airport", "city_name":"Hawarden"},
 {"airport_iata_code":"NCL", "airport_name":"Newcastle Airport", "city_name":"Newcastle"},
 {"airport_iata_code":"MME", "airport_name":"Durham Tees Valley Airport", "city_name":"Durham"},
 {"airport_iata_code":"EMA", "airport_name":"East Midlands Airport", "city_name":"Nottingham"},
 {"airport_iata_code":"HLY", "airport_name":"Anglesey Airport", "city_name":"Angelsey"},
 {"airport_iata_code":"KOI", "airport_name":"Kirkwall Airport", "city_name":"Orkney Islands"},
 {"airport_iata_code":"LSI", "airport_name":"Sumburgh Airport", "city_name":"Lerwick"},
 {"airport_iata_code":"WIC", "airport_name":"Wick Airport", "city_name":"Wick"},
 {"airport_iata_code":"ABZ", "airport_name":"Aberdeen Dyce Airport", "city_name":"Aberdeen"},
 {"airport_iata_code":"INV", "airport_name":"Inverness Airport", "city_name":"Inverness"},
 {"airport_iata_code":"GLA", "airport_name":"Glasgow International Airport", "city_name":"Glasgow"},
 {"airport_iata_code":"EDI", "airport_name":"Edinburgh Airport", "city_name":"Edinburgh"},
 {"airport_iata_code":"ILY", "airport_name":"Islay Airport", "city_name":"Port Ellen"},
 {"airport_iata_code":"PIK", "airport_name":"Glasgow Prestwick Airport", "city_name":"Glasgow"},
 {"airport_iata_code":"BEB", "airport_name":"Benbecula Airport", "city_name":"Balivanich"},
 {"airport_iata_code":"SCS", "airport_name":"Scatsta Airport", "city_name":"Shetland Islands"},
 {"airport_iata_code":"DND", "airport_name":"Dundee Airport", "city_name":"Dundee"},
 {"airport_iata_code":"SYY", "airport_name":"Stornoway Airport", "city_name":"Stornoway"},
 {"airport_iata_code":"BRR", "airport_name":"Barra Airport", "city_name":"Eoligarry"},
 {"airport_iata_code":"PSL", "airport_name":"Perth/Scone Airport", "city_name":"Perth"},
 {"airport_iata_code":"TRE", "airport_name":"Tiree Airport", "city_name":"Balemartine"},
 {"airport_iata_code":"UNT", "airport_name":"Unst Airport", "city_name":"Shetland Islands"},
 {"airport_iata_code":"BOL", "airport_name":"Ballykelly Airport", "city_name":"Ballykelly"},
 {"airport_iata_code":"FSS", "airport_name":"RAF Kinloss", "city_name":"Kinloss"},
 {"airport_iata_code":"ADX", "airport_name":"RAF Leuchars", "city_name":"St. Andrews"},
 {"airport_iata_code":"LMO", "airport_name":"RAF Lossiemouth", "city_name":"Lossiemouth"},
 {"airport_iata_code":"CBG", "airport_name":"Cambridge Airport", "city_name":"Cambridge"},
 {"airport_iata_code":"NWI", "airport_name":"Norwich International Airport", "city_name":"Norwich"},
 {"airport_iata_code":"STN", "airport_name":"London Stansted Airport", "city_name":"London"},
 {"airport_iata_code":"QFO", "airport_name":"Duxford Airport", "city_name":"Duxford"},
 {"airport_iata_code":"HYC", "airport_name":"Wycombe Air Park", "city_name":"High Wycombe"},
 {"airport_iata_code":"EXT", "airport_name":"Exeter International Airport", "city_name":"Exeter"},
 {"airport_iata_code":"FZO", "airport_name":"Bristol Filton Airport", "city_name":"Bristol"},
 {"airport_iata_code":"OXF", "airport_name":"Oxford (Kidlington) Airport", "city_name":"Kidlington"},
 {"airport_iata_code":"RCS", "airport_name":"Rochester Airport", "city_name":"Rochester"},
 {"airport_iata_code":"BEX", "airport_name":"RAF Benson", "city_name":"Benson"},
 {"airport_iata_code":"LKZ", "airport_name":"RAF Lakenheath", "city_name":"Lakenheath"},
 {"airport_iata_code":"MHZ", "airport_name":"RAF Mildenhall", "city_name":"Mildenhall"},
 {"airport_iata_code":"QUY", "airport_name":"RAF Wyton", "city_name":"St. Ives"},
 {"airport_iata_code":"FFD", "airport_name":"RAF Fairford", "city_name":"Fairford"},
 {"airport_iata_code":"BZZ", "airport_name":"RAF Brize Norton", "city_name":"Brize Norton"},
 {"airport_iata_code":"ODH", "airport_name":"RAF Odiham", "city_name":"Odiham"},
 {"airport_iata_code":"WXF", "airport_name":"Wethersfield Airport", "city_name":"Wethersfield"},
 {"airport_iata_code":"NHT", "airport_name":"RAF Northolt", "city_name":"London"},
 {"airport_iata_code":"QCY", "airport_name":"RAF Coningsby", "city_name":"Coningsby"},
 {"airport_iata_code":"BEQ", "airport_name":"RAF Honington", "city_name":"Thetford"},
 {"airport_iata_code":"OKH", "airport_name":"RAF Cottesmore", "city_name":"Cottesmore"},
 {"airport_iata_code":"SQZ", "airport_name":"RAF Scampton", "city_name":"Scampton"},
 {"airport_iata_code":"HRT", "airport_name":"RAF Linton-On-Ouse", "city_name":"Linton-On-Ouse"},
 {"airport_iata_code":"WTN", "airport_name":"RAF Waddington", "city_name":"Waddington"},
 {"airport_iata_code":"MRH", "airport_name":"RAF Marham", "city_name":"Marham"},
 {"airport_iata_code":"FEA", "airport_name":"Fetlar Airport", "city_name":"Fetlar Island"},
 {"airport_iata_code":"DOC", "airport_name":"Dornoch Airport", "city_name":"Dornoch"},
 {"airport_iata_code":"FLH", "airport_name":"Flotta Isle Airport", "city_name":"Flotta Isle"},
 {"airport_iata_code":"FOA", "airport_name":"Foula Airport", "city_name":"Foula"},
 {"airport_iata_code":"OUK", "airport_name":"Outer Skerries Airport", "city_name":"Grunay Island"},
 {"airport_iata_code":"PSV", "airport_name":"Papa Stour Airport", "city_name":"Papa Stour Island"},
 {"airport_iata_code":"ULL", "airport_name":"Glenforsa Airfield", "city_name":"Glenforsa"},
 {"airport_iata_code":"SKL", "airport_name":"Skye Bridge Ashaig Airport", "city_name":"Broadford"},
 {"airport_iata_code":"GND", "airport_name":"Point Salines International Airport", "city_name":"Saint George's"},
 {"airport_iata_code":"CRU", "airport_name":"Lauriston Airport", "city_name":"Carriacou Island"},
 {"airport_iata_code":"KUT", "airport_name":"Kopitnari Airport", "city_name":"Kutaisi"},
 {"airport_iata_code":"BUS", "airport_name":"Batumi International Airport", "city_name":"Batumi"},
 {"airport_iata_code":"SUI", "airport_name":"Sukhumi Dranda Airport", "city_name":"Sukhumi"},
 {"airport_iata_code":"TBS", "airport_name":"Tbilisi International Airport", "city_name":"Tbilisi"},
 {"airport_iata_code":"CAY", "airport_name":"Cayenne-Rochambeau Airport", "city_name":"Cayenne / Rochambeau"},
 {"airport_iata_code":"MPY", "airport_name":"Maripasoula Airport", "city_name":"Maripasoula"},
 {"airport_iata_code":"OXP", "airport_name":"Saint-Georges-de-l'Oyapock Airport", "city_name":"Saint-Georges-de-l'Oyapock Air"},
 {"airport_iata_code":"LDX", "airport_name":"Saint-Laurent-du-Maroni Airport", "city_name":"Saint-Laurent-du-Maroni"},
 {"airport_iata_code":"REI", "airport_name":"Regina Airport", "city_name":"Regina"},
 {"airport_iata_code":"XAU", "airport_name":"Sa\u00fal Airport", "city_name":"Sa\u00fal"},
 {"airport_iata_code":"ACI", "airport_name":"Alderney Airport", "city_name":"Saint Anne"},
 {"airport_iata_code":"GCI", "airport_name":"Guernsey Airport", "city_name":"Saint Peter Port"},
 {"airport_iata_code":"ACC", "airport_name":"Kotoka International Airport", "city_name":"Accra"},
 {"airport_iata_code":"TML", "airport_name":"Tamale Airport", "city_name":"Tamale"},
 {"airport_iata_code":"KMS", "airport_name":"Kumasi Airport", "city_name":"Kumasi"},
 {"airport_iata_code":"NYI", "airport_name":"Sunyani Airport", "city_name":"Sunyani"},
 {"airport_iata_code":"TKD", "airport_name":"Takoradi Airport", "city_name":"Sekondi-Takoradi"},
 {"airport_iata_code":"GIB", "airport_name":"Gibraltar Airport", "city_name":"Gibraltar"},
 {"airport_iata_code":"JEG", "airport_name":"Aasiaat Airport", "city_name":"Aasiaat"},
 {"airport_iata_code":"UAK", "airport_name":"Narsarsuaq Airport", "city_name":"Narsarsuaq"},
 {"airport_iata_code":"CNP", "airport_name":"Neerlerit Inaat Airport", "city_name":"Neerlerit Inaat"},
 {"airport_iata_code":"GOH", "airport_name":"Godthaab / Nuuk Airport", "city_name":"Nuuk"},
 {"airport_iata_code":"JAV", "airport_name":"Ilulissat Airport", "city_name":"Ilulissat"},
 {"airport_iata_code":"KUS", "airport_name":"Kulusuk Airport", "city_name":"Kulusuk"},
 {"airport_iata_code":"JSU", "airport_name":"Maniitsoq Airport", "city_name":"Maniitsoq"},
 {"airport_iata_code":"JFR", "airport_name":"Paamiut Airport", "city_name":"Paamiut"},
 {"airport_iata_code":"NAQ", "airport_name":"Qaanaaq Airport", "city_name":"Qaanaaq"},
 {"airport_iata_code":"SFJ", "airport_name":"Kangerlussuaq Airport", "city_name":"Kangerlussuaq"},
 {"airport_iata_code":"JHS", "airport_name":"Sisimiut Airport", "city_name":"Sisimiut"},
 {"airport_iata_code":"THU", "airport_name":"Thule Air Base", "city_name":"Thule"},
 {"airport_iata_code":"JUV", "airport_name":"Upernavik Airport", "city_name":"Upernavik"},
 {"airport_iata_code":"JQA", "airport_name":"Qaarsut Airport", "city_name":"Uummannaq"},
 {"airport_iata_code":"BJL", "airport_name":"Banjul International Airport", "city_name":"Banjul"},
 {"airport_iata_code":"CKY", "airport_name":"Conakry Airport", "city_name":"Conakry"},
 {"airport_iata_code":"FIG", "airport_name":"Fria Airport", "city_name":""},
 {"airport_iata_code":"FAA", "airport_name":"Faranah Airport", "city_name":""},
 {"airport_iata_code":"KSI", "airport_name":"Kissidougou Airport", "city_name":"Kissidougou"},
 {"airport_iata_code":"LEK", "airport_name":"Labe Airport", "city_name":""},
 {"airport_iata_code":"MCA", "airport_name":"Macenta Airport", "city_name":"Macenta"},
 {"airport_iata_code":"NZE", "airport_name":"Nzerekore Airport", "city_name":"Nzerekore"},
 {"airport_iata_code":"BKJ", "airport_name":"Boke Airport", "city_name":"Boke"},
 {"airport_iata_code":"SBI", "airport_name":"Sambailo Airport", "city_name":"Koundara"},
 {"airport_iata_code":"GII", "airport_name":"Siguiri Airport", "city_name":"Siguiri"},
 {"airport_iata_code":"KNN", "airport_name":"Kankan Airport", "city_name":"Kankan"},
 {"airport_iata_code":"DSD", "airport_name":"La D\u00e9sirade Airport", "city_name":"Grande Anse"},
 {"airport_iata_code":"BBR", "airport_name":"Baillif Airport", "city_name":"Basse Terre"},
 {"airport_iata_code":"SFC", "airport_name":"St-Fran\u00e7ois Airport", "city_name":"St-Fran\u00e7ois"},
 {"airport_iata_code":"GBJ", "airport_name":"Les Bases Airport", "city_name":"Grand Bourg"},
 {"airport_iata_code":"PTP", "airport_name":"Pointe-\u00e0-Pitre Le Raizet", "city_name":"Pointe-\u00e0-Pitre Le Raizet"},
 {"airport_iata_code":"LSS", "airport_name":"Terre-de-Haut Airport", "city_name":"Les Saintes"},
 {"airport_iata_code":"BSG", "airport_name":"Bata Airport", "city_name":""},
 {"airport_iata_code":"SSG", "airport_name":"Malabo Airport", "city_name":"Malabo"},
 {"airport_iata_code":"JSS", "airport_name":"Spetsai Airport", "city_name":"Spetsai"},
 {"airport_iata_code":"PYR", "airport_name":"Andravida Airport", "city_name":"Andravida"},
 {"airport_iata_code":"AGQ", "airport_name":"Agrinion Airport", "city_name":"Agrinion"},
 {"airport_iata_code":"AXD", "airport_name":"Dimokritos Airport", "city_name":"Alexandroupolis"},
 {"airport_iata_code":"HEW", "airport_name":"Athen Helenikon Airport", "city_name":"Athens"},
 {"airport_iata_code":"ATH", "airport_name":"Eleftherios Venizelos International Airport", "city_name":"Athens"},
 {"airport_iata_code":"VOL", "airport_name":"Nea Anchialos Airport", "city_name":"Nea Anchialos"},
 {"airport_iata_code":"JKH", "airport_name":"Chios Island National Airport", "city_name":"Chios Island"},
 {"airport_iata_code":"PKH", "airport_name":"Porto Cheli Airport", "city_name":"Porto Cheli"},
 {"airport_iata_code":"JIK", "airport_name":"Ikaria Airport", "city_name":"Ikaria Island"},
 {"airport_iata_code":"IOA", "airport_name":"Ioannina Airport", "city_name":"Ioannina"},
 {"airport_iata_code":"HER", "airport_name":"Heraklion International Nikos Kazantzakis Airport", "city_name":"Heraklion"},
 {"airport_iata_code":"KSO", "airport_name":"Kastoria National Airport", "city_name":"Kastoria"},
 {"airport_iata_code":"KIT", "airport_name":"Kithira Airport", "city_name":"Kithira Island"},
 {"airport_iata_code":"EFL", "airport_name":"Kefallinia Airport", "city_name":"Kefallinia Island"},
 {"airport_iata_code":"KZS", "airport_name":"Kastelorizo Airport", "city_name":"Kastelorizo Island"},
 {"airport_iata_code":"KLX", "airport_name":"Kalamata Airport", "city_name":"Kalamata"},
 {"airport_iata_code":"KGS", "airport_name":"Kos Airport", "city_name":"Kos Island"},
 {"airport_iata_code":"AOK", "airport_name":"Karpathos Airport", "city_name":"Karpathos Island"},
 {"airport_iata_code":"CFU", "airport_name":"Ioannis Kapodistrias International Airport", "city_name":"Kerkyra Island"},
 {"airport_iata_code":"KSJ", "airport_name":"Kasos Airport", "city_name":"Kasos Island"},
 {"airport_iata_code":"KVA", "airport_name":"Alexander the Great International Airport", "city_name":"Kavala"},
 {"airport_iata_code":"KZI", "airport_name":"Filippos Airport", "city_name":"Kozani"},
 {"airport_iata_code":"LRS", "airport_name":"Leros Airport", "city_name":"Leros Island"},
 {"airport_iata_code":"LXS", "airport_name":"Limnos Airport", "city_name":"Limnos Island"},
 {"airport_iata_code":"LRA", "airport_name":"Larisa Airport", "city_name":"Larisa"},
 {"airport_iata_code":"JMK", "airport_name":"Mikonos Airport", "city_name":"Mykonos Island"},
 {"airport_iata_code":"MLO", "airport_name":"Milos Airport", "city_name":"Milos Island"},
 {"airport_iata_code":"MJT", "airport_name":"Mytilene International Airport", "city_name":"Mytilene"},
 {"airport_iata_code":"JNX", "airport_name":"Naxos Airport", "city_name":"Naxos Island"},
 {"airport_iata_code":"PAS", "airport_name":"Paros Airport", "city_name":"Paros Island"},
 {"airport_iata_code":"JTY", "airport_name":"Astypalaia Airport", "city_name":"Astypalaia Island"},
 {"airport_iata_code":"PVK", "airport_name":"Aktion National Airport", "city_name":"Preveza/Lefkada"},
 {"airport_iata_code":"RHO", "airport_name":"Diagoras Airport", "city_name":"Rodes Island"},
 {"airport_iata_code":"GPA", "airport_name":"Araxos Airport", "city_name":"Patras"},
 {"airport_iata_code":"CHQ", "airport_name":"Chania International Airport", "city_name":"Souda"},
 {"airport_iata_code":"JSI", "airport_name":"Skiathos Island National Airport", "city_name":"Skiathos"},
 {"airport_iata_code":"SMI", "airport_name":"Samos Airport", "city_name":"Samos Island"},
 {"airport_iata_code":"JSY", "airport_name":"Syros Airport", "city_name":"Syros Island"},
 {"airport_iata_code":"SPJ", "airport_name":"Sparti Airport", "city_name":"Sparti"},
 {"airport_iata_code":"JTR", "airport_name":"Santorini Airport", "city_name":"Santorini Island"},
 {"airport_iata_code":"JSH", "airport_name":"Sitia Airport", "city_name":"Crete Island"},
 {"airport_iata_code":"SKU", "airport_name":"Skiros Airport", "city_name":"Skiros Island"},
 {"airport_iata_code":"SKG", "airport_name":"Thessaloniki Macedonia International Airport", "city_name":"Thessaloniki"},
 {"airport_iata_code":"ZTH", "airport_name":"Dionysios Solomos Airport", "city_name":"Zakynthos Island"},
 {"airport_iata_code":"CIQ", "airport_name":"Chiquimula Airport", "city_name":"Chiquimula"},
 {"airport_iata_code":"DON", "airport_name":"Dos Lagunas Airport", "city_name":"Dos Lagunas"},
 {"airport_iata_code":"ENJ", "airport_name":"El Naranjo Airport", "city_name":"El Naranjo"},
 {"airport_iata_code":"PCG", "airport_name":"Paso Caballos Airport", "city_name":"Paso Caballos"},
 {"airport_iata_code":"LCF", "airport_name":"Las Vegas Airport", "city_name":"Rio Dulce"},
 {"airport_iata_code":"TKM", "airport_name":"El Pet\u00e9n Airport", "city_name":"Tikal"},
 {"airport_iata_code":"UAX", "airport_name":"Uaxactun Airport", "city_name":"Uaxactun"},
 {"airport_iata_code":"PKJ", "airport_name":"Playa Grande Airport", "city_name":"Playa Grande"},
 {"airport_iata_code":"CBV", "airport_name":"Coban Airport", "city_name":"Coban"},
 {"airport_iata_code":"CMM", "airport_name":"Carmelita Airport", "city_name":"Carmelita"},
 {"airport_iata_code":"CTF", "airport_name":"Coatepeque Airport", "city_name":"Coatepeque"},
 {"airport_iata_code":"GUA", "airport_name":"La Aurora Airport", "city_name":"Guatemala City"},
 {"airport_iata_code":"HUG", "airport_name":"Huehuetenango Airport", "city_name":"Huehuetenango"},
 {"airport_iata_code":"MCR", "airport_name":"Melchor de Mencos Airport", "city_name":"Melchor de Mencos"},
 {"airport_iata_code":"PBR", "airport_name":"Puerto Barrios Airport", "city_name":"Puerto Barrios"},
 {"airport_iata_code":"PON", "airport_name":"Popt\u00fan Airport", "city_name":"Popt\u00fan"},
 {"airport_iata_code":"AQB", "airport_name":"Santa Cruz del Quiche Airport", "city_name":"Santa Cruz del Quiche"},
 {"airport_iata_code":"AAZ", "airport_name":"Quezaltenango Airport", "city_name":"Quezaltenango"},
 {"airport_iata_code":"RUV", "airport_name":"Rubelsanto Airport", "city_name":"Rubelsanto"},
 {"airport_iata_code":"RER", "airport_name":"Retalhuleu Airport", "city_name":"Retalhuleu"},
 {"airport_iata_code":"GSJ", "airport_name":"San Jos\u00e9 Airport", "city_name":"Puerto San Jos\u00e9"},
 {"airport_iata_code":"FRS", "airport_name":"Mundo Maya International Airport", "city_name":"San Benito"},
 {"airport_iata_code":"UAM", "airport_name":"Andersen Air Force Base", "city_name":"Andersen,Mariana Island"},
 {"airport_iata_code":"GUM", "airport_name":"Antonio B. Won Pat International Airport", "city_name":"Hag\u00e5t\u00f1a, Guam International Ai"},
 {"airport_iata_code":"BQE", "airport_name":"Bubaque Airport", "city_name":"Bubaque"},
 {"airport_iata_code":"OXB", "airport_name":"Osvaldo Vieira International Airport", "city_name":"Bissau"},
 {"airport_iata_code":"KIA", "airport_name":"Kaieteur International Airport", "city_name":"Kaieteur Falls"},
 {"airport_iata_code":"AHL", "airport_name":"Aishalton Airport", "city_name":"Aishalton"},
 {"airport_iata_code":"NAI", "airport_name":"Annai Airport", "city_name":"Annai"},
 {"airport_iata_code":"BMJ", "airport_name":"Baramita Airport", "city_name":"Baramita"},
 {"airport_iata_code":"GFO", "airport_name":"Bartica A Airport", "city_name":"Bartica"},
 {"airport_iata_code":"GEO", "airport_name":"Cheddi Jagan International Airport", "city_name":"Georgetown"},
 {"airport_iata_code":"OGL", "airport_name":"Ogle Airport", "city_name":"Ogle"},
 {"airport_iata_code":"IMB", "airport_name":"Imbaimadai Airport", "city_name":"Imbaimadai"},
 {"airport_iata_code":"KAR", "airport_name":"Kamarang Airport", "city_name":"Kamarang"},
 {"airport_iata_code":"KRM", "airport_name":"Karanambo Airport", "city_name":"Karanambo"},
 {"airport_iata_code":"KRG", "airport_name":"Karasabai Airport", "city_name":"Karasabai"},
 {"airport_iata_code":"KTO", "airport_name":"Kato Airport", "city_name":"Kato"},
 {"airport_iata_code":"LUB", "airport_name":"Lumid Pau Airport", "city_name":"Lumid Pau"},
 {"airport_iata_code":"LTM", "airport_name":"Lethem Airport", "city_name":"Lethem"},
 {"airport_iata_code":"USI", "airport_name":"Mabaruma Airport", "city_name":"Mabaruma"},
 {"airport_iata_code":"MHA", "airport_name":"Mahdia Airport", "city_name":"Mahdia"},
 {"airport_iata_code":"MYM", "airport_name":"Monkey Mountain Airport", "city_name":"Monkey Mountain"},
 {"airport_iata_code":"MWJ", "airport_name":"Matthews Ridge Airport", "city_name":"Matthews Ridge"},
 {"airport_iata_code":"QSX", "airport_name":"New Amsterdam Airport", "city_name":"New Amsterdam"},
 {"airport_iata_code":"ORJ", "airport_name":"Orinduik Airport", "city_name":"Orinduik"},
 {"airport_iata_code":"PRR", "airport_name":"Paruma Airport", "city_name":"Paruma"},
 {"airport_iata_code":"BCG", "airport_name":"Bemichi Airport", "city_name":"Bemichi"},
 {"airport_iata_code":"HKG", "airport_name":"Chek Lap Kok International Airport", "city_name":"Hong Kong"},
 {"airport_iata_code":"AHS", "airport_name":"Ahuas Airport", "city_name":"Ahuas"},
 {"airport_iata_code":"BHG", "airport_name":"Brus Laguna Airport", "city_name":"Brus Laguna"},
 {"airport_iata_code":"CAA", "airport_name":"Catacamas Airport", "city_name":"Catacamas"},
 {"airport_iata_code":"CDD", "airport_name":"Cauquira Airport", "city_name":"Cauquira"},
 {"airport_iata_code":"JUT", "airport_name":"Jutigalpa airport", "city_name":"Jutigalpa"},
 {"airport_iata_code":"LCE", "airport_name":"Goloson International Airport", "city_name":"La Ceiba"},
 {"airport_iata_code":"LEZ", "airport_name":"La Esperanza Airport", "city_name":"La Esperanza"},
 {"airport_iata_code":"SAP", "airport_name":"Ram\u00f3n Villeda Morales International Airport", "city_name":"La Mesa"},
 {"airport_iata_code":"GJA", "airport_name":"La Laguna Airport", "city_name":"Guanaja"},
 {"airport_iata_code":"PEU", "airport_name":"Puerto Lempira Airport", "city_name":"Puerto Lempira"},
 {"airport_iata_code":"RTB", "airport_name":"Juan Manuel Galvez International Airport", "city_name":"Roatan Island"},
 {"airport_iata_code":"SDH", "airport_name":"Santa Rosa Cop\u00e1n Airport", "city_name":"Santa Rosa de Cop\u00e1n"},
 {"airport_iata_code":"RUY", "airport_name":"Copan Ruinas Airport", "city_name":"Ruinas de Copan"},
 {"airport_iata_code":"XPL", "airport_name":"Coronel Enrique Soto Cano Air Base", "city_name":"Comayagua"},
 {"airport_iata_code":"TEA", "airport_name":"Tela Airport", "city_name":"Tela"},
 {"airport_iata_code":"TGU", "airport_name":"Toncont\u00edn International Airport", "city_name":"Tegucigalpa"},
 {"airport_iata_code":"TJI", "airport_name":"Trujillo Airport", "city_name":"Trujillo"},
 {"airport_iata_code":"SCD", "airport_name":"Sulaco Airport", "city_name":"Sulaco"},
 {"airport_iata_code":"UII", "airport_name":"Utila Airport", "city_name":"Utila Island"},
 {"airport_iata_code":"DBV", "airport_name":"Dubrovnik Airport", "city_name":"Dubrovnik"},
 {"airport_iata_code":"LSZ", "airport_name":"Lo\u0161inj Island Airport", "city_name":"Lo\u0161inj"},
 {"airport_iata_code":"OSI", "airport_name":"Osijek Airport", "city_name":"Osijek"},
 {"airport_iata_code":"PUY", "airport_name":"Pula Airport", "city_name":"Pula"},
 {"airport_iata_code":"RJK", "airport_name":"Rijeka Airport", "city_name":"Rijeka"},
 {"airport_iata_code":"BWK", "airport_name":"Bol Airport", "city_name":"Brac Island"},
 {"airport_iata_code":"SPU", "airport_name":"Split Airport", "city_name":"Split"},
 {"airport_iata_code":"ZAG", "airport_name":"Zagreb Airport", "city_name":"Zagreb"},
 {"airport_iata_code":"ZAD", "airport_name":"Zemunik Airport", "city_name":"Zadar"},
 {"airport_iata_code":"CYA", "airport_name":"Les Cayes Airport", "city_name":"Les Cayes"},
 {"airport_iata_code":"CAP", "airport_name":"Cap Haitien International Airport", "city_name":"Cap Haitien"},
 {"airport_iata_code":"JAK", "airport_name":"Jacmel Airport", "city_name":"Jacmel"},
 {"airport_iata_code":"JEE", "airport_name":"J\u00e9r\u00e9mie Airport", "city_name":"Jeremie"},
 {"airport_iata_code":"PAP", "airport_name":"Toussaint Louverture International Airport", "city_name":"Port-au-Prince"},
 {"airport_iata_code":"PAX", "airport_name":"Port-de-Paix Airport", "city_name":"Port-de-Paix"},
 {"airport_iata_code":"BUD", "airport_name":"Budapest Listz Ferenc international Airport", "city_name":"Budapest"},
 {"airport_iata_code":"DEB", "airport_name":"Debrecen International Airport", "city_name":"Debrecen"},
 {"airport_iata_code":"MCQ", "airport_name":"Miskolc Airport", "city_name":"Miskolc"},
 {"airport_iata_code":"PEV", "airport_name":"P\u00e9cs-Pog\u00e1ny Airport", "city_name":"P\u00e9cs-Pog\u00e1ny"},
 {"airport_iata_code":"QGY", "airport_name":"Gy?r-P\u00e9r International Airport", "city_name":"Gy?r"},
 {"airport_iata_code":"SOB", "airport_name":"S\u00e1rmell\u00e9k International Airport", "city_name":"S\u00e1rmell\u00e9k"},
 {"airport_iata_code":"TZR", "airport_name":"Tasz\u00e1r Air Base", "city_name":"Tasz\u00e1r"},
 {"airport_iata_code":"QZD", "airport_name":"Szeged Glider Airport", "city_name":"Szeged"},
 {"airport_iata_code":"AAS", "airport_name":"Apalapsili Airport", "city_name":"Apalapsili-Papua Island"},
 {"airport_iata_code":"AGD", "airport_name":"Anggi Airport", "city_name":"Anggi-Papua Island"},
 {"airport_iata_code":"AKQ", "airport_name":"Gunung Batin Airport", "city_name":"Astraksetra-Sumatra Island"},
 {"airport_iata_code":"AYW", "airport_name":"Ayawasi Airport", "city_name":"Ayawasi-Papua Island"},
 {"airport_iata_code":"BJG", "airport_name":"Boalang Airport", "city_name":"Boalang-Celebes Island"},
 {"airport_iata_code":"BXM", "airport_name":"Batom Airport", "city_name":"Batom-Papua Island"},
 {"airport_iata_code":"DRH", "airport_name":"Dabra Airport", "city_name":"Dabra-Papua Island"},
 {"airport_iata_code":"ELR", "airport_name":"Elelim Airport", "city_name":"Elelim-Papua Island"},
 {"airport_iata_code":"EWE", "airport_name":"Ewer Airport", "city_name":"Ewer-Papua Island"},
 {"airport_iata_code":"FOO", "airport_name":"Kornasoren Airfield", "city_name":"Kornasoren-Numfoor Island"},
 {"airport_iata_code":"GAV", "airport_name":"Gag Island Airport", "city_name":"Gag Island"},
 {"airport_iata_code":"IUL", "airport_name":"Ilu Airport", "city_name":"Ilu-Papua Island"},
 {"airport_iata_code":"KBF", "airport_name":"Karubaga Airport", "city_name":"Karubaga-Papua Island"},
 {"airport_iata_code":"KBX", "airport_name":"Kambuaya Airport", "city_name":"Kambuaya-Papua Island"},
 {"airport_iata_code":"KCD", "airport_name":"Kamur Airport", "city_name":"Kamur-Papua Island"},
 {"airport_iata_code":"KEA", "airport_name":"Keisah Airport", "city_name":"Keisah-Papua Island"},
 {"airport_iata_code":"KOD", "airport_name":"Kotabangun Airport", "city_name":"Kotabangun-Borneo Island"},
 {"airport_iata_code":"KRC", "airport_name":"Kerinici airport", "city_name":"Kerinici-Sumatra Island"},
 {"airport_iata_code":"KWB", "airport_name":"Karimunjawa Airport", "city_name":"Karimunjawa-Karimunjawa Island"},
 {"airport_iata_code":"LLN", "airport_name":"Kelila Airport", "city_name":"Kelila-Papua Island"},
 {"airport_iata_code":"LWE", "airport_name":"Lewoleba Airport", "city_name":"Lewoleba-Lembata Island"},
 {"airport_iata_code":"LYK", "airport_name":"Lunyuk Airport", "city_name":"Lunyuk-Simbawa Island"},
 {"airport_iata_code":"MJY", "airport_name":"Mangunjaya Airport", "city_name":"Mangungaya-Sumatra Island"},
 {"airport_iata_code":"MPT", "airport_name":"Maliana airport", "city_name":"Maliana-Alor Island"},
 {"airport_iata_code":"MSI", "airport_name":"Masalembo Airport", "city_name":"Masalembo Island"},
 {"airport_iata_code":"MUF", "airport_name":"Muting Airport", "city_name":"Muting-Papua Island"},
 {"airport_iata_code":"NAF", "airport_name":"Banaina Airport", "city_name":"Banaina-Borneo Island"},
 {"airport_iata_code":"NDA", "airport_name":"Bandanaira Airport", "city_name":"Bandanaira-Naira Island"},
 {"airport_iata_code":"OBD", "airport_name":"Obano Airport", "city_name":"Obano-Papua Island"},
 {"airport_iata_code":"PPJ", "airport_name":"Pulau Panjang Airport", "city_name":"Pulau Panjang-Sumatra Island"},
 {"airport_iata_code":"PUM", "airport_name":"Pomala Airport", "city_name":"Tambea-Celebes Island"},
 {"airport_iata_code":"PWL", "airport_name":"Purwokerto Airport", "city_name":"Purwokerto-Java Island"},
 {"airport_iata_code":"RAQ", "airport_name":"Sugimanuru Airport", "city_name":"Raha-Muna Island"},
 {"airport_iata_code":"RKI", "airport_name":"Rokot Airport", "city_name":"Rokot-Sumatra Island"},
 {"airport_iata_code":"RTI", "airport_name":"Roti Airport", "city_name":"Roti-Rote Island"},
 {"airport_iata_code":"RUF", "airport_name":"Yuruf Airport", "city_name":"Yuruf-Papua Island"},
 {"airport_iata_code":"SAE", "airport_name":"Sangir Airport", "city_name":"Sangir-Simbawa Island"},
 {"airport_iata_code":"TBM", "airport_name":"Tumbang Samba Airport", "city_name":"Tumbang Samba-Borneo Island"},
 {"airport_iata_code":"TMY", "airport_name":"Tiom Airport", "city_name":"Tiom-Papua Island"},
 {"airport_iata_code":"ZEG", "airport_name":"Senggo Airport", "city_name":"Senggo-Papua Island"},
 {"airport_iata_code":"ZGP", "airport_name":"Zugapa Airport", "city_name":"Zugapa-Papua Island"},
 {"airport_iata_code":"KMM", "airport_name":"Kimaam Airport", "city_name":"Kimaam"},
 {"airport_iata_code":"UPG", "airport_name":"Hasanuddin International Airport", "city_name":"Ujung Pandang-Celebes Island"},
 {"airport_iata_code":"MJU", "airport_name":"Tampa Padang Airport", "city_name":"Mamuju-Celebes Island"},
 {"airport_iata_code":"BIK", "airport_name":"Frans Kaisiepo Airport", "city_name":"Biak-Supiori Island"},
 {"airport_iata_code":"ONI", "airport_name":"Moanamani Airport", "city_name":"Moanamani-Papua Island"},
 {"airport_iata_code":"WET", "airport_name":"Wagethe Airport", "city_name":"Wagethe-Papua Island"},
 {"airport_iata_code":"NBX", "airport_name":"Nabire Airport", "city_name":"Nabire-Papua Island"},
 {"airport_iata_code":"ILA", "airport_name":"Illaga Airport", "city_name":"Illaga-Papua Island"},
 {"airport_iata_code":"KOX", "airport_name":"Kokonau Airport", "city_name":"Kokonau-Papua Island"},
 {"airport_iata_code":"ZRI", "airport_name":"Serui Airport", "city_name":"Serui-Japen Island"},
 {"airport_iata_code":"TIM", "airport_name":"Moses Kilangin Airport", "city_name":"Timika-Papua Island"},
 {"airport_iata_code":"EWI", "airport_name":"Enarotali Airport", "city_name":"Enarotali-Papua Island"},
 {"airport_iata_code":"AMI", "airport_name":"Selaparang Airport", "city_name":"Mataram-Lombok Island"},
 {"airport_iata_code":"BMU", "airport_name":"Muhammad Salahuddin Airport", "city_name":"Bima-Sumbawa Island"},
 {"airport_iata_code":"DPS", "airport_name":"Ngurah Rai (Bali) International Airport", "city_name":"Denpasar-Bali Island"},
 {"airport_iata_code":"LOP", "airport_name":"Bandara International Lombok Airport", "city_name":"Mataram"},
 {"airport_iata_code":"SWQ", "airport_name":"Sumbawa Besar Airport", "city_name":"Sumbawa Island"},
 {"airport_iata_code":"TMC", "airport_name":"Tambolaka Airport", "city_name":"Waikabubak-Sumba Island"},
 {"airport_iata_code":"WGP", "airport_name":"Waingapu Airport", "city_name":"Waingapu-Sumba Island"},
 {"airport_iata_code":"ARJ", "airport_name":"Arso Airport", "city_name":"Arso-Papua Island"},
 {"airport_iata_code":"BUI", "airport_name":"Bokondini Airport", "city_name":"Bokondini-Papua Island"},
 {"airport_iata_code":"ZRM", "airport_name":"Sarmi Airport", "city_name":"Sarmi-Papua Island"},
 {"airport_iata_code":"DJJ", "airport_name":"Sentani Airport", "city_name":"Jayapura-Papua Island"},
 {"airport_iata_code":"LHI", "airport_name":"Lereh Airport", "city_name":"Lereh-Papua Island"},
 {"airport_iata_code":"LII", "airport_name":"Mulia Airport", "city_name":"Mulia-Papua Island"},
 {"airport_iata_code":"OKL", "airport_name":"Oksibil Airport", "city_name":"Oksibil-Papua Island"},
 {"airport_iata_code":"WAR", "airport_name":"Waris Airport", "city_name":"Waris-Papua Island"},
 {"airport_iata_code":"SEH", "airport_name":"Senggeh Airport", "city_name":"Senggeh-Papua Island"},
 {"airport_iata_code":"UBR", "airport_name":"Ubrub Airport", "city_name":"Ubrub-Papua Island"},
 {"airport_iata_code":"WMX", "airport_name":"Wamena Airport", "city_name":"Wamena-Papua Island"},
 {"airport_iata_code":"MDP", "airport_name":"Mindiptana Airport", "city_name":"Mindiptana-Papua Island"},
 {"airport_iata_code":"BXD", "airport_name":"Bade Airport", "city_name":"Bade-Papua Island"},
 {"airport_iata_code":"MKQ", "airport_name":"Mopah Airport", "city_name":"Merauke-Papua Island"},
 {"airport_iata_code":"OKQ", "airport_name":"Okaba Airport", "city_name":"Okaba-Papua Island"},
 {"airport_iata_code":"KEI", "airport_name":"Kepi Airport", "city_name":"Kepi-Papua Island"},
 {"airport_iata_code":"TMH", "airport_name":"Tanah Merah Airport", "city_name":"Tanah Merah-Papua Island"},
 {"airport_iata_code":"TJS", "airport_name":"Tanjung Harapan Airport", "city_name":"Tanjung Selor-Borneo Island"},
 {"airport_iata_code":"DTD", "airport_name":"Datadawai Airport", "city_name":"Datadawai-Borneo Island"},
 {"airport_iata_code":"BEJ", "airport_name":"Barau(Kalimaru) Airport", "city_name":"Tanjung Redep-Borneo Island"},
 {"airport_iata_code":"BPN", "airport_name":"Sepinggan International Airport", "city_name":"Balikpapan-Borneo Island"},
 {"airport_iata_code":"TRK", "airport_name":"Juwata Airport", "city_name":"Tarakan Island"},
 {"airport_iata_code":"SRI", "airport_name":"Temindung Airport", "city_name":"Samarinda-Borneo Island"},
 {"airport_iata_code":"TSX", "airport_name":"Tanjung Santan Airport", "city_name":"Santan-Borneo Island"},
 {"airport_iata_code":"BYQ", "airport_name":"Bunyu Airport", "city_name":"Bunju Island"},
 {"airport_iata_code":"GLX", "airport_name":"Gamarmalamo Airport", "city_name":"Galela-Celebes Island"},
 {"airport_iata_code":"GTO", "airport_name":"Jalaluddin Airport", "city_name":"Gorontalo-Celebes Island"},
 {"airport_iata_code":"NAH", "airport_name":"Naha Airport", "city_name":"Tahuna-Sangihe Island"},
 {"airport_iata_code":"TLI", "airport_name":"Toli Toli Airport", "city_name":"Toli Toli-Celebes Island"},
 {"airport_iata_code":"GEB", "airport_name":"Gebe Airport", "city_name":"Gebe Island"},
 {"airport_iata_code":"KAZ", "airport_name":"Kao Airport", "city_name":"Kao-Celebes Island"},
 {"airport_iata_code":"PLW", "airport_name":"Mutiara Airport", "city_name":"Palu-Celebes Island"},
 {"airport_iata_code":"MDC", "airport_name":"Sam Ratulangi Airport", "city_name":"Manado-Celebes Island"},
 {"airport_iata_code":"MNA", "airport_name":"Melangguane Airport", "city_name":"Karakelong Island"},
 {"airport_iata_code":"PSJ", "airport_name":"Kasiguncu Airport", "city_name":"Poso-Celebes Island"},
 {"airport_iata_code":"OTI", "airport_name":"Pitu Airport", "city_name":"Gotalalamo-Morotai Island"},
 {"airport_iata_code":"TTE", "airport_name":"Sultan Khairun Babullah Airport", "city_name":"Sango-Ternate Island"},
 {"airport_iata_code":"LUW", "airport_name":"Bubung Airport", "city_name":"Luwok-Celebes Island"},
 {"airport_iata_code":"UOL", "airport_name":"Buol Airport", "city_name":"Buol-Celebes Island"},
 {"airport_iata_code":"BTW", "airport_name":"Batu Licin Airport", "city_name":"Batu Licin-Borneo Island"},
 {"airport_iata_code":"PKN", "airport_name":"Iskandar Airport", "city_name":"Pangkalanbun-Borneo Island"},
 {"airport_iata_code":"KBU", "airport_name":"Stagen Airport", "city_name":"Laut Island"},
 {"airport_iata_code":"MTW", "airport_name":"Beringin Airport", "city_name":"Murateweh-Borneo Island"},
 {"airport_iata_code":"TJG", "airport_name":"Warukin Airport", "city_name":"Tanta-Tabalong-Borneo Island"},
 {"airport_iata_code":"BDJ", "airport_name":"Syamsudin Noor Airport", "city_name":"Banjarmasin-Borneo Island"},
 {"airport_iata_code":"PKY", "airport_name":"Tjilik Riwut Airport", "city_name":"Palangkaraya-Kalimantan Tengah"},
 {"airport_iata_code":"SMQ", "airport_name":"Sampit(Hasan) Airport", "city_name":"Sampit-Borneo Island"},
 {"airport_iata_code":"AHI", "airport_name":"Amahai Airport", "city_name":"Amahai-Seram Island"},
 {"airport_iata_code":"DOB", "airport_name":"Dobo Airport", "city_name":"Dobo-Kobror Island"},
 {"airport_iata_code":"MAL", "airport_name":"Mangole Airport, Falabisahaya", "city_name":"Mangole Island"},
 {"airport_iata_code":"NRE", "airport_name":"Namrole Airport", "city_name":"Namrole-Buru Island"},
 {"airport_iata_code":"LAH", "airport_name":"Oesman Sadik Airport, Labuha", "city_name":"Labuha-Halmahera Island"},
 {"airport_iata_code":"SXK", "airport_name":"Saumlaki Airport", "city_name":"Saumlaki-Yamdena Island"},
 {"airport_iata_code":"BJK", "airport_name":"Nangasuri Airport", "city_name":"Maikoor Island"},
 {"airport_iata_code":"LUV", "airport_name":"Dumatumbun Airport", "city_name":"Langgur-Seram Island"},
 {"airport_iata_code":"SQN", "airport_name":"Emalamo Sanana Airport", "city_name":"Sanana-Seram Island"},
 {"airport_iata_code":"AMQ", "airport_name":"Pattimura Airport, Ambon", "city_name":"Ambon"},
 {"airport_iata_code":"NAM", "airport_name":"Namlea Airport", "city_name":"Namlea-Buru Island"},
 {"airport_iata_code":"TAX", "airport_name":"Taliabu Island Airport", "city_name":"Tikong-Taliabu Island"},
 {"airport_iata_code":"MLG", "airport_name":"Abdul Rachman Saleh Airport", "city_name":"Malang-Java Island"},
 {"airport_iata_code":"CPF", "airport_name":"Cepu Airport", "city_name":"Tjepu-Java Island"},
 {"airport_iata_code":"JOG", "airport_name":"Adi Sutjipto International Airport", "city_name":"Yogyakarta-Java Island"},
 {"airport_iata_code":"SOC", "airport_name":"Adi Sumarmo Wiryokusumo Airport", "city_name":"Sukarata(Solo)-Java Island"},
 {"airport_iata_code":"SUB", "airport_name":"Juanda International Airport", "city_name":"Surabaya"},
 {"airport_iata_code":"SRG", "airport_name":"Achmad Yani Airport", "city_name":"Semarang-Java Island"},
 {"airport_iata_code":"SUP", "airport_name":"Trunojoyo Airport", "city_name":"Sumenep-Madura Island"},
 {"airport_iata_code":"NTI", "airport_name":"Stenkol Airport", "city_name":"Bintuni-Papua Island"},
 {"airport_iata_code":"RSK", "airport_name":"Abresso Airport", "city_name":"Ransiki-Papua Island"},
 {"airport_iata_code":"KEQ", "airport_name":"Kebar Airport", "city_name":"Kebar-Papua Island"},
 {"airport_iata_code":"FKQ", "airport_name":"Fakfak Airport", "city_name":"Fakfak-Papua Island"},
 {"airport_iata_code":"INX", "airport_name":"Inanwatan Airport", "city_name":"Inanwatan Airport-Papua Island"},
 {"airport_iata_code":"KNG", "airport_name":"Kaimana Airport", "city_name":"Kaimana-Papua Island"},
 {"airport_iata_code":"RDE", "airport_name":"Merdei Airport", "city_name":"Merdei-Papua Island"},
 {"airport_iata_code":"BXB", "airport_name":"Babo Airport", "city_name":"Babo-Papua Island"},
 {"airport_iata_code":"MKW", "airport_name":"Rendani Airport", "city_name":"Manokwari-Papua Island"},
 {"airport_iata_code":"SOQ", "airport_name":"Sorong (Jefman) Airport", "city_name":"Sorong-Papua Island"},
 {"airport_iata_code":"TXM", "airport_name":"Teminabuan Airport", "city_name":"Atinjoe-Papua Island"},
 {"airport_iata_code":"WSR", "airport_name":"Wasior Airport", "city_name":"Wasior-Papua Island"},
 {"airport_iata_code":"BJW", "airport_name":"Bajawa Soa Airport", "city_name":"Bajawa"},
 {"airport_iata_code":"MOF", "airport_name":"Maumere(Wai Oti) Airport", "city_name":"Maumere-Flores Island"},
 {"airport_iata_code":"ENE", "airport_name":"Ende (H Hasan Aroeboesman) Airport", "city_name":"Ende-Flores Island"},
 {"airport_iata_code":"RTG", "airport_name":"Satar Tacik Airport", "city_name":"Satar Tacik-Flores Island"},
 {"airport_iata_code":"ARD", "airport_name":"Mali Airport", "city_name":"Alor Island"},
 {"airport_iata_code":"LBJ", "airport_name":"Komodo (Mutiara II) Airport", "city_name":"Labuan Bajo-Flores Island"},
 {"airport_iata_code":"KOE", "airport_name":"El Tari Airport", "city_name":"Kupang-Timor Island"},
 {"airport_iata_code":"BUW", "airport_name":"Betoambari Airport", "city_name":"Bau Bau-Butung Island"},
 {"airport_iata_code":"MXB", "airport_name":"Andi Jemma Airport", "city_name":"Masamba-Celebes Island"},
 {"airport_iata_code":"SQR", "airport_name":"Soroako Airport", "city_name":"Soroako-Celebes Island"},
 {"airport_iata_code":"TTR", "airport_name":"Pongtiku Airport", "city_name":"Tanah Toraja-Celebes Island"},
 {"airport_iata_code":"KDI", "airport_name":"Wolter Monginsidi Airport", "city_name":"Kendari-Celebes Island"},
 {"airport_iata_code":"TKG", "airport_name":"Radin Inten II (Branti) Airport", "city_name":"Bandar Lampung-Sumatra Island"},
 {"airport_iata_code":"PKU", "airport_name":"Sultan Syarif Kasim Ii (Simpang Tiga) Airport", "city_name":"Pekanbaru-Sumatra Island"},
 {"airport_iata_code":"DUM", "airport_name":"Pinang Kampai Airport", "city_name":"Dumai-Sumatra Island"},
 {"airport_iata_code":"RKO", "airport_name":"Rokot Airport", "city_name":"Sipora Island"},
 {"airport_iata_code":"SEQ", "airport_name":"Sungai Pakning Bengkalis Airport", "city_name":"Bengkalis-Sumatra Island"},
 {"airport_iata_code":"TJB", "airport_name":"Tanjung Balai Airport", "city_name":"Karinmunbesar Island"},
 {"airport_iata_code":"BDO", "airport_name":"Husein Sastranegara International Airport", "city_name":"Bandung-Java Island"},
 {"airport_iata_code":"CBN", "airport_name":"Penggung Airport", "city_name":"Cirebon-Java Island"},
 {"airport_iata_code":"TSY", "airport_name":"Cibeureum Airport", "city_name":"Tasikmalaya-Java Island"},
 {"airport_iata_code":"BTH", "airport_name":"Hang Nadim Airport", "city_name":"Batam Island"},
 {"airport_iata_code":"PPR", "airport_name":"Pasir Pangaraan Airport", "city_name":"Pasir Pengarayan-Sumatra Islan"},
 {"airport_iata_code":"TNJ", "airport_name":"Kijang Airport", "city_name":"Tanjung Pinang-Bintan Island"},
 {"airport_iata_code":"SIQ", "airport_name":"Dabo Airport", "city_name":"Pasirkuning-Singkep Island"},
 {"airport_iata_code":"HLP", "airport_name":"Halim Perdanakusuma International Airport", "city_name":"Jakarta"},
 {"airport_iata_code":"CXP", "airport_name":"Tunggul Wulung Airport", "city_name":"Cilacap-Java Island"},
 {"airport_iata_code":"PCB", "airport_name":"Pondok Cabe Air Base", "city_name":"Jakarta"},
 {"airport_iata_code":"CGK", "airport_name":"Soekarno-Hatta International Airport", "city_name":"Jakarta"},
 {"airport_iata_code":"GNS", "airport_name":"Binaka Airport", "city_name":"Gunung Sitoli-Nias Island"},
 {"airport_iata_code":"AEG", "airport_name":"Aek Godang Airport", "city_name":"Padang Sidempuan-Sumatra Islan"},
 {"airport_iata_code":"MES", "airport_name":"Polonia International Airport", "city_name":"Medan-Sumatra Island"},
 {"airport_iata_code":"TJQ", "airport_name":"Buluh Tumbang (H A S Hanandjoeddin) Airport", "city_name":"Tanjung Pandan-Belitung Island"},
 {"airport_iata_code":"NPO", "airport_name":"Nanga Pinoh I Airport", "city_name":"Nanga Pinoh-Borneo Island"},
 {"airport_iata_code":"KTG", "airport_name":"Ketapang(Rahadi Usman) Airport", "city_name":"Ketapang-Borneo Island"},
 {"airport_iata_code":"MWK", "airport_name":"Tarempa Airport", "city_name":"Matak Island"},
 {"airport_iata_code":"NTX", "airport_name":"Ranai Airport", "city_name":"Ranai-Natuna Besar Island"},
 {"airport_iata_code":"PNK", "airport_name":"Supadio Airport", "city_name":"Pontianak-Borneo Island"},
 {"airport_iata_code":"PSU", "airport_name":"Pangsuma Airport", "city_name":"Putussibau-Borneo Island"},
 {"airport_iata_code":"SQG", "airport_name":"Sintang(Susilo) Airport", "city_name":"Sintang-Borneo Island"},
 {"airport_iata_code":"DJB", "airport_name":"Sultan Thaha Airport", "city_name":"Jambi-Sumatra Island"},
 {"airport_iata_code":"PGK", "airport_name":"Pangkal Pinang (Depati Amir) Airport", "city_name":"Pangkal Pinang-Palaubangka Isl"},
 {"airport_iata_code":"BKS", "airport_name":"Padang Kemiling (Fatmawati Soekarno) Airport", "city_name":"Bengkulu-Sumatra Island"},
 {"airport_iata_code":"PLM", "airport_name":"Sultan Mahmud Badaruddin Ii Airport", "city_name":"Palembang-Sumatra Island"},
 {"airport_iata_code":"PDO", "airport_name":"Pendopo Airport", "city_name":"Talang Gudang-Sumatra Island"},
 {"airport_iata_code":"RGT", "airport_name":"Japura Airport", "city_name":"Rengat-Sumatra Island"},
 {"airport_iata_code":"PDG", "airport_name":"Minangkabau Airport", "city_name":"Ketaping/Padang-Sumatra Island"},
 {"airport_iata_code":"MPC", "airport_name":"Muko Muko Airport", "city_name":"Muko Muko-Sumatra Island"},
 {"airport_iata_code":"KLQ", "airport_name":"Keluang Airport", "city_name":"Keluang-Sumatra Island"},
 {"airport_iata_code":"TPK", "airport_name":"Teuku Cut Ali Airport", "city_name":"Tapak Tuan-Sumatra Island"},
 {"airport_iata_code":"SBG", "airport_name":"Maimun Saleh Airport", "city_name":"Sabang-We Island"},
 {"airport_iata_code":"MEQ", "airport_name":"Seunagan Airport", "city_name":"Peureumeue-Sumatra Island"},
 {"airport_iata_code":"LSX", "airport_name":"Lhok Sukon Airport", "city_name":"Lhok Sukon-Sumatra Island"},
 {"airport_iata_code":"LSW", "airport_name":"Malikus Saleh Airport", "city_name":"Lhok Seumawe-Sumatra Island"},
 {"airport_iata_code":"BTJ", "airport_name":"Sultan Iskandarmuda Airport", "city_name":"Banda Aceh-Sumatra Island"},
 {"airport_iata_code":"ABU", "airport_name":"Haliwen Airport", "city_name":"Atambua-Timor Island"},
 {"airport_iata_code":"LKA", "airport_name":"Gewayentana Airport", "city_name":"Larantuka-Flores Island"},
 {"airport_iata_code":"SAU", "airport_name":"Sawu Airport", "city_name":"Sawu-Sawu Island"},
 {"airport_iata_code":"SGQ", "airport_name":"Sanggata Airport", "city_name":"Sanggata-Timor Island"},
 {"airport_iata_code":"LBW", "airport_name":"Long Bawan Airport", "city_name":"Long Bawan-Borneo Island"},
 {"airport_iata_code":"BXT", "airport_name":"Bontang Airport", "city_name":"Bontang-Borneo Island"},
 {"airport_iata_code":"NNX", "airport_name":"Nunukan Airport", "city_name":"Nunukan-Nunukan Island"},
 {"airport_iata_code":"TNB", "airport_name":"Tanah Grogot Airport", "city_name":"Tanah Grogot-Borneo Island"},
 {"airport_iata_code":"LPU", "airport_name":"Long Apung Airport", "city_name":"Long Apung-Borneo Island"},
 {"airport_iata_code":"SZH", "airport_name":"Senipah Airport", "city_name":"Senipah-Borneo Island"},
 {"airport_iata_code":"BYT", "airport_name":"Bantry Aerodrome", "city_name":"Bantry"},
 {"airport_iata_code":"BLY", "airport_name":"Belmullet Aerodrome", "city_name":"Belmullet"},
 {"airport_iata_code":"NNR", "airport_name":"Connemara Regional Airport", "city_name":"Inverin"},
 {"airport_iata_code":"CLB", "airport_name":"Castlebar Airport", "city_name":"Castlebar"},
 {"airport_iata_code":"WEX", "airport_name":"Castlebridge Airport", "city_name":"Wexford"},
 {"airport_iata_code":"ORK", "airport_name":"Cork Airport", "city_name":"Cork"},
 {"airport_iata_code":"GWY", "airport_name":"Galway Airport", "city_name":"Galway"},
 {"airport_iata_code":"CFN", "airport_name":"Donegal Airport", "city_name":"Donegal"},
 {"airport_iata_code":"DUB", "airport_name":"Dublin Airport", "city_name":"Dublin"},
 {"airport_iata_code":"INQ", "airport_name":"Inisheer Aerodrome", "city_name":"Inis O\u00edrr"},
 {"airport_iata_code":"KKY", "airport_name":"Kilkenny Airport", "city_name":"Kilkenny"},
 {"airport_iata_code":"NOC", "airport_name":"Ireland West Knock Airport", "city_name":"Charleston"},
 {"airport_iata_code":"KIR", "airport_name":"Kerry Airport", "city_name":"Killarney"},
 {"airport_iata_code":"LTR", "airport_name":"Letterkenny Airport", "city_name":"Letterkenny"},
 {"airport_iata_code":"IIA", "airport_name":"Inishmaan Aerodrome", "city_name":"Inis Me\u00e1in"},
 {"airport_iata_code":"SNN", "airport_name":"Shannon Airport", "city_name":"Shannon"},
 {"airport_iata_code":"SXL", "airport_name":"Sligo Airport", "city_name":"Sligo"},
 {"airport_iata_code":"WAT", "airport_name":"Waterford Airport", "city_name":"Waterford"},
 {"airport_iata_code":"IOR", "airport_name":"Kilronan Airport", "city_name":"Inishmore"},
 {"airport_iata_code":"CHE", "airport_name":"Reeroe Airport", "city_name":"Caherciveen"},
 {"airport_iata_code":"TLV", "airport_name":"Ben Gurion International Airport", "city_name":"Tel Aviv"},
 {"airport_iata_code":"BEV", "airport_name":"Be'er Sheva (Teyman) Airport", "city_name":"Beersheva"},
 {"airport_iata_code":"ETH", "airport_name":"Eilat Airport", "city_name":"Eilat"},
 {"airport_iata_code":"EIY", "airport_name":"Ein Yahav Airfield", "city_name":"Sapir"},
 {"airport_iata_code":"HFA", "airport_name":"Haifa International Airport", "city_name":"Haifa"},
 {"airport_iata_code":"RPN", "airport_name":"Ben Ya'akov Airport", "city_name":"Rosh Pina"},
 {"airport_iata_code":"KSW", "airport_name":"Kiryat Shmona Airport", "city_name":"Kiryat Shmona"},
 {"airport_iata_code":"MTZ", "airport_name":"Bar Yehuda Airfield", "city_name":"Masada"},
 {"airport_iata_code":"VTM", "airport_name":"Nevatim Air Base", "city_name":"Beersheba"},
 {"airport_iata_code":"VDA", "airport_name":"Ovda International Airport", "city_name":"Eilat"},
 {"airport_iata_code":"MIP", "airport_name":"Ramon Air Base", "city_name":"Beersheba"},
 {"airport_iata_code":"SDV", "airport_name":"Sde Dov Airport", "city_name":"Tel Aviv"},
 {"airport_iata_code":"YOT", "airport_name":"Yotvata Airfield", "city_name":"Yotvata"},
 {"airport_iata_code":"IOM", "airport_name":"Isle of Man Airport", "city_name":"Castletown"},
 {"airport_iata_code":"DIU", "airport_name":"Diu Airport", "city_name":"Diu"},
 {"airport_iata_code":"AMD", "airport_name":"Sardar Vallabhbhai Patel International Airport", "city_name":"Ahmedabad"},
 {"airport_iata_code":"AKD", "airport_name":"Akola Airport", "city_name":""},
 {"airport_iata_code":"IXU", "airport_name":"Aurangabad Airport", "city_name":"Aurangabad"},
 {"airport_iata_code":"BOM", "airport_name":"Chhatrapati Shivaji International Airport", "city_name":"Mumbai"},
 {"airport_iata_code":"PAB", "airport_name":"Bilaspur Airport", "city_name":""},
 {"airport_iata_code":"BHJ", "airport_name":"Bhuj Airport", "city_name":"Bhuj"},
 {"airport_iata_code":"IXG", "airport_name":"Belgaum Airport", "city_name":"Belgaum"},
 {"airport_iata_code":"BDQ", "airport_name":"Vadodara Airport", "city_name":"Vadodara"},
 {"airport_iata_code":"BHO", "airport_name":"Raja Bhoj International Airport", "city_name":"Bhopal"},
 {"airport_iata_code":"BHU", "airport_name":"Bhavnagar Airport", "city_name":"Bhavnagar"},
 {"airport_iata_code":"NMB", "airport_name":"Daman Airport", "city_name":""},
 {"airport_iata_code":"GUX", "airport_name":"Guna Airport", "city_name":""},
 {"airport_iata_code":"GOI", "airport_name":"Dabolim Airport", "city_name":"Vasco da Gama"},
 {"airport_iata_code":"HBX", "airport_name":"Hubli Airport", "city_name":"Hubli"},
 {"airport_iata_code":"IDR", "airport_name":"Devi Ahilyabai Holkar Airport", "city_name":"Indore"},
 {"airport_iata_code":"JLR", "airport_name":"Jabalpur Airport", "city_name":""},
 {"airport_iata_code":"JGA", "airport_name":"Jamnagar Airport", "city_name":"Jamnagar"},
 {"airport_iata_code":"IXY", "airport_name":"Kandla Airport", "city_name":"Kandla"},
 {"airport_iata_code":"HJR", "airport_name":"Khajuraho Airport", "city_name":"Khajuraho"},
 {"airport_iata_code":"KLH", "airport_name":"Kolhapur Airport", "city_name":""},
 {"airport_iata_code":"IXK", "airport_name":"Keshod Airport", "city_name":""},
 {"airport_iata_code":"NDC", "airport_name":"Nanded Airport", "city_name":"Nanded"},
 {"airport_iata_code":"NAG", "airport_name":"Dr. Babasaheb Ambedkar International Airport", "city_name":"Naqpur"},
 {"airport_iata_code":"ISK", "airport_name":"Gandhinagar Airport", "city_name":"Nashik"},
 {"airport_iata_code":"PNQ", "airport_name":"Pune Airport", "city_name":"Pune"},
 {"airport_iata_code":"PBD", "airport_name":"Porbandar Airport", "city_name":"Porbandar"},
 {"airport_iata_code":"RTC", "airport_name":"Ratnagiri Airport", "city_name":""},
 {"airport_iata_code":"RAJ", "airport_name":"Rajkot Airport", "city_name":"Rajkot"},
 {"airport_iata_code":"RPR", "airport_name":"Raipur Airport", "city_name":"Raipur"},
 {"airport_iata_code":"SSE", "airport_name":"Solapur Airport", "city_name":"Solapur"},
 {"airport_iata_code":"STV", "airport_name":"Surat Airport", "city_name":""},
 {"airport_iata_code":"UDR", "airport_name":"Maharana Pratap Airport", "city_name":"Udaipur"},
 {"airport_iata_code":"IXV", "airport_name":"Along Airport", "city_name":""},
 {"airport_iata_code":"IXA", "airport_name":"Agartala Airport", "city_name":"Agartala"},
 {"airport_iata_code":"IXB", "airport_name":"Bagdogra Airport", "city_name":"Siliguri"},
 {"airport_iata_code":"RGH", "airport_name":"Balurghat Airport", "city_name":"Balurghat"},
 {"airport_iata_code":"SHL", "airport_name":"Shillong Airport", "city_name":"Shillong"},
 {"airport_iata_code":"BBI", "airport_name":"Biju Patnaik Airport", "city_name":"Bhubaneswar"},
 {"airport_iata_code":"CCU", "airport_name":"Netaji Subhash Chandra Bose International Airport", "city_name":"Kolkata"},
 {"airport_iata_code":"COH", "airport_name":"Cooch Behar Airport", "city_name":""},
 {"airport_iata_code":"DBD", "airport_name":"Dhanbad Airport", "city_name":""},
 {"airport_iata_code":"DAE", "airport_name":"Daporijo Airport", "city_name":""},
 {"airport_iata_code":"GOP", "airport_name":"Gorakhpur Airport", "city_name":"Gorakhpur"},
 {"airport_iata_code":"GAU", "airport_name":"Lokpriya Gopinath Bordoloi International Airport", "city_name":"Guwahati"},
 {"airport_iata_code":"GAY", "airport_name":"Gaya Airport", "city_name":""},
 {"airport_iata_code":"IMF", "airport_name":"Imphal Airport", "city_name":"Imphal"},
 {"airport_iata_code":"PYB", "airport_name":"Jeypore Airport", "city_name":"Jeypore"},
 {"airport_iata_code":"IXW", "airport_name":"Jamshedpur Airport", "city_name":""},
 {"airport_iata_code":"JRH", "airport_name":"Jorhat Airport", "city_name":"Jorhat"},
 {"airport_iata_code":"IXQ", "airport_name":"Kamalpur Airport", "city_name":""},
 {"airport_iata_code":"IXH", "airport_name":"Kailashahar Airport", "city_name":""},
 {"airport_iata_code":"IXS", "airport_name":"Silchar Airport", "city_name":"Silchar"},
 {"airport_iata_code":"IXN", "airport_name":"Khowai Airport", "city_name":"Khowai"},
 {"airport_iata_code":"AJL", "airport_name":"Lengpui Airport", "city_name":"Aizawl"},
 {"airport_iata_code":"IXI", "airport_name":"North Lakhimpur Airport", "city_name":"Lilabari"},
 {"airport_iata_code":"LDA", "airport_name":"Malda Airport", "city_name":"Malda"},
 {"airport_iata_code":"DIB", "airport_name":"Dibrugarh Airport", "city_name":"Dibrugarh"},
 {"airport_iata_code":"DMU", "airport_name":"Dimapur Airport", "city_name":"Dimapur"},
 {"airport_iata_code":"MZU", "airport_name":"Muzaffarpur Airport", "city_name":""},
 {"airport_iata_code":"IXT", "airport_name":"Pasighat Airport", "city_name":"Pasighat"},
 {"airport_iata_code":"PAT", "airport_name":"Lok Nayak Jayaprakash Airport", "city_name":"Patna"},
 {"airport_iata_code":"IXR", "airport_name":"Birsa Munda Airport", "city_name":"Ranchi"},
 {"airport_iata_code":"RRK", "airport_name":"Rourkela Airport", "city_name":""},
 {"airport_iata_code":"RUP", "airport_name":"Rupsi India Airport", "city_name":""},
 {"airport_iata_code":"TEZ", "airport_name":"Tezpur Airport", "city_name":""},
 {"airport_iata_code":"VTZ", "airport_name":"Vishakhapatnam Airport", "city_name":"Visakhapatnam"},
 {"airport_iata_code":"ZER", "airport_name":"Zero Airport", "city_name":""},
 {"airport_iata_code":"AGR", "airport_name":"Agra Airport", "city_name":"Agra"},
 {"airport_iata_code":"IXD", "airport_name":"Allahabad Airport", "city_name":"Allahabad"},
 {"airport_iata_code":"ATQ", "airport_name":"Sri Guru Ram Dass Jee International Airport, Amritsar", "city_name":"Amritsar"},
 {"airport_iata_code":"BKB", "airport_name":"Nal Airport", "city_name":"Bikaner"},
 {"airport_iata_code":"VNS", "airport_name":"Lal Bahadur Shastri Airport", "city_name":"Varanasi"},
 {"airport_iata_code":"KUU", "airport_name":"Kullu Manali Airport", "city_name":""},
 {"airport_iata_code":"BUP", "airport_name":"Bhatinda Air Force Station", "city_name":""},
 {"airport_iata_code":"IXC", "airport_name":"Chandigarh Airport", "city_name":"Chandigarh"},
 {"airport_iata_code":"DED", "airport_name":"Dehradun Airport", "city_name":"Dehradun"},
 {"airport_iata_code":"DEL", "airport_name":"Indira Gandhi International Airport", "city_name":"New Delhi"},
 {"airport_iata_code":"DHM", "airport_name":"Kangra Airport", "city_name":""},
 {"airport_iata_code":"GWL", "airport_name":"Gwalior Airport", "city_name":"Gwalior"},
 {"airport_iata_code":"HSS", "airport_name":"Hissar Airport", "city_name":""},
 {"airport_iata_code":"JDH", "airport_name":"Jodhpur Airport", "city_name":"Jodhpur"},
 {"airport_iata_code":"JAI", "airport_name":"Jaipur International Airport", "city_name":"Jaipur"},
 {"airport_iata_code":"JSA", "airport_name":"Jaisalmer Airport", "city_name":""},
 {"airport_iata_code":"IXJ", "airport_name":"Jammu Airport", "city_name":"Jammu"},
 {"airport_iata_code":"KNU", "airport_name":"Kanpur Airport", "city_name":""},
 {"airport_iata_code":"KTU", "airport_name":"Kota Airport", "city_name":""},
 {"airport_iata_code":"LUH", "airport_name":"Ludhiana Airport", "city_name":""},
 {"airport_iata_code":"IXL", "airport_name":"Leh Kushok Bakula Rimpochee Airport", "city_name":"Leh"},
 {"airport_iata_code":"LKO", "airport_name":"Chaudhary Charan Singh International Airport", "city_name":"Lucknow"},
 {"airport_iata_code":"IXP", "airport_name":"Pathankot Air Force Station", "city_name":""},
 {"airport_iata_code":"PGH", "airport_name":"Pantnagar Airport", "city_name":"Pantnagar"},
 {"airport_iata_code":"SLV", "airport_name":"Shimla Airport", "city_name":""},
 {"airport_iata_code":"SXR", "airport_name":"Sheikh ul Alam Airport", "city_name":"Srinagar"},
 {"airport_iata_code":"TNI", "airport_name":"Satna Airport", "city_name":""},
 {"airport_iata_code":"LTU", "airport_name":"Murod Kond Airport", "city_name":"Latur"},
 {"airport_iata_code":"AGX", "airport_name":"Agatti Airport", "city_name":""},
 {"airport_iata_code":"BEP", "airport_name":"Bellary Airport", "city_name":"Bellary"},
 {"airport_iata_code":"BLR", "airport_name":"Bengaluru International Airport", "city_name":"Bangalore"},
 {"airport_iata_code":"VGA", "airport_name":"Vijayawada Airport", "city_name":""},
 {"airport_iata_code":"CJB", "airport_name":"Coimbatore International Airport", "city_name":"Coimbatore"},
 {"airport_iata_code":"COK", "airport_name":"Cochin International Airport", "city_name":"Cochin"},
 {"airport_iata_code":"CCJ", "airport_name":"Calicut International Airport", "city_name":"Calicut"},
 {"airport_iata_code":"CDP", "airport_name":"Cuddapah Airport", "city_name":""},
 {"airport_iata_code":"CBD", "airport_name":"Car Nicobar Air Force Station", "city_name":""},
 {"airport_iata_code":"HYD", "airport_name":"Rajiv Gandhi International Airport, Shamshabad", "city_name":"Hyderabad"},
 {"airport_iata_code":"BPM", "airport_name":"Begumpet Airport", "city_name":"Hyderabad"},
 {"airport_iata_code":"IXM", "airport_name":"Madurai Airport", "city_name":"Madurai"},
 {"airport_iata_code":"IXE", "airport_name":"Mangalore International Airport", "city_name":"Mangalore"},
 {"airport_iata_code":"MAA", "airport_name":"Chennai International Airport", "city_name":"Chennai"},
 {"airport_iata_code":"MYQ", "airport_name":"Mysore Airport", "city_name":"Mysore"},
 {"airport_iata_code":"IXZ", "airport_name":"Vir Savarkar International Airport", "city_name":"Port Blair"},
 {"airport_iata_code":"PNY", "airport_name":"Pondicherry Airport", "city_name":""},
 {"airport_iata_code":"RJA", "airport_name":"Rajahmundry Airport", "city_name":"Rajahmundry"},
 {"airport_iata_code":"SXV", "airport_name":"Salem Airport", "city_name":""},
 {"airport_iata_code":"TJV", "airport_name":"Tanjore Air Force Base", "city_name":"Thanjavur"},
 {"airport_iata_code":"TIR", "airport_name":"Tirupati Airport", "city_name":"Tirupati"},
 {"airport_iata_code":"TRZ", "airport_name":"Tiruchirapally Civil Airport Airport", "city_name":"Tiruchirappally"},
 {"airport_iata_code":"TRV", "airport_name":"Trivandrum International Airport", "city_name":"Trivandrum"},
 {"airport_iata_code":"WGC", "airport_name":"Warangal Airport", "city_name":"Warrangal"},
 {"airport_iata_code":"VDY", "airport_name":"Vijayanagar Aerodrome (JSW)", "city_name":""},
 {"airport_iata_code":"JGB", "airport_name":"Jagdalpur Airport", "city_name":"Jagdalpur"},
 {"airport_iata_code":"NVY", "airport_name":"Neyveli Airport", "city_name":""},
 {"airport_iata_code":"RJI", "airport_name":"Rajouri Airport", "city_name":"Rajouri"},
 {"airport_iata_code":"TEI", "airport_name":"Tezu Airport", "city_name":"Tezu"},
 {"airport_iata_code":"NKW", "airport_name":"Diego Garcia Naval Support Facility", "city_name":"Diego Garcia"},
 {"airport_iata_code":"IQA", "airport_name":"Al Asad Air Base", "city_name":"Hit"},
 {"airport_iata_code":"BMN", "airport_name":"Bamarni Airport", "city_name":"Bamarni"},
 {"airport_iata_code":"BGW", "airport_name":"Baghdad International Airport", "city_name":"Baghdad"},
 {"airport_iata_code":"OSB", "airport_name":"Mosul International Airport", "city_name":"Mosul"},
 {"airport_iata_code":"EBL", "airport_name":"Erbil International Airport", "city_name":"Arbil"},
 {"airport_iata_code":"KIK", "airport_name":"Kirkuk Air Base", "city_name":"Kirkuk"},
 {"airport_iata_code":"BSR", "airport_name":"Basrah International Airport", "city_name":"Basrah"},
 {"airport_iata_code":"RQW", "airport_name":"Qayyarah West Airport", "city_name":"Qayyarah"},
 {"airport_iata_code":"ISU", "airport_name":"Sulaymaniyah International Airport", "city_name":"Sulaymaniyah"},
 {"airport_iata_code":"ABD", "airport_name":"Abadan Airport", "city_name":"Abadan"},
 {"airport_iata_code":"DEF", "airport_name":"Dezful Airport", "city_name":""},
 {"airport_iata_code":"OMI", "airport_name":"Omidiyeh Airport", "city_name":"Omidiyeh"},
 {"airport_iata_code":"MRX", "airport_name":"Mahshahr Airport", "city_name":""},
 {"airport_iata_code":"AWZ", "airport_name":"Ahwaz Airport", "city_name":"Ahwaz"},
 {"airport_iata_code":"AEU", "airport_name":"Abumusa Island Airport", "city_name":""},
 {"airport_iata_code":"BUZ", "airport_name":"Bushehr Airport", "city_name":"Bushehr"},
 {"airport_iata_code":"AOY", "airport_name":"Asaloyeh Airport", "city_name":"Asaloyeh"},
 {"airport_iata_code":"KNR", "airport_name":"Jam Airport", "city_name":"Kangan"},
 {"airport_iata_code":"KIH", "airport_name":"Kish International Airport", "city_name":"Kish Island"},
 {"airport_iata_code":"BDH", "airport_name":"Bandar Lengeh Airport", "city_name":"Bandar Lengeh"},
 {"airport_iata_code":"YEH", "airport_name":"Persian Gulf International Airport", "city_name":"Asalouyeh"},
 {"airport_iata_code":"KHK", "airport_name":"Khark Island Airport", "city_name":""},
 {"airport_iata_code":"SXI", "airport_name":"Sirri Island Airport", "city_name":""},
 {"airport_iata_code":"LVP", "airport_name":"Lavan Island Airport", "city_name":""},
 {"airport_iata_code":"KSH", "airport_name":"Shahid Ashrafi Esfahani Airport", "city_name":"Kermanshah"},
 {"airport_iata_code":"IIL", "airport_name":"Ilam Airport", "city_name":"Ilam"},
 {"airport_iata_code":"KHD", "airport_name":"Khoram Abad Airport", "city_name":""},
 {"airport_iata_code":"SDG", "airport_name":"Sanandaj Airport", "city_name":""},
 {"airport_iata_code":"IFH", "airport_name":"Hesa Airport", "city_name":"Hesa"},
 {"airport_iata_code":"IFN", "airport_name":"Esfahan Shahid Beheshti International Airport", "city_name":"Isfahan"},
 {"airport_iata_code":"CQD", "airport_name":"Shahrekord Airport", "city_name":"Shahrekord"},
 {"airport_iata_code":"RAS", "airport_name":"Sardar-e-Jangal Airport", "city_name":"Rasht"},
 {"airport_iata_code":"HDM", "airport_name":"Hamadan Airport", "city_name":"Hamadan"},
 {"airport_iata_code":"AJK", "airport_name":"Arak Airport", "city_name":"Araak"},
 {"airport_iata_code":"IKA", "airport_name":"Imam Khomeini International Airport", "city_name":"Tehran"},
 {"airport_iata_code":"THR", "airport_name":"Mehrabad International Airport", "city_name":"Tehran"},
 {"airport_iata_code":"BND", "airport_name":"Bandar Abbas International Airport", "city_name":"Bandar Abbas"},
 {"airport_iata_code":"KER", "airport_name":"Kerman Airport", "city_name":"Kerman"},
 {"airport_iata_code":"BXR", "airport_name":"Bam Airport", "city_name":""},
 {"airport_iata_code":"HDR", "airport_name":"Havadarya Airport", "city_name":"Havadarya"},
 {"airport_iata_code":"RJN", "airport_name":"Rafsanjan Airport", "city_name":""},
 {"airport_iata_code":"SYJ", "airport_name":"Sirjan Airport", "city_name":""},
 {"airport_iata_code":"XBJ", "airport_name":"Birjand Airport", "city_name":"Birjand"},
 {"airport_iata_code":"CKT", "airport_name":"Sarakhs Airport", "city_name":"Sarakhs"},
 {"airport_iata_code":"RUD", "airport_name":"Shahroud Airport", "city_name":""},
 {"airport_iata_code":"MHD", "airport_name":"Mashhad International Airport", "city_name":"Mashhad"},
 {"airport_iata_code":"BJB", "airport_name":"Bojnord Airport", "city_name":"Bojnord"},
 {"airport_iata_code":"AFZ", "airport_name":"Sabzevar National Airport", "city_name":"Sabzevar"},
 {"airport_iata_code":"TCX", "airport_name":"Tabas Airport", "city_name":"Tabas"},
 {"airport_iata_code":"BBL", "airport_name":"Babolsar Airport", "city_name":"Babolsar"},
 {"airport_iata_code":"GBT", "airport_name":"Gorgan Airport", "city_name":"Gorgan"},
 {"airport_iata_code":"BSM", "airport_name":"Bishe Kola Air Base", "city_name":"Amol"},
 {"airport_iata_code":"NSH", "airport_name":"Noshahr Airport", "city_name":""},
 {"airport_iata_code":"RZR", "airport_name":"Ramsar Airport", "city_name":""},
 {"airport_iata_code":"SRY", "airport_name":"Dasht-e Naz Airport", "city_name":"Sari"},
 {"airport_iata_code":"FAZ", "airport_name":"Fasa Airport", "city_name":"Fasa"},
 {"airport_iata_code":"LRR", "airport_name":"Lar Airport", "city_name":"Lar"},
 {"airport_iata_code":"SYZ", "airport_name":"Shiraz Shahid Dastghaib International Airport", "city_name":"Shiraz"},
 {"airport_iata_code":"KHY", "airport_name":"Khoy Airport", "city_name":"Khoy"},
 {"airport_iata_code":"ADU", "airport_name":"Ardabil Airport", "city_name":"Ardabil"},
 {"airport_iata_code":"ACP", "airport_name":"Sahand Airport", "city_name":"Maragheh"},
 {"airport_iata_code":"PFQ", "airport_name":"Parsabade Moghan Airport", "city_name":"Parsabad"},
 {"airport_iata_code":"OMH", "airport_name":"Urmia Airport", "city_name":"Urmia"},
 {"airport_iata_code":"TBZ", "airport_name":"Tabriz International Airport", "city_name":"Tabriz"},
 {"airport_iata_code":"AZD", "airport_name":"Shahid Sadooghi Airport", "city_name":"Yazd"},
 {"airport_iata_code":"ACZ", "airport_name":"Zabol Airport", "city_name":""},
 {"airport_iata_code":"ZBR", "airport_name":"Konarak Airport", "city_name":"Chabahar"},
 {"airport_iata_code":"ZAH", "airport_name":"Zahedan International Airport", "city_name":"Zahedan"},
 {"airport_iata_code":"KHA", "airport_name":"Khaneh Airport", "city_name":"Khaneh"},
 {"airport_iata_code":"GSM", "airport_name":"Gheshm Airport", "city_name":"Gheshm"},
 {"airport_iata_code":"AEY", "airport_name":"Akureyri Airport", "city_name":"Akureyri"},
 {"airport_iata_code":"BIU", "airport_name":"Bildudalur Airport", "city_name":"Bildudalur"},
 {"airport_iata_code":"BGJ", "airport_name":"Borgarfj\u00f6r\u00f0ur eystri Airport", "city_name":"Borgarfj\u00f6r\u00f0ur eystri"},
 {"airport_iata_code":"BJD", "airport_name":"Bakkafj\u00f6r\u00f0ur Airport", "city_name":"Bakkafj\u00f6r\u00f0ur"},
 {"airport_iata_code":"BLO", "airport_name":"Hjaltabakki Airport", "city_name":"Bl\u00f6ndu\u00f3s"},
 {"airport_iata_code":"BQD", "airport_name":"B\u00fa\u00f0ardalur Airport", "city_name":"B\u00fa\u00f0ardalur"},
 {"airport_iata_code":"BXV", "airport_name":"Brei\u00f0dalsv\u00edk Airport", "city_name":"Brei\u00f0dalsv\u00edk"},
 {"airport_iata_code":"DJU", "airport_name":"Dj\u00fapivogur Airport", "city_name":"Dj\u00fapivogur"},
 {"airport_iata_code":"EGS", "airport_name":"Egilssta\u00f0ir Airport", "city_name":"Egilssta\u00f0ir"},
 {"airport_iata_code":"FAS", "airport_name":"F\u00e1skr\u00fa\u00f0sfj\u00f6r\u00f0ur Airport", "city_name":"F\u00e1skr\u00fa\u00f0sfj\u00f6r\u00f0ur"},
 {"airport_iata_code":"FAG", "airport_name":"Fagurh\u00f3lsm\u00fdri Airport", "city_name":"Fagurh\u00f3lsm\u00fdri"},
 {"airport_iata_code":"GUU", "airport_name":"Grundarfj\u00f6r\u00f0ur Airport", "city_name":"Grundarfj\u00f6r\u00f0ur"},
 {"airport_iata_code":"GJR", "airport_name":"Gj\u00f6gur Airport", "city_name":"Gj\u00f6gur"},
 {"airport_iata_code":"GRY", "airport_name":"Gr\u00edmsey Airport", "city_name":"Gr\u00edmsey"},
 {"airport_iata_code":"HVK", "airport_name":"H\u00f3lmav\u00edk Airport", "city_name":"H\u00f3lmav\u00edk"},
 {"airport_iata_code":"HFN", "airport_name":"Hornafjordur Airport", "city_name":"Hornafjordur"},
 {"airport_iata_code":"FLI", "airport_name":"Holt Airport", "city_name":"Flateyri"},
 {"airport_iata_code":"HZK", "airport_name":"Husavik Airport", "city_name":"Husavik"},
 {"airport_iata_code":"HVM", "airport_name":"Kr\u00f3ksta\u00f0armelar Airport", "city_name":"Hvammstangi"},
 {"airport_iata_code":"HLO", "airport_name":"Ingjaldssan\u00f0ur Airport", "city_name":"Onundarfj\u00f6r\u00f0ur"},
 {"airport_iata_code":"IFJ", "airport_name":"\u00cdsafj\u00f6r\u00f0ur Airport", "city_name":"\u00cdsafj\u00f6r\u00f0ur"},
 {"airport_iata_code":"KEF", "airport_name":"Keflavik International Airport", "city_name":"Reykjav\u00edk"},
 {"airport_iata_code":"OPA", "airport_name":"K\u00f3pasker Airport", "city_name":"K\u00f3pasker"},
 {"airport_iata_code":"SAK", "airport_name":"Sau\u00f0\u00e1rkr\u00f3kur Airport", "city_name":"Sau\u00f0\u00e1rkr\u00f3kur"},
 {"airport_iata_code":"NOR", "airport_name":"Nor\u00f0fj\u00f6r\u00f0ur Airport", "city_name":"Nor\u00f0fj\u00f6r\u00f0ur"},
 {"airport_iata_code":"OFJ", "airport_name":"\u00d3lafsfj\u00f6r\u00f0ur Airport", "city_name":"\u00d3lafsfj\u00f6r\u00f0ur"},
 {"airport_iata_code":"PFJ", "airport_name":"Patreksfjordur Airport", "city_name":"Patreksfjordur"},
 {"airport_iata_code":"RHA", "airport_name":"Reykh\u00f3lar Airport", "city_name":"Reykh\u00f3lar"},
 {"airport_iata_code":"OLI", "airport_name":"Rif Airport", "city_name":"Rif"},
 {"airport_iata_code":"RFN", "airport_name":"Raufarh\u00f6fn Airport", "city_name":"Raufarh\u00f6fn"},
 {"airport_iata_code":"RKV", "airport_name":"Reykjavik Airport", "city_name":"Reykjavik"},
 {"airport_iata_code":"MVA", "airport_name":"Reykjahl\u00ed\u00f0 Airport", "city_name":"Myvatn"},
 {"airport_iata_code":"SIJ", "airport_name":"Siglufjordur Airport", "city_name":"Siglufjordur"},
 {"airport_iata_code":"SYK", "airport_name":"Stykkish\u00f3lmur Airport", "city_name":"Stykkish\u00f3lmur"},
 {"airport_iata_code":"TEY", "airport_name":"\u00deingeyri Airport", "city_name":"\u00deingeyri"},
 {"airport_iata_code":"THO", "airport_name":"Thorshofn Airport", "city_name":"Thorshofn"},
 {"airport_iata_code":"VEY", "airport_name":"Vestmannaeyjar Airport", "city_name":"Vestmannaeyjar"},
 {"airport_iata_code":"VPN", "airport_name":"Vopnafj\u00f6r\u00f0ur Airport", "city_name":"Vopnafj\u00f6r\u00f0ur"},
 {"airport_iata_code":"CRV", "airport_name":"Crotone Airport", "city_name":"Crotone"},
 {"airport_iata_code":"BRI", "airport_name":"Bari / Palese International Airport", "city_name":"Bari"},
 {"airport_iata_code":"FOG", "airport_name":"Foggia / Gino Lisa Airport", "city_name":"Foggia"},
 {"airport_iata_code":"TAR", "airport_name":"Taranto / Grottaglie Airport", "city_name":"Grottaglie"},
 {"airport_iata_code":"LCC", "airport_name":"Lecce Airport", "city_name":""},
 {"airport_iata_code":"PSR", "airport_name":"Pescara International Airport", "city_name":"Pescara"},
 {"airport_iata_code":"BDS", "airport_name":"Brindisi / Casale Airport", "city_name":"Brindisi"},
 {"airport_iata_code":"SUF", "airport_name":"Lamezia Terme Airport", "city_name":"Lamezia Terme"},
 {"airport_iata_code":"CIY", "airport_name":"Comiso Airport \"Vincenzo Magliocco\"", "city_name":"Comiso"},
 {"airport_iata_code":"CTA", "airport_name":"Catania / Fontanarossa Airport", "city_name":"Catania"},
 {"airport_iata_code":"LMP", "airport_name":"Lampedusa Airport", "city_name":"Lampedusa"},
 {"airport_iata_code":"PNL", "airport_name":"Pantelleria Airport", "city_name":"Pantelleria"},
 {"airport_iata_code":"PMO", "airport_name":"Palermo / Punta Raisi Airport", "city_name":"Palermo"},
 {"airport_iata_code":"REG", "airport_name":"Reggio Calabria Airport", "city_name":"Reggio Calabria"},
 {"airport_iata_code":"TPS", "airport_name":"Trapani / Birgi Airport", "city_name":"Trapani"},
 {"airport_iata_code":"NSY", "airport_name":"Sigonella Airport", "city_name":""},
 {"airport_iata_code":"BLX", "airport_name":"Belluno Airport", "city_name":"Belluno"},
 {"airport_iata_code":"CDF", "airport_name":"Cortina Airport", "city_name":"Cortina D'Ampezzo"},
 {"airport_iata_code":"RAN", "airport_name":"Ravenna Airport", "city_name":"Ravenna"},
 {"airport_iata_code":"ZIA", "airport_name":"Trento / Mattarello Airport", "city_name":"Trento"},
 {"airport_iata_code":"AHO", "airport_name":"Alghero / Fertilia Airport", "city_name":"Alghero"},
 {"airport_iata_code":"DCI", "airport_name":"Decimomannu Airport", "city_name":"Decimomannu"},
 {"airport_iata_code":"CAG", "airport_name":"Cagliari / Elmas Airport", "city_name":"Cagliari"},
 {"airport_iata_code":"OLB", "airport_name":"Olbia / Costa Smeralda Airport", "city_name":"Olbia"},
 {"airport_iata_code":"FNU", "airport_name":"Oristano / Fenosu Airport", "city_name":"Oristano"},
 {"airport_iata_code":"TTB", "airport_name":"Tortoli' / Arbatax Airport", "city_name":"Arbatax"},
 {"airport_iata_code":"QVA", "airport_name":"Varese / Venegono Airport", "city_name":"Varese"},
 {"airport_iata_code":"QMM", "airport_name":"Massa Cinquale Airport", "city_name":"Marina Di Massa"},
 {"airport_iata_code":"MXP", "airport_name":"Malpensa International Airport", "city_name":"Milan"},
 {"airport_iata_code":"BGY", "airport_name":"Bergamo / Orio Al Serio Airport", "city_name":"Bergamo"},
 {"airport_iata_code":"TRN", "airport_name":"Torino / Caselle International Airport", "city_name":"Torino"},
 {"airport_iata_code":"ALL", "airport_name":"Villanova D'Albenga International Airport", "city_name":"Albenga"},
 {"airport_iata_code":"GOA", "airport_name":"Genova / Sestri Cristoforo Colombo Airport", "city_name":"Genova"},
 {"airport_iata_code":"LIN", "airport_name":"Linate Airport", "city_name":"Milan"},
 {"airport_iata_code":"PMF", "airport_name":"Parma Airport", "city_name":"Parma"},
 {"airport_iata_code":"QPZ", "airport_name":"Piacenza Airport", "city_name":"Piacenza"},
 {"airport_iata_code":"AOT", "airport_name":"Aosta Airport", "city_name":"Aosta"},
 {"airport_iata_code":"CUF", "airport_name":"Cuneo / Levaldigi Airport", "city_name":"Cuneo"},
 {"airport_iata_code":"AVB", "airport_name":"Aviano Air Base", "city_name":"Aviano"},
 {"airport_iata_code":"BZO", "airport_name":"Bolzano Airport", "city_name":"Bolzano"},
 {"airport_iata_code":"UDN", "airport_name":"Udine / Campoformido Air Base", "city_name":"Udine"},
 {"airport_iata_code":"BLQ", "airport_name":"Bologna / Borgo Panigale Airport", "city_name":"Bologna"},
 {"airport_iata_code":"TSF", "airport_name":"Treviso / Sant'Angelo Airport", "city_name":"Treviso"},
 {"airport_iata_code":"FRL", "airport_name":"Forl\u00ec Airport", "city_name":"Forl\u00ec"},
 {"airport_iata_code":"VBS", "airport_name":"Brescia / Montichiari Airport", "city_name":"Brescia"},
 {"airport_iata_code":"TRS", "airport_name":"Trieste / Ronchi Dei Legionari", "city_name":"Trieste"},
 {"airport_iata_code":"RMI", "airport_name":"Rimini / Miramare - Federico Fellini International Airport", "city_name":"Rimini"},
 {"airport_iata_code":"VIC", "airport_name":"Vicenza Airport", "city_name":"Vicenza"},
 {"airport_iata_code":"QPA", "airport_name":"Padova Airport", "city_name":"Padova"},
 {"airport_iata_code":"VRN", "airport_name":"Verona / Villafranca Airport", "city_name":"Verona"},
 {"airport_iata_code":"AOI", "airport_name":"Ancona / Falconara Airport", "city_name":"Ancona"},
 {"airport_iata_code":"VCE", "airport_name":"Venezia / Tessera -  Marco Polo Airport", "city_name":"Venezia"},
 {"airport_iata_code":"QZO", "airport_name":"Arezzo Airport", "city_name":"Arezzo"},
 {"airport_iata_code":"LCV", "airport_name":"Lucca / Tassignano Airport", "city_name":"Lucca"},
 {"airport_iata_code":"QRT", "airport_name":"Rieti Airport", "city_name":"Rieti"},
 {"airport_iata_code":"SAY", "airport_name":"Siena / Ampugnano Airport", "city_name":"Siena"},
 {"airport_iata_code":"QLP", "airport_name":"Sarzana / Luni Airport", "city_name":"Sarzana"},
 {"airport_iata_code":"CIA", "airport_name":"Ciampino Airport", "city_name":"Roma"},
 {"airport_iata_code":"FCO", "airport_name":"Leonardo Da Vinci (Fiumicino) International Airport", "city_name":"Rome"},
 {"airport_iata_code":"QFR", "airport_name":"Frosinone Military Airport", "city_name":"Frosinone"},
 {"airport_iata_code":"QSR", "airport_name":"Salerno / Pontecagnano Airport", "city_name":"Salerno"},
 {"airport_iata_code":"EBA", "airport_name":"Marina Di Campo Airport", "city_name":"Marina  Di Campo"},
 {"airport_iata_code":"QLT", "airport_name":"Latina Airport", "city_name":"Latina"},
 {"airport_iata_code":"NAP", "airport_name":"N\u00e1poli / Capodichino International Airport", "city_name":"N\u00e1poli"},
 {"airport_iata_code":"PSA", "airport_name":"Pisa / San Giusto - Galileo Galilei International Airport", "city_name":"Pisa"},
 {"airport_iata_code":"FLR", "airport_name":"Firenze / Peretola Airport", "city_name":"Firenze"},
 {"airport_iata_code":"GRS", "airport_name":"Grosseto Airport", "city_name":"Grosetto"},
 {"airport_iata_code":"PEG", "airport_name":"Perugia / San Egidio Airport", "city_name":"Perugia"},
 {"airport_iata_code":"JER", "airport_name":"Jersey Airport", "city_name":"Saint Helier"},
 {"airport_iata_code":"OCJ", "airport_name":"Boscobel Aerodrome", "city_name":"Ocho Rios"},
 {"airport_iata_code":"KIN", "airport_name":"Norman Manley International Airport", "city_name":"Kingston"},
 {"airport_iata_code":"MBJ", "airport_name":"Sangster International Airport", "city_name":"Montego Bay"},
 {"airport_iata_code":"POT", "airport_name":"Ken Jones Airport", "city_name":"Ken Jones"},
 {"airport_iata_code":"NEG", "airport_name":"Negril Airport", "city_name":"Negril"},
 {"airport_iata_code":"KTP", "airport_name":"Tinson Pen Airport", "city_name":"Tinson Pen"},
 {"airport_iata_code":"AMM", "airport_name":"Queen Alia International Airport", "city_name":"Amman"},
 {"airport_iata_code":"ADJ", "airport_name":"Amman-Marka International Airport", "city_name":"Amman"},
 {"airport_iata_code":"AQJ", "airport_name":"Aqaba King Hussein International Airport", "city_name":"Aqaba"},
 {"airport_iata_code":"OMF", "airport_name":"King Hussein Air College", "city_name":"Mafraq"},
 {"airport_iata_code":"NRT", "airport_name":"Narita International Airport", "city_name":"Tokyo"},
 {"airport_iata_code":"MMJ", "airport_name":"Matsumoto Airport", "city_name":"Matsumoto"},
 {"airport_iata_code":"IBR", "airport_name":"Hyakuri Airport", "city_name":"Omitama"},
 {"airport_iata_code":"MUS", "airport_name":"Minami Torishima Airport", "city_name":""},
 {"airport_iata_code":"IWO", "airport_name":"Iwo Jima Airport", "city_name":""},
 {"airport_iata_code":"KIX", "airport_name":"Kansai International Airport", "city_name":"Osaka"},
 {"airport_iata_code":"SHM", "airport_name":"Nanki Shirahama Airport", "city_name":"Shirahama"},
 {"airport_iata_code":"UKB", "airport_name":"Kobe Airport", "city_name":"Kobe"},
 {"airport_iata_code":"HIW", "airport_name":"Hiroshimanishi Airport", "city_name":""},
 {"airport_iata_code":"TJH", "airport_name":"Tajima Airport", "city_name":"Tajima"},
 {"airport_iata_code":"OBO", "airport_name":"Tokachi-Obihiro Airport", "city_name":"Obihiro"},
 {"airport_iata_code":"CTS", "airport_name":"New Chitose Airport", "city_name":"Chitose / Tomakomai"},
 {"airport_iata_code":"HKD", "airport_name":"Hakodate Airport", "city_name":"Hakodate"},
 {"airport_iata_code":"KUH", "airport_name":"Kushiro Airport", "city_name":"Kushiro"},
 {"airport_iata_code":"MMB", "airport_name":"Memanbetsu Airport", "city_name":"?zora"},
 {"airport_iata_code":"SHB", "airport_name":"Nakashibetsu Airport", "city_name":"Nakashibetsu"},
 {"airport_iata_code":"OKD", "airport_name":"Okadama Airport", "city_name":"Sapporo"},
 {"airport_iata_code":"RBJ", "airport_name":"Rebun Airport Airport", "city_name":""},
 {"airport_iata_code":"WKJ", "airport_name":"Wakkanai Airport", "city_name":"Wakkanai"},
 {"airport_iata_code":"IKI", "airport_name":"Iki Airport", "city_name":"Iki"},
 {"airport_iata_code":"UBJ", "airport_name":"Yamaguchi Ube Airport", "city_name":"Ube"},
 {"airport_iata_code":"TSJ", "airport_name":"Tsushima Airport", "city_name":"Tsushima"},
 {"airport_iata_code":"MBE", "airport_name":"Monbetsu Airport", "city_name":"Monbetsu"},
 {"airport_iata_code":"AKJ", "airport_name":"Asahikawa Airport", "city_name":"Asahikawa / Hokkaido"},
 {"airport_iata_code":"OIR", "airport_name":"Okushiri Airport", "city_name":""},
 {"airport_iata_code":"RIS", "airport_name":"Rishiri Airport", "city_name":"Rishiri"},
 {"airport_iata_code":"KUM", "airport_name":"Yakushima Airport", "city_name":""},
 {"airport_iata_code":"FUJ", "airport_name":"Fukue Airport", "city_name":"Goto"},
 {"airport_iata_code":"FUK", "airport_name":"Fukuoka Airport", "city_name":"Fukuoka"},
 {"airport_iata_code":"TNE", "airport_name":"New Tanegashima Airport", "city_name":""},
 {"airport_iata_code":"KOJ", "airport_name":"Kagoshima Airport", "city_name":"Kagoshima"},
 {"airport_iata_code":"KMI", "airport_name":"Miyazaki Airport", "city_name":"Miyazaki"},
 {"airport_iata_code":"OIT", "airport_name":"Oita Airport", "city_name":"Oita"},
 {"airport_iata_code":"KKJ", "airport_name":"Kitakyushu Airport", "city_name":"Kitakyushu"},
 {"airport_iata_code":"HSG", "airport_name":"Saga Airport", "city_name":"Saga"},
 {"airport_iata_code":"KMJ", "airport_name":"Kumamoto Airport", "city_name":"Kumamoto"},
 {"airport_iata_code":"NGS", "airport_name":"Nagasaki Airport", "city_name":"Nagasaki"},
 {"airport_iata_code":"NGO", "airport_name":"Chubu Centrair International Airport", "city_name":"Tokoname"},
 {"airport_iata_code":"ASJ", "airport_name":"Amami Airport", "city_name":"Amami"},
 {"airport_iata_code":"OKE", "airport_name":"Okierabu Airport", "city_name":""},
 {"airport_iata_code":"KKX", "airport_name":"Kikai Airport", "city_name":""},
 {"airport_iata_code":"TKN", "airport_name":"Tokunoshima Airport", "city_name":"Tokunoshima"},
 {"airport_iata_code":"NKM", "airport_name":"Nagoya Airport", "city_name":"Nagoya"},
 {"airport_iata_code":"FKJ", "airport_name":"Fukui Airport", "city_name":""},
 {"airport_iata_code":"QGU", "airport_name":"Gifu Airport", "city_name":"Gifu"},
 {"airport_iata_code":"KMQ", "airport_name":"Komatsu Airport", "city_name":"Kanazawa"},
 {"airport_iata_code":"OKI", "airport_name":"Oki Airport", "city_name":"Okinoshima"},
 {"airport_iata_code":"TOY", "airport_name":"Toyama Airport", "city_name":"Toyama"},
 {"airport_iata_code":"NTQ", "airport_name":"Noto Airport", "city_name":"Wajima"},
 {"airport_iata_code":"HIJ", "airport_name":"Hiroshima Airport", "city_name":"Hiroshima"},
 {"airport_iata_code":"OKJ", "airport_name":"Okayama Airport", "city_name":"Okayama City"},
 {"airport_iata_code":"IZO", "airport_name":"Izumo Airport", "city_name":"Izumo"},
 {"airport_iata_code":"YGJ", "airport_name":"Miho Yonago Airport", "city_name":"Yonago"},
 {"airport_iata_code":"KCZ", "airport_name":"K?chi Ry?ma Airport", "city_name":"Nankoku"},
 {"airport_iata_code":"MYJ", "airport_name":"Matsuyama Airport", "city_name":"Matsuyama"},
 {"airport_iata_code":"ITM", "airport_name":"Osaka International Airport", "city_name":"Osaka"},
 {"airport_iata_code":"TTJ", "airport_name":"Tottori Airport", "city_name":"Tottori"},
 {"airport_iata_code":"TKS", "airport_name":"Tokushima Airport", "city_name":"Tokushima"},
 {"airport_iata_code":"TAK", "airport_name":"Takamatsu Airport", "city_name":"Takamatsu"},
 {"airport_iata_code":"IWJ", "airport_name":"Iwami Airport", "city_name":"Masuda"},
 {"airport_iata_code":"AOJ", "airport_name":"Aomori Airport", "city_name":"Aomori"},
 {"airport_iata_code":"GAJ", "airport_name":"Yamagata Airport", "city_name":"Yamagata"},
 {"airport_iata_code":"SDS", "airport_name":"Sado Airport", "city_name":""},
 {"airport_iata_code":"FKS", "airport_name":"Fukushima Airport", "city_name":"Sukagawa"},
 {"airport_iata_code":"HHE", "airport_name":"Hachinohe Airport", "city_name":""},
 {"airport_iata_code":"HNA", "airport_name":"Hanamaki Airport", "city_name":""},
 {"airport_iata_code":"AXT", "airport_name":"Akita Airport", "city_name":"Akita"},
 {"airport_iata_code":"MSJ", "airport_name":"Misawa Air Base", "city_name":"Misawa"},
 {"airport_iata_code":"KIJ", "airport_name":"Niigata Airport", "city_name":"Niigata"},
 {"airport_iata_code":"ONJ", "airport_name":"Odate Noshiro Airport", "city_name":"Odate"},
 {"airport_iata_code":"SDJ", "airport_name":"Sendai Airport", "city_name":"Sendai"},
 {"airport_iata_code":"SYO", "airport_name":"Shonai Airport", "city_name":"Shonai"},
 {"airport_iata_code":"NJA", "airport_name":"Atsugi Naval Air Facility", "city_name":""},
 {"airport_iata_code":"HAC", "airport_name":"Hachijojima Airport", "city_name":"Hachijojima"},
 {"airport_iata_code":"OIM", "airport_name":"Oshima Airport", "city_name":"Izu Oshima"},
 {"airport_iata_code":"MYE", "airport_name":"Miyakejima Airport", "city_name":"Miyakejima"},
 {"airport_iata_code":"HND", "airport_name":"Tokyo International Airport", "city_name":"Tokyo"},
 {"airport_iata_code":"QUT", "airport_name":"Utsunomiya Airport", "city_name":""},
 {"airport_iata_code":"OKO", "airport_name":"Yokota Air Base", "city_name":"Fussa"},
 {"airport_iata_code":"OKA", "airport_name":"Naha Airport", "city_name":"Naha"},
 {"airport_iata_code":"DNA", "airport_name":"Kadena Air Base", "city_name":""},
 {"airport_iata_code":"ISG", "airport_name":"Ishigaki Airport", "city_name":"Ishigaki"},
 {"airport_iata_code":"UEO", "airport_name":"Kumejima Airport", "city_name":""},
 {"airport_iata_code":"KJP", "airport_name":"Kerama Airport", "city_name":"Kerama"},
 {"airport_iata_code":"MMD", "airport_name":"Minami Daito Airport", "city_name":""},
 {"airport_iata_code":"MMY", "airport_name":"Miyako Airport", "city_name":"Miyako City"},
 {"airport_iata_code":"AGJ", "airport_name":"Aguni Airport", "city_name":"Aguni"},
 {"airport_iata_code":"IEJ", "airport_name":"Ie Jima Airport", "city_name":""},
 {"airport_iata_code":"HTR", "airport_name":"Hateruma Airport", "city_name":"Hateruma"},
 {"airport_iata_code":"KTD", "airport_name":"Kitadaito Airport", "city_name":""},
 {"airport_iata_code":"SHI", "airport_name":"Shimojishima Airport", "city_name":""},
 {"airport_iata_code":"TRA", "airport_name":"Tarama Airport", "city_name":""},
 {"airport_iata_code":"RNJ", "airport_name":"Yoron Airport", "city_name":""},
 {"airport_iata_code":"OGN", "airport_name":"Yonaguni Airport", "city_name":""},
 {"airport_iata_code":"ASV", "airport_name":"Amboseli Airport", "city_name":"Amboseli National Park"},
 {"airport_iata_code":"EDL", "airport_name":"Eldoret International Airport", "city_name":"Eldoret"},
 {"airport_iata_code":"EYS", "airport_name":"Eliye Springs Airport", "city_name":"Eliye Springs"},
 {"airport_iata_code":"KLK", "airport_name":"Kalokol Airport", "city_name":"Kalokol"},
 {"airport_iata_code":"GAS", "airport_name":"Garissa Airport", "city_name":"Garissa"},
 {"airport_iata_code":"HOA", "airport_name":"Hola Airport", "city_name":"Hola"},
 {"airport_iata_code":"NBO", "airport_name":"Jomo Kenyatta International Airport", "city_name":"Nairobi"},
 {"airport_iata_code":"GGM", "airport_name":"Kakamega Airport", "city_name":"Kakamega"},
 {"airport_iata_code":"KIS", "airport_name":"Kisumu Airport", "city_name":"Kisumu"},
 {"airport_iata_code":"ILU", "airport_name":"Kilaguni Airport", "city_name":"Kilaguni"},
 {"airport_iata_code":"KEY", "airport_name":"Kericho Airport", "city_name":"Kericho"},
 {"airport_iata_code":"KTL", "airport_name":"Kitale Airport", "city_name":"Kitale"},
 {"airport_iata_code":"LKG", "airport_name":"Lokichoggio Airport", "city_name":"Lokichoggio"},
 {"airport_iata_code":"LOK", "airport_name":"Lodwar Airport", "city_name":"Lodwar"},
 {"airport_iata_code":"LAU", "airport_name":"Manda Airstrip", "city_name":"Lamu"},
 {"airport_iata_code":"LOY", "airport_name":"Loyengalani Airport", "city_name":"Loyengalani"},
 {"airport_iata_code":"NDE", "airport_name":"Mandera Airport", "city_name":"Mandera"},
 {"airport_iata_code":"RBT", "airport_name":"Segel Airport", "city_name":"Marsabit"},
 {"airport_iata_code":"MYD", "airport_name":"Malindi Airport", "city_name":"Malindi"},
 {"airport_iata_code":"MBA", "airport_name":"Mombasa Moi International Airport", "city_name":"Mombasa"},
 {"airport_iata_code":"OYL", "airport_name":"Moyale Airport", "city_name":"Moyale (Lower)"},
 {"airport_iata_code":"NYE", "airport_name":"Nyeri Airport", "city_name":"Nyeri"},
 {"airport_iata_code":"NUU", "airport_name":"Nakuru Airport", "city_name":"Nakuru"},
 {"airport_iata_code":"WIL", "airport_name":"Nairobi Wilson Airport", "city_name":"Nairobi"},
 {"airport_iata_code":"NYK", "airport_name":"Nanyuki Airport", "city_name":"Nanyuki"},
 {"airport_iata_code":"UAS", "airport_name":"Samburu South Airport", "city_name":"Samburu South"},
 {"airport_iata_code":"UKA", "airport_name":"Ukunda Airstrip", "city_name":"Ukunda"},
 {"airport_iata_code":"WJR", "airport_name":"Wajir Airport", "city_name":"Wajir"},
 {"airport_iata_code":"JJM", "airport_name":"Mulika Lodge Airport", "city_name":"Meru-Kinna"},
 {"airport_iata_code":"VPG", "airport_name":"Vipingo Estate Airport", "city_name":"Vipingo Estate"},
 {"airport_iata_code":"KRV", "airport_name":"Kerio Valley Airport", "city_name":"Kimwarer"},
 {"airport_iata_code":"KIU", "airport_name":"Kiunga Airport", "city_name":"Kiunga"},
 {"airport_iata_code":"LBK", "airport_name":"Liboi Airport", "city_name":"Liboi"},
 {"airport_iata_code":"LBN", "airport_name":"Lake Baringo Airport", "city_name":"Lake Baringo"},
 {"airport_iata_code":"LKU", "airport_name":"Lake Rudolf Airport", "city_name":"Lake Rudolf"},
 {"airport_iata_code":"MRE", "airport_name":"Mara Lodges Airport", "city_name":"Mara Lodges"},
 {"airport_iata_code":"MUM", "airport_name":"Mumias Airport", "city_name":"Mumias"},
 {"airport_iata_code":"KWY", "airport_name":"Kiwayu Airport", "city_name":"Kiwayu"},
 {"airport_iata_code":"BMQ", "airport_name":"Bamburi Airport", "city_name":""},
 {"airport_iata_code":"FRU", "airport_name":"Manas International Airport", "city_name":"Bishkek"},
 {"airport_iata_code":"OSS", "airport_name":"Osh Airport", "city_name":"Osh"},
 {"airport_iata_code":"BBM", "airport_name":"Battambang Airport", "city_name":"Battambang"},
 {"airport_iata_code":"KZC", "airport_name":"Kampong Chhnang Airport", "city_name":""},
 {"airport_iata_code":"KKZ", "airport_name":"Kaoh Kong Airport", "city_name":"Kaoh Kong"},
 {"airport_iata_code":"KTI", "airport_name":"Kratie Airport", "city_name":"Kratie"},
 {"airport_iata_code":"PNH", "airport_name":"Phnom Penh International Airport", "city_name":"Phnom Penh"},
 {"airport_iata_code":"RBE", "airport_name":"Ratanakiri Airport", "city_name":"Ratanakiri"},
 {"airport_iata_code":"REP", "airport_name":"Angkor International Airport", "city_name":"Siem Reap"},
 {"airport_iata_code":"TNX", "airport_name":"Stung Treng Airport", "city_name":"Stung Treng"},
 {"airport_iata_code":"KOS", "airport_name":"Sihanoukville International Airport", "city_name":"Sihanukville"},
 {"airport_iata_code":"ABF", "airport_name":"Abaiang Airport", "city_name":"Abaiang"},
 {"airport_iata_code":"BEZ", "airport_name":"Beru Airport", "city_name":"Beru"},
 {"airport_iata_code":"KUC", "airport_name":"Kuria Airport", "city_name":"Kuria"},
 {"airport_iata_code":"MNK", "airport_name":"Maiana Airport", "city_name":"Maiana"},
 {"airport_iata_code":"MZK", "airport_name":"Marakei Airport", "city_name":"Marakei"},
 {"airport_iata_code":"MTK", "airport_name":"Makin Island Airport", "city_name":"Makin Island"},
 {"airport_iata_code":"NIG", "airport_name":"Nikunau Airport", "city_name":"Nikunau"},
 {"airport_iata_code":"OOT", "airport_name":"Onotoa Airport", "city_name":"Onotoa"},
 {"airport_iata_code":"TRW", "airport_name":"Bonriki International Airport", "city_name":"Tarawa"},
 {"airport_iata_code":"AEA", "airport_name":"Abemama Atoll Airport", "city_name":"Abemama Atoll"},
 {"airport_iata_code":"TBF", "airport_name":"Tabiteuea North Airport", "city_name":""},
 {"airport_iata_code":"TMN", "airport_name":"Tamana Island Airport", "city_name":"Tamana Island"},
 {"airport_iata_code":"NON", "airport_name":"Nonouti Airport", "city_name":"Nonouti"},
 {"airport_iata_code":"AIS", "airport_name":"Arorae Island Airport", "city_name":"Arorae Island"},
 {"airport_iata_code":"TSU", "airport_name":"Tabiteuea South Airport", "city_name":"Tabiteuea South"},
 {"airport_iata_code":"BBG", "airport_name":"Butaritari Atoll Airport", "city_name":"Butaritari Atoll"},
 {"airport_iata_code":"AAK", "airport_name":"Buariki Airport", "city_name":"Buariki"},
 {"airport_iata_code":"CIS", "airport_name":"Canton Airport", "city_name":"Abariringa"},
 {"airport_iata_code":"CXI", "airport_name":"Cassidy International Airport", "city_name":"Banana"},
 {"airport_iata_code":"TNV", "airport_name":"Tabuaeran Island Airport", "city_name":"Tabuaeran Island"},
 {"airport_iata_code":"HAH", "airport_name":"Prince Said Ibrahim International Airport", "city_name":"Moroni"},
 {"airport_iata_code":"NWA", "airport_name":"Moh\u00e9li Bandar Es Eslam Airport", "city_name":""},
 {"airport_iata_code":"YVA", "airport_name":"Iconi Airport", "city_name":"Moroni"},
 {"airport_iata_code":"AJN", "airport_name":"Ouani Airport", "city_name":"Ouani"},
 {"airport_iata_code":"SKB", "airport_name":"Robert L. Bradshaw International Airport", "city_name":"Basseterre"},
 {"airport_iata_code":"NEV", "airport_name":"Vance W. Amory International Airport", "city_name":"Charlestown"},
 {"airport_iata_code":"DSO", "airport_name":"Sondok Airport", "city_name":""},
 {"airport_iata_code":"YJS", "airport_name":"Samjiy?n Airport", "city_name":"Samjiy?n"},
 {"airport_iata_code":"RGO", "airport_name":"Orang Airport", "city_name":""},
 {"airport_iata_code":"FNJ", "airport_name":"Sunan International Airport", "city_name":"Pyongyang"},
 {"airport_iata_code":"UJN", "airport_name":"Uljin Airport", "city_name":"Uljin"},
 {"airport_iata_code":"MWX", "airport_name":"Muan International Airport", "city_name":""},
 {"airport_iata_code":"KWJ", "airport_name":"Gwangju Airport", "city_name":"Gwangju"},
 {"airport_iata_code":"KUV", "airport_name":"Kunsan Air Base", "city_name":"Kunsan"},
 {"airport_iata_code":"CHN", "airport_name":"Jeon Ju Airport", "city_name":"Jeon Ju"},
 {"airport_iata_code":"RSU", "airport_name":"Yeosu Airport", "city_name":"Yeosu"},
 {"airport_iata_code":"QUN", "airport_name":"A-306 Airport", "city_name":"Chun Chon City"},
 {"airport_iata_code":"SHO", "airport_name":"Sokcho Airport", "city_name":""},
 {"airport_iata_code":"KAG", "airport_name":"Gangneung Airport", "city_name":""},
 {"airport_iata_code":"WJU", "airport_name":"Wonju Airport", "city_name":"Wonju"},
 {"airport_iata_code":"YNY", "airport_name":"Yangyang International Airport", "city_name":"Sokcho / Gangneung"},
 {"airport_iata_code":"CJU", "airport_name":"Jeju International Airport", "city_name":"Jeju City"},
 {"airport_iata_code":"CHF", "airport_name":"Jinhae Airport", "city_name":"Jinhae"},
 {"airport_iata_code":"PUS", "airport_name":"Gimhae International Airport", "city_name":"Busan"},
 {"airport_iata_code":"HIN", "airport_name":"Sacheon Air Base", "city_name":"Sacheon"},
 {"airport_iata_code":"USN", "airport_name":"Ulsan Airport", "city_name":"Ulsan"},
 {"airport_iata_code":"ICN", "airport_name":"Incheon International Airport", "city_name":"Seoul"},
 {"airport_iata_code":"SSN", "airport_name":"Seoul Air Base", "city_name":""},
 {"airport_iata_code":"OSN", "airport_name":"Osan Air Base", "city_name":""},
 {"airport_iata_code":"GMP", "airport_name":"Gimpo International Airport", "city_name":"Seoul"},
 {"airport_iata_code":"SWU", "airport_name":"Suwon Airport", "city_name":""},
 {"airport_iata_code":"KPO", "airport_name":"Pohang Airport", "city_name":"Pohang"},
 {"airport_iata_code":"TAE", "airport_name":"Daegu Airport", "city_name":"Daegu"},
 {"airport_iata_code":"CJJ", "airport_name":"Cheongju International Airport", "city_name":"Cheongju"},
 {"airport_iata_code":"YEC", "airport_name":"Yecheon Airport", "city_name":""},
 {"airport_iata_code":"PRN", "airport_name":"Pri\u0161tina International Airport", "city_name":"Prishtina"},
 {"airport_iata_code":"KWI", "airport_name":"Kuwait International Airport", "city_name":"Kuwait City"},
 {"airport_iata_code":"CYB", "airport_name":"Gerrard Smith International Airport", "city_name":"Cayman Brac"},
 {"airport_iata_code":"LYB", "airport_name":"Edward Bodden Airfield", "city_name":"Little Cayman"},
 {"airport_iata_code":"GCM", "airport_name":"Owen Roberts International Airport", "city_name":"Georgetown"},
 {"airport_iata_code":"TDK", "airport_name":"Taldykorgan Airport", "city_name":"Taldy Kurgan"},
 {"airport_iata_code":"ATX", "airport_name":"Atbasar Airport", "city_name":"Atbasar"},
 {"airport_iata_code":"ALA", "airport_name":"Almaty Airport", "city_name":"Almaty"},
 {"airport_iata_code":"BXH", "airport_name":"Balkhash Airport", "city_name":"Balkhash"},
 {"airport_iata_code":"BXJ", "airport_name":"Boralday Airport", "city_name":"Aima Ata"},
 {"airport_iata_code":"TSE", "airport_name":"Astana International Airport", "city_name":"Astana"},
 {"airport_iata_code":"KOV", "airport_name":"Kokshetau Airport", "city_name":"Kokshetau"},
 {"airport_iata_code":"PPK", "airport_name":"Petropavlosk South Airport", "city_name":"Petropavlosk"},
 {"airport_iata_code":"DMB", "airport_name":"Taraz Airport", "city_name":"Taraz"},
 {"airport_iata_code":"CIT", "airport_name":"Shymkent Airport", "city_name":"Shymkent"},
 {"airport_iata_code":"DZN", "airport_name":"Zhezkazgan Airport", "city_name":"Zhezkazgan"},
 {"airport_iata_code":"KGF", "airport_name":"Sary-Arka Airport", "city_name":"Karaganda"},
 {"airport_iata_code":"KZO", "airport_name":"Kzyl-Orda Southwest Airport", "city_name":"Kzyl-Orda"},
 {"airport_iata_code":"URA", "airport_name":"Uralsk Airport", "city_name":"Uralsk"},
 {"airport_iata_code":"EKB", "airport_name":"Ekibastuz Airport", "city_name":"Ekibastuz"},
 {"airport_iata_code":"UKK", "airport_name":"Ust-Kamennogorsk Airport", "city_name":"Ust Kamenogorsk"},
 {"airport_iata_code":"PWQ", "airport_name":"Pavlodar Airport", "city_name":"Pavlodar"},
 {"airport_iata_code":"DLX", "airport_name":"Semipalatinsk Airport", "city_name":"Semey"},
 {"airport_iata_code":"SCO", "airport_name":"Aktau Airport", "city_name":"Aktau"},
 {"airport_iata_code":"GUW", "airport_name":"Atyrau Airport", "city_name":"Atyrau"},
 {"airport_iata_code":"AKX", "airport_name":"Aktobe Airport", "city_name":"Aktyuinsk"},
 {"airport_iata_code":"AYK", "airport_name":"Arkalyk North Airport", "city_name":"Arkalyk"},
 {"airport_iata_code":"KSN", "airport_name":"Kostanay West Airport", "city_name":"Kostanay"},
 {"airport_iata_code":"PCQ", "airport_name":"Bounneau Airport", "city_name":"Phongsaly"},
 {"airport_iata_code":"AOU", "airport_name":"Attopeu Airport", "city_name":"Attopeu"},
 {"airport_iata_code":"OUI", "airport_name":"Ban Huoeisay Airport", "city_name":""},
 {"airport_iata_code":"LPQ", "airport_name":"Luang Phabang International Airport", "city_name":"Luang Phabang"},
 {"airport_iata_code":"LXG", "airport_name":"Luang Namtha Airport", "city_name":"Luang Namtha"},
 {"airport_iata_code":"ODY", "airport_name":"Oudomsay Airport", "city_name":"Oudomsay"},
 {"airport_iata_code":"PKZ", "airport_name":"Pakse International Airport", "city_name":"Pakse"},
 {"airport_iata_code":"ZBY", "airport_name":"Sayaboury Airport", "city_name":"Sayaboury"},
 {"airport_iata_code":"ZVK", "airport_name":"Savannakhet Airport", "city_name":""},
 {"airport_iata_code":"NEU", "airport_name":"Sam Neua Airport", "city_name":""},
 {"airport_iata_code":"VNA", "airport_name":"Saravane Airport", "city_name":"Saravane"},
 {"airport_iata_code":"THK", "airport_name":"Thakhek Airport", "city_name":"Thakhek"},
 {"airport_iata_code":"VTE", "airport_name":"Wattay International Airport", "city_name":"Vientiane"},
 {"airport_iata_code":"XKH", "airport_name":"Xieng Khouang Airport", "city_name":"Xieng Khouang"},
 {"airport_iata_code":"BEY", "airport_name":"Beirut Rafic Hariri International Airport", "city_name":"Beirut"},
 {"airport_iata_code":"KYE", "airport_name":"Rene Mouawad Air Base", "city_name":"Tripoli"},
 {"airport_iata_code":"SLU", "airport_name":"George F. L. Charles Airport", "city_name":"Castries"},
 {"airport_iata_code":"UVF", "airport_name":"Hewanorra International Airport", "city_name":"Vieux Fort"},
 {"airport_iata_code":"CMB", "airport_name":"Bandaranaike International Colombo Airport", "city_name":"Colombo"},
 {"airport_iata_code":"ACJ", "airport_name":"Anuradhapura Air Force Base", "city_name":"Anuradhapura"},
 {"airport_iata_code":"BTC", "airport_name":"Batticaloa Airport", "city_name":"Batticaloa"},
 {"airport_iata_code":"RML", "airport_name":"Colombo Ratmalana Airport", "city_name":"Colombo"},
 {"airport_iata_code":"GOY", "airport_name":"Amparai Airport", "city_name":"Amparai"},
 {"airport_iata_code":"MNH", "airport_name":"Hingurakgoda Air Force Base", "city_name":"Polonnaruwa Town"},
 {"airport_iata_code":"JAF", "airport_name":"Kankesanturai Airport", "city_name":"Jaffna"},
 {"airport_iata_code":"KCT", "airport_name":"Koggala Airport", "city_name":"Galle"},
 {"airport_iata_code":"GIU", "airport_name":"Sigiriya Air Force Base", "city_name":"Sigiriya"},
 {"airport_iata_code":"TRR", "airport_name":"China Bay Airport", "city_name":"Trincomalee"},
 {"airport_iata_code":"WRZ", "airport_name":"Weerawila International Airport", "city_name":"Weerawila"},
 {"airport_iata_code":"BYL", "airport_name":"Bella Yella Airport", "city_name":"Beliyela"},
 {"airport_iata_code":"UCN", "airport_name":"Buchanan Airport", "city_name":"Buchanan"},
 {"airport_iata_code":"CPA", "airport_name":"Cape Palmas Airport", "city_name":"Harper"},
 {"airport_iata_code":"SNI", "airport_name":"Greenville Sinoe Airport", "city_name":"Greenville"},
 {"airport_iata_code":"MLW", "airport_name":"Spriggs Payne Airport", "city_name":"Monrovia"},
 {"airport_iata_code":"NIA", "airport_name":"Nimba Airport", "city_name":"Nimba"},
 {"airport_iata_code":"ROB", "airport_name":"Roberts International Airport", "city_name":"Monrovia"},
 {"airport_iata_code":"SAZ", "airport_name":"Sasstown Airport", "city_name":"Sasstown"},
 {"airport_iata_code":"THC", "airport_name":"Tchien Airport", "city_name":"Tchien"},
 {"airport_iata_code":"VOI", "airport_name":"Voinjama Airport", "city_name":"Voinjama"},
 {"airport_iata_code":"LEF", "airport_name":"Lebakeng Airport", "city_name":"Lebakeng"},
 {"airport_iata_code":"LRB", "airport_name":"Leribe Airport", "city_name":"Leribe"},
 {"airport_iata_code":"LES", "airport_name":"Lesobeng Airport", "city_name":"Lesobeng"},
 {"airport_iata_code":"MFC", "airport_name":"Mafeteng Airport", "city_name":"Mafeteng"},
 {"airport_iata_code":"MKH", "airport_name":"Mokhotlong Airport", "city_name":"Mokhotlong"},
 {"airport_iata_code":"MSU", "airport_name":"Moshoeshoe I International Airport", "city_name":"Maseru"},
 {"airport_iata_code":"NKU", "airport_name":"Nkaus Airport", "city_name":"Nkaus"},
 {"airport_iata_code":"PEL", "airport_name":"Pelaneng Airport", "city_name":"Pelaneng"},
 {"airport_iata_code":"UTG", "airport_name":"Quthing Airport", "city_name":"Quthing"},
 {"airport_iata_code":"UNE", "airport_name":"Qacha's Nek Airport", "city_name":"Qacha's Nek"},
 {"airport_iata_code":"SHK", "airport_name":"Sehonghong Airport", "city_name":"Sehonghong"},
 {"airport_iata_code":"SKQ", "airport_name":"Sekakes Airport", "city_name":"Sekakes"},
 {"airport_iata_code":"SOK", "airport_name":"Semonkong Airport", "city_name":"Semonkong"},
 {"airport_iata_code":"SHZ", "airport_name":"Seshutes Airport", "city_name":"Seshutes"},
 {"airport_iata_code":"THB", "airport_name":"Thaba-Tseka Airport", "city_name":"Thaba-Tseka"},
 {"airport_iata_code":"TKO", "airport_name":"Tlokoeng Airport", "city_name":"Tlokoeng"},
 {"airport_iata_code":"KUN", "airport_name":"Kaunas International Airport", "city_name":"Kaunas"},
 {"airport_iata_code":"KLJ", "airport_name":"Klaipeda Airport", "city_name":"Klaipeda"},
 {"airport_iata_code":"PLQ", "airport_name":"Palanga International Airport", "city_name":"Palanga"},
 {"airport_iata_code":"PNV", "airport_name":"Panev?\u017eys Air Base", "city_name":"Panev?\u017eys"},
 {"airport_iata_code":"SQQ", "airport_name":"\u0160iauliai International Airport", "city_name":"\u0160iauliai"},
 {"airport_iata_code":"HLJ", "airport_name":"Barysiai Airport", "city_name":"Barysiai"},
 {"airport_iata_code":"VNO", "airport_name":"Vilnius International Airport", "city_name":"Vilnius"},
 {"airport_iata_code":"LUX", "airport_name":"Luxembourg-Findel International Airport", "city_name":"Luxembourg"},
 {"airport_iata_code":"DGP", "airport_name":"Daugavpils Intrenational Airport", "city_name":"Daugavpils"},
 {"airport_iata_code":"LPX", "airport_name":"Liep?ja International Airport", "city_name":"Liep?ja"},
 {"airport_iata_code":"RIX", "airport_name":"Riga International Airport", "city_name":"Riga"},
 {"airport_iata_code":"VNT", "airport_name":"Ventspils International Airport", "city_name":"Ventspils"},
 {"airport_iata_code":"SRX", "airport_name":"Gardabya Airport", "city_name":"Sirt"},
 {"airport_iata_code":"TOB", "airport_name":"Gamal Abdel Nasser Airport", "city_name":"Tobruk"},
 {"airport_iata_code":"GHT", "airport_name":"Ghat Airport", "city_name":"Ghat"},
 {"airport_iata_code":"AKF", "airport_name":"Kufra Airport", "city_name":"Kufra"},
 {"airport_iata_code":"BEN", "airport_name":"Benina International Airport", "city_name":"Benghazi"},
 {"airport_iata_code":"MJI", "airport_name":"Mitiga Airport", "city_name":"Tripoli"},
 {"airport_iata_code":"LAQ", "airport_name":"La Abraq Airport", "city_name":"Al Bayda'"},
 {"airport_iata_code":"SEB", "airport_name":"Sabha Airport", "city_name":"Sabha"},
 {"airport_iata_code":"TIP", "airport_name":"Tripoli International Airport", "city_name":"Tripoli"},
 {"airport_iata_code":"LMQ", "airport_name":"Marsa Brega Airport", "city_name":""},
 {"airport_iata_code":"HUQ", "airport_name":"Hon Airport", "city_name":""},
 {"airport_iata_code":"LTD", "airport_name":"Ghadames East Airport", "city_name":"Ghadames"},
 {"airport_iata_code":"WAX", "airport_name":"Zwara Airport", "city_name":"Zuwara"},
 {"airport_iata_code":"BCQ", "airport_name":"Brack Airport", "city_name":"Brack"},
 {"airport_iata_code":"DNF", "airport_name":"Martubah Airport", "city_name":"Derna"},
 {"airport_iata_code":"MRA", "airport_name":"Misratah Airport", "city_name":"Misratah"},
 {"airport_iata_code":"QUB", "airport_name":"Ubari Airport", "city_name":"Ubari"},
 {"airport_iata_code":"GLN", "airport_name":"Goulimime Airport", "city_name":"Goulimime"},
 {"airport_iata_code":"AGA", "airport_name":"Al Massira Airport", "city_name":"Agadir"},
 {"airport_iata_code":"TTA", "airport_name":"Tan Tan Airport", "city_name":"Tan Tan"},
 {"airport_iata_code":"OZG", "airport_name":"Zagora Airport", "city_name":"Zagora"},
 {"airport_iata_code":"UAR", "airport_name":"Bouarfa Airport", "city_name":"Bouarfa"},
 {"airport_iata_code":"FEZ", "airport_name":"Sa\u00efss Airport", "city_name":"Fes"},
 {"airport_iata_code":"ERH", "airport_name":"Moulay Ali Cherif Airport", "city_name":"Errachidia"},
 {"airport_iata_code":"MEK", "airport_name":"Bassatine Airport", "city_name":"Meknes"},
 {"airport_iata_code":"OUD", "airport_name":"Angads Airport", "city_name":"Oujda"},
 {"airport_iata_code":"GMD", "airport_name":"Ben Slimane Airport", "city_name":"Ben Slimane"},
 {"airport_iata_code":"CAS", "airport_name":"Anfa Airport", "city_name":"Casablanca"},
 {"airport_iata_code":"RBA", "airport_name":"Rabat-Sal\u00e9 Airport", "city_name":"Rabat"},
 {"airport_iata_code":"SII", "airport_name":"Sidi Ifni Xx Airport", "city_name":"Sidi Ifni"},
 {"airport_iata_code":"ESU", "airport_name":"Mogador Airport", "city_name":"Essaouira"},
 {"airport_iata_code":"CMN", "airport_name":"Mohammed V International Airport", "city_name":"Casablanca"},
 {"airport_iata_code":"SFI", "airport_name":"Safi Airport", "city_name":"Safi"},
 {"airport_iata_code":"NDR", "airport_name":"Nador International Airport", "city_name":"Nador"},
 {"airport_iata_code":"RAK", "airport_name":"Menara Airport", "city_name":"Marrakech"},
 {"airport_iata_code":"NNA", "airport_name":"Kenitra Airport", "city_name":""},
 {"airport_iata_code":"OZZ", "airport_name":"Ouarzazate Airport", "city_name":"Ouarzazate"},
 {"airport_iata_code":"AHU", "airport_name":"Cherif Al Idrissi Airport", "city_name":"Al Hoceima"},
 {"airport_iata_code":"TTU", "airport_name":"Saniat Rmel Airport", "city_name":""},
 {"airport_iata_code":"TNG", "airport_name":"Ibn Batouta Airport", "city_name":"Tangier"},
 {"airport_iata_code":"BZY", "airport_name":"Balti International Airport", "city_name":"Strymba"},
 {"airport_iata_code":"KIV", "airport_name":"Chi?in?u International Airport", "city_name":"Chi?in?u"},
 {"airport_iata_code":"IVG", "airport_name":"Berane Airport", "city_name":"Berane"},
 {"airport_iata_code":"TGD", "airport_name":"Podgorica Airport", "city_name":"Podgorica"},
 {"airport_iata_code":"TIV", "airport_name":"Tivat Airport", "city_name":"Tivat"},
 {"airport_iata_code":"ZBK", "airport_name":"\u017dabljak Airport", "city_name":"\u017dabljak Airport"},
 {"airport_iata_code":"SFG", "airport_name":"L'Esp\u00e9rance Airport", "city_name":"Grand Case"},
 {"airport_iata_code":"DOA", "airport_name":"Doany Airport", "city_name":"Doany"},
 {"airport_iata_code":"AHY", "airport_name":"Ambatolhy Airport", "city_name":"Ambatolahy"},
 {"airport_iata_code":"AMY", "airport_name":"Ambatomainty Airport", "city_name":"Ambatomainty"},
 {"airport_iata_code":"BSV", "airport_name":"Besakoa Airport", "city_name":"Besakoa"},
 {"airport_iata_code":"BZM", "airport_name":"Bemolanga Airport", "city_name":"Bemolanga"},
 {"airport_iata_code":"WML", "airport_name":"Malaimbandy Airport", "city_name":"Malaimbandy"},
 {"airport_iata_code":"ATJ", "airport_name":"Antsirabe Airport", "city_name":"Antsirabe"},
 {"airport_iata_code":"WAQ", "airport_name":"Antsalova Airport", "city_name":"Antsalova"},
 {"airport_iata_code":"VVB", "airport_name":"Mahanoro Airport", "city_name":"Mahanoro"},
 {"airport_iata_code":"TNR", "airport_name":"Ivato Airport", "city_name":"Antananarivo"},
 {"airport_iata_code":"JVA", "airport_name":"Ankavandra Airport", "city_name":"Ankavandra"},
 {"airport_iata_code":"BMD", "airport_name":"Belo sur Tsiribihina Airport", "city_name":"Belo sur Tsiribihina"},
 {"airport_iata_code":"ZVA", "airport_name":"Miandrivazo Airport", "city_name":""},
 {"airport_iata_code":"MXT", "airport_name":"Maintirano Airport", "city_name":"Maintirano"},
 {"airport_iata_code":"ILK", "airport_name":"Atsinanana Airport", "city_name":"Ilaka"},
 {"airport_iata_code":"TVA", "airport_name":"Morafenobe Airport", "city_name":"Morafenobe"},
 {"airport_iata_code":"SMS", "airport_name":"Sainte Marie Airport", "city_name":""},
 {"airport_iata_code":"TMM", "airport_name":"Toamasina Airport", "city_name":""},
 {"airport_iata_code":"WTA", "airport_name":"Tambohorano Airport", "city_name":"Tambohorano"},
 {"airport_iata_code":"MOQ", "airport_name":"Morondava Airport", "city_name":""},
 {"airport_iata_code":"WTS", "airport_name":"Tsiroanomandidy Airport", "city_name":"Tsiroanomandidy"},
 {"airport_iata_code":"VAT", "airport_name":"Vatomandry Airport", "city_name":"Vatomandry"},
 {"airport_iata_code":"WAM", "airport_name":"Ambatondrazaka Airport", "city_name":"Ambatondrazaka"},
 {"airport_iata_code":"DIE", "airport_name":"Arrachart Airport", "city_name":""},
 {"airport_iata_code":"WMR", "airport_name":"Mananara Nord Airport", "city_name":"Mananara Nord"},
 {"airport_iata_code":"ZWA", "airport_name":"Andapa Airport", "city_name":""},
 {"airport_iata_code":"AMB", "airport_name":"Ambilobe Airport", "city_name":""},
 {"airport_iata_code":"WBD", "airport_name":"Avaratra Airport", "city_name":"Befandriana"},
 {"airport_iata_code":"WPB", "airport_name":"Port Berg\u00e9 Airport", "city_name":"Port Berg\u00e9"},
 {"airport_iata_code":"ANM", "airport_name":"Antsirabato Airport", "city_name":""},
 {"airport_iata_code":"IVA", "airport_name":"Ambanja Airport", "city_name":"Ambanja"},
 {"airport_iata_code":"HVA", "airport_name":"Analalava Airport", "city_name":""},
 {"airport_iata_code":"MJN", "airport_name":"Amborovy Airport", "city_name":""},
 {"airport_iata_code":"NOS", "airport_name":"Fascene Airport", "city_name":"Nosy Be"},
 {"airport_iata_code":"DWB", "airport_name":"Soalala Airport", "city_name":"Soalala"},
 {"airport_iata_code":"WMP", "airport_name":"Mampikony Airport", "city_name":"Mampikony"},
 {"airport_iata_code":"BPY", "airport_name":"Besalampy Airport", "city_name":""},
 {"airport_iata_code":"WMN", "airport_name":"Maroantsetra Airport", "city_name":""},
 {"airport_iata_code":"SVB", "airport_name":"Sambava Airport", "city_name":""},
 {"airport_iata_code":"TTS", "airport_name":"Tsaratanana Airport", "city_name":"Tsaratanana"},
 {"airport_iata_code":"VOH", "airport_name":"Vohimarina Airport", "city_name":""},
 {"airport_iata_code":"WAI", "airport_name":"Ambalabe Airport", "city_name":"Antsohihy"},
 {"airport_iata_code":"WMA", "airport_name":"Mandritsara Airport", "city_name":"Mandritsara"},
 {"airport_iata_code":"WBO", "airport_name":"Antsoa Airport", "city_name":"Beroroha"},
 {"airport_iata_code":"WMD", "airport_name":"Mandabe Airport", "city_name":"Mandabe"},
 {"airport_iata_code":"FTU", "airport_name":"T\u00f4lanaro Airport", "city_name":"T\u00f4lanaro"},
 {"airport_iata_code":"WFI", "airport_name":"Fianarantsoa Airport", "city_name":""},
 {"airport_iata_code":"RVA", "airport_name":"Farafangana Airport", "city_name":""},
 {"airport_iata_code":"IHO", "airport_name":"Ihosy Airport", "city_name":"Ihosy"},
 {"airport_iata_code":"MJA", "airport_name":"Manja Airport", "city_name":"Manja"},
 {"airport_iata_code":"WVK", "airport_name":"Manakara Airport", "city_name":""},
 {"airport_iata_code":"OVA", "airport_name":"Bekily Airport", "city_name":"Bekily"},
 {"airport_iata_code":"MNJ", "airport_name":"Mananjary Airport", "city_name":""},
 {"airport_iata_code":"TDV", "airport_name":"Samangoky Airport", "city_name":"Tanandava"},
 {"airport_iata_code":"MXM", "airport_name":"Morombe Airport", "city_name":""},
 {"airport_iata_code":"TLE", "airport_name":"Toliara Airport", "city_name":""},
 {"airport_iata_code":"VND", "airport_name":"Vangaindrano Airport", "city_name":"Vangaindrano"},
 {"airport_iata_code":"BKU", "airport_name":"Betioky Airport", "city_name":"Betioky"},
 {"airport_iata_code":"AMP", "airport_name":"Ampanihy Airport", "city_name":"Ampanihy"},
 {"airport_iata_code":"WAK", "airport_name":"Ankazoabo Airport", "city_name":"Ankazoabo"},
 {"airport_iata_code":"UTK", "airport_name":"Utirik Airport", "city_name":"Utirik Island"},
 {"airport_iata_code":"AIP", "airport_name":"Ailinglaplap Airport", "city_name":"Bigatyelang Island"},
 {"airport_iata_code":"EAL", "airport_name":"Elenak Airport", "city_name":"Mejato Island"},
 {"airport_iata_code":"ENT", "airport_name":"Eniwetok Airport", "city_name":"Eniwetok Atoll"},
 {"airport_iata_code":"MAJ", "airport_name":"Marshall Islands International Airport", "city_name":"Majuro Atoll"},
 {"airport_iata_code":"KWA", "airport_name":"Bucholz Army Air Field", "city_name":"Kwajalein"},
 {"airport_iata_code":"KIO", "airport_name":"Kili Airport", "city_name":"Kili Island"},
 {"airport_iata_code":"UJE", "airport_name":"Ujae Atoll Airport", "city_name":"Ujae Atoll"},
 {"airport_iata_code":"AIM", "airport_name":"Ailuk Airport", "city_name":"Ailuk Island"},
 {"airport_iata_code":"AUL", "airport_name":"Aur Island Airport", "city_name":"Aur Atoll"},
 {"airport_iata_code":"BII", "airport_name":"Enyu Airfield", "city_name":"Bikini Atoll"},
 {"airport_iata_code":"EBN", "airport_name":"Ebadon Airport", "city_name":"Ebadon Island"},
 {"airport_iata_code":"JAT", "airport_name":"Jabot Airport", "city_name":"Ailinglapalap Atoll"},
 {"airport_iata_code":"JEJ", "airport_name":"Jeh Airport", "city_name":"Ailinglapalap Atoll"},
 {"airport_iata_code":"KBT", "airport_name":"Kaben Airport", "city_name":"Kaben"},
 {"airport_iata_code":"LIK", "airport_name":"Likiep Airport", "city_name":"Likiep Island"},
 {"airport_iata_code":"LML", "airport_name":"Lae Island Airport", "city_name":"Lae Island"},
 {"airport_iata_code":"MAV", "airport_name":"Maloelap Island Airport", "city_name":"Maloelap Island"},
 {"airport_iata_code":"MJB", "airport_name":"Mejit Atoll Airport", "city_name":"Mejit Atoll"},
 {"airport_iata_code":"MJE", "airport_name":"Majkin Airport", "city_name":"Majkin"},
 {"airport_iata_code":"NDK", "airport_name":"Namorik Atoll Airport", "city_name":"Namorik Atoll"},
 {"airport_iata_code":"RNP", "airport_name":"Rongelap Island Airport", "city_name":"Rongelap Island"},
 {"airport_iata_code":"TIC", "airport_name":"Tinak Airport", "city_name":"Arno Atoll"},
 {"airport_iata_code":"UIT", "airport_name":"Jaluit Airport", "city_name":"Jabor Jaluit Atoll"},
 {"airport_iata_code":"WJA", "airport_name":"Woja Airport", "city_name":"Woja"},
 {"airport_iata_code":"WTE", "airport_name":"Wotje Atoll Airport", "city_name":"Wotje Atoll"},
 {"airport_iata_code":"WTO", "airport_name":"Wotho Island Airport", "city_name":"Wotho Island"},
 {"airport_iata_code":"MIJ", "airport_name":"Mili Island Airport", "city_name":"Mili Island"},
 {"airport_iata_code":"OHD", "airport_name":"Ohrid St. Paul the Apostle Airport", "city_name":"Ohrid"},
 {"airport_iata_code":"SKP", "airport_name":"Skopje Alexander the Great Airport", "city_name":"Skopje"},
 {"airport_iata_code":"BKO", "airport_name":"Senou Airport", "city_name":"Senou"},
 {"airport_iata_code":"GUD", "airport_name":"Goundam Airport", "city_name":"Goundam"},
 {"airport_iata_code":"GAQ", "airport_name":"Gao Airport", "city_name":""},
 {"airport_iata_code":"KNZ", "airport_name":"Kenieba Airport", "city_name":"Kenieba"},
 {"airport_iata_code":"KTX", "airport_name":"Koutiala Airport", "city_name":"Koutiala"},
 {"airport_iata_code":"KYS", "airport_name":"Kayes Dag Dag Airport", "city_name":""},
 {"airport_iata_code":"MZI", "airport_name":"Ambodedjo Airport", "city_name":""},
 {"airport_iata_code":"NRM", "airport_name":"Nara Airport", "city_name":"Nara"},
 {"airport_iata_code":"NIX", "airport_name":"Nioro du Sahel Airport", "city_name":"Nioro du Sahel"},
 {"airport_iata_code":"KSS", "airport_name":"Sikasso Airport", "city_name":"Sikasso"},
 {"airport_iata_code":"TOM", "airport_name":"Timbuktu Airport", "city_name":"Timbuktu"},
 {"airport_iata_code":"EYL", "airport_name":"Y\u00e9liman\u00e9 Airport", "city_name":"Y\u00e9liman\u00e9"},
 {"airport_iata_code":"HEB", "airport_name":"Henzada Airport", "city_name":"Henzada"},
 {"airport_iata_code":"NYU", "airport_name":"Bagan Airport", "city_name":"Nyaung U"},
 {"airport_iata_code":"BMO", "airport_name":"Banmaw Airport", "city_name":"Banmaw"},
 {"airport_iata_code":"TVY", "airport_name":"Dawei Airport", "city_name":"Dawei"},
 {"airport_iata_code":"NYT", "airport_name":"Naypyidaw Airport", "city_name":"Pyinmana"},
 {"airport_iata_code":"GAW", "airport_name":"Gangaw Airport", "city_name":"Gangaw"},
 {"airport_iata_code":"GWA", "airport_name":"Gwa Airport", "city_name":"Gwa"},
 {"airport_iata_code":"HEH", "airport_name":"Heho Airport", "city_name":"Heho"},
 {"airport_iata_code":"HOX", "airport_name":"Hommalinn Airport", "city_name":"Hommalinn"},
 {"airport_iata_code":"TIO", "airport_name":"Tilin Airport", "city_name":"Tilin"},
 {"airport_iata_code":"KET", "airport_name":"Kengtung Airport", "city_name":"Kengtung"},
 {"airport_iata_code":"KHM", "airport_name":"Kanti Airport", "city_name":"Kanti"},
 {"airport_iata_code":"KMV", "airport_name":"Kalay Airport", "city_name":"Kalemyo"},
 {"airport_iata_code":"KYP", "airport_name":"Kyaukpyu Airport", "city_name":"Kyaukpyu"},
 {"airport_iata_code":"KAW", "airport_name":"Kawthoung Airport", "city_name":"Kawthoung"},
 {"airport_iata_code":"KYT", "airport_name":"Kyauktu Airport", "city_name":"Kyauktu"},
 {"airport_iata_code":"LIW", "airport_name":"Loikaw Airport", "city_name":"Loikaw"},
 {"airport_iata_code":"LSH", "airport_name":"Lashio Airport", "city_name":"Lashio"},
 {"airport_iata_code":"MDL", "airport_name":"Mandalay International Airport", "city_name":"Mandalay"},
 {"airport_iata_code":"MGZ", "airport_name":"Myeik Airport", "city_name":"Mkeik"},
 {"airport_iata_code":"MYT", "airport_name":"Myitkyina Airport", "city_name":"Myitkyina"},
 {"airport_iata_code":"MNU", "airport_name":"Mawlamyine Airport", "city_name":"Mawlamyine"},
 {"airport_iata_code":"MGU", "airport_name":"Manaung Airport", "city_name":"Manaung"},
 {"airport_iata_code":"MOE", "airport_name":"Momeik Airport", "city_name":""},
 {"airport_iata_code":"MOG", "airport_name":"Mong Hsat Airport", "city_name":"Mong Hsat"},
 {"airport_iata_code":"MGK", "airport_name":"Mong Tong Airport", "city_name":"Mong Tong"},
 {"airport_iata_code":"MWQ", "airport_name":"Magway Airport", "city_name":"Magway"},
 {"airport_iata_code":"NMS", "airport_name":"Namsang Airport", "city_name":"Namsang"},
 {"airport_iata_code":"NMT", "airport_name":"Namtu Airport", "city_name":"Namtu"},
 {"airport_iata_code":"PAA", "airport_name":"Hpa-N Airport", "city_name":"Hpa-N"},
 {"airport_iata_code":"PAU", "airport_name":"Pauk Airport", "city_name":"Pauk"},
 {"airport_iata_code":"BSX", "airport_name":"Pathein Airport", "city_name":"Pathein"},
 {"airport_iata_code":"PPU", "airport_name":"Hpapun Airport", "city_name":"Pa Pun"},
 {"airport_iata_code":"PBU", "airport_name":"Putao Airport", "city_name":"Putao"},
 {"airport_iata_code":"PKK", "airport_name":"Pakhokku Airport", "city_name":"Pakhokku"},
 {"airport_iata_code":"PRU", "airport_name":"Pyay Airport", "city_name":"Pye"},
 {"airport_iata_code":"AKY", "airport_name":"Sittwe Airport", "city_name":"Sittwe"},
 {"airport_iata_code":"SNW", "airport_name":"Thandwe Airport", "city_name":"Thandwe"},
 {"airport_iata_code":"THL", "airport_name":"Tachileik Airport", "city_name":"Tachileik"},
 {"airport_iata_code":"XYE", "airport_name":"Ye Airport", "city_name":"Ye"},
 {"airport_iata_code":"RGN", "airport_name":"Yangon International Airport", "city_name":"Yangon"},
 {"airport_iata_code":"AVK", "airport_name":"Arvaikheer Airport", "city_name":"Arvaikheer"},
 {"airport_iata_code":"LTI", "airport_name":"Altai Airport", "city_name":"Altai"},
 {"airport_iata_code":"BYN", "airport_name":"Bayankhongor Airport", "city_name":"Bayankhongor"},
 {"airport_iata_code":"UGA", "airport_name":"Bulgan Airport", "city_name":"Bulgan"},
 {"airport_iata_code":"UGT", "airport_name":"Bulagtai Resort Airport", "city_name":"Umnugobitour"},
 {"airport_iata_code":"HBU", "airport_name":"Bulgan Sum Airport", "city_name":""},
 {"airport_iata_code":"UUN", "airport_name":"Baruun Urt Airport", "city_name":""},
 {"airport_iata_code":"COQ", "airport_name":"Choibalsan Airport", "city_name":""},
 {"airport_iata_code":"ULZ", "airport_name":"Donoi Airport", "city_name":"Uliastai"},
 {"airport_iata_code":"DLZ", "airport_name":"Dalanzadgad Airport", "city_name":"Dalanzadgad"},
 {"airport_iata_code":"KHR", "airport_name":"Kharkhorin Airport", "city_name":""},
 {"airport_iata_code":"HJT", "airport_name":"Khujirt Airport", "city_name":"Khujirt"},
 {"airport_iata_code":"HVD", "airport_name":"Khovd Airport", "city_name":"Khovd"},
 {"airport_iata_code":"MXV", "airport_name":"M\u00f6r\u00f6n Airport", "city_name":"M\u00f6r\u00f6n"},
 {"airport_iata_code":"TSZ", "airport_name":"Tselserleg Airport", "city_name":"Tselserleg"},
 {"airport_iata_code":"TNZ", "airport_name":"Tosontsengel Airport", "city_name":"Tosontsengel"},
 {"airport_iata_code":"ULN", "airport_name":"Chinggis Khaan International Airport", "city_name":"Ulan Bator"},
 {"airport_iata_code":"ULO", "airport_name":"Ulaangom Airport", "city_name":""},
 {"airport_iata_code":"UNR", "airport_name":"\u00d6nd\u00f6rkhaan Airport", "city_name":"\u00d6nd\u00f6rkhaan"},
 {"airport_iata_code":"ERT", "airport_name":"Erdenet Airport", "city_name":"Erdenet"},
 {"airport_iata_code":"HTM", "airport_name":"Khatgal Airport", "city_name":"Hatgal"},
 {"airport_iata_code":"MXW", "airport_name":"Mandalgobi Airport", "city_name":"Mandalgobi"},
 {"airport_iata_code":"ULG", "airport_name":"\u00d6lgii Airport", "city_name":"\u00d6lgii"},
 {"airport_iata_code":"MFM", "airport_name":"Macau International Airport", "city_name":"Taipa"},
 {"airport_iata_code":"ROP", "airport_name":"Rota International Airport", "city_name":"Rota Island"},
 {"airport_iata_code":"SPN", "airport_name":"Francisco C. Ada Saipan International Airport", "city_name":"Saipan Island"},
 {"airport_iata_code":"TIQ", "airport_name":"Tinian International Airport", "city_name":"Tinian Island"},
 {"airport_iata_code":"FDF", "airport_name":"Martinique Aim\u00e9 C\u00e9saire International Airport", "city_name":"Fort-de-France"},
 {"airport_iata_code":"AEO", "airport_name":"Aioun el Atrouss Airport", "city_name":"Aioun El Atrouss"},
 {"airport_iata_code":"OTL", "airport_name":"Boutilimit Airport", "city_name":"Boutilimit"},
 {"airport_iata_code":"THI", "airport_name":"Tichitt Airport", "city_name":"Tichitt"},
 {"airport_iata_code":"TIY", "airport_name":"Tidjikja Airport", "city_name":"Tidjikja"},
 {"airport_iata_code":"BGH", "airport_name":"Abbaye Airport", "city_name":"Boghe"},
 {"airport_iata_code":"KFA", "airport_name":"Kiffa Airport", "city_name":"Kiffa"},
 {"airport_iata_code":"TMD", "airport_name":"Timbedra Airport", "city_name":"Timbedra"},
 {"airport_iata_code":"EMN", "airport_name":"N\u00e9ma Airport", "city_name":"N\u00e9ma"},
 {"airport_iata_code":"AJJ", "airport_name":"Akjoujt Airport", "city_name":"Akjoujt"},
 {"airport_iata_code":"KED", "airport_name":"Ka\u00e9di Airport", "city_name":"Ka\u00e9di"},
 {"airport_iata_code":"MOM", "airport_name":"Letfotar Airport", "city_name":"Moudjeria"},
 {"airport_iata_code":"NKC", "airport_name":"Nouakchott International Airport", "city_name":"Nouakchott"},
 {"airport_iata_code":"SEY", "airport_name":"S\u00e9libaby Airport", "city_name":"S\u00e9libaby"},
 {"airport_iata_code":"THT", "airport_name":"Tamchakett Airport", "city_name":"Tamchakett"},
 {"airport_iata_code":"ATR", "airport_name":"Atar International Airport", "city_name":"Atar"},
 {"airport_iata_code":"FGD", "airport_name":"Fderik Airport", "city_name":"Fderik"},
 {"airport_iata_code":"NDB", "airport_name":"Nouadhibou International Airport", "city_name":"Nouadhibou"},
 {"airport_iata_code":"OUZ", "airport_name":"Tazadit Airport", "city_name":"Zou\u00e9rate"},
 {"airport_iata_code":"MNI", "airport_name":"John A. Osborne Airport", "city_name":"Gerald's Park"},
 {"airport_iata_code":"MLA", "airport_name":"Luqa Airport", "city_name":"Luqa"},
 {"airport_iata_code":"MRU", "airport_name":"Sir Seewoosagur Ramgoolam International Airport", "city_name":"Port Louis"},
 {"airport_iata_code":"RRG", "airport_name":"Sir Charles Gaetan Duval Airport", "city_name":"Port Mathurin"},
 {"airport_iata_code":"FVM", "airport_name":"Fuvahmulah Airport", "city_name":"Fuvahmulah Island"},
 {"airport_iata_code":"GAN", "airport_name":"Gan International Airport", "city_name":"Gan"},
 {"airport_iata_code":"HAQ", "airport_name":"Hanimaadhoo Airport", "city_name":"Haa Dhaalu Atoll"},
 {"airport_iata_code":"KDO", "airport_name":"Kadhdhoo Airport", "city_name":"Kadhdhoo"},
 {"airport_iata_code":"MLE", "airport_name":"Mal\u00e9 International Airport", "city_name":"Mal\u00e9"},
 {"airport_iata_code":"KDM", "airport_name":"Kaadedhdhoo Airport", "city_name":"Huvadhu Atoll"},
 {"airport_iata_code":"VAM", "airport_name":"Villa Airport", "city_name":"Maamigili"},
 {"airport_iata_code":"VUU", "airport_name":"Mvuu Camp Airport", "city_name":"Liwonde National Park"},
 {"airport_iata_code":"CEH", "airport_name":"Chelinda Malawi Airport", "city_name":""},
 {"airport_iata_code":"BLZ", "airport_name":"Chileka International Airport", "city_name":"Blantyre"},
 {"airport_iata_code":"CMK", "airport_name":"Club Makokola Airport", "city_name":"Club Makokola"},
 {"airport_iata_code":"DWA", "airport_name":"Dwangwa Airport", "city_name":"Dwangwa"},
 {"airport_iata_code":"KGJ", "airport_name":"Karonga Airport", "city_name":"Karonga"},
 {"airport_iata_code":"KBQ", "airport_name":"Kasungu Airport", "city_name":"Kasungu"},
 {"airport_iata_code":"LLW", "airport_name":"Lilongwe International Airport", "city_name":"Lilongwe"},
 {"airport_iata_code":"LIX", "airport_name":"Likoma Island Airport", "city_name":"Likoma Island"},
 {"airport_iata_code":"MAI", "airport_name":"Mangochi Airport", "city_name":"Mangochi"},
 {"airport_iata_code":"MYZ", "airport_name":"Monkey Bay Airport", "city_name":"Monkey Bay"},
 {"airport_iata_code":"LMB", "airport_name":"Salima Airport", "city_name":"Salima"},
 {"airport_iata_code":"ZZU", "airport_name":"Mzuzu Airport", "city_name":"Mzuzu"},
 {"airport_iata_code":"BHL", "airport_name":"Bah\u00eda de los \u00c1ngeles Airport", "city_name":"Bah\u00eda de los \u00c1ngeles"},
 {"airport_iata_code":"BLM", "airport_name":"Bahia De Los Angelos South", "city_name":""},
 {"airport_iata_code":"PCO", "airport_name":"Punta Colorada Airport", "city_name":"La Ribera"},
 {"airport_iata_code":"SNQ", "airport_name":"San Quint\u00edn Military Airstrip", "city_name":"Military Camp Number 2-D"},
 {"airport_iata_code":"ACA", "airport_name":"General Juan N Alvarez International Airport", "city_name":"Acapulco"},
 {"airport_iata_code":"NTR", "airport_name":"Del Norte International Airport", "city_name":""},
 {"airport_iata_code":"AGU", "airport_name":"Jesus Teran International Airport", "city_name":"Aguascalientes"},
 {"airport_iata_code":"HUX", "airport_name":"Bah\u00edas de Huatulco International Airport", "city_name":"Huatulco"},
 {"airport_iata_code":"CNA", "airport_name":"Cananea Airport", "city_name":""},
 {"airport_iata_code":"CVJ", "airport_name":"General Mariano Matamoros Airport", "city_name":""},
 {"airport_iata_code":"ACN", "airport_name":"Ciudad Acu\u00f1a New International Airport", "city_name":"Ciudad Acu\u00f1a"},
 {"airport_iata_code":"CME", "airport_name":"Ciudad del Carmen International Airport", "city_name":"Ciudad del Carmen"},
 {"airport_iata_code":"NCG", "airport_name":"Nuevo Casas Grandes Airport", "city_name":""},
 {"airport_iata_code":"CUL", "airport_name":"Federal de Bachigualato International Airport", "city_name":"Culiac\u00e1n"},
 {"airport_iata_code":"CTM", "airport_name":"Chetumal International Airport", "city_name":"Chetumal"},
 {"airport_iata_code":"CEN", "airport_name":"Ciudad Obreg\u00f3n International Airport", "city_name":"Ciudad Obreg\u00f3n"},
 {"airport_iata_code":"CJT", "airport_name":"Comitan Airport", "city_name":""},
 {"airport_iata_code":"CPE", "airport_name":"Ingeniero Alberto Acu\u00f1a Ongay International Airport", "city_name":"Campeche"},
 {"airport_iata_code":"CJS", "airport_name":"Abraham Gonz\u00e1lez International Airport", "city_name":"Ciudad Ju\u00e1rez"},
 {"airport_iata_code":"CZA", "airport_name":"Chichen Itza International Airport", "city_name":""},
 {"airport_iata_code":"CUU", "airport_name":"General Roberto Fierro Villalobos International Airport", "city_name":"Chihuahua"},
 {"airport_iata_code":"CVM", "airport_name":"General Pedro Jose Mendez International Airport", "city_name":"Ciudad Victoria"},
 {"airport_iata_code":"CYW", "airport_name":"Captain Rogelio Castillo National Airport", "city_name":"Celaya"},
 {"airport_iata_code":"CZM", "airport_name":"Cozumel International Airport", "city_name":"Cozumel"},
 {"airport_iata_code":"CUA", "airport_name":"Ciudad Constituci\u00f3n Airport", "city_name":"Ciudad Constituci\u00f3n"},
 {"airport_iata_code":"MMC", "airport_name":"Ciudad Mante National Airport", "city_name":"Ciudad Mante"},
 {"airport_iata_code":"DGO", "airport_name":"General Guadalupe Victoria International Airport", "city_name":"Durango"},
 {"airport_iata_code":"TPQ", "airport_name":"Amado Nervo National Airport", "city_name":"Tepic"},
 {"airport_iata_code":"ESE", "airport_name":"Ensenada Airport", "city_name":""},
 {"airport_iata_code":"GDL", "airport_name":"Don Miguel Hidalgo Y Costilla International Airport", "city_name":"Guadalajara"},
 {"airport_iata_code":"GYM", "airport_name":"General Jos\u00e9 Mar\u00eda Y\u00e1\u00f1ez International Airport", "city_name":"Guaymas"},
 {"airport_iata_code":"GUB", "airport_name":"Guerrero Negro Airport", "city_name":"Guerrero Negro"},
 {"airport_iata_code":"TCN", "airport_name":"Tehuacan Airport", "city_name":""},
 {"airport_iata_code":"HMO", "airport_name":"General Ignacio P. Garcia International Airport", "city_name":"Hermosillo"},
 {"airport_iata_code":"CLQ", "airport_name":"Lic. Miguel de la Madrid Airport", "city_name":"Colima"},
 {"airport_iata_code":"ISJ", "airport_name":"Isla Mujeres Airport", "city_name":""},
 {"airport_iata_code":"SLW", "airport_name":"Plan De Guadalupe International Airport", "city_name":"Saltillo"},
 {"airport_iata_code":"IZT", "airport_name":"Ixtepec Airport", "city_name":""},
 {"airport_iata_code":"JAL", "airport_name":"El Lencero Airport", "city_name":"Xalapa"},
 {"airport_iata_code":"AZP", "airport_name":"Atizapan De Zaragoza Airport", "city_name":""},
 {"airport_iata_code":"LZC", "airport_name":"L\u00e1zaro C\u00e1rdenas Airport", "city_name":"L\u00e1zaro C\u00e1rdenas"},
 {"airport_iata_code":"LMM", "airport_name":"Valle del Fuerte International Airport", "city_name":"Los Mochis"},
 {"airport_iata_code":"BJX", "airport_name":"Del Baj\u00edo International Airport", "city_name":"Silao"},
 {"airport_iata_code":"LAP", "airport_name":"Manuel M\u00e1rquez de Le\u00f3n International Airport", "city_name":"La Paz"},
 {"airport_iata_code":"LTO", "airport_name":"Loreto International Airport", "city_name":"Loreto"},
 {"airport_iata_code":"MAM", "airport_name":"General Servando Canales International Airport", "city_name":"Matamoros"},
 {"airport_iata_code":"MID", "airport_name":"Licenciado Manuel Crescencio Rejon Int Airport", "city_name":"M\u00e9rida"},
 {"airport_iata_code":"MUG", "airport_name":"Mulege Airport", "city_name":"Mulege"},
 {"airport_iata_code":"MXL", "airport_name":"General Rodolfo S\u00e1nchez Taboada International Airport", "city_name":"Mexicali"},
 {"airport_iata_code":"MLM", "airport_name":"General Francisco J. Mujica International Airport", "city_name":"Morelia"},
 {"airport_iata_code":"MTT", "airport_name":"Minatitl\u00e1n/Coatzacoalcos National Airport", "city_name":"Minatitl\u00e1n"},
 {"airport_iata_code":"LOV", "airport_name":"Monclova International Airport", "city_name":""},
 {"airport_iata_code":"MEX", "airport_name":"Licenciado Benito Juarez International Airport", "city_name":"Mexico City"},
 {"airport_iata_code":"MTY", "airport_name":"General Mariano Escobedo International Airport", "city_name":"Monterrey"},
 {"airport_iata_code":"MZT", "airport_name":"General Rafael Buelna International Airport", "city_name":"Mazatl\u00e1n"},
 {"airport_iata_code":"NOG", "airport_name":"Nogales International Airport", "city_name":""},
 {"airport_iata_code":"NLD", "airport_name":"Quetzalc\u00f3atl International Airport", "city_name":"Nuevo Laredo"},
 {"airport_iata_code":"OAX", "airport_name":"Xoxocotl\u00e1n International Airport", "city_name":"Oaxaca"},
 {"airport_iata_code":"PAZ", "airport_name":"El Taj\u00edn National Airport", "city_name":"Poza Rica"},
 {"airport_iata_code":"PBC", "airport_name":"Hermanos Serd\u00e1n International Airport", "city_name":"Puebla"},
 {"airport_iata_code":"PPE", "airport_name":"Puerto Pe\u00f1asco International Airport", "city_name":"Puerto Pe\u00f1asco"},
 {"airport_iata_code":"PDS", "airport_name":"Piedras Negras International Airport", "city_name":""},
 {"airport_iata_code":"UPN", "airport_name":"Licenciado y General Ignacio Lopez Rayon Airport", "city_name":""},
 {"airport_iata_code":"PQM", "airport_name":"Palenque International Airport", "city_name":""},
 {"airport_iata_code":"PVR", "airport_name":"Licenciado Gustavo D\u00edaz Ordaz International Airport", "city_name":"Puerto Vallarta"},
 {"airport_iata_code":"PXM", "airport_name":"Puerto Escondido International Airport", "city_name":"Puerto Escondido"},
 {"airport_iata_code":"QRO", "airport_name":"Quer\u00e9taro Intercontinental Airport", "city_name":"Quer\u00e9taro"},
 {"airport_iata_code":"REX", "airport_name":"General Lucio Blanco International Airport", "city_name":"Reynosa"},
 {"airport_iata_code":"SZT", "airport_name":"San Cristobal De Las Casas Airport", "city_name":""},
 {"airport_iata_code":"SJD", "airport_name":"Los Cabos International Airport", "city_name":"San Jos\u00e9 del Cabo"},
 {"airport_iata_code":"SFH", "airport_name":"San Felipe International Airport", "city_name":""},
 {"airport_iata_code":"NLU", "airport_name":"Santa Lucia Air Force Base", "city_name":"Reyes Acozac"},
 {"airport_iata_code":"SLP", "airport_name":"Ponciano Arriaga International Airport", "city_name":"San Luis Potos\u00ed"},
 {"airport_iata_code":"TRC", "airport_name":"Francisco Sarabia International Airport", "city_name":"Torre\u00f3n"},
 {"airport_iata_code":"TIJ", "airport_name":"General Abelardo L. Rodr\u00edguez International Airport", "city_name":"Tijuana"},
 {"airport_iata_code":"TAM", "airport_name":"General Francisco Javier Mina International Airport", "city_name":"Tampico"},
 {"airport_iata_code":"TSL", "airport_name":"Tamuin Airport", "city_name":""},
 {"airport_iata_code":"TLC", "airport_name":"Licenciado Adolfo Lopez Mateos International Airport", "city_name":"Toluca"},
 {"airport_iata_code":"TAP", "airport_name":"Tapachula International Airport", "city_name":"Tapachula"},
 {"airport_iata_code":"WIX", "airport_name":"Tuxpan Airport", "city_name":""},
 {"airport_iata_code":"CUN", "airport_name":"Canc\u00fan International Airport", "city_name":"Canc\u00fan"},
 {"airport_iata_code":"VSA", "airport_name":"Carlos Rovirosa P\u00e9rez International Airport", "city_name":"Villahermosa"},
 {"airport_iata_code":"VER", "airport_name":"General Heriberto Jara International Airport", "city_name":"Veracruz"},
 {"airport_iata_code":"ZCL", "airport_name":"General Leobardo C. Ruiz International Airport", "city_name":"Zacatecas"},
 {"airport_iata_code":"ZIH", "airport_name":"Ixtapa Zihuatanejo International Airport", "city_name":"Ixtapa"},
 {"airport_iata_code":"ZMM", "airport_name":"Zamora Airport", "city_name":""},
 {"airport_iata_code":"ZLO", "airport_name":"Playa De Oro International Airport", "city_name":"Manzanillo"},
 {"airport_iata_code":"AJS", "airport_name":"Abreojos Airport", "city_name":"Abreojos"},
 {"airport_iata_code":"AZG", "airport_name":"Pablo L. Sidar Airport", "city_name":"Apatzing\u00e1n"},
 {"airport_iata_code":"NVJ", "airport_name":"Navojoa Airport", "city_name":"Navojoa"},
 {"airport_iata_code":"PCM", "airport_name":"Playa del Carmen Airport", "city_name":"Playa del Carmen"},
 {"airport_iata_code":"PCV", "airport_name":"Punta Chivato Airport", "city_name":"Punta Chivato"},
 {"airport_iata_code":"PNO", "airport_name":"Pinotepa Nacional Airport", "city_name":"Pinotepa"},
 {"airport_iata_code":"SCX", "airport_name":"Salina Cruz Naval Air Station", "city_name":"Salina Cruz"},
 {"airport_iata_code":"SGM", "airport_name":"San Ignacio Airport", "city_name":"San Ignacio"},
 {"airport_iata_code":"TUY", "airport_name":"Tulum Naval Air Station", "city_name":"Tulum"},
 {"airport_iata_code":"UAC", "airport_name":"San Luis R\u00edo Colorado Airport", "city_name":"San Luis R\u00edo Colorado"},
 {"airport_iata_code":"XAL", "airport_name":"\u00c1lamos Airport", "city_name":"\u00c1lamos"},
 {"airport_iata_code":"BTU", "airport_name":"Bintulu Airport", "city_name":"Bintulu"},
 {"airport_iata_code":"BLG", "airport_name":"Belaga Airport", "city_name":"Belaga"},
 {"airport_iata_code":"LSM", "airport_name":"Long Semado Airport", "city_name":"Long Semado"},
 {"airport_iata_code":"LGL", "airport_name":"Long Lellang Airport", "city_name":"Long Datih"},
 {"airport_iata_code":"KCH", "airport_name":"Kuching International Airport", "city_name":"Kuching"},
 {"airport_iata_code":"ODN", "airport_name":"Long Seridan Airport", "city_name":"Long Seridan"},
 {"airport_iata_code":"LMN", "airport_name":"Limbang Airport", "city_name":"Limbang"},
 {"airport_iata_code":"MKM", "airport_name":"Mukah Airport", "city_name":"Mukah"},
 {"airport_iata_code":"LKH", "airport_name":"Long Akah Airport", "city_name":"Long Akah"},
 {"airport_iata_code":"MUR", "airport_name":"Marudi Airport", "city_name":"Marudi"},
 {"airport_iata_code":"BSE", "airport_name":"Sematan Airport", "city_name":"Sematan"},
 {"airport_iata_code":"KPI", "airport_name":"Kapit Airport", "city_name":"Kapit"},
 {"airport_iata_code":"BKM", "airport_name":"Bakalalan Airport", "city_name":"Bakalalan"},
 {"airport_iata_code":"MYY", "airport_name":"Miri Airport", "city_name":"Miri"},
 {"airport_iata_code":"SBW", "airport_name":"Sibu Airport", "city_name":"Sibu"},
 {"airport_iata_code":"TGC", "airport_name":"Tanjung Manis Airport", "city_name":"Tanjung Manis"},
 {"airport_iata_code":"LSU", "airport_name":"Long Sukang Airport", "city_name":"Long Sukang"},
 {"airport_iata_code":"LWY", "airport_name":"Lawas Airport", "city_name":"Lawas"},
 {"airport_iata_code":"SGG", "airport_name":"Simanggang Airport", "city_name":"Simanggang"},
 {"airport_iata_code":"BBN", "airport_name":"Bario Airport", "city_name":"Bario"},
 {"airport_iata_code":"SMM", "airport_name":"Semporna Airport", "city_name":"Semporna"},
 {"airport_iata_code":"LDU", "airport_name":"Lahad Datu Airport", "city_name":"Lahad Datu"},
 {"airport_iata_code":"TEL", "airport_name":"Telupid Airport", "city_name":"Telupid"},
 {"airport_iata_code":"KGU", "airport_name":"Keningau Airport", "city_name":"Keningau"},
 {"airport_iata_code":"SXS", "airport_name":"Sahabat [Sahabat 16] Airport", "city_name":"Sahabat"},
 {"airport_iata_code":"BKI", "airport_name":"Kota Kinabalu International Airport", "city_name":"Kota Kinabalu"},
 {"airport_iata_code":"LBU", "airport_name":"Labuan Airport", "city_name":"Labuan"},
 {"airport_iata_code":"TMG", "airport_name":"Tomanggong Airport", "city_name":"Tomanggong"},
 {"airport_iata_code":"GSA", "airport_name":"Long Pasia Airport", "city_name":"Long Miau"},
 {"airport_iata_code":"SPE", "airport_name":"Sepulot Airport", "city_name":"Sepulot"},
 {"airport_iata_code":"PAY", "airport_name":"Pamol Airport", "city_name":"Pamol"},
 {"airport_iata_code":"RNU", "airport_name":"Ranau Airport", "city_name":"Ranau"},
 {"airport_iata_code":"SDK", "airport_name":"Sandakan Airport", "city_name":"Sandakan"},
 {"airport_iata_code":"KUD", "airport_name":"Kudat Airport", "city_name":"Kudat"},
 {"airport_iata_code":"TWU", "airport_name":"Tawau Airport", "city_name":"Tawau"},
 {"airport_iata_code":"MZV", "airport_name":"Mulu Airport", "city_name":"Mulu"},
 {"airport_iata_code":"SXT", "airport_name":"Sungai Tiang Airport", "city_name":"Taman Negara"},
 {"airport_iata_code":"MEP", "airport_name":"Mersing Airport", "city_name":"Mersing"},
 {"airport_iata_code":"SWY", "airport_name":"Sitiawan Airport", "city_name":"Sitiawan"},
 {"airport_iata_code":"TPG", "airport_name":"Taiping (Tekah) Airport", "city_name":"Taiping"},
 {"airport_iata_code":"TOD", "airport_name":"Pulau Tioman Airport", "city_name":"Pulau Tioman"},
 {"airport_iata_code":"AOR", "airport_name":"Sultan Abdul Halim Airport", "city_name":"Alor Satar"},
 {"airport_iata_code":"BWH", "airport_name":"Butterworth Airport", "city_name":"Butterworth"},
 {"airport_iata_code":"KBR", "airport_name":"Sultan Ismail Petra Airport", "city_name":"Kota Baharu"},
 {"airport_iata_code":"KUA", "airport_name":"Kuantan Airport", "city_name":"Kuantan"},
 {"airport_iata_code":"KTE", "airport_name":"Kerteh Airport", "city_name":"Kerteh"},
 {"airport_iata_code":"IPH", "airport_name":"Sultan Azlan Shah Airport", "city_name":"Ipoh"},
 {"airport_iata_code":"JHB", "airport_name":"Senai International Airport", "city_name":"Senai"},
 {"airport_iata_code":"KUL", "airport_name":"Kuala Lumpur International Airport", "city_name":"Kuala Lumpur"},
 {"airport_iata_code":"LGK", "airport_name":"Langkawi International Airport", "city_name":"Langkawi"},
 {"airport_iata_code":"MKZ", "airport_name":"Malacca Airport", "city_name":"Malacca"},
 {"airport_iata_code":"TGG", "airport_name":"Sultan Mahmud Airport", "city_name":"Kuala Terengganu"},
 {"airport_iata_code":"PEN", "airport_name":"Penang International Airport", "city_name":"Penang"},
 {"airport_iata_code":"PKG", "airport_name":"Pulau Pangkor Airport", "city_name":"Pangkor Island"},
 {"airport_iata_code":"RDN", "airport_name":"LTS Pulau Redang Airport", "city_name":"Redang"},
 {"airport_iata_code":"SZB", "airport_name":"Sultan Abdul Aziz Shah International Airport", "city_name":"Subang"},
 {"airport_iata_code":"GTB", "airport_name":"Genting Airport", "city_name":"Genting"},
 {"airport_iata_code":"GTK", "airport_name":"Sungei Tekai Airport", "city_name":"Sungei Tekai"},
 {"airport_iata_code":"LBP", "airport_name":"Long Banga Airport", "city_name":"Long Banga"},
 {"airport_iata_code":"LLM", "airport_name":"Long Lama Airport", "city_name":"Long Lama"},
 {"airport_iata_code":"MZS", "airport_name":"Mostyn Airport", "city_name":"Mostyn"},
 {"airport_iata_code":"SPT", "airport_name":"Sipitang Airport", "city_name":"Sipitang"},
 {"airport_iata_code":"TGS", "airport_name":"Chokw\u00e9 Airport", "city_name":"Chokw\u00e9"},
 {"airport_iata_code":"IBL", "airport_name":"Indigo Bay Lodge Airport", "city_name":"Bazaruto Island"},
 {"airport_iata_code":"LMZ", "airport_name":"Palma Airport", "city_name":"Palma"},
 {"airport_iata_code":"MTU", "airport_name":"Montepuez Airport", "city_name":"Montepuez"},
 {"airport_iata_code":"AME", "airport_name":"Alto Molocue Airport", "city_name":"Alto Molocue"},
 {"airport_iata_code":"BCW", "airport_name":"Benguera Island Airport", "city_name":"Benguera Island"},
 {"airport_iata_code":"BZB", "airport_name":"Bazaruto Island Airport", "city_name":"Bazaruto Island"},
 {"airport_iata_code":"ANO", "airport_name":"Angoche Airport", "city_name":"Angoche"},
 {"airport_iata_code":"BEW", "airport_name":"Beira Airport", "city_name":"Beira"},
 {"airport_iata_code":"FXO", "airport_name":"Cuamba Airport", "city_name":"Cuamba"},
 {"airport_iata_code":"VPY", "airport_name":"Chimoio Airport", "city_name":"Chimoio"},
 {"airport_iata_code":"IHC", "airport_name":"Inhaca Airport", "city_name":"Inhaca"},
 {"airport_iata_code":"INH", "airport_name":"Inhambane Airport", "city_name":"Inhambabe"},
 {"airport_iata_code":"VXC", "airport_name":"Lichinga Airport", "city_name":"Lichinga"},
 {"airport_iata_code":"LFB", "airport_name":"Lumbo Airport", "city_name":"Lumbo"},
 {"airport_iata_code":"MPM", "airport_name":"Maputo Airport", "city_name":"Maputo"},
 {"airport_iata_code":"MUD", "airport_name":"Mueda Airport", "city_name":"Mueda"},
 {"airport_iata_code":"MZB", "airport_name":"Moc\u00edmboa da Praia Airport", "city_name":"Moc\u00edmboa da Praia"},
 {"airport_iata_code":"MNC", "airport_name":"Nacala Airport", "city_name":"Nacala"},
 {"airport_iata_code":"APL", "airport_name":"Nampula Airport", "city_name":"Nampula"},
 {"airport_iata_code":"POL", "airport_name":"Pemba Airport", "city_name":"Pemba / Porto Amelia"},
 {"airport_iata_code":"UEL", "airport_name":"Quelimane Airport", "city_name":"Quelimane"},
 {"airport_iata_code":"TET", "airport_name":"Chingozi Airport", "city_name":"Tete"},
 {"airport_iata_code":"VNX", "airport_name":"Vilankulo Airport", "city_name":"Vilanculo"},
 {"airport_iata_code":"VJB", "airport_name":"Xai-Xai Airport", "city_name":"Xai-Xai"},
 {"airport_iata_code":"ADI", "airport_name":"Arandis Airport", "city_name":"Arandis"},
 {"airport_iata_code":"GOG", "airport_name":"Gobabis Airport", "city_name":"Gobabis"},
 {"airport_iata_code":"GFY", "airport_name":"Grootfontein Airport", "city_name":"Grootfontein"},
 {"airport_iata_code":"MPA", "airport_name":"Katima Mulilo Airport", "city_name":"Mpacha"},
 {"airport_iata_code":"KMP", "airport_name":"Keetmanshoop Airport", "city_name":"Keetmanshoop"},
 {"airport_iata_code":"LUD", "airport_name":"Luderitz Airport", "city_name":"Luderitz"},
 {"airport_iata_code":"OKU", "airport_name":"Mokuti Lodge Airport", "city_name":"Mokuti Lodge"},
 {"airport_iata_code":"NNI", "airport_name":"Namutoni Airport", "city_name":"Namutoni"},
 {"airport_iata_code":"OND", "airport_name":"Ondangwa Airport", "city_name":"Ondangwa"},
 {"airport_iata_code":"OMG", "airport_name":"Omega Airport", "city_name":"Omega"},
 {"airport_iata_code":"OMD", "airport_name":"Oranjemund Airport", "city_name":"Oranjemund"},
 {"airport_iata_code":"OKF", "airport_name":"Okaukuejo Airport", "city_name":"Okaukuejo"},
 {"airport_iata_code":"NDU", "airport_name":"Rundu Airport", "city_name":"Rundu"},
 {"airport_iata_code":"SWP", "airport_name":"Swakopmund Airport", "city_name":"Swakopmund"},
 {"airport_iata_code":"TSB", "airport_name":"Tsumeb Airport", "city_name":"Tsumeb"},
 {"airport_iata_code":"WVB", "airport_name":"Walvis Bay Airport", "city_name":"Walvis Bay"},
 {"airport_iata_code":"ERS", "airport_name":"Eros Airport", "city_name":"Windhoek"},
 {"airport_iata_code":"WDH", "airport_name":"Hosea Kutako International Airport", "city_name":"Windhoek"},
 {"airport_iata_code":"BQI", "airport_name":"Bagani Airport", "city_name":"Bagani"},
 {"airport_iata_code":"TGJ", "airport_name":"Tiga Airport", "city_name":"Tiga"},
 {"airport_iata_code":"BMY", "airport_name":"\u00cele Art - Waala Airport", "city_name":"Waala"},
 {"airport_iata_code":"KNQ", "airport_name":"Kon\u00e9 Airport", "city_name":"Kon\u00e9"},
 {"airport_iata_code":"ILP", "airport_name":"\u00cele des Pins Airport", "city_name":"\u00cele des Pins"},
 {"airport_iata_code":"HLU", "airport_name":"Nesson Airport", "city_name":"Houailou"},
 {"airport_iata_code":"KOC", "airport_name":"Koumac Airport", "city_name":"Koumac"},
 {"airport_iata_code":"LIF", "airport_name":"Lifou Airport", "city_name":"Lifou"},
 {"airport_iata_code":"GEA", "airport_name":"Noum\u00e9a Magenta Airport", "city_name":"Noum\u00e9a"},
 {"airport_iata_code":"IOU", "airport_name":"Edmond Can\u00e9 Airport", "city_name":"\u00cele Ouen"},
 {"airport_iata_code":"PUV", "airport_name":"Poum Airport", "city_name":"Poum"},
 {"airport_iata_code":"PDC", "airport_name":"Mueo Airport", "city_name":"Mueo"},
 {"airport_iata_code":"MEE", "airport_name":"Mar\u00e9 Airport", "city_name":"Mar\u00e9"},
 {"airport_iata_code":"TOU", "airport_name":"Touho Airport", "city_name":"Touho"},
 {"airport_iata_code":"UVE", "airport_name":"Ouv\u00e9a Airport", "city_name":"Ouv\u00e9a"},
 {"airport_iata_code":"NOU", "airport_name":"La Tontouta International Airport", "city_name":"Noum\u00e9a"},
 {"airport_iata_code":"MFQ", "airport_name":"Maradi Airport", "city_name":"Maradi"},
 {"airport_iata_code":"NIM", "airport_name":"Diori Hamani International Airport", "city_name":"Niamey"},
 {"airport_iata_code":"THZ", "airport_name":"Tahoua Airport", "city_name":"Tahoua"},
 {"airport_iata_code":"AJY", "airport_name":"Mano Dayak International Airport", "city_name":"Agadez"},
 {"airport_iata_code":"ZND", "airport_name":"Zinder Airport", "city_name":"Zinder"},
 {"airport_iata_code":"NLK", "airport_name":"Norfolk Island International Airport", "city_name":"Burnt Pine"},
 {"airport_iata_code":"GMO", "airport_name":"Gombe Lawanti International Airport", "city_name":"Gombe"},
 {"airport_iata_code":"PHG", "airport_name":"Port Harcourt City Airport", "city_name":"Port Harcourt"},
 {"airport_iata_code":"BCU", "airport_name":"Bauchi Airport", "city_name":"Bauchi"},
 {"airport_iata_code":"QRW", "airport_name":"Warri Airport", "city_name":"Warri"},
 {"airport_iata_code":"DKA", "airport_name":"Katsina Airport", "city_name":"Katsina"},
 {"airport_iata_code":"ABV", "airport_name":"Nnamdi Azikiwe International Airport", "city_name":"Abuja"},
 {"airport_iata_code":"AKR", "airport_name":"Akure Airport", "city_name":"Akure"},
 {"airport_iata_code":"ABB", "airport_name":"Asaba International Airport", "city_name":"Asaba"},
 {"airport_iata_code":"BNI", "airport_name":"Benin Airport", "city_name":"Benin"},
 {"airport_iata_code":"CBQ", "airport_name":"Margaret Ekpo International Airport", "city_name":"Calabar"},
 {"airport_iata_code":"ENU", "airport_name":"Akanu Ibiam International Airport", "city_name":"Enegu"},
 {"airport_iata_code":"QUS", "airport_name":"Gusau Airport", "city_name":"Gusau"},
 {"airport_iata_code":"IBA", "airport_name":"Ibadan Airport", "city_name":"Ibadan"},
 {"airport_iata_code":"ILR", "airport_name":"Ilorin International Airport", "city_name":"Ilorin"},
 {"airport_iata_code":"QOW", "airport_name":"Sam Mbakwe International Airport", "city_name":"Owerri"},
 {"airport_iata_code":"JOS", "airport_name":"Yakubu Gowon Airport", "city_name":"Jos"},
 {"airport_iata_code":"KAD", "airport_name":"Kaduna Airport", "city_name":"Kaduna"},
 {"airport_iata_code":"KAN", "airport_name":"Mallam Aminu International Airport", "city_name":"Kano"},
 {"airport_iata_code":"MIU", "airport_name":"Maiduguri International Airport", "city_name":"Maiduguri"},
 {"airport_iata_code":"MDI", "airport_name":"Makurdi Airport", "city_name":"Makurdi"},
 {"airport_iata_code":"LOS", "airport_name":"Murtala Muhammed International Airport", "city_name":"Lagos"},
 {"airport_iata_code":"MXJ", "airport_name":"Minna Airport", "city_name":"Minna"},
 {"airport_iata_code":"PHC", "airport_name":"Port Harcourt International Airport", "city_name":"Port Harcourt"},
 {"airport_iata_code":"SKO", "airport_name":"Sadiq Abubakar III International Airport", "city_name":"Sokoto"},
 {"airport_iata_code":"YOL", "airport_name":"Yola Airport", "city_name":"Yola"},
 {"airport_iata_code":"ZAR", "airport_name":"Zaria Airport", "city_name":"Zaria"},
 {"airport_iata_code":"BEF", "airport_name":"Bluefields Airport", "city_name":"Bluefileds"},
 {"airport_iata_code":"BZA", "airport_name":"San Pedro Airport", "city_name":"Bonanza"},
 {"airport_iata_code":"RNI", "airport_name":"Corn Island", "city_name":"Corn Island"},
 {"airport_iata_code":"MGA", "airport_name":"Augusto C. Sandino (Managua) International Airport", "city_name":"Managua"},
 {"airport_iata_code":"NVG", "airport_name":"Nueva Guinea Airport", "city_name":"Nueva Guinea"},
 {"airport_iata_code":"PUZ", "airport_name":"Puerto Cabezas Airport", "city_name":"Puerto Cabezas"},
 {"airport_iata_code":"RFS", "airport_name":"Rosita Airport", "city_name":"La Rosita"},
 {"airport_iata_code":"NCR", "airport_name":"San Carlos", "city_name":"San Carlos"},
 {"airport_iata_code":"SIU", "airport_name":"Siuna", "city_name":"Siuna"},
 {"airport_iata_code":"WSP", "airport_name":"Waspam Airport", "city_name":"Waspam"},
 {"airport_iata_code":"AMS", "airport_name":"Amsterdam Airport Schiphol", "city_name":"Amsterdam"},
 {"airport_iata_code":"MST", "airport_name":"Maastricht Aachen Airport", "city_name":"Maastricht"},
 {"airport_iata_code":"QAR", "airport_name":"Deelen Air Base", "city_name":"Arnhem"},
 {"airport_iata_code":"EIN", "airport_name":"Eindhoven Airport", "city_name":"Eindhoven"},
 {"airport_iata_code":"GRQ", "airport_name":"Eelde Airport", "city_name":"Groningen"},
 {"airport_iata_code":"GLZ", "airport_name":"Gilze Rijen Air Base", "city_name":"Breda"},
 {"airport_iata_code":"DHR", "airport_name":"De Kooy Airport", "city_name":"Den Helder"},
 {"airport_iata_code":"LEY", "airport_name":"Lelystad Airport", "city_name":"Lelystad"},
 {"airport_iata_code":"LWR", "airport_name":"Leeuwarden Air Base", "city_name":"Leeuwarden"},
 {"airport_iata_code":"RTM", "airport_name":"Rotterdam Airport", "city_name":"Rotterdam"},
 {"airport_iata_code":"ENS", "airport_name":"Twenthe Airport", "city_name":"Enschede"},
 {"airport_iata_code":"UDE", "airport_name":"Volkel Air Base", "city_name":"Uden"},
 {"airport_iata_code":"WOE", "airport_name":"Woensdrecht Air Base", "city_name":"Bergen Op Zoom"},
 {"airport_iata_code":"AES", "airport_name":"Alesund Airport", "city_name":"Alesund"},
 {"airport_iata_code":"ANX", "airport_name":"And\u00f8ya Airport", "city_name":"Andenes"},
 {"airport_iata_code":"ALF", "airport_name":"Alta Airport", "city_name":"Alta"},
 {"airport_iata_code":"FDE", "airport_name":"Bringeland Airport", "city_name":"F\u00f8rde"},
 {"airport_iata_code":"BNN", "airport_name":"Br\u00f8nn\u00f8ysund Airport", "city_name":"Br\u00f8nn\u00f8y"},
 {"airport_iata_code":"BOO", "airport_name":"Bod\u00f8 Airport", "city_name":"Bod\u00f8"},
 {"airport_iata_code":"BGO", "airport_name":"Bergen Airport, Flesland", "city_name":"Bergen"},
 {"airport_iata_code":"BJF", "airport_name":"B\u00e5tsfjord Airport", "city_name":"B\u00e5tsfjord"},
 {"airport_iata_code":"BVG", "airport_name":"Berlev\u00e5g Airport", "city_name":"Berlev\u00e5g"},
 {"airport_iata_code":"KRS", "airport_name":"Kristiansand Airport", "city_name":"Kjevik"},
 {"airport_iata_code":"DLD", "airport_name":"Geilo Airport Dagali", "city_name":"Dagali"},
 {"airport_iata_code":"BDU", "airport_name":"Bardufoss Airport", "city_name":"M\u00e5lselv"},
 {"airport_iata_code":"EVE", "airport_name":"Harstad/Narvik Airport, Evenes", "city_name":"Evenes"},
 {"airport_iata_code":"VDB", "airport_name":"Leirin Airport", "city_name":""},
 {"airport_iata_code":"FRO", "airport_name":"Flor\u00f8 Airport", "city_name":"Flor\u00f8"},
 {"airport_iata_code":"OSL", "airport_name":"Oslo Gardermoen Airport", "city_name":"Oslo"},
 {"airport_iata_code":"HMR", "airport_name":"Stafsberg Airport", "city_name":"Hamar"},
 {"airport_iata_code":"HAU", "airport_name":"Haugesund Airport", "city_name":"Karm\u00f8y"},
 {"airport_iata_code":"HFT", "airport_name":"Hammerfest Airport", "city_name":"Hammerfest"},
 {"airport_iata_code":"HAA", "airport_name":"Hasvik Airport", "city_name":"Hasvik"},
 {"airport_iata_code":"HVG", "airport_name":"Valan Airport", "city_name":"Honningsv\u00e5g"},
 {"airport_iata_code":"QKX", "airport_name":"Kautokeino Air Base", "city_name":""},
 {"airport_iata_code":"KSU", "airport_name":"Kristiansund Airport, Kvernberget", "city_name":"Kvernberget"},
 {"airport_iata_code":"GLL", "airport_name":"Gol Airport", "city_name":"Klanten"},
 {"airport_iata_code":"KKN", "airport_name":"Kirkenes Airport, H\u00f8ybuktmoen", "city_name":"Kirkenes"},
 {"airport_iata_code":"FAN", "airport_name":"Lista Airport", "city_name":"Farsund"},
 {"airport_iata_code":"LKN", "airport_name":"Leknes Airport", "city_name":"Leknes"},
 {"airport_iata_code":"MEH", "airport_name":"Mehamn Airport", "city_name":"Mehamn"},
 {"airport_iata_code":"MOL", "airport_name":"Molde Airport", "city_name":"Aro"},
 {"airport_iata_code":"LKL", "airport_name":"Banak Airport", "city_name":"Lakselv"},
 {"airport_iata_code":"NVK", "airport_name":"Narvik Framnes Airport", "city_name":"Narvik"},
 {"airport_iata_code":"OSY", "airport_name":"Namsos H\u00f8knes\u00f8ra Airport", "city_name":"Namsos"},
 {"airport_iata_code":"NTB", "airport_name":"Notodden Airport", "city_name":""},
 {"airport_iata_code":"OLA", "airport_name":"\u00d8rland Airport", "city_name":"\u00d8rland"},
 {"airport_iata_code":"HOV", "airport_name":"Orsta-Volda Airport, Hovden", "city_name":"Orsta"},
 {"airport_iata_code":"MQN", "airport_name":"Mo i Rana Airport, R\u00f8ssvoll", "city_name":"Mo i Rana"},
 {"airport_iata_code":"RVK", "airport_name":"R\u00f8rvik Airport, Ryum", "city_name":"R\u00f8rvik"},
 {"airport_iata_code":"RRS", "airport_name":"R\u00f8ros Airport", "city_name":"R\u00f8ros"},
 {"airport_iata_code":"RET", "airport_name":"R\u00f8st Airport", "city_name":""},
 {"airport_iata_code":"RYG", "airport_name":"Moss Airport, Rygge", "city_name":"Rygge"},
 {"airport_iata_code":"LYR", "airport_name":"Svalbard Airport, Longyear", "city_name":"Longyearbyen"},
 {"airport_iata_code":"SDN", "airport_name":"Sandane Airport, Anda", "city_name":"Sandane"},
 {"airport_iata_code":"SOG", "airport_name":"Sogndal Airport", "city_name":"Sogndal"},
 {"airport_iata_code":"SVJ", "airport_name":"Svolv\u00e6r Helle Airport", "city_name":"Svolv\u00e6r"},
 {"airport_iata_code":"SKN", "airport_name":"Stokmarknes Skagen Airport", "city_name":"Hadsel"},
 {"airport_iata_code":"SKE", "airport_name":"Skien Airport", "city_name":"Geiteryggen"},
 {"airport_iata_code":"SRP", "airport_name":"Stord Airport", "city_name":"Leirvik"},
 {"airport_iata_code":"SOJ", "airport_name":"S\u00f8rkjosen Airport", "city_name":"S\u00f8rkjosen"},
 {"airport_iata_code":"VAW", "airport_name":"Vard\u00f8 Airport, Svartnes", "city_name":"Vard\u00f8"},
 {"airport_iata_code":"SSJ", "airport_name":"Sandnessj\u00f8en Airport, Stokka", "city_name":"Alstahaug"},
 {"airport_iata_code":"TOS", "airport_name":"Troms\u00f8 Airport", "city_name":"Troms\u00f8"},
 {"airport_iata_code":"TRF", "airport_name":"Sandefjord Airport, Torp", "city_name":"Torp"},
 {"airport_iata_code":"TRD", "airport_name":"Trondheim Airport, V\u00e6rnes", "city_name":"Trondheim"},
 {"airport_iata_code":"VDS", "airport_name":"Vads\u00f8 Airport", "city_name":"Vads\u00f8"},
 {"airport_iata_code":"SVG", "airport_name":"Stavanger Airport, Sola", "city_name":"Stavanger"},
 {"airport_iata_code":"BJH", "airport_name":"Bajhang Airport", "city_name":"Bajhang"},
 {"airport_iata_code":"BHP", "airport_name":"Bhojpur Airport", "city_name":"Bhojpur"},
 {"airport_iata_code":"BGL", "airport_name":"Baglung Airport", "city_name":"Baglung"},
 {"airport_iata_code":"BHR", "airport_name":"Bharatpur Airport", "city_name":"Bharatpur"},
 {"airport_iata_code":"BJU", "airport_name":"Bajura Airport", "city_name":"Bajura"},
 {"airport_iata_code":"BIT", "airport_name":"Baitadi Airport", "city_name":"Baitadi"},
 {"airport_iata_code":"BWA", "airport_name":"Bhairahawa Airport", "city_name":"Bhairawa"},
 {"airport_iata_code":"BDP", "airport_name":"Bhadrapur Airport", "city_name":"Bhadrapur"},
 {"airport_iata_code":"DNP", "airport_name":"Tulsipur Airport", "city_name":"Dang"},
 {"airport_iata_code":"DHI", "airport_name":"Dhangarhi Airport", "city_name":"Dhangarhi"},
 {"airport_iata_code":"DAP", "airport_name":"Darchula Airport", "city_name":"Darchula"},
 {"airport_iata_code":"DOP", "airport_name":"Dolpa Airport", "city_name":"Dolpa"},
 {"airport_iata_code":"SIH", "airport_name":"Silgadi Doti Airport", "city_name":"Silgadi Doti"},
 {"airport_iata_code":"GKH", "airport_name":"Gorkha Airport", "city_name":"Gorkha"},
 {"airport_iata_code":"JIR", "airport_name":"Jiri Airport", "city_name":"Jiri"},
 {"airport_iata_code":"JUM", "airport_name":"Jumla Airport", "city_name":"Jumla"},
 {"airport_iata_code":"JKR", "airport_name":"Janakpur Airport", "city_name":"Janakpur"},
 {"airport_iata_code":"JMO", "airport_name":"Jomsom Airport", "city_name":"Jomsom"},
 {"airport_iata_code":"KTM", "airport_name":"Tribhuvan International Airport", "city_name":"Kathmandu"},
 {"airport_iata_code":"LDN", "airport_name":"Lamidanda Airport", "city_name":"Lamidanda"},
 {"airport_iata_code":"LUA", "airport_name":"Lukla Airport", "city_name":"Lukla"},
 {"airport_iata_code":"LTG", "airport_name":"Langtang Airport", "city_name":"Langtang"},
 {"airport_iata_code":"NGX", "airport_name":"Manang Airport", "city_name":"Ngawal"},
 {"airport_iata_code":"MEY", "airport_name":"Meghauli Airport", "city_name":"Meghauli"},
 {"airport_iata_code":"XMG", "airport_name":"Mahendranagar Airport", "city_name":"Mahendranagar"},
 {"airport_iata_code":"KEP", "airport_name":"Nepalgunj Airport", "city_name":"Nepalgunj"},
 {"airport_iata_code":"PKR", "airport_name":"Pokhara Airport", "city_name":"Pokhara"},
 {"airport_iata_code":"PPL", "airport_name":"Phaplu Airport", "city_name":"Phaplu"},
 {"airport_iata_code":"RJB", "airport_name":"Rajbiraj Airport", "city_name":"Rajbiraj"},
 {"airport_iata_code":"RHP", "airport_name":"Ramechhap Airport", "city_name":"Ramechhap"},
 {"airport_iata_code":"RUK", "airport_name":"Rukumkot Airport", "city_name":"Rukumkot"},
 {"airport_iata_code":"RPA", "airport_name":"Rolpa Airport", "city_name":"Rolpa"},
 {"airport_iata_code":"RUM", "airport_name":"Rumjatar Airport", "city_name":"Rumjatar"},
 {"airport_iata_code":"SIF", "airport_name":"Simara Airport", "city_name":"Simara"},
 {"airport_iata_code":"SKH", "airport_name":"Surkhet Airport", "city_name":"Surkhet"},
 {"airport_iata_code":"FEB", "airport_name":"Sanfebagar Airport", "city_name":"Sanfebagar"},
 {"airport_iata_code":"IMK", "airport_name":"Simikot Airport", "city_name":"Simikot"},
 {"airport_iata_code":"TMI", "airport_name":"Tumling Tar Airport", "city_name":"Tumling Tar"},
 {"airport_iata_code":"BIR", "airport_name":"Biratnagar Airport", "city_name":"Biratnagar"},
 {"airport_iata_code":"MWP", "airport_name":"Mountain Airport", "city_name":"Mountain"},
 {"airport_iata_code":"TPJ", "airport_name":"Taplejung Airport", "city_name":"Taplejung"},
 {"airport_iata_code":"INU", "airport_name":"Nauru International Airport", "city_name":"Yaren District"},
 {"airport_iata_code":"IUE", "airport_name":"Niue International Airport", "city_name":"Alofi"},
 {"airport_iata_code":"AKL", "airport_name":"Auckland International Airport", "city_name":"Auckland"},
 {"airport_iata_code":"TUO", "airport_name":"Taupo Airport", "city_name":"Taupo"},
 {"airport_iata_code":"AMZ", "airport_name":"Ardmore Airport", "city_name":"Manurewa"},
 {"airport_iata_code":"ASG", "airport_name":"Ashburton Aerodrome", "city_name":""},
 {"airport_iata_code":"CHC", "airport_name":"Christchurch International Airport", "city_name":"Christchurch"},
 {"airport_iata_code":"CHT", "airport_name":"Chatham Islands-Tuuta Airport", "city_name":"Waitangi"},
 {"airport_iata_code":"CMV", "airport_name":"Coromandel Aerodrome", "city_name":""},
 {"airport_iata_code":"DGR", "airport_name":"Dargaville Aerodrome", "city_name":""},
 {"airport_iata_code":"DUD", "airport_name":"Dunedin Airport", "city_name":"Dunedin"},
 {"airport_iata_code":"WHO", "airport_name":"Franz Josef Aerodrome", "city_name":""},
 {"airport_iata_code":"GBZ", "airport_name":"Great Barrier Aerodrome", "city_name":"Claris"},
 {"airport_iata_code":"GMN", "airport_name":"Greymouth Airport", "city_name":""},
 {"airport_iata_code":"GIS", "airport_name":"Gisborne Airport", "city_name":"Gisborne"},
 {"airport_iata_code":"GTN", "airport_name":"Glentanner Airport", "city_name":"Glentanner Station"},
 {"airport_iata_code":"HKK", "airport_name":"Hokitika Airfield", "city_name":""},
 {"airport_iata_code":"HLZ", "airport_name":"Hamilton International Airport", "city_name":"Hamilton"},
 {"airport_iata_code":"WIK", "airport_name":"Waiheke Reeve Airport", "city_name":""},
 {"airport_iata_code":"KBZ", "airport_name":"Kaikoura Airport", "city_name":""},
 {"airport_iata_code":"KKE", "airport_name":"Kerikeri Airport", "city_name":"Kerikeri"},
 {"airport_iata_code":"KKO", "airport_name":"Kaikohe Airport", "city_name":""},
 {"airport_iata_code":"KAT", "airport_name":"Kaitaia Airport", "city_name":"Kaitaia"},
 {"airport_iata_code":"ALR", "airport_name":"Alexandra Airport", "city_name":"Alexandra"},
 {"airport_iata_code":"MTA", "airport_name":"Matamata Glider Airport", "city_name":""},
 {"airport_iata_code":"MON", "airport_name":"Mount Cook Airport", "city_name":""},
 {"airport_iata_code":"MFN", "airport_name":"Milford Sound Airport", "city_name":""},
 {"airport_iata_code":"MZP", "airport_name":"Motueka Airport", "city_name":""},
 {"airport_iata_code":"TEU", "airport_name":"Manapouri Airport", "city_name":""},
 {"airport_iata_code":"MRO", "airport_name":"Hood Airport", "city_name":"Masterton"},
 {"airport_iata_code":"NPL", "airport_name":"New Plymouth Airport", "city_name":"New Plymouth"},
 {"airport_iata_code":"NPE", "airport_name":"Napier Airport", "city_name":""},
 {"airport_iata_code":"NSN", "airport_name":"Nelson Airport", "city_name":"Nelson"},
 {"airport_iata_code":"IVC", "airport_name":"Invercargill Airport", "city_name":"Invercargill"},
 {"airport_iata_code":"OHA", "airport_name":"RNZAF Base Ohakea", "city_name":""},
 {"airport_iata_code":"OAM", "airport_name":"Oamaru Airport", "city_name":""},
 {"airport_iata_code":"PMR", "airport_name":"Palmerston North Airport", "city_name":""},
 {"airport_iata_code":"PCN", "airport_name":"Picton Aerodrome", "city_name":"Picton"},
 {"airport_iata_code":"PPQ", "airport_name":"Paraparaumu Airport", "city_name":""},
 {"airport_iata_code":"ZQN", "airport_name":"Queenstown International Airport", "city_name":"Queenstown"},
 {"airport_iata_code":"RAG", "airport_name":"Raglan Airfield", "city_name":""},
 {"airport_iata_code":"SZS", "airport_name":"Ryans Creek Aerodrome", "city_name":"Oban"},
 {"airport_iata_code":"ROT", "airport_name":"Rotorua Regional Airport", "city_name":"Rotorua"},
 {"airport_iata_code":"TRG", "airport_name":"Tauranga Airport", "city_name":"Tauranga"},
 {"airport_iata_code":"TMZ", "airport_name":"Thames Aerodrome", "city_name":""},
 {"airport_iata_code":"KTF", "airport_name":"Takaka Airport", "city_name":""},
 {"airport_iata_code":"TKZ", "airport_name":"Tokoroa Airfield", "city_name":"Tokoroa"},
 {"airport_iata_code":"THH", "airport_name":"Taharoa Aerodrome", "city_name":"Taharoa"},
 {"airport_iata_code":"TIU", "airport_name":"Timaru Airport", "city_name":""},
 {"airport_iata_code":"TWZ", "airport_name":"Pukaki Airport", "city_name":"Twitzel"},
 {"airport_iata_code":"BHE", "airport_name":"Woodbourne Airport", "city_name":"Blenheim"},
 {"airport_iata_code":"WKA", "airport_name":"Wanaka Airport", "city_name":""},
 {"airport_iata_code":"WHK", "airport_name":"Whakatane Airport", "city_name":""},
 {"airport_iata_code":"WLG", "airport_name":"Wellington International Airport", "city_name":"Wellington"},
 {"airport_iata_code":"WIR", "airport_name":"Wairoa Airport", "city_name":"Wairoa"},
 {"airport_iata_code":"WRE", "airport_name":"Whangarei Airport", "city_name":""},
 {"airport_iata_code":"WSZ", "airport_name":"Westport Airport", "city_name":""},
 {"airport_iata_code":"WTZ", "airport_name":"Whitianga Airport", "city_name":""},
 {"airport_iata_code":"WAG", "airport_name":"Wanganui Airport", "city_name":"Wanganui"},
 {"airport_iata_code":"BYB", "airport_name":"Dibba Airport", "city_name":"Dibba Al-Baya"},
 {"airport_iata_code":"AOM", "airport_name":"Adam Airport", "city_name":"Adam"},
 {"airport_iata_code":"RMB", "airport_name":"Buraimi Airport", "city_name":"Buraimi"},
 {"airport_iata_code":"FAU", "airport_name":"Fahud Airport", "city_name":"Fahud"},
 {"airport_iata_code":"RNM", "airport_name":"Qarn Alam Airport", "city_name":"Ghaba"},
 {"airport_iata_code":"KHS", "airport_name":"Khasab Air Base", "city_name":"Khasab"},
 {"airport_iata_code":"LKW", "airport_name":"Lekhwair Airport", "city_name":""},
 {"airport_iata_code":"MSH", "airport_name":"Masirah Air Base", "city_name":"Masirah"},
 {"airport_iata_code":"MCT", "airport_name":"Muscat International Airport", "city_name":"Muscat"},
 {"airport_iata_code":"OMM", "airport_name":"Marmul Airport", "city_name":"Marmul"},
 {"airport_iata_code":"SLL", "airport_name":"Salalah Airport", "city_name":"Salalah"},
 {"airport_iata_code":"SUH", "airport_name":"Sur Airport", "city_name":"Sur"},
 {"airport_iata_code":"TTH", "airport_name":"Thumrait Air Base", "city_name":"Thumrait"},
 {"airport_iata_code":"BJQ", "airport_name":"Bahja Airport", "city_name":"Bahja"},
 {"airport_iata_code":"UKH", "airport_name":"Mukhaizna Airport", "city_name":"Mukhaizna Oil Field"},
 {"airport_iata_code":"ACU", "airport_name":"Achutupo Airport", "city_name":"Achutupo"},
 {"airport_iata_code":"AIL", "airport_name":"Alligandi Airport", "city_name":"Alligandi"},
 {"airport_iata_code":"CTE", "airport_name":"Carti Airport", "city_name":"Carti"},
 {"airport_iata_code":"PYC", "airport_name":"Play\u00f3n Chico Airport", "city_name":"Play\u00f3n Chico"},
 {"airport_iata_code":"AML", "airport_name":"Puerto Armuelles Airport", "city_name":"Puerto Armuelles"},
 {"airport_iata_code":"BFQ", "airport_name":"Bahia Pi\u00f1a Airport", "city_name":"Bahia Pi\u00f1a"},
 {"airport_iata_code":"ELE", "airport_name":"EL Real Airport", "city_name":"El Real"},
 {"airport_iata_code":"OTD", "airport_name":"Contadora Airport", "city_name":"Contadora Island"},
 {"airport_iata_code":"SAX", "airport_name":"Sambu Airport", "city_name":"Boca de S\u00e1balo"},
 {"airport_iata_code":"BOC", "airport_name":"Bocas Del Toro International Airport", "city_name":"Isla Col\u00f3n"},
 {"airport_iata_code":"CTD", "airport_name":"Alonso Valderrama Airport", "city_name":"Chitr\u00e9"},
 {"airport_iata_code":"CHX", "airport_name":"Cap Manuel Ni\u00f1o International Airport", "city_name":"Changuinola"},
 {"airport_iata_code":"DAV", "airport_name":"Enrique Malek International Airport", "city_name":"David"},
 {"airport_iata_code":"ONX", "airport_name":"Enrique Adolfo Jimenez Airport", "city_name":"Col\u00f3n"},
 {"airport_iata_code":"BLB", "airport_name":"Howard Airport", "city_name":"Panam\u00e1 City"},
 {"airport_iata_code":"JQE", "airport_name":"Jaqu\u00e9 Airport", "city_name":"Jaqu\u00e9"},
 {"airport_iata_code":"PLP", "airport_name":"Captain Ramon Xatruch Airport", "city_name":"La Palma"},
 {"airport_iata_code":"PAC", "airport_name":"Marcos A. Gelabert International Airport", "city_name":"Albrook"},
 {"airport_iata_code":"SYP", "airport_name":"Ruben Cantu Airport", "city_name":"Santiago"},
 {"airport_iata_code":"PTY", "airport_name":"Tocumen International Airport", "city_name":"Tocumen"},
 {"airport_iata_code":"NBL", "airport_name":"San Blas Airport", "city_name":"Wannukandi"},
 {"airport_iata_code":"CZJ", "airport_name":"Coraz\u00f3n de Jes\u00fas Airport", "city_name":"Coraz\u00f3n de Jes\u00fas and Nargan\u00e1 I"},
 {"airport_iata_code":"MFT", "airport_name":"Machu Pichu Airport", "city_name":"Machu Pichu"},
 {"airport_iata_code":"APE", "airport_name":"San Juan Aposento Airport", "city_name":"San Juan Aposento"},
 {"airport_iata_code":"ALD", "airport_name":"Alerta Airport", "city_name":"Fortaleza"},
 {"airport_iata_code":"AOP", "airport_name":"Alferez FAP Alfredo Vladimir Sara Bauer Airport", "city_name":"Andoas"},
 {"airport_iata_code":"ATG", "airport_name":"Teniente General Gerardo P\u00e9rez Pinedo Airport", "city_name":"Atalaya"},
 {"airport_iata_code":"MBP", "airport_name":"Moyobamba Airport", "city_name":"Moyobamba"},
 {"airport_iata_code":"BLP", "airport_name":"Huallaga Airport", "city_name":"Bellavista"},
 {"airport_iata_code":"IBP", "airport_name":"Iberia Airport", "city_name":"Iberia"},
 {"airport_iata_code":"PCL", "airport_name":"Cap FAP David Abenzur Rengifo International Airport", "city_name":"Pucallpa"},
 {"airport_iata_code":"CHM", "airport_name":"Teniente FAP Jaime A De Montreuil Morales Airport", "city_name":"Chimbote"},
 {"airport_iata_code":"TGI", "airport_name":"Tingo Maria Airport", "city_name":"Tingo Maria"},
 {"airport_iata_code":"CIX", "airport_name":"Capitan FAP Jose A Quinones Gonzales International Airport", "city_name":"Chiclayo"},
 {"airport_iata_code":"AYP", "airport_name":"Coronel FAP Alfredo Mendivil Duarte Airport", "city_name":"Ayacucho"},
 {"airport_iata_code":"ANS", "airport_name":"Andahuaylas Airport", "city_name":"Andahuaylas"},
 {"airport_iata_code":"ATA", "airport_name":"Comandante FAP German Arias Graziani Airport", "city_name":"Anta"},
 {"airport_iata_code":"UMI", "airport_name":"Quince Air Base", "city_name":"Quince Mil"},
 {"airport_iata_code":"LIM", "airport_name":"Jorge Ch\u00e1vez International Airport", "city_name":"Lima"},
 {"airport_iata_code":"UCZ", "airport_name":"Uchiza Airport", "city_name":"Uchiza"},
 {"airport_iata_code":"RIJ", "airport_name":"Juan Simons Vela Airport", "city_name":"Rioja"},
 {"airport_iata_code":"JJI", "airport_name":"Juanjui Airport", "city_name":"Juanju\u00ed"},
 {"airport_iata_code":"JAU", "airport_name":"Francisco Carle Airport", "city_name":"Jauja"},
 {"airport_iata_code":"JUL", "airport_name":"Inca Manco Capac International Airport", "city_name":"Juliaca"},
 {"airport_iata_code":"SJA", "airport_name":"San Juan de Marcona Airport", "city_name":"San Juan de Marcona"},
 {"airport_iata_code":"CJA", "airport_name":"Mayor General FAP Armando Revoredo Iglesias Airport", "city_name":"Cajamarca"},
 {"airport_iata_code":"RIM", "airport_name":"San Nicolas Airport", "city_name":"Rodriguez de Mendoza"},
 {"airport_iata_code":"ILQ", "airport_name":"Ilo Airport", "city_name":"Ilo"},
 {"airport_iata_code":"TBP", "airport_name":"Capitan FAP Pedro Canga Rodriguez Airport", "city_name":"Tumbes"},
 {"airport_iata_code":"SMG", "airport_name":"Santa Maria Airport", "city_name":"Santa Mar\u00eda"},
 {"airport_iata_code":"YMS", "airport_name":"Moises Benzaquen Rengifo Airport", "city_name":"Yurimaguas"},
 {"airport_iata_code":"HUU", "airport_name":"Alferez Fap David Figueroa Fernandini Airport", "city_name":"Hu\u00e1nuco"},
 {"airport_iata_code":"SQU", "airport_name":"Saposoa Airport", "city_name":"Plaza Saposoa"},
 {"airport_iata_code":"CHH", "airport_name":"Chachapoyas Airport", "city_name":"Chachapoyas"},
 {"airport_iata_code":"IQT", "airport_name":"Coronel FAP Francisco Secada Vignetta International Airport", "city_name":"Iquitos"},
 {"airport_iata_code":"AQP", "airport_name":"Rodr\u00edguez Ball\u00f3n International Airport", "city_name":"Arequipa"},
 {"airport_iata_code":"TRU", "airport_name":"Capitan FAP Carlos Martinez De Pinillos International Airport", "city_name":"Trujillo"},
 {"airport_iata_code":"SQD", "airport_name":"San Francisco Airport", "city_name":"San Francisco de Yeso"},
 {"airport_iata_code":"PIO", "airport_name":"Capit\u00e1n FAP Ren\u00e1n El\u00edas Olivera International Airport", "city_name":"Pisco"},
 {"airport_iata_code":"TPP", "airport_name":"Cadete FAP Guillermo Del Castillo Paredes Airport", "city_name":"Tarapoto"},
 {"airport_iata_code":"SYC", "airport_name":"Shiringayoc Airport", "city_name":"Shiringayoc"},
 {"airport_iata_code":"TCQ", "airport_name":"Coronel FAP Carlos Ciriani Santa Rosa International Airport", "city_name":"Tacna"},
 {"airport_iata_code":"PEM", "airport_name":"Padre Aldamiz International Airport", "city_name":"Puerto Maldonado"},
 {"airport_iata_code":"PIU", "airport_name":"Capit\u00e1n FAP Guillermo Concha Iberico International Airport", "city_name":"Piura"},
 {"airport_iata_code":"TYL", "airport_name":"Capitan Montes Airport", "city_name":""},
 {"airport_iata_code":"NZA", "airport_name":"Maria Reiche Neuman Airport", "city_name":""},
 {"airport_iata_code":"CUZ", "airport_name":"Alejandro Velasco Astete International Airport", "city_name":"Cusco"},
 {"airport_iata_code":"VVN", "airport_name":"Malvinas Airport", "city_name":"Malvinas Gas Plant"},
 {"airport_iata_code":"PPT", "airport_name":"Faa'a International Airport", "city_name":"Papeete"},
 {"airport_iata_code":"RUR", "airport_name":"Rurutu Airport", "city_name":""},
 {"airport_iata_code":"TUB", "airport_name":"Tubuai Airport", "city_name":""},
 {"airport_iata_code":"RVV", "airport_name":"Raivavae Airport", "city_name":""},
 {"airport_iata_code":"AAA", "airport_name":"Anaa Airport", "city_name":""},
 {"airport_iata_code":"FGU", "airport_name":"Fangatau Airport", "city_name":""},
 {"airport_iata_code":"TIH", "airport_name":"Tikehau Airport", "city_name":""},
 {"airport_iata_code":"APK", "airport_name":"Apataki Airport", "city_name":"Apataki"},
 {"airport_iata_code":"REA", "airport_name":"Reao Airport", "city_name":""},
 {"airport_iata_code":"FAV", "airport_name":"Fakarava Airport", "city_name":""},
 {"airport_iata_code":"HHZ", "airport_name":"Hikueru Atoll Airport", "city_name":"Hikueru Atoll"},
 {"airport_iata_code":"XMH", "airport_name":"Manihi Airport", "city_name":""},
 {"airport_iata_code":"GMR", "airport_name":"Totegegie Airport", "city_name":""},
 {"airport_iata_code":"KKR", "airport_name":"Kaukura Airport", "city_name":""},
 {"airport_iata_code":"MKP", "airport_name":"Makemo Airport", "city_name":""},
 {"airport_iata_code":"NAU", "airport_name":"Napuka Island Airport", "city_name":"Napuka Island"},
 {"airport_iata_code":"TKV", "airport_name":"Tatakoto Airport", "city_name":"Tatakoto"},
 {"airport_iata_code":"PKP", "airport_name":"Puka Puka Airport", "city_name":""},
 {"airport_iata_code":"PUK", "airport_name":"Pukarua Airport", "city_name":"Pukarua"},
 {"airport_iata_code":"TKP", "airport_name":"Takapoto Airport", "city_name":""},
 {"airport_iata_code":"AXR", "airport_name":"Arutua Airport", "city_name":""},
 {"airport_iata_code":"MVT", "airport_name":"Mataiva Airport", "city_name":""},
 {"airport_iata_code":"NUK", "airport_name":"Nukutavake Airport", "city_name":"Nukutavake"},
 {"airport_iata_code":"ZTA", "airport_name":"Tureia Airport", "city_name":""},
 {"airport_iata_code":"AHE", "airport_name":"Ahe Airport", "city_name":"Ahe Atoll"},
 {"airport_iata_code":"KHZ", "airport_name":"Kauehi Airport", "city_name":"Kauehi"},
 {"airport_iata_code":"FAC", "airport_name":"Faaite Airport", "city_name":""},
 {"airport_iata_code":"FHZ", "airport_name":"Fakahina Airport", "city_name":"Fakahina"},
 {"airport_iata_code":"RKA", "airport_name":"Aratika Nord Airport", "city_name":""},
 {"airport_iata_code":"RRR", "airport_name":"Raroia Airport", "city_name":""},
 {"airport_iata_code":"TKX", "airport_name":"Takaroa Airport", "city_name":""},
 {"airport_iata_code":"NHV", "airport_name":"Nuku Hiva Airport", "city_name":""},
 {"airport_iata_code":"AUQ", "airport_name":"Hiva Oa-Atuona Airport", "city_name":""},
 {"airport_iata_code":"UAP", "airport_name":"Ua Pou Airport", "city_name":"Ua Pou"},
 {"airport_iata_code":"UAH", "airport_name":"Ua Huka Airport", "city_name":"Ua Huka"},
 {"airport_iata_code":"BOB", "airport_name":"Bora Bora Airport", "city_name":"Motu Mute"},
 {"airport_iata_code":"TTI", "airport_name":"Tetiaroa Airport", "city_name":"Tetiaroa"},
 {"airport_iata_code":"RGI", "airport_name":"Rangiroa Airport", "city_name":""},
 {"airport_iata_code":"HUH", "airport_name":"Huahine-Fare Airport", "city_name":"Fare"},
 {"airport_iata_code":"MOZ", "airport_name":"Moorea Airport", "city_name":""},
 {"airport_iata_code":"HOI", "airport_name":"Hao Airport", "city_name":""},
 {"airport_iata_code":"MAU", "airport_name":"Maupiti Airport", "city_name":""},
 {"airport_iata_code":"RFP", "airport_name":"Raiatea Airport", "city_name":"Uturoa"},
 {"airport_iata_code":"VHZ", "airport_name":"Vahitahi Airport", "city_name":"Vahitahi"},
 {"airport_iata_code":"MYX", "airport_name":"Menyamya Airport", "city_name":"Menyamya"},
 {"airport_iata_code":"NDI", "airport_name":"Namudi Airport", "city_name":""},
 {"airport_iata_code":"NDN", "airport_name":"Nadunumu Airport", "city_name":""},
 {"airport_iata_code":"NOM", "airport_name":"Nomad River Airport", "city_name":"Nomad River"},
 {"airport_iata_code":"NOO", "airport_name":"Naoro Airport", "city_name":"Naoro Vilage"},
 {"airport_iata_code":"NUG", "airport_name":"Nuguria Airstrip", "city_name":"Nuguria Island"},
 {"airport_iata_code":"OGE", "airport_name":"Ogeranang Airport", "city_name":""},
 {"airport_iata_code":"OKV", "airport_name":"Okao Airport", "city_name":""},
 {"airport_iata_code":"OLQ", "airport_name":"Olsobip Airport", "city_name":"Olsobip"},
 {"airport_iata_code":"ONB", "airport_name":"Ononge Airport", "city_name":"Onange Mission"},
 {"airport_iata_code":"OSE", "airport_name":"Omora Airport", "city_name":"Omara"},
 {"airport_iata_code":"OSG", "airport_name":"Ossima Airport", "city_name":"Ossima"},
 {"airport_iata_code":"PDI", "airport_name":"Pindiu Airport", "city_name":"Pindiu"},
 {"airport_iata_code":"ERE", "airport_name":"Erave Airport", "city_name":"Erave"},
 {"airport_iata_code":"ESA", "airport_name":"Esa'ala Airport", "city_name":"Esa'ala"},
 {"airport_iata_code":"GAR", "airport_name":"Garaina Airport", "city_name":"Garaina"},
 {"airport_iata_code":"BPD", "airport_name":"Bapi Airstrip", "city_name":""},
 {"airport_iata_code":"BPK", "airport_name":"Biangabip Airport", "city_name":""},
 {"airport_iata_code":"NWT", "airport_name":"Nowata Airport", "city_name":""},
 {"airport_iata_code":"AKG", "airport_name":"Anguganak Airport", "city_name":"Anguganak"},
 {"airport_iata_code":"TAJ", "airport_name":"Tadji Airport", "city_name":"Aitape"},
 {"airport_iata_code":"AWB", "airport_name":"Awaba Airport", "city_name":"Awaba"},
 {"airport_iata_code":"BAA", "airport_name":"Bialla Airport", "city_name":"Bialla, Matalilu, Ewase"},
 {"airport_iata_code":"CVB", "airport_name":"Chungribu Airport", "city_name":""},
 {"airport_iata_code":"GMI", "airport_name":"Gasmata Island Airport", "city_name":"Gasmata Island"},
 {"airport_iata_code":"GVI", "airport_name":"Green River Airport", "city_name":"Green River"},
 {"airport_iata_code":"HYF", "airport_name":"Hayfields Airport", "city_name":"Bainyik"},
 {"airport_iata_code":"IHU", "airport_name":"Ihu Airport", "city_name":"Ihu"},
 {"airport_iata_code":"IIS", "airport_name":"Nissan Island Airport", "city_name":"Nissan Island"},
 {"airport_iata_code":"JAQ", "airport_name":"Jacquinot Bay Airport", "city_name":"Jacquinot Bay"},
 {"airport_iata_code":"KDR", "airport_name":"Kandrian Airport", "city_name":"Kandrian"},
 {"airport_iata_code":"KKD", "airport_name":"Kokoda Airport", "city_name":"Kokoda"},
 {"airport_iata_code":"KUY", "airport_name":"Kamusi Airport", "city_name":"Kamusi"},
 {"airport_iata_code":"KWO", "airport_name":"Kawito Airport", "city_name":"Kawito"},
 {"airport_iata_code":"KXR", "airport_name":"Karoola Airport", "city_name":"Buka Island"},
 {"airport_iata_code":"LMI", "airport_name":"Lumi Airport", "city_name":"Lumi"},
 {"airport_iata_code":"LMY", "airport_name":"Lake Murray Airport", "city_name":"Lake Murray"},
 {"airport_iata_code":"OBX", "airport_name":"Obo Airport", "city_name":"Obo"},
 {"airport_iata_code":"OPU", "airport_name":"Balimo Airport", "city_name":"Balimo"},
 {"airport_iata_code":"SKC", "airport_name":"Suki Airport", "city_name":"Suki"},
 {"airport_iata_code":"TFI", "airport_name":"Tufi Airport", "city_name":"Tufi"},
 {"airport_iata_code":"TFM", "airport_name":"Telefomin Airport", "city_name":"Telefomin"},
 {"airport_iata_code":"TLO", "airport_name":"Tol Airport", "city_name":"Tol"},
 {"airport_iata_code":"UKU", "airport_name":"Nuku Airport", "city_name":"Nuku"},
 {"airport_iata_code":"ULE", "airport_name":"Sule Airport", "city_name":"Sule"},
 {"airport_iata_code":"VMU", "airport_name":"Baimuru Airport", "city_name":"Baimuru"},
 {"airport_iata_code":"WPM", "airport_name":"Wipim Airport", "city_name":"Wipim"},
 {"airport_iata_code":"PGE", "airport_name":"Yegepa Airport", "city_name":""},
 {"airport_iata_code":"PLE", "airport_name":"Paiela Airport", "city_name":""},
 {"airport_iata_code":"PMP", "airport_name":"Pimaga Airport", "city_name":""},
 {"airport_iata_code":"RAW", "airport_name":"Arawa Airport", "city_name":"Arawa"},
 {"airport_iata_code":"RAX", "airport_name":"Oram Airport", "city_name":""},
 {"airport_iata_code":"RBP", "airport_name":"Raba Raba Airport", "city_name":"Rabaraba"},
 {"airport_iata_code":"RKU", "airport_name":"Kairuku Airport", "city_name":"Yule Island"},
 {"airport_iata_code":"RUU", "airport_name":"Ruti Airport", "city_name":"Kawbenaberi"},
 {"airport_iata_code":"SDI", "airport_name":"Saidor Airport", "city_name":""},
 {"airport_iata_code":"SFU", "airport_name":"Safia Airport", "city_name":"Safia"},
 {"airport_iata_code":"SIZ", "airport_name":"Sissano Airport", "city_name":"Sissano"},
 {"airport_iata_code":"SMH", "airport_name":"Sapmanga Airport", "city_name":"Sapmanga"},
 {"airport_iata_code":"SQT", "airport_name":"China Strait Airstrip", "city_name":"Samarai Island"},
 {"airport_iata_code":"SWE", "airport_name":"Siwea Airport", "city_name":""},
 {"airport_iata_code":"SWG", "airport_name":"Satwag Airport", "city_name":""},
 {"airport_iata_code":"SWR", "airport_name":"Silur Airport", "city_name":"Silur Mission"},
 {"airport_iata_code":"SXH", "airport_name":"Sehulea Airport", "city_name":"Sehulea"},
 {"airport_iata_code":"TBQ", "airport_name":"Tarabo Airport", "city_name":"Tarabo"},
 {"airport_iata_code":"TEO", "airport_name":"Terapo Airport", "city_name":"Terapo Mission"},
 {"airport_iata_code":"TGL", "airport_name":"Tagula Airport", "city_name":"Sudest Island"},
 {"airport_iata_code":"TLP", "airport_name":"Tumolbil Airport", "city_name":""},
 {"airport_iata_code":"UAE", "airport_name":"Mount Aue Airport", "city_name":""},
 {"airport_iata_code":"UBI", "airport_name":"Buin Airport", "city_name":"Buin"},
 {"airport_iata_code":"VIV", "airport_name":"Vivigani Airfield", "city_name":"Vivigani"},
 {"airport_iata_code":"WTT", "airport_name":"Wantoat Airport", "city_name":""},
 {"airport_iata_code":"WUV", "airport_name":"Wuvulu Island Airport", "city_name":"Wuvulu Island"},
 {"airport_iata_code":"XBN", "airport_name":"Biniguni Airport", "city_name":""},
 {"airport_iata_code":"YEQ", "airport_name":"Yenkis(Yankisa) Airport", "city_name":""},
 {"airport_iata_code":"ZEN", "airport_name":"Zenag Airport", "city_name":""},
 {"airport_iata_code":"ABP", "airport_name":"Atkamba Airport", "city_name":"Atkamba Mission"},
 {"airport_iata_code":"ADC", "airport_name":"Andakombe Airport", "city_name":"Andekombe"},
 {"airport_iata_code":"AEK", "airport_name":"Aseki Airport", "city_name":"Aseki"},
 {"airport_iata_code":"AFR", "airport_name":"Afore Airstrip", "city_name":""},
 {"airport_iata_code":"AGG", "airport_name":"Angoram Airport", "city_name":"Angoram"},
 {"airport_iata_code":"AGK", "airport_name":"Kagua Airport", "city_name":"Kagua"},
 {"airport_iata_code":"AGL", "airport_name":"Wanigela Airport", "city_name":""},
 {"airport_iata_code":"AIE", "airport_name":"Aiome Airport", "city_name":"Aiome"},
 {"airport_iata_code":"AIH", "airport_name":"Aiambak Airport", "city_name":"Aiambak"},
 {"airport_iata_code":"AMF", "airport_name":"Ama Airport", "city_name":""},
 {"airport_iata_code":"AMU", "airport_name":"Amanab Airport", "city_name":"Amanab"},
 {"airport_iata_code":"AOB", "airport_name":"Annanberg Airport", "city_name":""},
 {"airport_iata_code":"APP", "airport_name":"Asapa Airport", "city_name":""},
 {"airport_iata_code":"APR", "airport_name":"April River Airport", "city_name":""},
 {"airport_iata_code":"ARP", "airport_name":"Aragip Airport", "city_name":""},
 {"airport_iata_code":"ASZ", "airport_name":"Asirim Airport", "city_name":""},
 {"airport_iata_code":"ATN", "airport_name":"Namatanai Airport", "city_name":"Namatanai"},
 {"airport_iata_code":"ATP", "airport_name":"Aitape Airport", "city_name":"Aitape"},
 {"airport_iata_code":"AUI", "airport_name":"Aua Island Airport", "city_name":""},
 {"airport_iata_code":"AUJ", "airport_name":"Ambunti Airport", "city_name":"Ambunti"},
 {"airport_iata_code":"AUP", "airport_name":"Agaun Airport", "city_name":""},
 {"airport_iata_code":"AUV", "airport_name":"Aumo Airport", "city_name":""},
 {"airport_iata_code":"KPM", "airport_name":"Kompiam Airport", "city_name":""},
 {"airport_iata_code":"BUA", "airport_name":"Buka Airport", "city_name":"Buka Island"},
 {"airport_iata_code":"CMU", "airport_name":"Chimbu Airport", "city_name":"Kundiawa"},
 {"airport_iata_code":"DAU", "airport_name":"Daru Airport", "city_name":"Daru"},
 {"airport_iata_code":"XYR", "airport_name":"Edwaki Airport", "city_name":"Yellow River Mission"},
 {"airport_iata_code":"FAQ", "airport_name":"Frieda River Airport", "city_name":"Frieda River"},
 {"airport_iata_code":"GKA", "airport_name":"Goroka Airport", "city_name":"Goronka"},
 {"airport_iata_code":"GRL", "airport_name":"Garasa Airport", "city_name":"Au"},
 {"airport_iata_code":"GUR", "airport_name":"Gurney Airport", "city_name":"Gurney"},
 {"airport_iata_code":"GAP", "airport_name":"Gusap Airport", "city_name":"Gusap"},
 {"airport_iata_code":"PNP", "airport_name":"Girua Airport", "city_name":"Popondetta"},
 {"airport_iata_code":"HKN", "airport_name":"Kimbe Airport", "city_name":"Hoskins"},
 {"airport_iata_code":"LSA", "airport_name":"Losuia Airport", "city_name":"Losuia"},
 {"airport_iata_code":"KPA", "airport_name":"Kopiago Airport", "city_name":"Kopiago"},
 {"airport_iata_code":"UNG", "airport_name":"Kiunga Airport", "city_name":"Kiunga"},
 {"airport_iata_code":"KNE", "airport_name":"Kanainj Airport", "city_name":"Kanainj"},
 {"airport_iata_code":"KRI", "airport_name":"Kikori Airport", "city_name":"Kikori"},
 {"airport_iata_code":"KMA", "airport_name":"Kerema Airport", "city_name":"Kerema"},
 {"airport_iata_code":"KRX", "airport_name":"Kar Kar Airport", "city_name":"Kar Kar Island"},
 {"airport_iata_code":"KUQ", "airport_name":"Kuri Airport", "city_name":"Kuri"},
 {"airport_iata_code":"KVG", "airport_name":"Kavieng Airport", "city_name":"Kavieng"},
 {"airport_iata_code":"LNV", "airport_name":"Londolovit Airport", "city_name":"Londolovit"},
 {"airport_iata_code":"MAG", "airport_name":"Madang Airport", "city_name":"Madang"},
 {"airport_iata_code":"HGU", "airport_name":"Mount Hagen Kagamuga Airport", "city_name":"Mount Hagen"},
 {"airport_iata_code":"GUV", "airport_name":"Mougulu Airport", "city_name":"Mougulu"},
 {"airport_iata_code":"MDU", "airport_name":"Mendi Airport", "city_name":""},
 {"airport_iata_code":"MAS", "airport_name":"Momote Airport", "city_name":""},
 {"airport_iata_code":"MXH", "airport_name":"Moro Airport", "city_name":"Moro"},
 {"airport_iata_code":"MIS", "airport_name":"Misima Island Airport", "city_name":"Misima Island"},
 {"airport_iata_code":"MWG", "airport_name":"Marawaka Airport", "city_name":"Marawaka"},
 {"airport_iata_code":"GBF", "airport_name":"Negarbo(Negabo) Airport", "city_name":"Negarbo"},
 {"airport_iata_code":"MFO", "airport_name":"Manguna Airport", "city_name":"Manguna"},
 {"airport_iata_code":"NMN", "airport_name":"Nomane Airport", "city_name":"Namane"},
 {"airport_iata_code":"LAE", "airport_name":"Lae Nadzab Airport", "city_name":"Nadzab"},
 {"airport_iata_code":"POM", "airport_name":"Port Moresby Jacksons International Airport", "city_name":"Port Moresby"},
 {"airport_iata_code":"RMN", "airport_name":"Rumginae Airport", "city_name":""},
 {"airport_iata_code":"KMR", "airport_name":"Karimui Airport", "city_name":"Karimui"},
 {"airport_iata_code":"SBE", "airport_name":"Suabi Airport", "city_name":""},
 {"airport_iata_code":"NIS", "airport_name":"Simberi Airport", "city_name":"Simberi Island"},
 {"airport_iata_code":"SIL", "airport_name":"Sila Airport", "city_name":"Sila Mission"},
 {"airport_iata_code":"SIM", "airport_name":"Simbai Airport", "city_name":"Simbai"},
 {"airport_iata_code":"TIZ", "airport_name":"Tari Airport", "city_name":"Tari"},
 {"airport_iata_code":"TBG", "airport_name":"Tabubil Airport", "city_name":"Tabubil"},
 {"airport_iata_code":"TPI", "airport_name":"Tapini Airport", "city_name":"Tapini"},
 {"airport_iata_code":"RAB", "airport_name":"Tokua Airport", "city_name":"Tokua"},
 {"airport_iata_code":"TKW", "airport_name":"Tekin Airport", "city_name":"Tekin"},
 {"airport_iata_code":"AYU", "airport_name":"Aiyura Airport", "city_name":"Aiyura Valley"},
 {"airport_iata_code":"VAI", "airport_name":"Vanimo Airport", "city_name":""},
 {"airport_iata_code":"WAO", "airport_name":"Wabo Airport", "city_name":"Wabo"},
 {"airport_iata_code":"WBM", "airport_name":"Wapenamanda Airport", "city_name":""},
 {"airport_iata_code":"WWK", "airport_name":"Wewak International Airport", "city_name":"Wewak"},
 {"airport_iata_code":"WOA", "airport_name":"Wonenara Airport", "city_name":"Wonenara"},
 {"airport_iata_code":"WSU", "airport_name":"Wasu Airport", "city_name":"Wasu"},
 {"airport_iata_code":"WTP", "airport_name":"Woitape Airport", "city_name":"Fatima Mission"},
 {"airport_iata_code":"WUG", "airport_name":"Wau Airport", "city_name":"Wau"},
 {"airport_iata_code":"AZB", "airport_name":"Amazon Bay Airport", "city_name":""},
 {"airport_iata_code":"BAJ", "airport_name":"Bali Airport", "city_name":"Unea Island"},
 {"airport_iata_code":"BCP", "airport_name":"Bambu Airport", "city_name":"Bambu"},
 {"airport_iata_code":"BDZ", "airport_name":"Baindoung Airport", "city_name":""},
 {"airport_iata_code":"BMH", "airport_name":"Bomai Airport", "city_name":""},
 {"airport_iata_code":"BMZ", "airport_name":"Bamu Airport", "city_name":"Airua"},
 {"airport_iata_code":"BNM", "airport_name":"Bodinumu Airport", "city_name":""},
 {"airport_iata_code":"BNT", "airport_name":"Bundi Airport", "city_name":""},
 {"airport_iata_code":"BOT", "airport_name":"Bosset Airport", "city_name":"Bosset"},
 {"airport_iata_code":"BSP", "airport_name":"Bensbach Airport", "city_name":""},
 {"airport_iata_code":"BUL", "airport_name":"Bulolo Airport", "city_name":"Bulolo"},
 {"airport_iata_code":"BWJ", "airport_name":"Bawan Airport", "city_name":""},
 {"airport_iata_code":"BWP", "airport_name":"Bewani Airport", "city_name":"Bewani"},
 {"airport_iata_code":"CPI", "airport_name":"Cape Orford Airport", "city_name":""},
 {"airport_iata_code":"CVL", "airport_name":"Cape Vogel Airport", "city_name":""},
 {"airport_iata_code":"DAO", "airport_name":"Dabo Airport", "city_name":""},
 {"airport_iata_code":"DBP", "airport_name":"Debepare Airport", "city_name":"Debepare"},
 {"airport_iata_code":"DDM", "airport_name":"Dodoima Airport", "city_name":""},
 {"airport_iata_code":"DER", "airport_name":"Derim Airport", "city_name":"Derim"},
 {"airport_iata_code":"DOO", "airport_name":"Dorobisoro Airport", "city_name":"Dorobisoro"},
 {"airport_iata_code":"EFG", "airport_name":"Efogi Airport", "city_name":"Efogi"},
 {"airport_iata_code":"FIN", "airport_name":"Finschhafen Airport", "city_name":"Buki"},
 {"airport_iata_code":"FNE", "airport_name":"Fane Airport", "city_name":"Fane Mission"},
 {"airport_iata_code":"GAZ", "airport_name":"Guasopa Airport", "city_name":"Woodlark (Muyua) Island"},
 {"airport_iata_code":"GUE", "airport_name":"Guriaso (Keraso) Airport", "city_name":"Guriaso"},
 {"airport_iata_code":"HEO", "airport_name":"Haelogo Airport", "city_name":""},
 {"airport_iata_code":"HWA", "airport_name":"Hawabango Airport", "city_name":""},
 {"airport_iata_code":"ITK", "airport_name":"Itokama Airport", "city_name":"Itokama"},
 {"airport_iata_code":"JOP", "airport_name":"Josephstaal Airport", "city_name":""},
 {"airport_iata_code":"KAQ", "airport_name":"Kamulai Airport", "city_name":"Kamulai Mission"},
 {"airport_iata_code":"KBM", "airport_name":"Kabwum", "city_name":""},
 {"airport_iata_code":"KDP", "airport_name":"Kandep Airport", "city_name":"Kandep"},
 {"airport_iata_code":"KDQ", "airport_name":"Kamberatoro Airport", "city_name":"Kamberatoro Mission"},
 {"airport_iata_code":"KEG", "airport_name":"Keglsugl Airport", "city_name":"Denglagu Mission"},
 {"airport_iata_code":"KEX", "airport_name":"Kanabea Airport", "city_name":""},
 {"airport_iata_code":"KGW", "airport_name":"Kagi Airport", "city_name":"Kagi"},
 {"airport_iata_code":"KIQ", "airport_name":"Kira Airport", "city_name":""},
 {"airport_iata_code":"KMB", "airport_name":"Koinambe Airport", "city_name":""},
 {"airport_iata_code":"KMF", "airport_name":"Kamina Airport", "city_name":"Hoieti"},
 {"airport_iata_code":"KOM", "airport_name":"Komo-Manda Airport", "city_name":"Komo"},
 {"airport_iata_code":"KOR", "airport_name":"Kakoro(Koroko) Airstrip", "city_name":"Kakoro"},
 {"airport_iata_code":"KPE", "airport_name":"Yapsiei Airport", "city_name":""},
 {"airport_iata_code":"KPL", "airport_name":"Kapal Airport", "city_name":"Kapal"},
 {"airport_iata_code":"KQL", "airport_name":"Kol Airport", "city_name":""},
 {"airport_iata_code":"KRJ", "airport_name":"Karawari Airstrip", "city_name":""},
 {"airport_iata_code":"KRU", "airport_name":"Kerau Airport", "city_name":"Gunim"},
 {"airport_iata_code":"KSP", "airport_name":"Kosipe Airport", "city_name":"Kosipe Mission"},
 {"airport_iata_code":"KUX", "airport_name":"Kuyol Airport", "city_name":""},
 {"airport_iata_code":"KZF", "airport_name":"Kaintiba Airport", "city_name":"Kaintiba"},
 {"airport_iata_code":"LGN", "airport_name":"Linga Linga Airport", "city_name":""},
 {"airport_iata_code":"LNC", "airport_name":"Lengbati Airport", "city_name":""},
 {"airport_iata_code":"LNF", "airport_name":"Munbil Airport", "city_name":""},
 {"airport_iata_code":"LNM", "airport_name":"Langimar Airport", "city_name":""},
 {"airport_iata_code":"MAP", "airport_name":"Mamai Airport", "city_name":"Mamai"},
 {"airport_iata_code":"MHY", "airport_name":"Morehead Airport", "city_name":"Morehead"},
 {"airport_iata_code":"MKN", "airport_name":"Malekolon Airport", "city_name":"Babase Island"},
 {"airport_iata_code":"MLQ", "airport_name":"Malalaua Airport", "city_name":""},
 {"airport_iata_code":"MMV", "airport_name":"Mal Airport", "city_name":"Mal Island"},
 {"airport_iata_code":"MPG", "airport_name":"Makini Airport", "city_name":""},
 {"airport_iata_code":"MPU", "airport_name":"Mapua(Mabua) Airport", "city_name":"Tatau Island"},
 {"airport_iata_code":"MPX", "airport_name":"Miyanmin Airport", "city_name":""},
 {"airport_iata_code":"MQO", "airport_name":"Malam Airport", "city_name":"Malam"},
 {"airport_iata_code":"MXK", "airport_name":"Mindik Airport", "city_name":"Mindik"},
 {"airport_iata_code":"ENI", "airport_name":"El Nido Airport", "city_name":"El Nido"},
 {"airport_iata_code":"SFS", "airport_name":"Subic Bay International Airport", "city_name":"Olongapo City"},
 {"airport_iata_code":"CRK", "airport_name":"Diosdado Macapagal International Airport", "city_name":"Angeles City"},
 {"airport_iata_code":"LAO", "airport_name":"Laoag International Airport", "city_name":"Laoag City"},
 {"airport_iata_code":"MNL", "airport_name":"Ninoy Aquino International Airport", "city_name":"Manila"},
 {"airport_iata_code":"CYU", "airport_name":"Cuyo Airport", "city_name":"Cuyo"},
 {"airport_iata_code":"LGP", "airport_name":"Legazpi City International Airport", "city_name":"Legazpi City"},
 {"airport_iata_code":"NSP", "airport_name":"Sangley Point Air Base", "city_name":"Cavite City"},
 {"airport_iata_code":"LBX", "airport_name":"Lubang Airport", "city_name":""},
 {"airport_iata_code":"AAV", "airport_name":"Allah Valley Airport", "city_name":"Surallah"},
 {"airport_iata_code":"CBO", "airport_name":"Awang Airport", "city_name":"Cotabato City"},
 {"airport_iata_code":"DVO", "airport_name":"Francisco Bangoy International Airport", "city_name":"Davao City"},
 {"airport_iata_code":"BXU", "airport_name":"Bancasi Airport", "city_name":"Butuan City"},
 {"airport_iata_code":"BPH", "airport_name":"Bislig Airport", "city_name":""},
 {"airport_iata_code":"DPL", "airport_name":"Dipolog Airport", "city_name":"Dipolog City"},
 {"airport_iata_code":"CGM", "airport_name":"Camiguin Airport", "city_name":""},
 {"airport_iata_code":"IGN", "airport_name":"Iligan Airport", "city_name":""},
 {"airport_iata_code":"JOL", "airport_name":"Jolo Airport", "city_name":""},
 {"airport_iata_code":"CGY", "airport_name":"Cagayan De Oro Airport", "city_name":"Cagayan De Oro City"},
 {"airport_iata_code":"MLP", "airport_name":"Malabang Airport", "city_name":"Malabang"},
 {"airport_iata_code":"SGS", "airport_name":"Sanga Sanga Airport", "city_name":""},
 {"airport_iata_code":"OZC", "airport_name":"Labo Airport", "city_name":"Ozamiz City"},
 {"airport_iata_code":"PAG", "airport_name":"Pagadian Airport", "city_name":"Pagadian City"},
 {"airport_iata_code":"MXI", "airport_name":"Mati National Airport", "city_name":""},
 {"airport_iata_code":"GES", "airport_name":"Tambler Airport", "city_name":"General Santos"},
 {"airport_iata_code":"SUG", "airport_name":"Surigao Airport", "city_name":"Surigao City"},
 {"airport_iata_code":"CDY", "airport_name":"Cagayan de Sulu Airport", "city_name":"Mapun"},
 {"airport_iata_code":"IPE", "airport_name":"Ipil Airport", "city_name":"Ipil"},
 {"airport_iata_code":"TDG", "airport_name":"Tandag Airport", "city_name":""},
 {"airport_iata_code":"ZAM", "airport_name":"Zamboanga International Airport", "city_name":"Zamboanga City"},
 {"airport_iata_code":"IAO", "airport_name":"Siargao Airport", "city_name":"Del Carmen"},
 {"airport_iata_code":"RZP", "airport_name":"Cesar Lim Rodriguez Airport", "city_name":"Taytay Airport, Sandoval Airpo"},
 {"airport_iata_code":"BAG", "airport_name":"Loakan Airport", "city_name":"Baguio City"},
 {"airport_iata_code":"DTE", "airport_name":"Daet Airport", "city_name":"Daet"},
 {"airport_iata_code":"SJI", "airport_name":"San Jose Airport", "city_name":"San Jose"},
 {"airport_iata_code":"MBO", "airport_name":"Mamburao Airport", "city_name":""},
 {"airport_iata_code":"WNP", "airport_name":"Naga Airport", "city_name":"Naga"},
 {"airport_iata_code":"BSO", "airport_name":"Basco Airport", "city_name":"Basco"},
 {"airport_iata_code":"BQA", "airport_name":"Dr.Juan C. Angara Airport", "city_name":"Baler"},
 {"airport_iata_code":"SFE", "airport_name":"San Fernando Airport", "city_name":""},
 {"airport_iata_code":"TUG", "airport_name":"Tuguegarao Airport", "city_name":"Tuguegarao City"},
 {"airport_iata_code":"VRC", "airport_name":"Virac Airport", "city_name":"Virac"},
 {"airport_iata_code":"MRQ", "airport_name":"Marinduque Airport", "city_name":"Gasan"},
 {"airport_iata_code":"CYZ", "airport_name":"Cauayan Airport", "city_name":"Cauayan City"},
 {"airport_iata_code":"TAC", "airport_name":"Daniel Z. Romualdez Airport", "city_name":"Tacloban City"},
 {"airport_iata_code":"BCD", "airport_name":"Bacolod-Silay City International Airport", "city_name":"Bacolod City"},
 {"airport_iata_code":"CYP", "airport_name":"Calbayog Airport", "city_name":"Calbayog City"},
 {"airport_iata_code":"DGT", "airport_name":"Sibulan Airport", "city_name":"Dumaguete City"},
 {"airport_iata_code":"MPH", "airport_name":"Godofredo P. Ramos Airport", "city_name":"Malay"},
 {"airport_iata_code":"CRM", "airport_name":"Catarman National Airport", "city_name":"Catarman"},
 {"airport_iata_code":"ILO", "airport_name":"Iloilo International Airport", "city_name":"Iloilo City"},
 {"airport_iata_code":"MBT", "airport_name":"Moises R. Espinosa Airport", "city_name":"Masbate"},
 {"airport_iata_code":"KLO", "airport_name":"Kalibo International Airport", "city_name":"Kalibo"},
 {"airport_iata_code":"CEB", "airport_name":"Mactan Cebu International Airport", "city_name":"Lapu-Lapu City"},
 {"airport_iata_code":"OMC", "airport_name":"Ormoc Airport", "city_name":"Ormoc City"},
 {"airport_iata_code":"PPS", "airport_name":"Puerto Princesa Airport", "city_name":"Puerto Princesa City"},
 {"airport_iata_code":"RXS", "airport_name":"Roxas Airport", "city_name":"Roxas City"},
 {"airport_iata_code":"EUQ", "airport_name":"Evelio Javier Airport", "city_name":"San Jose"},
 {"airport_iata_code":"TAG", "airport_name":"Tagbilaran Airport", "city_name":"Tagbilaran City"},
 {"airport_iata_code":"TBH", "airport_name":"Romblon Airport", "city_name":"Romblon"},
 {"airport_iata_code":"USU", "airport_name":"Francisco B. Reyes Airport", "city_name":"Coron"},
 {"airport_iata_code":"DSG", "airport_name":"Dilasag Airport", "city_name":"Dilasag"},
 {"airport_iata_code":"AAW", "airport_name":"Abbottabad Airport", "city_name":"Abbottabad"},
 {"airport_iata_code":"BHW", "airport_name":"Bhagatanwala Airport", "city_name":"Bhagatanwala"},
 {"airport_iata_code":"BNP", "airport_name":"Bannu Airport", "city_name":"Bannu"},
 {"airport_iata_code":"WGB", "airport_name":"Bahawalnagar Airport", "city_name":"Bahawalnagar"},
 {"airport_iata_code":"BHV", "airport_name":"Bahawalpur Airport", "city_name":"Bahawalpur"},
 {"airport_iata_code":"CJL", "airport_name":"Chitral Airport", "city_name":"Chitral"},
 {"airport_iata_code":"CHB", "airport_name":"Chilas Airport", "city_name":"Chilas"},
 {"airport_iata_code":"DBA", "airport_name":"Dalbandin Airport", "city_name":"Dalbandin"},
 {"airport_iata_code":"DDU", "airport_name":"Dadu Airport", "city_name":"Dadu"},
 {"airport_iata_code":"DEA", "airport_name":"Dera Ghazi Khan Airport", "city_name":"Dera Ghazi Khan"},
 {"airport_iata_code":"DSK", "airport_name":"Dera Ismael Khan Airport", "city_name":"Dera Ismael Khan"},
 {"airport_iata_code":"LYP", "airport_name":"Faisalabad International Airport", "city_name":"Faisalabad"},
 {"airport_iata_code":"GWD", "airport_name":"Gwadar International Airport", "city_name":"Gwadar"},
 {"airport_iata_code":"GIL", "airport_name":"Gilgit Airport", "city_name":"Gilgit"},
 {"airport_iata_code":"JAG", "airport_name":"Shahbaz Air Base", "city_name":"Jacobabad"},
 {"airport_iata_code":"JIW", "airport_name":"Jiwani Airport", "city_name":"Jiwani"},
 {"airport_iata_code":"KHI", "airport_name":"Jinnah International Airport", "city_name":"Karachi"},
 {"airport_iata_code":"HDD", "airport_name":"Hyderabad Airport", "city_name":"Hyderabad"},
 {"airport_iata_code":"KDD", "airport_name":"Khuzdar Airport", "city_name":"Khuzdar"},
 {"airport_iata_code":"KBH", "airport_name":"Kalat Airport", "city_name":"Kalat"},
 {"airport_iata_code":"OHT", "airport_name":"Kohat Airport", "city_name":"Kohat"},
 {"airport_iata_code":"LHE", "airport_name":"Alama Iqbal International Airport", "city_name":"Lahore"},
 {"airport_iata_code":"LRG", "airport_name":"Loralai Airport", "city_name":"Loralai"},
 {"airport_iata_code":"XJM", "airport_name":"Mangla Airport", "city_name":"Mangla"},
 {"airport_iata_code":"MFG", "airport_name":"Muzaffarabad Airport", "city_name":"Muzaffarabad"},
 {"airport_iata_code":"MWD", "airport_name":"Mianwali Air Base", "city_name":"Mianwali"},
 {"airport_iata_code":"MJD", "airport_name":"Moenjodaro Airport", "city_name":"Moenjodaro"},
 {"airport_iata_code":"MPD", "airport_name":"Sindhri Tharparkar Airport", "city_name":"Sindhri"},
 {"airport_iata_code":"MUX", "airport_name":"Multan International Airport", "city_name":"Multan"},
 {"airport_iata_code":"WNS", "airport_name":"Nawabshah Airport", "city_name":"Nawabash"},
 {"airport_iata_code":"NHS", "airport_name":"Nushki Airport", "city_name":"Nushki"},
 {"airport_iata_code":"ORW", "airport_name":"Ormara Airport", "city_name":"Ormara Raik"},
 {"airport_iata_code":"PAJ", "airport_name":"Parachinar Airport", "city_name":"Parachinar"},
 {"airport_iata_code":"PJG", "airport_name":"Panjgur Airport", "city_name":"Panjgur"},
 {"airport_iata_code":"PSI", "airport_name":"Pasni Airport", "city_name":"Pasni"},
 {"airport_iata_code":"PEW", "airport_name":"Peshawar International Airport", "city_name":"Peshawar"},
 {"airport_iata_code":"UET", "airport_name":"Quetta International Airport", "city_name":"Quetta"},
 {"airport_iata_code":"RYK", "airport_name":"Shaikh Zaid Airport", "city_name":"Rahim Yar Khan"},
 {"airport_iata_code":"ISB", "airport_name":"Benazir Bhutto International Airport", "city_name":"Islamabad"},
 {"airport_iata_code":"RAZ", "airport_name":"Rawalakot Airport", "city_name":"Rawalakot"},
 {"airport_iata_code":"SBQ", "airport_name":"Sibi Airport", "city_name":"Sibi"},
 {"airport_iata_code":"KDU", "airport_name":"Skardu Airport", "city_name":"Skardu"},
 {"airport_iata_code":"SKZ", "airport_name":"Sukkur Airport", "city_name":"Mirpur Khas"},
 {"airport_iata_code":"SYW", "airport_name":"Sehwan Sharif Airport", "city_name":"Sehwan Sharif"},
 {"airport_iata_code":"SGI", "airport_name":"Mushaf Air Base", "city_name":"Sargodha"},
 {"airport_iata_code":"SDT", "airport_name":"Saidu Sharif Airport", "city_name":"Saidu Sharif"},
 {"airport_iata_code":"SKT", "airport_name":"Sialkot Airport", "city_name":"Sialkot"},
 {"airport_iata_code":"SUL", "airport_name":"Sui Airport", "city_name":"Sui"},
 {"airport_iata_code":"SWN", "airport_name":"Sahiwal Airport", "city_name":"Sahiwal"},
 {"airport_iata_code":"TLB", "airport_name":"Tarbela Dam Airport", "city_name":"Tarbela"},
 {"airport_iata_code":"BDN", "airport_name":"Talhar Airport", "city_name":"Badin"},
 {"airport_iata_code":"TFT", "airport_name":"Taftan Airport", "city_name":"Taftan"},
 {"airport_iata_code":"TUK", "airport_name":"Turbat International Airport", "city_name":"Turbat"},
 {"airport_iata_code":"WAF", "airport_name":"Wana Airport", "city_name":"Waana"},
 {"airport_iata_code":"PZH", "airport_name":"Zhob Airport", "city_name":"Fort Sandeman"},
 {"airport_iata_code":"BHC", "airport_name":"Bhurban Heliport", "city_name":"Bhurban"},
 {"airport_iata_code":"CWP", "airport_name":"Campbellpore Airport", "city_name":"Campbellpore"},
 {"airport_iata_code":"GRT", "airport_name":"Gujrat Airport", "city_name":"Gujrat"},
 {"airport_iata_code":"HRA", "airport_name":"Mansehra Airport", "city_name":"Mansehra"},
 {"airport_iata_code":"KCF", "airport_name":"Kadanwari Airport", "city_name":"Kadanwari"},
 {"airport_iata_code":"REQ", "airport_name":"Reko Diq Airport", "city_name":"Chagai"},
 {"airport_iata_code":"RZS", "airport_name":"Sawan Airport", "city_name":"Sawan"},
 {"airport_iata_code":"QYY", "airport_name":"Biaaystok-Krywlany Airport", "city_name":"Biaaystok"},
 {"airport_iata_code":"BXP", "airport_name":"Bia?a Podlaska Airport", "city_name":"Bia?a Podlaska"},
 {"airport_iata_code":"BZG", "airport_name":"Bydgoszcz Ignacy Jan Paderewski Airport", "city_name":"Bydgoszcz"},
 {"airport_iata_code":"CZW", "airport_name":"Cz?stochowa-Rudniki", "city_name":"Cz?stochowa"},
 {"airport_iata_code":"GDN", "airport_name":"Gda?sk Lech Wa??sa Airport", "city_name":"Gda?sk"},
 {"airport_iata_code":"QLC", "airport_name":"Gliwice Glider Airport", "city_name":""},
 {"airport_iata_code":"KRK", "airport_name":"John Paul II International Airport Krak\u00f3w-Balice Airport", "city_name":"Krak\u00f3w"},
 {"airport_iata_code":"OSZ", "airport_name":"Koszalin Zegrze Airport", "city_name":""},
 {"airport_iata_code":"KTW", "airport_name":"Katowice International Airport", "city_name":"Katowice"},
 {"airport_iata_code":"QEO", "airport_name":"Bielsko-Bialo Kaniow Airfield", "city_name":"Czechowice-Dziedzice"},
 {"airport_iata_code":"LCJ", "airport_name":"Lodz Wladyslaw Reymont Airport", "city_name":"Lodz"},
 {"airport_iata_code":"QLU", "airport_name":"Lublin Radwiec Airport", "city_name":"Lublin"},
 {"airport_iata_code":"QWS", "airport_name":"Nowy Targ Airport", "city_name":"Nowy Targ"},
 {"airport_iata_code":"QYD", "airport_name":"Oksywie Military Air Base", "city_name":"Gdynia"},
 {"airport_iata_code":"QPM", "airport_name":"Opole-Polska Nowa Wie? Airport", "city_name":"Opole"},
 {"airport_iata_code":"POZ", "airport_name":"Pozna?-?awica Airport", "city_name":"Pozna?"},
 {"airport_iata_code":"RZE", "airport_name":"Rzesz\u00f3w-Jasionka Airport", "city_name":"Rzesz\u00f3w"},
 {"airport_iata_code":"SZZ", "airport_name":"Szczecin-Goleni\u00f3w \"Solidarno??\" Airport", "city_name":"Goleniow"},
 {"airport_iata_code":"WAW", "airport_name":"Warsaw Chopin Airport", "city_name":"Warsaw"},
 {"airport_iata_code":"WRO", "airport_name":"Copernicus Wroc?aw Airport", "city_name":"Wroc?aw"},
 {"airport_iata_code":"IEG", "airport_name":"Zielona G\u00f3ra-Babimost Airport", "city_name":"Babimost"},
 {"airport_iata_code":"MQC", "airport_name":"Miquelon Airport", "city_name":"Miquelon"},
 {"airport_iata_code":"FSP", "airport_name":"St Pierre Airport", "city_name":"Saint-Pierre"},
 {"airport_iata_code":"HUC", "airport_name":"Humacao Airport", "city_name":"Humacao"},
 {"airport_iata_code":"PPD", "airport_name":"Palmas del Mar Airstrip", "city_name":"Palmas del Mar"},
 {"airport_iata_code":"ARE", "airport_name":"Antonio Nery Juarbe Pol Airport", "city_name":"Arecibo"},
 {"airport_iata_code":"BQN", "airport_name":"Rafael Hernandez Airport", "city_name":"Aguadilla"},
 {"airport_iata_code":"CPX", "airport_name":"Benjamin Rivera Noriega Airport", "city_name":"Culebra Island"},
 {"airport_iata_code":"FAJ", "airport_name":"Diego Jimenez Torres Airport", "city_name":"Fajardo"},
 {"airport_iata_code":"SIG", "airport_name":"Fernando Luis Ribas Dominicci Airport", "city_name":"San Juan"},
 {"airport_iata_code":"MAZ", "airport_name":"Eugenio Maria De Hostos Airport", "city_name":"Mayaguez"},
 {"airport_iata_code":"PSE", "airport_name":"Mercedita Airport", "city_name":"Ponce"},
 {"airport_iata_code":"SJU", "airport_name":"Luis Munoz Marin International Airport", "city_name":"San Juan"},
 {"airport_iata_code":"VQS", "airport_name":"Antonio Rivera Rodriguez Airport", "city_name":"Vieques Island"},
 {"airport_iata_code":"MJF", "airport_name":"Areopuerto Internacional Islas Michael", "city_name":"Michael"},
 {"airport_iata_code":"SMA", "airport_name":"Santa Maria Airport", "city_name":"Vila do Porto"},
 {"airport_iata_code":"BGC", "airport_name":"Braganca Airport", "city_name":""},
 {"airport_iata_code":"BYJ", "airport_name":"Beja International Airport", "city_name":"Beja"},
 {"airport_iata_code":"BGZ", "airport_name":"Braga Municipal Aerodrome", "city_name":"Braga"},
 {"airport_iata_code":"CHV", "airport_name":"Chaves Airport", "city_name":"Chaves"},
 {"airport_iata_code":"CBP", "airport_name":"Coimbra Airport", "city_name":""},
 {"airport_iata_code":"CVU", "airport_name":"Corvo Airport", "city_name":"Corvo"},
 {"airport_iata_code":"COV", "airport_name":"Covilha Airport", "city_name":""},
 {"airport_iata_code":"FLW", "airport_name":"Flores Airport", "city_name":"Santa Cruz das Flores"},
 {"airport_iata_code":"FAO", "airport_name":"Faro Airport", "city_name":"Faro"},
 {"airport_iata_code":"GRW", "airport_name":"Graciosa Airport", "city_name":"Santa Cruz da Graciosa"},
 {"airport_iata_code":"HOR", "airport_name":"Horta Airport", "city_name":"Horta"},
 {"airport_iata_code":"TER", "airport_name":"Lajes Field", "city_name":"Lajes"},
 {"airport_iata_code":"FNC", "airport_name":"Madeira Airport", "city_name":"Funchal"},
 {"airport_iata_code":"PDL", "airport_name":"Jo\u00e3o Paulo II Airport", "city_name":"Ponta Delgada"},
 {"airport_iata_code":"PIX", "airport_name":"Pico Airport", "city_name":"Pico Island"},
 {"airport_iata_code":"PRM", "airport_name":"Portimao Airport", "city_name":""},
 {"airport_iata_code":"OPO", "airport_name":"Francisco de S\u00e1 Carneiro Airport", "city_name":"Porto"},
 {"airport_iata_code":"PXO", "airport_name":"Porto Santo Airport", "city_name":"Vila Baleira"},
 {"airport_iata_code":"LIS", "airport_name":"Lisbon Portela Airport", "city_name":"Lisbon"},
 {"airport_iata_code":"SIE", "airport_name":"Sines Airport", "city_name":""},
 {"airport_iata_code":"SJZ", "airport_name":"S\u00e3o Jorge Airport", "city_name":"Velas"},
 {"airport_iata_code":"VRL", "airport_name":"Vila Real Airport", "city_name":""},
 {"airport_iata_code":"VSE", "airport_name":"Viseu Airport", "city_name":""},
 {"airport_iata_code":"ROR", "airport_name":"Babelthuap Airport", "city_name":"Babelthuap Island"},
 {"airport_iata_code":"BFA", "airport_name":"Bah\u00eda Negra Airport", "city_name":"Bah\u00eda Negra"},
 {"airport_iata_code":"OLK", "airport_name":"Fuerte Olimpo Airport", "city_name":"Fuerte Olimpo"},
 {"airport_iata_code":"PBT", "airport_name":"Puerto Leda Airport", "city_name":"Puerto Leda"},
 {"airport_iata_code":"PCJ", "airport_name":"Puerto La Victoria Airport", "city_name":"Puerto La Victoria"},
 {"airport_iata_code":"ASU", "airport_name":"Silvio Pettirossi International Airport", "city_name":"Asunci\u00f3n"},
 {"airport_iata_code":"AYO", "airport_name":"Juan De Ayolas Airport", "city_name":"Ayolas"},
 {"airport_iata_code":"CIO", "airport_name":"Teniente Col Carmelo Peralta Airport", "city_name":"Concepci\u00f3n"},
 {"airport_iata_code":"ENO", "airport_name":"Encarnaci\u00f3n Airport", "city_name":"Encarnaci\u00f3n"},
 {"airport_iata_code":"AGT", "airport_name":"Guarani International Airport", "city_name":"Ciudad del Este"},
 {"airport_iata_code":"FLM", "airport_name":"Filadelfia Airport", "city_name":"Filadelfia"},
 {"airport_iata_code":"ESG", "airport_name":"Dr. Luis Maria Arga\u00f1a International Airport", "city_name":"Mariscal Estigarribia"},
 {"airport_iata_code":"PIL", "airport_name":"Carlos Miguel Gimenez Airport", "city_name":"Pilar"},
 {"airport_iata_code":"PJC", "airport_name":"Dr Augusto Roberto Fuster International Airport", "city_name":"Pedro Juan Caballero"},
 {"airport_iata_code":"VMI", "airport_name":"Dr Juan Plate Airport", "city_name":"Puerto Vallemi"},
 {"airport_iata_code":"DOH", "airport_name":"Doha International Airport", "city_name":"Doha"},
 {"airport_iata_code":"IUD", "airport_name":"Al Udeid Air Base", "city_name":"Ar Rayyan"},
 {"airport_iata_code":"RUN", "airport_name":"Roland Garros Airport", "city_name":"St Denis"},
 {"airport_iata_code":"ZSE", "airport_name":"Pierrefonds Airport", "city_name":"St Pierre"},
 {"airport_iata_code":"ARW", "airport_name":"Arad International Airport", "city_name":"Arad"},
 {"airport_iata_code":"BCM", "airport_name":"Bacau Airport", "city_name":"Bacau"},
 {"airport_iata_code":"BAY", "airport_name":"Tautii Magheraus Airport", "city_name":"Baia Mare"},
 {"airport_iata_code":"BBU", "airport_name":"B?neasa International Airport", "city_name":"Bucharest"},
 {"airport_iata_code":"CND", "airport_name":"Mihail Kogalniceanu International Airport", "city_name":"Constanta"},
 {"airport_iata_code":"CLJ", "airport_name":"Cluj-Napoca International Airport", "city_name":"Cluj-Napoca"},
 {"airport_iata_code":"CSB", "airport_name":"Caransebes Airport", "city_name":"Caransebe?"},
 {"airport_iata_code":"CRA", "airport_name":"Craiova Airport", "city_name":"Craiova"},
 {"airport_iata_code":"IAS", "airport_name":"Ia?i Airport", "city_name":"Ia?i"},
 {"airport_iata_code":"OMR", "airport_name":"Oradea International Airport", "city_name":"Oradea"},
 {"airport_iata_code":"OTP", "airport_name":"Henri Coanda International Airport", "city_name":"Bucharest"},
 {"airport_iata_code":"SBZ", "airport_name":"Sibiu International Airport", "city_name":"Sibiu"},
 {"airport_iata_code":"SUJ", "airport_name":"Satu Mare Airport", "city_name":"Satu Mare"},
 {"airport_iata_code":"SCV", "airport_name":"Suceava Stefan cel Mare Airport", "city_name":"Suceava"},
 {"airport_iata_code":"TCE", "airport_name":"Tulcea Airport", "city_name":"Tulcea"},
 {"airport_iata_code":"TGM", "airport_name":"Transilvania Targu Mures International Airport", "city_name":"Targu Mures"},
 {"airport_iata_code":"TSR", "airport_name":"Timisoara Traian Vuia Airport", "city_name":"Timisoara"},
 {"airport_iata_code":"UZC", "airport_name":"Ponikve Airport", "city_name":"U\u017eice"},
 {"airport_iata_code":"BEG", "airport_name":"Belgrade Nikola Tesla Airport", "city_name":"Belgrad"},
 {"airport_iata_code":"BJY", "airport_name":"Batajnica Air Base", "city_name":"Batajnica"},
 {"airport_iata_code":"INI", "airport_name":"Nis Airport", "city_name":"Nis"},
 {"airport_iata_code":"QND", "airport_name":"Cenej Airport", "city_name":"Novi Sad"},
 {"airport_iata_code":"QWV", "airport_name":"Divci Airport", "city_name":"Valjevo"},
 {"airport_iata_code":"ZRE", "airport_name":"Zrenjanin Airport", "city_name":"Zrenjanin"},
 {"airport_iata_code":"NGK", "airport_name":"Nogliki Airport", "city_name":"Nogliki-Sakhalin Island"},
 {"airport_iata_code":"GRV", "airport_name":"Grozny North Airport", "city_name":"Grozny"},
 {"airport_iata_code":"LNX", "airport_name":"Smolensk South Airport", "city_name":"Smolensk"},
 {"airport_iata_code":"VUS", "airport_name":"Velikiy Ustyug Airport", "city_name":"Velikiy Ustyug"},
 {"airport_iata_code":"ADH", "airport_name":"Aldan Airport", "city_name":"Aldan"},
 {"airport_iata_code":"YKS", "airport_name":"Yakutsk Airport", "city_name":"Yakutsk"},
 {"airport_iata_code":"CNN", "airport_name":"Chulman Airport", "city_name":"Chulman"},
 {"airport_iata_code":"ULK", "airport_name":"Lensk Airport", "city_name":"Lensk"},
 {"airport_iata_code":"PYJ", "airport_name":"Polyarny Airport", "city_name":"Yakutia"},
 {"airport_iata_code":"MJZ", "airport_name":"Mirny Airport", "city_name":"Mirny"},
 {"airport_iata_code":"CKH", "airport_name":"Chokurdakh Airport", "city_name":"Chokurdah"},
 {"airport_iata_code":"CYX", "airport_name":"Cherskiy Airport", "city_name":"Cherskiy"},
 {"airport_iata_code":"IKS", "airport_name":"Tiksi Airport", "city_name":"Tiksi"},
 {"airport_iata_code":"BQS", "airport_name":"Ignatyevo Airport", "city_name":"Blagoveschensk"},
 {"airport_iata_code":"GDG", "airport_name":"Magdagachi Airport", "city_name":"Magdagachi"},
 {"airport_iata_code":"TYD", "airport_name":"Tynda Airport", "city_name":"Tynda"},
 {"airport_iata_code":"KHV", "airport_name":"Khabarovsk-Novy Airport", "city_name":"Khabarovsk"},
 {"airport_iata_code":"KXK", "airport_name":"Komsomolsk-on-Amur Airport", "city_name":"Komsomolsk-on-Amur"},
 {"airport_iata_code":"GVN", "airport_name":"Maygatka Airport.", "city_name":"Sovetskaya Gavan"},
 {"airport_iata_code":"DYR", "airport_name":"Ugolny Airport", "city_name":"Anadyr"},
 {"airport_iata_code":"PVS", "airport_name":"Provideniya Bay Airport", "city_name":"Chukotka"},
 {"airport_iata_code":"GDX", "airport_name":"Sokol Airport", "city_name":"Magadan"},
 {"airport_iata_code":"PWE", "airport_name":"Pevek Airport", "city_name":"Pevek"},
 {"airport_iata_code":"BQG", "airport_name":"Bogorodskoye Airport", "city_name":"Bogorodskoye"},
 {"airport_iata_code":"OHO", "airport_name":"Okhotsk Airport", "city_name":"Okhotsk"},
 {"airport_iata_code":"PKC", "airport_name":"Yelizovo Airport", "city_name":"Petropavlovsk-Kamchatsky"},
 {"airport_iata_code":"OHH", "airport_name":"Okha Airport", "city_name":"Okha"},
 {"airport_iata_code":"EKS", "airport_name":"Shakhtyorsk Airport", "city_name":"Shakhtersk"},
 {"airport_iata_code":"DEE", "airport_name":"Mendeleyevo Airport", "city_name":"Kunashir Island"},
 {"airport_iata_code":"ZZO", "airport_name":"Zonalnoye Airport", "city_name":"Tymovskoye"},
 {"airport_iata_code":"UUS", "airport_name":"Yuzhno-Sakhalinsk Airport", "city_name":"Yuzhno-Sakhalinsk"},
 {"airport_iata_code":"VVO", "airport_name":"Vladivostok International Airport", "city_name":"Vladivostok"},
 {"airport_iata_code":"HTA", "airport_name":"Chita-Kadala Airport", "city_name":"Chita"},
 {"airport_iata_code":"BTK", "airport_name":"Bratsk Airport", "city_name":"Bratsk"},
 {"airport_iata_code":"UIK", "airport_name":"Ust-Ilimsk Airport", "city_name":"Ust-Ilimsk"},
 {"airport_iata_code":"IKT", "airport_name":"Irkutsk Airport", "city_name":"Irkutsk"},
 {"airport_iata_code":"ODO", "airport_name":"Bodaybo Airport", "city_name":"Bodaybo"},
 {"airport_iata_code":"ERG", "airport_name":"Yerbogachen Airport", "city_name":"Erbogachen"},
 {"airport_iata_code":"UKX", "airport_name":"Ust-Kut Airport", "city_name":"Ust-Kut"},
 {"airport_iata_code":"UUD", "airport_name":"Ulan-Ude Airport (Mukhino)", "city_name":"Ulan Ude"},
 {"airport_iata_code":"ARH", "airport_name":"Talagi Airport", "city_name":"Archangelsk"},
 {"airport_iata_code":"LDG", "airport_name":"Leshukonskoye Airport", "city_name":"Leshukonskoye"},
 {"airport_iata_code":"NNM", "airport_name":"Naryan Mar Airport", "city_name":"Naryan Mar"},
 {"airport_iata_code":"CSH", "airport_name":"Solovki Airport", "city_name":"Solovetsky Islands"},
 {"airport_iata_code":"CEE", "airport_name":"Cherepovets Airport", "city_name":"Cherepovets"},
 {"airport_iata_code":"AMV", "airport_name":"Amderma Airport", "city_name":"Amderma"},
 {"airport_iata_code":"KSZ", "airport_name":"Kotlas Airport", "city_name":"Kotlas"},
 {"airport_iata_code":"LED", "airport_name":"Pulkovo Airport", "city_name":"St. Petersburg"},
 {"airport_iata_code":"KVK", "airport_name":"Kirovsk-Apatity Airport", "city_name":"Apatity"},
 {"airport_iata_code":"MMK", "airport_name":"Murmansk Airport", "city_name":"Murmansk"},
 {"airport_iata_code":"VLU", "airport_name":"Velikiye Luki Airport", "city_name":"Velikiye Luki"},
 {"airport_iata_code":"PKV", "airport_name":"Pskov Airport", "city_name":"Pskov"},
 {"airport_iata_code":"PES", "airport_name":"Petrozavodsk Airport", "city_name":"Petrozavodsk"},
 {"airport_iata_code":"VGD", "airport_name":"Vologda Airport", "city_name":"Vologda"},
 {"airport_iata_code":"KGD", "airport_name":"Khrabrovo Airport", "city_name":"Kaliningrad"},
 {"airport_iata_code":"ABA", "airport_name":"Abakan Airport", "city_name":"Abakan"},
 {"airport_iata_code":"BAX", "airport_name":"Barnaul Airport", "city_name":"Barnaul"},
 {"airport_iata_code":"RGK", "airport_name":"Gorno-Altaysk Airport", "city_name":"Gorno-Altaysk"},
 {"airport_iata_code":"KEJ", "airport_name":"Kemerovo Airport", "city_name":"Kemerovo"},
 {"airport_iata_code":"EIE", "airport_name":"Yeniseysk Airport", "city_name":"Yeniseysk"},
 {"airport_iata_code":"KJA", "airport_name":"Yemelyanovo Airport", "city_name":"Krasnoyarsk"},
 {"airport_iata_code":"ACS", "airport_name":"Achinsk Airport", "city_name":"Achinsk"},
 {"airport_iata_code":"KYZ", "airport_name":"Kyzyl Airport", "city_name":"Kyzyl"},
 {"airport_iata_code":"OVB", "airport_name":"Tolmachevo Airport", "city_name":"Novosibirsk"},
 {"airport_iata_code":"OMS", "airport_name":"Omsk Central Airport", "city_name":"Omsk"},
 {"airport_iata_code":"SWT", "airport_name":"Strezhevoy Airport", "city_name":"Strezhevoy"},
 {"airport_iata_code":"TOF", "airport_name":"Bogashevo Airport", "city_name":"Tomsk"},
 {"airport_iata_code":"NOZ", "airport_name":"Spichenkovo Airport", "city_name":"Novokuznetsk"},
 {"airport_iata_code":"DKS", "airport_name":"Dikson Airport", "city_name":"Dikson"},
 {"airport_iata_code":"HTG", "airport_name":"Khatanga Airport", "city_name":"Khatanga"},
 {"airport_iata_code":"IAA", "airport_name":"Igarka Airport", "city_name":"Igarka"},
 {"airport_iata_code":"NSK", "airport_name":"Norilsk-Alykel Airport", "city_name":"Norilsk"},
 {"airport_iata_code":"AAQ", "airport_name":"Anapa Airport", "city_name":"Anapa"},
 {"airport_iata_code":"EIK", "airport_name":"Yeysk Airport", "city_name":"Yeysk"},
 {"airport_iata_code":"GDZ", "airport_name":"Gelendzhik Airport", "city_name":"Gelendzhik"},
 {"airport_iata_code":"KRR", "airport_name":"Krasnodar International Airport", "city_name":"Krasnodar"},
 {"airport_iata_code":"MCX", "airport_name":"Uytash Airport", "city_name":"Makhachkala"},
 {"airport_iata_code":"MRV", "airport_name":"Mineralnyye Vody Airport", "city_name":"Mineralnyye Vody"},
 {"airport_iata_code":"NAL", "airport_name":"Nalchik Airport", "city_name":"Nalchik"},
 {"airport_iata_code":"OGZ", "airport_name":"Beslan Airport", "city_name":"Beslan"},
 {"airport_iata_code":"IGT", "airport_name":"Magas Airport", "city_name":"Magas"},
 {"airport_iata_code":"STW", "airport_name":"Stavropol Shpakovskoye Airport", "city_name":"Stavropol"},
 {"airport_iata_code":"ROV", "airport_name":"Rostov-na-Donu Airport", "city_name":"Rostov-on-Don"},
 {"airport_iata_code":"VLK", "airport_name":"Volgodonsk Airport", "city_name":""},
 {"airport_iata_code":"AER", "airport_name":"Sochi International Airport", "city_name":"Sochi"},
 {"airport_iata_code":"ASF", "airport_name":"Astrakhan Airport", "city_name":"Astrakhan"},
 {"airport_iata_code":"ESL", "airport_name":"Elista Airport", "city_name":"Elista"},
 {"airport_iata_code":"VOG", "airport_name":"Volgograd International Airport", "city_name":"Volgograd"},
 {"airport_iata_code":"CEK", "airport_name":"Chelyabinsk Balandino Airport", "city_name":"Chelyabinsk"},
 {"airport_iata_code":"MQF", "airport_name":"Magnitogorsk International Airport", "city_name":"Magnitogorsk"},
 {"airport_iata_code":"SLY", "airport_name":"Salekhard Airport", "city_name":"Salekhard"},
 {"airport_iata_code":"YMK", "airport_name":"Mys Kamenny Airport", "city_name":"Mys Kamennyi"},
 {"airport_iata_code":"TQL", "airport_name":"Tarko-Sale Airport", "city_name":"Tarko-Sale"},
 {"airport_iata_code":"UEN", "airport_name":"Urengoy Airport", "city_name":"Urengoy"},
 {"airport_iata_code":"EZV", "airport_name":"Berezovo Airport", "city_name":""},
 {"airport_iata_code":"HMA", "airport_name":"Khanty Mansiysk Airport", "city_name":"Khanty-Mansiysk"},
 {"airport_iata_code":"IRM", "airport_name":"Igrim Airport", "city_name":""},
 {"airport_iata_code":"NYA", "airport_name":"Nyagan Airport", "city_name":"Nyagan"},
 {"airport_iata_code":"OVS", "airport_name":"Sovetskiy Airport", "city_name":"Sovetskiy"},
 {"airport_iata_code":"URJ", "airport_name":"Uray Airport", "city_name":"Uray"},
 {"airport_iata_code":"EYK", "airport_name":"Beloyarskiy Airport", "city_name":""},
 {"airport_iata_code":"IJK", "airport_name":"Izhevsk Airport", "city_name":"Izhevsk"},
 {"airport_iata_code":"KVX", "airport_name":"Pobedilovo Airport", "city_name":"Kirov"},
 {"airport_iata_code":"NYM", "airport_name":"Nadym Airport", "city_name":"Nadym"},
 {"airport_iata_code":"NUX", "airport_name":"Novy Urengoy Airport", "city_name":"Novy Urengoy"},
 {"airport_iata_code":"NJC", "airport_name":"Nizhnevartovsk Airport", "city_name":"Nizhnevartovsk"},
 {"airport_iata_code":"PEE", "airport_name":"Bolshoye Savino Airport", "city_name":"Perm"},
 {"airport_iata_code":"KGP", "airport_name":"Kogalym International Airport", "city_name":"Kogalym"},
 {"airport_iata_code":"NFG", "airport_name":"Nefteyugansk Airport", "city_name":"Nefteyugansk"},
 {"airport_iata_code":"NOJ", "airport_name":"Noyabrsk Airport", "city_name":"Noyabrsk"},
 {"airport_iata_code":"SGC", "airport_name":"Surgut Airport", "city_name":"Surgut"},
 {"airport_iata_code":"SVX", "airport_name":"Koltsovo Airport", "city_name":"Yekaterinburg"},
 {"airport_iata_code":"TOX", "airport_name":"Tobolsk Airport", "city_name":"Tobolsk"},
 {"airport_iata_code":"TJM", "airport_name":"Roshchino International Airport", "city_name":"Tyumen"},
 {"airport_iata_code":"KRO", "airport_name":"Kurgan Airport", "city_name":"Kurgan"},
 {"airport_iata_code":"KMW", "airport_name":"Kostroma Sokerkino Airport", "city_name":"Kostroma"},
 {"airport_iata_code":"BKA", "airport_name":"Bykovo Airport", "city_name":"Moscow"},
 {"airport_iata_code":"KLF", "airport_name":"Grabtsevo Airport", "city_name":"Kaluga"},
 {"airport_iata_code":"IWA", "airport_name":"Ivanovo South Airport", "city_name":"Ivanovo"},
 {"airport_iata_code":"RYB", "airport_name":"Staroselye Airport", "city_name":"Rybinsk"},
 {"airport_iata_code":"BZK", "airport_name":"Bryansk Airport", "city_name":"Bryansk"},
 {"airport_iata_code":"DME", "airport_name":"Domodedovo International Airport", "city_name":"Moscow"},
 {"airport_iata_code":"IAR", "airport_name":"Tunoshna Airport", "city_name":""},
 {"airport_iata_code":"SVO", "airport_name":"Sheremetyevo International Airport", "city_name":"Moscow"},
 {"airport_iata_code":"KLD", "airport_name":"Migalovo Air Base", "city_name":"Tver"},
 {"airport_iata_code":"CKL", "airport_name":"Chkalovskiy Airport", "city_name":"Moscow"},
 {"airport_iata_code":"EGO", "airport_name":"Belgorod International Airport", "city_name":"Belgorod"},
 {"airport_iata_code":"URS", "airport_name":"Kursk East Airport", "city_name":"Kursk"},
 {"airport_iata_code":"LPK", "airport_name":"Lipetsk Airport", "city_name":"Lipetsk"},
 {"airport_iata_code":"VOZ", "airport_name":"Voronezh International Airport", "city_name":"Voronezh"},
 {"airport_iata_code":"OEL", "airport_name":"Oryol Yuzhny Airport", "city_name":"Orel"},
 {"airport_iata_code":"TBW", "airport_name":"Donskoye Airport", "city_name":"Tambov"},
 {"airport_iata_code":"RZN", "airport_name":"Turlatovo Airport", "city_name":"Ryazan"},
 {"airport_iata_code":"TYA", "airport_name":"Klokovo Airfield", "city_name":"Tula"},
 {"airport_iata_code":"VKO", "airport_name":"Vnukovo International Airport", "city_name":"Moscow"},
 {"airport_iata_code":"UCT", "airport_name":"Ukhta Airport", "city_name":"Ukhta"},
 {"airport_iata_code":"INA", "airport_name":"Inta Airport", "city_name":"Inta"},
 {"airport_iata_code":"PEX", "airport_name":"Pechora Airport", "city_name":"Pechora"},
 {"airport_iata_code":"USK", "airport_name":"Usinsk Airport", "city_name":"Usinsk"},
 {"airport_iata_code":"VKT", "airport_name":"Vorkuta Airport", "city_name":"Vorkuta"},
 {"airport_iata_code":"UTS", "airport_name":"Ust-Tsylma Airport", "city_name":"Ust-Tsylma"},
 {"airport_iata_code":"SCW", "airport_name":"Syktyvkar Airport", "city_name":"Syktyvkar"},
 {"airport_iata_code":"GOJ", "airport_name":"Nizhny Novgorod International Airport", "city_name":"Nizhny Novgorod"},
 {"airport_iata_code":"UUA", "airport_name":"Bugulma Airport", "city_name":"Bugulma"},
 {"airport_iata_code":"KZN", "airport_name":"Kazan International Airport", "city_name":"Kazan"},
 {"airport_iata_code":"NBC", "airport_name":"Begishevo Airport", "city_name":"Nizhnekamsk"},
 {"airport_iata_code":"JOK", "airport_name":"Yoshkar-Ola Airport", "city_name":"Yoshkar-Ola"},
 {"airport_iata_code":"CSY", "airport_name":"Cheboksary Airport", "city_name":"Cheboksary"},
 {"airport_iata_code":"ULV", "airport_name":"Ulyanovsk Baratayevka Airport", "city_name":"Ulyanovsk"},
 {"airport_iata_code":"ULY", "airport_name":"Ulyanovsk East Airport", "city_name":"Ulyanovsk"},
 {"airport_iata_code":"REN", "airport_name":"Orenburg Central Airport", "city_name":"Orenburg"},
 {"airport_iata_code":"OSW", "airport_name":"Orsk Airport", "city_name":"Orsk"},
 {"airport_iata_code":"PEZ", "airport_name":"Penza Airport", "city_name":"Penza"},
 {"airport_iata_code":"SKX", "airport_name":"Saransk Airport", "city_name":"Saransk"},
 {"airport_iata_code":"BWO", "airport_name":"Balakovo Airport", "city_name":"Balakovo"},
 {"airport_iata_code":"RTW", "airport_name":"Saratov Central Airport", "city_name":"Saratov"},
 {"airport_iata_code":"BCX", "airport_name":"Beloretsk Airport", "city_name":"Beloretsk"},
 {"airport_iata_code":"NEF", "airport_name":"Neftekamsk Airport", "city_name":"Neftekamsk"},
 {"airport_iata_code":"OKT", "airport_name":"Oktyabrskiy Airport", "city_name":"Kzyl-Yar"},
 {"airport_iata_code":"UFA", "airport_name":"Ufa International Airport", "city_name":"Ufa"},
 {"airport_iata_code":"KUF", "airport_name":"Kurumoch International Airport", "city_name":"Samara"},
 {"airport_iata_code":"GYI", "airport_name":"Gisenyi Airport", "city_name":"Gisenyi"},
 {"airport_iata_code":"BTQ", "airport_name":"Butare Airport", "city_name":"Butare"},
 {"airport_iata_code":"KGL", "airport_name":"Kigali International Airport", "city_name":"Kigali"},
 {"airport_iata_code":"RHG", "airport_name":"Ruhengeri Airport", "city_name":"Ruhengeri"},
 {"airport_iata_code":"KME", "airport_name":"Kamembe Airport", "city_name":"Kamembe"},
 {"airport_iata_code":"ULH", "airport_name":"Majeed Bin Abdulaziz Airport", "city_name":"Al Ula"},
 {"airport_iata_code":"AHB", "airport_name":"Abha Regional Airport", "city_name":"Abha"},
 {"airport_iata_code":"HOF", "airport_name":"Al Ahsa Airport", "city_name":""},
 {"airport_iata_code":"ABT", "airport_name":"Al Baha Airport", "city_name":""},
 {"airport_iata_code":"BHH", "airport_name":"Bisha Airport", "city_name":""},
 {"airport_iata_code":"DMM", "airport_name":"King Fahd International Airport", "city_name":"Ad Dammam"},
 {"airport_iata_code":"DHA", "airport_name":"King Abdulaziz Air Base", "city_name":""},
 {"airport_iata_code":"DWD", "airport_name":"Dawadmi Domestic Airport", "city_name":"Dawadmi"},
 {"airport_iata_code":"GIZ", "airport_name":"Jizan Regional Airport", "city_name":"Jizan"},
 {"airport_iata_code":"ELQ", "airport_name":"Gassim Airport", "city_name":""},
 {"airport_iata_code":"URY", "airport_name":"Guriat Domestic Airport", "city_name":""},
 {"airport_iata_code":"HAS", "airport_name":"Hail Airport", "city_name":""},
 {"airport_iata_code":"QJB", "airport_name":"Jubail Airport", "city_name":"Jubail"},
 {"airport_iata_code":"JED", "airport_name":"King Abdulaziz International Airport", "city_name":"Jeddah"},
 {"airport_iata_code":"HBT", "airport_name":"King Khaled Military City Airport", "city_name":"King Khaled Military City"},
 {"airport_iata_code":"KMX", "airport_name":"King Khaled Air Base", "city_name":""},
 {"airport_iata_code":"MED", "airport_name":"Prince Mohammad Bin Abdulaziz Airport", "city_name":"Medina"},
 {"airport_iata_code":"EAM", "airport_name":"Nejran Airport", "city_name":""},
 {"airport_iata_code":"AQI", "airport_name":"Hafr Al Batin Airport", "city_name":"Qaisumah"},
 {"airport_iata_code":"AKH", "airport_name":"Prince Sultan Air Base", "city_name":""},
 {"airport_iata_code":"RAH", "airport_name":"Rafha Domestic Airport", "city_name":"Rafha"},
 {"airport_iata_code":"RUH", "airport_name":"King Khaled International Airport", "city_name":"Riyadh"},
 {"airport_iata_code":"RAE", "airport_name":"Arar Domestic Airport", "city_name":"Arar"},
 {"airport_iata_code":"XXN", "airport_name":"Riyadh Air Base", "city_name":"Riyadh"},
 {"airport_iata_code":"SHW", "airport_name":"Sharurah Airport", "city_name":""},
 {"airport_iata_code":"AJF", "airport_name":"Al-Jawf Domestic Airport", "city_name":"Al-Jawf"},
 {"airport_iata_code":"SLF", "airport_name":"Sulayel Airport", "city_name":""},
 {"airport_iata_code":"TUU", "airport_name":"Tabuk Airport", "city_name":""},
 {"airport_iata_code":"TIF", "airport_name":"Taif Airport", "city_name":""},
 {"airport_iata_code":"TUI", "airport_name":"Turaif Domestic Airport", "city_name":""},
 {"airport_iata_code":"WAE", "airport_name":"Wadi Al Dawasir Airport", "city_name":""},
 {"airport_iata_code":"EJH", "airport_name":"Al Wajh Domestic Airport", "city_name":"Al Wajh"},
 {"airport_iata_code":"YNB", "airport_name":"Yenbo Airport", "city_name":""},
 {"airport_iata_code":"ZUL", "airport_name":"Zilfi Airport", "city_name":"Zilfi"},
 {"airport_iata_code":"VIU", "airport_name":"Viru Harbour Airstrip", "city_name":"Viru"},
 {"airport_iata_code":"GTA", "airport_name":"Gatokae Airport", "city_name":"Gatokae"},
 {"airport_iata_code":"AFT", "airport_name":"Afutara Aerodrome", "city_name":"Bila"},
 {"airport_iata_code":"RNA", "airport_name":"Ulawa Airport", "city_name":"Arona"},
 {"airport_iata_code":"ATD", "airport_name":"Uru Harbour Airport", "city_name":"Atoifi"},
 {"airport_iata_code":"VEV", "airport_name":"Barakoma Airport", "city_name":"Barakoma"},
 {"airport_iata_code":"GEF", "airport_name":"Geva Airport", "city_name":"Liangia"},
 {"airport_iata_code":"AKS", "airport_name":"Auki Airport", "city_name":"Auki"},
 {"airport_iata_code":"BNY", "airport_name":"Bellona/Anua Airport", "city_name":"Anua"},
 {"airport_iata_code":"CHY", "airport_name":"Choiseul Bay Airport", "city_name":""},
 {"airport_iata_code":"BAS", "airport_name":"Ballalae Airport", "city_name":"Ballalae"},
 {"airport_iata_code":"FRE", "airport_name":"Fera/Maringe Airport", "city_name":"Fera Island"},
 {"airport_iata_code":"HIR", "airport_name":"Honiara International Airport", "city_name":"Honiara"},
 {"airport_iata_code":"MBU", "airport_name":"Babanakira Airport", "city_name":"Mbambanakira"},
 {"airport_iata_code":"AVU", "airport_name":"Avu Avu Airport", "city_name":""},
 {"airport_iata_code":"IRA", "airport_name":"Ngorangora Airport", "city_name":"Kirakira"},
 {"airport_iata_code":"SCZ", "airport_name":"Santa Cruz/Graciosa Bay/Luova Airport", "city_name":"Santa Cruz/Graciosa Bay/Luova"},
 {"airport_iata_code":"MUA", "airport_name":"Munda Airport", "city_name":""},
 {"airport_iata_code":"GZO", "airport_name":"Nusatupe Airport", "city_name":"Gizo"},
 {"airport_iata_code":"MNY", "airport_name":"Mono Airport", "city_name":"Stirling Island"},
 {"airport_iata_code":"PRS", "airport_name":"Parasi Airport", "city_name":"Parasi"},
 {"airport_iata_code":"RNL", "airport_name":"Rennell/Tingoa Airport", "city_name":"Rennell Island"},
 {"airport_iata_code":"EGM", "airport_name":"Sege Airport", "city_name":"Sege"},
 {"airport_iata_code":"NNB", "airport_name":"Santa Ana Airport", "city_name":"Santa Ana Island"},
 {"airport_iata_code":"RUS", "airport_name":"Marau Airport", "city_name":"Marau"},
 {"airport_iata_code":"VAO", "airport_name":"Suavanao Airport", "city_name":"Suavanao"},
 {"airport_iata_code":"XYA", "airport_name":"Yandina Airport", "city_name":"Yandina"},
 {"airport_iata_code":"KGE", "airport_name":"Kagau Island Airport", "city_name":"Kagau Island"},
 {"airport_iata_code":"KWS", "airport_name":"Kwailabesi Airport", "city_name":"Kwailabesi"},
 {"airport_iata_code":"RIN", "airport_name":"Ringi Cove Airport", "city_name":"Ringi Cove"},
 {"airport_iata_code":"RBV", "airport_name":"Ramata Airport", "city_name":"Ramata"},
 {"airport_iata_code":"BPF", "airport_name":"Batuna Aerodrome", "city_name":"Batuna Mission Station"},
 {"airport_iata_code":"DES", "airport_name":"Desroches Airport", "city_name":"Desroches Island"},
 {"airport_iata_code":"SEZ", "airport_name":"Seychelles International Airport", "city_name":"Mahe Island"},
 {"airport_iata_code":"BDI", "airport_name":"Bird Island Airport", "city_name":"Bird Island"},
 {"airport_iata_code":"DEI", "airport_name":"Denis Island Airport", "city_name":"Denis Island"},
 {"airport_iata_code":"FRK", "airport_name":"Fr\u00e9gate Island Airport", "city_name":"Fr\u00e9gate Island"},
 {"airport_iata_code":"PRI", "airport_name":"Praslin Island Airport", "city_name":"Praslin Island"},
 {"airport_iata_code":"ATB", "airport_name":"Atbara Airport", "city_name":"Atbara"},
 {"airport_iata_code":"EDB", "airport_name":"El Debba Airport", "city_name":"El Debba"},
 {"airport_iata_code":"DOG", "airport_name":"Dongola Airport", "city_name":"Dongola"},
 {"airport_iata_code":"RSS", "airport_name":"Damazin Airport", "city_name":"Ad Damazin"},
 {"airport_iata_code":"ELF", "airport_name":"El Fasher Airport", "city_name":"El Fasher"},
 {"airport_iata_code":"GSU", "airport_name":"Azaza Airport", "city_name":"Gedaref"},
 {"airport_iata_code":"DNX", "airport_name":"Galegu Airport", "city_name":"Dinder"},
 {"airport_iata_code":"EGN", "airport_name":"Geneina Airport", "city_name":"Geneina"},
 {"airport_iata_code":"HEG", "airport_name":"Heglig Airport", "city_name":"Heglig Oilfield"},
 {"airport_iata_code":"KSL", "airport_name":"Kassala Airport", "city_name":"Kassala"},
 {"airport_iata_code":"GBU", "airport_name":"Khashm El Girba Airport", "city_name":"Khashm El Girba"},
 {"airport_iata_code":"KST", "airport_name":"Kosti Airport", "city_name":"Kosti"},
 {"airport_iata_code":"KDX", "airport_name":"Kadugli Airport", "city_name":"Kadugli"},
 {"airport_iata_code":"MWE", "airport_name":"Merowe Airport", "city_name":"Merowe"},
 {"airport_iata_code":"NUD", "airport_name":"En Nahud Airport", "city_name":"En Nahud"},
 {"airport_iata_code":"UYL", "airport_name":"Nyala Airport", "city_name":"Nyala"},
 {"airport_iata_code":"NHF", "airport_name":"New Halfa Airport", "city_name":"New Halfa"},
 {"airport_iata_code":"EBD", "airport_name":"El Obeid Airport", "city_name":"Al-Ubayyid"},
 {"airport_iata_code":"PZU", "airport_name":"Port Sudan New International Airport", "city_name":"Port Sudan"},
 {"airport_iata_code":"KRT", "airport_name":"Khartoum International Airport", "city_name":"Khartoum"},
 {"airport_iata_code":"WHF", "airport_name":"Wadi Halfa Airport", "city_name":"Wadi Halfa"},
 {"airport_iata_code":"BJE", "airport_name":"Baleela Airport", "city_name":"Baleela Base Camp"},
 {"airport_iata_code":"RNB", "airport_name":"Ronneby Airport", "city_name":""},
 {"airport_iata_code":"XWP", "airport_name":"H\u00e4ssleholm Bokeberg Airport", "city_name":"H\u00e4ssleholm"},
 {"airport_iata_code":"GOT", "airport_name":"Gothenburg-Landvetter Airport", "city_name":"Gothenburg"},
 {"airport_iata_code":"JKG", "airport_name":"J\u00f6nk\u00f6ping Airport", "city_name":"J\u00f6nk\u00f6ping"},
 {"airport_iata_code":"LDK", "airport_name":"Lidk\u00f6ping-Hovby Airport", "city_name":"Lidk\u00f6ping"},
 {"airport_iata_code":"GSE", "airport_name":"Gothenburg City Airport", "city_name":"Gothenburg"},
 {"airport_iata_code":"KVB", "airport_name":"Sk\u00f6vde Airport", "city_name":"Sk\u00f6vde"},
 {"airport_iata_code":"THN", "airport_name":"Trollh\u00e4ttan-V\u00e4nersborg Airport", "city_name":"Trollh\u00e4ttan"},
 {"airport_iata_code":"KSK", "airport_name":"Karlskoga Airport", "city_name":""},
 {"airport_iata_code":"MXX", "airport_name":"Mora Airport", "city_name":""},
 {"airport_iata_code":"NYO", "airport_name":"Stockholm Skavsta Airport", "city_name":"Stockholm / Nyk\u00f6ping"},
 {"airport_iata_code":"KID", "airport_name":"Kristianstad Airport", "city_name":"Kristianstad"},
 {"airport_iata_code":"OSK", "airport_name":"Oskarshamn Airport", "city_name":""},
 {"airport_iata_code":"KLR", "airport_name":"Kalmar Airport", "city_name":""},
 {"airport_iata_code":"MMX", "airport_name":"Malm\u00f6 Sturup Airport", "city_name":"Malm\u00f6"},
 {"airport_iata_code":"HAD", "airport_name":"Halmstad Airport", "city_name":"Halmstad"},
 {"airport_iata_code":"VXO", "airport_name":"V\u00e4xj\u00f6 Kronoberg Airport", "city_name":"V\u00e4xj\u00f6"},
 {"airport_iata_code":"EVG", "airport_name":"Sveg Airport", "city_name":""},
 {"airport_iata_code":"GEV", "airport_name":"G\u00e4llivare Airport", "city_name":"G\u00e4llivare"},
 {"airport_iata_code":"KRF", "airport_name":"Kramfors Solleftea Airport", "city_name":"Kramfors / Solleftea"},
 {"airport_iata_code":"LYC", "airport_name":"Lycksele Airport", "city_name":""},
 {"airport_iata_code":"SDL", "airport_name":"Sundsvall-H\u00e4rn\u00f6sand Airport", "city_name":"Sundsvall/ H\u00e4rn\u00f6sand"},
 {"airport_iata_code":"OER", "airport_name":"\u00d6rnsk\u00f6ldsvik Airport", "city_name":"\u00d6rnsk\u00f6ldsvik"},
 {"airport_iata_code":"KRN", "airport_name":"Kiruna Airport", "city_name":""},
 {"airport_iata_code":"SFT", "airport_name":"Skellefte\u00e5 Airport", "city_name":"Skellefte\u00e5"},
 {"airport_iata_code":"UME", "airport_name":"Ume\u00e5 Airport", "city_name":"Ume\u00e5"},
 {"airport_iata_code":"VHM", "airport_name":"Vilhelmina Airport", "city_name":""},
 {"airport_iata_code":"AJR", "airport_name":"Arvidsjaur Airport", "city_name":"Arvidsjaur"},
 {"airport_iata_code":"SOO", "airport_name":"S\u00f6derhamn Airport", "city_name":"S\u00f6derhamn"},
 {"airport_iata_code":"OSD", "airport_name":"\u00d6stersund Airport", "city_name":"\u00d6stersund"},
 {"airport_iata_code":"ORB", "airport_name":"\u00d6rebro Airport", "city_name":"\u00d6rebro"},
 {"airport_iata_code":"HFS", "airport_name":"Hagfors Airport", "city_name":""},
 {"airport_iata_code":"KSD", "airport_name":"Karlstad Airport", "city_name":"Karlstad"},
 {"airport_iata_code":"VST", "airport_name":"Stockholm Vasteras Airport", "city_name":"Stockholm / Vasteras"},
 {"airport_iata_code":"LLA", "airport_name":"Lule\u00e5 Airport", "city_name":"Lule\u00e5"},
 {"airport_iata_code":"ARN", "airport_name":"Stockholm-Arlanda Airport", "city_name":"Stockholm"},
 {"airport_iata_code":"BMA", "airport_name":"Stockholm-Bromma Airport", "city_name":"Stockholm"},
 {"airport_iata_code":"BLE", "airport_name":"Borlange Airport", "city_name":""},
 {"airport_iata_code":"HLF", "airport_name":"Hultsfred Airport", "city_name":""},
 {"airport_iata_code":"GVX", "airport_name":"G\u00e4vle Sandviken Airport", "city_name":"G\u00e4vle / Sandviken"},
 {"airport_iata_code":"LPI", "airport_name":"Link\u00f6ping SAAB Airport", "city_name":"Link\u00f6ping"},
 {"airport_iata_code":"NRK", "airport_name":"Norrk\u00f6ping Airport", "city_name":"Norrk\u00f6ping"},
 {"airport_iata_code":"TYF", "airport_name":"Torsby Airport", "city_name":""},
 {"airport_iata_code":"EKT", "airport_name":"Eskilstuna Airport", "city_name":"Eskilstuna"},
 {"airport_iata_code":"VBY", "airport_name":"Visby Airport", "city_name":"Visby"},
 {"airport_iata_code":"VVK", "airport_name":"V\u00e4stervik Airport", "city_name":"V\u00e4stervik"},
 {"airport_iata_code":"AGH", "airport_name":"\u00c4ngelholm-Helsingborg Airport", "city_name":"\u00c4ngelholm"},
 {"airport_iata_code":"SQO", "airport_name":"Storuman Airport", "city_name":""},
 {"airport_iata_code":"IDB", "airport_name":"Idre Airport", "city_name":"Idre"},
 {"airport_iata_code":"PJA", "airport_name":"Pajala Airport", "city_name":""},
 {"airport_iata_code":"HMV", "airport_name":"Hemavan Airport", "city_name":""},
 {"airport_iata_code":"QPG", "airport_name":"Paya Lebar Air Base", "city_name":""},
 {"airport_iata_code":"TGA", "airport_name":"Tengah Air Base", "city_name":""},
 {"airport_iata_code":"XSP", "airport_name":"Seletar Airport", "city_name":"Seletar"},
 {"airport_iata_code":"SIN", "airport_name":"Singapore Changi International Airport", "city_name":"Singapore"},
 {"airport_iata_code":"ASI", "airport_name":"RAF Ascension Island", "city_name":"Ascension Island"},
 {"airport_iata_code":"LJU", "airport_name":"Ljubljana Jo\u017ee Pu?nik Airport", "city_name":"Ljubljana"},
 {"airport_iata_code":"MBX", "airport_name":"Maribor Airport", "city_name":""},
 {"airport_iata_code":"POW", "airport_name":"Portoroz Airport", "city_name":"Portoroz"},
 {"airport_iata_code":"BTS", "airport_name":"M. R. \u0160tef\u00e1nik Airport", "city_name":"Bratislava"},
 {"airport_iata_code":"KSC", "airport_name":"Ko\u0161ice Airport", "city_name":"Ko\u0161ice"},
 {"airport_iata_code":"LUE", "airport_name":"Lu?enec Airport", "city_name":"Lu?enec"},
 {"airport_iata_code":"PZY", "airport_name":"Pie\u0161?any Airport", "city_name":"Pie\u0161?any"},
 {"airport_iata_code":"POV", "airport_name":"Pre\u0161ov Air Base", "city_name":"Pre\u0161ov"},
 {"airport_iata_code":"SLD", "airport_name":"Slia? Airport", "city_name":"Slia?"},
 {"airport_iata_code":"TAT", "airport_name":"Poprad-Tatry Airport", "city_name":"Poprad"},
 {"airport_iata_code":"ILZ", "airport_name":"\u017dilina Airport", "city_name":"\u017dilina"},
 {"airport_iata_code":"BTE", "airport_name":"Sherbro International Airport", "city_name":"Bonthe"},
 {"airport_iata_code":"KBS", "airport_name":"Bo Airport", "city_name":"Bo"},
 {"airport_iata_code":"GBK", "airport_name":"Gbangbatok Airport", "city_name":"Gbangbatok"},
 {"airport_iata_code":"HGS", "airport_name":"Hastings Airport", "city_name":"Freetown"},
 {"airport_iata_code":"KBA", "airport_name":"Kabala Airport", "city_name":"Kabala"},
 {"airport_iata_code":"KEN", "airport_name":"Kenema Airport", "city_name":"Kenema"},
 {"airport_iata_code":"FNA", "airport_name":"Lungi International Airport", "city_name":"Freetown"},
 {"airport_iata_code":"WYE", "airport_name":"Yengema Airport", "city_name":"Yengema"},
 {"airport_iata_code":"KDA", "airport_name":"Kolda North Airport", "city_name":"Kolda"},
 {"airport_iata_code":"ZIG", "airport_name":"Ziguinchor Airport", "city_name":"Ziguinchor"},
 {"airport_iata_code":"CSK", "airport_name":"Cap Skirring Airport", "city_name":"Cap Skirring"},
 {"airport_iata_code":"KLC", "airport_name":"Kaolack Airport", "city_name":"Kaolack"},
 {"airport_iata_code":"DKR", "airport_name":"L\u00e9opold S\u00e9dar Senghor International Airport", "city_name":"Dakar"},
 {"airport_iata_code":"MAX", "airport_name":"Ouro Sogui Airport", "city_name":"Matam"},
 {"airport_iata_code":"POD", "airport_name":"Podor Airport", "city_name":"Podor"},
 {"airport_iata_code":"RDT", "airport_name":"Richard Toll Airport", "city_name":"Richard Toll"},
 {"airport_iata_code":"XLS", "airport_name":"Saint Louis Airport", "city_name":"Saint Louis"},
 {"airport_iata_code":"BXE", "airport_name":"Bakel Airport", "city_name":"Bakel"},
 {"airport_iata_code":"KGG", "airport_name":"K\u00e9dougou Airport", "city_name":"K\u00e9dougou"},
 {"airport_iata_code":"SMY", "airport_name":"Simenti Airport", "city_name":"Simenti"},
 {"airport_iata_code":"TUD", "airport_name":"Tambacounda Airport", "city_name":"Tambacounda"},
 {"airport_iata_code":"ALU", "airport_name":"Alula Airport", "city_name":"Alula"},
 {"airport_iata_code":"BIB", "airport_name":"Baidoa Airport", "city_name":"Baidoa"},
 {"airport_iata_code":"CXN", "airport_name":"Candala Airport", "city_name":"Candala"},
 {"airport_iata_code":"BSY", "airport_name":"Bardera Airport", "city_name":""},
 {"airport_iata_code":"HCM", "airport_name":"Eil Airport", "city_name":"Eil"},
 {"airport_iata_code":"BSA", "airport_name":"Bosaso Airport", "city_name":"Bosaso"},
 {"airport_iata_code":"GSR", "airport_name":"Gardo Airport", "city_name":"Gardo"},
 {"airport_iata_code":"HGA", "airport_name":"Egal International Airport", "city_name":"Hargeisa"},
 {"airport_iata_code":"BBO", "airport_name":"Berbera Airport", "city_name":"Berbera"},
 {"airport_iata_code":"KMU", "airport_name":"Kisimayu Airport", "city_name":""},
 {"airport_iata_code":"MGQ", "airport_name":"Aden Adde International Airport", "city_name":"Mogadishu"},
 {"airport_iata_code":"CMO", "airport_name":"Obbia Airport", "city_name":"Obbia"},
 {"airport_iata_code":"GLK", "airport_name":"Galcaio Airport", "city_name":"Galcaio"},
 {"airport_iata_code":"CMS", "airport_name":"Scusciuban Airport", "city_name":"Scusciuban"},
 {"airport_iata_code":"ERA", "airport_name":"Erigavo Airport", "city_name":"Erigavo"},
 {"airport_iata_code":"BUO", "airport_name":"Burao Airport", "city_name":"Burao"},
 {"airport_iata_code":"BXX", "airport_name":"Boorama Airport", "city_name":"Boorama"},
 {"airport_iata_code":"ABN", "airport_name":"Albina Airport", "city_name":"Albina"},
 {"airport_iata_code":"TOT", "airport_name":"Totness Airport", "city_name":"Totness"},
 {"airport_iata_code":"DRJ", "airport_name":"Drietabbetje Airport", "city_name":"Drietabbetje"},
 {"airport_iata_code":"PBM", "airport_name":"Johan Adolf Pengel International Airport", "city_name":"Zandery"},
 {"airport_iata_code":"MOJ", "airport_name":"Moengo Airstrip", "city_name":"Moengo"},
 {"airport_iata_code":"ICK", "airport_name":"Nieuw Nickerie Airport", "city_name":"Nieuw Nickerie"},
 {"airport_iata_code":"OEM", "airport_name":"Vincent Fayks Airport", "city_name":"Paloemeu"},
 {"airport_iata_code":"SMZ", "airport_name":"Stoelmanseiland Airport", "city_name":"Stoelmanseiland"},
 {"airport_iata_code":"AGI", "airport_name":"Wageningen Airport Airport", "city_name":"Wageningen Airport"},
 {"airport_iata_code":"ORG", "airport_name":"Zorg en Hoop Airport", "city_name":"Paramaribo"},
 {"airport_iata_code":"AAJ", "airport_name":"Cayana Airstrip", "city_name":"Awaradam"},
 {"airport_iata_code":"KCB", "airport_name":"Tepoe Airstrip", "city_name":"Kasikasima"},
 {"airport_iata_code":"BTO", "airport_name":"Botopasi Airport", "city_name":"Botopasi"},
 {"airport_iata_code":"DOE", "airport_name":"Djumu-Djomoe Airport", "city_name":"Djumu-Djomoe"},
 {"airport_iata_code":"LDO", "airport_name":"Ladouanie Airport", "city_name":"Aurora"},
 {"airport_iata_code":"WSO", "airport_name":"Washabo Airport", "city_name":"Washabo"},
 {"airport_iata_code":"HGI", "airport_name":"Palogue Airport", "city_name":"Higleig"},
 {"airport_iata_code":"RBX", "airport_name":"Rumbek Airport", "city_name":"Rumbek"},
 {"airport_iata_code":"JUB", "airport_name":"Juba Airport", "city_name":"Juba"},
 {"airport_iata_code":"MAK", "airport_name":"Malakal Airport", "city_name":"Malakal"},
 {"airport_iata_code":"WUU", "airport_name":"Wau Airport", "city_name":"Wau"},
 {"airport_iata_code":"AEE", "airport_name":"Adareil Airport", "city_name":""},
 {"airport_iata_code":"PCP", "airport_name":"Principe Airport", "city_name":""},
 {"airport_iata_code":"TMS", "airport_name":"S\u00e3o Tom\u00e9 International Airport", "city_name":"S\u00e3o Tom\u00e9"},
 {"airport_iata_code":"SAL", "airport_name":"El Salvador International Airport", "city_name":"Santa Clara"},
 {"airport_iata_code":"SXM", "airport_name":"Princess Juliana International Airport", "city_name":"Saint Martin"},
 {"airport_iata_code":"ALP", "airport_name":"Aleppo International Airport", "city_name":"Aleppo"},
 {"airport_iata_code":"DAM", "airport_name":"Damascus International Airport", "city_name":"Damascus"},
 {"airport_iata_code":"DEZ", "airport_name":"Deir ez-Zor Airport", "city_name":"Deir ez-Zor"},
 {"airport_iata_code":"KAC", "airport_name":"Kamishly Airport", "city_name":"Kamishly"},
 {"airport_iata_code":"LTK", "airport_name":"Bassel Al-Assad International Airport", "city_name":"Latakia"},
 {"airport_iata_code":"PMS", "airport_name":"Palmyra Airport", "city_name":""},
 {"airport_iata_code":"MTS", "airport_name":"Matsapha Airport", "city_name":"Manzini"},
 {"airport_iata_code":"GDT", "airport_name":"JAGS McCartney International Airport", "city_name":"Cockburn Town"},
 {"airport_iata_code":"MDS", "airport_name":"Middle Caicos Airport", "city_name":"Middle Caicos"},
 {"airport_iata_code":"NCA", "airport_name":"North Caicos Airport", "city_name":""},
 {"airport_iata_code":"PIC", "airport_name":"Pine Cay Airport", "city_name":"Pine Cay"},
 {"airport_iata_code":"PLS", "airport_name":"Providenciales Airport", "city_name":"Providenciales Island"},
 {"airport_iata_code":"XSC", "airport_name":"South Caicos Airport", "city_name":""},
 {"airport_iata_code":"SLX", "airport_name":"Salt Cay Airport", "city_name":"Salt Cay"},
 {"airport_iata_code":"AKM", "airport_name":"Zakuoma Airport", "city_name":"ZaKouma"},
 {"airport_iata_code":"SRH", "airport_name":"Sarh Airport", "city_name":"Sarh"},
 {"airport_iata_code":"OGR", "airport_name":"Bongor Airport", "city_name":"Bongor"},
 {"airport_iata_code":"AEH", "airport_name":"Abeche Airport", "city_name":""},
 {"airport_iata_code":"MQQ", "airport_name":"Moundou Airport", "city_name":""},
 {"airport_iata_code":"LTC", "airport_name":"Lai Airport", "city_name":"Lai"},
 {"airport_iata_code":"ATV", "airport_name":"Ati Airport", "city_name":"Ati"},
 {"airport_iata_code":"NDJ", "airport_name":"N'Djamena International Airport", "city_name":"N'Djamena"},
 {"airport_iata_code":"BKR", "airport_name":"Bokoro Airport", "city_name":"Bokoro"},
 {"airport_iata_code":"OTC", "airport_name":"Bol Airport", "city_name":"Bol"},
 {"airport_iata_code":"MVO", "airport_name":"Mongo Airport", "city_name":"Mongo"},
 {"airport_iata_code":"AMC", "airport_name":"Am Timan Airport", "city_name":"Am Timan"},
 {"airport_iata_code":"PLF", "airport_name":"Pala Airport", "city_name":"Pala"},
 {"airport_iata_code":"OUT", "airport_name":"Bousso Airport", "city_name":"Bousso"},
 {"airport_iata_code":"AMO", "airport_name":"Mao Airport", "city_name":"Mao"},
 {"airport_iata_code":"FYT", "airport_name":"Faya Largeau Airport", "city_name":""},
 {"airport_iata_code":"LRL", "airport_name":"Niamtougou International Airport", "city_name":"Niamtougou"},
 {"airport_iata_code":"LFW", "airport_name":"Lom\u00e9-Tokoin Airport", "city_name":"Lom\u00e9"},
 {"airport_iata_code":"DMK", "airport_name":"Don Mueang International Airport", "city_name":"Bangkok"},
 {"airport_iata_code":"KKM", "airport_name":"Sa Pran Nak Airport", "city_name":""},
 {"airport_iata_code":"KDT", "airport_name":"Kamphaeng Saen Airport", "city_name":"Nakhon Pathom"},
 {"airport_iata_code":"TDX", "airport_name":"Trat Airport", "city_name":""},
 {"airport_iata_code":"BKK", "airport_name":"Suvarnabhumi Airport", "city_name":"Bangkok"},
 {"airport_iata_code":"UTP", "airport_name":"U-Tapao International Airport", "city_name":"Rayong"},
 {"airport_iata_code":"CNX", "airport_name":"Chiang Mai International Airport", "city_name":"Chiang Mai"},
 {"airport_iata_code":"HGN", "airport_name":"Mae Hong Son Airport", "city_name":""},
 {"airport_iata_code":"PYY", "airport_name":"Mae Hong Son Airport", "city_name":"Mae Hong Son"},
 {"airport_iata_code":"LPT", "airport_name":"Lampang Airport", "city_name":""},
 {"airport_iata_code":"NNT", "airport_name":"Nan Airport", "city_name":""},
 {"airport_iata_code":"PRH", "airport_name":"Phrae Airport", "city_name":""},
 {"airport_iata_code":"CEI", "airport_name":"Chiang Rai International Airport", "city_name":"Chiang Rai"},
 {"airport_iata_code":"BAO", "airport_name":"Udorn Air Base", "city_name":"Ban Mak Khaen"},
 {"airport_iata_code":"PHY", "airport_name":"Phetchabun Airport", "city_name":""},
 {"airport_iata_code":"HHQ", "airport_name":"Hua Hin Airport", "city_name":"Hua Hin"},
 {"airport_iata_code":"TKH", "airport_name":"Takhli Airport", "city_name":""},
 {"airport_iata_code":"MAQ", "airport_name":"Mae Sot Airport", "city_name":""},
 {"airport_iata_code":"THS", "airport_name":"Sukhothai Airport", "city_name":""},
 {"airport_iata_code":"PHS", "airport_name":"Phitsanulok Airport", "city_name":""},
 {"airport_iata_code":"TKT", "airport_name":"Tak Airport", "city_name":""},
 {"airport_iata_code":"UTR", "airport_name":"Uttaradit Airport", "city_name":"Uttaradit"},
 {"airport_iata_code":"URT", "airport_name":"Surat Thani Airport", "city_name":"Surat Thani"},
 {"airport_iata_code":"NAW", "airport_name":"Narathiwat Airport", "city_name":""},
 {"airport_iata_code":"CJM", "airport_name":"Chumphon Airport", "city_name":""},
 {"airport_iata_code":"NST", "airport_name":"Nakhon Si Thammarat Airport", "city_name":"Nakhon Si Thammarat"},
 {"airport_iata_code":"KBV", "airport_name":"Krabi Airport", "city_name":"Krabi"},
 {"airport_iata_code":"SGZ", "airport_name":"Songkhla Airport", "city_name":""},
 {"airport_iata_code":"PAN", "airport_name":"Pattani Airport", "city_name":""},
 {"airport_iata_code":"USM", "airport_name":"Samui Airport", "city_name":"Na Thon (Ko Samui Island)"},
 {"airport_iata_code":"HKT", "airport_name":"Phuket International Airport", "city_name":"Phuket"},
 {"airport_iata_code":"UNN", "airport_name":"Ranong Airport", "city_name":""},
 {"airport_iata_code":"HDY", "airport_name":"Hat Yai International Airport", "city_name":"Hat Yai"},
 {"airport_iata_code":"TST", "airport_name":"Trang Airport", "city_name":""},
 {"airport_iata_code":"UTH", "airport_name":"Udon Thani Airport", "city_name":"Udon Thani"},
 {"airport_iata_code":"SNO", "airport_name":"Sakon Nakhon Airport", "city_name":""},
 {"airport_iata_code":"KKC", "airport_name":"Khon Kaen Airport", "city_name":"Khon Kaen"},
 {"airport_iata_code":"LOE", "airport_name":"Loei Airport", "city_name":""},
 {"airport_iata_code":"BFV", "airport_name":"Buri Ram Airport", "city_name":""},
 {"airport_iata_code":"NAK", "airport_name":"Nakhon Ratchasima Airport", "city_name":""},
 {"airport_iata_code":"UBP", "airport_name":"Ubon Ratchathani Airport", "city_name":"Ubon Ratchathani"},
 {"airport_iata_code":"ROI", "airport_name":"Roi Et Airport", "city_name":""},
 {"airport_iata_code":"KOP", "airport_name":"Nakhon Phanom Airport", "city_name":""},
 {"airport_iata_code":"DYU", "airport_name":"Dushanbe Airport", "city_name":"Dushanbe"},
 {"airport_iata_code":"TJU", "airport_name":"Kulob Airport", "city_name":"Kulyab"},
 {"airport_iata_code":"LBD", "airport_name":"Khudzhand Airport", "city_name":"Khudzhand"},
 {"airport_iata_code":"KQT", "airport_name":"Qurghonteppa International Airport", "city_name":"Kurgan-Tyube"},
 {"airport_iata_code":"AUT", "airport_name":"Atauro Airport", "city_name":"Atauro"},
 {"airport_iata_code":"UAI", "airport_name":"Suai Airport", "city_name":"Suai"},
 {"airport_iata_code":"DIL", "airport_name":"Presidente Nicolau Lobato International Airport", "city_name":"Dili"},
 {"airport_iata_code":"BCH", "airport_name":"Cakung Airport", "city_name":"Baucau"},
 {"airport_iata_code":"OEC", "airport_name":"Oecussi Airport", "city_name":"Oecussi-Ambeno"},
 {"airport_iata_code":"VIQ", "airport_name":"Viqueque Airport", "city_name":"Viqueque"},
 {"airport_iata_code":"KCI", "airport_name":"Kon Airport", "city_name":"Kon"},
 {"airport_iata_code":"ASB", "airport_name":"Ashgabat Airport", "city_name":"Ashgabat"},
 {"airport_iata_code":"KRW", "airport_name":"Turkmenbashi Airport", "city_name":"Krasnovodsk"},
 {"airport_iata_code":"MYP", "airport_name":"Mary Airport", "city_name":"Mary"},
 {"airport_iata_code":"TAZ", "airport_name":"Da?oguz Airport", "city_name":"Da?oguz"},
 {"airport_iata_code":"CRZ", "airport_name":"Turkmenabat Airport", "city_name":"T\u00fcrkmenabat"},
 {"airport_iata_code":"TBJ", "airport_name":"Tabarka 7 Novembre Airport", "city_name":"Tabarka"},
 {"airport_iata_code":"MIR", "airport_name":"Monastir Habib Bourguiba International Airport", "city_name":"Monastir"},
 {"airport_iata_code":"TUN", "airport_name":"Tunis Carthage International Airport", "city_name":"Tunis"},
 {"airport_iata_code":"QIZ", "airport_name":"Sidi Ahmed Air Base", "city_name":"Sidi Ahmed"},
 {"airport_iata_code":"GAF", "airport_name":"Gafsa Ksar International Airport", "city_name":"Gafsa"},
 {"airport_iata_code":"GAE", "airport_name":"Gab\u00e8s Matmata International Airport", "city_name":"Gab\u00e8s"},
 {"airport_iata_code":"DJE", "airport_name":"Djerba Zarzis International Airport", "city_name":"Djerba"},
 {"airport_iata_code":"EBM", "airport_name":"El Borma Airport", "city_name":"El Borma"},
 {"airport_iata_code":"SFA", "airport_name":"Sfax Thyna International Airport", "city_name":"Sfax"},
 {"airport_iata_code":"TOE", "airport_name":"Tozeur Nefta International Airport", "city_name":"Tozeur"},
 {"airport_iata_code":"NBE", "airport_name":"Enfidha - Hammamet International Airport", "city_name":"Enfidha"},
 {"airport_iata_code":"EUA", "airport_name":"Kaufana Airport", "city_name":"Eua Island"},
 {"airport_iata_code":"TBU", "airport_name":"Fua'amotu International Airport", "city_name":"Nuku'alofa"},
 {"airport_iata_code":"HPA", "airport_name":"Lifuka Island Airport", "city_name":"Lifuka"},
 {"airport_iata_code":"NFO", "airport_name":"Mata'aho Airport", "city_name":"Angaha, Niuafo'ou Island"},
 {"airport_iata_code":"NTT", "airport_name":"Kuini Lavenia Airport", "city_name":"Niuatoputapu"},
 {"airport_iata_code":"VAV", "airport_name":"Vava'u International Airport", "city_name":"Vava'u Island"},
 {"airport_iata_code":"IGD", "airport_name":"I?d?r Airport", "city_name":"I?d?r"},
 {"airport_iata_code":"GKD", "airport_name":"Imroz Airport", "city_name":"G\u00f6k\u00e7eada"},
 {"airport_iata_code":"ESB", "airport_name":"Esenbo?a International Airport", "city_name":"Ankara"},
 {"airport_iata_code":"ANK", "airport_name":"Etimesgut Air Base", "city_name":"Ankara"},
 {"airport_iata_code":"ADA", "airport_name":"Adana Airport", "city_name":"Adana"},
 {"airport_iata_code":"UAB", "airport_name":"?ncirlik Air Base", "city_name":"Adana"},
 {"airport_iata_code":"AFY", "airport_name":"Afyon Airport", "city_name":"Afyonkarahisar"},
 {"airport_iata_code":"AYT", "airport_name":"Antalya International Airport", "city_name":"Antalya"},
 {"airport_iata_code":"GZT", "airport_name":"Gaziantep International Airport", "city_name":"Gaziantep"},
 {"airport_iata_code":"KFS", "airport_name":"Kastamonu Airport", "city_name":"Kastamonu"},
 {"airport_iata_code":"KYA", "airport_name":"Konya Airport", "city_name":"Konya"},
 {"airport_iata_code":"MZH", "airport_name":"Amasya Merzifon Airport", "city_name":"Amasya"},
 {"airport_iata_code":"SSX", "airport_name":"Samsun Samair Airport", "city_name":"Samsun"},
 {"airport_iata_code":"VAS", "airport_name":"Sivas Airport", "city_name":"Sivas"},
 {"airport_iata_code":"ONQ", "airport_name":"Zonguldak Airport", "city_name":"Zonguldak"},
 {"airport_iata_code":"MLX", "airport_name":"Malatya Erha\u00e7 Airport", "city_name":"Malatya"},
 {"airport_iata_code":"ASR", "airport_name":"Kayseri Erkilet Airport", "city_name":"Kayseri"},
 {"airport_iata_code":"TJK", "airport_name":"Tokat Airport", "city_name":"Tokat"},
 {"airport_iata_code":"DNZ", "airport_name":"\u00c7ardak Airport", "city_name":"Denizli"},
 {"airport_iata_code":"NAV", "airport_name":"Nev?ehir Kapadokya International Airport", "city_name":"Nev?ehir"},
 {"airport_iata_code":"IST", "airport_name":"Atat\u00fcrk International Airport", "city_name":"Istanbul"},
 {"airport_iata_code":"CII", "airport_name":"Cildir Airport", "city_name":"Aydin"},
 {"airport_iata_code":"BTZ", "airport_name":"Bursa Airport", "city_name":"Bursa"},
 {"airport_iata_code":"BZI", "airport_name":"Bal?kesir Merkez Airport", "city_name":""},
 {"airport_iata_code":"BDM", "airport_name":"Band?rma Airport", "city_name":""},
 {"airport_iata_code":"CKZ", "airport_name":"\u00c7anakkale Airport", "city_name":"\u00c7anakkale"},
 {"airport_iata_code":"ESK", "airport_name":"Eski?ehir Air Base", "city_name":""},
 {"airport_iata_code":"ADB", "airport_name":"Adnan Menderes International Airport", "city_name":"?zmir"},
 {"airport_iata_code":"IGL", "airport_name":"\u00c7i?li Airport", "city_name":""},
 {"airport_iata_code":"USQ", "airport_name":"U?ak Airport", "city_name":"U?ak"},
 {"airport_iata_code":"KCO", "airport_name":"Cengiz Topel Airport", "city_name":""},
 {"airport_iata_code":"YEI", "airport_name":"Bursa Yeni?ehir Airport", "city_name":"Bursa"},
 {"airport_iata_code":"DLM", "airport_name":"Dalaman International Airport", "city_name":"Dalaman"},
 {"airport_iata_code":"TEQ", "airport_name":"Tekirda? \u00c7orlu Airport", "city_name":"\u00c7orlu"},
 {"airport_iata_code":"BXN", "airport_name":"Ims?k Airport", "city_name":""},
 {"airport_iata_code":"AOE", "airport_name":"Anadolu University Airport", "city_name":"Eskisehir"},
 {"airport_iata_code":"EZS", "airport_name":"Elaz?? Airport", "city_name":"Elaz??"},
 {"airport_iata_code":"DIY", "airport_name":"Diyarbakir Airport", "city_name":"Diyarbakir"},
 {"airport_iata_code":"ERC", "airport_name":"Erzincan Airport", "city_name":"Erzincan"},
 {"airport_iata_code":"ERZ", "airport_name":"Erzurum International Airport", "city_name":"Erzurum"},
 {"airport_iata_code":"KSY", "airport_name":"Kars Airport", "city_name":"Kars"},
 {"airport_iata_code":"TZX", "airport_name":"Trabzon International Airport", "city_name":"Trabzon"},
 {"airport_iata_code":"SFQ", "airport_name":"Sanliurfa Airport", "city_name":"Sanliurfa"},
 {"airport_iata_code":"VAN", "airport_name":"Van Ferit Melen Airport", "city_name":"Van"},
 {"airport_iata_code":"BAL", "airport_name":"Batman Airport", "city_name":"Batman"},
 {"airport_iata_code":"MSR", "airport_name":"Mu? Airport", "city_name":"Mu?"},
 {"airport_iata_code":"SXZ", "airport_name":"Siirt Airport", "city_name":"Siirt"},
 {"airport_iata_code":"SIC", "airport_name":"Sinop Airport", "city_name":"Sinop"},
 {"airport_iata_code":"KCM", "airport_name":"Kahramanmara? Airport", "city_name":"Kahramanmara?"},
 {"airport_iata_code":"AJI", "airport_name":"A?r? Airport", "city_name":""},
 {"airport_iata_code":"ADF", "airport_name":"Adiyaman Airport", "city_name":"Adiyaman"},
 {"airport_iata_code":"MQM", "airport_name":"Mardin Airport", "city_name":"Mardin"},
 {"airport_iata_code":"GNY", "airport_name":"Sanliurfa GAP Airport", "city_name":"Sanliurfa"},
 {"airport_iata_code":"HTY", "airport_name":"Hatay Airport", "city_name":"Hatay"},
 {"airport_iata_code":"ISE", "airport_name":"S\u00fcleyman Demirel International Airport", "city_name":"Isparta"},
 {"airport_iata_code":"EDO", "airport_name":"Bal?kesir K\u00f6rfez Airport", "city_name":"Edremit"},
 {"airport_iata_code":"BJV", "airport_name":"Milas Bodrum International Airport", "city_name":"Bodrum"},
 {"airport_iata_code":"SZF", "airport_name":"Samsun \u00c7ar?amba Airport", "city_name":"Samsun"},
 {"airport_iata_code":"SAW", "airport_name":"Sabiha G\u00f6k\u00e7en International Airport", "city_name":"Istanbul"},
 {"airport_iata_code":"GZP", "airport_name":"Gazipa?a Airport", "city_name":"Gazipa?a"},
 {"airport_iata_code":"TAB", "airport_name":"Tobago-Crown Point Airport", "city_name":"Scarborough"},
 {"airport_iata_code":"POS", "airport_name":"Piarco International Airport", "city_name":"Port of Spain"},
 {"airport_iata_code":"FUN", "airport_name":"Funafuti International Airport", "city_name":"Funafuti"},
 {"airport_iata_code":"KNH", "airport_name":"Kinmen Airport", "city_name":"Shang-I"},
 {"airport_iata_code":"LZN", "airport_name":"Matsu Nangan Airport", "city_name":"Nangang Island"},
 {"airport_iata_code":"TTT", "airport_name":"Taitung Airport", "city_name":"Taitung City"},
 {"airport_iata_code":"GNI", "airport_name":"Lyudao Airport", "city_name":"Lyudao"},
 {"airport_iata_code":"KHH", "airport_name":"Kaohsiung International Airport", "city_name":"Kaohsiung City"},
 {"airport_iata_code":"CYI", "airport_name":"Chiayi Airport", "city_name":"Chiayi City"},
 {"airport_iata_code":"HCN", "airport_name":"Hengchun Airport", "city_name":"Hengchung"},
 {"airport_iata_code":"TXG", "airport_name":"Taichung Airport", "city_name":"Taichung City"},
 {"airport_iata_code":"KYD", "airport_name":"Lanyu Airport", "city_name":"Orchid Island"},
 {"airport_iata_code":"RMQ", "airport_name":"Taichung Ching Chuang Kang Airport", "city_name":"Taichung City"},
 {"airport_iata_code":"MFK", "airport_name":"Matsu Beigan Airport", "city_name":"Beigan Island"},
 {"airport_iata_code":"TNN", "airport_name":"Tainan Airport", "city_name":"Tainan City"},
 {"airport_iata_code":"MZG", "airport_name":"Makung Airport", "city_name":"Makung City"},
 {"airport_iata_code":"PIF", "airport_name":"Pingtung North Airport", "city_name":"Pingtung"},
 {"airport_iata_code":"TSA", "airport_name":"Taipei Songshan Airport", "city_name":"Taipei City"},
 {"airport_iata_code":"TPE", "airport_name":"Taiwan Taoyuan International Airport", "city_name":"Taipei"},
 {"airport_iata_code":"WOT", "airport_name":"Wang-an Airport", "city_name":"Wang-an"},
 {"airport_iata_code":"HUN", "airport_name":"Hualien Airport", "city_name":"Hualien City"},
 {"airport_iata_code":"CMJ", "airport_name":"Chi Mei Airport", "city_name":"Chi Mei"},
 {"airport_iata_code":"GIT", "airport_name":"Geita Airport", "city_name":"Geita"},
 {"airport_iata_code":"LUY", "airport_name":"Lushoto Airport", "city_name":"Lushoto"},
 {"airport_iata_code":"GTZ", "airport_name":"Kirawira B Aerodrome", "city_name":"Grumeti Game Reserve"},
 {"airport_iata_code":"ARK", "airport_name":"Arusha Airport", "city_name":"Arusha"},
 {"airport_iata_code":"BKZ", "airport_name":"Bukoba Airport", "city_name":"Bukoba"},
 {"airport_iata_code":"DAR", "airport_name":"Mwalimu Julius K. Nyerere International Airport", "city_name":"Dar es Salaam"},
 {"airport_iata_code":"DOD", "airport_name":"Dodoma Airport", "city_name":"Dodoma"},
 {"airport_iata_code":"IRI", "airport_name":"Iringa Airport", "city_name":"Nduli"},
 {"airport_iata_code":"TKQ", "airport_name":"Kigoma Airport", "city_name":"Kigoma"},
 {"airport_iata_code":"KIY", "airport_name":"Kilwa Masoko Airport", "city_name":"Kilwa Masoko"},
 {"airport_iata_code":"JRO", "airport_name":"Kilimanjaro International Airport", "city_name":"Arusha"},
 {"airport_iata_code":"LDI", "airport_name":"Kikwetu Airport", "city_name":"Lindi"},
 {"airport_iata_code":"LKY", "airport_name":"Lake Manyara Airport", "city_name":"Lake Manyara National Park"},
 {"airport_iata_code":"MFA", "airport_name":"Mafia Island Airport", "city_name":"Mafia Island"},
 {"airport_iata_code":"MBI", "airport_name":"Mbeya Airport", "city_name":"Mbeya"},
 {"airport_iata_code":"MWN", "airport_name":"Mwadui Airport", "city_name":"Mwadui"},
 {"airport_iata_code":"XMI", "airport_name":"Masasi Airport", "city_name":"Masasi"},
 {"airport_iata_code":"QSI", "airport_name":"Moshi Airport", "city_name":"Moshi"},
 {"airport_iata_code":"MYW", "airport_name":"Mtwara Airport", "city_name":"Mtwara"},
 {"airport_iata_code":"MUZ", "airport_name":"Musoma Airport", "city_name":"Musoma"},
 {"airport_iata_code":"MWZ", "airport_name":"Mwanza Airport", "city_name":"Mwanza"},
 {"airport_iata_code":"NCH", "airport_name":"Nachingwea Airport", "city_name":"Nachingwea"},
 {"airport_iata_code":"JOM", "airport_name":"Njombe Airport", "city_name":"Njombe"},
 {"airport_iata_code":"PMA", "airport_name":"Pemba Airport", "city_name":"Chake"},
 {"airport_iata_code":"SEU", "airport_name":"Seronera Airport", "city_name":"Seronera"},
 {"airport_iata_code":"SGX", "airport_name":"Songea Airport", "city_name":"Songea"},
 {"airport_iata_code":"SUT", "airport_name":"Sumbawanga Airport", "city_name":"Sumbawanga"},
 {"airport_iata_code":"SHY", "airport_name":"Shinyanga Airport", "city_name":"Shinyanga"},
 {"airport_iata_code":"TBO", "airport_name":"Tabora Airport", "city_name":"Tabora"},
 {"airport_iata_code":"TGT", "airport_name":"Tanga Airport", "city_name":"Tanga"},
 {"airport_iata_code":"ZNZ", "airport_name":"Zanzibar Airport", "city_name":"Kiembi Samaki"},
 {"airport_iata_code":"KBP", "airport_name":"Boryspil International Airport", "city_name":"Kiev"},
 {"airport_iata_code":"DOK", "airport_name":"Donetsk International Airport", "city_name":"Donetsk"},
 {"airport_iata_code":"KRQ", "airport_name":"Kramatorsk Airport", "city_name":"Kramatorsk"},
 {"airport_iata_code":"MPW", "airport_name":"Mariupol International Airport", "city_name":"Mariupol"},
 {"airport_iata_code":"SEV", "airport_name":"Sievierodonetsk Airport", "city_name":"Sievierodonetsk"},
 {"airport_iata_code":"VSG", "airport_name":"Luhansk International Airport", "city_name":"Luhansk"},
 {"airport_iata_code":"ERD", "airport_name":"Berdyansk Airport", "city_name":"Berdyansk"},
 {"airport_iata_code":"DNK", "airport_name":"Dnipropetrovsk International Airport", "city_name":"Dnipropetrovsk"},
 {"airport_iata_code":"OZH", "airport_name":"Zaporizhzhia International Airport", "city_name":"Zaporizhia"},
 {"airport_iata_code":"KWG", "airport_name":"Kryvyi Rih International Airport", "city_name":"Kryvyi Rih"},
 {"airport_iata_code":"UKS", "airport_name":"Belbek Airport", "city_name":"Sevastopol"},
 {"airport_iata_code":"SIP", "airport_name":"Simferopol International Airport", "city_name":"Simferopol"},
 {"airport_iata_code":"KHC", "airport_name":"Kerch Airport", "city_name":"Kerch"},
 {"airport_iata_code":"HRK", "airport_name":"Kharkiv International Airport", "city_name":"Kharkiv"},
 {"airport_iata_code":"PLV", "airport_name":"Suprunovka Airport", "city_name":"Poltava"},
 {"airport_iata_code":"UMY", "airport_name":"Sumy Airport", "city_name":"Sumy"},
 {"airport_iata_code":"CKC", "airport_name":"Cherkasy International Airport", "city_name":"Cherkasy"},
 {"airport_iata_code":"KGO", "airport_name":"Kirovograd Airport", "city_name":"Kirovograd"},
 {"airport_iata_code":"IEV", "airport_name":"Kiev Zhuliany International Airport", "city_name":"Kiev"},
 {"airport_iata_code":"GML", "airport_name":"Gostomel Airport", "city_name":"Kiev"},
 {"airport_iata_code":"ZTR", "airport_name":"Zhytomyr Airport", "city_name":""},
 {"airport_iata_code":"UCK", "airport_name":"Lutsk Airport", "city_name":"Lutsk"},
 {"airport_iata_code":"HMJ", "airport_name":"Khmelnytskyi Airport", "city_name":"Khmelnytskyi"},
 {"airport_iata_code":"IFO", "airport_name":"Ivano-Frankivsk International Airport", "city_name":"Ivano-Frankivsk"},
 {"airport_iata_code":"LWO", "airport_name":"Lviv International Airport", "city_name":"Lviv"},
 {"airport_iata_code":"CWC", "airport_name":"Chernivtsi International Airport", "city_name":"Chernivtsi"},
 {"airport_iata_code":"RWN", "airport_name":"Rivne International Airport", "city_name":"Rivne"},
 {"airport_iata_code":"TNL", "airport_name":"Ternopil International Airport", "city_name":"Ternopil"},
 {"airport_iata_code":"UDJ", "airport_name":"Uzhhorod International Airport", "city_name":"Uzhhorod"},
 {"airport_iata_code":"KHE", "airport_name":"Chernobayevka Airport", "city_name":"Kherson"},
 {"airport_iata_code":"NLV", "airport_name":"Mykolaiv International Airport", "city_name":"Nikolayev"},
 {"airport_iata_code":"ODS", "airport_name":"Odessa International Airport", "city_name":"Odessa"},
 {"airport_iata_code":"VIN", "airport_name":"Vinnytsia/Gavyryshivka Airport", "city_name":"Vinnitsa"},
 {"airport_iata_code":"RUA", "airport_name":"Arua Airport", "city_name":"Arua"},
 {"airport_iata_code":"EBB", "airport_name":"Entebbe International Airport", "city_name":"Kampala"},
 {"airport_iata_code":"ULU", "airport_name":"Gulu Airport", "city_name":"Gulu"},
 {"airport_iata_code":"JIN", "airport_name":"Jinja Airport", "city_name":"Jinja"},
 {"airport_iata_code":"KBG", "airport_name":"Kabalega Falls Airport", "city_name":"Kabalega Falls"},
 {"airport_iata_code":"KSE", "airport_name":"Kasese Airport", "city_name":"Kasese"},
 {"airport_iata_code":"MBQ", "airport_name":"Mbarara Airport", "city_name":"Mbarara"},
 {"airport_iata_code":"KCU", "airport_name":"Masindi Airport", "city_name":"Masindi"},
 {"airport_iata_code":"PAF", "airport_name":"Pakuba Airport", "city_name":""},
 {"airport_iata_code":"SRT", "airport_name":"Soroti Airport", "city_name":"Soroti"},
 {"airport_iata_code":"TRY", "airport_name":"Tororo Airport", "city_name":"Tororo"},
 {"airport_iata_code":"OYG", "airport_name":"Moyo Airport", "city_name":"Moyo"},
 {"airport_iata_code":"MDY", "airport_name":"Henderson Field", "city_name":"Sand Island"},
 {"airport_iata_code":"AWK", "airport_name":"Wake Island Airfield", "city_name":"Wake Island"},
 {"airport_iata_code":"BZF", "airport_name":"Benton Field", "city_name":"Redding"},
 {"airport_iata_code":"DWN", "airport_name":"Downtown Airpark", "city_name":"Oklahoma City"},
 {"airport_iata_code":"AKB", "airport_name":"Atka Airport", "city_name":"Atka"},
 {"airport_iata_code":"PML", "airport_name":"Port Moller Airport", "city_name":"Cold Bay"},
 {"airport_iata_code":"PAQ", "airport_name":"Palmer Municipal Airport", "city_name":"Palmer"},
 {"airport_iata_code":"BTI", "airport_name":"Barter Island LRRS Airport", "city_name":"Barter Island Lrrs"},
 {"airport_iata_code":"BET", "airport_name":"Bethel Airport", "city_name":"Bethel"},
 {"airport_iata_code":"BVU", "airport_name":"Beluga Airport", "city_name":"Beluga"},
 {"airport_iata_code":"BIG", "airport_name":"Allen Army Airfield", "city_name":"Delta Junction Ft Greely"},
 {"airport_iata_code":"BKC", "airport_name":"Buckland Airport", "city_name":"Buckland"},
 {"airport_iata_code":"BMX", "airport_name":"Big Mountain Airport", "city_name":"Big Mountain"},
 {"airport_iata_code":"BRW", "airport_name":"Wiley Post Will Rogers Memorial Airport", "city_name":"Barrow"},
 {"airport_iata_code":"BTT", "airport_name":"Bettles Airport", "city_name":"Bettles"},
 {"airport_iata_code":"CDB", "airport_name":"Cold Bay Airport", "city_name":"Cold Bay"},
 {"airport_iata_code":"CEM", "airport_name":"Central Airport", "city_name":"Central"},
 {"airport_iata_code":"CIK", "airport_name":"Chalkyitsik Airport", "city_name":"Chalkyitsik"},
 {"airport_iata_code":"SCM", "airport_name":"Scammon Bay Airport", "city_name":"Scammon Bay"},
 {"airport_iata_code":"IRC", "airport_name":"Circle City /New/ Airport", "city_name":"Circle"},
 {"airport_iata_code":"CDV", "airport_name":"Merle K (Mudhole) Smith Airport", "city_name":"Cordova"},
 {"airport_iata_code":"CYT", "airport_name":"Yakataga Airport", "city_name":"Yakataga"},
 {"airport_iata_code":"CZF", "airport_name":"Cape Romanzof LRRS Airport", "city_name":"Cape Romanzof"},
 {"airport_iata_code":"DRG", "airport_name":"Deering Airport", "city_name":"Deering"},
 {"airport_iata_code":"RDB", "airport_name":"Red Dog Airport", "city_name":"Red Dog"},
 {"airport_iata_code":"ADK", "airport_name":"Adak Airport", "city_name":"Adak Island"},
 {"airport_iata_code":"DLG", "airport_name":"Dillingham Airport", "city_name":"Dillingham"},
 {"airport_iata_code":"ADQ", "airport_name":"Kodiak Airport", "city_name":"Kodiak"},
 {"airport_iata_code":"DUT", "airport_name":"Unalaska Airport", "city_name":"Unalaska"},
 {"airport_iata_code":"EDF", "airport_name":"Elmendorf Air Force Base", "city_name":"Anchorage"},
 {"airport_iata_code":"EEK", "airport_name":"Eek Airport", "city_name":"Eek"},
 {"airport_iata_code":"EHM", "airport_name":"Cape Newenham LRRS Airport", "city_name":"Cape Newenham"},
 {"airport_iata_code":"EIL", "airport_name":"Eielson Air Force Base", "city_name":"Fairbanks"},
 {"airport_iata_code":"EMK", "airport_name":"Emmonak Airport", "city_name":"Emmonak"},
 {"airport_iata_code":"ENA", "airport_name":"Kenai Municipal Airport", "city_name":"Kenai"},
 {"airport_iata_code":"FAI", "airport_name":"Fairbanks International Airport", "city_name":"Fairbanks"},
 {"airport_iata_code":"FBK", "airport_name":"Ladd AAF Airfield", "city_name":"Fairbanks/Ft Wainwright"},
 {"airport_iata_code":"AFE", "airport_name":"Kake Airport", "city_name":"Kake"},
 {"airport_iata_code":"ABL", "airport_name":"Ambler Airport", "city_name":"Ambler"},
 {"airport_iata_code":"FMC", "airport_name":"Five Mile Airport", "city_name":"Five Mile"},
 {"airport_iata_code":"GAL", "airport_name":"Edward G. Pitka Sr Airport", "city_name":"Galena"},
 {"airport_iata_code":"GKN", "airport_name":"Gulkana Airport", "city_name":"Gulkana"},
 {"airport_iata_code":"GLV", "airport_name":"Golovin Airport", "city_name":"Golovin"},
 {"airport_iata_code":"GAM", "airport_name":"Gambell Airport", "city_name":"Gambell"},
 {"airport_iata_code":"GST", "airport_name":"Gustavus Airport", "city_name":"Gustavus"},
 {"airport_iata_code":"SGY", "airport_name":"Skagway Airport", "city_name":"Skagway"},
 {"airport_iata_code":"HCR", "airport_name":"Holy Cross Airport", "city_name":"Holy Cross"},
 {"airport_iata_code":"HNS", "airport_name":"Haines Airport", "city_name":"Haines"},
 {"airport_iata_code":"HOM", "airport_name":"Homer Airport", "city_name":"Homer"},
 {"airport_iata_code":"HPB", "airport_name":"Hooper Bay Airport", "city_name":"Hooper Bay"},
 {"airport_iata_code":"SHX", "airport_name":"Shageluk Airport", "city_name":"Shageluk"},
 {"airport_iata_code":"IGG", "airport_name":"Igiugig Airport", "city_name":"Igiugig"},
 {"airport_iata_code":"EGX", "airport_name":"Egegik Airport", "city_name":"Egegik"},
 {"airport_iata_code":"IAN", "airport_name":"Bob Baker Memorial Airport", "city_name":"Kiana"},
 {"airport_iata_code":"ILI", "airport_name":"Iliamna Airport", "city_name":"Iliamna"},
 {"airport_iata_code":"UTO", "airport_name":"Indian Mountain LRRS Airport", "city_name":"Utopia Creek"},
 {"airport_iata_code":"WAA", "airport_name":"Wales Airport", "city_name":"Wales"},
 {"airport_iata_code":"JNU", "airport_name":"Juneau International Airport", "city_name":"Juneau"},
 {"airport_iata_code":"KFP", "airport_name":"False Pass Airport", "city_name":"False Pass"},
 {"airport_iata_code":"AKK", "airport_name":"Akhiok Airport", "city_name":"Akhiok"},
 {"airport_iata_code":"KKA", "airport_name":"Koyuk Alfred Adams Airport", "city_name":"Koyuk"},
 {"airport_iata_code":"AKN", "airport_name":"King Salmon Airport", "city_name":"King Salmon"},
 {"airport_iata_code":"IKO", "airport_name":"Nikolski Air Station", "city_name":"Nikolski"},
 {"airport_iata_code":"AKP", "airport_name":"Anaktuvuk Pass Airport", "city_name":"Anaktuvuk Pass"},
 {"airport_iata_code":"KTN", "airport_name":"Ketchikan International Airport", "city_name":"Ketchikan"},
 {"airport_iata_code":"UUK", "airport_name":"Ugnu-Kuparuk Airport", "city_name":"Kuparuk"},
 {"airport_iata_code":"KAL", "airport_name":"Kaltag Airport", "city_name":"Kaltag"},
 {"airport_iata_code":"AKW", "airport_name":"Klawock Airport", "city_name":"Klawock"},
 {"airport_iata_code":"KYK", "airport_name":"Karluk Airport", "city_name":"Karluk"},
 {"airport_iata_code":"KLG", "airport_name":"Kalskag Airport", "city_name":"Kalskag"},
 {"airport_iata_code":"DQH", "airport_name":"Alpine Airstrip", "city_name":"Deadhorse"},
 {"airport_iata_code":"LUR", "airport_name":"Cape Lisburne LRRS Airport", "city_name":"Cape Lisburne"},
 {"airport_iata_code":"KMO", "airport_name":"Manokotak Airport", "city_name":"Manokotak"},
 {"airport_iata_code":"MCG", "airport_name":"McGrath Airport", "city_name":"McGrath"},
 {"airport_iata_code":"MLY", "airport_name":"Manley Hot Springs Airport", "city_name":"Manley Hot Springs"},
 {"airport_iata_code":"MOU", "airport_name":"Mountain Village Airport", "city_name":"Mountain Village"},
 {"airport_iata_code":"MRI", "airport_name":"Merrill Field", "city_name":"Anchorage"},
 {"airport_iata_code":"MYU", "airport_name":"Mekoryuk Airport", "city_name":"Mekoryuk"},
 {"airport_iata_code":"WNA", "airport_name":"Napakiak Airport", "city_name":"Napakiak"},
 {"airport_iata_code":"ANC", "airport_name":"Ted Stevens Anchorage International Airport", "city_name":"Anchorage"},
 {"airport_iata_code":"ANI", "airport_name":"Aniak Airport", "city_name":"Aniak"},
 {"airport_iata_code":"ENN", "airport_name":"Nenana Municipal Airport", "city_name":"Nenana"},
 {"airport_iata_code":"ANN", "airport_name":"Annette Island Airport", "city_name":"Annette"},
 {"airport_iata_code":"ANV", "airport_name":"Anvik Airport", "city_name":"Anvik"},
 {"airport_iata_code":"KNW", "airport_name":"New Stuyahok Airport", "city_name":"New Stuyahok"},
 {"airport_iata_code":"OBU", "airport_name":"Kobuk Airport", "city_name":"Kobuk"},
 {"airport_iata_code":"PCA", "airport_name":"Portage Creek Airport", "city_name":"Portage Creek"},
 {"airport_iata_code":"HNH", "airport_name":"Hoonah Airport", "city_name":"Hoonah"},
 {"airport_iata_code":"OME", "airport_name":"Nome Airport", "city_name":"Nome"},
 {"airport_iata_code":"OOK", "airport_name":"Toksook Bay Airport", "city_name":"Toksook Bay"},
 {"airport_iata_code":"ORT", "airport_name":"Northway Airport", "city_name":"Northway"},
 {"airport_iata_code":"OTZ", "airport_name":"Ralph Wien Memorial Airport", "city_name":"Kotzebue"},
 {"airport_iata_code":"PBV", "airport_name":"St George Airport", "city_name":"St George"},
 {"airport_iata_code":"KPC", "airport_name":"Port Clarence Coast Guard Station", "city_name":"Port Clarence"},
 {"airport_iata_code":"PSG", "airport_name":"Petersburg James A Johnson Airport", "city_name":"Petersburg"},
 {"airport_iata_code":"PTH", "airport_name":"Port Heiden Airport", "city_name":"Port Heiden"},
 {"airport_iata_code":"PKA", "airport_name":"Napaskiak Airport", "city_name":"Napaskiak"},
 {"airport_iata_code":"PTU", "airport_name":"Platinum Airport", "city_name":"Platinum"},
 {"airport_iata_code":"PIP", "airport_name":"Pilot Point Airport", "city_name":"Pilot Point"},
 {"airport_iata_code":"PHO", "airport_name":"Point Hope Airport", "city_name":"Point Hope"},
 {"airport_iata_code":"NUI", "airport_name":"Nuiqsut Airport", "city_name":"Nuiqsut"},
 {"airport_iata_code":"ARC", "airport_name":"Arctic Village Airport", "city_name":"Arctic Village"},
 {"airport_iata_code":"RBY", "airport_name":"Ruby Airport", "city_name":"Ruby"},
 {"airport_iata_code":"SVA", "airport_name":"Savoonga Airport", "city_name":"Savoonga"},
 {"airport_iata_code":"SCC", "airport_name":"Deadhorse Airport", "city_name":"Deadhorse"},
 {"airport_iata_code":"SDP", "airport_name":"Sand Point Airport", "city_name":"Sand Point"},
 {"airport_iata_code":"SHH", "airport_name":"Shishmaref Airport", "city_name":"Shishmaref"},
 {"airport_iata_code":"SIT", "airport_name":"Sitka Rocky Gutierrez Airport", "city_name":"Sitka"},
 {"airport_iata_code":"SQL", "airport_name":"Sleetmute Airport", "city_name":"Sleetmute"},
 {"airport_iata_code":"KSM", "airport_name":"St Mary's Airport", "city_name":"St Mary's"},
 {"airport_iata_code":"SNP", "airport_name":"St Paul Island Airport", "city_name":"St Paul Island"},
 {"airport_iata_code":"SMU", "airport_name":"Sheep Mountain Airport", "city_name":"Sheep Mountain"},
 {"airport_iata_code":"UMM", "airport_name":"Summit Airport", "city_name":"Summit"},
 {"airport_iata_code":"SVW", "airport_name":"Sparrevohn LRRS Airport", "city_name":"Sparrevohn"},
 {"airport_iata_code":"SXQ", "airport_name":"Soldotna Airport", "city_name":"Soldotna"},
 {"airport_iata_code":"SYA", "airport_name":"Eareckson Air Station", "city_name":"Shemya"},
 {"airport_iata_code":"TAL", "airport_name":"Ralph M Calhoun Memorial Airport", "city_name":"Tanana"},
 {"airport_iata_code":"TNC", "airport_name":"Tin City Long Range Radar Station Airport", "city_name":"Tin City"},
 {"airport_iata_code":"TOG", "airport_name":"Togiak Airport", "city_name":"Togiak Village"},
 {"airport_iata_code":"TKJ", "airport_name":"Tok Airport", "city_name":"Tok"},
 {"airport_iata_code":"TLJ", "airport_name":"Tatalina LRRS Airport", "city_name":"Takotna"},
 {"airport_iata_code":"ATK", "airport_name":"Atqasuk Edward Burnell Sr Memorial Airport", "city_name":"Atqasuk"},
 {"airport_iata_code":"AUK", "airport_name":"Alakanuk Airport", "city_name":"Alakanuk"},
 {"airport_iata_code":"UMT", "airport_name":"Umiat Airport", "city_name":"Umiat"},
 {"airport_iata_code":"UNK", "airport_name":"Unalakleet Airport", "city_name":"Unalakleet"},
 {"airport_iata_code":"VAK", "airport_name":"Chevak Airport", "city_name":"Chevak"},
 {"airport_iata_code":"KVC", "airport_name":"King Cove Airport", "city_name":"King Cove"},
 {"airport_iata_code":"VDZ", "airport_name":"Valdez Pioneer Field", "city_name":"Valdez"},
 {"airport_iata_code":"VEE", "airport_name":"Venetie Airport", "city_name":"Venetie"},
 {"airport_iata_code":"KVL", "airport_name":"Kivalina Airport", "city_name":"Kivalina"},
 {"airport_iata_code":"WBQ", "airport_name":"Beaver Airport", "city_name":"Beaver"},
 {"airport_iata_code":"SWD", "airport_name":"Seward Airport", "city_name":"Seward"},
 {"airport_iata_code":"WRG", "airport_name":"Wrangell Airport", "city_name":"Wrangell"},
 {"airport_iata_code":"AIN", "airport_name":"Wainwright Airport", "city_name":"Wainwright"},
 {"airport_iata_code":"WTK", "airport_name":"Noatak Airport", "city_name":"Noatak"},
 {"airport_iata_code":"IYS", "airport_name":"Wasilla Airport", "city_name":"Wasilla"},
 {"airport_iata_code":"YAK", "airport_name":"Yakutat Airport", "city_name":"Yakutat"},
 {"airport_iata_code":"AKI", "airport_name":"Akiak Airport", "city_name":"Akiak"},
 {"airport_iata_code":"AET", "airport_name":"Allakaket Airport", "city_name":"Allakaket"},
 {"airport_iata_code":"PFC", "airport_name":"Pacific City State Airport", "city_name":"Pacific City"},
 {"airport_iata_code":"NCN", "airport_name":"Chenega Bay Airport", "city_name":"Chenega"},
 {"airport_iata_code":"CLP", "airport_name":"Clarks Point Airport", "city_name":"Clarks Point"},
 {"airport_iata_code":"ELI", "airport_name":"Elim Airport", "city_name":"Elim"},
 {"airport_iata_code":"KNK", "airport_name":"Kokhanok Airport", "city_name":"Kokhanok"},
 {"airport_iata_code":"KOT", "airport_name":"Kotlik Airport", "city_name":"Kotlik"},
 {"airport_iata_code":"KYU", "airport_name":"Koyukuk Airport", "city_name":"Koyukuk"},
 {"airport_iata_code":"KWT", "airport_name":"Kwethluk Airport", "city_name":"Kwethluk"},
 {"airport_iata_code":"ORV", "airport_name":"Robert (Bob) Curtis Memorial Airport", "city_name":"Noorvik"},
 {"airport_iata_code":"SKK", "airport_name":"Shaktoolik Airport", "city_name":"Shaktoolik"},
 {"airport_iata_code":"WSN", "airport_name":"South Naknek Nr 2 Airport", "city_name":"South Naknek"},
 {"airport_iata_code":"FYU", "airport_name":"Fort Yukon Airport", "city_name":"Fort Yukon"},
 {"airport_iata_code":"BKH", "airport_name":"Barking Sands Airport", "city_name":"Kekaha"},
 {"airport_iata_code":"HDH", "airport_name":"Dillingham Airfield", "city_name":"Mokuleia"},
 {"airport_iata_code":"HHI", "airport_name":"Wheeler Army Airfield", "city_name":"Wahiawa"},
 {"airport_iata_code":"HNM", "airport_name":"Hana Airport", "city_name":"Hana"},
 {"airport_iata_code":"JHM", "airport_name":"Kapalua Airport", "city_name":"Lahaina"},
 {"airport_iata_code":"JRF", "airport_name":"Kalaeloa (John Rodgers Field) Airport", "city_name":"Kapolei"},
 {"airport_iata_code":"KOA", "airport_name":"Kona International At Keahole Airport", "city_name":"Kailua/Kona"},
 {"airport_iata_code":"LIH", "airport_name":"Lihue Airport", "city_name":"Lihue"},
 {"airport_iata_code":"LUP", "airport_name":"Kalaupapa Airport", "city_name":"Kalaupapa"},
 {"airport_iata_code":"MKK", "airport_name":"Molokai Airport", "city_name":"Kaunakakai"},
 {"airport_iata_code":"MUE", "airport_name":"Waimea Kohala Airport", "city_name":"Kamuela"},
 {"airport_iata_code":"HNL", "airport_name":"Honolulu International Airport", "city_name":"Honolulu"},
 {"airport_iata_code":"LNY", "airport_name":"Lanai Airport", "city_name":"Lanai City"},
 {"airport_iata_code":"OGG", "airport_name":"Kahului Airport", "city_name":"Kahului"},
 {"airport_iata_code":"PAK", "airport_name":"Port Allen Airport", "city_name":"Hanapepe"},
 {"airport_iata_code":"BSF", "airport_name":"Bradshaw Army Airfield", "city_name":"Camp Pohakuloa"},
 {"airport_iata_code":"ITO", "airport_name":"Hilo International Airport", "city_name":"Hilo"},
 {"airport_iata_code":"UPP", "airport_name":"Upolu Airport", "city_name":"Hawi"},
 {"airport_iata_code":"PIZ", "airport_name":"Point Lay Lrrs Airport", "city_name":"Point Lay"},
 {"airport_iata_code":"RDV", "airport_name":"Red Devil Airport", "city_name":"Red Devil"},
 {"airport_iata_code":"LPS", "airport_name":"Lopez Island Airport", "city_name":"Lopez"},
 {"airport_iata_code":"SAS", "airport_name":"Salton Sea Airport", "city_name":"Salton City"},
 {"airport_iata_code":"SRV", "airport_name":"Stony River 2 Airport", "city_name":"Stony River"},
 {"airport_iata_code":"SYB", "airport_name":"Seal Bay Seaplane Base", "city_name":"Seal Bay"},
 {"airport_iata_code":"SYN", "airport_name":"Stanton Airfield", "city_name":"Stanton"},
 {"airport_iata_code":"SZN", "airport_name":"Santa Cruz Island Airport", "city_name":"Santa Barbara"},
 {"airport_iata_code":"SZP", "airport_name":"Santa Paula Airport", "city_name":"Santa Paula"},
 {"airport_iata_code":"TLT", "airport_name":"Tuluksak Airport", "city_name":"Tuluksak"},
 {"airport_iata_code":"PTA", "airport_name":"Port Alsworth Airport", "city_name":"Port Alsworth"},
 {"airport_iata_code":"TSG", "airport_name":"Tanacross Airport", "city_name":"Tanacross"},
 {"airport_iata_code":"DBS", "airport_name":"Dubois Municipal Airport", "city_name":"Dubois"},
 {"airport_iata_code":"GMT", "airport_name":"Granite Mountain Air Station", "city_name":"Granite Mountain"},
 {"airport_iata_code":"HNE", "airport_name":"Tahneta Pass Airport", "city_name":"Tahneta Pass Lodge"},
 {"airport_iata_code":"AMK", "airport_name":"Animas Air Park", "city_name":"Durango"},
 {"airport_iata_code":"BDX", "airport_name":"Broadus Airport", "city_name":"Broadus"},
 {"airport_iata_code":"RLA", "airport_name":"Rolla Downtown Airport", "city_name":"Rolla"},
 {"airport_iata_code":"FID", "airport_name":"Elizabeth Field", "city_name":"Fishers Island"},
 {"airport_iata_code":"AHD", "airport_name":"Ardmore Downtown Executive Airport", "city_name":"Ardmore"},
 {"airport_iata_code":"GCW", "airport_name":"Grand Canyon West Airport", "city_name":"Peach Springs"},
 {"airport_iata_code":"RKC", "airport_name":"Montague-Yreka Rohrer Field", "city_name":"Montague"},
 {"airport_iata_code":"GNF", "airport_name":"Gansner Field", "city_name":"Quincy"},
 {"airport_iata_code":"AHF", "airport_name":"Arapahoe Municipal Airport", "city_name":"Arapahoe"},
 {"airport_iata_code":"GTP", "airport_name":"Grants Pass Airport", "city_name":"Grants Pass"},
 {"airport_iata_code":"GCD", "airport_name":"Grand Coulee Dam Airport", "city_name":"Electric City"},
 {"airport_iata_code":"FPY", "airport_name":"Perry Foley Airport", "city_name":"Perry"},
 {"airport_iata_code":"FHB", "airport_name":"Fernandina Beach Municipal Airport", "city_name":"Fernandina Beach"},
 {"airport_iata_code":"BLD", "airport_name":"Boulder City Municipal Airport", "city_name":"Boulder City"},
 {"airport_iata_code":"FMU", "airport_name":"Florence Municipal Airport", "city_name":"Florence"},
 {"airport_iata_code":"ATE", "airport_name":"Antlers Municipal Airport", "city_name":"Antlers"},
 {"airport_iata_code":"ASQ", "airport_name":"Austin Airport", "city_name":"Austin"},
 {"airport_iata_code":"AAF", "airport_name":"Apalachicola Regional Airport", "city_name":"Apalachicola"},
 {"airport_iata_code":"ABE", "airport_name":"Lehigh Valley International Airport", "city_name":"Allentown"},
 {"airport_iata_code":"ABI", "airport_name":"Abilene Regional Airport", "city_name":"Abilene"},
 {"airport_iata_code":"ABQ", "airport_name":"Albuquerque International Sunport Airport", "city_name":"Albuquerque"},
 {"airport_iata_code":"ABR", "airport_name":"Aberdeen Regional Airport", "city_name":"Aberdeen"},
 {"airport_iata_code":"ABY", "airport_name":"Southwest Georgia Regional Airport", "city_name":"Albany"},
 {"airport_iata_code":"ACB", "airport_name":"Antrim County Airport", "city_name":"Bellaire"},
 {"airport_iata_code":"ACK", "airport_name":"Nantucket Memorial Airport", "city_name":"Nantucket"},
 {"airport_iata_code":"ACT", "airport_name":"Waco Regional Airport", "city_name":"Waco"},
 {"airport_iata_code":"ACV", "airport_name":"Arcata Airport", "city_name":"Arcata/Eureka"},
 {"airport_iata_code":"ACY", "airport_name":"Atlantic City International Airport", "city_name":"Atlantic City"},
 {"airport_iata_code":"ADG", "airport_name":"Lenawee County Airport", "city_name":"Adrian"},
 {"airport_iata_code":"ADT", "airport_name":"Ada Municipal Airport", "city_name":"Ada"},
 {"airport_iata_code":"ADM", "airport_name":"Ardmore Municipal Airport", "city_name":"Ardmore"},
 {"airport_iata_code":"ADS", "airport_name":"Addison Airport", "city_name":"Dallas"},
 {"airport_iata_code":"ADW", "airport_name":"Andrews Air Force Base", "city_name":"Camp Springs"},
 {"airport_iata_code":"AEL", "airport_name":"Albert Lea Municipal Airport", "city_name":"Albert Lea"},
 {"airport_iata_code":"AEX", "airport_name":"Alexandria International Airport", "city_name":"Alexandria"},
 {"airport_iata_code":"AFF", "airport_name":"USAF Academy Airfield", "city_name":"Colorado Springs"},
 {"airport_iata_code":"WSG", "airport_name":"Washington County Airport", "city_name":"Washington"},
 {"airport_iata_code":"AFN", "airport_name":"Jaffrey Airport Silver Ranch Airport", "city_name":"Jaffrey"},
 {"airport_iata_code":"AFO", "airport_name":"Afton Municipal Airport", "city_name":"Afton"},
 {"airport_iata_code":"AFW", "airport_name":"Fort Worth Alliance Airport", "city_name":"Fort Worth"},
 {"airport_iata_code":"AGC", "airport_name":"Allegheny County Airport", "city_name":"Pittsburgh"},
 {"airport_iata_code":"AGO", "airport_name":"Magnolia Municipal Airport", "city_name":"Magnolia"},
 {"airport_iata_code":"AGS", "airport_name":"Augusta Regional At Bush Field", "city_name":"Augusta"},
 {"airport_iata_code":"AHC", "airport_name":"Amedee Army Air Field", "city_name":"Herlong"},
 {"airport_iata_code":"AHH", "airport_name":"Amery Municipal Airport", "city_name":"Amery"},
 {"airport_iata_code":"AHN", "airport_name":"Athens Ben Epps Airport", "city_name":"Athens"},
 {"airport_iata_code":"AIA", "airport_name":"Alliance Municipal Airport", "city_name":"Alliance"},
 {"airport_iata_code":"AID", "airport_name":"Anderson Municipal Darlington Field", "city_name":"Anderson"},
 {"airport_iata_code":"AIK", "airport_name":"Aiken Municipal Airport", "city_name":"Aiken"},
 {"airport_iata_code":"AIO", "airport_name":"Atlantic Municipal Airport", "city_name":"Atlantic"},
 {"airport_iata_code":"AIV", "airport_name":"George Downer Airport", "city_name":"Aliceville"},
 {"airport_iata_code":"AIZ", "airport_name":"Lee C Fine Memorial Airport", "city_name":"Kaiser Lake Ozark"},
 {"airport_iata_code":"AKO", "airport_name":"Colorado Plains Regional Airport", "city_name":"Akron"},
 {"airport_iata_code":"AKC", "airport_name":"Akron Fulton International Airport", "city_name":"Akron"},
 {"airport_iata_code":"ALB", "airport_name":"Albany International Airport", "city_name":"Albany"},
 {"airport_iata_code":"ALI", "airport_name":"Alice International Airport", "city_name":"Alice"},
 {"airport_iata_code":"ALM", "airport_name":"Alamogordo White Sands Regional Airport", "city_name":"Alamogordo"},
 {"airport_iata_code":"ALN", "airport_name":"St Louis Regional Airport", "city_name":"Alton/St Louis"},
 {"airport_iata_code":"ALO", "airport_name":"Waterloo Regional Airport", "city_name":"Waterloo"},
 {"airport_iata_code":"ALS", "airport_name":"San Luis Valley Regional Bergman Field", "city_name":"Alamosa"},
 {"airport_iata_code":"ALW", "airport_name":"Walla Walla Regional Airport", "city_name":"Walla Walla"},
 {"airport_iata_code":"ALX", "airport_name":"Thomas C Russell Field", "city_name":"Alexander City"},
 {"airport_iata_code":"AMA", "airport_name":"Rick Husband Amarillo International Airport", "city_name":"Amarillo"},
 {"airport_iata_code":"AMN", "airport_name":"RPD Airport", "city_name":"Alma"},
 {"airport_iata_code":"AMW", "airport_name":"Ames Municipal Airport", "city_name":"Ames"},
 {"airport_iata_code":"ANB", "airport_name":"Anniston Metropolitan Airport", "city_name":"Anniston"},
 {"airport_iata_code":"AND", "airport_name":"Anderson Regional Airport", "city_name":"Anderson"},
 {"airport_iata_code":"ANP", "airport_name":"Lee Airport", "city_name":"Annapolis"},
 {"airport_iata_code":"ANQ", "airport_name":"Tri State Steuben County Airport", "city_name":"Angola"},
 {"airport_iata_code":"ANW", "airport_name":"Ainsworth Municipal Airport", "city_name":"Ainsworth"},
 {"airport_iata_code":"ANY", "airport_name":"Anthony Municipal Airport", "city_name":"Anthony"},
 {"airport_iata_code":"AOH", "airport_name":"Lima Allen County Airport", "city_name":"Lima"},
 {"airport_iata_code":"AOO", "airport_name":"Altoona Blair County Airport", "city_name":"Altoona"},
 {"airport_iata_code":"APA", "airport_name":"Centennial Airport", "city_name":"Denver"},
 {"airport_iata_code":"APC", "airport_name":"Napa County Airport", "city_name":"Napa"},
 {"airport_iata_code":"APF", "airport_name":"Naples Municipal Airport", "city_name":"Naples"},
 {"airport_iata_code":"APG", "airport_name":"Phillips Army Air Field", "city_name":"Aberdeen Proving Grounds(Aberd"},
 {"airport_iata_code":"APH", "airport_name":"A P Hill Aaf (Fort A P Hill) Airport", "city_name":"Fort A. P. Hill"},
 {"airport_iata_code":"APN", "airport_name":"Alpena County Regional Airport", "city_name":"Alpena"},
 {"airport_iata_code":"APT", "airport_name":"Marion County Brown Field", "city_name":"Jasper"},
 {"airport_iata_code":"APV", "airport_name":"Apple Valley Airport", "city_name":"Apple Valley"},
 {"airport_iata_code":"ARA", "airport_name":"Acadiana Regional Airport", "city_name":"New Iberia"},
 {"airport_iata_code":"ARB", "airport_name":"Ann Arbor Municipal Airport", "city_name":"Ann Arbor"},
 {"airport_iata_code":"ARG", "airport_name":"Walnut Ridge Regional Airport", "city_name":"Walnut Ridge"},
 {"airport_iata_code":"AUZ", "airport_name":"Aurora Municipal Airport", "city_name":"Chicago/Aurora"},
 {"airport_iata_code":"ART", "airport_name":"Watertown International Airport", "city_name":"Watertown"},
 {"airport_iata_code":"ARV", "airport_name":"Lakeland-Noble F. Lee Memorial field", "city_name":"Minocqua-Woodruff"},
 {"airport_iata_code":"ASE", "airport_name":"Aspen-Pitkin Co/Sardy Field", "city_name":"Aspen"},
 {"airport_iata_code":"ASH", "airport_name":"Boire Field", "city_name":"Nashua"},
 {"airport_iata_code":"ASL", "airport_name":"Harrison County Airport", "city_name":"Marshall"},
 {"airport_iata_code":"ASN", "airport_name":"Talladega Municipal Airport", "city_name":"Talladega"},
 {"airport_iata_code":"AST", "airport_name":"Astoria Regional Airport", "city_name":"Astoria"},
 {"airport_iata_code":"ASX", "airport_name":"John F Kennedy Memorial Airport", "city_name":"Ashland"},
 {"airport_iata_code":"ASY", "airport_name":"Ashley Municipal Airport", "city_name":"Ashley"},
 {"airport_iata_code":"ATL", "airport_name":"Hartsfield Jackson Atlanta International Airport", "city_name":"Atlanta"},
 {"airport_iata_code":"ATS", "airport_name":"Artesia Municipal Airport", "city_name":"Artesia"},
 {"airport_iata_code":"ATW", "airport_name":"Outagamie County Regional Airport", "city_name":"Appleton"},
 {"airport_iata_code":"ATY", "airport_name":"Watertown Regional Airport", "city_name":"Watertown"},
 {"airport_iata_code":"AUG", "airport_name":"Augusta State Airport", "city_name":"Augusta"},
 {"airport_iata_code":"AUM", "airport_name":"Austin Municipal Airport", "city_name":"Austin"},
 {"airport_iata_code":"AUN", "airport_name":"Auburn Municipal Airport", "city_name":"Auburn"},
 {"airport_iata_code":"AUO", "airport_name":"Auburn Opelika Robert G. Pitts Airport", "city_name":"Auburn"},
 {"airport_iata_code":"AUS", "airport_name":"Austin Bergstrom International Airport", "city_name":"Austin"},
 {"airport_iata_code":"AUW", "airport_name":"Wausau Downtown Airport", "city_name":"Wausau"},
 {"airport_iata_code":"AVL", "airport_name":"Asheville Regional Airport", "city_name":"Asheville"},
 {"airport_iata_code":"AVO", "airport_name":"Avon Park Executive Airport", "city_name":"Avon Park"},
 {"airport_iata_code":"AVP", "airport_name":"Wilkes Barre Scranton International Airport", "city_name":"Wilkes-Barre/Scranton"},
 {"airport_iata_code":"AVW", "airport_name":"Marana Regional Airport", "city_name":"Tucson"},
 {"airport_iata_code":"AWM", "airport_name":"West Memphis Municipal Airport", "city_name":"West Memphis"},
 {"airport_iata_code":"AXG", "airport_name":"Algona Municipal Airport", "city_name":"Algona"},
 {"airport_iata_code":"AXN", "airport_name":"Chandler Field", "city_name":"Alexandria"},
 {"airport_iata_code":"AXS", "airport_name":"Altus Quartz Mountain Regional Airport", "city_name":"Altus"},
 {"airport_iata_code":"AXV", "airport_name":"Neil Armstrong Airport", "city_name":"Wapakoneta"},
 {"airport_iata_code":"AXX", "airport_name":"Angel Fire Airport", "city_name":"Angel Fire"},
 {"airport_iata_code":"AYS", "airport_name":"Waycross Ware County Airport", "city_name":"Waycross"},
 {"airport_iata_code":"AZO", "airport_name":"Kalamazoo Battle Creek International Airport", "city_name":"Kalamazoo"},
 {"airport_iata_code":"BAB", "airport_name":"Beale Air Force Base", "city_name":"Marysville"},
 {"airport_iata_code":"BAD", "airport_name":"Barksdale Air Force Base", "city_name":"Bossier City"},
 {"airport_iata_code":"BAF", "airport_name":"Barnes Municipal Airport", "city_name":"Westfield/Springfield"},
 {"airport_iata_code":"CLU", "airport_name":"Columbus Municipal Airport", "city_name":"Columbus"},
 {"airport_iata_code":"BAM", "airport_name":"Battle Mountain Airport", "city_name":"Battle Mountain"},
 {"airport_iata_code":"BBB", "airport_name":"Benson Municipal Airport", "city_name":"Benson"},
 {"airport_iata_code":"BBD", "airport_name":"Curtis Field", "city_name":"Brady"},
 {"airport_iata_code":"BTN", "airport_name":"Marlboro County Jetport H.E. Avent Field", "city_name":"Bennettsville"},
 {"airport_iata_code":"BBW", "airport_name":"Broken Bow Municipal Airport", "city_name":"Broken Bow"},
 {"airport_iata_code":"BCB", "airport_name":"Virginia Tech Montgomery Executive Airport", "city_name":"Blacksburg"},
 {"airport_iata_code":"BCE", "airport_name":"Bryce Canyon Airport", "city_name":"Bryce Canyon"},
 {"airport_iata_code":"BCT", "airport_name":"Boca Raton Airport", "city_name":"Boca Raton"},
 {"airport_iata_code":"BDE", "airport_name":"Baudette International Airport", "city_name":"Baudette"},
 {"airport_iata_code":"BDG", "airport_name":"Blanding Municipal Airport", "city_name":"Blanding"},
 {"airport_iata_code":"BDL", "airport_name":"Bradley International Airport", "city_name":"Hartford"},
 {"airport_iata_code":"BDR", "airport_name":"Igor I Sikorsky Memorial Airport", "city_name":"Bridgeport"},
 {"airport_iata_code":"WBU", "airport_name":"Boulder Municipal Airport", "city_name":"Boulder"},
 {"airport_iata_code":"BEC", "airport_name":"Beech Factory Airport", "city_name":"Wichita"},
 {"airport_iata_code":"BED", "airport_name":"Laurence G Hanscom Field", "city_name":"Bedford"},
 {"airport_iata_code":"BEH", "airport_name":"Southwest Michigan Regional Airport", "city_name":"Benton Harbor"},
 {"airport_iata_code":"BFD", "airport_name":"Bradford Regional Airport", "city_name":"Bradford"},
 {"airport_iata_code":"BFF", "airport_name":"Western Neb. Rgnl/William B. Heilig Airport", "city_name":"Scottsbluff"},
 {"airport_iata_code":"BFI", "airport_name":"Boeing Field King County International Airport", "city_name":"Seattle"},
 {"airport_iata_code":"BFL", "airport_name":"Meadows Field", "city_name":"Bakersfield"},
 {"airport_iata_code":"BFM", "airport_name":"Mobile Downtown Airport", "city_name":"Mobile"},
 {"airport_iata_code":"BFR", "airport_name":"Virgil I Grissom Municipal Airport", "city_name":"Bedford"},
 {"airport_iata_code":"BGD", "airport_name":"Hutchinson County Airport", "city_name":"Borger"},
 {"airport_iata_code":"BGE", "airport_name":"Decatur County Industrial Air Park", "city_name":"Bainbridge"},
 {"airport_iata_code":"BGM", "airport_name":"Greater Binghamton/Edwin A Link field", "city_name":"Binghamton"},
 {"airport_iata_code":"BGR", "airport_name":"Bangor International Airport", "city_name":"Bangor"},
 {"airport_iata_code":"BHB", "airport_name":"Hancock County-Bar Harbor Airport", "city_name":"Bar Harbor"},
 {"airport_iata_code":"BHM", "airport_name":"Birmingham-Shuttlesworth International Airport", "city_name":"Birmingham"},
 {"airport_iata_code":"BID", "airport_name":"Block Island State Airport", "city_name":"Block Island"},
 {"airport_iata_code":"BIE", "airport_name":"Beatrice Municipal Airport", "city_name":"Beatrice"},
 {"airport_iata_code":"BIF", "airport_name":"Biggs Army Air Field (Fort Bliss)", "city_name":"Fort Bliss/El Paso"},
 {"airport_iata_code":"BIH", "airport_name":"Eastern Sierra Regional Airport", "city_name":"Bishop"},
 {"airport_iata_code":"BIL", "airport_name":"Billings Logan International Airport", "city_name":"Billings"},
 {"airport_iata_code":"BIS", "airport_name":"Bismarck Municipal Airport", "city_name":"Bismarck"},
 {"airport_iata_code":"BIX", "airport_name":"Keesler Air Force Base", "city_name":"Biloxi"},
 {"airport_iata_code":"BJC", "airport_name":"Rocky Mountain Metropolitan Airport", "city_name":"Denver"},
 {"airport_iata_code":"BKD", "airport_name":"Stephens County Airport", "city_name":"Breckenridge"},
 {"airport_iata_code":"BKE", "airport_name":"Baker City Municipal Airport", "city_name":"Baker City"},
 {"airport_iata_code":"BFK", "airport_name":"Buckley Air Force Base", "city_name":"Aurora"},
 {"airport_iata_code":"BKL", "airport_name":"Burke Lakefront Airport", "city_name":"Cleveland"},
 {"airport_iata_code":"BKT", "airport_name":"Allen C Perkinson Blackstone Army Air Field", "city_name":"Blackstone"},
 {"airport_iata_code":"BKW", "airport_name":"Raleigh County Memorial Airport", "city_name":"Beckley"},
 {"airport_iata_code":"BKX", "airport_name":"Brookings Regional Airport", "city_name":"Brookings"},
 {"airport_iata_code":"BLF", "airport_name":"Mercer County Airport", "city_name":"Bluefield"},
 {"airport_iata_code":"BLH", "airport_name":"Blythe Airport", "city_name":"Blythe"},
 {"airport_iata_code":"BLI", "airport_name":"Bellingham International Airport", "city_name":"Bellingham"},
 {"airport_iata_code":"BLU", "airport_name":"Blue Canyon Nyack Airport", "city_name":"Emigrant Gap"},
 {"airport_iata_code":"BLV", "airport_name":"Scott AFB/Midamerica Airport", "city_name":"Belleville"},
 {"airport_iata_code":"BMC", "airport_name":"Brigham City Airport", "city_name":"Brigham City"},
 {"airport_iata_code":"BMG", "airport_name":"Monroe County Airport", "city_name":"Bloomington"},
 {"airport_iata_code":"BMI", "airport_name":"Central Illinois Regional Airport at Bloomington-Normal", "city_name":"Bloomington-Normal"},
 {"airport_iata_code":"BML", "airport_name":"Berlin Regional Airport", "city_name":"Berlin"},
 {"airport_iata_code":"BMT", "airport_name":"Beaumont Municipal Airport", "city_name":"Beaumont"},
 {"airport_iata_code":"BNA", "airport_name":"Nashville International Airport", "city_name":"Nashville"},
 {"airport_iata_code":"BNG", "airport_name":"Banning Municipal Airport", "city_name":"Banning"},
 {"airport_iata_code":"BNL", "airport_name":"Barnwell Regional Airport", "city_name":"Barnwell"},
 {"airport_iata_code":"BNO", "airport_name":"Burns Municipal Airport", "city_name":"Burns"},
 {"airport_iata_code":"BNW", "airport_name":"Boone Municipal Airport", "city_name":"Boone"},
 {"airport_iata_code":"BOI", "airport_name":"Boise Air Terminal/Gowen field", "city_name":"Boise"},
 {"airport_iata_code":"BOS", "airport_name":"General Edward Lawrence Logan International Airport", "city_name":"Boston"},
 {"airport_iata_code":"BOW", "airport_name":"Bartow Municipal Airport", "city_name":"Bartow"},
 {"airport_iata_code":"BGS", "airport_name":"Big Spring Mc Mahon-Wrinkle Airport", "city_name":"Big Spring"},
 {"airport_iata_code":"BPI", "airport_name":"Miley Memorial Field", "city_name":"Big Piney"},
 {"airport_iata_code":"WMH", "airport_name":"Ozark Regional Airport", "city_name":"Mountain Home"},
 {"airport_iata_code":"BWM", "airport_name":"Bowman Municipal Airport", "city_name":"Bowman"},
 {"airport_iata_code":"BPT", "airport_name":"Southeast Texas Regional Airport", "city_name":"Beaumont/Port Arthur"},
 {"airport_iata_code":"BQK", "airport_name":"Brunswick Golden Isles Airport", "city_name":"Brunswick"},
 {"airport_iata_code":"BRD", "airport_name":"Brainerd Lakes Regional Airport", "city_name":"Brainerd"},
 {"airport_iata_code":"BRL", "airport_name":"Southeast Iowa Regional Airport", "city_name":"Burlington"},
 {"airport_iata_code":"BRO", "airport_name":"Brownsville South Padre Island International Airport", "city_name":"Brownsville"},
 {"airport_iata_code":"BRY", "airport_name":"Samuels Field", "city_name":"Bardstown"},
 {"airport_iata_code":"BTF", "airport_name":"Skypark Airport", "city_name":"Bountiful"},
 {"airport_iata_code":"BTL", "airport_name":"W K Kellogg Airport", "city_name":"Battle Creek"},
 {"airport_iata_code":"BTM", "airport_name":"Bert Mooney Airport", "city_name":"Butte"},
 {"airport_iata_code":"BTR", "airport_name":"Baton Rouge Metropolitan, Ryan Field", "city_name":"Baton Rouge"},
 {"airport_iata_code":"BTV", "airport_name":"Burlington International Airport", "city_name":"Burlington"},
 {"airport_iata_code":"BTY", "airport_name":"Beatty Airport", "city_name":"Beatty"},
 {"airport_iata_code":"BUB", "airport_name":"Cram Field", "city_name":"Burwell"},
 {"airport_iata_code":"BUF", "airport_name":"Buffalo Niagara International Airport", "city_name":"Buffalo"},
 {"airport_iata_code":"BUM", "airport_name":"Butler Memorial Airport", "city_name":"Butler"},
 {"airport_iata_code":"BUR", "airport_name":"Bob Hope Airport", "city_name":"Burbank"},
 {"airport_iata_code":"BFP", "airport_name":"Beaver County Airport", "city_name":"Beaver Falls"},
 {"airport_iata_code":"BVO", "airport_name":"Bartlesville Municipal Airport", "city_name":"Bartlesville"},
 {"airport_iata_code":"BVX", "airport_name":"Batesville Regional Airport", "city_name":"Batesville"},
 {"airport_iata_code":"BVY", "airport_name":"Beverly Municipal Airport", "city_name":"Beverly"},
 {"airport_iata_code":"BWC", "airport_name":"Brawley Municipal Airport", "city_name":"Brawley"},
 {"airport_iata_code":"BWD", "airport_name":"Brownwood Regional Airport", "city_name":"Brownwood"},
 {"airport_iata_code":"BWG", "airport_name":"Bowling Green Warren County Regional Airport", "city_name":"Bowling Green"},
 {"airport_iata_code":"BWI", "airport_name":"Baltimore/Washington International Thurgood Marshal Airport", "city_name":"Baltimore"},
 {"airport_iata_code":"WAH", "airport_name":"Harry Stern Airport", "city_name":"Wahpeton"},
 {"airport_iata_code":"BXA", "airport_name":"George R Carr Memorial Air Field", "city_name":"Bogalusa"},
 {"airport_iata_code":"BXK", "airport_name":"Buckeye Municipal Airport", "city_name":"Buckeye"},
 {"airport_iata_code":"BYS", "airport_name":"Bicycle Lake Army Air Field", "city_name":"Fort Irwin/Barstow"},
 {"airport_iata_code":"BBC", "airport_name":"Bay City Municipal Airport", "city_name":"Bay City"},
 {"airport_iata_code":"BZN", "airport_name":"Gallatin Field", "city_name":"Bozeman"},
 {"airport_iata_code":"CLG", "airport_name":"New Coalinga Municipal Airport", "city_name":"Coalinga"},
 {"airport_iata_code":"CAD", "airport_name":"Wexford County Airport", "city_name":"Cadillac"},
 {"airport_iata_code":"CAE", "airport_name":"Columbia Metropolitan Airport", "city_name":"Columbia"},
 {"airport_iata_code":"CAK", "airport_name":"Akron Canton Regional Airport", "city_name":"Akron"},
 {"airport_iata_code":"CAO", "airport_name":"Clayton Municipal Airpark", "city_name":"Clayton"},
 {"airport_iata_code":"CBE", "airport_name":"Greater Cumberland Regional Airport", "city_name":"Cumberland"},
 {"airport_iata_code":"CBF", "airport_name":"Council Bluffs Municipal Airport", "city_name":"Council Bluffs"},
 {"airport_iata_code":"CBK", "airport_name":"Shalz Field", "city_name":"Colby"},
 {"airport_iata_code":"CBM", "airport_name":"Columbus Air Force Base", "city_name":"Columbus"},
 {"airport_iata_code":"CCB", "airport_name":"Cable Airport", "city_name":"Upland"},
 {"airport_iata_code":"CCR", "airport_name":"Buchanan Field", "city_name":"Concord"},
 {"airport_iata_code":"CCY", "airport_name":"Northeast Iowa Regional Airport", "city_name":"Charles City"},
 {"airport_iata_code":"LLX", "airport_name":"Caledonia County Airport", "city_name":"Lyndonville"},
 {"airport_iata_code":"CDC", "airport_name":"Cedar City Regional Airport", "city_name":"Cedar City"},
 {"airport_iata_code":"CDH", "airport_name":"Harrell Field", "city_name":"Camden"},
 {"airport_iata_code":"CDN", "airport_name":"Woodward Field", "city_name":"Camden"},
 {"airport_iata_code":"CDR", "airport_name":"Chadron Municipal Airport", "city_name":"Chadron"},
 {"airport_iata_code":"CDS", "airport_name":"Childress Municipal Airport", "city_name":"Childress"},
 {"airport_iata_code":"CDW", "airport_name":"Essex County Airport", "city_name":"Caldwell"},
 {"airport_iata_code":"CEC", "airport_name":"Jack Mc Namara Field Airport", "city_name":"Crescent City"},
 {"airport_iata_code":"CEF", "airport_name":"Westover ARB/Metropolitan Airport", "city_name":"Springfield/Chicopee"},
 {"airport_iata_code":"CEZ", "airport_name":"Cortez Municipal Airport", "city_name":"Cortez"},
 {"airport_iata_code":"CGI", "airport_name":"Cape Girardeau Regional Airport", "city_name":"Cape Girardeau"},
 {"airport_iata_code":"CHA", "airport_name":"Lovell Field", "city_name":"Chattanooga"},
 {"airport_iata_code":"CHO", "airport_name":"Charlottesville Albemarle Airport", "city_name":"Charlottesville"},
 {"airport_iata_code":"CHS", "airport_name":"Charleston Air Force Base-International Airport", "city_name":"Charleston"},
 {"airport_iata_code":"CID", "airport_name":"The Eastern Iowa Airport", "city_name":"Cedar Rapids"},
 {"airport_iata_code":"CIU", "airport_name":"Chippewa County International Airport", "city_name":"Sault Ste Marie"},
 {"airport_iata_code":"CKB", "airport_name":"North Central West Virginia Airport", "city_name":"Clarksburg"},
 {"airport_iata_code":"KCL", "airport_name":"Chignik Lagoon Airport", "city_name":"Chignik Lagoon"},
 {"airport_iata_code":"CLE", "airport_name":"Cleveland Hopkins International Airport", "city_name":"Cleveland"},
 {"airport_iata_code":"CLL", "airport_name":"Easterwood Field", "city_name":"College Station"},
 {"airport_iata_code":"CLM", "airport_name":"William R Fairchild International Airport", "city_name":"Port Angeles"},
 {"airport_iata_code":"CLT", "airport_name":"Charlotte Douglas International Airport", "city_name":"Charlotte"},
 {"airport_iata_code":"CMH", "airport_name":"Port Columbus International Airport", "city_name":"Columbus"},
 {"airport_iata_code":"CMI", "airport_name":"University of Illinois Willard Airport", "city_name":"Champaign/Urbana"},
 {"airport_iata_code":"CMX", "airport_name":"Houghton County Memorial Airport", "city_name":"Hancock"},
 {"airport_iata_code":"CNM", "airport_name":"Cavern City Air Terminal", "city_name":"Carlsbad"},
 {"airport_iata_code":"COS", "airport_name":"City of Colorado Springs Municipal Airport", "city_name":"Colorado Springs"},
 {"airport_iata_code":"COU", "airport_name":"Columbia Regional Airport", "city_name":"Columbia"},
 {"airport_iata_code":"CPR", "airport_name":"Casper-Natrona County International Airport", "city_name":"Casper"},
 {"airport_iata_code":"KCR", "airport_name":"Colorado Creek Airport", "city_name":"Colorado Creek"},
 {"airport_iata_code":"CRO", "airport_name":"Corcoran Airport", "city_name":"Corcoran"},
 {"airport_iata_code":"CRP", "airport_name":"Corpus Christi International Airport", "city_name":"Corpus Christi"},
 {"airport_iata_code":"CRW", "airport_name":"Yeager Airport", "city_name":"Charleston"},
 {"airport_iata_code":"CSG", "airport_name":"Columbus Metropolitan Airport", "city_name":"Columbus"},
 {"airport_iata_code":"CVG", "airport_name":"Cincinnati Northern Kentucky International Airport", "city_name":"Cincinnati"},
 {"airport_iata_code":"CVN", "airport_name":"Clovis Municipal Airport", "city_name":"Clovis"},
 {"airport_iata_code":"KIP", "airport_name":"Kickapoo Downtown Airport", "city_name":"Wichita Falls"},
 {"airport_iata_code":"CWF", "airport_name":"Chennault International Airport", "city_name":"Lake Charles"},
 {"airport_iata_code":"CYS", "airport_name":"Cheyenne Regional Jerry Olson Field", "city_name":"Cheyenne"},
 {"airport_iata_code":"DAA", "airport_name":"Davison Army Air Field", "city_name":"Fort Belvoir"},
 {"airport_iata_code":"DAB", "airport_name":"Daytona Beach International Airport", "city_name":"Daytona Beach"},
 {"airport_iata_code":"DAG", "airport_name":"Barstow Daggett Airport", "city_name":"Daggett"},
 {"airport_iata_code":"DAL", "airport_name":"Dallas Love Field", "city_name":"Dallas"},
 {"airport_iata_code":"DAN", "airport_name":"Danville Regional Airport", "city_name":"Danville"},
 {"airport_iata_code":"DAY", "airport_name":"James M Cox Dayton International Airport", "city_name":"Dayton"},
 {"airport_iata_code":"DBN", "airport_name":"W H 'Bud' Barron Airport", "city_name":"Dublin"},
 {"airport_iata_code":"DBQ", "airport_name":"Dubuque Regional Airport", "city_name":"Dubuque"},
 {"airport_iata_code":"DCA", "airport_name":"Ronald Reagan Washington National Airport", "city_name":"Washington"},
 {"airport_iata_code":"DCU", "airport_name":"Pryor Field Regional Airport", "city_name":"Decatur"},
 {"airport_iata_code":"DDC", "airport_name":"Dodge City Regional Airport", "city_name":"Dodge City"},
 {"airport_iata_code":"DEC", "airport_name":"Decatur Airport", "city_name":"Decatur"},
 {"airport_iata_code":"DEH", "airport_name":"Decorah Municipal Airport", "city_name":"Decorah"},
 {"airport_iata_code":"DEN", "airport_name":"Denver International Airport", "city_name":"Denver"},
 {"airport_iata_code":"DET", "airport_name":"Coleman A. Young Municipal Airport", "city_name":"Detroit"},
 {"airport_iata_code":"DFI", "airport_name":"Defiance Memorial Airport", "city_name":"Defiance"},
 {"airport_iata_code":"DFW", "airport_name":"Dallas Fort Worth International Airport", "city_name":"Dallas-Fort Worth"},
 {"airport_iata_code":"DGL", "airport_name":"Douglas Municipal Airport", "city_name":"Douglas"},
 {"airport_iata_code":"DGW", "airport_name":"Converse County Airport", "city_name":"Douglas"},
 {"airport_iata_code":"DHN", "airport_name":"Dothan Regional Airport", "city_name":"Dothan"},
 {"airport_iata_code":"DHT", "airport_name":"Dalhart Municipal Airport", "city_name":"Dalhart"},
 {"airport_iata_code":"DIK", "airport_name":"Dickinson Theodore Roosevelt Regional Airport", "city_name":"Dickinson"},
 {"airport_iata_code":"DLH", "airport_name":"Duluth International Airport", "city_name":"Duluth"},
 {"airport_iata_code":"DNL", "airport_name":"Daniel Field", "city_name":"Augusta"},
 {"airport_iata_code":"DPA", "airport_name":"Dupage Airport", "city_name":"Chicago/West Chicago"},
 {"airport_iata_code":"DPG", "airport_name":"Michael AAF (Dugway Proving Ground) Airport", "city_name":"Dugway Proving Ground"},
 {"airport_iata_code":"DRO", "airport_name":"Durango La Plata County Airport", "city_name":"Durango"},
 {"airport_iata_code":"DRT", "airport_name":"Del Rio International Airport", "city_name":"Del Rio"},
 {"airport_iata_code":"DSM", "airport_name":"Des Moines International Airport", "city_name":"Des Moines"},
 {"airport_iata_code":"DSV", "airport_name":"Dansville Municipal Airport", "city_name":"Dansville"},
 {"airport_iata_code":"DTA", "airport_name":"Delta Municipal Airport", "city_name":"Delta"},
 {"airport_iata_code":"DTL", "airport_name":"Detroit Lakes Airport - Wething Field", "city_name":"Detroit Lakes"},
 {"airport_iata_code":"DTN", "airport_name":"Shreveport Downtown Airport", "city_name":"Shreveport"},
 {"airport_iata_code":"DSI", "airport_name":"Destin Fort Walton Beach Airport", "city_name":"Destin"},
 {"airport_iata_code":"DTW", "airport_name":"Detroit Metropolitan Wayne County Airport", "city_name":"Detroit"},
 {"airport_iata_code":"DUA", "airport_name":"Eaker Field", "city_name":"Durant"},
 {"airport_iata_code":"DUC", "airport_name":"Halliburton Field", "city_name":"Duncan"},
 {"airport_iata_code":"DUG", "airport_name":"Bisbee Douglas International Airport", "city_name":"Douglas Bisbee"},
 {"airport_iata_code":"DUJ", "airport_name":"DuBois Regional Airport", "city_name":"Dubois"},
 {"airport_iata_code":"DVL", "airport_name":"Devils Lake Regional Airport", "city_name":"Devils Lake"},
 {"airport_iata_code":"DVN", "airport_name":"Davenport Municipal Airport", "city_name":"Davenport"},
 {"airport_iata_code":"DVT", "airport_name":"Phoenix Deer Valley Airport", "city_name":"Phoenix"},
 {"airport_iata_code":"DWH", "airport_name":"David Wayne Hooks Memorial Airport", "city_name":"Houston"},
 {"airport_iata_code":"DXR", "airport_name":"Danbury Municipal Airport", "city_name":"Danbury"},
 {"airport_iata_code":"DYL", "airport_name":"Doylestown Airport", "city_name":"Doylestown"},
 {"airport_iata_code":"DYS", "airport_name":"Dyess Air Force Base", "city_name":"Abilene"},
 {"airport_iata_code":"CCG", "airport_name":"Crane County Airport", "city_name":"Crane"},
 {"airport_iata_code":"BGQ", "airport_name":"Reagan County Airport", "city_name":"Big Lake"},
 {"airport_iata_code":"BGT", "airport_name":"Bagdad Airport", "city_name":"Bagdad"},
 {"airport_iata_code":"EAR", "airport_name":"Kearney Regional Airport", "city_name":"Kearney"},
 {"airport_iata_code":"EAU", "airport_name":"Chippewa Valley Regional Airport", "city_name":"Eau Claire"},
 {"airport_iata_code":"EBS", "airport_name":"Webster City Municipal Airport", "city_name":"Webster City"},
 {"airport_iata_code":"ECG", "airport_name":"Elizabeth City Regional Airport & Coast Guard Air Station", "city_name":"Elizabeth City"},
 {"airport_iata_code":"ECP", "airport_name":"Northwest Florida Beaches International Airport", "city_name":"Panama City Beach"},
 {"airport_iata_code":"EEN", "airport_name":"Dillant Hopkins Airport", "city_name":"Keene"},
 {"airport_iata_code":"EFK", "airport_name":"Newport State Airport", "city_name":"Newport"},
 {"airport_iata_code":"EGE", "airport_name":"Eagle County Regional Airport", "city_name":"Eagle"},
 {"airport_iata_code":"KEK", "airport_name":"Ekwok Airport", "city_name":"Ekwok"},
 {"airport_iata_code":"EKO", "airport_name":"Elko Regional Airport", "city_name":"Elko"},
 {"airport_iata_code":"ELD", "airport_name":"South Arkansas Regional At Goodwin Field", "city_name":"El Dorado"},
 {"airport_iata_code":"ELM", "airport_name":"Elmira Corning Regional Airport", "city_name":"Elmira/Corning"},
 {"airport_iata_code":"ELP", "airport_name":"El Paso International Airport", "city_name":"El Paso"},
 {"airport_iata_code":"END", "airport_name":"Vance Air Force Base", "city_name":"Enid"},
 {"airport_iata_code":"ERI", "airport_name":"Erie International Tom Ridge Field", "city_name":"Erie"},
 {"airport_iata_code":"EUG", "airport_name":"Mahlon Sweet Field", "city_name":"Eugene"},
 {"airport_iata_code":"EVV", "airport_name":"Evansville Regional Airport", "city_name":"Evansville"},
 {"airport_iata_code":"EVW", "airport_name":"Evanston-Uinta County Airport-Burns Field", "city_name":"Evanston"},
 {"airport_iata_code":"EWB", "airport_name":"New Bedford Regional Airport", "city_name":"New Bedford"},
 {"airport_iata_code":"EWK", "airport_name":"Newton City-County Airport", "city_name":"Newton"},
 {"airport_iata_code":"EWN", "airport_name":"Coastal Carolina Regional Airport", "city_name":"New Bern"},
 {"airport_iata_code":"EWR", "airport_name":"Newark Liberty International Airport", "city_name":"Newark"},
 {"airport_iata_code":"EYW", "airport_name":"Key West International Airport", "city_name":"Key West"},
 {"airport_iata_code":"FAR", "airport_name":"Hector International Airport", "city_name":"Fargo"},
 {"airport_iata_code":"FAT", "airport_name":"Fresno Yosemite International Airport", "city_name":"Fresno"},
 {"airport_iata_code":"FAY", "airport_name":"Fayetteville Regional Grannis Field", "city_name":"Fayetteville"},
 {"airport_iata_code":"FBG", "airport_name":"Simmons Army Air Field", "city_name":"Fort Bragg"},
 {"airport_iata_code":"FCS", "airport_name":"Butts AAF (Fort Carson) Air Field", "city_name":"Fort Carson"},
 {"airport_iata_code":"FRD", "airport_name":"Friday Harbor Airport", "city_name":"Friday Harbor"},
 {"airport_iata_code":"FHU", "airport_name":"Sierra Vista Municipal Libby Army Air Field", "city_name":"Fort Huachuca Sierra Vista"},
 {"airport_iata_code":"FLG", "airport_name":"Flagstaff Pulliam Airport", "city_name":"Flagstaff"},
 {"airport_iata_code":"FLL", "airport_name":"Fort Lauderdale Hollywood International Airport", "city_name":"Fort Lauderdale"},
 {"airport_iata_code":"FLO", "airport_name":"Florence Regional Airport", "city_name":"Florence"},
 {"airport_iata_code":"FNT", "airport_name":"Bishop International Airport", "city_name":"Flint"},
 {"airport_iata_code":"FOD", "airport_name":"Fort Dodge Regional Airport", "city_name":"Fort Dodge"},
 {"airport_iata_code":"FOE", "airport_name":"Forbes Field", "city_name":"Topeka"},
 {"airport_iata_code":"FIL", "airport_name":"Fillmore Municipal Airport", "city_name":"Fillmore"},
 {"airport_iata_code":"FPR", "airport_name":"St Lucie County International Airport", "city_name":"Fort Pierce"},
 {"airport_iata_code":"FRH", "airport_name":"French Lick Municipal Airport", "city_name":"French Lick"},
 {"airport_iata_code":"FRI", "airport_name":"Marshall Army Air Field", "city_name":"Fort Riley(Junction City)"},
 {"airport_iata_code":"FRM", "airport_name":"Fairmont Municipal Airport", "city_name":"Fairmont"},
 {"airport_iata_code":"FSD", "airport_name":"Joe Foss Field Airport", "city_name":"Sioux Falls"},
 {"airport_iata_code":"FSM", "airport_name":"Fort Smith Regional Airport", "city_name":"Fort Smith"},
 {"airport_iata_code":"FMS", "airport_name":"Fort Madison Municipal Airport", "city_name":"Fort Madison"},
 {"airport_iata_code":"FTW", "airport_name":"Fort Worth Meacham International Airport", "city_name":"Fort Worth"},
 {"airport_iata_code":"FUL", "airport_name":"Fullerton Municipal Airport", "city_name":"Fullerton"},
 {"airport_iata_code":"FWA", "airport_name":"Fort Wayne International Airport", "city_name":"Fort Wayne"},
 {"airport_iata_code":"FYV", "airport_name":"Drake Field", "city_name":"Fayetteville"},
 {"airport_iata_code":"GAG", "airport_name":"Gage Airport", "city_name":"Gage"},
 {"airport_iata_code":"GBD", "airport_name":"Great Bend Municipal Airport", "city_name":"Great Bend"},
 {"airport_iata_code":"GCK", "airport_name":"Garden City Regional Airport", "city_name":"Garden City"},
 {"airport_iata_code":"GCN", "airport_name":"Grand Canyon National Park Airport", "city_name":"Grand Canyon"},
 {"airport_iata_code":"GDV", "airport_name":"Dawson Community Airport", "city_name":"Glendive"},
 {"airport_iata_code":"GEG", "airport_name":"Spokane International Airport", "city_name":"Spokane"},
 {"airport_iata_code":"GFK", "airport_name":"Grand Forks International Airport", "city_name":"Grand Forks"},
 {"airport_iata_code":"GGG", "airport_name":"East Texas Regional Airport", "city_name":"Longview"},
 {"airport_iata_code":"GGW", "airport_name":"Wokal Field Glasgow International Airport", "city_name":"Glasgow"},
 {"airport_iata_code":"GJT", "airport_name":"Grand Junction Regional Airport", "city_name":"Grand Junction"},
 {"airport_iata_code":"GLH", "airport_name":"Mid Delta Regional Airport", "city_name":"Greenville"},
 {"airport_iata_code":"GLS", "airport_name":"Scholes International At Galveston Airport", "city_name":"Galveston"},
 {"airport_iata_code":"GNV", "airport_name":"Gainesville Regional Airport", "city_name":"Gainesville"},
 {"airport_iata_code":"GON", "airport_name":"Groton New London Airport", "city_name":"Groton (New London)"},
 {"airport_iata_code":"FCA", "airport_name":"Glacier Park International Airport", "city_name":"Kalispell"},
 {"airport_iata_code":"GPT", "airport_name":"Gulfport Biloxi International Airport", "city_name":"Gulfport"},
 {"airport_iata_code":"GRB", "airport_name":"Austin Straubel International Airport", "city_name":"Green Bay"},
 {"airport_iata_code":"GRI", "airport_name":"Central Nebraska Regional Airport", "city_name":"Grand Island"},
 {"airport_iata_code":"GRK", "airport_name":"Robert Gray  Army Air Field Airport", "city_name":"Fort Hood/Killeen"},
 {"airport_iata_code":"GRR", "airport_name":"Gerald R. Ford International Airport", "city_name":"Grand Rapids"},
 {"airport_iata_code":"GSO", "airport_name":"Piedmont Triad International Airport", "city_name":"Greensboro"},
 {"airport_iata_code":"GSP", "airport_name":"Greenville Spartanburg International Airport", "city_name":"Greenville"},
 {"airport_iata_code":"GTF", "airport_name":"Great Falls International Airport", "city_name":"Great Falls"},
 {"airport_iata_code":"GTR", "airport_name":"Golden Triangle Regional Airport", "city_name":"Columbus/W Point/Starkville"},
 {"airport_iata_code":"GUP", "airport_name":"Gallup Municipal Airport", "city_name":"Gallup"},
 {"airport_iata_code":"GVT", "airport_name":"Majors Airport", "city_name":"Greenville"},
 {"airport_iata_code":"KGX", "airport_name":"Grayling Airport", "city_name":"Grayling"},
 {"airport_iata_code":"GYY", "airport_name":"Gary Chicago International Airport", "city_name":"Gary"},
 {"airport_iata_code":"HDN", "airport_name":"Yampa Valley Airport", "city_name":"Hayden"},
 {"airport_iata_code":"HGR", "airport_name":"Hagerstown Regional Richard A Henson Field", "city_name":"Hagerstown"},
 {"airport_iata_code":"HIB", "airport_name":"Chisholm Hibbing Airport", "city_name":"Hibbing"},
 {"airport_iata_code":"HKY", "airport_name":"Hickory Regional Airport", "city_name":"Hickory"},
 {"airport_iata_code":"HLN", "airport_name":"Helena Regional Airport", "city_name":"Helena"},
 {"airport_iata_code":"HON", "airport_name":"Huron Regional Airport", "city_name":"Huron"},
 {"airport_iata_code":"HOT", "airport_name":"Memorial Field", "city_name":"Hot Springs"},
 {"airport_iata_code":"HOU", "airport_name":"William P Hobby Airport", "city_name":"Houston"},
 {"airport_iata_code":"HPN", "airport_name":"Westchester County Airport", "city_name":"White Plains"},
 {"airport_iata_code":"HRL", "airport_name":"Valley International Airport", "city_name":"Harlingen"},
 {"airport_iata_code":"HRO", "airport_name":"Boone County Airport", "city_name":"Harrison"},
 {"airport_iata_code":"HNC", "airport_name":"Billy Mitchell Airport", "city_name":"Hatteras"},
 {"airport_iata_code":"HSV", "airport_name":"Huntsville International Carl T Jones Field", "city_name":"Huntsville"},
 {"airport_iata_code":"HTS", "airport_name":"Tri-State/Milton J. Ferguson Field", "city_name":"Huntington"},
 {"airport_iata_code":"HUF", "airport_name":"Terre Haute International Hulman Field", "city_name":"Terre Haute"},
 {"airport_iata_code":"HVN", "airport_name":"Tweed New Haven Airport", "city_name":"New Haven"},
 {"airport_iata_code":"HVR", "airport_name":"Havre City County Airport", "city_name":"Havre"},
 {"airport_iata_code":"HYA", "airport_name":"Barnstable Municipal Boardman Polando Field", "city_name":"Hyannis"},
 {"airport_iata_code":"HYS", "airport_name":"Hays Regional Airport", "city_name":"Hays"},
 {"airport_iata_code":"IAB", "airport_name":"Mc Connell Air Force Base", "city_name":"Wichita"},
 {"airport_iata_code":"IAD", "airport_name":"Washington Dulles International Airport", "city_name":"Washington"},
 {"airport_iata_code":"IAG", "airport_name":"Niagara Falls International Airport", "city_name":"Niagara Falls"},
 {"airport_iata_code":"IAH", "airport_name":"George Bush Intercontinental Houston Airport", "city_name":"Houston"},
 {"airport_iata_code":"ICT", "airport_name":"Wichita Mid Continent Airport", "city_name":"Wichita"},
 {"airport_iata_code":"IDA", "airport_name":"Idaho Falls Regional Airport", "city_name":"Idaho Falls"},
 {"airport_iata_code":"XPR", "airport_name":"Pine Ridge Airport", "city_name":"Pine Ridge"},
 {"airport_iata_code":"IFP", "airport_name":"Laughlin Bullhead International Airport", "city_name":"Bullhead City"},
 {"airport_iata_code":"IGM", "airport_name":"Kingman Airport", "city_name":"Kingman"},
 {"airport_iata_code":"ILG", "airport_name":"New Castle Airport", "city_name":"Wilmington"},
 {"airport_iata_code":"ILM", "airport_name":"Wilmington International Airport", "city_name":"Wilmington"},
 {"airport_iata_code":"IMT", "airport_name":"Ford Airport", "city_name":"Iron Mountain Kingsford"},
 {"airport_iata_code":"IND", "airport_name":"Indianapolis International Airport", "city_name":"Indianapolis"},
 {"airport_iata_code":"INL", "airport_name":"Falls International Airport", "city_name":"International Falls"},
 {"airport_iata_code":"INT", "airport_name":"Smith Reynolds Airport", "city_name":"Winston Salem"},
 {"airport_iata_code":"IPL", "airport_name":"Imperial County Airport", "city_name":"Imperial"},
 {"airport_iata_code":"IPT", "airport_name":"Williamsport Regional Airport", "city_name":"Williamsport"},
 {"airport_iata_code":"IRK", "airport_name":"Kirksville Regional Airport", "city_name":"Kirksville"},
 {"airport_iata_code":"ISN", "airport_name":"Sloulin Field International Airport", "city_name":"Williston"},
 {"airport_iata_code":"ISP", "airport_name":"Long Island Mac Arthur Airport", "city_name":"Islip"},
 {"airport_iata_code":"ITH", "airport_name":"Ithaca Tompkins Regional Airport", "city_name":"Ithaca"},
 {"airport_iata_code":"AZA", "airport_name":"Phoenix-Mesa-Gateway Airport", "city_name":"Phoenix"},
 {"airport_iata_code":"IYK", "airport_name":"Inyokern Airport", "city_name":"Inyokern"},
 {"airport_iata_code":"JAC", "airport_name":"Jackson Hole Airport", "city_name":"Jackson"},
 {"airport_iata_code":"JAN", "airport_name":"Jackson Evers International Airport", "city_name":"Jackson"},
 {"airport_iata_code":"JAX", "airport_name":"Jacksonville International Airport", "city_name":"Jacksonville"},
 {"airport_iata_code":"JBR", "airport_name":"Jonesboro Municipal Airport", "city_name":"Jonesboro"},
 {"airport_iata_code":"JFK", "airport_name":"John F Kennedy International Airport", "city_name":"New York"},
 {"airport_iata_code":"JLN", "airport_name":"Joplin Regional Airport", "city_name":"Joplin"},
 {"airport_iata_code":"JMS", "airport_name":"Jamestown Regional Airport", "city_name":"Jamestown"},
 {"airport_iata_code":"JST", "airport_name":"John Murtha Johnstown Cambria County Airport", "city_name":"Johnstown"},
 {"airport_iata_code":"KKU", "airport_name":"Ekuk Airport", "city_name":"Ekuk"},
 {"airport_iata_code":"BXS", "airport_name":"Borrego Valley Airport", "city_name":"Borrego Springs"},
 {"airport_iata_code":"LAF", "airport_name":"Purdue University Airport", "city_name":"Lafayette"},
 {"airport_iata_code":"LAN", "airport_name":"Capital City Airport", "city_name":"Lansing"},
 {"airport_iata_code":"LAR", "airport_name":"Laramie Regional Airport", "city_name":"Laramie"},
 {"airport_iata_code":"LAS", "airport_name":"McCarran International Airport", "city_name":"Las Vegas"},
 {"airport_iata_code":"LAX", "airport_name":"Los Angeles International Airport", "city_name":"Los Angeles"},
 {"airport_iata_code":"LBB", "airport_name":"Lubbock Preston Smith International Airport", "city_name":"Lubbock"},
 {"airport_iata_code":"LBE", "airport_name":"Arnold Palmer Regional Airport", "city_name":"Latrobe"},
 {"airport_iata_code":"LBF", "airport_name":"North Platte Regional Airport Lee Bird Field", "city_name":"North Platte"},
 {"airport_iata_code":"LBL", "airport_name":"Liberal Mid-America Regional Airport", "city_name":"Liberal"},
 {"airport_iata_code":"LCH", "airport_name":"Lake Charles Regional Airport", "city_name":"Lake Charles"},
 {"airport_iata_code":"LEB", "airport_name":"Lebanon Municipal Airport", "city_name":"Lebanon"},
 {"airport_iata_code":"LEX", "airport_name":"Blue Grass Airport", "city_name":"Lexington"},
 {"airport_iata_code":"LFT", "airport_name":"Lafayette Regional Airport", "city_name":"Lafayette"},
 {"airport_iata_code":"LGA", "airport_name":"La Guardia Airport", "city_name":"New York"},
 {"airport_iata_code":"LGB", "airport_name":"Long Beach /Daugherty Field/ Airport", "city_name":"Long Beach"},
 {"airport_iata_code":"LIT", "airport_name":"Adams Field", "city_name":"Little Rock"},
 {"airport_iata_code":"LMS", "airport_name":"Louisville Winston County Airport", "city_name":"Louisville"},
 {"airport_iata_code":"LMT", "airport_name":"Klamath Falls Airport", "city_name":"Klamath Falls"},
 {"airport_iata_code":"LNA", "airport_name":"Palm Beach County Park Airport", "city_name":"West Palm Beach"},
 {"airport_iata_code":"LND", "airport_name":"Hunt Field", "city_name":"Lander"},
 {"airport_iata_code":"LNK", "airport_name":"Lincoln Airport", "city_name":"Lincoln"},
 {"airport_iata_code":"LNN", "airport_name":"Willoughby Lost Nation Municipal Airport", "city_name":"Willoughby"},
 {"airport_iata_code":"LNP", "airport_name":"Lonesome Pine Airport", "city_name":"Wise"},
 {"airport_iata_code":"LNR", "airport_name":"Tri-County Regional Airport", "city_name":"Lone Rock"},
 {"airport_iata_code":"LNS", "airport_name":"Lancaster Airport", "city_name":"Lancaster"},
 {"airport_iata_code":"BBX", "airport_name":"Wings Field", "city_name":"Philadelphia"},
 {"airport_iata_code":"LRD", "airport_name":"Laredo International Airport", "city_name":"Laredo"},
 {"airport_iata_code":"LRU", "airport_name":"Las Cruces International Airport", "city_name":"Las Cruces"},
 {"airport_iata_code":"LSE", "airport_name":"La Crosse Municipal Airport", "city_name":"La Crosse"},
 {"airport_iata_code":"LUK", "airport_name":"Cincinnati Municipal Airport Lunken Field", "city_name":"Cincinnati"},
 {"airport_iata_code":"LWB", "airport_name":"Greenbrier Valley Airport", "city_name":"Lewisburg"},
 {"airport_iata_code":"LWS", "airport_name":"Lewiston Nez Perce County Airport", "city_name":"Lewiston"},
 {"airport_iata_code":"LWT", "airport_name":"Lewistown Municipal Airport", "city_name":"Lewistown"},
 {"airport_iata_code":"LYH", "airport_name":"Lynchburg Regional Preston Glenn Field", "city_name":"Lynchburg"},
 {"airport_iata_code":"LZU", "airport_name":"Gwinnett County Briscoe Field", "city_name":"Lawrenceville"},
 {"airport_iata_code":"MAF", "airport_name":"Midland International Airport", "city_name":"Midland"},
 {"airport_iata_code":"MBS", "airport_name":"MBS International Airport", "city_name":"Saginaw"},
 {"airport_iata_code":"MCE", "airport_name":"Merced Regional Macready Field", "city_name":"Merced"},
 {"airport_iata_code":"MCI", "airport_name":"Kansas City International Airport", "city_name":"Kansas City"},
 {"airport_iata_code":"MCN", "airport_name":"Middle Georgia Regional Airport", "city_name":"Macon"},
 {"airport_iata_code":"MCO", "airport_name":"Orlando International Airport", "city_name":"Orlando"},
 {"airport_iata_code":"MCW", "airport_name":"Mason City Municipal Airport", "city_name":"Mason City"},
 {"airport_iata_code":"MDH", "airport_name":"Southern Illinois Airport", "city_name":"Carbondale/Murphysboro"},
 {"airport_iata_code":"MDT", "airport_name":"Harrisburg International Airport", "city_name":"Harrisburg"},
 {"airport_iata_code":"MDW", "airport_name":"Chicago Midway International Airport", "city_name":"Chicago"},
 {"airport_iata_code":"MEI", "airport_name":"Key Field", "city_name":"Meridian"},
 {"airport_iata_code":"MEM", "airport_name":"Memphis International Airport", "city_name":"Memphis"},
 {"airport_iata_code":"MFE", "airport_name":"Mc Allen Miller International Airport", "city_name":"Mc Allen"},
 {"airport_iata_code":"MFR", "airport_name":"Rogue Valley International Medford Airport", "city_name":"Medford"},
 {"airport_iata_code":"MGM", "airport_name":"Montgomery Regional (Dannelly Field) Airport", "city_name":"Montgomery"},
 {"airport_iata_code":"MGW", "airport_name":"Morgantown Municipal Walter L. Bill Hart Field", "city_name":"Morgantown"},
 {"airport_iata_code":"MHK", "airport_name":"Manhattan Regional Airport", "city_name":"Manhattan"},
 {"airport_iata_code":"MHT", "airport_name":"Manchester Airport", "city_name":"Manchester"},
 {"airport_iata_code":"MHV", "airport_name":"Mojave Airport", "city_name":"Mojave"},
 {"airport_iata_code":"MIA", "airport_name":"Miami International Airport", "city_name":"Miami"},
 {"airport_iata_code":"MIE", "airport_name":"Delaware County Johnson Field", "city_name":"Muncie"},
 {"airport_iata_code":"MKC", "airport_name":"Charles B. Wheeler Downtown Airport", "city_name":"Kansas City"},
 {"airport_iata_code":"MKE", "airport_name":"General Mitchell International Airport", "city_name":"Milwaukee"},
 {"airport_iata_code":"MKG", "airport_name":"Muskegon County Airport", "city_name":"Muskegon"},
 {"airport_iata_code":"MKL", "airport_name":"Mc Kellar Sipes Regional Airport", "city_name":"Jackson"},
 {"airport_iata_code":"MLB", "airport_name":"Melbourne International Airport", "city_name":"Melbourne"},
 {"airport_iata_code":"MLI", "airport_name":"Quad City International Airport", "city_name":"Moline"},
 {"airport_iata_code":"MLS", "airport_name":"Frank Wiley Field", "city_name":"Miles City"},
 {"airport_iata_code":"MLU", "airport_name":"Monroe Regional Airport", "city_name":"Monroe"},
 {"airport_iata_code":"MMH", "airport_name":"Mammoth Yosemite Airport", "city_name":"Mammoth Lakes"},
 {"airport_iata_code":"MMU", "airport_name":"Morristown Municipal Airport", "city_name":"Morristown"},
 {"airport_iata_code":"MOB", "airport_name":"Mobile Regional Airport", "city_name":"Mobile"},
 {"airport_iata_code":"MOD", "airport_name":"Modesto City Co-Harry Sham Field", "city_name":"Modesto"},
 {"airport_iata_code":"MOT", "airport_name":"Minot International Airport", "city_name":"Minot"},
 {"airport_iata_code":"RMY", "airport_name":"Mariposa Yosemite Airport", "city_name":"Mariposa"},
 {"airport_iata_code":"MPV", "airport_name":"Edward F Knapp State Airport", "city_name":"Barre/Montpelier"},
 {"airport_iata_code":"MRY", "airport_name":"Monterey Peninsula Airport", "city_name":"Monterey"},
 {"airport_iata_code":"MSL", "airport_name":"Northwest Alabama Regional Airport", "city_name":"Muscle Shoals"},
 {"airport_iata_code":"MSN", "airport_name":"Dane County Regional Truax Field", "city_name":"Madison"},
 {"airport_iata_code":"MSO", "airport_name":"Missoula International Airport", "city_name":"Missoula"},
 {"airport_iata_code":"MSP", "airport_name":"Minneapolis-St Paul International/Wold-Chamberlain Airport", "city_name":"Minneapolis"},
 {"airport_iata_code":"MSS", "airport_name":"Massena International Richards Field", "city_name":"Massena"},
 {"airport_iata_code":"MSY", "airport_name":"Louis Armstrong New Orleans International Airport", "city_name":"New Orleans"},
 {"airport_iata_code":"MTJ", "airport_name":"Montrose Regional Airport", "city_name":"Montrose"},
 {"airport_iata_code":"MVL", "airport_name":"Morrisville Stowe State Airport", "city_name":"Morrisville"},
 {"airport_iata_code":"MVY", "airport_name":"Martha's Vineyard Airport", "city_name":"Martha's Vineyard"},
 {"airport_iata_code":"MYR", "airport_name":"Myrtle Beach International Airport", "city_name":"Myrtle Beach"},
 {"airport_iata_code":"MZZ", "airport_name":"Marion Municipal Airport", "city_name":"Marion"},
 {"airport_iata_code":"BFT", "airport_name":"Beaufort MCAS - Merritt Field", "city_name":"Beaufort"},
 {"airport_iata_code":"DGN", "airport_name":"Dahlgren Naval Surface Warfare Center Airport", "city_name":"Dahlgren"},
 {"airport_iata_code":"NEL", "airport_name":"Lakehurst NAES /Maxfield Field", "city_name":"Lakehurst"},
 {"airport_iata_code":"YUM", "airport_name":"Yuma MCAS/Yuma International Airport", "city_name":"Yuma"},
 {"airport_iata_code":"NZY", "airport_name":"North Island Naval Air Station-Halsey Field", "city_name":"San Diego"},
 {"airport_iata_code":"NVN", "airport_name":"Nervino Airport", "city_name":"Beckwourth"},
 {"airport_iata_code":"ODC", "airport_name":"Oakdale Airport", "city_name":"Oakdale"},
 {"airport_iata_code":"EYR", "airport_name":"Yerington Municipal Airport", "city_name":"Yerington"},
 {"airport_iata_code":"OAJ", "airport_name":"Albert J Ellis Airport", "city_name":"Jacksonville"},
 {"airport_iata_code":"OAK", "airport_name":"Metropolitan Oakland International Airport", "city_name":"Oakland"},
 {"airport_iata_code":"OAR", "airport_name":"Marina Municipal Airport", "city_name":"Marina"},
 {"airport_iata_code":"OGS", "airport_name":"Ogdensburg International Airport", "city_name":"Ogdensburg"},
 {"airport_iata_code":"OKC", "airport_name":"Will Rogers World Airport", "city_name":"Oklahoma City"},
 {"airport_iata_code":"OLD", "airport_name":"Dewitt Field,Old Town Municipal Airport", "city_name":"Old Town"},
 {"airport_iata_code":"OLM", "airport_name":"Olympia Regional Airport", "city_name":"Olympia"},
 {"airport_iata_code":"OMA", "airport_name":"Eppley Airfield", "city_name":"Omaha"},
 {"airport_iata_code":"ONT", "airport_name":"Ontario International Airport", "city_name":"Ontario"},
 {"airport_iata_code":"ORD", "airport_name":"Chicago O'Hare International Airport", "city_name":"Chicago"},
 {"airport_iata_code":"ORF", "airport_name":"Norfolk International Airport", "city_name":"Norfolk"},
 {"airport_iata_code":"ORH", "airport_name":"Worcester Regional Airport", "city_name":"Worcester"},
 {"airport_iata_code":"ESD", "airport_name":"Orcas Island Airport", "city_name":"Eastsound"},
 {"airport_iata_code":"OSH", "airport_name":"Wittman Regional Airport", "city_name":"Oshkosh"},
 {"airport_iata_code":"OTH", "airport_name":"Southwest Oregon Regional Airport", "city_name":"North Bend"},
 {"airport_iata_code":"OTM", "airport_name":"Ottumwa Regional Airport", "city_name":"Ottumwa"},
 {"airport_iata_code":"OWB", "airport_name":"Owensboro Daviess County Airport", "city_name":"Owensboro"},
 {"airport_iata_code":"BSQ", "airport_name":"Bisbee Municipal Airport", "city_name":"Bisbee"},
 {"airport_iata_code":"HBK", "airport_name":"Holbrook Municipal Airport", "city_name":"Holbrook"},
 {"airport_iata_code":"CWX", "airport_name":"Cochise County Airport", "city_name":"Willcox"},
 {"airport_iata_code":"PAE", "airport_name":"Snohomish County (Paine Field) Airport", "city_name":"Everett"},
 {"airport_iata_code":"PAH", "airport_name":"Barkley Regional Airport", "city_name":"Paducah"},
 {"airport_iata_code":"PBG", "airport_name":"Plattsburgh International Airport", "city_name":"Plattsburgh"},
 {"airport_iata_code":"PBI", "airport_name":"Palm Beach International Airport", "city_name":"West Palm Beach"},
 {"airport_iata_code":"PDK", "airport_name":"DeKalb Peachtree Airport", "city_name":"Atlanta"},
 {"airport_iata_code":"PDX", "airport_name":"Portland International Airport", "city_name":"Portland"},
 {"airport_iata_code":"PFN", "airport_name":"Panama City-Bay Co International Airport", "city_name":"Panama City"},
 {"airport_iata_code":"PGA", "airport_name":"Page Municipal Airport", "city_name":"Page"},
 {"airport_iata_code":"PGV", "airport_name":"Pitt Greenville Airport", "city_name":"Greenville"},
 {"airport_iata_code":"PHF", "airport_name":"Newport News Williamsburg International Airport", "city_name":"Newport News"},
 {"airport_iata_code":"ADR", "airport_name":"Robert F Swinnie Airport", "city_name":"Andrews"},
 {"airport_iata_code":"PHL", "airport_name":"Philadelphia International Airport", "city_name":"Philadelphia"},
 {"airport_iata_code":"PHX", "airport_name":"Phoenix Sky Harbor International Airport", "city_name":"Phoenix"},
 {"airport_iata_code":"PIA", "airport_name":"Greater Peoria Regional Airport", "city_name":"Peoria"},
 {"airport_iata_code":"PIB", "airport_name":"Hattiesburg Laurel Regional Airport", "city_name":"Hattiesburg/Laurel"},
 {"airport_iata_code":"PIE", "airport_name":"St Petersburg Clearwater International Airport", "city_name":"St Petersburg-Clearwater"},
 {"airport_iata_code":"PIH", "airport_name":"Pocatello Regional Airport", "city_name":"Pocatello"},
 {"airport_iata_code":"PIR", "airport_name":"Pierre Regional Airport", "city_name":"Pierre"},
 {"airport_iata_code":"PIT", "airport_name":"Pittsburgh International Airport", "city_name":"Pittsburgh"},
 {"airport_iata_code":"PKB", "airport_name":"Mid Ohio Valley Regional Airport", "city_name":"Parkersburg"},
 {"airport_iata_code":"PLN", "airport_name":"Pellston Regional Airport of Emmet County Airport", "city_name":"Pellston"},
 {"airport_iata_code":"PMD", "airport_name":"Palmdale Regional/USAF Plant 42 Airport", "city_name":"Palmdale"},
 {"airport_iata_code":"PNS", "airport_name":"Pensacola Regional Airport", "city_name":"Pensacola"},
 {"airport_iata_code":"POU", "airport_name":"Dutchess County Airport", "city_name":"Poughkeepsie"},
 {"airport_iata_code":"PQI", "airport_name":"Northern Maine Regional Airport at Presque Isle", "city_name":"Presque Isle"},
 {"airport_iata_code":"PRC", "airport_name":"Ernest A. Love Field", "city_name":"Prescott"},
 {"airport_iata_code":"PSC", "airport_name":"Tri Cities Airport", "city_name":"Pasco"},
 {"airport_iata_code":"PSM", "airport_name":"Portsmouth International at Pease Airport", "city_name":"Portsmouth"},
 {"airport_iata_code":"PSP", "airport_name":"Palm Springs International Airport", "city_name":"Palm Springs"},
 {"airport_iata_code":"PUB", "airport_name":"Pueblo Memorial Airport", "city_name":"Pueblo"},
 {"airport_iata_code":"PUW", "airport_name":"Pullman Moscow Regional Airport", "city_name":"Pullman/Moscow,Id"},
 {"airport_iata_code":"PVD", "airport_name":"Theodore Francis Green State Airport", "city_name":"Providence"},
 {"airport_iata_code":"PWM", "airport_name":"Portland International Jetport Airport", "city_name":"Portland"},
 {"airport_iata_code":"PWT", "airport_name":"Bremerton National Airport", "city_name":"Bremerton"},
 {"airport_iata_code":"RAP", "airport_name":"Rapid City Regional Airport", "city_name":"Rapid City"},
 {"airport_iata_code":"RCT", "airport_name":"Nartron Field", "city_name":"Reed City"},
 {"airport_iata_code":"RDD", "airport_name":"Redding Municipal Airport", "city_name":"Redding"},
 {"airport_iata_code":"RDG", "airport_name":"Reading Regional Carl A Spaatz Field", "city_name":"Reading"},
 {"airport_iata_code":"RDM", "airport_name":"Roberts Field", "city_name":"Redmond"},
 {"airport_iata_code":"RDU", "airport_name":"Raleigh Durham International Airport", "city_name":"Raleigh/Durham"},
 {"airport_iata_code":"RFD", "airport_name":"Chicago Rockford International Airport", "city_name":"Chicago/Rockford"},
 {"airport_iata_code":"RHI", "airport_name":"Rhinelander Oneida County Airport", "city_name":"Rhinelander"},
 {"airport_iata_code":"RIC", "airport_name":"Richmond International Airport", "city_name":"Richmond"},
 {"airport_iata_code":"RKD", "airport_name":"Knox County Regional Airport", "city_name":"Rockland"},
 {"airport_iata_code":"RKS", "airport_name":"Rock Springs Sweetwater County Airport", "city_name":"Rock Springs"},
 {"airport_iata_code":"RNO", "airport_name":"Reno Tahoe International Airport", "city_name":"Reno"},
 {"airport_iata_code":"ROA", "airport_name":"Roanoke Regional Woodrum Field", "city_name":"Roanoke"},
 {"airport_iata_code":"ROC", "airport_name":"Greater Rochester International Airport", "city_name":"Rochester"},
 {"airport_iata_code":"ROW", "airport_name":"Roswell International Air Center Airport", "city_name":"Roswell"},
 {"airport_iata_code":"RQO", "airport_name":"El Reno Regional Airport", "city_name":"El Reno"},
 {"airport_iata_code":"RST", "airport_name":"Rochester International Airport", "city_name":"Rochester"},
 {"airport_iata_code":"RSW", "airport_name":"Southwest Florida International Airport", "city_name":"Fort Myers"},
 {"airport_iata_code":"RUT", "airport_name":"Rutland - Southern Vermont Regional Airport", "city_name":"Rutland"},
 {"airport_iata_code":"RWI", "airport_name":"Rocky Mount Wilson Regional Airport", "city_name":"Rocky Mount"},
 {"airport_iata_code":"RXE", "airport_name":"Rexburg Madison County Airport", "city_name":"Rexburg"},
 {"airport_iata_code":"AHM", "airport_name":"Ashland Municipal Sumner Parker Field", "city_name":"Ashland"},
 {"airport_iata_code":"BDY", "airport_name":"Bandon State Airport", "city_name":"Bandon"},
 {"airport_iata_code":"SUO", "airport_name":"Sunriver Airport", "city_name":"Sunriver"},
 {"airport_iata_code":"IDH", "airport_name":"Idaho County Airport", "city_name":"Grangeville"},
 {"airport_iata_code":"VSK", "airport_name":"Vista Field", "city_name":"Kennewick"},
 {"airport_iata_code":"SAF", "airport_name":"Santa Fe Municipal Airport", "city_name":"Santa Fe"},
 {"airport_iata_code":"SAN", "airport_name":"San Diego International Airport", "city_name":"San Diego"},
 {"airport_iata_code":"SAT", "airport_name":"San Antonio International Airport", "city_name":"San Antonio"},
 {"airport_iata_code":"SAV", "airport_name":"Savannah Hilton Head International Airport", "city_name":"Savannah"},
 {"airport_iata_code":"SBA", "airport_name":"Santa Barbara Municipal Airport", "city_name":"Santa Barbara"},
 {"airport_iata_code":"SBN", "airport_name":"South Bend Regional Airport", "city_name":"South Bend"},
 {"airport_iata_code":"SBP", "airport_name":"San Luis County Regional Airport", "city_name":"San Luis Obispo"},
 {"airport_iata_code":"SBY", "airport_name":"Salisbury Ocean City Wicomico Regional Airport", "city_name":"Salisbury"},
 {"airport_iata_code":"SDF", "airport_name":"Louisville International Standiford Field", "city_name":"Louisville"},
 {"airport_iata_code":"SDY", "airport_name":"Sidney Richland Municipal Airport", "city_name":"Sidney"},
 {"airport_iata_code":"SEA", "airport_name":"Seattle Tacoma International Airport", "city_name":"Seattle"},
 {"airport_iata_code":"SFB", "airport_name":"Orlando Sanford International Airport", "city_name":"Orlando"},
 {"airport_iata_code":"SFO", "airport_name":"San Francisco International Airport", "city_name":"San Francisco"},
 {"airport_iata_code":"SGF", "airport_name":"Springfield Branson National Airport", "city_name":"Springfield"},
 {"airport_iata_code":"SGU", "airport_name":"St George Municipal Airport", "city_name":"St George"},
 {"airport_iata_code":"SHD", "airport_name":"Shenandoah Valley Regional Airport", "city_name":"Staunton/Waynesboro/Harrisonbu"},
 {"airport_iata_code":"SHR", "airport_name":"Sheridan County Airport", "city_name":"Sheridan"},
 {"airport_iata_code":"SHV", "airport_name":"Shreveport Regional Airport", "city_name":"Shreveport"},
 {"airport_iata_code":"SJC", "airport_name":"Norman Y. Mineta San Jose International Airport", "city_name":"San Jose"},
 {"airport_iata_code":"SJT", "airport_name":"San Angelo Regional Mathis Field", "city_name":"San Angelo"},
 {"airport_iata_code":"SLC", "airport_name":"Salt Lake City International Airport", "city_name":"Salt Lake City"},
 {"airport_iata_code":"SLK", "airport_name":"Adirondack Regional Airport", "city_name":"Saranac Lake"},
 {"airport_iata_code":"SLN", "airport_name":"Salina Municipal Airport", "city_name":"Salina"},
 {"airport_iata_code":"SMF", "airport_name":"Sacramento International Airport", "city_name":"Sacramento"},
 {"airport_iata_code":"SMX", "airport_name":"Santa Maria Pub/Capt G Allan Hancock Field", "city_name":"Santa Maria"},
 {"airport_iata_code":"SNA", "airport_name":"John Wayne Airport-Orange County Airport", "city_name":"Santa Ana"},
 {"airport_iata_code":"SOW", "airport_name":"Show Low Regional Airport", "city_name":"Show Low"},
 {"airport_iata_code":"SPI", "airport_name":"Abraham Lincoln Capital Airport", "city_name":"Springfield"},
 {"airport_iata_code":"SPS", "airport_name":"Sheppard Air Force Base-Wichita Falls Municipal Airport", "city_name":"Wichita Falls"},
 {"airport_iata_code":"SRQ", "airport_name":"Sarasota Bradenton International Airport", "city_name":"Sarasota/Bradenton"},
 {"airport_iata_code":"STC", "airport_name":"St Cloud Regional Airport", "city_name":"St Cloud"},
 {"airport_iata_code":"STL", "airport_name":"Lambert St Louis International Airport", "city_name":"St Louis"},
 {"airport_iata_code":"STP", "airport_name":"St Paul Downtown Holman Field", "city_name":"St Paul"},
 {"airport_iata_code":"SUN", "airport_name":"Friedman Memorial Airport", "city_name":"Hailey"},
 {"airport_iata_code":"SUX", "airport_name":"Sioux Gateway Col. Bud Day Field", "city_name":"Sioux City"},
 {"airport_iata_code":"SVC", "airport_name":"Grant County Airport", "city_name":"Silver City"},
 {"airport_iata_code":"SWF", "airport_name":"Stewart International Airport", "city_name":"Newburgh"},
 {"airport_iata_code":"SYR", "airport_name":"Syracuse Hancock International Airport", "city_name":"Syracuse"},
 {"airport_iata_code":"TAD", "airport_name":"Perry Stokes Airport", "city_name":"Trinidad"},
 {"airport_iata_code":"TBN", "airport_name":"Waynesville-St. Robert Regional Forney field", "city_name":"Fort Leonard Wood"},
 {"airport_iata_code":"TCL", "airport_name":"Tuscaloosa Regional Airport", "city_name":"Tuscaloosa"},
 {"airport_iata_code":"TEX", "airport_name":"Telluride Regional Airport", "city_name":"Telluride"},
 {"airport_iata_code":"TLH", "airport_name":"Tallahassee Regional Airport", "city_name":"Tallahassee"},
 {"airport_iata_code":"OTK", "airport_name":"Tillamook Airport", "city_name":"Tillamook"},
 {"airport_iata_code":"TOL", "airport_name":"Toledo Express Airport", "city_name":"Toledo"},
 {"airport_iata_code":"TPA", "airport_name":"Tampa International Airport", "city_name":"Tampa"},
 {"airport_iata_code":"TPL", "airport_name":"Draughon Miller Central Texas Regional Airport", "city_name":"Temple"},
 {"airport_iata_code":"TRI", "airport_name":"Tri Cities Regional Tn Va Airport", "city_name":"Bristol/Johnson/Kingsport"},
 {"airport_iata_code":"TTN", "airport_name":"Trenton Mercer Airport", "city_name":"Trenton"},
 {"airport_iata_code":"TUL", "airport_name":"Tulsa International Airport", "city_name":"Tulsa"},
 {"airport_iata_code":"TUP", "airport_name":"Tupelo Regional Airport", "city_name":"Tupelo"},
 {"airport_iata_code":"TUS", "airport_name":"Tucson International Airport", "city_name":"Tucson"},
 {"airport_iata_code":"TVC", "airport_name":"Cherry Capital Airport", "city_name":"Traverse City"},
 {"airport_iata_code":"TVL", "airport_name":"Lake Tahoe Airport", "city_name":"South Lake Tahoe"},
 {"airport_iata_code":"TWF", "airport_name":"Joslin Field Magic Valley Regional Airport", "city_name":"Twin Falls"},
 {"airport_iata_code":"TXK", "airport_name":"Texarkana Regional Webb Field", "city_name":"Texarkana"},
 {"airport_iata_code":"TYR", "airport_name":"Tyler Pounds Regional Airport", "city_name":"Tyler"},
 {"airport_iata_code":"TYS", "airport_name":"McGhee Tyson Airport", "city_name":"Knoxville"},
 {"airport_iata_code":"BFG", "airport_name":"Bullfrog Basin Airport", "city_name":"Glen Canyon Natl Rec Area"},
 {"airport_iata_code":"RVR", "airport_name":"Green River Municipal Airport", "city_name":"Green River"},
 {"airport_iata_code":"ICS", "airport_name":"Cascade Airport", "city_name":"Cascade"},
 {"airport_iata_code":"UIN", "airport_name":"Quincy Regional Baldwin Field", "city_name":"Quincy"},
 {"airport_iata_code":"ATO", "airport_name":"Ohio University Snyder Field", "city_name":"Athens/Albany"},
 {"airport_iata_code":"SCE", "airport_name":"University Park Airport", "city_name":"State College"},
 {"airport_iata_code":"VAD", "airport_name":"Moody Air Force Base", "city_name":"Valdosta"},
 {"airport_iata_code":"VCT", "airport_name":"Victoria Regional Airport", "city_name":"Victoria"},
 {"airport_iata_code":"VLD", "airport_name":"Valdosta Regional Airport", "city_name":"Valdosta"},
 {"airport_iata_code":"VPS", "airport_name":"Eglin Air Force Base", "city_name":"Valparaiso"},
 {"airport_iata_code":"VRB", "airport_name":"Vero Beach Municipal Airport", "city_name":"Vero Beach"},
 {"airport_iata_code":"VSF", "airport_name":"Hartness State (Springfield) Airport", "city_name":"Springfield"},
 {"airport_iata_code":"WAL", "airport_name":"Wallops Flight Facility Airport", "city_name":"Wallops Island"},
 {"airport_iata_code":"WAY", "airport_name":"Greene County Airport", "city_name":"Waynesburg"},
 {"airport_iata_code":"WRI", "airport_name":"Mc Guire Air Force Base", "city_name":"Wrightstown"},
 {"airport_iata_code":"WRL", "airport_name":"Worland Municipal Airport", "city_name":"Worland"},
 {"airport_iata_code":"XNA", "airport_name":"Northwest Arkansas Regional Airport", "city_name":"Fayetteville/Springdale/"},
 {"airport_iata_code":"YIP", "airport_name":"Willow Run Airport", "city_name":"Detroit"},
 {"airport_iata_code":"YKM", "airport_name":"Yakima Air Terminal McAllister Field", "city_name":"Yakima"},
 {"airport_iata_code":"YNG", "airport_name":"Youngstown Warren Regional Airport", "city_name":"Youngstown/Warren"},
 {"airport_iata_code":"ZPH", "airport_name":"Zephyrhills Municipal Airport", "city_name":"Zephyrhills"},
 {"airport_iata_code":"ZZV", "airport_name":"Zanesville Municipal Airport", "city_name":"Zanesville"},
 {"airport_iata_code":"DRU", "airport_name":"Drummond Airport", "city_name":"Drummond"},
 {"airport_iata_code":"MDR", "airport_name":"Medfra Airport", "city_name":"Medra"},
 {"airport_iata_code":"MTX", "airport_name":"Metro Field", "city_name":"Fairbanks"},
 {"airport_iata_code":"UGB", "airport_name":"Ugashik Bay Airport", "city_name":"Pilot Point"},
 {"airport_iata_code":"VKX", "airport_name":"Potomac Airfield", "city_name":"Friendly"},
 {"airport_iata_code":"DTR", "airport_name":"Decatur Shores Airport", "city_name":"Decatur"},
 {"airport_iata_code":"BCC", "airport_name":"Bear Creek 3 Airport", "city_name":"Bear Creek"},
 {"airport_iata_code":"ZNC", "airport_name":"Nyac Airport", "city_name":"Nyac"},
 {"airport_iata_code":"NUP", "airport_name":"Nunapitchuk Airport", "city_name":"Nunapitchuk"},
 {"airport_iata_code":"ICY", "airport_name":"Icy Bay Airport", "city_name":"Icy Bay"},
 {"airport_iata_code":"KKK", "airport_name":"Kalakaket Creek AS Airport", "city_name":"Kalakaket Creek"},
 {"airport_iata_code":"HGZ", "airport_name":"Hog River Airport", "city_name":"Hogatza"},
 {"airport_iata_code":"BYW", "airport_name":"Blakely Island Airport", "city_name":"Blakely Island"},
 {"airport_iata_code":"BDF", "airport_name":"Rinkenberger Restricted Landing Area", "city_name":"Bradford"},
 {"airport_iata_code":"ATT", "airport_name":"Atmautluak Airport", "city_name":"Atmautluak"},
 {"airport_iata_code":"PDB", "airport_name":"Pedro Bay Airport", "city_name":"Pedro Bay"},
 {"airport_iata_code":"WKK", "airport_name":"Aleknagik / New Airport", "city_name":"Aleknagik"},
 {"airport_iata_code":"BCS", "airport_name":"Southern Seaplane Airport", "city_name":"Belle Chasse"},
 {"airport_iata_code":"BWL", "airport_name":"Earl Henry Airport", "city_name":"Blackwell"},
 {"airport_iata_code":"DUF", "airport_name":"Pine Island Airport", "city_name":"Corolla"},
 {"airport_iata_code":"FOB", "airport_name":"Fort Bragg Airport", "city_name":"Fort Bragg"},
 {"airport_iata_code":"CHU", "airport_name":"Chuathbaluk Airport", "city_name":"Chuathbaluk"},
 {"airport_iata_code":"KLL", "airport_name":"Levelock Airport", "city_name":"Levelock"},
 {"airport_iata_code":"CEX", "airport_name":"Chena Hot Springs Airport", "city_name":"Chena Hot Springs"},
 {"airport_iata_code":"HED", "airport_name":"Herendeen Bay Airport", "city_name":"Herendeen Bay"},
 {"airport_iata_code":"TWE", "airport_name":"Taylor Airport", "city_name":"Taylor"},
 {"airport_iata_code":"LNI", "airport_name":"Lonely Air Station", "city_name":"Lonely"},
 {"airport_iata_code":"CDL", "airport_name":"Candle 2 Airport", "city_name":"Candle"},
 {"airport_iata_code":"BSZ", "airport_name":"Bartletts Airport", "city_name":"Egegik"},
 {"airport_iata_code":"BSW", "airport_name":"Boswell Bay Airport", "city_name":"Boswell Bay"},
 {"airport_iata_code":"AQY", "airport_name":"Girdwood Airport", "city_name":"Girdwood"},
 {"airport_iata_code":"BKG", "airport_name":"Branson Airport", "city_name":"Branson"},
 {"airport_iata_code":"BOK", "airport_name":"Brookings Airport", "city_name":"Brookings"},
 {"airport_iata_code":"BSI", "airport_name":"Blairsville", "city_name":"Blairsville"},
 {"airport_iata_code":"BYA", "airport_name":"Boundary Airport", "city_name":"Boundary"},
 {"airport_iata_code":"CDK", "airport_name":"George T Lewis Airport", "city_name":"Cedar Key"},
 {"airport_iata_code":"CKD", "airport_name":"Crooked Creek Airport", "city_name":"Crooked Creek"},
 {"airport_iata_code":"CKX", "airport_name":"Chicken Airport", "city_name":"Chicken"},
 {"airport_iata_code":"HLI", "airport_name":"Hollister Municipal Airport", "city_name":"Hollister"},
 {"airport_iata_code":"DJN", "airport_name":"Delta Junction Airport", "city_name":"Delta Junction"},
 {"airport_iata_code":"DCK", "airport_name":"Dahl Creek Airport", "city_name":"Dahl Creek"},
 {"airport_iata_code":"DCR", "airport_name":"Decatur HI-Way Airfield", "city_name":"Decatur"},
 {"airport_iata_code":"GAB", "airport_name":"Gabbs Airport", "city_name":"Gabbs"},
 {"airport_iata_code":"GDY", "airport_name":"Grundy Municipal Airport", "city_name":"Grundy"},
 {"airport_iata_code":"GNU", "airport_name":"Goodnews Airport", "city_name":"Goodnews"},
 {"airport_iata_code":"ATI", "airport_name":"Artigas International Airport", "city_name":"Artigas"},
 {"airport_iata_code":"BUV", "airport_name":"Bella Union Airport", "city_name":"Bella Union"},
 {"airport_iata_code":"CYR", "airport_name":"Laguna de Los Patos International Airport", "city_name":"Colonia"},
 {"airport_iata_code":"CAR", "airport_name":"Carmelo International Airport", "city_name":"Carmelo"},
 {"airport_iata_code":"DZO", "airport_name":"Santa Bernardina International Airport", "city_name":"Durazno"},
 {"airport_iata_code":"PDP", "airport_name":"Capitan Corbeta CA Curbelo International Airport", "city_name":"Punta del Este"},
 {"airport_iata_code":"MER", "airport_name":"Ricardo de Tomasi International Airport", "city_name":"Mercedes"},
 {"airport_iata_code":"MLZ", "airport_name":"Cerro Largo International Airport", "city_name":"Melo"},
 {"airport_iata_code":"MVD", "airport_name":"Carrasco International /General C L Berisso Airport", "city_name":"Montevideo"},
 {"airport_iata_code":"MDO", "airport_name":"El Jaguel / Punta del Este Airport", "city_name":"Maldonado"},
 {"airport_iata_code":"PDU", "airport_name":"Tydeo Larre Borges Airport", "city_name":"Paysandu"},
 {"airport_iata_code":"RVY", "airport_name":"Presidente General Don Oscar D. Gestido International Airport", "city_name":"Rivera"},
 {"airport_iata_code":"STY", "airport_name":"Nueva Hesperides International Airport", "city_name":"Salto"},
 {"airport_iata_code":"TAW", "airport_name":"Tacuarembo Airport", "city_name":"Tacuarembo"},
 {"airport_iata_code":"TYT", "airport_name":"Treinta y Tres Airport", "city_name":"Treinta y Tres"},
 {"airport_iata_code":"VCH", "airport_name":"Vichadero Airport", "city_name":"Vichadero"},
 {"airport_iata_code":"FZB", "airport_name":"Fray Bentos Airport", "city_name":"Fray Bentos"},
 {"airport_iata_code":"AZN", "airport_name":"Andizhan Airport", "city_name":"Andizhan"},
 {"airport_iata_code":"FEG", "airport_name":"Fergana International Airport", "city_name":"Fergana"},
 {"airport_iata_code":"NMA", "airport_name":"Namangan Airport", "city_name":"Namangan"},
 {"airport_iata_code":"NCU", "airport_name":"Nukus Airport", "city_name":"Nukus"},
 {"airport_iata_code":"UGC", "airport_name":"Urgench Airport", "city_name":"Urgench"},
 {"airport_iata_code":"NVI", "airport_name":"Navoi Airport", "city_name":"Navoi"},
 {"airport_iata_code":"BHK", "airport_name":"Bukhara Airport", "city_name":"Bukhara"},
 {"airport_iata_code":"KSQ", "airport_name":"Karshi Khanabad Airport", "city_name":"Khanabad"},
 {"airport_iata_code":"AFS", "airport_name":"Sugraly Airport", "city_name":"Zarafshan"},
 {"airport_iata_code":"SKD", "airport_name":"Samarkand Airport", "city_name":"Samarkand"},
 {"airport_iata_code":"TMJ", "airport_name":"Termez Airport", "city_name":"Termez"},
 {"airport_iata_code":"TAS", "airport_name":"Tashkent International Airport", "city_name":"Tashkent"},
 {"airport_iata_code":"BQU", "airport_name":"J F Mitchell Airport", "city_name":"Bequia"},
 {"airport_iata_code":"CIW", "airport_name":"Canouan Airport", "city_name":"Canouan"},
 {"airport_iata_code":"MQS", "airport_name":"Mustique Airport", "city_name":"Mustique Island"},
 {"airport_iata_code":"UNI", "airport_name":"Union Island International Airport", "city_name":"Union Island"},
 {"airport_iata_code":"SVD", "airport_name":"E. T. Joshua Airport", "city_name":"Kingstown"},
 {"airport_iata_code":"AGV", "airport_name":"Oswaldo Guevara Mujica Airport", "city_name":"Acarigua"},
 {"airport_iata_code":"AAO", "airport_name":"Anaco Airport", "city_name":"Anaco"},
 {"airport_iata_code":"LPJ", "airport_name":"Armando Schwarck Airport", "city_name":"Guayabal"},
 {"airport_iata_code":"BLA", "airport_name":"General Jose Antonio Anzoategui International Airport", "city_name":"Barcelona"},
 {"airport_iata_code":"BNS", "airport_name":"Barinas Airport", "city_name":"Barinas"},
 {"airport_iata_code":"BRM", "airport_name":"Barquisimeto International Airport", "city_name":"Barquisimeto"},
 {"airport_iata_code":"MYC", "airport_name":"Escuela Mariscal Sucre Airport", "city_name":"Maracay"},
 {"airport_iata_code":"CBL", "airport_name":"Ciudad Bol\u00edvar Airport", "city_name":""},
 {"airport_iata_code":"CXA", "airport_name":"Caicara del Orinoco Airport", "city_name":""},
 {"airport_iata_code":"CUV", "airport_name":"Casigua El Cubo Airport", "city_name":"Casigua El Cubo"},
 {"airport_iata_code":"CLZ", "airport_name":"Calabozo Airport", "city_name":"Guarico"},
 {"airport_iata_code":"CAJ", "airport_name":"Canaima Airport", "city_name":"Canaima"},
 {"airport_iata_code":"VCR", "airport_name":"Carora Airport", "city_name":"Carora"},
 {"airport_iata_code":"CUP", "airport_name":"General Francisco Berm\u00fadez Airport", "city_name":"Car\u00fapano"},
 {"airport_iata_code":"CZE", "airport_name":"Jos\u00e9 Leonardo Chirinos Airport", "city_name":"Coro"},
 {"airport_iata_code":"CUM", "airport_name":"Cuman\u00e1 (Antonio Jos\u00e9 de Sucre) Airport", "city_name":""},
 {"airport_iata_code":"isl", "airport_name":"La Tortuga Punta Delgada Airport", "city_name":"Isla La Tortuga"},
 {"airport_iata_code":"EOR", "airport_name":"El Dorado Airport", "city_name":"Bolivar"},
 {"airport_iata_code":"EOZ", "airport_name":"Elorza Airport", "city_name":""},
 {"airport_iata_code":"GDO", "airport_name":"Guasdalito Airport", "city_name":""},
 {"airport_iata_code":"GUI", "airport_name":"Guiria Airport", "city_name":""},
 {"airport_iata_code":"GUQ", "airport_name":"Guanare Airport", "city_name":"Guanare"},
 {"airport_iata_code":"ICA", "airport_name":"Icabar\u00fa Airport", "city_name":""},
 {"airport_iata_code":"LSP", "airport_name":"Josefa Camejo International Airport", "city_name":"Paraguan\u00e1"},
 {"airport_iata_code":"KAV", "airport_name":"Kavanayen Airport", "city_name":""},
 {"airport_iata_code":"LFR", "airport_name":"La Fria Airport", "city_name":""},
 {"airport_iata_code":"MAR", "airport_name":"La Chinita International Airport", "city_name":"Maracaibo"},
 {"airport_iata_code":"MRD", "airport_name":"Alberto Carnevalli Airport", "city_name":"M\u00e9rida"},
 {"airport_iata_code":"PMV", "airport_name":"Del Caribe Santiago Mari\u00f1o International Airport", "city_name":"Isla Margarita"},
 {"airport_iata_code":"CCS", "airport_name":"Maiquet\u00eda (Sim\u00f3n Bol\u00edvar Internacional) Airport", "city_name":"Caracas"},
 {"airport_iata_code":"MUN", "airport_name":"Matur\u00edn Airport", "city_name":""},
 {"airport_iata_code":"CBS", "airport_name":"Oro Negro Airport", "city_name":"Cabimas"},
 {"airport_iata_code":"PYH", "airport_name":"Cacique Aramare Airport", "city_name":""},
 {"airport_iata_code":"PBL", "airport_name":"General Bartolome Salom International Airport", "city_name":""},
 {"airport_iata_code":"PDZ", "airport_name":"Pedernales Airport", "city_name":""},
 {"airport_iata_code":"PPH", "airport_name":"Perai Tepuy Airport", "city_name":""},
 {"airport_iata_code":"SCI", "airport_name":"Paramillo Airport", "city_name":""},
 {"airport_iata_code":"PZO", "airport_name":"General Manuel Carlos Piar International Airport", "city_name":"Puerto Ordaz-Ciudad Guayana"},
 {"airport_iata_code":"PTM", "airport_name":"Palmarito Airport", "city_name":"Palmarito"},
 {"airport_iata_code":"LRV", "airport_name":"Gran Roque Airport", "city_name":"Los Roques"},
 {"airport_iata_code":"SVZ", "airport_name":"San Antonio Del Tachira Airport", "city_name":""},
 {"airport_iata_code":"SBB", "airport_name":"Santa B\u00e1rbara de Barinas Airport", "city_name":"Santa B\u00e1rbara"},
 {"airport_iata_code":"SNV", "airport_name":"Santa Elena de Uairen Airport", "city_name":""},
 {"airport_iata_code":"STD", "airport_name":"Mayor Buenaventura Vivas International Airport", "city_name":"Santo Domingo"},
 {"airport_iata_code":"SNF", "airport_name":"Sub Teniente Nestor Arias Airport", "city_name":"San Felipe"},
 {"airport_iata_code":"SFD", "airport_name":"San Fernando De Apure Airport", "city_name":"Inglaterra"},
 {"airport_iata_code":"SOM", "airport_name":"San Tome Airport", "city_name":""},
 {"airport_iata_code":"STB", "airport_name":"Santa B\u00e1rbara del Zulia Airport", "city_name":""},
 {"airport_iata_code":"TUV", "airport_name":"Tucupita Airport", "city_name":"Tucupita"},
 {"airport_iata_code":"TMO", "airport_name":"Tumeremo Airport", "city_name":""},
 {"airport_iata_code":"URM", "airport_name":"Uriman Airport", "city_name":""},
 {"airport_iata_code":"VLN", "airport_name":"Arturo Michelena International Airport", "city_name":"Valencia"},
 {"airport_iata_code":"VIG", "airport_name":"Juan Pablo P\u00e9rez Alfonso Airport", "city_name":"El Vig\u00eda"},
 {"airport_iata_code":"VLV", "airport_name":"Dr. Antonio Nicol\u00e1s Brice\u00f1o Airport", "city_name":"Valera"},
 {"airport_iata_code":"VDP", "airport_name":"Valle de La Pascua Airport", "city_name":""},
 {"airport_iata_code":"LGY", "airport_name":"Lagunillas Airport", "city_name":"Lagunillas"},
 {"airport_iata_code":"KTV", "airport_name":"Kamarata Airport", "city_name":"Kamarata"},
 {"airport_iata_code":"LAG", "airport_name":"La Guaira Airport", "city_name":"La Guaira"},
 {"airport_iata_code":"SFX", "airport_name":"San Felix Airport", "city_name":"Ciudad Guayana"},
 {"airport_iata_code":"SVV", "airport_name":"San Salvador de Paul Airport", "city_name":"San Salvador de Paul"},
 {"airport_iata_code":"WOK", "airport_name":"Wonken Airport", "city_name":"Wonken"},
 {"airport_iata_code":"NGD", "airport_name":"Captain Auguste George Airport", "city_name":"Anegada"},
 {"airport_iata_code":"EIS", "airport_name":"Terrance B. Lettsome International Airport", "city_name":"Road Town"},
 {"airport_iata_code":"VIJ", "airport_name":"Virgin Gorda Airport", "city_name":"Spanish Town"},
 {"airport_iata_code":"STT", "airport_name":"Cyril E. King Airport", "city_name":"Charlotte Amalie, Harry S. Tru"},
 {"airport_iata_code":"STX", "airport_name":"Henry E Rohlsen Airport", "city_name":"Christiansted"},
 {"airport_iata_code":"VDH", "airport_name":"Dong Hoi Airport", "city_name":"Dong Hoi"},
 {"airport_iata_code":"KON", "airport_name":"Kontum Airport", "city_name":"Kontum"},
 {"airport_iata_code":"BMV", "airport_name":"Buon Ma Thuot Airport", "city_name":"Buon Ma Thuot"},
 {"airport_iata_code":"VCL", "airport_name":"Chu Lai International Airport", "city_name":"Dung Quat Bay"},
 {"airport_iata_code":"HPH", "airport_name":"Cat Bi International Airport", "city_name":"Haiphong"},
 {"airport_iata_code":"CAH", "airport_name":"C\u00e0 Mau Airport", "city_name":"Ca Mau City"},
 {"airport_iata_code":"CXR", "airport_name":"Cam Ranh Airport", "city_name":"Nha Trang"},
 {"airport_iata_code":"VCS", "airport_name":"Co Ong Airport", "city_name":"Con Ong"},
 {"airport_iata_code":"VCA", "airport_name":"Tr\u00e0 N\u00f3c Airport", "city_name":"Can Tho"},
 {"airport_iata_code":"DIN", "airport_name":"Dien Bien Phu Airport", "city_name":"Dien Bien Phu"},
 {"airport_iata_code":"DLI", "airport_name":"Lien Khuong Airport", "city_name":"Dalat"},
 {"airport_iata_code":"DAD", "airport_name":"Da Nang International Airport", "city_name":"Da Nang"},
 {"airport_iata_code":"HAN", "airport_name":"Noi Bai International Airport", "city_name":"Hanoi"},
 {"airport_iata_code":"SQH", "airport_name":"Na-San Airport", "city_name":"Son-La"},
 {"airport_iata_code":"NHA", "airport_name":"Nha Trang Air Base", "city_name":"Nha Trang"},
 {"airport_iata_code":"HUI", "airport_name":"Phu Bai Airport", "city_name":"Hue"},
 {"airport_iata_code":"UIH", "airport_name":"Phu Cat Airport", "city_name":"Quy Nohn"},
 {"airport_iata_code":"PXU", "airport_name":"Pleiku Airport", "city_name":"Pleiku"},
 {"airport_iata_code":"PQC", "airport_name":"Phu Quoc Airport", "city_name":"Duong Dong"},
 {"airport_iata_code":"PHA", "airport_name":"Phan Rang Airport", "city_name":"Phan Rang"},
 {"airport_iata_code":"PHH", "airport_name":"Phan Thiet Airport", "city_name":"Phan Thiet"},
 {"airport_iata_code":"VKG", "airport_name":"Rach Gia Airport", "city_name":"Rach Gia"},
 {"airport_iata_code":"TBB", "airport_name":"Dong Tac Airport", "city_name":"Tuy Hoa"},
 {"airport_iata_code":"SGN", "airport_name":"Tan Son Nhat International Airport", "city_name":"Ho Chi Minh City"},
 {"airport_iata_code":"VII", "airport_name":"Vinh Airport", "city_name":"Vinh"},
 {"airport_iata_code":"VTG", "airport_name":"Vung Tau Airport", "city_name":"Vung Tau"},
 {"airport_iata_code":"MTV", "airport_name":"Mota Lava Airport", "city_name":"Ablow"},
 {"airport_iata_code":"SLH", "airport_name":"Sola Airport", "city_name":"Sola"},
 {"airport_iata_code":"TOH", "airport_name":"Torres Airstrip", "city_name":"Loh/Linua"},
 {"airport_iata_code":"EAE", "airport_name":"Sangafa Airport", "city_name":"Sangafa"},
 {"airport_iata_code":"CCV", "airport_name":"Craig Cove Airport", "city_name":"Craig Cove"},
 {"airport_iata_code":"LOD", "airport_name":"Longana Airport", "city_name":"Longana"},
 {"airport_iata_code":"SSR", "airport_name":"Sara Airport", "city_name":"Pentecost Island"},
 {"airport_iata_code":"PBJ", "airport_name":"Tavie Airport", "city_name":"Paama Island"},
 {"airport_iata_code":"LPM", "airport_name":"Lamap Airport", "city_name":"Lamap"},
 {"airport_iata_code":"LNB", "airport_name":"Lamen Bay Airport", "city_name":"Lamen Bay"},
 {"airport_iata_code":"MWF", "airport_name":"Naone Airport", "city_name":"Maewo Island"},
 {"airport_iata_code":"LNE", "airport_name":"Lonorore Airport", "city_name":"Lonorore"},
 {"airport_iata_code":"NUS", "airport_name":"Norsup Airport", "city_name":"Norsup"},
 {"airport_iata_code":"ZGU", "airport_name":"Gaua Island Airport", "city_name":"Gaua Island"},
 {"airport_iata_code":"RCL", "airport_name":"Redcliffe Airport", "city_name":"Redcliffe"},
 {"airport_iata_code":"SON", "airport_name":"Santo Pekoa International Airport", "city_name":"Luganville"},
 {"airport_iata_code":"TGH", "airport_name":"Tongoa Island Airport", "city_name":"Tongoa Island"},
 {"airport_iata_code":"ULB", "airport_name":"Ul\u00e9i Airport", "city_name":"Ambryn Island"},
 {"airport_iata_code":"VLS", "airport_name":"Valesdir Airport", "city_name":"Valesdir"},
 {"airport_iata_code":"WLH", "airport_name":"Walaha Airport", "city_name":"Walaha"},
 {"airport_iata_code":"SWJ", "airport_name":"Southwest Bay Airport", "city_name":"Malekula Island"},
 {"airport_iata_code":"OLJ", "airport_name":"North West Santo Airport", "city_name":"Olpoi"},
 {"airport_iata_code":"AUY", "airport_name":"Anelghowhat Airport", "city_name":"Anelghowhat"},
 {"airport_iata_code":"AWD", "airport_name":"Aniwa Airport", "city_name":"Aniwa"},
 {"airport_iata_code":"DLY", "airport_name":"Dillon's Bay Airport", "city_name":"Dillon's Bay"},
 {"airport_iata_code":"FTA", "airport_name":"Futuna Airport", "city_name":"Futuna Island"},
 {"airport_iata_code":"IPA", "airport_name":"Ipota Airport", "city_name":"Ipota"},
 {"airport_iata_code":"UIQ", "airport_name":"Quion Hill Airport", "city_name":"Quion Hill"},
 {"airport_iata_code":"VLI", "airport_name":"Port Vila Bauerfield Airport", "city_name":"Port Vila"},
 {"airport_iata_code":"TAH", "airport_name":"Tanna Airport", "city_name":""},
 {"airport_iata_code":"FUT", "airport_name":"Pointe Vele Airport", "city_name":"Futuna Island"},
 {"airport_iata_code":"WLS", "airport_name":"Hihifo Airport", "city_name":"Wallis Island"},
 {"airport_iata_code":"AAU", "airport_name":"Asau Airport", "city_name":"Asau"},
 {"airport_iata_code":"APW", "airport_name":"Faleolo International Airport", "city_name":"Apia"},
 {"airport_iata_code":"FGI", "airport_name":"Fagali'i Airport", "city_name":"Apia"},
 {"airport_iata_code":"MXS", "airport_name":"Maota Airport", "city_name":"Maota"},
 {"airport_iata_code":"ADE", "airport_name":"Aden International Airport", "city_name":"Aden"},
 {"airport_iata_code":"EAB", "airport_name":"Abbse Airport", "city_name":"Abbse"},
 {"airport_iata_code":"AXK", "airport_name":"Ataq Airport", "city_name":""},
 {"airport_iata_code":"BHN", "airport_name":"Beihan Airport", "city_name":""},
 {"airport_iata_code":"AAY", "airport_name":"Al Ghaidah International Airport", "city_name":""},
 {"airport_iata_code":"HOD", "airport_name":"Hodeidah International Airport", "city_name":"Hodeida"},
 {"airport_iata_code":"MYN", "airport_name":"Mareb Airport", "city_name":"Mareb"},
 {"airport_iata_code":"IHN", "airport_name":"Qishn Airport", "city_name":"Qishn"},
 {"airport_iata_code":"RIY", "airport_name":"Mukalla International Airport", "city_name":"Riyan"},
 {"airport_iata_code":"SYE", "airport_name":"Sadah Airport", "city_name":"Sadah"},
 {"airport_iata_code":"SAH", "airport_name":"Sana'a International Airport", "city_name":"Sana'a"},
 {"airport_iata_code":"SCT", "airport_name":"Socotra International Airport", "city_name":"Socotra Islands"},
 {"airport_iata_code":"GXF", "airport_name":"Sayun International Airport", "city_name":"Sayun"},
 {"airport_iata_code":"TAI", "airport_name":"Ta'izz International Airport", "city_name":"Ta'izz"},
 {"airport_iata_code":"WDA", "airport_name":"Wadi Ain Airport", "city_name":"Wadi Ain"},
 {"airport_iata_code":"DZA", "airport_name":"Dzaoudzi Pamandzi International Airport", "city_name":"Dzaoudzi"},
 {"airport_iata_code":"ALJ", "airport_name":"Alexander Bay Airport", "city_name":"Alexander Bay"},
 {"airport_iata_code":"AGZ", "airport_name":"Aggeneys Airport", "city_name":"Aggeneys"},
 {"airport_iata_code":"ADY", "airport_name":"Alldays Airport", "city_name":"Alldays"},
 {"airport_iata_code":"BIY", "airport_name":"Bisho Airport", "city_name":"Bisho"},
 {"airport_iata_code":"BFN", "airport_name":"J B M Hertzog International Airport", "city_name":"Bloemfontain"},
 {"airport_iata_code":"UTE", "airport_name":"Bultfontein Airport", "city_name":"Bultfontein"},
 {"airport_iata_code":"CDO", "airport_name":"Cradock Airport", "city_name":"Cradock"},
 {"airport_iata_code":"CPT", "airport_name":"Cape Town International Airport", "city_name":"Cape Town"},
 {"airport_iata_code":"DUK", "airport_name":"Mubatuba Airport", "city_name":"Mubatuba"},
 {"airport_iata_code":"PZL", "airport_name":"Zulu Inyala Airport", "city_name":"Phinda"},
 {"airport_iata_code":"ELS", "airport_name":"Ben Schoeman Airport", "city_name":"East London"},
 {"airport_iata_code":"EMG", "airport_name":"Empangeni Airport", "city_name":"Empangeni"},
 {"airport_iata_code":"ELL", "airport_name":"Ellisras Matimba Airport", "city_name":"Ellisras"},
 {"airport_iata_code":"FCB", "airport_name":"Ficksburg Sentraoes Airport", "city_name":"Ficksburg"},
 {"airport_iata_code":"GCJ", "airport_name":"Grand Central Airport", "city_name":"Midrand"},
 {"airport_iata_code":"GRJ", "airport_name":"George Airport", "city_name":"George"},
 {"airport_iata_code":"GIY", "airport_name":"Giyani Airport", "city_name":"Giyani"},
 {"airport_iata_code":"QRA", "airport_name":"Rand Airport", "city_name":"Johannesburg"},
 {"airport_iata_code":"HLW", "airport_name":"Hluhluwe Airport", "city_name":"Hluhluwe"},
 {"airport_iata_code":"HRS", "airport_name":"Harrismith Airport", "city_name":"Harrismith"},
 {"airport_iata_code":"HDS", "airport_name":"Hoedspruit Air Force Base Airport", "city_name":"Hoedspruit"},
 {"airport_iata_code":"JNB", "airport_name":"OR Tambo International Airport", "city_name":"Johannesburg"},
 {"airport_iata_code":"KXE", "airport_name":"P C Pelser Airport", "city_name":"Klerksdorp"},
 {"airport_iata_code":"KIM", "airport_name":"Kimberley Airport", "city_name":"Kimberley"},
 {"airport_iata_code":"MQP", "airport_name":"Kruger Mpumalanga International Airport", "city_name":"Mpumalanga"},
 {"airport_iata_code":"KOF", "airport_name":"Komatipoort Airport", "city_name":"Komatipoort"},
 {"airport_iata_code":"KMH", "airport_name":"Johan Pienaar Airport", "city_name":"Kuruman"},
 {"airport_iata_code":"KLZ", "airport_name":"Kleinsee Airport", "city_name":"Kleinsee"},
 {"airport_iata_code":"HLA", "airport_name":"Lanseria Airport", "city_name":"Johannesburg"},
 {"airport_iata_code":"LMR", "airport_name":"Lime Acres Finsch Mine Airport", "city_name":"Lime Acres"},
 {"airport_iata_code":"LDZ", "airport_name":"Londolozi Airport", "city_name":"Londolozi"},
 {"airport_iata_code":"DUR", "airport_name":"King Shaka International Airport", "city_name":"Durban"},
 {"airport_iata_code":"LUJ", "airport_name":"Lusikisiki Airport", "city_name":"Lusikisiki"},
 {"airport_iata_code":"LCD", "airport_name":"Louis Trichardt Airport", "city_name":"Louis Trichardt"},
 {"airport_iata_code":"SDB", "airport_name":"Langebaanweg Airport", "city_name":"Langebaanweg"},
 {"airport_iata_code":"LAY", "airport_name":"Ladysmith Airport", "city_name":"Ladysmith"},
 {"airport_iata_code":"AAM", "airport_name":"Malamala Airport", "city_name":"Malamala"},
 {"airport_iata_code":"MGH", "airport_name":"Margate Airport", "city_name":"Margate"},
 {"airport_iata_code":"MEZ", "airport_name":"Musina(Messina) Airport", "city_name":"Musina"},
 {"airport_iata_code":"MBD", "airport_name":"Mmabatho International Airport", "city_name":"Mafeking"},
 {"airport_iata_code":"LLE", "airport_name":"Riverside Airport", "city_name":"Malelane"},
 {"airport_iata_code":"MZY", "airport_name":"Mossel Bay Airport", "city_name":"Mossel Bay"},
 {"airport_iata_code":"MZQ", "airport_name":"Mkuze Airport", "city_name":"Mkuze"},
 {"airport_iata_code":"NCS", "airport_name":"Newcastle Airport", "city_name":"Newcastle"},
 {"airport_iata_code":"NGL", "airport_name":"Ngala Airport", "city_name":"Ngala"},
 {"airport_iata_code":"NLP", "airport_name":"Nelspruit Airport", "city_name":"Nelspruit"},
 {"airport_iata_code":"OVG", "airport_name":"Overberg Airport", "city_name":"Overberg"},
 {"airport_iata_code":"OUH", "airport_name":"Oudtshoorn Airport", "city_name":"Oudtshoorn"},
 {"airport_iata_code":"AFD", "airport_name":"Port Alfred Airport", "city_name":"Port Alfred"},
 {"airport_iata_code":"PLZ", "airport_name":"Port Elizabeth Airport", "city_name":"Port Elizabeth"},
 {"airport_iata_code":"PBZ", "airport_name":"Plettenberg Bay Airport", "city_name":"Plettenberg Bay"},
 {"airport_iata_code":"PHW", "airport_name":"Hendrik Van Eck Airport", "city_name":"Phalaborwa"},
 {"airport_iata_code":"JOH", "airport_name":"Port St Johns Airport", "city_name":"Port St Johns"},
 {"airport_iata_code":"PRK", "airport_name":"Prieska Airport", "city_name":"Prieska"},
 {"airport_iata_code":"PZB", "airport_name":"Pietermaritzburg Airport", "city_name":"Pietermaritzburg"},
 {"airport_iata_code":"NTY", "airport_name":"Pilanesberg International Airport", "city_name":"Pilanesberg"},
 {"airport_iata_code":"PTG", "airport_name":"Polokwane International Airport", "city_name":"Potgietersrus"},
 {"airport_iata_code":"PCF", "airport_name":"Potchefstroom Airport", "city_name":"Potchefstroom"},
 {"airport_iata_code":"UTW", "airport_name":"Queenstown Airport", "city_name":"Queenstown"},
 {"airport_iata_code":"RCB", "airport_name":"Richards Bay Airport", "city_name":"Richards Bay"},
 {"airport_iata_code":"RVO", "airport_name":"Reivilo Airport", "city_name":"Reivilo"},
 {"airport_iata_code":"ROD", "airport_name":"Robertson Airport", "city_name":"Robertson"},
 {"airport_iata_code":"SBU", "airport_name":"Springbok Airport", "city_name":"Springbok"},
 {"airport_iata_code":"ZEC", "airport_name":"Secunda Airport", "city_name":"Secunda"},
 {"airport_iata_code":"GSS", "airport_name":"Sabi Sabi Airport", "city_name":"Belfast"},
 {"airport_iata_code":"SIS", "airport_name":"Sishen Airport", "city_name":"Sishen"},
 {"airport_iata_code":"SZK", "airport_name":"Skukuza Airport", "city_name":"Skukuza"},
 {"airport_iata_code":"THY", "airport_name":"Thohoyandou Airport", "city_name":"Thohoyandou"},
 {"airport_iata_code":"TCU", "airport_name":"Thaba Nchu Tar Airport", "city_name":"Homeward"},
 {"airport_iata_code":"LTA", "airport_name":"Tzaneen Airport", "city_name":"Tzaneen"},
 {"airport_iata_code":"ULD", "airport_name":"Prince Mangosuthu Buthelezi Airport", "city_name":"Ulundi"},
 {"airport_iata_code":"UTN", "airport_name":"Pierre Van Ryneveld Airport", "city_name":"Upington"},
 {"airport_iata_code":"UTT", "airport_name":"K. D. Matanzima Airport", "city_name":"Mthatha"},
 {"airport_iata_code":"VRU", "airport_name":"Vryburg Airport", "city_name":"Vyrburg"},
 {"airport_iata_code":"VIR", "airport_name":"Virginia Airport", "city_name":"Durban"},
 {"airport_iata_code":"VRE", "airport_name":"Vredendal Airport", "city_name":"Vredendal"},
 {"airport_iata_code":"VYD", "airport_name":"Vryheid Airport", "city_name":"Vryheid"},
 {"airport_iata_code":"PRY", "airport_name":"Wonderboom Airport", "city_name":"Pretoria"},
 {"airport_iata_code":"WKF", "airport_name":"Waterkloof Air Force Base", "city_name":"Pretoria"},
 {"airport_iata_code":"WEL", "airport_name":"Welkom Airport", "city_name":"Welkom"},
 {"airport_iata_code":"ULX", "airport_name":"Ulusaba Airport", "city_name":"Ulusaba"},
 {"airport_iata_code":"TDT", "airport_name":"Tanda Tula Airport", "city_name":"Welverdiend"},
 {"airport_iata_code":"HZV", "airport_name":"Hazyview Airport", "city_name":"Hazyview"},
 {"airport_iata_code":"KHO", "airport_name":"Khoka Moya Airport", "city_name":"Khoka Moya"},
 {"airport_iata_code":"MBM", "airport_name":"Mkambati Airport", "city_name":"Mkambati"},
 {"airport_iata_code":"INY", "airport_name":"Inyati Airport", "city_name":"Inyati"},
 {"airport_iata_code":"TSD", "airport_name":"Tshipise Airport", "city_name":"Tshipise"},
 {"airport_iata_code":"KIG", "airport_name":"Koingnaas Airport", "city_name":"Koingnaas"},
 {"airport_iata_code":"CGJ", "airport_name":"Chingola Airport", "city_name":"Chingola"},
 {"airport_iata_code":"MMQ", "airport_name":"Mbala Airport", "city_name":"Mbala"},
 {"airport_iata_code":"CIP", "airport_name":"Chipata Airport", "city_name":"Chipata"},
 {"airport_iata_code":"ZKP", "airport_name":"Kasompe Airport", "city_name":"Kasompe"},
 {"airport_iata_code":"KLB", "airport_name":"Kalabo Airport", "city_name":"Kalabo"},
 {"airport_iata_code":"KMZ", "airport_name":"Kaoma Airport", "city_name":"Kaoma"},
 {"airport_iata_code":"KAA", "airport_name":"Kasama Airport", "city_name":"Kasama"},
 {"airport_iata_code":"ZKB", "airport_name":"Kasaba Bay Airport", "city_name":"Kasaba Bay"},
 {"airport_iata_code":"LVI", "airport_name":"Livingstone Airport", "city_name":"Livingstone"},
 {"airport_iata_code":"LXU", "airport_name":"Lukulu Airport", "city_name":"Lukulu"},
 {"airport_iata_code":"LUN", "airport_name":"Lusaka International Airport", "city_name":"Lusaka"},
 {"airport_iata_code":"MNS", "airport_name":"Mansa Airport", "city_name":"Mansa"},
 {"airport_iata_code":"MFU", "airport_name":"Mfuwe Airport", "city_name":"Mfuwe"},
 {"airport_iata_code":"MNR", "airport_name":"Mongu Airport", "city_name":"Mongu"},
 {"airport_iata_code":"ZGM", "airport_name":"Ngoma Airport", "city_name":"Ngoma"},
 {"airport_iata_code":"NLA", "airport_name":"Ndola Airport", "city_name":"Ndola"},
 {"airport_iata_code":"SXG", "airport_name":"Senanga Airport", "city_name":"Senanga"},
 {"airport_iata_code":"KIW", "airport_name":"Southdowns Airport", "city_name":"Kitwe"},
 {"airport_iata_code":"SJQ", "airport_name":"Sesheke Airport", "city_name":"Sesheke"},
 {"airport_iata_code":"SLI", "airport_name":"Solwesi Airport", "city_name":"Solwesi"},
 {"airport_iata_code":"BBZ", "airport_name":"Zambezi Airport", "city_name":"Zambezi"},
 {"airport_iata_code":"BUQ", "airport_name":"Joshua Mqabuko Nkomo International Airport", "city_name":"Bulawayo"},
 {"airport_iata_code":"CHJ", "airport_name":"Chipinge Airport", "city_name":"Chipinge"},
 {"airport_iata_code":"BFO", "airport_name":"Buffalo Range Airport", "city_name":"Chiredzi"},
 {"airport_iata_code":"VFA", "airport_name":"Victoria Falls International Airport", "city_name":"Victoria Falls"},
 {"airport_iata_code":"HRE", "airport_name":"Harare International Airport", "city_name":"Harare"},
 {"airport_iata_code":"KAB", "airport_name":"Kariba International Airport", "city_name":"Kariba"},
 {"airport_iata_code":"UTA", "airport_name":"Mutare Airport", "city_name":""},
 {"airport_iata_code":"MVZ", "airport_name":"Masvingo International Airport", "city_name":"Masvingo"},
 {"airport_iata_code":"GWE", "airport_name":"Thornhill Air Base", "city_name":"Gweru"},
 {"airport_iata_code":"HWN", "airport_name":"Hwange National Park Airport", "city_name":"Hwange"},
 {"airport_iata_code":"WKI", "airport_name":"Hwange Airport", "city_name":"Hwange"},
 {"airport_iata_code":"XMB", "airport_name":"Marina Mall Airport", "city_name":"Dubai"},
 {"airport_iata_code":"SAC", "airport_name":"Sacramento Executive Airport", "city_name":"Sacramento"},
 {"airport_iata_code":"XDS", "airport_name":"Ottawa Train Station", "city_name":"Ottawa"},
 {"airport_iata_code":"LON", "airport_name":"London All Airports", "city_name":"London"},
 {"airport_iata_code":"YTO", "airport_name":"Toronto All Aiports", "city_name":"Toronto"},
 {"airport_iata_code":"CHI", "airport_name":"Chicago All Airports", "city_name":"Chicago"},
 {"airport_iata_code":"NYC", "airport_name":"New York", "city_name":"New York"},
 {"airport_iata_code":"WAS", "airport_name":"Washington All Airports", "city_name":"Washington"},
 {"airport_iata_code":"PAR", "airport_name":"Paris All Airports", "city_name":"Paris"},
 {"airport_iata_code":"MOW", "airport_name":"Moscow All Airports", "city_name":"Moscow"},
 {"airport_iata_code":"BER", "airport_name":"Berlin All Airports", "city_name":"Berlin"},
 {"airport_iata_code":"TYO", "airport_name":"Tokyo All Airports", "city_name":"Tokyo"},
 {"airport_iata_code":"SEL", "airport_name":"Seoul All Airports", "city_name":"Seoul"},
 {"airport_iata_code":"OSA", "airport_name":"Osaka All Airports", "city_name":"Osaka"},
 {"airport_iata_code":"YEA", "airport_name":"Edmonton All Airports", "city_name":"Edmonton"},
 {"airport_iata_code":"BJS", "airport_name":"Beijing All Airports", "city_name":"Beijing"},
 {"airport_iata_code":"STO", "airport_name":"Stockholm All Airports", "city_name":"Stockholm"},
 {"airport_iata_code":"RIO", "airport_name":"Rio De Janeiro All Airports", "city_name":"Rio De Janeiro"},
 {"airport_iata_code":"ORL", "airport_name":"Orlando Executive Airport", "city_name":"Orlando"},
 {"airport_iata_code":"DSS", "airport_name":"Blaise Diagne International Airport", "city_name":"Dakar"},
 {"airport_iata_code":"XEA", "airport_name":"Pacific Central Station", "city_name":"Vancouver"},
 {"airport_iata_code":"YMQ", "airport_name":"Montreal All Airports", "city_name":"Montreal"},
 {"airport_iata_code":"BUH", "airport_name":"Aurel Vlaicu International Airport", "city_name":"Bucharest"},
 {"airport_iata_code":"BUE", "airport_name":"Buenos Aires", "city_name":"Buenos Aires"},
 {"airport_iata_code":"TGZ", "airport_name":"Angel Albino Corzo International Airport", "city_name":"Tuxtla Gutierrez"},
 {"airport_iata_code":"QQK", "airport_name":"Kings Cross Airport", "city_name":"London"},
 {"airport_iata_code":"QQY", "airport_name":"York railway station", "city_name":"York"},
 {"airport_iata_code":"NJF", "airport_name":"Al Najaf International Airport", "city_name":"Al Najaf"},
 {"airport_iata_code":"IWK", "airport_name":"Marine Corps Air Station Iwakuni", "city_name":"Iwakuni"},
 {"airport_iata_code":"PKX", "airport_name":"Beijing Daxing International Airport", "city_name":"Beijing"},
 {"airport_iata_code":"SPK-D-7111", "airport_name":"ChennaiInternationalAirport", "city_name":"Chennai"},
 {"airport_iata_code":"YZQ", "airport_name":"CanadaInternational", "city_name":"canada"},
 {"airport_iata_code":"SPK-D-7113", "airport_name":"Sapporo", "city_name":"Sapporo"},
 {"airport_iata_code":"RYV", "airport_name":"Watertownmunicipal", "city_name":"watertown"},
 {"airport_iata_code":"hyy", "airport_name":"ffff", "city_name":"gggggo"},
 {"airport_iata_code":"SPK-D-7116", "airport_name":"sapparo", "city_name":"sapparo"},
 {"airport_iata_code":"SPK-D-7117", "airport_name":"sappanro", "city_name":"sappanro"},
 {"airport_iata_code":"SPK-D-7118", "airport_name":"sjdks", "city_name":"sdsdsd"},
 {"airport_iata_code":"SPK-D-7119", "airport_name":"rdfdf", "city_name":"vhhuuuuuuuuuuuuuuuuuuuuu"},
 {"airport_iata_code":"SPK-D-7120", "airport_name":"sapparo", "city_name":"sapparo"},
 {"airport_iata_code":"SPK-D-7121", "airport_name":"Sapparo", "city_name":"Sapparo"},
 {"airport_iata_code":"SPK-D-7122", "airport_name":"ffgfgggf", "city_name":"gfggfhghh"},
 {"airport_iata_code":"SPK-D-7123", "airport_name":"FCHGG", "city_name":"GJHYTJ"},
 {"airport_iata_code":"SPK-D-7124", "airport_name":"sapparo", "city_name":"sapparo"},
 {"airport_iata_code":"SPK-D-7125", "airport_name":"sapparo", "city_name":"sapparo"},
 {"airport_iata_code":"SPK-D-7126", "airport_name":"sapparo", "city_name":"sapparo"},
 {"airport_iata_code":"SPK-D-7127", "airport_name":"Sapparo", "city_name":"Sapparo"},
 {"airport_iata_code":"ZQZ", "airport_name":"quer", "city_name":"chennai"},
 {"airport_iata_code":"SPK-D-7129", "airport_name":"japan nation", "city_name":"japan"},
 {"airport_iata_code":"XNB", "airport_name":"United Arab Emirates", "city_name":"Dubai"},
 {"airport_iata_code":"SPK", "airport_name":"JSPK", "city_name":"chiba"},
 {"airport_iata_code":"PVF-D-7132", "airport_name":"xvvc", "city_name":"asdffg"},
 {"airport_iata_code":"SSS", "airport_name":"Vancouver International Airport", "city_name":"Vancouver"},
 {"airport_iata_code":"PVF", "airport_name":"asdd", "city_name":"asdfd"},
 {"airport_iata_code":"DDD", "airport_name":"vancouver_airport", "city_name":"canada"},
 {"airport_iata_code":"XYZ", "airport_name":"Chennai", "city_name":"Chennai"}];