<template>
<div>
    <FlightSearch/>
    <FlightResult v-if="bflightlistvisible"/>
</div>
</template>

<script>
import FlightSearch from "./flight-search-form.vue";
import FlightResult from "./flight-result.vue";
export default {
  name: "HomeIndex",
  components: {
    FlightSearch,
    FlightResult
  },
  computed : {
    bflightlistvisible : function(){
      return this.$store.getters["flights/getFlightListVisible"];
    }
  },
};  
</script>