<template>
  <app-layout>
     <div class="home_content_container"> 
         <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="airshoping-tab" data-toggle="tab" href="#airshoping" role="tab" aria-controls="home" aria-selected="true">Air Shopping</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="booking-tab" data-toggle="tab" href="#booking" role="tab" aria-controls="profile" aria-selected="false">Flight Booking</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="ticket-tab" data-toggle="tab" href="#ticket" role="tab" aria-controls="contact" aria-selected="false">Tickets</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="airshoping" role="tabpanel" aria-labelledby="airshoping-tab">
             <Flight v-if="bflightvisible"/>
             <FlightBooking v-if="bflightbookingvisible"/>
          </div>
          <div class="tab-pane fade" id="booking" role="tabpanel" aria-labelledby="booking-tab">
            <Booking/>
          </div>
          <div class="tab-pane fade" id="ticket" role="tabpanel" aria-labelledby="ticket-tab">
            <Tickets/>
          </div>
        </div>
     </div>  
     <JsonModel/>
     <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="true" :color="loadingbgcolor"></loading>   
  </app-layout>
</template>

<style></style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AppLayout from "@/layouts/Default.vue";
import Flight from '@/components/flights/index.vue';
import FlightBooking from '@/components/flights/flight-booking.vue';
import Booking from '@/components/booking.vue';
import Tickets from '@/components/tickets.vue';
import JsonModel from '@/components/Jsonviewer.vue';

export default {
  name: "HomeIndex",
  components: {
    Loading,
    AppLayout,
    Flight,
    FlightBooking,
    Booking,
    Tickets,
    JsonModel
  },
  data: function() {
    return {
      //isLoading: true,
      loadingbgcolor: "#0069d9",
    }
  },
  computed : {
    bflightvisible : function(){
      return this.$store.getters["flights/getFlightResultVisible"];
    },
    bflightbookingvisible : function(){
      return !this.$store.getters["flights/getFlightResultVisible"];
    },
    isLoading : function(){
      return this.$store.getters["common/getAppLoaderstatus"];
    }
  },
  mounted(){
    //console.log(this.$store.getters["flights/getFlightResultVisible"]);
  }
};  
</script>
