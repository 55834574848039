<template>
<div class="flight-booking_container" v-if="blistvisible">
   <div class="itinerary-info-pnl">
       <div class="d-flex w-100 justify-content-end align-items-center" style="margin-bottom:10px;text-align: right;">
           <span class="badge badge-info" style="font-size:14px;cursor: pointer;margin-right: 10px;" @click="airpriceviewjson">View JSON</span>
       </div>
       <div class="itinerary-header-details d-flex w-100 justify-content-between align-items-center">
           <div>
               <p class="m-0 itinerary-lable" style="display:none;">
                   <span>One Way</span>
                   <span>|</span>
                   <span>Economy</span>
               </p>    
               <p>
                   <span class="span-info">{{ fromcity }}</span>
                   <span class="span-info"><i class="fa fa-arrow-right"></i></span>
                   <span class="span-info">{{ tocity }}</span>
               </p>
           </div>
           <div>
               <p class="mb-0 itinerary-lable">
                   <span>Total price from</span>
               </p>
               <p style="text-align:right;">
                   <span class="span-info">C$ {{ disPrice }}</span>
               </p>
           </div>
       </div>
       <div class="itinerary-flight-details" style="margin-top:20px;">
           <h3 style="font-size:20px;">Selected flights</h3>
           <div v-if="blistvisible">
             <FlightList :flightdetails="flightObj" :showSelBtn="false"/>
           </div>     
       </div>
      <form v-on:submit.prevent="actionBooking" novalidate>
       <div class="passenger-basicinfo-pnl" style="margin-top:30px;">
           <h3 style="font-size:20px;">Passenger 1 - Adult</h3>
           <div class="passenger-basicinfo-form">
               <div class="form-row">
                   <div class="form-group col-md-4">
                        <label for="inputState">Title</label>
                       <!-- <multiselect v-model="passengerDetails.title" :searchable="false" :show-labels="false" placeholder="Select Title" :options="Titles" track-by="value" label="value" >
                          <span slot="noResult">{{$t('common').NoResults}}</span>
                        </multiselect>-->
                        <select id="inputState" class="form-control" v-model="passengerDetails.title" @click="setGender">
                            <option selected="selected" value="Mr">Mr.</option>
                            <option value="Ms">Ms.</option>
                            <option value="Master">Master.</option>
                            <option value="Miss">Miss.</option>
                            <option value="Mrs">Mrs.</option>
                        </select> 
                   </div>
                   <div class="form-group col-md-4">
                        <label for="inputCity">First</label>
                        <input type="text" class="form-control" v-model="passengerDetails.first" :class="{ 'is-invalid': $v.passengerDetails.first.$error }">
                   </div>
                   <div class="form-group col-md-4">
                        <label for="inputCity">Last</label>
                        <input type="text" class="form-control" v-model="passengerDetails.last" :class="{ 'is-invalid': $v.passengerDetails.last.$error }">
                   </div>
               </div>
               <div class="form-row">
                   <div class="form-group col-md-4">
                        <label for="inputCity">Phone</label>
                        <input type="text" class="form-control" v-model="passengerDetails.phone" :class="{ 'is-invalid': $v.passengerDetails.phone.$error }">
                   </div>
                   <div class="form-group col-md-4">
                        <label for="inputCity">Email</label>
                        <input type="text" class="form-control" v-model="passengerDetails.email" :class="{ 'is-invalid': $v.passengerDetails.email.$error }">
                   </div>
                   <div class="form-group col-md-4">
                        <label for="inputCity">Nationality</label>
                        <input type="text" class="form-control" v-model="passengerDetails.paxcountry" :class="{ 'is-invalid': $v.passengerDetails.paxcountry.$error }">
                   </div>
               </div>
           </div>
       </div>

       <div class="passenger-address-pnl" style="margin-top:30px;">
           <h3 style="font-size:20px;">Address</h3>
           <div class="passenger-address-form">
               <div class="form-row">
                   <div class="form-group col-md-12">
                        <label for="inputCity">Address</label>
                        <input type="text" class="form-control" v-model="passengerDetails.address" :class="{ 'is-invalid': $v.passengerDetails.address.$error }">
                   </div>
               </div>
               <div class="form-row">
                   <div class="form-group col-md-3">
                       <label for="inputCity">City</label>
                       <input type="text" class="form-control" v-model="passengerDetails.city" :class="{ 'is-invalid': $v.passengerDetails.city.$error }">
                   </div>
                   <div class="form-group col-md-3">
                       <label for="inputCity">Zip</label>
                       <input type="text" class="form-control" v-model="passengerDetails.zip" :class="{ 'is-invalid': $v.passengerDetails.zip.$error }">
                   </div>
                   <div class="form-group col-md-3">
                       <label for="inputCity">Country</label>
                       <input type="text" class="form-control" v-model="passengerDetails.adscountry" :class="{ 'is-invalid': $v.passengerDetails.adscountry.$error }">
                   </div>
                   <div class="form-group col-md-3">
                       <label for="inputCity">State</label>
                       <input type="text" class="form-control" v-model="passengerDetails.state" :class="{ 'is-invalid': $v.passengerDetails.state.$error }">
                   </div>
               </div>    
           </div>
       </div>

       <div class="passenger-payment-pnl" style="margin-top:30px;">
           <h3 style="font-size:20px;">Payment Information</h3>
           <div class="passenger-payment-form">
               <div class="form-row">
                   <div class="form-group col-md-3">
                       <label for="inputCity">Credit Card</label>
                       <input type="text" class="form-control" v-model="passengerDetails.creditcard" :class="{ 'is-invalid': $v.passengerDetails.state.$error }">
                   </div>
                   <div class="form-group col-md-3">
                       <label for="inputCity">Number</label>
                       <input type="password" class="form-control" v-model="passengerDetails.creditnumber" :class="{ 'is-invalid': $v.passengerDetails.state.$error }">
                   </div>
                   <div class="form-group col-md-3">
                       <label for="inputCity">Expires</label>
                       <input type="text" class="form-control" v-model="passengerDetails.creditexpire" :class="{ 'is-invalid': $v.passengerDetails.state.$error }">
                   </div>
                   <div class="form-group col-md-3">
                       <label for="inputCity">CVV </label>
                       <input type="text" class="form-control" v-model="passengerDetails.creditcvv" :class="{ 'is-invalid': $v.passengerDetails.state.$error }">
                   </div>
               </div>
           </div>
       </div>

       <div class="passenger-payment-pnl" style="margin-top:30px;text-align:center">
           <button type="submit" class="btn btn-primary">Confirm & Book</button>
       </div>    
       </form> 
   
   </div>

   <div id="bookingModal" class="modal fade" ref="bookingModal" tabindex="-1">
        <div class="modal-dialog"> 
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Booking Confirmation</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="api-details-pnl">
                    <p style="text-align:center;font-weight:bold;">Booking Confirmed Successfully</p>
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td><b>OrderID</b></td>
                                <td>
                                    {{ orderdetails.orderid }}
                                </td>
                            </tr>
                            <tr>
                                <td><b>PNR</b></td>
                                <td>
                                    {{ orderdetails.gdsref }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                   <p style="text-align:center;"><span class="badge badge-info" style="font-size: 14px; cursor: pointer; margin-right: 10px;" @click="createorderviewjson">View JSON</span></p>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { airports } from "@/utils/airports";
import FlightList from "./flight-list.vue";
import { required } from "vuelidate/lib/validators";
import $ from "jquery";
import moment from "moment";
export default {
  name: "FlightResult",
  components: {
    FlightList,
  },
  computed: {
    dealSelected: function() {
      return this.$store.getters["flights/getDealSelected"];
    },
    fromcity: function() {
      if (this.firstSegment.Departure) {
        return this.getAirportName(this.firstSegment.Departure.AirportCode);
      }
      return "";
    },
    tocity: function() {
      if (this.lastSegment.Arrival) {
        return this.getAirportName(this.lastSegment.Arrival.AirportCode);
      }
      return "";
    },
    blistvisible: function() {
      if (Object.keys(this.flightObj).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      flightObj: {},
      firstSegment: {},
      lastSegment: [],
      disPrice: "",
      passengerDetails: {
        title: "Mr",
        gender:"Male",
        first: "John",
        last: "Smith",
        phone: "123456789",
        email: "johnsmith@clarityndcdemo.com",
        paxcountry: "US",
        address: "Via Augusta 59 5",
        city: "Madrid",
        zip: "50156",
        adscountry: "US",
        state: "IA",
        creditcard: "Kumar",
        creditnumber: "4444333322221111",
        creditexpire: "2022-05",
        creditcvv: "4111"
      },
      orderdetails: {
        orderid: "",
        gdsref: ""
      },
      }
  },
  validations: {
    passengerDetails: {
      first: {
        required
      },
      last: {
        required
      },
      phone: {
        required
      },
      email: {
        required
      },
      paxcountry: {
        required
      },
      address: {
        required
      },
      city: {
        required
      },
      zip: {
        required
      },
      adscountry: {
        required
      },
      state: {
        required
      },
      creditcard: {
        required
      },
      creditnumber: {
        required
      },
      creditexpire: {
        required
      },
      creditcvv: {
        required
      }
    }
  },
  watch: {
    $dealSelected: {
      handler() {
        this.handleFlightResonse();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setGender() {
          //this.passengerDetails.title == 'Mr.' || this.passengerDetails.title == 'Master.'? this.passengerDetails.gender = 'Male':  this.passengerDetails.gender = 'Female';
           if (this.passengerDetails.title == 'Mr' || this.passengerDetails.title == 'Master') {
                  this.passengerDetails.gender = 'Male';
                  }
            else {
                    this.passengerDetails.gender = 'Female';
                }
            },       
    handleFlightResonse: function() {
      //console.log(this.dealSelected);
      this.$store
        .dispatch("flights/getAirOfferPrice", this.dealSelected)
        .then(res => {
            if(res.Error){
                this.$toast.open({
              message: res.Error["Value"],
              type: "error",
              duration: 3000,
              dismissible: true,
              position: "top"
            });
            }else{
                 this.flightObj = res[0];
                let { Flights = [], TotalPrice } = this.flightObj;
                let flight = Flights[0];
                let segmentCount = flight.Segments.length;
                this.firstSegment = flight.Segments[0];
                this.lastSegment = flight.Segments[segmentCount - 1];
                this.disPrice = TotalPrice.BookingCurrencyPrice;
            }
        }).catch((err)=> {
            console.log(err);
        });
    },
    getAirportName(airportCode) {
      let dataobj = airports.find(row => row.airport_iata_code == airportCode);
      return dataobj.city_name;
    },
    isFormValid() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        return true;
      }
      return false;
    },
    actionBooking: function() {
      if (this.isFormValid()) {
        let dealOffer = {
          shoppingResponseId: this.flightObj.shoppingResponseId,
          offerId: this.flightObj.OfferID,
          offerResponseId: this.flightObj.offerResponseId,
          totalamount : this.disPrice
        };
        let input_param = {
          dealoffer: dealOffer,
          passengerdetails: this.passengerDetails
        };
        this.$store.dispatch("bookings/createOrder", input_param).then(res => {
          if (res.OrderViewRS.Errors) {
            let msg = res.OrderViewRS.Errors.Error["Value"];
            this.$toast.open({
              message: msg,
              type: "error",
              duration: 3000,
              dismissible: true,
              position: "top"
            });
          } else {

            let cache_obj = res.OrderViewRS.Order[0];
            this.orderdetails["orderid"] = cache_obj["OrderID"];
            this.orderdetails["gdsref"] = cache_obj["GdsBookingReference"];

            let {
                Flights = []
            } = this.flightObj;

            let flight = Flights[0];
            let firstSegment = flight.Segments[0];
             
            let bookingdate = moment().format("YYYY-MM-DD");
            let traveldate = firstSegment.Departure.Date

            let bookingobj = {
                "pnr" : cache_obj["GdsBookingReference"],
                "bookingid" : cache_obj["OrderID"],
                "booking_date" : bookingdate,
                "traveldate" : traveldate,
                "status" : cache_obj["OrderStatus"],
                "shoppingResponseId" : res.OrderViewRS["1620355940584375141"],
                "offerid" : cache_obj["OfferID"]
            }
            this.$store.dispatch("bookings/addBookingList", bookingobj);
            $("#bookingModal").modal("toggle");
          }
        });
      }
    },
    airpriceviewjson: function() {
      let dataobj = this.$store.getters["flights/getJsonViewser"];
      let jsonmodel = {
        title: "Air Offer Price",
        url:this.api_url+"/"+"AirOfferPrice",
        //url: "https://newapi.tltid.com/AirOfferPrice",
        api_req: dataobj.airprice_request,
        api_res: dataobj.airprice_response
      };
      this.$store.dispatch("common/setJsonModel", jsonmodel).then(() => {
        $("#jsonModel").modal("toggle");
      });
    },
    createorderviewjson: function() {
      let dataobj = this.$store.getters["bookings/getJsonViewser"];
      let jsonmodel = {
        title: "Order Create",
        url:this.api_url+"/"+"AirOrderCreate",
        //url: "https://newapi.tltid.com/AirOrderCreate",
        api_req: dataobj.createorder_request,
        api_res: dataobj.createorder_response
      };
      this.$store.dispatch("common/setJsonModel", jsonmodel).then(() => {
        $("#jsonModel").modal("toggle");
      });
    },
         
  },
  created(){
      let ndc_url = (localStorage.getItem('ndc_api_url')) ? (localStorage.getItem('ndc_api_url')) : "";
      this.api_url = ndc_url;
    },
};
</script>